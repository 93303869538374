import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { BodyRegular } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";

export function OutlineRadioButton(props: Props) {
  const {text, isSelected, onClick} = props;
  const {t} = useTranslation();
  return (
    <ButtonContainer onClick={onClick} isSelected={isSelected}>
      <Row>
        <Radio isSelected={isSelected}>
          {isSelected && <RadioCircle isSelected={isSelected} />}
        </Radio>
        <BodyRegular weight={600}>{t(text)}</BodyRegular>
      </Row>
      {props.children && {...props.children}}
    </ButtonContainer>
  )
}

interface Props {
  text: string;
  isSelected: boolean;
  onClick: () => any;
  children?: any;
}

const ButtonContainer = styled.div<any>`
  padding: 24px;
  border-radius: 8px;
  border: ${props => props.isSelected ? `solid 1px ${Colours.blue}` : `solid 1px ${Colours.mildGrey}`};
  margin-bottom: 16px;
  cursor: pointer;
  width: 100%;
`

const Row = styled.div<any>`
  display: flex;
  cursor: pointer;
  width: 100%;
`

const Radio = styled.div<any>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 24px;
  border: ${props => props.isSelected ? `solid 2px ${Colours.blue}` : `solid 1px ${Colours.mildGrey}`};
  position: relative;
`
const RadioCircle = styled.div<any>`
  width: 12px;
  height: 12px;
  top: ${props => props.isSelected ? '8px' : '9px'};
  left: ${props => props.isSelected ? '8px' : '9px'};
  border-radius: 100%;
  background-color: ${props => props.isSelected ? Colours.blue : Colours.mildGrey};
  position: absolute;
`
