import styled, { css } from "styled-components/macro";
import Colours from "./Colours";
import { smallTablet } from "./MediaQueries";

export const Row = styled.div`
  display: flex;
`

export const VerticallyCenteredRow = styled.div`
  display: flex;
  align-items: center;
`

export const SpaceBetweenRow = styled<any>(Row)<{divider?: boolean}>`
  justify-content: space-between;
  ${props => css`border-bottom: ${props.divider ? `1px solid ${Colours.mildGrey}` : 'none'};`};
  padding: 16px 0;
  width: 100%;
`

export const FullWidthColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (${smallTablet}) {
    flex-direction: row;
    & > * {
      margin-right: 24px;
    }
  }
  & > * :last-child {
    margin-right: 0;
  }
`
