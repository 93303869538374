import React, { useEffect, useMemo, useState } from 'react';
import { BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import TextField from "../../../../../UI/atoms/TextField";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { useDispatch, useSelector } from "react-redux";
import { addEditingDesks, updateOfficeFloor } from "../../../../../../store/ducks/editOfficeLayout.duck";
import { Row } from "../../../../../UI/atoms/StructuralLayout";
import IconButton from "../../../../../UI/molecules/icon-button/IconButton";
import { deleteOffice, OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { Desk, getDesksInOffice } from "../../../../../../services/DeskBookingService";
import { ManageAreaControl } from "./ManageAreaControl";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findAllDirectAreasOfOffice } from "../../../../../../utils/OfficeHelper";
import { ManageDeskControl } from "./ManageDeskControl";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";
import { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";

interface Props {
  isEditable: boolean;
  floor: OfficeEntity;
}

export default function OfficeFloorControl(props: Props) {
  const {isEditable, floor} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const allOffices = useSelector(selectAllOfficeEntities);

  const [desks, setDesks] = useState<Desk[]>([]);
  const areas = useMemo(() => findAllDirectAreasOfOffice(allOffices, floor.id), [allOffices, floor]);
  const activeDesks = useMemo(() => desks.filter(d => d.active), [desks]);

  useEffect(() => {
    if (floor && floor.id > 0) {
      getDesksInOffice(floor.id).then((desks) => {
        setDesks(desks);
        dispatch(addEditingDesks(desks));
      });
    }
  }, [dispatch, floor]);


  const onFloorLabelChange = (floor: OfficeEntity, value: string) => {
    if (!isEditable) return;
    dispatch(updateOfficeFloor({floor, field: 'label', value}));
  }

  const onFloorCapacityChange = (floor: OfficeEntity, value: string) => {
    if (!isEditable) return;
    dispatch(updateOfficeFloor({floor, field: 'capacity', value}));
  }

  const onDeleteFloorClicked = () => {
    dispatch(openDialogWithPayload({
      payload: { officeId: floor.id, onConfirm: deleteOffice},
      activeDialog: DialogIdentifiers.DeleteOfficeEntityDialog
    }))
  }

  return <>
    <FloorContainer className={"floorContainer"}>
      <BodyLarge weight={400} colour={Colours.blue}>{t('settings.office-floor')}</BodyLarge>

      <Row>
        <TextField disabled={!isEditable}
                   className="floor__name__textBox"
                   value={floor.label}
                   onChange={(value: string) => onFloorLabelChange(floor, value)}
                   dataTest="office-floor-name-input"
                   label="textinput.floor-name"/>

        <IconButton disabled={!isEditable} icon={IconTypes.Bin}
                    className="floor__delete__icon"
                    onClick={() => onDeleteFloorClicked()} />
      </Row>

      {areas.length === 0 && desks.length === 0 && <>
        <TextField disabled={!isEditable}
                   className="floor__capacity__textBox"
                   value={'' + (floor.capacity ?? 0)}
                   type="number"
                   onChange={(value: string) => onFloorCapacityChange(floor, value)}
                   dataTest="office-floor-capacity-input"
                   label="textinput.number-desks-for-floor" rightText="textinput.desks-available-each-day"/>
      </>}

      {floor.id > 0 && <>
        <ManageAreaControl areas={areas} numberOfActiveDesks={activeDesks.length} isEditable={isEditable} parentOffice={floor}/>
        <ManageDeskControl desks={desks} isEditable={isEditable} parentOffice={floor}/>
      </>}

    </FloorContainer>
  </>;
}

export const FloorContainer = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 16px;

  &:last-child {
    border-bottom: none;
  }
`
