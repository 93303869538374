import React, { useEffect, useMemo, useState } from 'react';
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { OfficeCapacityLabel } from "../../../../UI/molecules/OfficeCapacityLabel";
import { CapacityResponse } from "../../../../../services/OfficeCapacityService";
import { BodyLarge } from "../../../../UI/atoms/fonts/Body";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveDay, selectActivePeriod } from "../../../../../store/ducks/editMovements.duck";
import { Column, Row, SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { Period, SimpleUserWithLocation } from "../../../../../models/movements.models";
import { bookSingleDayMovement } from "../../../../../store/ducks/officeUsage.duck";
import { calculateAmSpacesAvailable, calculatePmSpacesAvailable } from "../../../../../utils/OfficeHelper";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { getUsersInOfficeOn } from "../../../../../services/Movements";
import Colours from "../../../../UI/atoms/Colours";
import { ExpandableUserList } from "./ExpandableUserList";
import Icon, { IconTypes } from '../../../../UI/atoms/icon/Icon';
import { isInTeams } from "../../../../../utils/TeamsUtils";

export const AUTO_EXPAND_LIMIT = 8;

export function DailyOfficeArea(props: Props) {
  const {entity, capacity, initialOpenState} = props;
  const dispatch = useDispatch();
  const [usersInArea, setUsersInArea] = useState<SimpleUserWithLocation[]>([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(initialOpenState);
  const currentUser = useSelector(selectCurrentUser);
  const selectedDate = useSelector(selectActiveDay);
  const activePeriod = useSelector(selectActivePeriod);
  const amSpacesAvailable = useMemo(() => calculateAmSpacesAvailable(capacity), [capacity]);
  const pmSpacesAvailable = useMemo(() => calculatePmSpacesAvailable(capacity), [capacity]);
  const allDaySpacesAvailable = useMemo(() => amSpacesAvailable > 0 && pmSpacesAvailable > 0, [amSpacesAvailable, pmSpacesAvailable])

  const bookSingleDay = (period: Period) => {
    if (selectedDate && amSpacesAvailable > 0 && pmSpacesAvailable > 0) {
      dispatch(bookSingleDayMovement({
        date: selectedDate,
        office: entity,
        userId: currentUser?.id,
        period: period
      }));
    }
  }

  useEffect(() => {
    setHasLoaded(false);
    if (isVisible) {
      if ((capacity?.amUsed ?? 0) + (capacity?.pmUsed ?? 0) > 0 && selectedDate) {
        getUsersInOfficeOn(selectedDate, entity.id)
          .then((resp: SimpleUserWithLocation[]) => setUsersInArea(resp))
          .finally(() => setHasLoaded(true));
      } else {
        setHasLoaded(true);
      }
    }
  }, [capacity, entity, selectedDate, isVisible]);

  const onRowClick = (event: Event) => {
    event.stopPropagation();
    setIsVisible(!isVisible);
  }

  return (
    <AreaContainer data-test={entity.label}>
      <ExpandableRow onClick={(event: Event) => onRowClick(event)}>
        <Column>
          <BodyLarge weight={600} style={{lineHeight: '26px'}}>{entity.label}</BodyLarge>
          <Row>
            <Icon icon={isVisible ? IconTypes.Down : IconTypes.Next} className={"expandableRow__icon"} color={"grey"} />
            <OfficeCapacityLabel capacity={capacity} />
          </Row>
        </Column>

        <div style={{alignItems: 'center', display: 'flex'}}>
          {entity.floor && !isInTeams() && <OutlineButton text={'button.view'} size={'small'} style={{marginRight: 8}}
                                          click={() => window.location.href = `/whereabouts/company/all?office=${entity.id}&view=floor`} />}
          <OutlineButton size={'small'}
                         disabled={!entity.active || !allDaySpacesAvailable}
                         click={() => bookSingleDay(activePeriod)}
                         text="button.book"
                         dataTest="book-into-office-button" />
        </div>
      </ExpandableRow>

      <ExpandableUserList office={entity} users={usersInArea} isVisible={isVisible} isLoading={!hasLoaded} />

    </AreaContainer>
  )
}

interface Props {
  entity: OfficeEntity;
  initialOpenState: boolean;
  capacity?: CapacityResponse;
}

const AreaContainer = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  &:last-child {
    border: none;
  }
`

const ExpandableRow = styled<any>(SpaceBetweenRow)`
  transition: background-color .2s;
  &:hover {
    background-color: ${Colours.blue05};
  }
  .expandableRow__icon {
    margin-right: 8px;
  }
`
