import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthLoading, setAuthError, setAuthLoading, setUser } from "../../../store/ducks/auth.duck";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { setLoading } from '../../../store/ducks/registration.duck';
import { agent } from "../../../services/agent";
import { Auth } from "../../../services/Auth";
import { TrackJS } from "trackjs";
import { isInTeams } from "../../../utils/TeamsUtils";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { teamsLogin } from "../../../utils/AuthUtils";
import { microsoftRequest } from "../../../services/EnvironmentVariables";
import { emitRegisterEvent } from "../../../services/RegisterService";
import { useMsal } from "@azure/msal-react";

export function RegisterWithMicrosoftTeamsButton(props: Props) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsAuthLoading);
  const {instance} = useMsal();

  const registerWithServer = async () => {
    const informMe = localStorage.getItem('informMe') === 'true';
    try {
      await agent.requests.post('/register/azure', {informMe: informMe, inTeams: isInTeams()});
      const user = await Auth.current(false);
      dispatch(setUser(user));
      dispatch(setAuthLoading(false));
    } catch (e: any) {
      console.error(e);
      dispatch(setAuthError(e.message))
      TrackJS?.track(e);
    }
  }

  const register = async () => {
    if (isLoading) return;

    try {
      dispatch(setLoading(true));
      dispatch(closeDialog());

      emitRegisterEvent();

      await teamsLogin(instance, microsoftRequest.register.scopes, dispatch, false, true);
      await registerWithServer();

    } catch (e: any) {
      TrackJS?.track(e);
      console.log(e);
      dispatch(failureNotification('Failed to register'))
    } finally {
      dispatch(setLoading(false))
    }
  }
  return (
    <div>
      <PrimaryButton click={register} fullWidth={true} size="large" text="button.continue-to-signup"/>
    </div>
  )
}

interface Props {
}
