import { useEffect, useState } from "react";
import { isInTeams, resetInTeamsStoreApp, setInTeamsStoreApp } from "../utils/TeamsUtils";
import { app } from "@microsoft/teams-js";
import { setIsInTeamsApp } from "../store/ducks/auth.duck";
import { useDispatch } from "react-redux";

export function WithMsTeamsAppChecker() {
  const [context, setContext] = useState<app.Context | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInTeams()) {
      app.initialize().then(() => app.getContext()).then((ctx: app.Context) => {
        setContext(ctx);
        dispatch(setIsInTeamsApp(ctx.page.id.includes('teamsapp-')));
      })
    }
  }, [dispatch]);

  useEffect(() => {
    if (context && context.page.id.includes('teamsapp-')) {
      setInTeamsStoreApp();
    } else {
      resetInTeamsStoreApp();
    }

  }, [context]);
}
