import React, { useState } from 'react';
import Dialog from "../../../UI/molecules/Dialog";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import DialogHeader from "../../../dialogs/DialogHeader";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { Center, Column } from "../../../UI/atoms/StructuralLayout";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { MeetingRoom } from "../models/MeetingRooms";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { deleteMeetingRoom } from "../../../../services/MeetingRoomsService";
import { redirectTo } from "../../../../store/ducks/auth.duck";

export function DeleteMeetingRoomConfirmDialog(props: Props) {
  const {meetingRoom} = props.payload;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const close = () => dispatch(closeDialog());

  const deleteRoom = async () => {
    setLoading(true);
    try {
      await deleteMeetingRoom(meetingRoom.id);
      dispatch(redirectTo(`/manage/meeting-rooms`));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog isOpen={true} onClose={close} showLogo={true}>
        <DialogHeader title={'delete-meeting-room-dialog.delete-meeting-room'} />
        {loading ? <Center>
          <LoadingSpinner hideBorder={true} hideText={true} />
        </Center> : <>

          <FlatContentCard style={{marginBottom: 16}}>
            <Column>
              <BodyRegular weight={600}>{meetingRoom.name}</BodyRegular>
              <BodyRegular>{t('delete-meeting-room-dialog.capacity', {capacity: meetingRoom.capacity})}</BodyRegular>
            </Column>
          </FlatContentCard>

          <PrimaryButton click={deleteRoom}
                         style={{marginBottom: 16}}
                         text={'delete-meeting-room-dialog.confirm-delete-meeting-room'}
                         fullWidth={true} />
          <OutlineButton click={close}
                         text={'delete-meeting-room-dialog.cancel'}
                         fullWidth={true} />
        </>}
      </Dialog>
    </>
  )
}

interface Props {
  payload: {
    meetingRoom: MeetingRoom,
  };
}
