import React, { useEffect, useState } from 'react';
import DialogHeader from "../dialogs/DialogHeader";
import { DialogWrapper } from "../pages/company-movements/dialogs/LinkTeamOrWorkgroupToOfficeDialog";
import { closeDialog } from "../../store/ducks/dialog.duck";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/ducks/auth.duck";
import { TeamWithCount } from "../../models/team.models";
import { OfficeEntity } from "../../services/AdvanceHotDeskingService";
import { TeamSelect } from "./components/TeamSelect";
import { Done } from "./components/Done";
import { DefaultOfficeSelect } from "./components/DefaultOfficeSelect";
import { Intro } from "./components/Intro";

enum View {
  Intro, TeamSelect, DefaultOffice, Done
}

export function NewUserOnboardingDialog(props: Props) {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [view, setView] = useState(View.TeamSelect);

  useEffect(() => {
    if (!!user?.teamEntity && !!user?.defaultLocationId) {
      setView(View.Done);
    } else {
      setView(View.Intro);
    }
  }, [user]);

  const close = () => {
    dispatch(closeDialog());
  };

  const onTeamSelected = (team: TeamWithCount) => {
    setView(View.DefaultOffice);
  }

  const onOfficeSelected = (office: OfficeEntity) => {
    setView(View.Done);
  }

  const onIntroDone = () => {
    if (!user?.teamEntity) {
      setView(View.TeamSelect);
    } else if (!user?.defaultLocationId) {
      setView(View.DefaultOffice);
    } else {
      setView(View.Done);
    }
  }

  console.log('view', view)

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="new-user-onboarding-dialog.title" />


      {view === View.Intro && <Intro next={onIntroDone} />}
      {view === View.TeamSelect && <TeamSelect onSelect={onTeamSelected} />}
      {view === View.DefaultOffice && <DefaultOfficeSelect onSelect={onOfficeSelected} />}
      {view === View.Done && <Done />}


    </DialogWrapper>
  )
}

interface Props {
}
