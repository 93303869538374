import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import {
  ConfigEntity,
  DefaultConfigEntity,
  getAppConfig,
  getConfig,
  postUpdateConfig,
  setAdvanceHotDesking,
} from "../../services/ConfigService";
import { updateEditCompanyConfig } from '../../components/pages/settings/pages/edit-company-page/editCompany.duck';
import { loadCompanyStructure } from "./companyStructure.duck";
import { setOffices } from "./advanceHotDeskingSetup.duck";
import { setTags } from "./userTags.duck";
import { setWhereaboutsOptions } from "./whereaboutsOptions.duck";
import { setTeamStructureForMovements } from "./companyMovements.duck";
import { TeamWithCount } from "../../models/team.models";
import { setAccountInfo } from "./payments.duck";
import { setMeetingRooms } from "../../components/pages/settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { setDepartments } from "../../components/pages/departments/data/departments.duck";

export interface ConfigState {
  configEntity: ConfigEntity;
  loading: boolean;
  globalConfigLoaded: boolean;
  teams: TeamWithCount[];
}

export const initialConfigState: ConfigState = {
  configEntity: DefaultConfigEntity,
  loading: false,
  globalConfigLoaded: false,
  teams: [],
}

export const loadConfig: any = createAsyncThunk('config/loadAppConfig',
  async (blank, thunkAPI) => {
    const appConfig = await getAppConfig();
    thunkAPI.dispatch(setOffices(appConfig.offices));
    thunkAPI.dispatch(setTags(appConfig.workGroups));
    thunkAPI.dispatch(setTeamStructureForMovements(appConfig.teams));
    thunkAPI.dispatch(setTeams(appConfig.teams));
    thunkAPI.dispatch(setWhereaboutsOptions(appConfig.whereaboutsOptions));
    thunkAPI.dispatch(setAccountInfo(appConfig.accountInfo));
    thunkAPI.dispatch(updateEditCompanyConfig(appConfig.config));
    thunkAPI.dispatch(setMeetingRooms(appConfig?.meetingRooms ?? []));
    thunkAPI.dispatch(setDepartments(appConfig?.departments ?? []));
    thunkAPI.dispatch(setPlatformConfig(new ConfigEntity(
      appConfig.config.holidayAllowance,
      appConfig.config.numberOfDesksAvailable,
      appConfig.config.companyHolidayAllowance,
      appConfig.config.holidayYearRecurringMonth,
      appConfig.config.holidayYearRecurringDay,
      appConfig.config.carParkingEnabled,
      appConfig.config.repeatWeeklySchedule,
      appConfig.config.repeatScheduleWeeksLimit,
      appConfig.config.restrictBookingWindowForTeamAdmins,
      appConfig.config.visitorBookingEnabled,
    )));
    await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
  }
)

export const fetchConfig: any = createAsyncThunk(
  'config/fetchConfig',
  async (blank, thunkAPI) => {
    if (!(thunkAPI.getState() as AppState).config.loading) {
      await thunkAPI.dispatch(setLoading(true));
      try {
        const platformConfig = await getConfig();
        await thunkAPI.dispatch(setPlatformConfig(platformConfig));
        await thunkAPI.dispatch(updateEditCompanyConfig(platformConfig));
      } finally {
        await thunkAPI.dispatch(setLoading(false));
      }
    }
  }
)

export const updateConfig: any = createAsyncThunk(
  'config/updateConfig',
  async (configEntity: ConfigEntity, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    if (!state.config.loading) {
      await thunkAPI.dispatch(setLoading(true));
      try {
        const config = await postUpdateConfig(configEntity);
        await thunkAPI.dispatch(loadCompanyStructure());
        return config;
      } finally {
        // reset
        await thunkAPI.dispatch(setLoading(false));
      }
    }
  }
)

export const updateAdvanceHotDeskingConfig: any = createAsyncThunk(
  'config/updateAdvanceHotDeskingConfig',
  async (value: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    if (!state.config.loading) {
      await thunkAPI.dispatch(setLoading(true));
      try {
        return await setAdvanceHotDesking(value);
      } finally {
        // reset
        await thunkAPI.dispatch(setLoading(false));
      }
    }
  }
)

const configSlice = createSlice({
  name: 'config',
  initialState: initialConfigState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setTeams: (state, action) => ({ ...state, teams: action.payload, }),
    setPlatformConfig: (state, action) => ({...state, configEntity: action.payload}),
    reset: () => ({...initialConfigState}),
  },
  extraReducers: {
    [updateConfig.fulfilled]: (state, action) => ({...state, configEntity: action.payload}),

    [loadConfig.pending]: (state) => ({...state, loading: true, globalConfigLoaded: false}),
    [loadConfig.reject]: (state) => ({...state, globalConfigLoaded: false, loading: false}),
    [loadConfig.fulfilled]: (state) => ({...state, loading: false, globalConfigLoaded: true}),
  }
});

export default configSlice.reducer
export const {
  setLoading,
  reset,
  setPlatformConfig,
  setTeams,
} = configSlice.actions;

// Selectors
export const selectIsConfigLoading = (state: AppState) => state.config.loading;
export const selectConfig = (state: AppState) => state.config.configEntity;
export const selectHasGlobalConfigLoaded = (state: AppState) => state.config.globalConfigLoaded;
export const selectAllTeams = (state: AppState) => state.config.teams;
export const selectIsCarParkingEnabled = (state: AppState) => !!state.config.configEntity.carParkingEnabled;
export const selectIsRepeatWeeklyScheduleEnabled = (state: AppState) => !!state.config.configEntity.repeatWeeklySchedule;
export const selectRepeatScheduleWeeksLimit = (state: AppState) => state.config.configEntity.repeatScheduleWeeksLimit;
