import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { User } from '../../models/user.models';
import { Auth } from '../../services/Auth';

export interface ForgotPasswordState {
  currentUser?: User;
  rememberMe: boolean;
  errorMessage?: string;
  redirectTo: string;
  loading: boolean;
  complete: boolean;
  staleToken: boolean;
}

export const initialForgotPasswordState: ForgotPasswordState = {
  currentUser: undefined,
  rememberMe: !!localStorage.getItem('rememberMe'),
  errorMessage: '',
  redirectTo: '',
  loading: false,
  complete: false,
  staleToken: false,
}

export const requestPasswordReset = createAsyncThunk(
  'forgotPassword/requestPasswordReset',
  async (emailAddress: string, thunkAPI) => {
    await thunkAPI.dispatch(setLoading(true));
    try {
      await Auth.forgotPassword(emailAddress);
    } catch (err: any) {
      await thunkAPI.dispatch(setLoading(false));
      await thunkAPI.dispatch(setErrorMessage(err?.message || 'Unknown error'));
    }
  }
)

export const resetPassword = createAsyncThunk(
  'forgotPassword/resetPassword',
  async (resetRequest: {password: string, resetId: string}, thunkAPI) => {
    await thunkAPI.dispatch(setLoading(true));
    try {
      await Auth.resetPassword(resetRequest.password, resetRequest.resetId);
    } catch (err: any) {
      await thunkAPI.dispatch(setLoading(false));
      if (err.statusCode === 404) {
        await thunkAPI.dispatch(setStaleToken(true));
      } else {
        await thunkAPI.dispatch(setErrorMessage(err?.message || 'Unknown error'));
      }
    }
  }
)

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: initialForgotPasswordState,
  reducers: {
    setLoading: (state, action) => ({ ...state, loading: action.payload }),
    setErrorMessage: (state, action) => ({ ...state, errorMessage: action.payload }),
    setStaleToken: (state, action) => ({ ...state, staleToken: action.payload }),
    reset: () => ({...initialForgotPasswordState}),
  },
  extraReducers: {
    // eslint-disable-next-line
    ['forgotPassword/requestPasswordReset/fulfilled']: (state) => {
      return {
        ...state,
        loading: false,
        complete: true,
      }
    },
    // eslint-disable-next-line
    ['forgotPassword/resetPassword/fulfilled']: (state) => {
      return {
        ...state,
        loading: false,
        complete: true,
      }
    },
  }
});

export default forgotPasswordSlice.reducer
export const {
  setLoading,
  reset,
  setErrorMessage,
  setStaleToken,
} = forgotPasswordSlice.actions;

// Selectors
export const selectIsLoading = (state: AppState) => state.forgotPassword.loading;
export const selectIsComplete = (state: AppState) => state.forgotPassword.complete;
export const selectError = (state: AppState) => state.forgotPassword.errorMessage;
export const selectIsStaleToken = (state: AppState) => state.forgotPassword.staleToken;
