import React from 'react';
import { useDispatch } from 'react-redux';
import { endEditingWhereabouts } from '../../../../../../store/ducks/editMovements.duck';
import './edit-movements-mobile.scss';
import MobileEditMovementsContent from "../../../weekly-movements-setter/components/MobileEditMovementsContent";
import IconButton from "../../../../molecules/icon-button/IconButton";
import WeekFlicker from "../../../../../pages/team-movements/components/WeekFlicker";
import { IconTypes } from "../../../../atoms/icon/Icon";
import { Column, Row } from '../../../../atoms/StructuralLayout';
import { PrimaryButton } from "../../../../atoms/buttons/PrimaryButton";

export default function EditMovementsMobile() {
  const dispatch = useDispatch();

  const save = () => {
    dispatch(endEditingWhereabouts());
  };

  return (
    <Column className="editMovementsMobile">
      <Row className="editMovementsMobile__header">
        <WeekFlicker className="editMovementsMobile__weekFlicker"/>
        <IconButton size="mediumlarge" className="editMovementsMobile__close" icon={IconTypes.Close}
                    onClick={() => dispatch(endEditingWhereabouts())}/>
      </Row>
      <MobileEditMovementsContent/>
      <div className="editMovementsMobile__controls">
        <PrimaryButton fullWidth={true} size="large" text="button.finish-and-save" click={() => save()}/>
      </div>
    </Column>
  )
}
