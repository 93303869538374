import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SidebarMode } from "../data/ManageDesks.model";
import { AppState } from "../../../../store/state/app.state";


export interface ManageDesksState {
  sidebarMode: SidebarMode
  selectedDeskIds: number[];
}

export const initialManageDesksState: ManageDesksState = {
  sidebarMode: SidebarMode.ChooseOffice,
  selectedDeskIds: [],
};

export const declineRequest: any = createAsyncThunk(
  'manageDesks/declineRequest',
  async (test: any, thunkAPI) => {
  }
);


const manageDesksSlice = createSlice({
  name: 'manageDesks',
  initialState: initialManageDesksState,
  reducers: {
    resetManageDesks: (state) => (initialManageDesksState),
    setSidebarMode: (state, action) => ({...state, sidebarMode: action.payload, selectedDeskIds: []}),
    setSelectedDeskIds: (state, action) => ({...state, selectedDeskIds: action.payload}),
    addSelectedDeskId: (state, action) => ({...state, selectedDeskIds: [...state.selectedDeskIds, action.payload]}),
    removeSelectedDeskId: (state, action) => ({...state, selectedDeskIds: [...state.selectedDeskIds].filter(id => id !== action.payload)}),
    clearSelectedDeskIds: (state) => ({...state, selectedDeskIds: []}),
  },
  extraReducers: {
    // [getApprovals.pending]: (state) => ({...state, approvals: [], loading: true}),
    // [getApprovals.failed]: (state) => ({...state, approvals: [], loading: false}),
    // [getApprovals.fulfilled]: (state, action) => ({...state, approvals: action.payload, loading: false}),
  },
});



export default manageDesksSlice.reducer;
export const {
  resetManageDesks,
  setSidebarMode,
  setSelectedDeskIds,
  clearSelectedDeskIds,
  addSelectedDeskId,
  removeSelectedDeskId
} = manageDesksSlice.actions;


// Selectors
export const selectSidebarMode = (state: AppState) => state.manageDesks.sidebarMode;
export const selectSelectedDeskIds = (state: AppState) => state.manageDesks.selectedDeskIds;
export const isDeskSelectionEnabled = (state: AppState) => state.manageDesks.sidebarMode === SidebarMode.ManageDesks;
