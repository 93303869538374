import React from 'react';
import { UsersWeekNote } from "./UsersWeekNote";
import { WeekNote } from "../../../../services/WeekNotesService";
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";

export function WeeklyTeamNotesView(props: Props) {
  const {weeklyNotesData, teamName} = props;
  return (
    <>
      <BodyLarge weight={600}>{teamName}</BodyLarge>
      <TeamNotesWrapper>
        {weeklyNotesData.map((weekNote, key) => (
          <UsersWeekNote weekNote={weekNote} key={key} />
        ))}
      </TeamNotesWrapper>
    </>
  )
}

interface Props {
  teamName: string;
  teamId: string;
  weeklyNotesData: WeekNote[];
}

const TeamNotesWrapper = styled.div`
  margin-left: 16px;
`
