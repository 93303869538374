import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import DialogHeader from "./DialogHeader";
import Dialog from "../UI/molecules/Dialog";
import { isCompanyAdminUser } from "../../utils/AccessControl";
import { selectCurrentUser } from "../../store/ducks/auth.duck";
import AccessRequired from "../pages/settings/components/AccessRequired";
import { checkOfficeUsage, deleteOffice, OfficeUsageSummary } from "../../services/AdvanceHotDeskingService";
import { LoadingSpinner } from "../UI/atoms/LoadingSpinner";
import { BodyRegular, BodySmall } from '../UI/atoms/fonts/Body';
import Colours from "../UI/atoms/Colours";
import { ConfigurationList } from "../pages/settings/StyleComponents";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import { failureNotification, successNotification } from "../../store/ducks/notification.duck";
import { fetchOfficeEntities } from "../../store/ducks/advanceHotDeskingSetup.duck";
import { selectFloors, setEditingFloors } from "../../store/ducks/editOfficeLayout.duck";
import { useTranslation } from "react-i18next";

interface Props {
  payload?: {officeId: number};
}

export default function DeleteOfficeEntityDialog(props: Props) {
  const {payload} = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => isCompanyAdminUser(currentUser), [currentUser]);
  const [loading, setLoading] = useState(true);
  const [officeUsage, setOfficeUsage] = useState<OfficeUsageSummary | undefined>();

  const close = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  useEffect(() => {
    if (payload?.officeId) {
      setLoading(true)
      setOfficeUsage(undefined);
      checkOfficeUsage(payload.officeId).then(setOfficeUsage).finally(() => setLoading(false))
    } else {
      console.error('No office entity id');
      close();
    }
  }, [close, dispatch, payload]);

  return (
    <Dialog isOpen={true}
            onClose={close}
            showLogo={true}>
      <AccessRequired teamAdminsRequired={false}
                      companyAdminsRequired={true}
                      visible={!hasAccess} />
      {hasAccess && <>
        {loading ? <>
          <LoadingSpinner hideBorder={true} />
        </> : <>
          {officeUsage && <>
            <OfficeItemsToDeleteMessage officeId={payload?.officeId ?? 0}
                                        officeUsage={officeUsage} />
          </>}
        </>}
      </>}
    </Dialog>
  )
}

function OfficeItemsToDeleteMessage(props: {officeId: number, officeUsage?: OfficeUsageSummary}) {
  const {officeId, officeUsage} = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const editingFloors = useSelector(selectFloors);
  const {t} = useTranslation();

  const confirmDeleteClicked = () => {
    if (officeId) {
      setLoading(true);
      deleteOffice(officeId)
        .then(() => {
          dispatch(fetchOfficeEntities(true));
          dispatch(setEditingFloors(editingFloors.filter(f => f.id !== officeId)))
          dispatch(successNotification('Deletion request submitted'));
        })
        .catch((err) => {
          console.error(err);
          dispatch(failureNotification('Failed to delete'));
        })
        .finally(() => {
          dispatch(closeDialog());
          setLoading(false);
        });
    } else {
      console.error('Missing onConfirm function')
      dispatch(failureNotification('Failed to delete'));
      dispatch(closeDialog());
    }
  }

  return (
    <>
      <DialogHeader title="dialog-headers.are-you-sure" />
      <BodyRegular>{t('delete-office-dialog.message')}</BodyRegular>
      {loading && <LoadingSpinner fullScreen={true} />}
      <ConfigurationList>
        <ul>
          {!!officeUsage?.offices && <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-office-dialog.office', {count: officeUsage.offices})}</BodySmall></li>}
          {!!officeUsage?.floors && <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-office-dialog.floor', {count: officeUsage.floors})}</BodySmall></li>}
          {!!officeUsage?.areas && <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-office-dialog.area', {count: officeUsage.areas})}</BodySmall></li>}
          {!!officeUsage?.desks && <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-office-dialog.desk', {count: officeUsage.desks})}</BodySmall></li>}
          {!!officeUsage?.favourites && <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-office-dialog.favourites', {count: officeUsage.favourites})}</BodySmall></li>}
        </ul>
      </ConfigurationList>
      <OutlineButton click={confirmDeleteClicked}
                     text={"button.yes-delete"}
                     disabled={loading}
                     size={"large"}
                     fullWidth={true} />
    </>
  )
}
