import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { selectFollowingTeams } from "../../../../store/ducks/followingTeamsAndTags.duck";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";

export function WithFollowedTeams() {
  const followedTeams = useSelector(selectFollowingTeams);
  const currentUser = useSelector(selectCurrentUser);

  return useMemo(() => {
    return followedTeams.map((t) => t.id === currentUser?.teamEntity?.id ? ({...t, name: `My team (${t.name})` }) : t);
  }, [followedTeams, currentUser]);
}
