import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import { MobileOnly } from "../../../UI/atoms/DeviceSpecific";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import DialogHeader from "../../DialogHeader";
import {
  selectAreaForReservation,
  selectDeskForReservation,
  selectSelectedFloor,
  setShowFloorMap
} from "../../../../store/ducks/deskReservation.duck";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { BodyLarge, BodyRegular, BodySmall } from "../../../UI/atoms/fonts/Body";
import { CurrentOfficeWhereaboutsSelection } from "./CurrentOfficeWhereaboutsSelection";
import { getOfficeAreaCapacity } from "../../../../services/OfficeCapacityService";
import { Moment } from "moment";
import { selectActiveDay, selectActivePeriod } from "../../../../store/ducks/editMovements.duck";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";
import { Desk, getAllAvailableDesksInOffice } from "../../../../services/DeskBookingService";
import { GenericDropDownList } from "../../../UI/molecules/GenericDropDownList";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { AppState } from "../../../../store/state/app.state";
import { selectOfficesByParentId } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";

export function ChooseArea() {
  const dispatch = useDispatch();
  const close = () => dispatch(closeDialog());
  const selectedFloor = useSelector(selectSelectedFloor);
  const activeDay = useSelector(selectActiveDay);
  const activePeriod = useSelector(selectActivePeriod);
  const selectedFloorChildren = useSelector((state: AppState) => selectOfficesByParentId(state, selectedFloor?.id));
  const {t} = useTranslation();

  const [desks, setDesks] = useState<Desk[]>([]);

  useEffect(() => {
    if (activeDay && selectedFloor?.deskBookingEnabled && selectedFloor?.id && activePeriod) {
      getAllAvailableDesksInOffice(selectedFloor.id, activeDay, activePeriod)
        .then((resp: Desk[]) => setDesks(resp));
    }
  }, [activeDay, selectedFloor, activePeriod]);

  const orderedDesks = useMemo(() => {
    let deskDropDownItems = desks.map((desk : Desk) => ({id: desk.id, name: desk.label, disabled: !desk.active}));
    return naturalSort(deskDropDownItems, 'name');
  }, [desks])

  if (!activeDay) {
    return null;
  }

  const selectDesk = (deskId: string) => {
    const desk = desks.find(d => d.id === parseInt(deskId));
    if (!desk) {
      console.error('Could not find desk', deskId)
    } else {
      if (desk.active) {
        dispatch(selectDeskForReservation(desk));
      } else {
        dispatch(warningNotification('The desk is not active.'));
      }
    }
  }

  return (
    <>
      <MobileOnly>
        <GoBackButton onClick={close} text="back-to-whereabouts"/>
      </MobileOnly>
      <DialogHeader title="dialog-headers.choose-area"/>
      <CurrentOfficeWhereaboutsSelection />

        {selectedFloor?.floorPlanEnabled && <>
          <OutlineButton click={() => dispatch(setShowFloorMap(true))}
                         style={{marginTop: 16, marginBottom: 12}}
                         fullWidth={true}
                         text={'whereabouts.select-on-floor-plan'} />

          <BodyRegular weight={600} style={{textAlign: 'center', marginBottom: 8}}>{t('general.or')}</BodyRegular>
        </>}

        {selectedFloor?.deskBookingEnabled && <GenericDropDownList onSelect={(deskId: string) => selectDesk(deskId)}
                                                                   fullWidth={true}
                                                                   label={"whereabouts.available-desks"}
                                                                   disabled={!orderedDesks || orderedDesks.length === 0}
                                                                   placeholder={orderedDesks.length > 0 ? "textinput.select-desk" : "textinput.none-available"}
                                                                   items={orderedDesks} />}

      {(selectedFloorChildren ?? []).length > 0 && <BodySmall style={{marginBottom: 14}} colour={Colours.darkGrey}>{t('whereabouts.available-areas')}</BodySmall>}
      <AreaList>
        {selectedFloorChildren.map((office: OfficeEntity, key: number) => (
          <div key={key}>
            <AreaRow area={office} date={activeDay} />
          </div>
        ))}
      </AreaList>
    </>
  )
}

function AreaRow(props: { area: OfficeEntity, date: Moment }) {
  const [remaining, setRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (!isLoading && !isRequested) {
      setIsLoading(true);
      setIsRequested(true);
      getOfficeAreaCapacity(props.area.id, props.date)
        .then((resp: any) => setRemaining(resp.capacity - Math.max(resp.amUsed, resp.pmUsed)))
        .finally(() => setIsLoading(false));
    }
  }, [props.area.id, props.date, isLoading, isRequested]);

  const selectArea = () => {
    if (props.area.active) {
      dispatch(selectAreaForReservation(props.area));
    } else {
      dispatch(warningNotification('The space is not available.'));
    }
  }

  return (
    <ContentCard onClick={selectArea}>
      <Content>
        <BodyLarge weight={600}>{props.area.label}</BodyLarge>
        {isLoading ? <SimpleSpinner size={24} /> : <BodyRegular>{t('whereabouts.seats-available', {remaining: remaining})}</BodyRegular>}
      </Content>
    </ContentCard>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`

const ContentCard = styled.div`
  background: ${Colours.white};
  border: 1px solid ${Colours.mildGrey};
  border-radius: 8px;
  width: 100%;
  padding: 24px 8px 24px 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin: 0 0 24px 0;
  position: relative;
`

const AreaList = styled.div`
`
