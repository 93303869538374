import { agent } from './agent';

export async function createOrUpdateOffice(request: CreateOfficeRequest): Promise<OfficeEntity> {
  return await agent.requests.post('/api/office', request);
}

export async function updateSingleOfficeEntity(request: OfficeEntity): Promise<OfficeEntity> {
  return await agent.requests.put('/api/office', request);
}

export async function deleteOffice(officeId: number): Promise<any> {
  return await agent.requests.del(`/api/office/${officeId}`);
}

export async function getOffices(): Promise<OfficeEntity[]> {
  return await agent.requests.get('/api/office');
}

export async function getRootOffices(): Promise<OfficeEntity[]> {
  return await agent.requests.get('/api/office/root');
}

export async function getOffice(id: number): Promise<OfficeEntity> {
  return await agent.requests.get(`/api/office/${id}`);
}

export async function getFavouriteOffices(): Promise<FavouriteOfficeEntity> {
  return await agent.requests.get(`/api/office/favourites`);
}

export async function toggleFavouriteOffice(officeId: number): Promise<any> {
  return await agent.requests.post(`/api/office/favourite/${officeId}`);
}

export async function checkOfficeUsage(officeId: number): Promise<OfficeUsageSummary> {
  return await agent.requests.get(`/api/office/check/${officeId}`);
}

export async function toggleFavouriteDesk(deskId: number): Promise<any> {
  return await agent.requests.post(`/api/desk/favourite/${deskId}`);
}

export async function getFavouriteDesks(): Promise<FavouriteDesk[]> {
  return await agent.requests.get(`/api/desk/favourites`);
}

export interface CreateOfficeRequest {
  id: number;
  name: string;
  postCode: string;
  capacity: number;
  parentId: number;
  floors: OfficeEntity[];
  officeType: OfficeType
  capacityEnabled: boolean;
  carParkCapacity?: number;
  officeBookingWindowWeeks?: number;
  restrictBookingWindowForTeamAdmins?: boolean;
}

export interface OfficeUsageSummary {
  offices: number;
  floors: number;
  areas: number;
  desks: number;
  favourites: number;
  floorPlans: boolean;
}

export interface UpdateOfficeRequest {
  id: number;
  name: string;
  postCode: string;
  capacity: number;
}

export interface DeleteOfficeRequest {
  id: number;
}

export interface OfficeEntity {
  id: number;
  label: string;
  postCode: string;
  capacity: number;
  companyId: string;
  parentId: number;
  officePlan: string;
  hasAreas: boolean;
  office: boolean;
  floor: boolean;
  area: boolean;
  deskBookingEnabled: boolean;
  floorPlanEnabled: boolean;
  order: number;
  active: boolean;
  officeBookingWindowWeeks: number;
  restrictBookingWindowForTeamAdmins: boolean;
  hasCarParkPlan: boolean;

  zoomOutLimit?: number;
  zoomInLimit?: number;
  startingZoom?: number;
  capacityEnabled?: boolean;
  carParkCapacity?: number;

}

export interface OfficeArea {
  id: number;
  officeid: number;
  label: string;
}

export interface OfficeEntityWithFavourite extends OfficeEntity {
  isFavourite: boolean;
  remainingSeats: string;
}

export interface FavouriteOfficeEntity {
  id: number;
  userId: string;
  officeId: number;
}

export enum OfficeType {
  OFFICE = 'OFFICE', FLOOR = 'FLOOR', AREA = 'AREA'
}

export interface EditableAreaEntity extends OfficeEntity {
  dirty?: boolean;
}

export const MockEditableAreaEntity = {
  id: 0,
  label: '',
  capacity: 0,
  companyId: '',
  parentId: 0,
  children: [],
  hasAreas: false,
  office: false,
  floor: false,
  area: true,
  deskBookingEnabled: false,
  floorPlanEnabled: false,
  order: 0,
  active: true,
}

export interface FavouriteDesk {
  rootOfficeLabel: string;
  parentOfficeLabel: string;
  favouriteDeskId: number;
  deskId: number;
  parentOfficeId: number;
  rootOfficeId: number;
}
