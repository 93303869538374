import { isInTeams } from "../utils/TeamsUtils";
import { WithTeamsAuth } from "./auth-hooks/WithTeamsAuth";
import { WithWebAuth } from "./auth-hooks/WithWebAuth";
import { useEffect } from "react";
import { PUBLIC_ROUTES } from "../App";
import { redirectTo, selectCurrentUser } from "../store/ducks/auth.duck";
import { useDispatch, useSelector } from "react-redux";
import { isOnTeamsTabAuthPage } from "../components/pages/login/LoginWithAzureInTeamsTab";

export function WithAuth() {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!!user && PUBLIC_ROUTES.includes(window.location.pathname) && window.location.pathname !== "/login/tab-auth") {
      dispatch(redirectTo('/whereabouts/team'));
    }
  }, [dispatch, user]);

  if (isOnTeamsTabAuthPage()) {
    console.log('No auth on teams tab page');
    return;
  }

  if (isInTeams()) {
    WithTeamsAuth();
  } else {
    WithWebAuth();
  }

}
