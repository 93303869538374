import React from 'react';
import styled from "styled-components";
import Colours from "./Colours";

export function SimpleSpinner(props: Props) {
  return (
    <Container size={props.size} className="simple-spinner" style={props.style ?? {}}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  )
}

interface Props {
  size: number;
  style?: any;
}

const Container = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  align-self: center;
  .lds-ring {
    position: absolute;
    display: inline-block;
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 60%;
    height: 60%;
    margin: ${props => `${props.size * .1}px`};
    border: ${props => `${props.size * .1}px`} solid ${Colours.green};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${Colours.green} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
