import React from 'react';
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { desktop, tablet } from "../../../UI/atoms/MediaQueries";
import { useTranslation } from "react-i18next";
import { HorizontalSpacer } from "../../../UI/atoms/VerticalSpacer";
import { Checkbox } from "../../../UI/atoms/Checkbox";
import { selectSelectedDeskIds, setSelectedDeskIds, setSidebarMode } from "../ducks/manageDesks.duck";
import { SidebarMode } from "../data/ManageDesks.model";
import { selectAllOfficeDesks } from "../../../../store/ducks/deskBooking.duck";
import { DialogIdentifiers, openDialog } from "../../../../store/ducks/dialog.duck";
import { AssignedTeamsAndTagsFilters } from "./floor-map-view/AssignedTeamsAndTagsFilters";
import { ActiveDeskControlForManageDesksSidebar } from "./floor-map-view/ActiveDeskControlForManageDesksSidebar";

export function ManageDesksSidebar() {
  const dispatch = useDispatch();
  const desks = useSelector(selectAllOfficeDesks);
  const selectedDesks = useSelector(selectSelectedDeskIds);
  const {t} = useTranslation();

  const selectAllDesks = (state: boolean) => {
    if (state) {
      dispatch(setSelectedDeskIds(desks.map(d => d.id)));
    } else {
      dispatch(setSelectedDeskIds([]));
    }
  }

  return (
    <Wrapper data-test="office-sidebar">
      <HeadlineSmall style={{marginBottom: 12}}>{t('manage-desks-sidebar.edit-view')}</HeadlineSmall>
      <BodyRegular>{t('manage-desks-sidebar.total-desks', {total: desks.length})}</BodyRegular>
      <BodyRegular>{t('manage-desks-sidebar.selected-desks', {selected: selectedDesks.length})}</BodyRegular>
      <HorizontalSpacer />

      <Checkbox style={{marginBottom: 12}}
                value={selectedDesks.length > 0 && selectedDesks.length === desks.length}
                onChange={selectAllDesks}
                disabled={desks.length === 0}
                text={'manage-desks-sidebar.select-all-desks'} />

      <OutlineButton style={{marginBottom: 12}}
                     text={'manage-desks-sidebar.assign-to-indi'}
                     disabled={selectedDesks.length === 0}
                     click={() => dispatch(openDialog(DialogIdentifiers.AssignDesksToIndividual))}
                     fullWidth={true} />

      <OutlineButton style={{marginBottom: 12}}
                     text={'manage-desks-sidebar.assign-to-team'}
                     disabled={selectedDesks.length === 0}
                     click={() => dispatch(openDialog(DialogIdentifiers.AssignDesksToTeam))}
                     fullWidth={true} />

      <OutlineButton style={{marginBottom: 24}}
                     text={'manage-desks-sidebar.assign-to-workgroup'}
                     disabled={selectedDesks.length === 0}
                     click={() => dispatch(openDialog(DialogIdentifiers.AssignDesksToWorkgroup))}
                     fullWidth={true} />

      <ActiveDeskControlForManageDesksSidebar />

      <OutlineButton style={{marginBottom: 24}}
                     text={'manage-desks-sidebar.reset-desks'}
                     disabled={selectedDesks.length === 0}
                     click={() => dispatch(openDialog(DialogIdentifiers.ResetDesks))}
                     fullWidth={true} />

      <HorizontalSpacer style={{marginBottom: 32}} />

      <AssignedTeamsAndTagsFilters />

      <OutlineButton  style={{marginBottom: 12}} text={'button.close'} fullWidth={true} click={() => dispatch(setSidebarMode(SidebarMode.ChooseOffice))} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    max-width: 100%;
  }
  @media (${tablet}) {
    margin-top: 0;
    max-width: 230px;
  }
  @media (${desktop}) {
    margin-top: 0;
    max-width: 295px;
  }
  .calendar {
    align-self: center;
  }
`
