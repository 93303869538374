import { BasicUserInfo, User } from '../models/user.models';
import { agent } from './agent';

export interface UserManagementStructure {
  userId: string;
  firstName: string;
  lastName: string;
  lineReports: BasicUserInfo[];
  reportingManager: BasicUserInfo[];
}

export interface AddLineReportRequest {
  parentUserId: string;
  childUserId: string;
}

export async function requestUsersHierarchy(userId: string): Promise<UserManagementStructure> {
  return await agent.requests.get(`/api/company-hierarchy/${userId}`);
}

export async function addLineReportRequest(subordinateUserId: string, reportingToUserId: string): Promise<UserManagementStructure> {
  return await agent.requests.post(`/api/company-hierarchy`, {
    parentUserId: reportingToUserId,
    childUserId: subordinateUserId,
  });
}

export async function deleteLineReportRequest(subordinateUserId: string, reportingToUserId: string): Promise<UserManagementStructure> {
  return await agent.requests.del(`/api/company-hierarchy/user/${subordinateUserId}/report/${reportingToUserId}`)
}

export async function getUsersLineReports(user?: User): Promise<BasicUserInfo[]> {
  if (!user) {
    throw new Error('No user');
  }
  return await agent.requests.get(`/api/company-hierarchy/${user?.id}/line-reports`)
}
