import React from 'react';
import { useMsal } from "@azure/msal-react";
import { resetAllLoginStorages } from "../../../utils/AuthUtils";
import { Column } from "../../UI/atoms/StructuralLayout";
import { CurrentUserAvatarAndName } from "../../UI/molecules/UserAvatarAndName";
import { Link } from "react-router-dom";
import { HeadlineXtraSmall } from "../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";

export function MicrosoftLogoutButton(props: Props) {
  const {instance} = useMsal();
  const {t} = useTranslation();

  const logout = () => {
    resetAllLoginStorages();
    props.onClick();
    instance.logoutRedirect({ postLogoutRedirectUri: "/logout/azure" })
  };

  return (
    <Column className="logoutMenuItem">
      <CurrentUserAvatarAndName className="logoutMenuItem__avatar" colour="blue" hideName={true}/>
      <Link to="#" onClick={() => logout()} className="logoutMenuItem__link noSelect">
        <HeadlineXtraSmall className="logoutMenuItem__text">{t('button.logout')}</HeadlineXtraSmall>
        <img src="/assets/icons/font/Logout.svg" alt="" />
      </Link>
    </Column>
  )
}

interface Props {
  onClick: () => any
}
