import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  endEditingWhereabouts,
  openRepeatWhereaboutsDialog,
  selectActiveDay,
  selectUserRequiredParking, setUserRequiresParking,
  updateMovement
} from '../../../../../../store/ducks/editMovements.duck';
import ActivityList from '../../../weekly-movements-setter/components/ActivityList';
import PeriodList from '../../../period-list/PeriodList';
import { BaseProps } from '../../../../../../models/Props.models';
import './edit-movements-content.scss';
import { Column, Row } from "../../../../atoms/StructuralLayout";
import { OutlineButton } from "../../../../atoms/buttons/OutlineButton";
import { BorderlessButton } from "../../../../atoms/buttons/BorderlessButton";
import { IconTypes } from "../../../../atoms/icon/Icon";
import { Checkbox } from "../../../../atoms/Checkbox";
import {
  selectIsCarParkingEnabled,
  selectIsRepeatWeeklyScheduleEnabled
} from "../../../../../../store/ducks/config.duck";
import { UNKNOWN_OPTION } from "../../../../../../services/WhereaboutOptions";

export default function EditMovementsContent(props: BaseProps) {
  const dispatch = useDispatch();
  const className = props.className || '';
  const activeDay = useSelector(selectActiveDay);
  const userRequiresParking = useSelector(selectUserRequiredParking);
  const isCarParkingEnabled = useSelector(selectIsCarParkingEnabled);
  const isRepeatScheduleEnabled = useSelector(selectIsRepeatWeeklyScheduleEnabled);

  const saveWhereabouts = () => {
    dispatch(endEditingWhereabouts());
  }

  const clearWhereabouts = () => {
    dispatch(updateMovement({selectedOption: UNKNOWN_OPTION, locationId: 0, stickOnDay: true}));
  }

  const onRequireParkingSpaceCheckboxChanged = (val: boolean) => {
    dispatch(setUserRequiresParking(val));
  }

  const openRepeatWhereaboutsDialogViaEditWhereaboutsDuck = () => {
    dispatch(openRepeatWhereaboutsDialog());
  }

  return (
    <Row className={`editMovements__content ${className}`.trim()} data-test="edit-movements-panel">
      <Column className="editMovements__periods">
        <PeriodList/>
        {isCarParkingEnabled && <>
          <Checkbox value={!!userRequiresParking}
                    className={"editMovements__carParking"}
                    onChange={onRequireParkingSpaceCheckboxChanged}
                    text={'whereabouts.require-parking-space'} />
        </>}
        {isRepeatScheduleEnabled && <>
          <OutlineButton click={openRepeatWhereaboutsDialogViaEditWhereaboutsDuck}
                         text={'whereabouts.require-weekly-schedule'} />
        </>}
        <OutlineButton dataTest="save-whereabouts-btn"
                       className="editMovements__saveBtn"
                       text="button.save-whereabouts"
                       click={saveWhereabouts}/>
        <BorderlessButton dataTest="clear-whereabouts-btn"
                          className="editMovements__clearBtn"
                          icon={IconTypes.Bin}
                          text="button.clear-day"
                          click={clearWhereabouts}/>
      </Column>
      <Column className="editMovements__activityList">
        <ActivityList activeDate={activeDay}/>
      </Column>
    </Row>
  )
}
