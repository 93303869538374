import React from 'react';
import { FireWardenBadge } from "../atoms/FireWardenBadge";
import { FirstAiderBadge } from "../atoms/FirstAiderBadge";
import { MentalHealthAiderBadge } from "../atoms/MentalHealthAiderBadge";
import { KeyHolderBadge } from "../atoms/KeyHolderBadge";
import styled from "styled-components";
import { MemberMovements, SimpleUserWithLocation } from "../../../models/movements.models";
import { desktopWide, smallTablet } from "../atoms/MediaQueries";
import { UserInfoV2 } from "../../pages/settings/pages/manage-users-page-v2/ducks/ManageUsers.model";

export function UserResponsibilityBadges(props: Props) {
  return (
    <Wrapper className={'userResponsibilityBadges'}>
      {(props?.fireWarden || props.user?.fireWarden) && <FireWardenBadge/>}
      {(props?.firstAider || props.user?.firstAider) && <FirstAiderBadge/>}
      {(props?.mentalHealthAider || props.user?.mentalHealthAider) && <MentalHealthAiderBadge/>}
      {(props?.keyHolder || props.user?.keyHolder) && <KeyHolderBadge/>}
    </Wrapper>
  )
}

interface Props {
  user?: SimpleUserWithLocation | MemberMovements | UserInfoV2;
  fireWarden?: boolean;
  firstAider?: boolean;
  mentalHealthAider?: boolean;
  keyHolder?: boolean;
}

const Wrapper = styled.div`
  margin-left: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2px;
  row-gap: 2px;
  @media (${smallTablet}) {
    display: flex;
    margin: 4px 0 4px 44px;
  }
  @media (${desktopWide}) {
    display: grid;
    margin: 0 0 0 16px;
  }
`
