import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BodyVerySmall } from '../../../atoms/fonts/Body';
import Colours from '../../../atoms/Colours';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../../store/ducks/auth.duck';

interface Props {
  children: any;
  count: number;
  forceShow?: boolean;
}

export default function NotificationCounter(props: Props) {
  const currentUser = useSelector(selectCurrentUser);
  const approvalsEnabled = useMemo(() => currentUser?.companyEntity.whereaboutsApprovalsEnabled || currentUser?.companyEntity.holidayApprovalEnabled, [currentUser]);
  if (props.forceShow || (approvalsEnabled && props.count > 0)) {
    return (
      <NotificationsCountWrapper>
        <NotificationsCount>
          <BodyVerySmall colour={Colours.white}>{props.count > 9 ? '9+' : props.count}</BodyVerySmall>
        </NotificationsCount>
        {props.children}
      </NotificationsCountWrapper>
    )
  } else {
    return props.children;
  }
}

const NotificationsCount = styled.div`
  position: absolute;
  top: -4px;
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: ${Colours.red};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px 1px rgba(63, 67, 74, 0.15);
  z-index: 2;
  p {
    font-size: 12px;
    font-weight: 700;
    user-select: none;
  }
`
const NotificationsCountWrapper = styled.div`
  position: relative;
  align-self: center;
`
