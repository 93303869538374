import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../UI/molecules/Dialog';
import { BodyError, BodyLarge, BodyRegular, BodyXXSmall } from '../../UI/atoms/fonts/Body';
import { Switch } from '../../UI/atoms/Switch';
import TextField from '../../UI/atoms/TextField';
import { addNewTeam, resetAddTeamDialog, selectAddTeamProcessingState, } from '../../../store/ducks/addTeamDialog.duck';
import DialogHeader from "../DialogHeader";
import { redirectTo, selectCurrentUser } from "../../../store/ducks/auth.duck";
import { Row } from '../../UI/atoms/StructuralLayout';
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { selectAccountInfo } from "../../../store/ducks/payments.duck";
import { LinkButton } from "../../UI/atoms/buttons/LinkButton";
import { isInTeams } from "../../../utils/TeamsUtils";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { BorderlessButton } from "../../UI/atoms/buttons/BorderlessButton";
import { useTranslation } from "react-i18next";
import { IconTypes } from "../../UI/atoms/icon/Icon";
import { hasUsedTeamAllocation } from "../../../services/AccountService";
import { closeDialog } from "../../../store/ducks/dialog.duck";

export default function AddTeamDialog() {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <Dialog isOpen={true} onClose={() => close()}>
      <div className="dialog__addMember">
        <AddTeamForm/>
      </div>
    </Dialog>
  )
}

function AddTeamForm() {
  const dialogState = useSelector(selectAddTeamProcessingState);
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState(dialogState.failedRequest.teamName);
  const [addUserToTeam, setAddUserToTeam] = useState(dialogState.failedRequest.addUserToTeam);
  const currentUser = useSelector(selectCurrentUser);
  const accountInfo = useSelector(selectAccountInfo);
  const dispatchNewTeamRequest = () => dispatch(addNewTeam({teamName, addUserToTeam}));
  const {t} = useTranslation();
  const openAddMemberDialog = () => {
    dispatch(resetAddTeamDialog());
    dispatch(closeDialog());
    dispatch(redirectTo('/manage/add-users'));
  }

  if (dialogState.processing) {
    return (
      <React.Fragment>
        <Row style={{justifyContent: 'center'}}>
          <LoadingSpinner className="dialog__spinner" hideBorder={true}/>
        </Row>
      </React.Fragment>
    )
  }

  if (dialogState.success) {
    return (
      <React.Fragment>
        <DialogHeader title="dialog-headers.team-created" image="/assets/images/woman-laptop-no-speech-bubble.svg" icon={IconTypes.Tick}/>
        <BodyLarge className="addTeamDialog__text">{t('dialog.add-people-to-team-message')}</BodyLarge>
        <PrimaryButton text="button.add-team-members"
                       click={() => openAddMemberDialog()}
                       fullWidth={true}
                       style={{marginBottom: 16}}
                       size="large"/>
        <BorderlessButton text="button.add-later"
                          click={() => dispatch(closeDialog())}
                          fullWidth={true}
                          size="large"/>
      </React.Fragment>
    )
  }

  if (hasUsedTeamAllocation(accountInfo)) {
    return <React.Fragment>
      <DialogHeader title={isInTeams() ? `dialog.max-teams-header-msteams` : `dialog.max-teams-header`}/>
      <BodyLarge className="addTeamDialog__text">{t(isInTeams() ? `dialog.max-teams-message-msteams` : `dialog.max-teams-message`)}</BodyLarge>
      {isInTeams() ? <PrimaryButton click={() => dispatch(closeDialog())} size="large" text="button.ok" /> :
        <LinkButton text="button.upgrade" onClick={() => dispatch(closeDialog())} link="/manage/payments" type="primary" size="large"/>}
    </React.Fragment>
  }

  if (!currentUser?.companyEntity) {
    return (
      <React.Fragment>
        <DialogHeader title="dialog.add-new-team" image="/assets/images/woman-laptop-no-speech-bubble.svg"/>
        <BodyRegular>{t('dialog.add-company-to-add-more-teams')}</BodyRegular>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <DialogHeader title="dialog.add-new-team" image="/assets/images/woman-laptop-no-speech-bubble.svg"/>

      <BodyError>{dialogState.error}</BodyError>

      <form onSubmit={(e) => e.preventDefault()}>
        <TextField dataTest="teamDialog-teamName-input"
                   label="textinput.team-name"
                   value={teamName}
                   onChange={(val: string) => setTeamName(val)}/>

        <div className="addTeamDialog__addSelf">
          <Switch label="dialog.add-self-to-team" value={addUserToTeam} switchClass="addTeamDialog__switch"
                  onChange={(val: boolean) => setAddUserToTeam(val)}/>
          <BodyXXSmall>{t('dialog.add-self-to-team-warning')}</BodyXXSmall>
        </div>

        <PrimaryButton text="button.create" click={() => dispatchNewTeamRequest()} buttonType="submit" fullWidth={true}/>
      </form>
    </React.Fragment>
  )
}
