import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { IconTypes } from "../../components/UI/atoms/icon/Icon";

const DURATION = 2500;
let timeoutId: any;

export interface NotificationState {
  isOpen: boolean;
  title?: string;
  message: string;
  icon?: IconTypes;
  iconColour?: string;
  type?: NotificationType
}

export enum NotificationType {
  Success, Error, Warning
}

export const initialNotificationState: NotificationState = {
  isOpen: true,
  message: '',
}

export const successNotification = (message: string, title?: string) => triggerNotification({message, title, type: NotificationType.Success});
export const warningNotification = (message: string, title?: string) => triggerNotification({message, title, type: NotificationType.Warning});
export const failureNotification = (message: string, title?: string) => triggerNotification({message, title, type: NotificationType.Error});

export const triggerNotification = createAsyncThunk(
  'notification/open',
  async (notification: any, thunkAPI) => {

    if (timeoutId) {
      clearTimeout(timeoutId);
      await thunkAPI.dispatch(reset());
    }

    thunkAPI.dispatch(show({
      title: notification.title,
      message: notification.message,
      type: notification.type,
      icon: notification.icon,
      iconColour: notification.iconColour || '',
      isOpen: true,
    }));

    setTimeout(() => thunkAPI.dispatch(reset()), DURATION);
  }
);

const slice = createSlice({
  name: 'notification',
  initialState: initialNotificationState,
  reducers: {
    show: (state, action) => {
      return { ...state, ...action.payload }
    },
    reset: () => initialNotificationState,
  },
  extraReducers: {
    // eslint-disable-next-line
    ['notification/open/fulfilled']: (state, action) => {
      return {
        ...state,
      }
    },
  }
})

export default slice.reducer;
export const {
  show,
  reset,
} = slice.actions;

// Selectors
export const selectIsNotification = (state: AppState) => state.notification;
