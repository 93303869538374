import React from 'react';
import { BodyRegular } from "../atoms/fonts/Body";
import { TeamEntity } from "../../../models/team.models";
import { Tag } from "../../../models/tags.models";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

export function TeamAndWorkGroupsRadioButtonList(props: Props) {
  const {teams, workgroups, onWorkgroupSelected, onTeamSelected, checkedId} = props;
  const {t} = useTranslation();

  if (teams.length === 0 && workgroups.length === 0) {
    return null;
  }

  return (
    <>

      <BodyRegular weight={600} style={{marginBottom: 16}}>{t('team-and-group-radio-button-list.filter-desks')}</BodyRegular>

      {teams.map((at: TeamEntity, key: number) => <RadioButtonLine className={'radioButton__line'} onClick={() => onTeamSelected(at)}>
        <input type="radio"
               id={`assignedTeam${key}`}
               className={'radioButton__input'}
               checked={at.id === checkedId}
               name="fav_language"
               value={at.id}
               onSelect={() => onTeamSelected(at)} />
        <label htmlFor="html" className={'radioButton__label' + (at.id === checkedId ? ` radioButton__label--checked` : '')}>{at.name}</label>
      </RadioButtonLine>)}

      {workgroups.map((t: Tag, key: number) => <RadioButtonLine className={'radioButton__line'} onClick={() => onWorkgroupSelected(t)}>
        <input type="radio"
               id={`assignedTag${key}`}
               className={'radioButton__input'}
               checked={t.id === checkedId}
               name="fav_language"
               value={t.id}
               onSelect={() => onWorkgroupSelected(t)} />
        <label htmlFor="html" className={'radioButton__label' + (t.id === checkedId ? ` radioButton__label--checked` : '')}>{t.label}</label>
      </RadioButtonLine>)}
    </>
  )
}

interface Props {
  teams: TeamEntity[];
  workgroups: Tag[];
  onTeamSelected: (team: TeamEntity) => any;
  onWorkgroupSelected: (team: Tag) => any;
  checkedId: string | number;
}


const RadioButtonLine = styled.div`
`
