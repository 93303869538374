import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { requestCompanyInfo } from "../../services/CompanyService";
import { AppState } from "../state/app.state";
import { setTeamStructureForMovements } from "./companyMovements.duck";
import { setPlatformConfig } from "./config.duck";
import { ConfigEntity } from "../../services/ConfigService";
import { updateEditCompanyConfig } from "../../components/pages/settings/pages/edit-company-page/editCompany.duck";
import { setNumberOfApprovals } from "./approvalRequests.duck";
import { TeamEntity } from "../../models/team.models";
import { getUsersLineReports } from "../../services/CompanyHierarchyService";
import { BasicUserInfo } from "../../models/user.models";

export interface CompanyStructureState {
  loading: boolean;
  hasCompanyStructureLoaded: boolean;
  teams: TeamEntity[];
  lineReports: BasicUserInfo[];
}

export const initialCompanyStructure: CompanyStructureState = {
  loading: false,
  hasCompanyStructureLoaded: false,
  teams: [],
  lineReports: [],
}

export const loadLineReports: any = createAsyncThunk(
  'companyStructure/loadLineReports',
  async (params: any, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const currentUser = state.auth.currentUser;

    if (currentUser && currentUser.companyEntity.lineManagementEnabled) {
      const lineReports = await getUsersLineReports(currentUser);
      return lineReports;
    }

    return [];
  }
);

export const loadCompanyStructure: any = createAsyncThunk(
  'companyStructure/loadCompanyStructure',
  async (force: any, thunkAPI) => {
    const structure = await requestCompanyInfo();
    thunkAPI.dispatch(setTeamStructureForMovements(structure.teamList));

    // handle config
    const config = new ConfigEntity(
      structure.config.holidayAllowance,
      structure.config.numberOfDesksAvailable,
      structure.config.companyHolidayAllowance,
      structure.config.holidayYearRecurringMonth,
      structure.config.holidayYearRecurringDay,
      structure.config.carParkingEnabled,
      structure.config.repeatWeeklySchedule,
      structure.config.repeatScheduleWeeksLimit,
      structure.config.restrictBookingWindowForTeamAdmins,
      structure.config.visitorBookingEnabled,
      structure.config.holidayYearEndDate,
      structure.config.holidayYearStartDate,
    );
    await thunkAPI.dispatch(setPlatformConfig(config));
    await thunkAPI.dispatch(updateEditCompanyConfig(config));
    await thunkAPI.dispatch(setNumberOfApprovals(structure.numberOfApprovals))

    return structure;
  }
);

const companyStructureSlice = createSlice({
  name: 'companyStructure',
  initialState: initialCompanyStructure,
  reducers: {
    reset: () => initialCompanyStructure,
    clearHolidays: () => initialCompanyStructure
  },
  extraReducers: {
    [loadCompanyStructure.pending]: (state) => ({...state, loading: true, hasCompanyStructureLoaded: false,}),
    [loadCompanyStructure.reject]: (state) => ({...state, loading: false, hasCompanyStructureLoaded: true,}),
    [loadCompanyStructure.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      hasCompanyStructureLoaded: true,
      teams: action.payload.teamList,
    }),

    [loadLineReports.pending]: (state) => ({...state, loading: true, lineReports: [],}),
    [loadLineReports.reject]: (state) => ({...state, loading: false, lineReports: [],}),
    [loadLineReports.fulfilled]: (state, action) => ({...state, loading: false, lineReports: action.payload,}),
  }
});

export const {
  reset,
} = companyStructureSlice.actions;
export default companyStructureSlice.reducer;

// Selectors
export const selectIsCompanyStructureLoading = (state: AppState) => state.companyStructure.loading;
export const selectHasCompanyStructureLoaded = (state: AppState) => state.companyStructure.hasCompanyStructureLoaded;
export const selectCurrentUsersLineReport = (state: AppState) => state.companyStructure.lineReports;
