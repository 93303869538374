import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import styled from "styled-components/macro";
import { fetchAllUsersHolidaysInCompany, fetchAllUsersHolidaysInTeam } from "../../../services/HolidayService";
import { CompanyHolidayUsageSummary, SimpleUserWithHolidayUsage } from "../../../models/Holiday.model";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isOfficeAdminUser, isTeamAdminUser } from "../../../utils/AccessControl";
import AccessRequired from "../settings/components/AccessRequired";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import moment, { Moment } from "moment";
import TeamDropDownList, { ALL_TEAMS } from "../../UI/organisms/team-drop-down-list/TeamDropDownList";
import { SortableTable } from "../../UI/molecules/SortableTable";
import { selectAllTeams } from "../../../store/ducks/config.duck";
import { DownloadHolidayReport } from "./components/DownloadHolidayReport";
import { DownloadAbsenceReport } from "./components/DownloadAbsenceReport";
import { Permission } from "../../../models/user.models";
import { Center } from "../../UI/atoms/StructuralLayout";
import { FlatContentCard } from "../../UI/atoms/FlatContentCard";
import { BodyLarge, BodyRegular } from "../../UI/atoms/fonts/Body";

function calculateUsedPercentage(used: number, totalAllowance: number) {
  if (used === 0 && totalAllowance === 0) {
    return '100%';
  } else if (used > totalAllowance) {
    return '100%+';
  } else {
    return `${Math.round((used / totalAllowance) * 100)}%`
  }
}

export function HolidaysUsageReportingPage(props: Props) {
  const [visibleHolidaysList, setVisibleHolidaysList] = useState<SimpleUserWithHolidayUsage[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedTeamId, setSelectedTeamId] = useState<string>(ALL_TEAMS);
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = useMemo(() => isCompanyAdminUser(currentUser) || isTeamAdminUser(currentUser), [currentUser]);
  const teams = useSelector(selectAllTeams);
  const currentYear = selectedDate?.year();

  const teamsToShowInDropDown = useMemo(() => {
    if (currentUser) {
      if (currentUser.role === Permission.CompanyAdmin || currentUser.role === Permission.OfficeAdmin) {
        return teams;
      } else if (currentUser.role === Permission.TeamAdmin) {
        return teams.filter(t => t.id === currentUser.teamEntity?.id)
      } else {
        return [];
      }
    }
  }, [teams, currentUser])

  const holidayUsageData = useMemo(() => {
    return visibleHolidaysList.map((user: SimpleUserWithHolidayUsage) => ({
      usedPercentage: calculateUsedPercentage(user.daysUsed, user.totalAllowance),
      user: `${user.firstName} ${user.lastName}`.trim(),
      used: user.daysUsed,
      allowance: user.totalAllowance,
      teamName: user.teamName,
    }))
  }, [visibleHolidaysList]);

  const holidayUsageColumns = useMemo(() => {
    return [
      {Header: 'reports-page.holidays-report.percentage', accessor: 'usedPercentage', },
      {Header: 'reports-page.holidays-report.user', accessor: 'user', isSorted: true },
      {Header: 'reports-page.holidays-report.days-used', accessor: 'used', },
      {Header: 'reports-page.holidays-report.allowance', accessor: 'allowance', },
      {Header: 'reports-page.holidays-report.team-name', accessor: 'teamName', },
    ];
  }, []);

  useEffect(() => {
    if (isAdmin) {
      if (selectedTeamId === ALL_TEAMS) {
        fetchAllUsersHolidaysInCompany(currentYear)
          .then((holidayUsages: CompanyHolidayUsageSummary) => setVisibleHolidaysList(holidayUsages.holidayUsages));
      } else if (!!selectedTeamId) {
        fetchAllUsersHolidaysInTeam(currentYear, selectedTeamId)
          .then((holidayUsages: CompanyHolidayUsageSummary) => setVisibleHolidaysList(holidayUsages.holidayUsages));
      }
    }
  }, [isAdmin, currentYear, selectedTeamId]);

  const setDate = (date: Moment) => {
    setSelectedDate(date);
  }

  const teamSelected = (teamId: string) => {
    setSelectedTeamId(teamId);
  }

  const initialTableState = {
    sortBy: [ { id: 'user', desc: false } ]
  }

  return (
    <ReportingContainer>
      <GoBackButton url={"/manage/reporting"} />
      {isAdmin && <>

        {isTeamAdminUser(currentUser) && !currentUser?.teamEntity ? <>
          <Center>
            <FlatContentCard>
              <BodyLarge>You are not assigned to a team</BodyLarge>
              <BodyRegular>As a team admin once you're assigned to a team you will be able to see team members holiday reports.</BodyRegular>
            </FlatContentCard>
          </Center>
        </> : <>
          <SpaceBetweenRow>
            <DateFlicker dateScale={DateScale.Year} updateGlobalDate={false} onChange={date => setDate(date)} />
            <TeamDropDownListContainer>
              <TeamDropDownList teams={teamsToShowInDropDown || []} includeAllTeams={isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser)} onSelect={teamSelected} />
            </TeamDropDownListContainer>
          </SpaceBetweenRow>
          <div>
            {isAdmin ? <>
              {holidayUsageColumns.length > 0 && <SortableTable data={holidayUsageData}
                                                                columns={holidayUsageColumns}
                                                                initialState={initialTableState} />}

              <DownloadHolidayReport selectedDate={selectedDate}
                                     selectedTeamId={selectedTeamId}
                                     setDate={setDate} />

              <DownloadAbsenceReport />
            </> : <>
              <AccessRequired visible={!isAdmin} teamAdminsRequired={true} companyAdminsRequired={true} />
            </>}
          </div>
        </>}
      </>}
    </ReportingContainer>
  )
}

interface Props {
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
`

const TeamDropDownListContainer = styled.div`
  .teamDropDownList {
    margin: 0;
  }
`
