import { agent } from './agent';
import {
  CreateTeamOptions,
  TeamActivities,
  TeamEvent,
  UsersActivities,
  UsersWorkBlock,
  WorkGroupActivities
} from "../models/wall-planner.models";
import moment from 'moment';
import { printDate } from "../utils/DateUtils";
import { companyMovementsForConjoiningTypes } from "../utils/Movement";
import { UNKNOWN_OPTION, WhereaboutsOption } from "./WhereaboutOptions";

function parseTeamActivities(whereaboutsOptions: WhereaboutsOption[], teamActivities: UsersActivities[]) {

  const getOptionFor = (id: number) => {
    return whereaboutsOptions.find(wo => wo.id === id) ?? UNKNOWN_OPTION;
  }

  const result = teamActivities.map((userActivities) => {

    let failSafeCounter = 0;
    let summerizedWorkList: UsersWorkBlock[] = [];

    // Enrich worklist with custom whereabouts
    let workList: UsersWorkBlock[] = userActivities.working.map((workBlock) => ({
      ...workBlock,
      amOption: getOptionFor(workBlock.amStatusId),
      pmOption: getOptionFor(workBlock.pmStatusId),
      fromDate: workBlock.date,
      toDate: workBlock.date,
    }));

    workList.sort((a, b) => moment(a.fromDate).isBefore(moment(b.fromDate)) ? 1 : -1)

    while (workList.length > 0) {
      let current: UsersWorkBlock | undefined = workList.pop();
      if (!current) {
        console.log('Unexpectedly had nothing left in work list');
        break;
      }

      // Add current movement to collection list
      current = {
        ...current,
        collection: [current]
      }

      while (workList.length > 0) {
        const next = workList[workList.length - 1];
        if (moment(next.fromDate).diff(moment(current?.toDate), 'days') <= 1 &&
          companyMovementsForConjoiningTypes(getOptionFor(next.amStatusId), getOptionFor(current?.pmStatusId)) && // Check that next is the same as this day
          current?.amStatusId === current?.pmStatusId && next.amStatusId === next.pmStatusId) { // On join on full and matching current and next days
          current = {
            ...current,
            toDate: next.fromDate,
            amStatusId: next.amStatusId,
            pmStatusId: next.pmStatusId,
            collection: [...(current.collection || []), next]
          };
          workList.pop();
        } else {
          break;
        }
      }

      summerizedWorkList.push(current);

      failSafeCounter++;
      if (failSafeCounter > 1000) {
        break;
      }
    }

    return {
      ...userActivities,
      working: summerizedWorkList,
    }
  });

  return result;
}

export async function requestWorkGroupActivitiesFor(whereaboutsOptions: WhereaboutsOption[], workGroupId: number, startDate: string): Promise<any> {
  const holidayOverview: WorkGroupActivities = await agent.requests.get(`/api/wall-planner/group/${workGroupId}/activities/${startDate}`, true);
  const result = parseTeamActivities(whereaboutsOptions, holidayOverview.workGroupActivities);
  return {
    ...holidayOverview,
    teamActivities: result,
  };
}

export async function requestTeamActivitiesFor(whereaboutsOptions: WhereaboutsOption[], teamId: string, startDate: string): Promise<any> {
  const holidayOverview: TeamActivities = await agent.requests.get(`/api/wall-planner/team/${teamId}/activities/${startDate}`, true);
  const result = parseTeamActivities(whereaboutsOptions, holidayOverview.teamActivities);
  return {
    ...holidayOverview,
    teamActivities: result,
  };
}

export async function updateTeamEventRequest(createTeamOptions: CreateTeamOptions) {
  await agent.requests.put('/api/wall-planner/team-event', {
    id: createTeamOptions.id,
    teamId: createTeamOptions.teamId,
    eventName: createTeamOptions.eventName,
    from: printDate(createTeamOptions.fromDate),
    to: printDate(createTeamOptions.toDate),
  });
}

export async function deleteTeamEventRequest(teamEventId: number) {
  await agent.requests.del(`/api/wall-planner/team-event/${teamEventId}`);
}

export async function createTeamEventRequest(createTeamOptions: CreateTeamOptions) {
  await agent.requests.post('/api/wall-planner/team-event', {
    teamId: createTeamOptions.teamId,
    eventName: createTeamOptions.eventName,
    from: printDate(createTeamOptions.fromDate),
    to: printDate(createTeamOptions.toDate),
  });
}

export async function getTeamEvents(teamId: string, dateString: string) {
  const teamEvents = (await agent.requests.get(`/api/wall-planner/team-event/${teamId}/${dateString}`))
    .map((event: any) => {
      return {
        id: event.id,
        eventName: event.eventName,
        teamId: event.teamId,
        from: moment(event.from),
        to: moment(event.to),
      }
    });
  return teamEvents;
}

export async function getTeamEvent(eventId: number): Promise<TeamEvent> {
  const event = await agent.requests.get(`/api/wall-planner/team-event/${eventId}`)
  return {
    id: event.id,
    eventName: event.eventName,
    teamId: event.teamId,
    from: moment(event.fromDate),
    to: moment(event.toDate),
  }
}
