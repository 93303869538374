import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Colours from "../../../UI/atoms/Colours";
import { CustomToolTip } from "./CustomToolTip";
import { CustomLegend } from './CustomLegend';

export function OfficeUsageLineChart(props: Props) {
  const {reportData} = props;
  return (
    <div style={{height: 400}}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={400}
          data={reportData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="-10%" stopColor={Colours.blue} stopOpacity={1}/>
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
            </linearGradient>
          </defs>
          <CartesianGrid stroke={Colours.veryLightGrey} strokeDasharray="5 5"/>
          <YAxis fontFamily={'proxima-nova'} fill={Colours.mildGrey} fontSize={18} axisLine={false} />
          <XAxis dataKey="name"
                 fontFamily={'proxima-nova'}
                 fill={Colours.mildGrey}
                 fontSize={18} axisLine={false} />
          <Tooltip content={<CustomToolTip />} />
          <Line type="monotone" dataKey="usage" stroke={Colours.blue} />
          <Line type="monotone" dataKey="capacity" stroke={Colours.green} />
          <Legend content={<CustomLegend />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

interface Props {
  reportData: any[];
}
