import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../UI/atoms/Checkbox';
import { BodyError, BodyRegular, BodySmall } from '../../UI/atoms/fonts/Body';
import TextField from '../../UI/atoms/TextField';
import { validateEmail } from '../../../utils/Validators';
import { login, selectLoginErrorMessage } from './login.duck';
import Colours from "../../UI/atoms/Colours";
import { Column } from '../../UI/atoms/StructuralLayout';
import { googleClientId } from "../../../services/EnvironmentVariables";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import { Divider } from "../../UI/atoms/Divider";
import GoogleLogin from "react-google-login";
import { AuthType, clearLoginStorage } from "../../../utils/AuthUtils";
import { Auth } from "../../../services/Auth";
import LoginErrorMessage from "./LoginErrorMessage";
import { TrackJS } from "trackjs";
import { MicrosoftLoginButton } from './MicrosoftLoginButton';
import { isInTeams } from "../../../utils/TeamsUtils";
import { MicrosoftTeamsLoginButton } from "./MicrosoftTeamsLoginButton";
import { getParameterByName } from "../../../utils/UrlUtils";
import { NotificationMessages } from "../../../models/notification.models";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { selectTenantInfo } from "../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const GOOGLE = 'Google';

export default function StandardLogin() {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState<boolean>(localStorage.getItem('rememberMe') === 'true');
  const [email, setEmail] = useState(localStorage.getItem('rememberMeEmail') || '');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [generalErrorCode, setGeneralErrorCode] = useState('');
  const [attemptedLoginMethod, setAttemptedLoginMethod] = useState('');
  const [showAllAuthMethods, setShowAllAuthMethods] = useState(false);
  const {t} = useTranslation();

  const errorMessage = useSelector(selectLoginErrorMessage);
  const tenantInfo = useSelector(selectTenantInfo);


  useEffect(() => {
    if (rememberMe && !!email) {
      localStorage.setItem('rememberMeEmail', email);
    }
  }, [rememberMe, email]);

  useEffect(() => {
    const notification = getParameterByName('not', window.location.href);
    if (notification) {
      setGeneralErrorCode(notification)
    }
  }, [dispatch]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!email || !validateEmail(email)) {
      setEmailError(i18next.t('login.valid-email-address-error') as string);
    } else {
      setEmailError('');
    }

    if (rememberMe) {
      localStorage.setItem('rememberMeEmail', email);
    }

    if (!password) {
      setPasswordError(i18next.t('login.enter-password', 'test') as string);
    } else {
      setPasswordError('');
    }

    if (!!email && !!password) {
      setAttemptedLoginMethod('Team Today');
      localStorage.setItem('type', AuthType.Standard);
      dispatch(login({
        email: email.trim(),
        password
      }));
    }
  }

  const onRememberMe = (value: boolean) => {
    setRememberMe(value);
    localStorage.setItem('rememberMe', `${value}`);
    if (value) {
      localStorage.setItem('rememberMeEmail', `${value}`);
    }
  }

  const responseGoogle = (response: any) => {
    clearLoginStorage();
    setAttemptedLoginMethod(GOOGLE);
    localStorage.setItem('type', AuthType.Google)
    localStorage.setItem('idToken', response.tokenId)
    localStorage.setItem('accessToken', response.accessToken)
    Auth.current(true)
      .then(() => window.location.href = '/whereabouts/team')
      .catch((err) => {
        TrackJS.track(err);
        console.error(err);
        setGeneralErrorCode('goog');
      });
  }

  const failedGoogleResponse = (response: any) => {
    TrackJS.track('failed google response')
    if (response) {
      TrackJS.track(response)
    }

    if (response && response.error === 'idpiframe_initialization_failed') {
    } else {
      setAttemptedLoginMethod(GOOGLE);
      setGeneralErrorCode('goog');
    }
  }

  if (tenantInfo && !showAllAuthMethods) {
    return <>
      {!!generalErrorCode && <LoginErrorMessage errorCode={generalErrorCode} loginMethod={attemptedLoginMethod} />}

      <BodyRegular style={{marginBottom: 16, marginTop: 16}}>{t('login.company-already-has-account')}</BodyRegular>

      {isInTeams() ? <MicrosoftTeamsLoginButton onError={setGeneralErrorCode} /> : <MicrosoftLoginButton onFailure={() => setGeneralErrorCode(NotificationMessages.azureLoginFailed)} />}

      <div className="auth__backLink">
        <div onClick={() => {}} className="noDecoration">
          <BodyRegular weight={600}
                       style={{cursor: 'pointer'}}
                       colour={Colours.blue}
                       onClick={() => setShowAllAuthMethods(true)}>{t('login.try-another-method')}</BodyRegular>
        </div>
      </div>
    </>
  }

  return (
    <>
      {!!generalErrorCode && <LoginErrorMessage errorCode={generalErrorCode} loginMethod={attemptedLoginMethod} />}

      <BodyRegular style={{marginBottom: 16}}>{t('login.signup-with-microsoft')} <strong>{isInTeams() ? t('login.microsoft') : t('login.microsoft-or-google')}</strong> {t('login.sync-own-calendars')}</BodyRegular>

      {isInTeams() ? <MicrosoftTeamsLoginButton onError={setGeneralErrorCode} /> : <MicrosoftLoginButton onFailure={() => setGeneralErrorCode(NotificationMessages.azureLoginFailed)} />}


      {!isInTeams() &&
        <GoogleLogin
          clientId={googleClientId}
          render={(renderProps) => (
            <OutlineButton dataTest="googleLogin"
                           className="login__button"
                           text={'login.with-google'}
                           borderColour={Colours.black}
                           click={renderProps.onClick}
                           fullWidth={true}
                           size="large"
                           imageUrl="/assets/icons/google-logo.svg"/>)}
          buttonText={'login.login'}
          onFailure={failedGoogleResponse}
          onSuccess={responseGoogle}
          scope="https://www.googleapis.com/auth/calendar.events"
          cookiePolicy={'single_host_origin'}
          // isSignedIn={true}
        />
      }

      <SpaceBetweenRow style={{alignItems: 'center', marginBottom: 16, marginTop: 16}}>
        <Divider width={68} color={Colours.mildGrey} />
        <BodyRegular style={{margin: 0}} weight={600}>{t('login.or-login-with-email')}</BodyRegular>
        <Divider width={68} color={Colours.mildGrey} />
      </SpaceBetweenRow>

      {!!errorMessage &&
      <Column>
        <BodyError data-test="loginPage-error" weight={400} className="login__error"><img src="/assets/icons/error.svg"
                                                                                          alt="Error"/>{errorMessage?.title ?? ''}
        </BodyError>
        <BodyError size={16} weight={400} className="login__error">{errorMessage?.description ?? ''}</BodyError>
      </Column>
      }
      <form className="auth__form" onSubmit={(e) => e.preventDefault()}>

        <TextField dataTest="emailInput"
                   type="email"
                   name="email"
                   label={'login.email-address'}
                   value={email}
                   onChange={e => setEmail(e)}
                   fullWidth={true}
                   error={emailError}
                   enableAutoComplete={true}/>

        <TextField dataTest="passwordInput"
                   type="password"
                   name="password"
                   label={'login.password'}
                   value={password}
                   fullWidth={true}
                   onChange={e => setPassword(e)}
                   error={passwordError}
                   enableAutoComplete={true}/>

        <div className="login__formControls">
          <Checkbox value={!!rememberMe} text={'login.remember'} onChange={(value: boolean) => onRememberMe(value)}/>
          <Link className="noDecoration" to="/forgot-password">
            <BodySmall colour={Colours.blue}>{t('forgot-password.forgot-password')}</BodySmall>
          </Link>
        </div>
        <PrimaryButton fullWidth={true}
                       dataTest="submit"
                       className="login__button"
                       text={'login.login'}
                       buttonType="submit"
                       click={handleSubmit}/>
      </form>

      <div className="auth__backLink">
        <Link to="/register" className="noDecoration">
          <BodyRegular weight={600} colour={Colours.blue}>{t('login.no-account')}</BodyRegular>
        </Link>
      </div>
    </>
  )
}
