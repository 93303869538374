import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  next,
  selectDeskBookingEnabled,
  selectHolidayManagementEnabled,
  updateDeskBooking,
  updateHolidayBooking
} from '../../../../store/ducks/onboarding.duck';
import { Column, Row } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { BodyLarge, BodyRegular } from '../../../UI/atoms/fonts/Body';
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";
import { PrimaryChip } from "../../../UI/atoms/Chip";
import styled from "styled-components/macro";

export default function OnboardingFeatures() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const isDeskBookingEnabled = useSelector(selectDeskBookingEnabled);
  const isHolidayManagementEnabled = useSelector(selectHolidayManagementEnabled);

  return (
    <div className="onboarding">
      <Column className="onboarding__content">

        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.choose-features')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 48}}>{t('onboarding.choose-features-description')}</BodyLarge>

        <Column style={{marginBottom: 48}}>
          <FeatureToggle title="onboarding.whereabouts-feature"
                         dataTest="onboarding-feature-whereabouts"
                         description="onboarding.whereabouts-feature-description"
                         onClick={() => dispatch(warningNotification('Whereabouts is required'))}
                         icon={'Compass'}
                         required={true}
                         isSelected={true} />

          <FeatureToggle title="onboarding.deskbooking-feature"
                         dataTest="onboarding-feature-deskBooking"
                         description="onboarding.deskbooking-feature-description"
                         onClick={() => dispatch(updateDeskBooking(!isDeskBookingEnabled))}
                         icon={'Chair'}
                         required={false}
                         isSelected={isDeskBookingEnabled} />

          <FeatureToggle title="onboarding.holiday-management-feature"
                         dataTest="onboarding-feature-holidays"
                         description="onboarding.holiday-management-feature-description"
                         onClick={() => dispatch(updateHolidayBooking(!isHolidayManagementEnabled))}
                         icon={'Holiday'}
                         required={false}
                         isSelected={isHolidayManagementEnabled} />
        </Column>

        <PrimaryButton dataTest="onboarding-features-next"
                size="large"
                text="button.continue"
                click={() => dispatch(next())}
                fullWidth={true}/>
      </Column>
    </div>
  )
}

interface FeatureToggleProps {
  icon: string;
  title: string;
  description: string;
  isSelected: boolean;
  onClick: any;
  dataTest: string;
  required: boolean;
}

function FeatureToggle(props: FeatureToggleProps) {
  const {t} = useTranslation();
  return <div className={`onboardingFeature ${props.isSelected ? 'onboardingFeature--selected' : ''}`.trim()}
              data-test={props.dataTest}
              onClick={props.onClick}>
    <Row>
      <div>
        <div className={`onboardingFeature__iconContainer ${props.isSelected ? 'onboardingFeature__iconContainer--selected' : ''}`.trim()}>
          <img src={`/assets/icons/font/${props.icon}.svg`} alt="" />
        </div>
      </div>
      <Column>
        <TitleRow>
          <BodyRegular weight={600}>{t(props.title)}</BodyRegular>
          {props.required && <PrimaryChip label={'onboarding.required'} onClick={() => {}} size={'verysmall'} />}
        </TitleRow>
        <BodyRegular>{t(props.description)}</BodyRegular>
      </Column>
      {props.isSelected && <SelectedIcon icon={IconTypes.Tick} circle={true} color="blue" size="small" />}
    </Row>
  </div>;
}

const TitleRow = styled.div`
  margin-bottom: 8px;
  .chip {
    height: 20px;
    padding: 0 12px;
  }
`

const SelectedIcon = styled<any>(Icon)<any>`
  top: 14px;
  right: 12px;
  position: absolute;
`
