import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectIsFreeAccount, selectNumberOfRemainingUsers } from "../../../../../../store/ducks/payments.duck";
import { Center, Row } from "../../../../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { isInTeams } from "../../../../../../utils/TeamsUtils";
import { SuggestionMessage } from "../../../../../UI/molecules/SuggestionMessage";
import { setAddTeamMemberView } from "../ducks/add-users.duck";
import { AddTeamMemberView } from "../ducks/AddUsers.model";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { useTranslation } from "react-i18next";
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";

export function AddUserSuccess(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const remainingUsers = useSelector(selectNumberOfRemainingUsers);
  const isFreeAccount = useSelector(selectIsFreeAccount);
  return (
    <>
      <Row className="addMember__header">
        <Center>
          <img style={{width: 180}} className="addMember__image" src="/assets/images/onboarding-invite-sent-image.svg" alt="" aria-hidden={true} />
        </Center>
      </Row>
      <Icon icon={IconTypes.Tick}
            style={{marginBottom: 16}}
            circle={true}
            color={'blue'}
            size={'large'} />
      <HeadlineLarge style={{marginBottom: 16}}>{t('team.team-member-invited')}</HeadlineLarge>

      <BodyLarge style={{marginBottom: 32}}>{t('payments.users-available-message', {remainingUsers: remainingUsers})}</BodyLarge>

      <PrimaryButton dataTest="addAnotherButton"
                     size="large"
                     style={{marginBottom: 24}}
                     fullWidth={true} text="button.add-another-team-member"
                     click={() => dispatch(setAddTeamMemberView(AddTeamMemberView.AddUser))}/>

      <OutlineButton dataTest="doneButton"
                     size="large"
                     fullWidth={true}
                     text="button.done-for-now"
                     link={"/whereabouts/team"}/>

      {isFreeAccount && !isInTeams() && <SuggestionMessage
        style={{padding: 24, marginTop: 32}}
        title="did-you-know"
        linkMessage="payments.upgrade-account-in-settings"
        content="payments.with-as-many-users"
        link="/manage/payments"/>}
    </>
  )
}

interface Props {
}
