import styled from "styled-components/macro";
import TextField from "../../UI/atoms/TextField";
import Colours from "../../UI/atoms/Colours";
import { Row } from "../../UI/atoms/StructuralLayout";
import { tablet } from "../../UI/atoms/MediaQueries";

export const SpaceBetweenRow = styled<any>(Row)`
  justify-content: space-between;
  margin-bottom: 12px;
`

export const ConfigurationSection = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  position: relative;
  margin: 0 0 24px 0;
  padding-bottom: 24px;
  &:last-child {
    border-bottom: none;
  }
`

export const ConfigurationOptions = styled.div`
  opacity: .5;
`

export const ConfigurationIcon = styled.img`
  height: 24px;
`

export const ConfigurationList = styled.ul`
  list-style-position: inside;
  margin: 8px 0 16px -36px;
  @media (${tablet}) {
    margin: 8px 0 16px 0;
  }
`

export const DeskAvailabilityCount = styled<any>(TextField)`
  input {
    width: 100px;
    text-align: center;
  }
  p {
    display: flex;
    align-items: center;
  }
`
