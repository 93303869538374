import React from 'react';
import GoogleLogin from "react-google-login";
import { googleClientId } from "../../../services/EnvironmentVariables";
import ReactGA from "react-ga";
import { AuthType, clearLoginStorage } from "../../../utils/AuthUtils";
import { agent } from "../../../services/agent";
import { setSuccess } from "../../../store/ducks/registration.duck";
import { setAuthError, setAuthLoading } from "../../../store/ducks/auth.duck";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { emitRegisterEvent } from "../../../services/RegisterService";

export function RegisterWithGoogleButton(props: Props) {
  const dispatch = useDispatch();

  const registerWithGoogle = async (response: any) => {
    ReactGA.event({category: 'NewUser', action: 'Registered with Google success'});
    try {
      emitRegisterEvent();
      dispatch(setAuthLoading(true));
      localStorage.setItem('type', AuthType.Google);
      localStorage.setItem('idToken', response.tokenId);
      localStorage.setItem('accessToken', response.accessToken);
      await agent.requests.post('/register/google', {}, true);
      dispatch(setSuccess(true));
      window.location.href = '/whereabouts/team';
    } catch (e: any) {
      clearLoginStorage();
      dispatch(setAuthError(e.message));
    } finally {
      dispatch(closeDialog());
      dispatch(setAuthLoading(false));
    }
  }

  const failedGoogleResponse = async (response: any) => {
    console.error(response)
    TrackJS?.track(response);
  }

  return (
    <>
      <GoogleLogin
        clientId={googleClientId}
        render={(renderProps) => (
          <PrimaryButton click={renderProps.onClick} fullWidth={true} size="large" text="button.continue-to-signup"/>
        )}
        buttonText="login.login"
        redirectUri={`${window.location.origin}/login/google`}
        onFailure={failedGoogleResponse}
        onSuccess={registerWithGoogle}
        scope="https://www.googleapis.com/auth/calendar.events"
        cookiePolicy={'single_host_origin'}
      />
    </>
  )
}

interface Props {
}
