import React, { useEffect, useMemo } from 'react';
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { useDispatch, useSelector } from "react-redux";
import AccessRequired from "../../components/AccessRequired";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { canManageWorkGroups } from "../../../../../utils/AccessControl";
import { TagManager } from "./components/TagManager";
import { loadAllUsers, setActiveView, UserManagementView } from "../../../../../store/ducks/userManagement.duck";
import styled from "styled-components";
import Colours from "../../../../UI/atoms/Colours";
import { getParameterByName } from "../../../../../utils/UrlUtils";
import Layout from "../../../../UI/organisms/layout/Layout";

export default function TagManagementPage() {
  document.body.style.backgroundColor = 'white';
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => canManageWorkGroups(currentUser), [currentUser]);
  const page = getParameterByName('view', window.location.href);

  useEffect(() => {
    dispatch(loadAllUsers());

    if (page && page === UserManagementView.TagManager) {
      dispatch(setActiveView(UserManagementView.TagManager));
    } else {
      dispatch(setActiveView(UserManagementView.UserManager));
    }

    const container = document.getElementsByClassName('managePage__container');
    if (container && container[0]) {
      // @ts-ignore
      container[0].style.backgroundColor = Colours.white;
    }

  }, [dispatch, page]);

  useEffect(() => {
    if (page) {

    }
  }, [page])

  return (
    <Layout hideFooter={true}>
      <div className="management">
        <HeaderRow>
          <GoBackButton url="/manage"/>
        </HeaderRow>

        <AccessRequired teamAdminsRequired={true} companyAdminsRequired={true} visible={!hasAccess} />

        <TagManager />
      </div>
    </Layout>
  );
}

const HeaderRow = styled.div`
  margin: 16px 0 0 0;
`
