import React from 'react';
import { BodyRegular, BodyXXSmall } from "../../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { GreenPill } from "../../atoms/Pill";
import { HeadlineSmall } from "../../atoms/fonts/Headline";

interface Props {
  style?: any;
}

export function UserImportSpreadsheetMessage(props: Props) {
  const {t} = useTranslation();

  return (
    <div style={props?.style ?? {}}>
      <GreenPill style={{marginBottom: 16}}><BodyXXSmall>{t('settings.spreadsheet.trial')}</BodyXXSmall></GreenPill>
      <HeadlineSmall style={{marginBottom: 4}}>{t('settings.spreadsheet.title')}</HeadlineSmall>
      <BodyRegular style={{marginBottom: 4}} weight={600}>{t('settings.spreadsheet.subtitle')}</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.spreadsheet.description')}</BodyRegular>
    </div>
  )
}
