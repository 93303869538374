import React from 'react';
import Dialog from '../../UI/molecules/Dialog';
import './permission-needed-dialog.scss';
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsPermissionNeededDialogOpen,
  selectPermissionType,
  setIsPermissionNeededDialogOpen
} from "./permissionNeeded.duck";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";

export default function PermissionNeededDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsPermissionNeededDialogOpen);
  const permissionType = useSelector(selectPermissionType);

  // @ts-ignore
  const message = script[permissionType]?.message ?? '';

  if (!isOpen) {
    return null;
  }

  const close = () => {
    dispatch(setIsPermissionNeededDialogOpen({isOpen: false}))
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => close()}>
      <div className="permissionNeeded">
        <HeadlineMedium className="permissionNeeded__title">Permission needed</HeadlineMedium>
        <div className="permissionNeeded__content">
          <BodyRegular className="permissionNeeded__info" style={{marginBottom: 16}}>{message}</BodyRegular>
        </div>
        <PrimaryButton text="button.done-for-now"
                       size="large"
                       fullWidth={true}
                       click={() => close()}/>
      </div>
    </Dialog>
  )
}

const script = {
  "TEAM_ADMIN": {
    message: 'You need to be a Company Admin or a Team Admin to add a user.',
    action: 'add a user to this team from their account',
    access: ['Company Admin', 'Team Admin'],
  },
  "COMPANY_ADMIN": {
    message: 'You need to be a Company Admin to add another team.',
    action: 'add another team from their account',
    access: ['Company Admin'],
  },
  "CREATE_COMPANY": {
    message: 'You need to be a Company Admin to set up a company.',
    action: 'set up a company from their account',
    access: ['Company Admin'],
  }
}
