import { useCallback, useEffect, useState } from 'react';
import { getWeeklyTeamNotes, WeekNote } from "../../../../services/WeekNotesService";
import { Moment } from "moment";

export function WithWeeklyNotes(teamIds: string[], date: Moment) {
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(true);
  const [notes, setNotes] = useState<WeekNote[]>([])

  const makeRequest = useCallback(async () => {
    setRequested(true);
    setLoading(true);
    try {
      const resp = await getWeeklyTeamNotes(teamIds, date)
      setNotes(resp)
    } finally {
      setLoading(false);
    }
  }, [date, teamIds])

  useEffect(() => {
    if (!teamIds || !date || loading || requested) return;
    setRequested(true);
    setLoading(true);
    makeRequest();
  }, [date, loading, makeRequest, requested, teamIds])

  useEffect(() => {
    if (teamIds && date) {
      setRequested(false);
    }
  }, [teamIds, date]);

  return {
    loading: loading,
    hasRequested: requested,
    notes: notes,
    load: makeRequest,
  }
}

