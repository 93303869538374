import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { UserItem } from "./UserItem";
import { UserListViewControl } from "./UserListViewControl";
import { UserInfoV2 } from "../ducks/ManageUsers.model";
import { useSelector } from "react-redux";
import { selectUsers } from "../ducks/manage-users.duck";
import { ManageUsersPaginationControls } from "./ManageUsersPaginationControls";

export function ManageUsersList(props: Props) {
  const users = useSelector(selectUsers);

  return (
    <Container>
      <UserListViewControl />
      {users.map((user: UserInfoV2, key) => <UserItem key={key} user={user} />)}
      <ManageUsersPaginationControls />
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  background-color: ${Colours.veryLightGrey};
  padding: 20px 16px;
  width: 100%;
`
