import styled from "styled-components/macro"
import { FadeAndPopOnLoad } from "../../../animations/AnimationVariants";
import { desktopSmall, tablet } from "./MediaQueries";

export const MountedContentCard = styled<any>(FadeAndPopOnLoad)`
  width: 100%;
  max-width: 580px;
  background-color: white;
  padding: 0 16px 66px 16px;
  margin-bottom: 16px;
  @media (${tablet}) {
    border-radius: 12px;
    box-shadow: 0 10px 35px 2px rgba(0, 87, 255, 0.15);
    padding: 32px 60px;
    margin: ${props => props.centre ? '0 auto' : '0'};
    margin-bottom: 16px;
  }
  @media (${desktopSmall}) {
    padding: 48px 92px;
  }
`

export const MountedContentCardContent = styled.div`
  width: 100%;
  max-width: 340px;
`
