import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import DialogHeader from "./DialogHeader";
import Dialog from "../UI/molecules/Dialog";
import { selectTagsLoading } from "../../store/ducks/userTags.duck";
import { Tag } from "../../models/tags.models";
import { TrackJS } from "trackjs";
import { canChangeUsersWorkGroup } from "../../utils/AccessControl";
import { selectCurrentUser } from "../../store/ducks/auth.duck";
import AccessRequired from "../pages/settings/components/AccessRequired";
import { Column } from "../UI/atoms/StructuralLayout";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";
import { LoadingOverlay } from "../UI/molecules/LoadingOverlay";
import TextField from "../UI/atoms/TextField";
import { BasicUserInfo } from "../../models/user.models";
import { UserAvatarAndName } from "../UI/molecules/UserAvatarAndName";
import { Checkbox } from "../UI/atoms/Checkbox";
import styled from "styled-components";
import { findUserRequest } from "../../services/UserService";
import { createUserTagRequest, getAllUsersInTag, removeUserTagRequest } from "../../services/Tags";
import { BodyRegular } from "../UI/atoms/fonts/Body";
import Colours from "../UI/atoms/Colours";
import { selectCalendarDate, setCalendarDate } from "../../store/ducks/dashboard.duck";
import moment from "moment";
import { safePrintError } from "../../utils/error-helper";

interface Props {
  payload?: { tag: Tag }
}

export default function EditUsersInTagDialog(props: Props) {
  const dispatch = useDispatch();
  const tagsLoading = useSelector(selectTagsLoading);
  const currentUser = useSelector(selectCurrentUser);
  const calendarDate = useSelector(selectCalendarDate);
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const hasAccess = useMemo(() => canChangeUsersWorkGroup(currentUser), [currentUser]);

  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<BasicUserInfo[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<BasicUserInfo[]>([]);

  useEffect(() => {
    if (props.payload?.tag) {
      // fetch tag members
      setLoading(true);
      getAllUsersInTag(props.payload.tag.id)
        .then(setSelectedUsers)
        .finally(() => setLoading(false));
    }
  }, [props]);

  useEffect(() => {
    const id = setTimeout(() => {
      findUserRequest(searchQuery)
        .then((result: BasicUserInfo[]) => setUsers(result));
    }, 250);

    return () => {
      clearTimeout(id);
    }
  }, [searchQuery]);

  const close = () => {
    if (hasChanged) {
      dispatch(setCalendarDate(moment(calendarDate)));
    }
    dispatch(closeDialog());
  }

  const onUserSelect = async (selectedUser: BasicUserInfo) => {
    if (!props.payload?.tag) return;
    setLoading(true);
    try {
      if (selectedUsers.includes(selectedUser)) {
        await removeUserTagRequest(selectedUser.userId, props.payload.tag.id);
        setSelectedUsers(selectedUsers.filter(su => su.userId !== selectedUser.userId));
      } else {
        await createUserTagRequest(selectedUser.userId, props.payload.tag.id);
        setSelectedUsers([...selectedUsers, selectedUser]);
      }
      setHasChanged(true);
    } catch (e: any) {
      safePrintError(e, dispatch, 'Failed to add user to group');
      TrackJS?.track(e);
    } finally {
      setLoading(false);
    }
  }

  const onChange = (val: string) => setSearchQuery(val);

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="dialog-headers.add-group-member" />
      <BodyRegular style={{marginBottom: 16}}>To group: {props.payload?.tag.label ?? '???'}</BodyRegular>
      <AccessRequired teamAdminsRequired={false} companyAdminsRequired={true} visible={!hasAccess} />

      {(loading || tagsLoading) && <LoadingOverlay />}

      {hasAccess && <>
        <SearchBar onIconClick={() => setSearchQuery('')} icon={searchQuery.length > 0 ? "Close" : undefined} value={searchQuery} onChange={onChange} />
        <SearchResults className={"searchDialog__results"}>

          {(searchQuery.length === 0 ? selectedUsers : users).map((user: BasicUserInfo, key: number) => (
            <UserInfoRow key={key} className={'searchDialog__result'}>
              <UserAvatarAndName firstName={user.firstName} lastName={user.lastName} />
              <Checkbox value={!!selectedUsers.find(su => su.userId === user.userId)} onChange={() => onUserSelect(user)} />
            </UserInfoRow>
          ))}

        </SearchResults>
      </>}



      <Column>
        <PrimaryButton fullWidth={true} click={close} text="button.done" />
      </Column>
    </Dialog>
  )
}

const SearchBar = styled(TextField)<any>`
  .icon {
    right: 12px;
    padding: 2px;
    border-radius: 2px;
    transition: background-color .2s;
    cursor: pointer;
    &:hover {
      background-color: ${Colours.opaqueMildGrey};
    }
  }
  .icon--medium {
    width: unset;
    height: unset;
  }
`

const SearchResults = styled.div`
  margin-bottom: 32px;
  height: 250px;
  overflow-y: auto;
`

const UserInfoRow = styled.div<any>`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  .userAvatarAndName {
    cursor: pointer;
  }
`
