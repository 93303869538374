import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectAllOfficeDesks } from "../../../../../store/ducks/deskBooking.duck";
import { selectAllTeams } from "../../../../../store/ducks/config.duck";
import { TeamEntity } from "../../../../../models/team.models";
import { selectAllTags } from "../../../../../store/ducks/userTags.duck";
import { Tag } from "../../../../../models/tags.models";
import styled from "styled-components/macro";
import { setSelectedDeskIds } from "../../ducks/manageDesks.duck";
import { TeamAndWorkGroupsRadioButtonList } from "../../../../UI/organisms/TeamAndWorkGroupsRadioButtonList";
import { naturalComparator } from "../../../../../utils/ArrayUtils";

export function AssignedTeamsAndTagsFilters(props: Props) {
  const dispatch = useDispatch();
  const desks = useSelector(selectAllOfficeDesks);
  const teams = useSelector(selectAllTeams);
  const workgroups = useSelector(selectAllTags);
  const [checkedId, setCheckedId] = useState<string | number>(0)

  const assignedGroups: any = useMemo(() => {
    let teamIds: string[] = [], workgroupIds: number[] = [], userIds: string[] = [];

    desks.forEach(desk => {
      desk.workgroupIds.forEach(workgroupId => {
        if (!workgroupIds.includes(workgroupId)) workgroupIds.push(workgroupId);
      });
      desk.teamIds.forEach(teamId => {
        if (!teamIds.includes(teamId)) teamIds.push(teamId);
      });
      desk.users.forEach(user => {
        if (!userIds.includes(user.userId)) userIds.push(user.userId);
      });
    })

    return {teamIds, workgroupIds, userIds}
  }, [desks]);

  const assignedTeams = useMemo(() => {
    return assignedGroups.teamIds
      .map((teamId: string) => teams.find(t => t.id === teamId))
      .filter((t: TeamEntity) => !!t)
      .sort((a: TeamEntity, b: TeamEntity) => naturalComparator(a, b, 'name'));
  }, [assignedGroups, teams]);

  const assignedWorkgroups = useMemo(() => {
    return assignedGroups.workgroupIds
      .map((workgroupId: number) => workgroups.find(t => t.id === workgroupId))
      .filter((t: Tag) => !!t)
      .sort((a: Tag, b: Tag) => naturalComparator(a, b, 'label'));
  }, [assignedGroups, workgroups]);

  const onTeamSelected = (team: TeamEntity) => {
    if (checkedId === team.id) {
      setCheckedId(0);
      dispatch(setSelectedDeskIds([]));
    } else {
      setCheckedId(team.id);
      const selectedDesksIds = desks.filter(d => d.teamIds.includes(team.id)).map(d => d.id);
      dispatch(setSelectedDeskIds(selectedDesksIds));
    }
  }

  const onWorkgroupSelected = (tag: Tag) => {
    if (checkedId === tag.id) {
      setCheckedId(0);
      dispatch(setSelectedDeskIds([]));
    } else {
      setCheckedId(tag.id);
      const selectedDesksIds = desks.filter(d => d.workgroupIds.includes(tag.id)).map(d => d.id);
      dispatch(setSelectedDeskIds(selectedDesksIds));
    }
  }

  return (
    <Wrapper>

      <TeamAndWorkGroupsRadioButtonList teams={assignedTeams}
                                        workgroups={assignedWorkgroups}
                                        onWorkgroupSelected={onWorkgroupSelected}
                                        checkedId={checkedId}
                                        onTeamSelected={onTeamSelected} />

    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
