import React from 'react';
import { BodyRegular } from '../../../UI/atoms/fonts/Body';
import { HeadlineVerySmall } from '../../../UI/atoms/fonts/Headline';
import Colours from "../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";

interface Props {
  teamAdminsRequired?: boolean;
  companyAdminsRequired?: boolean;
  officeAdminsRequired?: boolean;
  departmentAdminRequired?: boolean;
  visible: boolean;
}

export default function AccessRequired(props: Props) {
  const {visible, teamAdminsRequired, officeAdminsRequired} = props;
  const {t} = useTranslation();

  if (!visible) return null;

  return (
    <div data-test="access-required" className="management__accessRequired">
      <HeadlineVerySmall color={Colours.blue}>{t('admin-access-required')}</HeadlineVerySmall>
      <BodyRegular>{t('insufficient-rights')}</BodyRegular>

      <BodyRegular>{t('Ask a Company Admin')} {props?.departmentAdminRequired ? t('or your Department Admin') : ''} {officeAdminsRequired ? t('or your Office Admin') : ''}{teamAdminsRequired ? t('or your Team Admin') : ''} {t('to change your permissions or do it for you.')}</BodyRegular>
    </div>
  )
}
