import React from 'react';
import { CompanyViewContentCardWrapper } from "./TeamContentCard";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../atoms/fonts/Headline";
import { OutlineButton } from "../atoms/buttons/OutlineButton";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DialogIdentifiers, openDialog } from "../../../store/ducks/dialog.duck";

export function CreateTeamContentCard(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onClick = () => {
    dispatch(openDialog(DialogIdentifiers.CreateTeamDialog));
  }
  return (
    <Wrapper>
      <HeadlineSmall style={{marginBottom: 16}}>{t('create-new-team')}</HeadlineSmall>
      <OutlineButton size={"medium"}
                     text={"button.create-new-team"}
                     click={onClick} />
    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled<any>(CompanyViewContentCardWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-color: transparent !important;
  cursor: default;
  box-shadow: 0 7px 11px 2px rgba(142, 149, 163, 0.15);
`
