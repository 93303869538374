import React from 'react';
import { HeadlineLarge } from "../../../../../UI/atoms/fonts/Headline";
import { isInTeams } from "../../../../../../utils/TeamsUtils";
import { BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import { Trans, useTranslation } from "react-i18next";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { setAddTeamMemberView } from "../ducks/add-users.duck";
import { AddTeamMemberView } from "../ducks/AddUsers.model";

export function UserLimitReached(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onClick = () => {
    dispatch(setAddTeamMemberView(AddTeamMemberView.AddUser));
  }
  return (
    <>
      <HeadlineLarge style={{marginBottom: 12}}>{t('settings.member-limit-reached')}</HeadlineLarge>

      {isInTeams() ? <>
        <BodyLarge style={{marginBottom: 24}}>{t('settings.account-full-in-teams')}</BodyLarge>
      </> : <>
        <BodyLarge style={{marginBottom: 24}}><Trans i18nKey={'settings.account-full-normal'} /></BodyLarge>

        <div style={{marginBottom: 16}}>
          <OutlineButton dataTest="upgradeButton"
                         size="large"
                         text="button.upgrade-my-account"
                         click={onClick}
                         fullWidth={true}/>
        </div>
      </>}

      <PrimaryButton dataTest="doneButton"
                     size="large"
                     text="button.done-for-now"
                     click={onClick}
                     fullWidth={true}/>
    </>
  )
}

interface Props {
}
