import React, { useState } from 'react';
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { BodySmall } from "../../../../../UI/atoms/fonts/Body";
import Colours from "../../../../../UI/atoms/Colours";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCheckedUsers, selectInfo } from "../ducks/manage-users.duck";
import { warningNotification } from "../../../../../../store/ducks/notification.duck";
import { DialogIdentifiers, openDialog, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";
import { AddTeamMemberButton } from "../../../../../UI/atoms/buttons/AddTeamMemberButton";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isOfficeAdminUser } from "../../../../../../utils/AccessControl";
import { exportAllUsersToCsv } from "../../../../../../services/ReportingService";

export function ManageUsersSidebar(props: Props) {
  const {t} = useTranslation();
  const checkedUsers = useSelector(selectAllCheckedUsers);
  const info = useSelector(selectInfo);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);

  const canExportUsers = isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser);

  const onAssignToOfficeClick = () => {
    if (checkedUsers.length === 0) {
      dispatch(warningNotification('First select some users'));
      return;
    }
    dispatch(openDialogWithPayload({
      payload: { },
      activeDialog: DialogIdentifiers.AssignToOffice
    }));
  }

  const onAssignToTeamClick = () => {
    if (checkedUsers.length === 0) {
      dispatch(warningNotification('First select some users'));
      return;
    }
    dispatch(openDialogWithPayload({
      payload: { },
      activeDialog: DialogIdentifiers.AssignToTeam
    }));
  }

  const onAssignToWorkgroupClick = () => {
    if (checkedUsers.length === 0) {
      dispatch(warningNotification('First select some users'));
      return;
    }
    dispatch(openDialogWithPayload({
      payload: { },
      activeDialog: DialogIdentifiers.AssignToWorkgroup
    }));
  }

  const onExportUsersClick = async () => {
    setLoading(true);
    try {
      dispatch(warningNotification('Loading... this will take some time.'))
      await exportAllUsersToCsv(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <SidebarContainer>
      <HeadlineSmall style={{marginBottom: 12}}>{t('manage-users-page.account')}</HeadlineSmall>
      <BodySmall weight={400}>{t('manage-users-page.user-count', {count: info?.totalUsers ?? 0})}</BodySmall>
      <BodySmall style={{marginBottom: 16}} weight={400} colour={Colours.darkGrey}>{t('manage-users-page.free-spaces-count', {count: info?.spacesFree ?? 0})}</BodySmall>

      <AddTeamMemberButton disabled={loading} buttonStyle={'primary'} size={'medium'} style={{marginBottom: 12}}  />
      <OutlineButton disabled={loading} style={{marginBottom: 64}} text={'manage-users-page.add-multiple-users'} click={() => dispatch(openDialog(DialogIdentifiers.AddMultipleUsersInfo))} />

      <OutlineButton disabled={loading} style={{marginBottom: 12}} text={'manage-users-page.assign-to-office'} click={onAssignToOfficeClick} />
      <OutlineButton disabled={loading} style={{marginBottom: 12}} text={'manage-users-page.assign-to-team'} click={onAssignToTeamClick} />
      <OutlineButton disabled={loading} style={{marginBottom: 64}} text={'manage-users-page.assign-to-workgroup'} click={onAssignToWorkgroupClick} />

      <OutlineButton disabled={loading} style={{marginBottom: 12}} text={'manage-users-page.manage-teams'} link={"/manage/teams-and-users"} />
      <OutlineButton disabled={loading} style={{marginBottom: 36}} text={'manage-users-page.manage-workgroups'} link={"/manage/workgroups"} />

      {canExportUsers && <OutlineButton disabled={loading} style={{marginBottom: 12}} text={'manage-users-page.export-users'} click={onExportUsersClick} />}
    </SidebarContainer>
  )
}

interface Props {
}

const SidebarContainer = styled.div`
  width: 100%;
`
