import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { HeadlineLarge } from "../../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../../UI/atoms/fonts/Body";
import { selectIsConfigLoading, updateAdvanceHotDeskingConfig } from "../../../../../store/ducks/config.duck";
import { Center } from "../../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../../services/WhereaboutOptions";

export default function AdvanceHotDeskingPage() {
  const isLoading = useSelector(selectIsConfigLoading)
  const dispatch = useDispatch();
  const iconPack = useSelector(selectIconPack);
  const {t} = useTranslation();

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner fullScreen={true}/>}
      <div className="management">
        <GoBackButton/>
        <img src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="Office" aria-hidden={true} style={{height: 120, marginBottom: 16}} />
        <HeadlineLarge className="management__title">{t('settings.hot-desking-page.title')}</HeadlineLarge>

        <BodyLarge style={{marginBottom: 12}}>{t('settings.hot-desking-page.message-1')}</BodyLarge>
        <PaidPlanList>
          <li><BodyLarge>{t('settings.hot-desking-page.message-2')}</BodyLarge></li>
          <li><BodyLarge>{t('settings.hot-desking-page.message-3')}</BodyLarge></li>
          <li><BodyLarge>{t('settings.hot-desking-page.message4')}</BodyLarge></li>
        </PaidPlanList>
        <>
          <Center>
            <PrimaryButton style={{marginTop: 24}}
                           click={() => dispatch(updateAdvanceHotDeskingConfig(true))}
                           text="settings.hot-desking-page.button"
                           size="medium" />
          </Center>
        </>

      </div>
    </React.Fragment>
  );
}

const PaidPlanList = styled.ul`
  margin-bottom: 16px;

  li {
    list-style: none;
    margin-bottom: 12px;
    background-repeat: no-repeat;
    background-position-y: 4px;
    padding: 0 0 0 40px;
    background-image: url("/assets/icons/GreenCircleTick.svg");
  }
`
