import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canUserEditOfficesInManagement } from "../../../../../utils/AccessControl";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { resetEditOfficeLayout, selectIsLoading } from "../../../../../store/ducks/editOfficeLayout.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { useParams } from "react-router";
import { fetchOfficeEntities, selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import styled from "styled-components/macro";
import DialogHeader from "../../../../dialogs/DialogHeader";
import {
  createOrUpdateOffice,
  EditableAreaEntity,
  MockEditableAreaEntity,
  OfficeEntity,
  updateSingleOfficeEntity
} from "../../../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import TextField from "../../../../UI/atoms/TextField";
import { Switch } from "../../../../UI/atoms/Switch";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { failureNotification, successNotification } from "../../../../../store/ducks/notification.duck";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { officeToCreateOfficeRequest } from "../../../../../utils/OfficeHelper";
import { PendingSaveDot } from "../../../../UI/atoms/PendingSaveDot";
import { HeaderRow } from "./StructuralLayout";
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { Trans, useTranslation } from "react-i18next";

export default function ManageOfficeAreasPage() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const allOffices = useSelector(selectAllOfficeEntities);
  const loading = useSelector(selectIsLoading);
  const isEditable = useMemo(() => canUserEditOfficesInManagement(currentUser), [currentUser]);
  const [areas, setAreas] = useState<EditableAreaEntity[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(resetEditOfficeLayout());
    }
  }, [dispatch]);

  const parentOffice = useMemo(() => {
    return allOffices.find((area: OfficeEntity) => area.id === parseInt(params.officeId))
  }, [allOffices, params.officeId]);

  useEffect(() => {
    setAreas(allOffices.filter((area: OfficeEntity) => area.parentId === parseInt(params.officeId) && area.area));
  }, [allOffices, params.officeId]);

  const updateArea = (label: string, capacity: number, active: boolean, area: EditableAreaEntity) => {
    const idx = areas.indexOf(area);
    if (idx > -1) {
      const updatedAreas = [...areas];
      updatedAreas.splice(idx, 1, {...area, label: label, capacity: capacity, active: active, dirty: true});
      setAreas(updatedAreas);
      setIsDirty(true);
    }
  }

  const addNewArea = () => {
    const newArea = {
      ...MockEditableAreaEntity,
      parentId: parentOffice.id,
      companyId: currentUser?.companyEntity.id,
      dirty: true,
    } as any;
    setAreas([...areas, newArea]);
    setIsDirty(true);
  }

  const saveChanges = async () => {
    setIsLoading(true);
    try {
      await Promise.all(
        areas.filter(area => area.dirty)
          .map(area => area.id ? updateSingleOfficeEntity(area) : createOrUpdateOffice(officeToCreateOfficeRequest(area)))
      );
      dispatch(fetchOfficeEntities());
      setIsDirty(false);
      dispatch(successNotification('Successfully updated all areas'));
    } catch (e: any) {
      dispatch(failureNotification('Failed to update one or more areas.'));
    } finally {
      setIsLoading(false);
    }
  }

  const onDeleteClicked = (area: EditableAreaEntity) => {
    if (area.id) {
      dispatch(openDialogWithPayload({
        payload: { officeId: area.id },
        activeDialog: DialogIdentifiers.DeleteOfficeEntityDialog
      }))
    }
  }

  return (
    <>
      {(loading || isLoading) && <LoadingSpinner fullScreen={true}/>}

      <div className="management">
        <GoBackButton url={!!parentOffice ? `/manage/hot-desking/edit/${parentOffice.parentId}` : '/manage/hot-desking'} />
        <HeaderRow>
          <DialogHeader title={"settings.bookable-areas"}/>
        </HeaderRow>

        {parentOffice?.floorPlanEnabled && <>
          <FlatContentCard style={{marginBottom: 24}}>
            <BodyRegular style={{marginBottom: 8}} weight={600}>{t('settings.manage-desk.floor-plans-enabled')}</BodyRegular>
            <BodyRegular><Trans i18nKey={'settings.manage-desk.contact-us-to-change'}
                                components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} /></BodyRegular>
          </FlatContentCard>
        </>}

        {areas.map((area: EditableAreaEntity, key: number) => <div key={key} className={"manageAreas__areaRow"}>
          <ManageDeskRow>
            <NameColumn>
              <BodyRegular weight={600}>{t('settings.manage-area.name')}</BodyRegular>
              <TextField onChange={(val: string) => updateArea(val, area.capacity, area.active, area)}
                         value={area.label}
                         dataTest={"area-label"}
                         fullWidth={true} />
            </NameColumn>
            <ControlsColumn>
              <BodyRegular weight={600}>{t('settings.manage-desk.number-of-desks')}</BodyRegular>
              <ControlsRow>
                <TextFieldSpacesContainer>
                  <TextField onChange={(val: string) => updateArea(area.label, parseInt(val), area.active, area)}
                             value={`${area.capacity}`}
                             type={'number'}
                             dataTest={"area-capacity"}
                             fullWidth={true} />
                </TextFieldSpacesContainer>

                <Switch value={area.active}
                        label={""}
                        onChange={enabled => updateArea(area.label, area.capacity, enabled, area)} />

                {!!area.id && <div style={{marginLeft: 12}}>
                  <IconButton icon={IconTypes.Bin} onClick={() => onDeleteClicked(area)} />
                </div>}


                <PendingSaveDot visible={!!area.dirty} style={{marginLeft: 16}} />

              </ControlsRow>
            </ControlsColumn>
          </ManageDeskRow>
        </div>)}

        <OutlineButton click={addNewArea}
                       text={"button.add-new-area"}
                       dataTest={"add-new-area-button"}
                       size={"medium"}
                       fullWidth={true}
                       style={{marginBottom: 16, marginTop: 32}}
                       disabled={!isEditable} />

        <PrimaryButton click={saveChanges}
                       text={"button.save-changes"}
                       dataTest={"save-areas-button"}
                       fullWidth={true}
                       size={"large"}
                       disabled={isEditable && !isDirty} />
      </div>
    </>
  )
}

const ManageDeskRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`
const NameColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
`
const ControlsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`
const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  .iconButton--mediumlarge {
    padding: 0;
    margin: 0;
  }
`
const TextFieldSpacesContainer = styled.div`
  display: flex;
  width: 80px;
  justify-content: center;
  .textField {
    margin: 0;
  }
`
