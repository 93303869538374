import { app, HostClientType } from "@microsoft/teams-js";
import { AccountInfo } from "@azure/msal-common";
import { microsoftClientId } from "../services/EnvironmentVariables";
import getContext = app.getContext;

const userAgent = window.navigator.userAgent.toLowerCase();
const isTeamsUserAgent = userAgent.includes('teams');

const url = window.location.href.toLowerCase();
const isTeamsUrl = url.includes('teams.microsoft');

const host = window.location.host.toLowerCase();
const isTeamsHost = host.includes('teams.microsoft');

export async function isInTeamsApp() {
  if (!isInTeams() || !getContext) return false;
  try {
    const context = await getContext();
    return context.app.host.clientType !== HostClientType.web;
  } catch (e) {
    return false;
  }
}

export function isInTeams() {
  return isTeamsUserAgent || isTeamsUrl || isTeamsHost || window.parent !== window;
}

export function isInTeamsSettingsTab() {
  return isInTeams() && window.location.href.includes('/manage');
}

export function isInCompanyTab() {
  return isInTeams() && window.location.href.includes('/whereabouts/company');
}

export function isInTeamsTab() {
  return isInTeams() && !isInCompanyTab() && window.location.href.includes('/whereabouts');
}

export function deepLinkTo(tabId: string, externalUrl: string, type?: 'user' | 'search' | 'floor' | 'team' | 'tag' | 'personal' | 'add-users', id?: string | number) {
  let qs =`&context={"subEntityId": "${type ?? ''}:${id ?? ''}:${Date.now()}"}`;
  const url = `https://teams.microsoft.com/l/entity/${microsoftClientId}/${tabId}?webUrl=${externalUrl}${qs}`;
  return app.openLink(url);
}

export function getLinkIdFromSubEntityId(subEntityId?: string) {
  if (!subEntityId) return;
  const split = subEntityId.split(":");
  if (split.length !== 3) return;
  return split[2];
}

export function isInTeamsStoreApp() {
  return isInTeams() && localStorage.getItem('isInTeamsStoreApp') === 'true';
}

export function setInTeamsStoreApp() {
  return localStorage.setItem('isInTeamsStoreApp', 'true');
}

export function resetInTeamsStoreApp() {
  return localStorage.removeItem('isInTeamsStoreApp');
}

export function findLoginAccount(context: app.Context, accounts: AccountInfo[]): AccountInfo | undefined {
  const principleName = context.user?.userPrincipalName;
  const loginHint = context.user?.loginHint;
  return accounts.find(account => account.username === loginHint) ?? accounts.find(account => account.username === principleName)
}
