import React, { useMemo } from 'react';
import { SwimLane } from "./SwimLane";
import styled from "styled-components/macro";
import { MeetingRoom, MeetingRoomBooking } from "../models/MeetingRooms";
import { MeetingRoomBookingContentCard } from "./MeetingRoomBookingContentCard";
import { useDispatch, useSelector } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { filteredMeetingRoomBookings } from "../models/room-finder.duck";
import { BORDER_SOLID, MEETING_ROOM_SWIMLANE_WIDTH } from "./StructuralComponents";
import { MeetingRoomTitle } from "./MeetingRoomTitle";
import { SyncStatus } from "../../../../hooks/WithMicrosoftCalendarSyncActiveChecker";
import { warningNotification } from "../../../../store/ducks/notification.duck";

export function DailyMeetingRoomSchedule(props: Props) {
  const {meetingRoom} = props;
  const dispatch = useDispatch();
  const allMeetingRoomBookings = useSelector(filteredMeetingRoomBookings);

  const disableSwimLane = !!meetingRoom.microsoftRoomId && props.calendarSyncStatus !== SyncStatus.Active;

  const meetingRoomBookings = useMemo(() => {
    return allMeetingRoomBookings.find(booking => booking.meetingRoomId === meetingRoom.id || booking.meetingRoomId === meetingRoom.microsoftRoomId)
  }, [allMeetingRoomBookings, meetingRoom.id, meetingRoom.microsoftRoomId]);

  const bookings = meetingRoomBookings?.meetingRoomBookings ?? [];

  const onClick = (hour: number, minutes: number) => {
    if (disableSwimLane) {
      dispatch(warningNotification('You need calendar sync to be active'))
      return;
    } else {
      dispatch(openDialogWithPayload({
        payload: {hour, minutes, meetingRoom},
        activeDialog: DialogIdentifiers.BookMeetingRoom,
      }));
    }
  }

  return (
    <Container className={props?.className ?? ''} unableToLoadMeetings={disableSwimLane}>
      <MeetingRoomTitle capacity={meetingRoom.capacity} name={meetingRoom.name} isOutlook={!!meetingRoom.microsoftRoomId} />
      <SwimLane onClick={onClick} disabled={disableSwimLane} />
      {bookings.map((booking: MeetingRoomBooking, key: number) => <MeetingRoomBookingContentCard key={key} booking={booking} />)}
    </Container>
  )
}

interface Props {
  meetingRoom: MeetingRoom;
  className?: string;
  calendarSyncStatus: SyncStatus;
}

const Container = styled.div<any>`
  position: relative;
  border-right: ${BORDER_SOLID};
  width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  min-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  max-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
`
