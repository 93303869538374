import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import styled from "styled-components/macro";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { getOfficeUsageReport, toCsvDownload } from "../../../../services/ReportingService";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import TeamDropDownList, { ALL_TEAMS } from '../../../UI/organisms/team-drop-down-list/TeamDropDownList';
import { DateInputField } from '../../../UI/molecules/DateInputField';
import { OutlineButton } from '../../../UI/atoms/buttons/OutlineButton';
import FullScreenDateRangeSelector from "../../../UI/molecules/calendar/FullScreenDateRangeSelector";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { selectAllTeams } from "../../../../store/ducks/config.duck";
import { useTranslation } from "react-i18next";

export function OfficeUsageDownloadReportBar(props: Props) {
  const teams = useSelector(selectAllTeams);
  const dispatch = useDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(ALL_TEAMS);
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const onTeamSelected = (teamId: string) => {
    setSelectedTeamId(teamId);
  }

  const onDatesSelected = (startDate?: Moment, endDate?: Moment) => {
    if (startDate) setFromDate(startDate);
    if (endDate) setToDate(endDate);
  }

  const onClick = () => {
    if (!fromDate) {
      dispatch(warningNotification("Start date required."));
      return;
    }
    if (!toDate) {
      dispatch(warningNotification("End date required."));
      return;
    }
    if (!selectedTeamId) {
      dispatch(warningNotification("Select a team."));
      return;
    }
    if (!props.selectedOffice) {
      dispatch(warningNotification("Select an office."));
      return;
    }

    const teamName = teams.find(t => t.id === selectedTeamId)?.name || selectedTeamId;
    setLoading(true);
    getOfficeUsageReport(selectedTeamId, props.selectedOffice, fromDate, toDate)
      .then(response => toCsvDownload(response, `${props.selectedOffice?.label} Office Usage for ${teamName}`))
      .finally(() => setLoading(false))
  }

  return (<>
      <HeadlineSmall>{t('reports-page.download-office-report')}</HeadlineSmall>
      <ReportRowContainer>

        <TeamDropDownListWrapper>
          <TeamDropDownList teams={teams}
                            onSelect={onTeamSelected}
                            label={"reports-page.download-report"}
                            includeAllTeams={true} />

        </TeamDropDownListWrapper>

        <DateInputField onClick={() => setIsCalendarOpen(true)}
                        placeholder="textinput.add-date"
                        value={fromDate ? fromDate.format('D MMMM YYYY') : ''}
                        label="textinput.from"
                        editableOnly={true}/>

        <DateInputField onClick={() => setIsCalendarOpen(true)}
                        placeholder="textinput.add-date"
                        value={toDate ? toDate.format('D MMMM YYYY') : ''}
                        label="textinput.to"
                        editableOnly={true}/>

        <OutlineButton size={"medium"} disabled={loading}
                       text="button.download-csv"
                       click={onClick} />
      </ReportRowContainer>
      <FullScreenDateRangeSelector open={isCalendarOpen}
                                   onDateSelect={(startDate, endDate) => onDatesSelected(startDate, endDate)}
                                   onClose={() => setIsCalendarOpen(false)} startDate={fromDate} endDate={toDate}
                                   initialDate={fromDate ?? moment()}/>
    </>
  )
}

interface Props {
  selectedOffice?: OfficeEntity;
}

const TeamDropDownListWrapper = styled.div`
  width: 25% !important;
`

const ReportRowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  .textField {
    margin-bottom: 0;
  }
  .teamDropDownList {
    margin-bottom: 0;
  }
  > * {
    width: 20%;
    padding-right: 24px;
  }
`
