import React, { useEffect } from 'react';
import { HeadlineLarge } from "../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, loadMeetingRooms, selectAllMeetingRooms } from "./meeting-rooms-settings.duck";
import { MeetingRoom } from "../../../meeting-rooms/models/MeetingRooms";
import { MeetingRoomContentCard } from "./components/MeetingRoomContentCard";
import { LinkButton } from "../../../../UI/atoms/buttons/LinkButton";
import styled from "styled-components/macro";
import { Center } from "../../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { ManageMeetingRoomsIntroduction } from "./components/ManageMeetingRoomsIntroduction";
import { MeetingRoomLimitReachedMessage } from "./components/MeetingRoomLimitReachedMessage";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";

export function ManageMeetingRoomsPage(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const meetingRooms = useSelector(selectAllMeetingRooms);
  const loading = useSelector(isLoading);

  useEffect(() => {
    dispatch(loadMeetingRooms());
  }, [dispatch]);

  return (
    <>
      <GoBackButton />
      <HeadlineLarge style={{marginBottom: 24}}>{t('settings.meeting-rooms.manage-meeting-rooms')}</HeadlineLarge>

      {loading ? <Center><LoadingSpinner hideBorder={true} hideText={true} /></Center> : <>

        {meetingRooms.length > 0 ? <>
          <MeetingRoomLimitReachedMessage />
          <MeetingRoomList>
            {meetingRooms.map((meetingRoom: MeetingRoom) => (<MeetingRoomContentCard meetingRoom={meetingRoom} />))}
          </MeetingRoomList>
        </> : <ManageMeetingRoomsIntroduction />}

        <LinkButton fullWidth={true} size={'large'}
                    link={"/manage/meeting-rooms/add"}
                    text={'settings.meeting-rooms.add-meeting-rooms'} />

      </>}
    </>
  )
}

interface Props {
}

const MeetingRoomList = styled.div`
  margin-bottom: 24px;
`
