export const apiUrl: string = (window.location.href.includes('team-tracker') ? process.env.REACT_APP_TEAMS_API_URL : process.env.REACT_APP_API_URL) ?? '';
export const uiUrl: string = (window.location.href.includes('team-tracker') ? process.env.REACT_APP_TEAMS_UI_URL : process.env.REACT_APP_UI_URL) ?? '';

export const stripeProductMonthlyPriceId_USD: string = process.env.REACT_APP_PRODUCT_MONTHLY_PRICE_ID_USD ?? '';
export const stripeProductMonthlyPriceId_GBP: string = process.env.REACT_APP_PRODUCT_MONTHLY_PRICE_ID_GBP ?? '';
export const stripeProductMonthlyPriceId_EUR: string = process.env.REACT_APP_PRODUCT_MONTHLY_PRICE_ID_EUR ?? '';
export const stripeProductMonthlyPriceId_AUD: string = process.env.REACT_APP_PRODUCT_MONTHLY_PRICE_ID_AUD ?? '';

export const stripeProductYearlyPriceId_USD: string = process.env.REACT_APP_PRODUCT_YEARLY_PRICE_ID_USD ?? '';
export const stripeProductYearlyPriceId_GBP: string = process.env.REACT_APP_PRODUCT_YEARLY_PRICE_ID_GBP ?? '';
export const stripeProductYearlyPriceId_EUR: string = process.env.REACT_APP_PRODUCT_YEARLY_PRICE_ID_EUR ?? '';
export const stripeProductYearlyPriceId_AUD: string = process.env.REACT_APP_PRODUCT_YEARLY_PRICE_ID_AUD ?? '';

export const stripePublicKey: string = process.env.REACT_APP_STRIPE_PK ?? '';
export const stripeUserPackPrice: number = parseInt(process.env.REACT_APP_USER_PACK_PRICE ?? '0');
export const stripeBasePrice: number = parseInt(process.env.REACT_APP_BASE_PRICE ?? '0');
export const stripeMinUserLimit: number = parseInt(process.env.REACT_APP_MIN_USER_LIMIT ?? '0');
export const stripePackSize: number = parseInt(process.env.REACT_APP_PACK_SIZE ?? '0');
export const stripeUserLimit: number = parseInt(process.env.REACT_APP_USER_LIMIT ?? '0');
export const paymentsEnabled: boolean = process.env.REACT_APP_PAYMENTS_ENABLED === 'true';
export const showNewUserOnboardingButton: boolean = process.env.REACT_APP_NEW_USER_ONBOARDING_BUTTON === 'true';

export const calendarS3Bucket: string = process.env.REACT_APP_S3_CALENDAR_BUCKET ?? '';
export const followedTeamsLimit: number = parseInt(process.env.REACT_APP_FOLLOWED_TEAMS_LIMIT ?? '6');

export const enableStripe: boolean = process.env.REACT_APP_ENABLE_STRIPE === 'true';

export const microsoftClientId: string = process.env.REACT_APP_MICROSOFT_CLIENT_ID ?? '';
export const googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

export const recaptchaEnabled: boolean = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';

export const buildNumber: string = process.env.REACT_APP_BUILD ?? 'dev';

export const teamsTabs: any = {
  settings: 'teamsapp-team-tracker-settings',
  team: 'teamsapp-team-tracker-team',
  company: 'teamsapp-team-tracker-company',
  planner: 'teamsapp-team-tracker-planner',
  holidays: 'teamsapp-team-tracker-holidays',
  meetingRooms: 'teamsapp-team-tracker-meetingrooms',
}

export const microsoftRequest = {
  register: {
    scopes: ["email", "User.Read", "profile", "openid", "offline_access"],
    redirectUri: `${window.location.origin}/register/azure`,
  },
  login: {
    scopes: ["email", "User.Read", "profile", "openid", "offline_access"],
    redirectUri: `${window.location.origin}/login/azure`,
  },
  calendarScopes: ["email", "User.Read", "profile", "openid", "offline_access", "Calendars.ReadWrite", "MailboxSettings.Read"],
}

export const msalConfig = {
  auth: {
    clientId: microsoftClientId, // This is the ONLY mandatory field that you need to supply.
    authority: "https://login.microsoftonline.com/common", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loadFrameTimeout: 10000,
    iframeHashTimeout: 10000,
    tokenRenewalOffsetSeconds: 30,
    // loggerOptions: {
    //   loggerCallback: (level: any, message: any, containsPii: any) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //     }
    //   }
    // }
  }
};
