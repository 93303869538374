import React, { useEffect } from 'react';
import Layout from "../../../UI/organisms/layout/Layout";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { RoomFinderView } from "../components/RoomFinderView";
import { selectedOffice, setOffice } from "../models/room-finder.duck";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { getRootOfficeById } from "../../../../utils/OfficeHelper";
import { loadMeetingRooms } from "../../settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { addOrReplaceQueryParam, deleteQueryParam, getParameterByName } from "../../../../utils/UrlUtils";
import { breakpoints } from "../../../UI/atoms/MediaQueries";
import { RoomFinderSidebar } from "../components/RoomFinderSidebar";
import { WithDefaultOfficeSelection } from "../../../../hooks/WithDefaultOfficeSelection";
import { FullScreenContent } from "../../company-movements/components/ViewComponents";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";

export function RoomFinderPage(props: Props) {
  const office = useSelector(selectedOffice)
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const allOffices = useSelector(selectAllOfficeEntities);

  WithDefaultOfficeSelection(true, true);

  useEffect(() => {
    dispatch(loadMeetingRooms());
  }, [dispatch]);

  useEffect(() => {
    if (office) return;

    const officeQueryParam = getParameterByName('office', window.location.href);
    if (officeQueryParam) {
      try {
        const officeId = parseInt(officeQueryParam);
        const officeToSet = getRootOfficeById(allOffices, officeId);
        if (officeToSet) {
          dispatch(setOffice(officeToSet));
        }
      } catch (e) {
        deleteQueryParam('office');
      }
    } else if (!office && !!currentUser?.defaultLocationId) {
      const defaultOffice = getRootOfficeById(allOffices, currentUser.defaultLocationId);
      if (defaultOffice) {
        dispatch(setOffice(defaultOffice));
      }
    } else {
      const rootOffices = allOffices.filter((o: OfficeEntity) => o.office && !o.parentId)
      if (rootOffices.length > 0) {
        const defaultMeetingRoom = rootOffices[0];
        dispatch(setOffice(defaultMeetingRoom));
      }
    }
  }, [currentUser, office, dispatch, allOffices]);

  useEffect(() => {
    if (office) {
      addOrReplaceQueryParam('office', office.id.toString());
    }
  }, [office]);



  return (
    <>
      <Layout hideFooter={true} fullScreenView={true} style={{maxWidth: '100%'}}>
        <FullScreenContent>
          <RoomFinderSidebar showCloseButton={false} />

          <RoomFinderContainer>
            <RoomFinderView />
          </RoomFinderContainer>
        </FullScreenContent>
      </Layout>
    </>
  )
}

interface Props {
}

const RoomFinderContainer = styled.div`
  max-height: inherit;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${breakpoints.desktopMidWide}px;
`
