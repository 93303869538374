import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import DialogHeader from "./DialogHeader";
import Dialog from "../UI/molecules/Dialog";
import { Column, SpaceBetweenRow } from "../UI/atoms/StructuralLayout";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";
import {
  loadFavouriteOffices,
  selectAllOfficeEntities,
  selectFavouriteDesks
} from "../../store/ducks/advanceHotDeskingSetup.duck";
import { FavouriteDesk, OfficeEntity, toggleFavouriteDesk } from "../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../UI/atoms/fonts/Body";
import { findOfficeInOffices, getFullOfficeTitle } from "../../utils/OfficeHelper";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import { Desk, getDesksInOffice } from "../../services/DeskBookingService";
import { naturalSort } from "../../utils/ArrayUtils";
import { LoadingOverlay } from "../UI/molecules/LoadingOverlay";
import { FavouriteImage } from "../UI/atoms/FavouriteImage";
import OfficeLocationContentCard from "../UI/molecules/OfficeLocationContentCard";

export default function ManageFavouritesDialog() {
  const dispatch = useDispatch();
  const [selectedOfficeId, setSelectedOfficeId] = useState(0);
  const [desks, setDesks] = useState<Desk[]>([]);
  const [loading, setLoading] = useState(false);

  const allOffices = useSelector(selectAllOfficeEntities);
  const favoriteDesks = useSelector(selectFavouriteDesks);

  const selectedOffice = useMemo(() => findOfficeInOffices(allOffices, selectedOfficeId), [selectedOfficeId, allOffices]);

  const visibleOffices = useMemo(() => {
    if (selectedOffice) {
      return allOffices.filter((o: OfficeEntity) => o.parentId === selectedOffice.id)
    } else {
      return allOffices.filter((o: OfficeEntity) => !o.parentId)
    }
  }, [allOffices, selectedOffice]);

  const breadCrumb = useMemo(() => {
    if (selectedOffice) {
      return getFullOfficeTitle(selectedOffice, allOffices)
    }
  }, [selectedOffice, allOffices])

  useEffect(() => {
    if (selectedOffice?.deskBookingEnabled) {
      setLoading(true);
      setDesks([]);
      getDesksInOffice(selectedOffice.id)
        .then((resp: Desk[]) => naturalSort(resp, 'label'))
        .then((resp: Desk[]) => setDesks(resp))
        .finally(() => setLoading(false))
    } else {
      setDesks([]);
    }
  }, [selectedOffice]);


  const close = () => {
    dispatch(closeDialog());
  }

  const isDeskFavoured = (desk: Desk): boolean => {
    return !!favoriteDesks.find((foe: FavouriteDesk) => foe.deskId === desk.id);
  }

  const onDeskFavoured = async (desk: Desk) => {
    try {
      setLoading(true);
      await toggleFavouriteDesk(desk.id);
      await new Promise((resolve: any) => setTimeout(() => resolve(), 250));
      dispatch(loadFavouriteOffices());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      {loading && <LoadingOverlay />}
      <DialogHeader title="dialog-headers.manage-favourites" />

      {!!breadCrumb && <SpaceBetweenRow>
        <BodyRegular>{breadCrumb}</BodyRegular>
        <OutlineButton size={"small"}
                       click={() => setSelectedOfficeId(0)}
                       style={{marginBottom: 16, maxWidth: 80}}
                       text="Reset" />
      </SpaceBetweenRow>}

      {visibleOffices.length === 0 && desks.length === 0 && !loading && !!selectedOffice && <>
        <OfficeLocationContentCard office={selectedOffice} onClick={()=>{}} />
      </>}

      <Column>
        {visibleOffices.map((office: OfficeEntity, key: number) => <OfficeLocationContentCard office={office}
                                                                                              key={key}
                                                                                              onClick={() => setSelectedOfficeId(office.id)} />)}

        {desks.map((desk: Desk, key: number) => <SpaceBetweenRow key={key}>
          <BodyRegular weight={600}>{desk.label}</BodyRegular>
          <FavouriteImage src={isDeskFavoured(desk) ? '/assets/icons/StarActive.svg' : '/assets/icons/Star.svg'}
                          onClick={() => onDeskFavoured(desk)}
                          alt="Favourite desk"/>
        </SpaceBetweenRow>)}
      </Column>

      <Column>

        <PrimaryButton fullWidth={true}
                       click={close}
                       text="button.close" />
      </Column>
    </Dialog>
  )
}

