import { useSelector } from "react-redux";
import { selectCurrentUser } from "../store/ducks/auth.duck";
import { useMemo } from "react";

export function IsSuperAdmin() {
  const current = useSelector(selectCurrentUser);

  const superAdminButtonsEnabled = useMemo(() => {
    if (!current || !current.email) return false;
    return ['robin@team-today.com', 'andrew@team-tracker.com', 'lucy@team-today.com'].includes(current.email);
  }, [current]);

  return superAdminButtonsEnabled;
}
