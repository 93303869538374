import React, { useMemo, useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { Switch } from "../../atoms/Switch";
import { requestTenantIdSignUp } from "../../../../services/CompanyService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, updateCurrentUser } from "../../../../store/ducks/auth.duck";
import { failureNotification } from "../../../../store/ducks/notification.duck";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { FlatContentCard } from "../../atoms/FlatContentCard";
import { AuthType, GetCurrentAuthType } from "../../../../utils/AuthUtils";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

export function TenantBasedSignUpSetting(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const {instance} = useMsal();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const isActive = useMemo(() => {
    return !!user?.companyEntity.tenantBasedAuthEnabled
  }, [user]);

  const onDomainNameChange = async (value: boolean) => {
    try {
      setIsLoading(true);
      await requestTenantIdSignUp(value, instance.getActiveAccount()?.tenantId);
      dispatch(updateCurrentUser(true));
    } catch (e: any) {
      dispatch(failureNotification('Failed to update setting, please wait a minute.'));
    } finally {
      setIsLoading(false);
    }
  };

  if (GetCurrentAuthType() !== AuthType.Microsoft) {
    // If user is not microsoft then cancel.
    return null;
  }

  return (
    <ConfigurationSection>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.tenant-based.title')}</BodyLarge>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.tenantBasedAuthEnabled} label="" onChange={onDomainNameChange}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.tenant-based.message-1')}</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.tenant-based.message-2')}</BodyRegular>

      {isActive &&
        <FlatContentCard>
          <BodyLarge colour={Colours.blue}>{t('settings.tenant-based.active-1')}</BodyLarge>
          <BodyRegular style={{marginBottom: 16}}>{t('settings.tenant-based.active-2')}</BodyRegular>
          <BodyRegular style={{marginBottom: 16}}>{t('settings.tenant-based.active-3')}</BodyRegular>
        </FlatContentCard>}

    </ConfigurationSection>
  )
}

interface Props {
}
