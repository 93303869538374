import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCalendarDate } from "../store/ducks/dashboard.duck";
import { Moment } from "moment";

export function WithWeeksInCurrentCalendarMonth() {
  const calendarDate = useSelector(selectCalendarDate);
  const weeksInMonth = useMemo(() => {
    let monday = calendarDate.clone().startOf('month').day('Monday');
    if (monday.date() > 1 && monday.month() === calendarDate.month()) {
      monday = calendarDate.clone().startOf('month').day('Monday').subtract(1, 'week')
    }
    const month = calendarDate.clone().month();
    let dates: Moment[] = [];

    // Week 1
    dates.push(monday.clone());
    monday.add(7, 'days');
    // Week 2
    dates.push(monday.clone());
    monday.add(7, 'days');
    // Week 3
    dates.push(monday.clone());
    monday.add(7, 'days');
    // Week 4
    dates.push(monday.clone());
    monday.add(7, 'days');

    if (monday.month() === month) {
      // Week 5 (maybe)
      dates.push(monday.clone());
      monday.add(7, 'days');
    }

    if (monday.month() === month) {
      // Week 6 (maybe)
      dates.push(monday.clone());
      monday.add(7, 'days');
    }

    return dates;
  }, [calendarDate]);

  return weeksInMonth;
}
