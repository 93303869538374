import { agent } from './agent';
import { TeamEntity } from "../models/team.models";
import { Tag } from "../models/tags.models";

export async function updateDeskOwnershipsForTeams(deskIds: number[], teams: TeamEntity[]): Promise<void> {
  const teamIds = teams.map(t => t.id);
  const payload: any[] = deskIds.map(id => ({
    deskId: id,
    teamIds: teamIds,
  }))
  return await agent.requests.post('/api/desk-ownership/multiple', payload);
}

export async function updateDeskOwnershipsForWorkgroups(deskIds: number[], workgroups: Tag[]): Promise<void> {
  const workGroupIds = workgroups.map(t => t.id);
  const payload: any[] = deskIds.map(id => ({
    deskId: id,
    workgroupIds: workGroupIds
  }))
  return await agent.requests.post('/api/desk-ownership/multiple', payload);
}

export async function updateDeskOwnershipsForUsers(deskIds: number[], userIds: string[]): Promise<void> {
  const payload: any[] = deskIds.map(id => ({
    deskId: id,
    userIds: userIds
  }));
  return await agent.requests.post('/api/desk-ownership/multiple', payload);
}

export async function resetDeskOwnership(deskIds: number[]): Promise<void> {
  return await agent.requests.post('/api/desk-ownership/clear', deskIds, true);
}
