import React from 'react';
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { useSelector } from "react-redux";
import { selectOffices } from "../../../store/ducks/advanceHotDeskingSetup.duck";

export function DefaultOfficeSelect(props: Props) {
  const office = useSelector(selectOffices);
  return (
    <>
      <List>
        {office.map((office: OfficeEntity, key: number) => (<Option onClick={() => props.onSelect(office)} key={key}>
          <BodyRegular>{office.label}</BodyRegular>
        </Option>))}
      </List>
    </>
  )
}

interface Props {
  onSelect: (office: OfficeEntity) => any;
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: auto;
`
const Option = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${Colours.mildGrey};
  margin-bottom: 16px;
  margin-right: 24px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: ${Colours.blue05};
  }
`
