import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { requestLineReportsWhereabouts } from '../../services/Movements';
import { MemberMovements } from '../../models/movements.models';

export interface LineReportsWhereaboutsState {
  whereabouts: MemberMovements[];
  loading: boolean;
  isLineReportOptionSelected: boolean;
}

export const initialLineReportsWhereaboutsState: LineReportsWhereaboutsState = {
  whereabouts: [],
  loading: false,
  isLineReportOptionSelected: false,
}

export const loadLineReportsWhereabouts: any = createAsyncThunk('lineReportWhereabouts/load',
  async (params: {date: string}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    if (params.date && state.lineReportsWhereabouts.isLineReportOptionSelected) {
      const resp = await requestLineReportsWhereabouts(params.date);
      return resp;
    }
    return [];
  }
);

const lineReportWhereaboutsSlice = createSlice({
  name: 'lineReportWhereabouts',
  initialState: initialLineReportsWhereaboutsState,
  reducers: {
    reset: () => ({...initialLineReportsWhereaboutsState}),
    setWhereaboutsLineReportSelected: (state, action) => ({...state, isLineReportOptionSelected: action.payload}),
  },
  extraReducers: {
    [loadLineReportsWhereabouts.pending]: (state) => ({ ...state, whereabouts: [], loading: true }),
    [loadLineReportsWhereabouts.failed]: (state) => ({ ...state, whereabouts: [], loading: false }),
    [loadLineReportsWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      whereabouts: action.payload,
      loading: false,
    }),
  }
})

export default lineReportWhereaboutsSlice.reducer;
export const {
  reset,
  setWhereaboutsLineReportSelected,
} = lineReportWhereaboutsSlice.actions;

// Selectors
export const selectLineReportsWhereabouts = (state: AppState) => state.lineReportsWhereabouts.whereabouts;
export const selectIsLoading = (state: AppState) => state.lineReportsWhereabouts.loading;
export const selectIsLineReportOptionSelected = (state: AppState) => state.lineReportsWhereabouts.isLineReportOptionSelected;
