import React, { useEffect } from 'react';
import { clearLastPath, getParameterByName } from '../../../utils/UrlUtils';
import { useDispatch, useSelector } from 'react-redux';
import { redirectTo, selectCurrentUser, setUser } from '../../../store/ducks/auth.duck';
import AuthWrapper from '../auth/components/AuthWrapper';
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { AzureAcquireTokenSilent, LoginWithRedirect } from "../../../utils/AuthUtils";
import { Redirect } from "react-router";
import {
  NotificationMessages,
  parseErrorResponse,
  redirectWithNotification
} from "../../../models/notification.models";
import { rawUserRequest } from "../../../services/Auth";
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { Trans, useTranslation } from "react-i18next";
import { isInTeams } from "../../../utils/TeamsUtils";

export default function LoginWithAzure() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const invitationId = getParameterByName('invitation', window.location.href);
  const {t} = useTranslation();

  const {instance, accounts} = useMsal();

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    clearLastPath();
  }, []);

  useEffect(() => {
    if (isInTeams()) return;
    instance.addEventCallback((event: any) => {
      if ((event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) && event.payload.account) {
        const account = event.payload.account;
        instance.setActiveAccount(account);

        AzureAcquireTokenSilent(dispatch, instance, InteractionStatus.None, instance.getActiveAccount(), undefined, true)
          .catch((err) => {
            redirectWithNotification(parseErrorResponse(err.message, NotificationMessages.azureLoginFailed), '/login');
          })
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log(JSON.stringify(event));
      }
    });
  }, [instance, dispatch]);

  useEffect(() => {
    if (isInTeams()) return;
    if (currentUser) {
      const redirectUrl = localStorage.getItem('redirect');
      localStorage.removeItem('redirect');
      if (redirectUrl === '/login/azure' || redirectUrl === '/login/google') {
        dispatch(redirectTo('/whereabouts/team'));
      } else {
        dispatch(redirectTo(redirectUrl ?? '/whereabouts/team'));
      }
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (isInTeams()) return;
    if (accounts.length === 0) return;
    rawUserRequest().then(user => {
      dispatch(setUser(user));
    });
  }, [dispatch, instance, accounts]);

  const loginAgain = () => {
    LoginWithRedirect(instance, dispatch, true);
  }

  if (!!invitationId) {
    return (
      <Redirect to={`/invite?id=${invitationId}`} />
    )
  } else {
    return (
      <AuthWrapper dataTest="login-invite" title="" spinner={accounts.length > 0} hideLoginLink={true}>
        <BodyRegular>{t('login.login-failed-with-azure')}</BodyRegular>
        <BodyRegular><Trans i18nKey={'login.login-failed-with-azure-help'} components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} /></BodyRegular>
        <PrimaryButton text={"button.try-again"}
                       click={loginAgain}
                       fullWidth={true} />
      </AuthWrapper>
    )
  }
}

