import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { OutlineButton } from "./OutlineButton";
import { PrimaryButton } from "./PrimaryButton";

export function LinkButton({id, link, text, imageUrl, marginTop = 0, type = 'primary', dataTest, fullWidth = false, className = '', size = 'small', style = {}, onClick = () => {}, disabled = false}: Props) {
  let buttonAttributes = {};
  if (!!id) {
    // @ts-ignore
    buttonAttributes.id = id;
  }
  if (!!dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = dataTest;
  }
  if (disabled) {
    return (<InnerButton
      imageUrl={imageUrl}
      text={text}
      type={type}
      onClick={onClick}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled} />)
  }
  return (
    <Link style={{...style, marginTop: marginTop}} {...buttonAttributes}
          to={link}
          className={`${className} linkButton`}>
      <InnerButton
        imageUrl={imageUrl}
        text={text}
        type={type}
        onClick={onClick}
        size={size}
        fullWidth={fullWidth} />
    </Link>
  );
}

function InnerButton(props: Omit<Props, "link">) {
  const {imageUrl, text, type, onClick, size, fullWidth} = props;
  if (type === 'outline') {
    return <OutlineButton
      imageUrl={imageUrl}
      click={onClick}
      size={size}
      fullWidth={fullWidth}
      text={text}
      disabled={props.disabled} />
  } else if (type === 'primary') {
    return <PrimaryButton
      imageUrl={imageUrl}
      click={onClick}
      size={size}
      fullWidth={fullWidth}
      text={text}
      disabled={props.disabled} />
  }
  return <Button
    imageUrl={imageUrl}
    text={text}
    type={type}
    onClick={onClick}
    size={size}
    fullWidth={fullWidth} disabled={props.disabled} />
}

interface Props {
  id?: string;
  text: string;
  type?: 'primary' | 'outline' | 'grey' | 'outline-borderless' | 'outline-black';
  onClick?: any;
  link: string;
  className?: string;
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  dataTest?: string;
  style?: any;
  imageUrl?: string;
  marginTop?: number;
  disabled?: boolean;
}
