import React from 'react';
import './empty-team-view.scss';
import { BodyLarge, BodyRegular } from "../../../../atoms/fonts/Body";
import { Column, Row } from '../../../../atoms/StructuralLayout';
import Colours from "../../../../atoms/Colours";
import { PrimaryButton } from "../../../../atoms/buttons/PrimaryButton";
import { Tag } from "../../../../../../models/tags.models";
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";
import { useTranslation } from "react-i18next";

interface Props {
  tag: Tag;
}

export default function EmptyTagView(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const onClick = () => {
    dispatch(openDialogWithPayload({
      payload: { tag: props.tag },
      activeDialog: DialogIdentifiers.EditUsersInTagDialog
    }));
  }
  return (
    <Row className="emptyTeamView">
      <Column className="emptyTeamView__message">
        <BodyRegular>{props.tag.label}</BodyRegular>
        <BodyLarge className="emptyTeamView__title" colour={Colours.blue}>{t('company-view-page.no-members-in-workgroup')}</BodyLarge>
        <BodyRegular style={{marginBottom: 32}}>{t('company-view-page.no-members-in-workgroup-description')}</BodyRegular>
        <PrimaryButton text={"button.add-members"} click={onClick} />
      </Column>
      <Column className="emptyTeamView__image">
        <img src="/assets/images/man-fishing.svg" alt="Man fishing" />
      </Column>
    </Row>
  )
}
