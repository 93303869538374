import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { desktop, tablet } from "../../../UI/atoms/MediaQueries";
import { selectActiveDay, setActiveDay } from "../../../../store/ducks/editMovements.duck";
import moment, { Moment } from 'moment';
import Calendar from "../../../UI/molecules/calendar/Calendar";
import { ChooseOfficeControls } from "./ChooseOfficeControls";

export function ChoseOfficeSidebar() {
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);

  const onDateSelect = (dateSelect: Moment) => {
    dispatch(setActiveDay(dateSelect));
  }

  return (
    <Wrapper data-test="office-sidebar">
      <Calendar onDateSelect={onDateSelect} activeDate={activeDay}
                initialDate={activeDay ?? moment()}
                highlightToday={true}
                hideActiveDayHighlight={false} />

      <ChooseOfficeControls />

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    max-width: 100%;
  }
  @media (${tablet}) {
    margin-top: 0;
    max-width: 230px;
  }
  @media (${desktop}) {
    margin-top: 0;
    max-width: 295px;
  }
  .calendar {
    align-self: center;
  }
`
