import React from 'react';
import Layout from "../../../UI/organisms/layout/Layout";
import { HeadlineLarge } from "../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import styled from "styled-components/macro";
import { FilterBar } from "./components/FilterBar";
import { HolidayAllowanceUserList } from "./components/HolidayAllowanceUserList";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUsersAllowances,
  selectGroupSelection,
  selectHolidayAllowances,
  selectIsLoading,
  selectPaginationInfo
} from "../models/holiday-allowances.duck";
import { Center } from "../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { HolidayAllowanceSaveBar } from "./components/HolidayAllowanceSaveBar";
import { HolidayAllowanceHeaderRow } from "./components/HolidayAllowanceHeaderRow";

export function HolidayAllowanceSettings(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const paginationInfo = useSelector(selectPaginationInfo);
  const groupSelection = useSelector(selectGroupSelection)
  const holidayAllowances = useSelector(selectHolidayAllowances)
  const isLoading = useSelector(selectIsLoading);

  const onGroupSelectionChange = (selection: string) => {
    dispatch(loadUsersAllowances({groupSelection: selection, skip: 0}))
  }

  const changePage = (shift: number) => {
    dispatch(loadUsersAllowances({groupSelection: groupSelection, skip: paginationInfo.skip + shift}))
  }

  return (
    <>
      <Layout>
        <HeaderRow>
          <HeadlineLarge>{t('allowance.title')}</HeadlineLarge>
          <OutlineButton link={'/holidays/reports'} text={'Go to Reporting'} />
        </HeaderRow>

        <HolidayAllowanceHeaderRow />

        <FilterBar onGroupSelected={selection => onGroupSelectionChange(selection)} />

        <HolidayAllowanceUserList allowances={holidayAllowances} />

        {isLoading ? <>
          <Center>
            <LoadingSpinner style={{marginTop: 50}}
                            hideText={true}
                            fullScreen={false}
                            hideBorder={true} />
          </Center>
        </> : <>
          {paginationInfo.totalRecords > paginationInfo.count && <FooterRow>
            <OutlineButton text={"Previous"} click={() => changePage(-1 * paginationInfo.count)} />
            <BodyRegular>Showing {paginationInfo.skip} to {paginationInfo.count + paginationInfo.skip} of {paginationInfo.totalRecords}</BodyRegular>
            <OutlineButton text={"Next"} click={() => changePage(paginationInfo.count)} />
          </FooterRow>}
        </>}


      </Layout>
      <HolidayAllowanceSaveBar />
    </>
  )
}

interface Props {
}

const HeaderRow = styled.div`
  margin: 32px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .linkButton {
    margin: 0;
    width: unset;
    max-width: unset;
  }
`

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`
