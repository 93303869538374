import React from 'react';
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { Checkbox } from "../../../../../UI/atoms/Checkbox";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";

export function OfficeRow(props: Props) {
  const {office, label, isChecked, onChange} = props;
  return (
    <Wrapper onClick={() => onChange(!isChecked, office)} isChecked={isChecked}>
      <Icon icon={IconTypes.Office} />
      <BodyRegular weight={600}>{label}</BodyRegular>
      <CheckboxWrapper>
        <Checkbox className={'officeRow__checkbox'} value={isChecked} onChange={(state: boolean) => onChange(state, office)} />
      </CheckboxWrapper>
    </Wrapper>
  )
}

interface Props {
  label: string;
  office?: OfficeEntity;
  isChecked: boolean;
  onChange: (state: boolean, office?: OfficeEntity) => void;
}

const Wrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  border: 1px ${Colours.lightGrey} solid;
  padding: 0 0 0 16px;
  border-radius: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  height: 50px;
  border-color: ${props => props.isChecked ? Colours.blue : Colours.lightGrey};
  &:hover {
    background-color: ${Colours.blue05};
  }
  i {
    align-self: center;
    margin-right: 8px;
    user-select: none;
  }
  p {
    align-self: center;
    user-select: none;
  }
`

const CheckboxWrapper = styled.div`
  margin-left: auto;
  align-self: center;
  justify-content: center;
  border-left: 1px solid ${Colours.lightGrey};
  height: 100%;
  padding: 0 4px 0 16px;
  .officeRow__checkbox {
    margin-top: -8px;
  }
`
