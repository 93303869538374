import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCalendarDate, setCalendarDate } from '../../../../store/ducks/dashboard.duck';
import moment, { Moment } from 'moment';
import { Button } from '../../atoms/buttons/Button';
import { BodyRegular } from '../../atoms/fonts/Body';
import Icon, { IconTypes } from '../../atoms/icon/Icon';
import Calendar from './Calendar';
import './full-screen-calendar.scss';
import { useTranslation } from "react-i18next";

interface Props {
  open?: boolean;
  onClose?: any;
  onDateSelect?: () => {};
  initialDate?: Moment;
}

export default function FullScreenCalendar(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const calendarDate = useSelector(selectCalendarDate)
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => isOpen ? document.body.classList.add('noscroll') : document.body.classList.remove('noscroll'), [isOpen]);
  useEffect(() => {
    setIsHidden(false);
    setTimeout(() => setIsOpen(props.open || false), 10);
  }, [props.open]);
  useEffect(() => () => document.body.classList.remove('noscroll'), []);

  const close = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
    setTimeout(() => setIsHidden(true), 300);
  }

  const resetCurrentWeek = () => {
      dispatch(setCalendarDate(moment().startOf('isoWeek')));
  }

  if (!isOpen && isHidden) {
    return null;
  }

  return (
    <div className={`fullScreenCalendar__container ${isOpen ? 'fullScreenCalendar__container--open' : ''}`}>
      <div className="fullScreenCalendar">
        <div className="fullScreenCalendar__header">
          <BodyRegular weight={600}>{t('calendar.choose-week')}</BodyRegular>
        </div>
        <Calendar initialDate={calendarDate} stretch={true} onDateSelect={(date) => dispatch(setCalendarDate(date))}/>
        <Button text="button.reset-to-current-week" type="outline-borderless" onClick={() => resetCurrentWeek()} />
        <Button text="button.done" size="large" onClick={() => close()} fullWidth={true} />
        <Icon size="medium" icon={IconTypes.Close} onClick={() => close()} className="fullScreenCalendar__close" />
      </div>
    </div>
  );
}
