import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectIsAuthLoading } from '../../store/ducks/auth.duck';
import { Permission } from '../../models/user.models';

function PrivateRoute(props: any) {
  const currentUser = useSelector(selectCurrentUser);
  const isAuthLoading = useSelector(selectIsAuthLoading);

  if (currentUser) {
    if (!props.path.includes('onboarding')) {
      if (currentUser.companyEntity && currentUser?.teamEntity) {
        // company is not null AND team is not null ---> OK

      } else if (currentUser.companyEntity && !currentUser?.teamEntity) {
        // company is not null AND team is null ----> Onboarding
        // if user is company admin then onboarding needs finished. Otherwise proceed
        if (currentUser.role === Permission.CompanyAdmin) {
          // return <Redirect to="/onboarding" />;
        }
      } else if (!currentUser.companyEntity && !currentUser?.teamEntity) {
        // company is null AND team is null ---> Onboarding
        // No company entity or team means this is a new user and needs to do the full onboarding process
        return <Redirect to="/onboarding" />;

      } else if (!currentUser.companyEntity && currentUser?.teamEntity) {
        // company is null and TEAM is not null ----> OK
      }
    }

    return <Route {...props} />;
  }

  if (isAuthLoading) {
    return <h1>Loading</h1>;
  }

  if (!currentUser && !isAuthLoading) {
    return null
  }

  return <Redirect to="/login" />;
}

export default PrivateRoute;
