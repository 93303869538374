import React, { useEffect, useState } from 'react';
import { Moment } from "moment";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { getUsersHolidayUsage } from "../../../../services/HolidayService";
import { UsersHolidayInfo } from "../models/Holidays.model";
import { UsersHolidayUsageView } from "./UsersHolidayUsageView";

export function UsersHolidayUsageViewWithDataProvider(props: Props) {
  const {userId, date} = props;
  const dateString = date.format(DATE_FORMAT);
  const [usersHolidayInfo, setUserHolidayInfo] = useState<UsersHolidayInfo | undefined>();

  useEffect(() => {
    getUsersHolidayUsage(userId, dateString)
      .then(setUserHolidayInfo)
      .catch(() => setUserHolidayInfo(undefined));
  }, [dateString, userId]);

  return (
    <>
      {usersHolidayInfo && <UsersHolidayUsageView usersHolidayInfo={usersHolidayInfo} />}
    </>
  )
}

interface Props {
  userId: string;
  date: Moment;
}
