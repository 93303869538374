import React from 'react';
import styled from "styled-components/macro";
import { desktopSmall } from "./MediaQueries";

export function Sidebar(props: Props) {
  return (
    <Container>
      {props.children}
    </Container>
  )
}

interface Props {
  children: any;
}

export const Container = styled.div`
  width: 100%;
  min-width: 280px;
  & > * {
    margin-bottom: 16px;
  }
  @media (${desktopSmall}) {
    width: 20%;
  }
`
