import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";

export const DeskPickerContentCard = styled.div`
  box-shadow: 0px 9px 22px 0px rgba(46, 74, 148, 0.08);
  padding: 24px 32px;
  border-radius: 12px;
  border: 1px solid ${Colours.lightGrey};
  background: white;
`
