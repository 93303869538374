import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, Legend, YAxis } from "recharts";
import Colours from "../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { CustomToolTip } from "./CustomToolTip";

export function OfficeConfirmationLineChart(props: Props) {
  const {reportData} = props;
  return (
    <ReportingContainer style={{height: 400}}>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={400}
          data={reportData}
          margin={{top: 10, right: 30, left: 0, bottom: 0}}
        >
          <CartesianGrid stroke={Colours.mildGrey} strokeDasharray="5 5"/>
          <XAxis dataKey="name" fontFamily={'proxima-nova'} fill={Colours.mildGrey} fontSize={18} axisLine={false} />
          <YAxis fontFamily={'proxima-nova'} fill={Colours.mildGrey} fontSize={18} axisLine={false} />
          <Tooltip content={<CustomToolTip />} />
          <Line type="monotone" dataKey="officeBookings" stroke={Colours.blue} />
          <Line type="monotone" dataKey="confirmations" stroke={Colours.green} />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </ReportingContainer>
  )
}

interface Props {
  reportData: any[];
}

const ReportingContainer = styled.div`
  .recharts-tooltip-wrapper {
    .customTooltip {
      color: red !important;
    }
  }
`
