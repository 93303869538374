import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../store/ducks/auth.duck";
import { loadConfig, selectHasGlobalConfigLoaded, selectIsConfigLoading } from "../store/ducks/config.duck";

export function LoadConfig() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const isConfigLoading = useSelector(selectIsConfigLoading);
  const hasGlobalConfigLoaded = useSelector(selectHasGlobalConfigLoaded);
  useEffect(() => {
    if (current && !isConfigLoading && !hasGlobalConfigLoaded) {
      dispatch(loadConfig());
    }
  }, [dispatch, current, isConfigLoading, hasGlobalConfigLoaded]);
}
