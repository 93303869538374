import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge } from '../../../../UI/atoms/fonts/Headline';
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { redirectTo, selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import PlanUsageContentCard from "./PlanUsageContentCard";
import CancelSection from "./CancelSection";
import { Permission } from "../../../../../models/user.models";
import AccessRequired from "../../components/AccessRequired";
import {
  loadAccountInfo,
  openNewPaymentDialog,
  selectAccountInfo,
  selectIsAccountActive
} from "../../../../../store/ducks/payments.duck";
import { paymentsEnabled } from "../../../../../services/EnvironmentVariables";
import { getCustomerPortal } from "../../../../UI/organisms/stripe/stripeService";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import Colours from "../../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { AccountSummarySection } from "./AccountSummarySection";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { isInTeamsStoreApp } from "../../../../../utils/TeamsUtils";
import { TrackJS } from "trackjs";
import { failureNotification } from "../../../../../store/ducks/notification.duck";
import { Trans, useTranslation } from "react-i18next";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";

export default function PaymentsPage() {
  const dispatch = useDispatch();
  const accountInfo = useSelector(selectAccountInfo);
  const isAccountActive = useSelector(selectIsAccountActive);
  const user = useSelector(selectCurrentUser);
  const isAdmin = user?.role === Permission.CompanyAdmin;
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (isInTeamsStoreApp()) {
      dispatch(redirectTo('/manage'))
    } else if (isAdmin) {
      dispatch(loadAccountInfo());
    }
  }, [dispatch, isAdmin])

  if (!paymentsEnabled) {
    window.location.href = '/manage/teams-and-users'
  }

  const createNewSubscription = async () => {
    dispatch(openNewPaymentDialog());
  }

  const openStripePortal = async () => {
    try {
      setIsLoading(true);
      window.location.href = (await getCustomerPortal()).url;
    } catch (e: any) {
      console.error(e)
      TrackJS?.track(e)
      dispatch(failureNotification('There has been an issue opening the Stripe payments portal'));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner fullScreen={true}/>}
      <GoBackButton/>
      <HeadlineLarge className="management__title">{t('settings.billing-header')}</HeadlineLarge>

      <AccessRequired visible={!isAdmin} companyAdminsRequired={true}/>

      <PlanUsageContentCard/>

      {accountInfo?.usesStripe ? <>
        {isAccountActive && <AccountSummarySection/>}

        {isAdmin && <>
          <PaymentDetailsContainer>
            {(accountInfo?.billTotal > 0 || accountInfo?.hasSubscription) ?
              <OutlineButton size="large" click={openStripePortal} text="button.view-subscription"/> :
              <OutlineButton size="large"
                               click={createNewSubscription}
                               text="button.create-subscription"/>}

          </PaymentDetailsContainer>
        </>}

      </> : <div style={{marginBottom: 32}}>
        <BodyLarge weight={600} style={{marginBottom: 16}}>{t('settings.payment-details')}</BodyLarge>
        <BodyRegular weight={400}><Trans i18nKey={'settings.cancel-message'} components={[<a style={{color: Colours.blue}} href={'mailto:hello@team-today.com'}>hello@team-today.com</a>]} /></BodyRegular>
      </div>}

      <CancelSection/>
    </>
  );
}

const PaymentDetailsContainer = styled.div`
  margin-bottom: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${Colours.mildGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    max-width: 400px;
    margin-bottom: 16px;
  }
`
