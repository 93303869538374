import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { BodyRegular, BodySmall, BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../../store/ducks/config.duck";
import moment from "moment";
import Colours from "../../../../UI/atoms/Colours";

export function HolidayAllowanceHeaderRow(props: Props) {
  const config = useSelector(selectConfig);

  const previousYearString = useMemo(() => {
    const start = config.getHolidayYearStartDate(moment().subtract(1, 'year'))
    const end = config.getHolidayYearEndDate(moment().subtract(1, 'year'));
    return start.format('MMM YYYY') + ' to ' + end?.format('MMM YYYY')
  }, [config]);

  const thisYearString = useMemo(() => {
    const start = config.getHolidayYearStartDate(moment())
    const end = config.getHolidayYearEndDate(moment());
    return start.format('MMM YYYY') + ' to ' + end?.format('MMM YYYY')
  }, [config]);

  const nextYearString = useMemo(() => {
    const start = config.getHolidayYearStartDate(moment().add(1, 'year'))
    const end = config.getHolidayYearEndDate(moment().add(1, 'year'));
    return start.format('MMM YYYY') + ' to ' + end?.format('MMM YYYY')
  }, [config]);

  return (
    <Container>
      <TopLeft>
        <BodyVerySmall weight={600} style={{marginBottom: -4}} title={"This is the numbers of days leave a user will automatically get if they don't have a value assigned to them"}>Default allowance</BodyVerySmall>
        <BodyRegular weight={600}>{config.companyHolidayAllowance} days</BodyRegular>
      </TopLeft>
      <HeaderItem>
        <HeadlineSmall>Previous Year</HeadlineSmall>
        <BodySmall weight={600} colour={Colours.darkGrey}>{previousYearString}</BodySmall>
      </HeaderItem>
      <HeaderItem>
        <HeadlineSmall>Current Year</HeadlineSmall>
        <BodySmall weight={600} colour={Colours.darkGrey}>{thisYearString}</BodySmall>
      </HeaderItem>
      <HeaderItem>
        <HeadlineSmall>Next Year</HeadlineSmall>
        <BodySmall weight={600} colour={Colours.darkGrey}>{nextYearString}</BodySmall>
      </HeaderItem>
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid ${Colours.lightGrey};
  justify-content: space-between;
`

const TopLeft = styled.div`
  width: 15%;
  padding: 18px 20px 12px 20px;
`

const HeaderItem = styled.div`
  width: 25%;
  border-left: 1px solid ${Colours.lightGrey};
  padding: 12px 20px;
`
