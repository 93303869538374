import React from 'react';
import { Switch } from "react-router";
import PrivateRoute from "../../routing/PrivateRoute";
import { RoomFinderPage } from "./pages/RoomFinderPage";
import { RoomBookings } from "./pages/RoomBookings";

export function MeetingRoomRouter(props: Props) {
  return (
    <Switch>
      <PrivateRoute exact path="/meeting-rooms" component={RoomFinderPage}/>
      <PrivateRoute exact path="/meeting-rooms/my-bookings" component={RoomBookings}/>
    </Switch>
  )
}

interface Props {
}
