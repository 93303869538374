import React, { useMemo } from 'react';
import { WeekNote } from "../../../../services/WeekNotesService";
import { Row } from "../../../UI/atoms/StructuralLayout";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { BodyRegular, BodyXXLSmall } from "../../../UI/atoms/fonts/Body";
import { UsersWhereaboutsIconsRow } from "./UsersWhereaboutsIconsRow";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";

export function UsersWeekNote(props: Props) {
  const {weekNote} = props;
  const lastUpdated = useMemo(() => moment(weekNote.lastUpdated).format(DATE_FORMAT), [weekNote]);
  const {t} = useTranslation()
  return (
    <Row style={{marginBottom: 8}}>
      <UserAvatarAndName firstName={weekNote.firstName} lastName={weekNote.lastName} hideName={true} />
      <NoteBackground>
        <BodyRegular weight={400} style={{marginBottom: 10, whiteSpace: 'pre-wrap'}}>{weekNote.note}</BodyRegular>
        <UsersWhereaboutsIconsRow whereabouts={weekNote.whereabouts} />
        <LastUpdated>
          <BodyXXLSmall colour={Colours.darkGrey}>{t('dialog.week-note-last-updated', {lastUpdated: lastUpdated})}</BodyXXLSmall>
        </LastUpdated>
      </NoteBackground>
    </Row>
  )
}

interface Props {
  weekNote: WeekNote;
}

const NoteBackground = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: ${Colours.veryLightGrey};
  border-radius: 12px;
  margin-left: 16px;
  position: relative;
`

const LastUpdated = styled.div`
  position: absolute;
  right: 16px;
  bottom: 8px;
`

