import './_button.scss';
import styled from "styled-components/macro";
import { IconTypes } from "../icon/Icon";


export interface NewButtonProps {
  dataTest?: string;
  buttonType?: 'reset' | 'submit' | 'button';
  style?: any;
  click?: any;
  icon?: IconTypes;
  text?: string;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  className?: string;
  imageUrl?: string;
  link?: string;
  subtext?: string;
  borderColour?: string;
}

export const BaseButton = styled.button<any>`
  padding: 4px 16px;
  border-radius: 6px;
  font-family: soleil, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  transition: opacity 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    margin: 0 auto;
  }
  i {
    display: inline-flex;
  }

  width: 100%;
  max-width: ${props => props.fullWidth ? '100%' : (props.size === 'small' ? '190px' : (props.size === 'medium' ? '192px' : '295px'))};

  height: ${props => props.size === 'small' ? '32px' : (props.size === 'medium' ? '40px' : '50px')};
  min-height: ${props => props.size === 'small' ? '32px' : (props.size === 'medium' ? '40px' : '50px')};

  span {
    font-size: ${props => props.size === 'small' ? '14px' : (props.size === 'medium' ? '14px' : '16px')};;
  }
`
