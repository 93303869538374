import React from 'react';
import './logout-menu-item.scss'
import { Auth } from "../../../../../../services/Auth";
import { useDispatch, useSelector } from "react-redux";
import { Column, Row } from '../../../../atoms/StructuralLayout';
import { AuthType, GetCurrentAuthType } from "../../../../../../utils/AuthUtils";
import { OutlineButton } from '../../../../atoms/buttons/OutlineButton';
import { MicrosoftLogoutButton } from "../../../../../pages/login/MicrosoftLogoutButton";
import { isInTeams } from "../../../../../../utils/TeamsUtils";
import { MicrosoftTeamsLogoutButton } from "../../../../../pages/login/MicrosoftTeamsLogoutButton";
import { selectIsInTeamsApp } from "../../../../../../store/ducks/auth.duck";
import { HeadlineXtraSmall } from "../../../../atoms/fonts/Headline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LogoutMenuItem(props: Props) {
  const authType = GetCurrentAuthType();
  if (authType === AuthType.Microsoft && !isInTeams()) {
    return <MicrosoftLogoutButton {...props} />
  } else if (authType === AuthType.Microsoft && isInTeams()) {
    return <MicrosoftTeamsLogoutButton {...props} />
  } else {
    return <StandardLogoutMenuItem {...props} />
  }
}

function StandardLogoutMenuItem(props: Props) {
  const dispatch = useDispatch();
  const isInTeamsApp = useSelector(selectIsInTeamsApp);
  const {t} = useTranslation();
  const logout = () => {
    Auth.logout(dispatch)
    props.onClick();
  };

  if (isInTeamsApp) {
    return <Row className="logoutMenuItem">
      <Link to="#" onClick={logout} className="logoutMenuItem__link noSelect">
        <HeadlineXtraSmall className="logoutMenuItem__text">{t('button.logout')} </HeadlineXtraSmall>
        <img src="/assets/icons/font/Logout.svg" alt="" />
      </Link>
    </Row>
  } else {
    return (
      <Column className="logoutMenuItem">
        <OutlineButton click={logout} text="button.logout" />
      </Column>
    )
  }
}

interface Props {
  onClick: () => any;
}
