import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { HeadlineMedium } from "../atoms/fonts/Headline";
import { tablet } from "../atoms/MediaQueries";

interface Props {
  onChange: any;
  value: number;
  limit: number;
  increment: number;
  minValue: number;
}

export function SwitchCounter(props: Props) {
  const increment = (val: number) => {
    const newValue = props.increment * val + props.value;
    if (newValue < props.minValue) {
      props.onChange(props.minValue);
    } else if (newValue > props.limit) {
      props.onChange(props.limit);
    } else {
      props.onChange(props.increment * val + props.value);
    }
  }

  return (
    <SwitchCounterContainer>
      <SwitchButton onClick={() => increment(-1)}><img src="/assets/icons/BlueMinus.svg" alt="10 less users"/></SwitchButton>
      <SwitchValue>{props.value}</SwitchValue>
      <SwitchButton onClick={() => increment(1)}><img src="/assets/icons/BlueAdd.svg" alt="10 more users"/></SwitchButton>
    </SwitchCounterContainer>
  )
}

export const SwitchValue = styled<any>(HeadlineMedium)`
  border-left: 1px ${Colours.mildGrey} solid;
  border-right: 1px ${Colours.mildGrey} solid;
  padding: 0 10%;
  user-select: none;
`

export const SwitchButton = styled.div`
  min-height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  transition: background-color .25s;
  cursor: pointer;
  user-select: none;
  @media (${tablet}) {
    &:hover {
      background-color: ${Colours.blue10};
    }
  }
  
`

export const SwitchCounterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 32px;
  box-shadow: 0 0 17px 2px rgba(0, 87, 255, 0.15);
  margin-bottom: 48px;
  overflow: hidden;
  min-height: 64px;
  user-select: none;
`
