import React from 'react';
import styled from "styled-components/macro";
import { BodyVerySmall } from "../../UI/atoms/fonts/Body";
import Colours from "../../UI/atoms/Colours";
import { EditableDesk } from "../../../services/DeskBookingService";

export function EditableDeskSpot(props: Props) {
  const {desk, selected} = props;
  return (
    <DeskOverlay x={desk.x} y={desk.y}
                 isVisible={selected}
                 width={desk.width}
                 height={desk.height}
                 rotate={desk.rotation ?? 0} onClick={(e: any) => props.onClick(e, desk)}
                 onMouseDown={() => props.onMouseDown(desk)}
                 onMouseUp={() => props.onMouseUp(desk)}
    >
      <DeskLabel weight={600}>{desk.label}</DeskLabel>
    </DeskOverlay>
  )
}

interface Props {
  desk: EditableDesk;
  selected?: boolean;
  onClick: (e: any, desk: EditableDesk) => void;
  onMouseDown: (desk: EditableDesk) => void;
  onMouseUp: (desk: EditableDesk) => void;
}

const DeskLabel = styled<any>(BodyVerySmall)`
  z-index: 10;
`

const DeskOverlay = styled.div<any>`
  width: ${props => `${props.width}px`};
  min-height: ${props => `${props.height}px`};
  border-radius: 3px;
  background-color: ${Colours.transparent};
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  border: ${props => props.isVisible ? `3px solid ${Colours.green}` : `3px solid transparent`};
  transform: rotate(${props => props.rotate}deg);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color .1s ease-in-out;
  padding: 2px;
  text-align: center;
  &:hover {
    border: 3px solid ${Colours.green};
  }
`
