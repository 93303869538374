import { agent } from './agent';

export async function getKeyResponsibilitiesConfig(officeId: number): Promise<KeyResponsibilitiesSetting[]> {
  return await agent.requests.get(`/api/key-responsibilities-config?office=${officeId}`);
}

export async function saveConfig(setting: KeyResponsibilitiesSetting): Promise<void> {
  await agent.requests.post('/api/key-responsibilities-config', setting);
}

export interface KeyResponsibilitiesSetting {
  id: number;
  officeId: number;
  configId: string;
  value: string;
}
