import React from 'react';
import { useDispatch } from 'react-redux';
import { next } from '../../../../store/ducks/onboarding.duck';
import { Column } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { BodyLarge } from '../../../UI/atoms/fonts/Body';
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";

export default function OnboardingOfficeCreated() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <img style={{width: 340}}
             className="onboarding__image"
             src="/assets/images/onboarding-office-created.svg" alt="" aria-hidden={true} />
        <Icon icon={IconTypes.Tick} circle={true} color="blue" size="large" className="onboarding__icon" />

        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.office-created-title')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 48}}>{t('onboarding.office-created-message')}</BodyLarge>

        <PrimaryButton dataTest="onboarding-officeCreated-next"
                size="large"
                text="button.got-it"
                click={() => dispatch(next())}
                fullWidth={true}/>
      </Column>
    </div>
  )
}
