import React, { useState } from 'react';
import moment, { Moment } from "moment";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { DateInputField } from "../../../UI/molecules/DateInputField";
import FullScreenDateRangeSelector from "../../../UI/molecules/calendar/FullScreenDateRangeSelector";
import { useDispatch, useSelector } from "react-redux";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { DownloadDetailedAbsenceReportButton } from "./DownloadDetailedAbsenceReportButton";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { isOfficeAdminUser } from "../../../../utils/AccessControl";

export function DownloadAbsenceReport(props: Props) {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const {t} = useTranslation();
  const user = useSelector(selectCurrentUser);
  const canAccessReport = isOfficeAdminUser(user);

  const onDatesSelected = (from?: Moment, to?: Moment) => {
    if (!from || !to) {
      dispatch(warningNotification("Both from and to dates required"));
      return;
    }
    setFromDate(from);
    setToDate(to);
  }

  return (
    <>
      <Wrapper>
        <HeadlineSmall style={{marginBottom: 8}}>{t('reports-page.download-absence-summary')}</HeadlineSmall>
        <Row>

          <DateInputField onClick={() => setIsCalendarOpen(true)} placeholder="textinput.from"
                          value={fromDate ? fromDate.format('D MMMM YYYY') : ''}
                          label="textinput.from"
                          editableOnly={true} />

          <DateInputField onClick={() => setIsCalendarOpen(true)} placeholder="textinput.to"
                          value={toDate ? toDate.format('D MMMM YYYY') : ''}
                          label="textinput.to"
                          editableOnly={true} />


          <DownloadDetailedAbsenceReportButton fromDate={fromDate} toDate={toDate} disabled={canAccessReport} />
        </Row>
      </Wrapper>

      <FullScreenDateRangeSelector open={isCalendarOpen}
                                   onDateSelect={onDatesSelected}
                                   onClose={() => setIsCalendarOpen(false)}
                                   activeToDate={true}
                                   startDate={fromDate} endDate={toDate}
                                   initialDate={fromDate ?? moment()} />
    </>
  )
}

interface Props {
}

const Wrapper = styled.div`
  margin: 36px 0 24px 0;
  
  .textField {
    max-width: 200px;
    margin-bottom: 0;
    margin-right: 12px;
  }
  button {
    align-self: flex-end;
    margin-bottom: 2px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 24px;
    padding-right: 24px;
  }
  button {
    margin-left: 32px;
  }
  .dateFlicker {
    max-width: 300px;
  }
`
