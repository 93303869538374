import { useState } from "react";
import { MeetingRoom } from "../models/MeetingRooms";
import { useDispatch } from "react-redux";
import { failureNotification, successNotification } from "../../../../store/ducks/notification.duck";
import { bookMeetingRoomRequest } from "../../../../services/MeetingRoomsService";
import { refreshBookings } from "../models/room-finder.duck";
import moment from "moment";

export function MeetingRoomBookingConfirmation() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const bookMeetingRoom = async (meetingRoom: MeetingRoom, startDateTime: moment.Moment, endDateTime: moment.Moment, meetingName: string, addToCalendar: boolean) => {
    const bookingFromDateTime = startDateTime.clone();
    const bookingToDateTime = endDateTime.clone();
    bookingFromDateTime.seconds(0);
    bookingFromDateTime.milliseconds(0);
    bookingToDateTime.seconds(0);
    bookingToDateTime.milliseconds(0);

    if (bookingFromDateTime.isAfter(bookingToDateTime)) {
      dispatch(failureNotification('Booking end time cannot be before to time'));
      throw new Error('Booking end time cannot be before to time');
    }

    if (!bookingFromDateTime.isSame(bookingToDateTime, 'day')) {
      dispatch(failureNotification('Book cannot be over multiple days'));
      throw new Error('Book cannot be over multiple days');
    }

    if (!meetingName) {
      dispatch(failureNotification('Meeting name cannot be blank'));
      throw new Error('Meeting name cannot be blank');
    }

    if (bookingFromDateTime.isBefore(moment())) {
      dispatch(failureNotification('Cannot book meeting room in the past'));
      throw new Error('Cannot book meeting room in the past');
    }

    setLoading(true)
    try {
      await bookMeetingRoomRequest(meetingRoom, bookingFromDateTime, bookingToDateTime, meetingName, addToCalendar);
      dispatch(refreshBookings());
      dispatch(successNotification('Meeting room booked'));
    } catch (e) {
      dispatch(failureNotification('Failed to book meeting room'))
      throw e;
    } finally {
      setLoading(false);
    }

  }

  return {
    confirmatonLoading: loading,
    bookMeeting: bookMeetingRoom,
  }
}
