import React, { useState } from 'react';
import { OfficeDropDownList } from "../../../../UI/organisms/OfficeDropDownList";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import Colours from "../../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../../../../store/ducks/auth.duck";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { UpdateUserField, updateUserField } from "../../../../../services/UserService";

export function UsersDefaultOffice(props: Props) {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const onOfficeSelect = async (office: OfficeEntity) => {
    if (!current) {
      return;
    }

    if (office.id !== current?.defaultLocationId) {
      try {
        setLoading(true);
        await updateUserField(current.id, UpdateUserField.defaultOfficeId, office.id);
        dispatch(setUser({...current, defaultLocationId: office.id}));
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Container>
      <BodyLarge colour={Colours.blue} weight={400} style={{marginBottom: 12}}>{t('personal-perf.default-office-title')}</BodyLarge>
      <BodyRegular style={{marginBottom: 24}} weight={400}>{t('personal-perf.default-office-description')}</BodyRegular>
      <OfficeListRow>
        <OfficeListContainer>
          <OfficeDropDownList onSelect={onOfficeSelect}
                              disabled={loading}
                              preSelectedOffice={current?.defaultLocationId}
                              label={"personal-perf.default-office-and-location"} />
        </OfficeListContainer>
        {loading && <SimpleSpinner size={24} />}
      </OfficeListRow>
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  margin-bottom: 48px;
  padding-bottom: 48px;
  border-bottom: 1px solid ${Colours.lightGrey};
`

const OfficeListContainer = styled.div`
  flex: 1;
`

const OfficeListRow = styled.div`
  display: flex;
  align-items: center;
  .simple-spinner {
    align-self: flex-end !important;
    margin-bottom: 8px;
    margin-left: 8px;
  }
`
