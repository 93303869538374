import React from 'react';
import { useDispatch } from 'react-redux';
import { next } from '../../../../store/ducks/onboarding.duck';
import { Column, Row } from '../../../UI/atoms/StructuralLayout';
import { HeadlineLarge } from '../../../UI/atoms/fonts/Headline';
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import {
  setHolidayYearRecurringDay,
  setHolidayYearRecurringMonth
} from "../../settings/pages/edit-company-page/editCompany.duck";
import { Button } from "../../../UI/atoms/buttons/Button";
import {
  MONTHS,
  START_OPTIONS,
  StartDateDropDown
} from "../../settings/pages/holiday-settings-page/HolidayYearSetting";
import { useTranslation } from "react-i18next";

export default function SetupHolidayRules() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <HeadlineLarge style={{marginBottom: 12}}>{t('settings.holiday-rules')}</HeadlineLarge>
        <BodyLarge style={{marginBottom: 16}}>{t('settings.holiday-year-start-message')}</BodyLarge>

        <Row>
          <StartDateDropDown items={START_OPTIONS}
                             onSelect={(v: any) => dispatch(setHolidayYearRecurringDay(v))}
                             selectedId={0}
                             placeholder="textinput.select-start-day"/>
        </Row>
        <Row>
          <StartDateDropDown items={MONTHS}
                             onSelect={(v: any) => dispatch(setHolidayYearRecurringMonth(v))}
                             selectedId={1}
                             placeholder="textinput.select-month"/>
        </Row>

        <Button dataTest="onboarding-hotDesking-next"
                size="large"
                text="button.done"
                type="primary"
                onClick={() => dispatch(next())}
                fullWidth={true}/>
      </Column>
    </div>
  )
}
