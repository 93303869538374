import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { selectOffice } from "../../../../store/ducks/officeView.duck";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { Moment } from "moment";
import { WithOfficeAvailability } from "../../../../hooks/WithOfficeAvailability";

interface Props {
  area: OfficeEntity;
  date: Moment;
  isActive: boolean
  onSelect?: () => any;
}

export function AreaButton(props: Props) {
  const {area, isActive, date} = props;
  const dispatch = useDispatch();

  const officeAvailability = WithOfficeAvailability(area, date);

  const onClick = () => {
    dispatch(selectOffice(props.area));
    if (props.onSelect) {
      props.onSelect();
    }
  }

  useEffect(() => {
    officeAvailability.reloadAvailability();
  }, [officeAvailability]);

  return (
    <AreaButtonWrapper onClick={onClick} isActive={isActive}>
      <BodyRegular colour={isActive ? Colours.black : Colours.darkGrey} weight={600}>{area.label}</BodyRegular>
      <BodyRegular colour={Colours.darkGrey}>{officeAvailability.spacesAvailable()} desks available</BodyRegular>
    </AreaButtonWrapper>
  )
}

export const AreaButtonWrapper = styled.div<any>`
  cursor: pointer;
  margin-bottom: 16px;
  border: ${props => props.isActive ? `2px solid ${Colours.blue}` : `1px solid ${Colours.mildGrey}`};
  width: 100%;
  padding: 8px 0 8px 8px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`
