import React, { useMemo } from 'react';
import { BodyLarge } from "../../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import { SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import Colours from "../../../../UI/atoms/Colours";
import { useSelector } from "react-redux";
import { selectAccountInfo } from "../../../../../store/ducks/payments.duck";
import moment from "moment";
import { useTranslation } from "react-i18next";

export function AccountSummarySection(props: Props) {
  const accountInfo = useSelector(selectAccountInfo);
  const {t} = useTranslation();
  const isFreeTrialActive = useMemo(() => !!accountInfo?.isFreeTrial, [accountInfo]);
  const expires = useMemo(() => {
    return !!accountInfo && accountInfo.endDateString ? moment(accountInfo.endDateString).format('Do MMMM YY') : '??';
  }, [accountInfo])

  return (
    <Wrapper>
      {isFreeTrialActive &&
        <>
          <InfoRow>
            <BodyLarge>{t('payments.free-trial-ends')}</BodyLarge>
            <BodyLarge weight={600}>{expires}</BodyLarge>
          </InfoRow>
        </>}
      <>
        <InfoRow style={{border: 'none'}}>
          <BodyLarge>{t('payments.view-payment-plan')}</BodyLarge>
          <img src="/assets/icons/powered-by-stripe-purple.svg" alt="Powered by Stripe" />
        </InfoRow>
      </>
    </Wrapper>
  )
}

interface Props {
}

const InfoRow = styled<any>(SpaceBetweenRow)`
  border-bottom: 1px solid ${Colours.mildGrey};
  padding: 12px 0;
`

const Wrapper = styled.div`
  margin-bottom: 32px;
`
