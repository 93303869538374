import React, { useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';
import Calendar, { HighlightDay } from './Calendar';
import './full-screen-calendar.scss';
import styled, { css } from "styled-components";
import { BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { getListOfDaysBetween, workdayCount } from "../../../../utils/DateUtils";
import Dialog from "../Dialog";
import { PrimaryButton } from "../../atoms/buttons/PrimaryButton";
import { HeadlineMedium } from "../../atoms/fonts/Headline";

interface Props {
  open?: boolean;
  onClose?: any;
  onDateSelect?: (startDate?: Moment, endDate?: Moment) => void;
  onSingleDateSelect?: (date: Moment) => void;
  initialDate?: Moment;
  startDate?: Moment | null;
  endDate?: Moment | null;
  activeToDate?: boolean;
  selectionType?: SelectionType;
}

export enum SelectionType {
  DateRange, SingleDate
}

export default function FullScreenDateRangeSelector(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isStartDateActive, setIsStartDateActive] = useState(false);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const selectionType = props.selectionType ?? SelectionType.DateRange;

  const workDays = useMemo(() => startDate && endDate ? workdayCount(startDate, endDate) : 0, [startDate, endDate]);
  const highlightedDays = useMemo(() => {
    return getListOfDaysBetween(startDate, endDate)
      .map((date) => ({ date: date, am: true, pm: true, color: 'blue', } as HighlightDay))
  }, [startDate, endDate]);

  useEffect(() => {
    setIsStartDateActive(!props.activeToDate)
  }, [props.activeToDate])

  useEffect(() => isOpen ? document.body.classList.add('noscroll') : document.body.classList.remove('noscroll'), [isOpen]);
  useEffect(() => {
    setIsHidden(false);
    setTimeout(() => setIsOpen(props.open || false), 10);
    setStartDate(props.startDate ?? null);
    setEndDate(props.endDate ?? null);
  }, [props]);
  useEffect(() => () => document.body.classList.remove('noscroll'), []);

  const close = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
  }

  const done = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
    setTimeout(() => setIsHidden(true), 300);
    if (selectionType === SelectionType.SingleDate && props.onSingleDateSelect && startDate) {
      props.onSingleDateSelect(startDate);
    }
    if (startDate && endDate && props.onDateSelect) {
      props.onDateSelect(startDate, endDate);
    }
  }

  const onDateSelected = (date: Moment) => {
    if (selectionType === SelectionType.SingleDate) {
      setStartDate(date);
      return;
    }

    if (isStartDateActive || date.isBefore(startDate)) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setIsStartDateActive(false);
  }

  if (!isOpen && isHidden) {
    return null;
  }

  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <div className="fullScreenCalendar" data-test={"date-range-picker"}>
        {selectionType === SelectionType.SingleDate && <HeadlineMedium style={{marginBottom: 24}}>Select a date</HeadlineMedium>}
        {selectionType === SelectionType.DateRange &&
          <RangeRow>
            <RangeSelector isSelected={isStartDateActive} onClick={() => setIsStartDateActive(true)}>
              <BodyRegular weight={600}>Start date</BodyRegular>
            </RangeSelector>
            <RangeSelector isSelected={!isStartDateActive} onClick={() => setIsStartDateActive(false)}>
              <BodyRegular weight={600}>end date</BodyRegular>
            </RangeSelector>
          </RangeRow>}
        <CalendarWrapper>
          <Calendar enabledDateRange={true}
                    stretch={true}
                    initialDate={startDate}
                    activeDate={startDate}
                    endDate={endDate}
                    hideDaysOutsideOfMonth={true}
                    highlightedDays={highlightedDays}
                    onDateSelect={(date: Moment) => onDateSelected(date)}/>
          {selectionType === SelectionType.DateRange && <BodyRegular weight={600} style={{textAlign: 'center'}}>{workDays} work day selected</BodyRegular>}
          <PrimaryButton text="button.done"
                         dataTest={"date-range-done-button"}
                         size="large"
                         click={() => done()}
                         fullWidth={true}/>
        </CalendarWrapper>
      </div>
    </Dialog>
  );
}

export const RangeRow = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 32px;
`

export const RangeSelector = styled.div<{ isSelected: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid ${Colours.blue};
  transition: border-color .25s;

  p {
    text-align: center;
    margin: 8px 0;
  }

  ${props => css`border-color: ${props.isSelected ? Colours.blue : 'transparent'};`};
`

export const CalendarWrapper = styled.div`
  padding: 0 16px;

  .calendar {
    margin-bottom: 24px;
    & p {
      font-size: 16px;
    }

    & .calendar__day--header {
      font-size: 18px;
      font-weight: 600;
      color: ${Colours.darkGrey}
    }
  }
`
