import { useSelector } from "react-redux";
import { selectCurrentUser } from "../store/ducks/auth.duck";
import { selectAllTeams, selectHasGlobalConfigLoaded } from "../store/ducks/config.duck";
import { isCompanyAdminUser } from "../utils/AccessControl";

export function ShowOnboarding() {
  const current = useSelector(selectCurrentUser);
  const hasGlobalConfigLoaded = useSelector(selectHasGlobalConfigLoaded);
  const teams = useSelector(selectAllTeams);

  return hasGlobalConfigLoaded && isCompanyAdminUser(current) && teams.length === 0;
}
