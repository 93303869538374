import React from 'react';
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";

export function CustomLegend(props: TooltipProps<ValueType, NameType>) {
  const {payload} = props;
  if (payload && payload.length) {
    return (
      <LegendContainer>
        {payload.map((item: any, key) => (
          <ItemRow key={key}>
            <ColourBox colour={item.color} />
            <BodyRegular colour={Colours.darkGrey}>{item.value}</BodyRegular>
          </ItemRow>
        ))}
      </LegendContainer>
    )
  }
  return null;
}

const LegendContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  margin-top: 8px;
`

const ColourBox = styled.div<any>`
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${props => props.colour};
  margin-right: 4px;
`

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`
