import React, { useMemo } from 'react';
import { WhereaboutsBookingError, WhereaboutsBookingErrorType } from "../../../services/DeskBookingService";
import { BodyRegular, BodySmall } from "../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { WhereaboutsDay } from "../../../models/movements.models";
import styled from "styled-components/macro";
import { Pill } from "../../UI/atoms/Pill";
import Colours from "../../UI/atoms/Colours";
import moment from "moment";

function getErrorAccentColour(error: WhereaboutsBookingError) {
  switch (error.type) {
    case WhereaboutsBookingErrorType.NO_PARKING_SPACE: return Colours.orange;
    case WhereaboutsBookingErrorType.NOT_BOOKABLE_LOCATION: return Colours.white;
    default: return Colours.darkGrey;
  }
}

function getErrorBackgroundColour(error: WhereaboutsBookingError) {
  switch (error.type) {
    case WhereaboutsBookingErrorType.NO_PARKING_SPACE: return Colours.orange10;
    case WhereaboutsBookingErrorType.NOT_BOOKABLE_LOCATION: return Colours.red;
    default: return Colours.veryLightGrey;
  }
}

export function WhereaboutsErrorRow(props: Props) {
  const {errors, day} = props;
  const {t} = useTranslation();

  const prettyDate = useMemo(() => {
    if (day?.date) {
      return moment(day.date).format('dddd Do MMMM')
    } else {
      return '???';
    }
  }, [day]);

  const error = useMemo(() => {
    if (!day || !day.date) return;
    return errors.find(e => e.date === day.date);
  }, [errors, day]);

  return <Wrapper>
    <div className={'whereaboutsError--left'}>
      <BodyRegular style={{textAlign: 'right'}} weight={600}>{prettyDate}</BodyRegular>
    </div>
    <div className={'whereaboutsError--right'}>
      {error ? <>
        <Pill style={{borderColor: getErrorAccentColour(error)}}
              colour={getErrorAccentColour(error)}
              backgroundColour={getErrorBackgroundColour(error)}>
          <ErrorMessage errorType={error.type} />
        </Pill>
      </> : <>
        <Pill colour={Colours.white} backgroundColour={Colours.green}>
          <BodySmall weight={600}>{t('dialog.booked')}</BodySmall>
        </Pill>
      </>}

    </div>
  </Wrapper>
}

function ErrorMessage(props: {errorType: WhereaboutsBookingErrorType}) {
  const {t} = useTranslation();
  if (props.errorType === WhereaboutsBookingErrorType.NO_PARKING_SPACE) {
    return <BodySmall weight={600}>No parking space</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.OFFICE_FULL) {
    return <BodySmall weight={600}>Office full</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.NOT_BOOKABLE_LOCATION) {
    return <BodySmall weight={600}>Not a bookable location</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.OUT_OF_BOOKING_RANGE) {
    return <BodySmall weight={600}>Out of booking range</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.NO_ACCESS_TO_DESK) {
    return <BodySmall weight={600}>No access to desk</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.CANNOT_BOOK_OVER_APPROVED_HOLIDAY) {
    return <BodySmall weight={600}>Cannot book over approved holiday</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.CANNOT_EDIT_PAST_WHEREABOUTS) {
    return <BodySmall weight={600}>Cannot edit past whereabouts for that user</BodySmall>
  } else if (props.errorType === WhereaboutsBookingErrorType.DESK_NOT_ACTIVE) {
    return <BodySmall weight={600}>That desk is not active</BodySmall>
  } else {
    return <BodySmall weight={600}>{t('dialog.unavailable')}</BodySmall>
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${Colours.veryLightGrey};
  margin: 14px 0;
  
  &:last-child {
    border: none;
  }
  
  .whereaboutsError--left {
    width: 50%;
    align-items: flex-end;
    padding-right: 8px;
  }
  .whereaboutsError--right {
    width: 50%;
    align-items: flex-start;
    padding-left: 8px;
  }
`

interface Props {
  day: WhereaboutsDay;
  errors: WhereaboutsBookingError[];
}
