import { useEffect } from 'react';
import i18next from "i18next";

export const usePageName = (urlPath?: string) => {
  useEffect(() => {
    if (urlPath) {
      document.title = getPageTitle(urlPath);
    }
  }, [urlPath]);
};

const getPageTitle = (url: string) => {
  const lowerCasedUrl = url.toLowerCase();
  switch (lowerCasedUrl) {
    case '/holidays':
      return 'Team Today - Your holidays';
    case '/onboarding':
      return 'Team Today - Onboarding';
    case '/whereabouts/team':
      return 'Team Today - Team view';
    case '/whereabouts/company':
      return 'Team Today - Company whereabouts';
    case '/manage':
      return 'Team Today - Settings';
    case '/manage/teams-and-users':
      return 'Team Today - Team management';
    case '/login':
      return 'Team Today - Login';
    case '/register':
      return 'Team Today - Register';
    case '/forgot-password':
      return 'Team Today - Forgot password';
    case '/holidays/add':
      return 'Team Today - Add holiday';
    case '/manage/assign-users':
      return 'Team Today - Assign users';
    case '/manage/global-settings':
      return 'Team Today - Settings';
    case '/manage/company-holidays':
      return 'Team Today - Company holidays';
    case '/complete-registration':
      return 'Team Today - Complete registration';
    case '/reset-password':
      return 'Team Today - Reset password';
    case '/404':
      return 'Team Today - 404';
    case '/work-planner':
      return `Team Today - ${i18next.t('work-planner.work-planner')}`;
  }

  if (lowerCasedUrl.startsWith('/manage/company/')) {
    return 'Team Today - Company settings';
  } else if (lowerCasedUrl.startsWith('/manage/team/')) {
    return 'Team Today - Team settings';
  } else if (lowerCasedUrl.startsWith('/manage/user/')) {
    return 'Team Today - User settings';
  } else if (lowerCasedUrl.startsWith('/holidays/add/')) {
    return 'Team Today - Edit holiday';
  }  else if (lowerCasedUrl.startsWith('/work-planner/event')) {
    return 'Team Today - Edit team event / project';
  } else {
    return 'Team Today';
  }
}
