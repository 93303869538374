import React from 'react';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { LinkButton } from "../../../UI/atoms/buttons/LinkButton";
import { Column } from '../../../UI/atoms/StructuralLayout';
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import { AddToTeamsButton } from "../../../UI/atoms/AddToTeamsButton";
import { useTranslation } from "react-i18next";

export default function OnboardingComplete() {
  const {t} = useTranslation();
  return (
    <div className="onboarding" data-test="onboardingComplete">
      <Column className="onboarding__content">
        <img style={{width: 180, marginBottom: 24}}
             className="onboarding__image"
             src="/assets/images/onboarding-complete-image.svg"
             alt="" aria-hidden={true}/>

        <HeadlineMedium className="onboarding__title onboarding__complete">{t('onboarding.onboarding-complete')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 24}}>{t('onboarding.onboarding-complete-description')}</BodyLarge>

        <AddToTeamsButton style={{marginBottom: 48}} />

        <div className="onboardingComplete__controls">
          <LinkButton dataTest="onboardingCompleteButton"
                      size="large"
                      link="/whereabouts/team"
                      text="button.done-for-now"
                      fullWidth={true}
                      type="primary"/>
        </div>
      </Column>
    </div>
  )
}
