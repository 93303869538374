import { agent } from "./agent";


export async function getCompanyHolidayList(year: number): Promise<CompanyHolidayOverview> {
  const resp = await agent.requests.get(`/api/v2/companies-holidays?year=${year}`);
  return {
    totalCompanyHolidays: resp.length,
    companyHolidays: resp,
  }
}

export async function getCompanyHoliday(id: number): Promise<CompanyHoliday> {
  const resp = await agent.requests.get(`/api/v2/companies-holidays/${id}`, true);
  return resp
}

export async function deleteCompanyHoliday(id: number): Promise<void> {
  return await agent.requests.del(`/api/v2/companies-holidays/${id}`);
}

export async function createCompanyHoliday(holiday: CompanyHoliday): Promise<void> {
  return await agent.requests.post(`/api/v2/companies-holidays`, holiday);
}

export interface CompanyHolidayOverview {
  totalCompanyHolidays: number;
  companyHolidays: CompanyHoliday[];
}

export interface CompanyHoliday {
  id: number;
  companyId: string;
  date: string;
  label: string;
  companyHolidayOffices: number[];
}
