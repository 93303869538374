import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../state/app.state";
import { Moment } from "moment";
import { selectOffices } from "./advanceHotDeskingSetup.duck";
import { CapacityResponse, getOfficeCapacity } from "../../services/OfficeCapacityService";

export interface WeeklyDeskAvailability {
  requestingDate?: Moment;
  dailyAvailability: CapacityResponse[];
  loading: boolean;
}

export const initialWeeklyDeskAvailability: WeeklyDeskAvailability = {
  loading: false,
  dailyAvailability: [],
}


export const loadWeeklyAvailability: any = createAsyncThunk(
  'weeklyDeskAvailability/load',
  async (nothing: any, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const offices = selectOffices(appState);

    const date = appState.dashboard.calendarDate;

    let availability = [];
    if (date && offices.length === 1) {
      const officeId = offices[0].id;
      const startOfWeek = date.clone().isoWeekday('monday');
      availability.push(await getOfficeCapacity(officeId, startOfWeek));
      availability.push(await getOfficeCapacity(officeId, startOfWeek.clone().add(1, 'days')));
      availability.push(await getOfficeCapacity(officeId, startOfWeek.clone().add(2, 'days')));
      availability.push(await getOfficeCapacity(officeId, startOfWeek.clone().add(3, 'days')));
      availability.push(await getOfficeCapacity(officeId, startOfWeek.clone().add(4, 'days')));

      if (appState.auth.currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled) {
        availability.push(await getOfficeCapacity(officeId, startOfWeek.clone().add(5, 'days')));
        availability.push(await getOfficeCapacity(officeId, startOfWeek.clone().add(6, 'days')));
      }
    }

    return {
      requestingDate: date,
      dailyAvailability: availability,
      loading: false,
    }
  }
);
const weeklyDeskAvailabilitySlice = createSlice({
  name: 'weeklyDeskAvailability',
  initialState: initialWeeklyDeskAvailability,
  reducers: {
    reset: () => initialWeeklyDeskAvailability,
  },
  extraReducers: {
    [loadWeeklyAvailability.pending]: (state) => ({...state, loading: true }),
    [loadWeeklyAvailability.reject]: (state) => ({...state, loading: false }),
    [loadWeeklyAvailability.fulfilled]: (state, action) => ({...state, ...action.payload, loading: false }),
  }
});

export const {
  reset,
} = weeklyDeskAvailabilitySlice.actions;
export default weeklyDeskAvailabilitySlice.reducer;

export const selectWeeklyAvailability = (state: AppState) => state.weeklyDeskAvailability.dailyAvailability;
