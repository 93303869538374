import React from 'react';
import styled from "styled-components";
import Colours from "../../atoms/Colours";
import { useDispatch } from "react-redux";
import { setIsSearchBarVisible } from "../../../../store/ducks/dashboard.duck";

export function SearchButton(props: Props) {
  const dispatch = useDispatch();

  return <SearchIconContainer style={props.style ?? {}} className={props.className ?? ''}>
    <i className="icon-Search" onClick={() => dispatch(setIsSearchBarVisible(true))} style={{cursor: 'pointer', fontSize: 24}}/>
  </SearchIconContainer>
}

interface Props {
  style?: any;
  className?: string;
}

const SearchIconContainer = styled.div`
  display: flex;
  max-width: 100%;
  align-self: center;
  margin: 0 12px;
  color: ${Colours.white};
`
