import React, { useMemo } from 'react';
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { BodyRegular, BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { HolidayAllowanceContentCard } from "./HolidayAllowanceContentCard";
import { UsersHolidayAllowances } from "../../models/holidayAllowances.model";
import moment from "moment";

export function HolidayAllowanceUserRow(props: Props) {
  const {item} = props;

  const previous = useMemo(() => {
    const year = moment().year() - 1;
    return item.allowanceData.find(allowance => allowance.holidayYear === year);
  }, [item]);

  const current = useMemo(() => {
    const year = moment().year();
    return item.allowanceData.find(allowance => allowance.holidayYear === year);
  }, [item]);

  const next = useMemo(() => {
    const year = moment().year() + 1;
    return item.allowanceData.find(allowance => allowance.holidayYear === year);
  }, [item]);

  return (
    <Row>
      <NameContentCard>
        <UserAvatarAndName firstName={item.firstName} lastName={item.lastName} hideName={true} colour={'darkBlue'} />
        <BodyRegular weight={600}>{`${item.firstName} ${item.lastName}`}</BodyRegular>
        <BodyVerySmall colour={Colours.darkGrey} weight={600}>{item.jobTitle}</BodyVerySmall>
      </NameContentCard>
      <HolidayAllowanceContentCard disabled={true}
                                   accentColour={Colours.lightGrey}
                                   basicAllowance={previous?.basicAllowance ?? 0}
                                   remainingDays={previous?.remainingDays ?? 0}
                                   additionalDays={previous?.additionalDays ?? 0}
                                   totalDays={previous?.totalDays ?? 0}
                                   daysUsed={previous?.daysUsed ?? 0}
                                   userId={item.userId}
                                   year={previous?.holidayYear ?? 0}
                                   edited={previous?.edited} />

      <HolidayAllowanceContentCard disabled={false}
                                   accentColour={Colours.plannerGreen}
                                   basicAllowance={current?.basicAllowance ?? 0}
                                   remainingDays={current?.remainingDays ?? 0}
                                   additionalDays={current?.additionalDays ?? 0}
                                   totalDays={current?.totalDays ?? 0}
                                   daysUsed={current?.daysUsed ?? 0}
                                   userId={item.userId}
                                   year={current?.holidayYear ?? 0}
                                   edited={current?.edited} />

      <HolidayAllowanceContentCard disabled={false}
                                   accentColour={Colours.plannerOfficeGreen}
                                   basicAllowance={next?.basicAllowance ?? 0}
                                   remainingDays={next?.remainingDays ?? 0}
                                   additionalDays={next?.additionalDays ?? 0}
                                   totalDays={next?.totalDays ?? 0}
                                   daysUsed={next?.daysUsed ?? 0}
                                   userId={item.userId}
                                   year={next?.holidayYear ?? 0}
                                   edited={next?.edited} />
    </Row>
  )
}

interface Props {
  item: UsersHolidayAllowances;
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colours.veryLightGrey};
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
`

const NameContentCard = styled.div`
  width: 15%;
  min-height: 300px;
  border-radius: 12px;
  background-color: white;
  padding: 24px 20px;
  .userAvatarAndName {
    margin-bottom: 8px;
  }
`
