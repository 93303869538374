import React from 'react';
import { Tag } from "../../../../../models/tags.models";
import { Column } from "../../../atoms/StructuralLayout";
import { BodyRegular } from "../../../atoms/fonts/Body";
import styled from "styled-components/macro";
import { desktopSmall } from "../../../atoms/MediaQueries";
import IconButton, { IconButtonProps } from "../../../molecules/icon-button/IconButton";
import { isInTeamsStoreApp } from "../../../../../utils/TeamsUtils";
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { IconTypes } from "../../../atoms/icon/Icon";

export function EditTagMovementsRow(props: Props) {
  const {tag} = props;
  const dispatch = useDispatch();
  const onEditGroupClicked = () => {
    dispatch(openDialogWithPayload({
      payload: { tag: tag },
      activeDialog: DialogIdentifiers.EditUsersInTagDialog
    }));
  }
  return (
    <Row>
      <Column>
        <BodyRegular weight={600}>{tag.label}</BodyRegular>
      </Column>

      {!isInTeamsStoreApp() && <EditButton dataTest="editWorkGroupButton" onClick={onEditGroupClicked} icon={IconTypes.Edit} text="edit-workgroup.edit-workgroup"/>}
    </Row>
  )
}

interface Props {
  tag: Tag;
}

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: 8px;
  @media (${desktopSmall}) {
    margin-top: 0;
    padding: 16px;
  }
`

export const EditButton = styled<any>(IconButton)<IconButtonProps>`
  justify-self: flex-end;
  margin-left: auto;
  i {
    font-size: 30px;
  }
`
