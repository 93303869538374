import React, { useMemo, useState } from 'react';
import { ReactSelectDropDownList } from "./drop-down-list/ReactSelectDropDownList";
import styled from "styled-components/macro";
import { largeMobile } from "../atoms/MediaQueries";
import { useSelector } from "react-redux";
import { selectAllTeams } from "../../../store/ducks/config.duck";
import {
  isCompanyAdminUser,
  isDepartmentAdmin,
  isOfficeAdminUser,
  isTeamAdminUser
} from "../../../utils/AccessControl";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { selectAllOfficeEntities } from "../../../store/ducks/advanceHotDeskingSetup.duck";

export function FilterControlBar(props: Props) {
  const currentUser = useSelector(selectCurrentUser);
  const offices = useSelector(selectAllOfficeEntities);
  const teams = useSelector(selectAllTeams);
  const [selectedTypeOfGroupsToShow, setSelectedTypeOfGroupsToShow] = useState('');

  const optionsForSort = [{value: 'User name', label: 'Sort: User name'}]

  const rootOfficeId = useMemo(() => {
    if (currentUser?.defaultLocationId) {
      return offices.find((o: OfficeEntity) => o.id === currentUser?.defaultLocationId)?.id;
    }
  }, [offices, currentUser?.defaultLocationId])

  const teamsToShow = useMemo(() => {
    if (isCompanyAdminUser(currentUser) || isDepartmentAdmin(currentUser)) {
      return teams;
    } else if (isOfficeAdminUser(currentUser) && rootOfficeId) {
      return teams.filter(t => t.officeIds?.includes(rootOfficeId))
    } else if (isTeamAdminUser(currentUser)) {
      return teams.filter(t => t.id === currentUser?.teamEntity.id)
    }
    return [];
  }, [currentUser, teams, rootOfficeId]);

  const optionsForGroupToShow = useMemo(() => {
    const allOption = (isCompanyAdminUser(currentUser) || isDepartmentAdmin(currentUser)) ? [{value: 'ALL', label: 'Show: All'}] : [];
    return [
      {value: 'LINE_REPORTS', label: 'Show: Line reports'},
      {value: 'INDIRECT_LINE_REPORTS', label: 'Show: Indirect line reports'},
      ...allOption,
      ...teamsToShow.map(t => ({value: t.id, label: `${t.name} (${t.memberCount})`}))
    ]
  }, [teamsToShow, currentUser]);

  const onGroupToShowChanged = (selection: any) => {
    setSelectedTypeOfGroupsToShow(selection.value);
    props.onGroupSelected(selection.value);
  }

  const onSortChanged = (selection: any) => {
  }

  return (
    <ContentControls>
      <ReactSelectDropDownList selectedItem={optionsForGroupToShow.find(g => selectedTypeOfGroupsToShow === g.value)}
                               onItemSelected={onGroupToShowChanged} items={optionsForGroupToShow} />

      <ReactSelectDropDownList selectedItem={optionsForSort[0]}
                               disabled={true}
                               onItemSelected={onSortChanged}
                               items={optionsForSort} />
    </ContentControls>
  )
}

interface Props {
  onGroupSelected: (selection: any) => any;
}

const ContentControls = styled.div`
  display: flex;
  flex-direction: column;

  @media (${largeMobile}) {
    flex-direction: row;
    justify-content: space-between;
    & > * {
      width: 250px;
    }
  }
`
