import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { Auth } from '../../services/Auth';

export interface RegistrationState {
  errorMessage?: string;
  redirectTo: string;
  loading: boolean;
  success: boolean;
}

export const initialRegistrationState: RegistrationState = {
  errorMessage: undefined,
  redirectTo: '',
  loading: false,
  success: false,
}

export const register = createAsyncThunk(
  'registration/register',
  async (registrationRequest: any, thunkAPI) => {

    await thunkAPI.dispatch(setLoading(true));

    try {
      await Auth.register(registrationRequest);
      await thunkAPI.dispatch(setSuccess(true));
    } catch (err: any) {
      thunkAPI.dispatch(setError(err?.message || 'Log in failed'));
    }
  }
);

const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialRegistrationState,
  reducers: {
    setLoading: (state, action) => ({ ...state, loading: action.payload }),
    setError: (state, action) => ({ ...state, errorMessage: action.payload }),
    setSuccess: (state, action) => ({ ...state, success: action.payload }),
    redirectTo: (state, action) => ({ ...state, redirectTo: action.payload }),
    reset: () => ({...initialRegistrationState}),
  },
  extraReducers: {
    // eslint-disable-next-line
    ['registration/register/fulfilled']: (state) => {
      return {
        ...state,
        loading: false,
      }
    },
  }
});

export default registrationSlice.reducer
export const {
  setError,
  setLoading,
  reset,
  redirectTo,
  setSuccess,
} = registrationSlice.actions;

// Selectors
export const selectRegisterErrorMessage = (state: AppState) => state.registration.errorMessage;
export const selectRedirectTo = (state: AppState) => state.registration.redirectTo;
export const selectIsLoading = (state: AppState) => state.registration.loading;
export const selectIsSuccessful = (state: AppState) => state.registration.success;
