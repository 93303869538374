export function getParameterByName(name: string, url: string) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getHashParams() {
  const href = window.location.href;
  const params = href.split('#')[1];

  // Be sure url params exist
    if (params && params !== '') {
      return params.split('&').reduce(function (res, item) {
        const parts = item.split('=');
        // @ts-ignore
        res[parts[0]] = parts[1];
        return res;
      }, {});
    }
    return {}
}

export function setQueryParams(newQueryParams: string[]) {
  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + newQueryParams.join('&');
  window.history.pushState({path:newurl},'', newurl);
}

export function addOrReplaceQueryParam(key: string, value: string) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);

  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
  window.history.pushState({path:newurl},'', newurl);
}

export function deleteQueryParam(key: string) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(key);

  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryParams.toString();
  window.history.pushState({path:newurl},'', newurl);
}

export function redirectWithQueryParams(url: string) {
  const queryParams = new URLSearchParams(window.location.search);
  window.location.href = `${url}?${queryParams.toString()}`
}

export function clearLastPath() {
  localStorage.removeItem('lastPath');
}

export function updateLastPath(pathname: string) {
  if (localStorage.getItem('lastPath') !== pathname) {
    localStorage.setItem('lastPath', pathname);
  }
}

export function getLastPath(fallBack: string): string {
  return localStorage.getItem('lastPath') ?? fallBack;
}
