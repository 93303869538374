import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import Select from "react-select";
import Colours from "../atoms/Colours";
import makeAnimated from "react-select/animated";
import { BodySmall } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import {
  Department,
  findDepartmentInDepartments,
  sortDepartments
} from "../../pages/departments/data/departments.models";
import { selectAllDepartments } from "../../pages/departments/data/departments.duck";

const styles = {
  option: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    backgroundColor: 'white',
    color: Colours.black,
    cursor: 'pointer',
    '&:hover': {
      color: Colours.black,
      backgroundColor: Colours.blue10,
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
    fontSize: '18px',
  }),
  menu: (css: any) => ({
    ...css,
    width: "max-content",
    minWidth: "250px",
    zIndex: 100,
  }),
  placeholder: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: 'hsl(0, 0%, 80%)',
  }),
  noOptionsMessage: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  input: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: Colours.black,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Colours.darkGrey,
      color: 'white',
    },
  }),
};

interface DepartmentDropDownItem extends Department {
  value: number;
  label: string;
}

const animatedComponents = makeAnimated();

export function DepartmentsMultiSelectDropDownList(props: Props) {
  const {onSelect, selectedDepartmentIds} = props;
  const {t} = useTranslation();
  const allDepartments = useSelector(selectAllDepartments);
  const [selectedDepartments, setSelectedDepartments] = useState<DepartmentDropDownItem[]>([]);

  const dropDownDepartment: DepartmentDropDownItem[] = useMemo(() => {
    return allDepartments.map((d: Department) => {
      return {...d, label: d.name, value: d.id}
    }).sort(sortDepartments);
  }, [allDepartments]);

  useEffect(() => {
    if (selectedDepartmentIds && selectedDepartmentIds.length > 0) {
      const departments = selectedDepartmentIds
        .map(id => findDepartmentInDepartments(id, dropDownDepartment))
        .filter(department => !!department) as DepartmentDropDownItem[];
      setSelectedDepartments(departments);
    }
  }, [allDepartments, dropDownDepartment, selectedDepartmentIds]);

  const onItemSelected = (item: any) => {
    setSelectedDepartments(item);
    onSelect(item);
  }

  return (
    <>
      <BodySmall className="textField__label" weight={600}>{t(props.label)}</BodySmall>
      <Select
        options={dropDownDepartment}
        onChange={onItemSelected}
        value={selectedDepartments}
        styles={styles}
        closeMenuOnSelect={false}
        components={{ ...animatedComponents, ClearIndicator:() => null, IndicatorSeparator:() => null }}
        isMulti={true}
        isDisabled={props.disabled}
      />
    </>
  )
}

interface Props {
  onSelect: (departments: Department[]) => any;
  selectedDepartmentIds?: number[];
  disabled: boolean;
  label: string;
}

