import React from 'react';
import { Pill } from "./Pill";
import Colours from "./Colours";
import { BodyVerySmall } from "./fonts/Body";
import { useTranslation } from "react-i18next";

export function HolidayDaysPill(props: Props) {
  const {t} = useTranslation();
  return (
    <Pill colour={Colours.darkGreen} backgroundColour={Colours.green2}>
      <BodyVerySmall weight={600}>{t('holidays.holiday-duration', {duration: props.length})}</BodyVerySmall>
    </Pill>
  )
}

interface Props {
  length: number;
}
