import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  button {
    max-width: 100%;
    height: 42px;
  }
  @media (${tablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      max-width: 190px;
      height: 32px;
    }
  }
`
