import React, { useMemo, useState } from 'react';
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { MountedContentCard } from "../../UI/atoms/MountedContentCard";
import Layout from '../../UI/organisms/layout/Layout';
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { OutlineRadioButton } from "../../UI/molecules/OutlineRadioButton";
import { UserListItem } from "../../../models/user.models";
import { BodyRegular, BodySmall } from "../../UI/atoms/fonts/Body";
import Colours from "../../UI/atoms/Colours";
import { UserAvatarAndName } from "../../UI/molecules/UserAvatarAndName";
import { ListOfUsersHolidaysCanBeAddedTo } from "./user-annual-holidays/components/ListOfUsersHolidaysCanBeAddedTo";

export function HolidaySelectUserPage() {
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const [leaveForMe, setLeaveForMe] = useState(true);
  const [user, setUser] = useState<UserListItem | undefined>();

  const continueLink = useMemo(() => {
    if (leaveForMe && currentUser) {
      return `/holidays/${currentUser?.id}/add`;
    } else if (user) {
      return `/holidays/${user.id}/add`;
    }
    return '';
  }, [currentUser, leaveForMe, user]);

  const onUserSelect = (user?: UserListItem) => {
    setUser(user);
  }

  return (
    <Layout>
        <MountedContentCardWrapper centre={true}>
          <HeadlineLarge style={{marginBottom: 24}}>{t('holidays.add-leave')}</HeadlineLarge>

          <OutlineRadioButton isSelected={leaveForMe} onClick={() => setLeaveForMe(true)} text={'holidays.for-me'} />

          <OutlineRadioButton isSelected={!leaveForMe} onClick={() => setLeaveForMe(false)} text={'holidays.for-another'}>
            {!leaveForMe && <>
              <SelectUserContent>
                <BodyRegular weight={600} style={{marginBottom: 8}} colour={Colours.darkGrey}>{t('search-for-existing-user')}</BodyRegular>
                <SearchBarWrapper>
                  <ListOfUsersHolidaysCanBeAddedTo onSelect={onUserSelect} />
                  {/*<UserSearchDropDownList closeOnSelect={true}*/}
                  {/*                        disabled={false}*/}
                  {/*                        onSelect={onUserSelect} />*/}
                </SearchBarWrapper>

                {user ? <>
                  <UserAvatarAndName firstName={user.name.split(' ')[0]} lastName={user.name.split(' ')[1]} colour={'blue'} />
                  <BodySmall weight={400} colour={Colours.darkGrey} style={{marginLeft: 44}}>{user?.name}</BodySmall>
                </> : <>
                </>}

              </SelectUserContent>
            </>}
          </OutlineRadioButton>

          <PrimaryButton text={'button.continue'}
                         link={continueLink}
                         fullWidth={true}
                         style={{marginTop: 40, marginBottom: 40, maxWidth: 300}}
                         disabled={!continueLink} />
        </MountedContentCardWrapper>
    </Layout>
  )
}

const SearchBarWrapper = styled.div`
  margin-bottom: 34px;
`

const SelectUserContent = styled.div`
  padding: 16px 0 16px 56px;
`

const MountedContentCardWrapper = styled<any>(MountedContentCard)`
  margin-top: 40px;
  max-width: 680px;
  margin-bottom: 100px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`

