import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CarParkingPlan, CarParkPlanBooking, requestBookCarParkingSpace, requestCancelCarParkBooking,
  requestCarParkingPlan,
  requestCarParkPlanBookingsInCarPark
} from "../../../../services/CarParkingPlanService";
import { AppState } from "../../../../store/state/app.state";
import { Period } from "../../../../models/movements.models";


export interface CarParkingMapViewState {
  carParkDetails: CarParkingPlan[]
  selectedParkingPlanId?: number;
  bookings: CarParkPlanBooking[]
  loading: boolean;
}

export const initialCarParkingMapViewState: CarParkingMapViewState = {
  carParkDetails: [],
  bookings: [],
  loading: false,
};

export const getCarParkDetails: any = createAsyncThunk(
  'carParkingMapView/getCarParkDetails',
  async (params: {officeId: number}, thunkAPI) => {
    const carParkDetails = await requestCarParkingPlan(params.officeId);
    return {
      carParkDetails: carParkDetails,
    }
  }
);

export const getCarParkBookings: any = createAsyncThunk(
  'carParkingMapView/getCarParkBookings',
  async (params: {carParkPlanId: number, dateString: string}, thunkAPI) => {
    const bookings = await requestCarParkPlanBookingsInCarPark(params.carParkPlanId, params.dateString);
    return {
      bookings: bookings,
    }
  }
);

export const bookCarParkSpace: any = createAsyncThunk(
  'carParkingMapView/bookCarParkSpace',
  async (params: {period: Period, userId: string, carParkPlanId: number, carParkingSpaceId: number, dateString: string}, thunkAPI) => {
    await requestBookCarParkingSpace(params.userId, params.dateString, params.carParkingSpaceId, params.period);
    thunkAPI.dispatch(getCarParkBookings({carParkPlanId: params.carParkPlanId, dateString: params.dateString}));
  }
);

export const cancelCarParkBooking: any = createAsyncThunk(
  'carParkingMapView/cancelCarParkBooking',
  async (params: {userId: string, dateString: string, carParkingSpaceId: number, carParkPlanId: number}, thunkAPI) => {
    await requestCancelCarParkBooking(params.userId, params.dateString, params.carParkingSpaceId);
    thunkAPI.dispatch(getCarParkBookings({carParkPlanId: params.carParkPlanId, dateString: params.dateString}));
  }
);


const carParkingMapViewSlice = createSlice({
  name: 'carParkingMapView',
  initialState: initialCarParkingMapViewState,
  reducers: {
    resetCarParkingMapView: (state) => (initialCarParkingMapViewState),
    setSelectedParkingPlanId: (state, action) => ({...state, selectedParkingPlanId: action.payload}),
  },
  extraReducers: {
    [getCarParkDetails.pending]: (state) => ({...state, carParkDetails: [], loading: true}),
    [getCarParkDetails.failed]: (state) => ({...state, carParkDetails: [], loading: false}),
    [getCarParkDetails.fulfilled]: (state, action) => ({...state, carParkDetails: action.payload.carParkDetails, loading: false}),

    [getCarParkBookings.pending]: (state) => ({...state, bookings: [], loading: true}),
    [getCarParkBookings.failed]: (state) => ({...state, bookings: [], loading: false}),
    [getCarParkBookings.fulfilled]: (state, action) => ({...state, bookings: action.payload.bookings, loading: false}),

    [bookCarParkSpace.pending]: (state) => ({...state, loading: true}),
    [bookCarParkSpace.failed]: (state) => ({...state, loading: false}),
    [bookCarParkSpace.fulfilled]: (state, action) => ({...state, loading: false}),

    [cancelCarParkBooking.pending]: (state) => ({...state, loading: true}),
    [cancelCarParkBooking.failed]: (state) => ({...state, loading: false}),
    [cancelCarParkBooking.fulfilled]: (state, action) => ({...state, loading: false}),
  },
});



export default carParkingMapViewSlice.reducer;
export const {
  resetCarParkingMapView, setSelectedParkingPlanId,
} = carParkingMapViewSlice.actions;


// Selectors
export const selectCarParkingDetails = (state: AppState) => state.carParkingMapView.carParkDetails;
export const selectCarParkingPlanBookings = (state: AppState) => state.carParkingMapView.bookings;
export const selectSelectedParkingPlanId = (state: AppState) => state.carParkingMapView.selectedParkingPlanId;
export const selectIsLoading = (state: AppState) => state.carParkingMapView.loading;
