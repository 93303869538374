import React, { useMemo } from 'react';
import Colours from "../atoms/Colours";
import { BodyLarge, BodyRegular, BodyVerySmall } from "../atoms/fonts/Body";
import { Row } from "../atoms/StructuralLayout";
import {
  selectIsAdvanceHotDeskingLoading,
  toggleDefaultOffice
} from "../../../store/ducks/advanceHotDeskingSetup.duck";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import { OfficeEntity, OfficeEntityWithFavourite } from "../../../services/AdvanceHotDeskingService";
import { WithFavouriteOffices } from "../../../hooks/AdvanceHotDeskingHooks";
import { SimpleSpinner } from "../atoms/SimpleSpinner";
import { failureNotification, warningNotification } from "../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";
import { FavouriteImage } from "../atoms/FavouriteImage";
import styled from "styled-components/macro";
import { WithOfficeChildren } from "../../../hooks/WithOfficeChildren";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../store/ducks/auth.duck";
import { CapacityResponse } from "../../../services/OfficeCapacityService";

interface Props {
  onClick: any;
  office: OfficeEntity;
  hideFavourite?: boolean;
  capacity?: CapacityResponse;
  isCapacityLoading?: boolean
  showCapacities?: boolean;
}

export default function OfficeLocationContentCard(props: Props) {
  const {office, capacity, isCapacityLoading} = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsAdvanceHotDeskingLoading);
  const officeChildren = WithOfficeChildren(office.id);
  const iconPack = useSelector(selectIconPack);
  const {t} = useTranslation()

  const capacityRemainingInOffice = useMemo(() => {
    if (capacity) {
      return capacity.capacity - capacity.amUsed;
    }
    return 0;
  }, [capacity]);

  const favouriteOffices = WithFavouriteOffices();

  const showAvailabilityCount = useMemo(() => office.office && office.capacity > 0, [office]);

  const isFavourited = useMemo(() => {
    return !!favouriteOffices.find((fav: OfficeEntityWithFavourite) => fav.id === office.id && fav.isFavourite)
  }, [office.id, favouriteOffices]);

  const makeFavourite = (e: Event) => {
    e.stopPropagation();
    dispatch(toggleDefaultOffice(office.id))
  };

  const click = () => {
    if (!props?.showCapacities) {
      props.onClick();
    } else if (isCapacityLoading || !capacity) {
      dispatch(warningNotification('Wait for the office capacity to load'));
    } else if (!isCapacityLoading && (!showAvailabilityCount || capacityRemainingInOffice > 0)) {
      props.onClick();
    } else if (capacityRemainingInOffice < 1) {
      dispatch(failureNotification('That office space is full'));
    } else {
      TrackJS?.track('Unknown error - officeLocationContentCard')
      dispatch(failureNotification('Unknown error'));
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner fullScreen={true}/>}
      <OfficeContentCard onClick={click} data-test={'office-location-content-card'}>
        <LocationImage src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="Office"/>
        <OfficeDetails>
          <BodyLarge weight={600}>{office.label}</BodyLarge>

          {!!props?.showCapacities && showAvailabilityCount && <>
            {isCapacityLoading ? <SimpleSpinner size={24} /> : <BodyRegular style={{marginTop: -4}}>{t('whereabouts.seats-available', {remaining: capacityRemainingInOffice})}</BodyRegular>}
          </>}

          <Row style={{justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: 4}}>
            <BodyVerySmall>{office.postCode}</BodyVerySmall>
            {!props.hideFavourite && officeChildren.length === 0 &&
                <Row onClick={(e: any) => makeFavourite(e)} data-test={'add-favourite-button'}>
                  {!isFavourited && <BodyRegular colour={Colours.blue} weight={600}>{t('whereabouts.add-favourite')}</BodyRegular>}
                  <FavouriteImage src={isFavourited ? '/assets/icons/StarActive.svg' : '/assets/icons/Star.svg'}
                                  alt="Favourite"/>
                </Row>
            }
          </Row>
        </OfficeDetails>
      </OfficeContentCard>
    </>
  );
}

const OfficeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 16px;
`

const OfficeContentCard = styled.div`
  background: ${Colours.white};
  border: 1px solid ${Colours.mildGrey};
  border-radius: 8px;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin: 0 8px 24px 8px;
`

const LocationImage = styled.img`
  width: 64px;
`


