import React from 'react';
import { HeadlineLarge } from "../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

export function SelectOfficeFloor(props: Props) {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <HeadlineLarge style={{marginBottom: 12}}>{t('whereabouts.select-floor')}</HeadlineLarge>
      <BodyLarge style={{marginBottom: 24}}>{t('whereabouts.select-floor-description')}</BodyLarge>
    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled.div`
  max-width: 520px;
  img {
    max-width: 100%;
  }
`;

