import React, { useEffect, useState } from 'react';
import Dialog from "../UI/molecules/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import { BodyError, BodySmall } from "../UI/atoms/fonts/Body";
import { closeDialog } from "../../store/ducks/dialog.duck";
import { HeadlineLarge } from "../UI/atoms/fonts/Headline";
import { Checkbox } from "../UI/atoms/Checkbox";
import styled from "styled-components/macro";
import { selectRegisterAuthType } from "../../store/ducks/auth.duck";
import { AuthType } from "../../utils/AuthUtils";
import ReactGA from "react-ga";
import { RegisterWithMicrosoftButton } from "../pages/login/RegisterWithMicrosoftButton";
import { RegisterWithGoogleButton } from "../pages/login/RegisterWithGoogleButton";
import { isInTeams } from "../../utils/TeamsUtils";
import { RegisterWithMicrosoftTeamsButton } from "../pages/login/RegisterWithMicrosoftTeamsButton";
import { Trans, useTranslation } from "react-i18next";

export default function SignUpTermsDialog() {
  const dispatch = useDispatch();
  const registrationAuthType = useSelector(selectRegisterAuthType);
  const [error, setError] = useState('');
  const {t} = useTranslation();

  const [informMe, setInformMe] = useState(false);
  const [agreeToTnC, setAgreeToTnC] = useState(false);

  useEffect(() => {
    ReactGA.event({category: 'NewUser', action: 'Register third party sign up dialog'});
  }, []);

  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <HeadlineLarge style={{marginBottom: 32}}>{t('register.review-terms')}</HeadlineLarge>

      {error && <BodyError style={{marginBottom: 16}}>{error}</BodyError>}

      <div style={{marginBottom: 16}}>
        <Checkbox dataTest="termsAndConditionsCheckbox" value={agreeToTnC} onChange={(e: boolean) => setAgreeToTnC(e)}>
          <BodySmall className="login__checkbox">
            <Trans i18nKey={'register.terms-accept-message'} components={[
              <a target={"_blank"} rel="noreferrer" href="https://team-today.com/legal/terms-and-conditions">{t('Terms & Conditions')}</a>,
              <a target={"_blank"} rel="noreferrer" href="https://team-today.com/legal/privacy-policy">{t('Privacy Policy')}</a>]} />
          </BodySmall>
        </Checkbox>
      </div>
      <div style={{marginBottom: 48}}>
        <Checkbox value={informMe} onChange={(e: boolean) => setInformMe(e)}
                  text="login.marketing-emails"/>
      </div>

      {registrationAuthType === AuthType.Microsoft && !isInTeams() && <RegisterWithMicrosoftButton informMe={informMe}
                                                                                   agreeToTnC={agreeToTnC}
                                                                                   onError={setError} />}

      {registrationAuthType === AuthType.Microsoft && isInTeams() && <RegisterWithMicrosoftTeamsButton />}

      {registrationAuthType === AuthType.Google && <RegisterWithGoogleButton />}

    </DialogWrapper>
  )
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 524px;
  }
`
