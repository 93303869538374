import React from 'react';
import styled from "styled-components/macro";

export function KeyHolderBadge(props: Props) {
  return (
    <KeyHolderWrapper data-test="key-holder-badge" style={props.style ?? {}} title="This person is a designated Key holder">
      <img src="/assets/icons/key-holder.svg" alt="Key holder" />
    </KeyHolderWrapper>
  )
}

interface Props {
  style?: any;
}

const KeyHolderWrapper = styled.div`
`
