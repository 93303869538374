import React from 'react';
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { bookSingleDayMovementByOfficeId } from "../../../../../store/ducks/officeUsage.duck";
import { Period } from "../../../../../models/movements.models";
import { useDispatch } from "react-redux";
import { Moment } from "moment";
import { Row } from "../../../../UI/atoms/StructuralLayout";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { useTranslation } from "react-i18next";

export function BookingPopup(props: Props) {
  const {isSelected, label, officeId, onSelect, date} = props;
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const onBookDesk = (e: Event, period: Period) => {
    e.stopPropagation();
    onSelect(undefined);
    dispatch(bookSingleDayMovementByOfficeId({
      date: date,
      officeId: officeId,
      period: period
    }));
  }

  return (
    <Popup isVisible={isSelected}>
      <BodyRegular weight={400} colour={Colours.darkGrey} style={{marginBottom: 12}}>{t('company-view-page.area')} {label}</BodyRegular>
      <Row style={{marginBottom: 16}}>
        <OutlineButton text="button.book-am" style={{marginRight: 8}} click={(e: Event) => onBookDesk(e, Period.AM)} />
        <OutlineButton text="button.book-pm" style={{marginLeft: 8}} click={(e: Event) => onBookDesk(e, Period.PM)} />
      </Row>
      <PrimaryButton fullWidth={true} click={(e: Event) => onBookDesk(e, Period.AllDay)} text="button.book-all-day"/>
    </Popup>
  )
}

interface Props {
  isSelected: boolean;
  officeId: number;
  label: string;
  date: Moment;
  onSelect: (office: any) => {};
}

const Popup = styled.div<any>`
  width: 260px;
  min-height: 120px;
  background-color: white;
  border: 1px solid ${Colours.veryLightGrey};
  box-shadow: 0 22px 24px 2px rgba(0, 87, 255, 0.15);
  border-radius: 16px;
  left: 12.5%;
  top: -25%;
  position: absolute;
  z-index: 150;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  justify-content: center;
  padding: 24px;
  flex-direction: column;
`
