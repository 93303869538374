import React, { useState } from 'react';
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { AbsenceReportLine, downloadAbsenceReport, toCsvDownload } from "../../../../services/ReportingService";
import { firstBy } from "thenby";
import { Moment } from "moment";

export function DownloadDetailedAbsenceReportButton(props: Props) {
  const {fromDate, toDate, disabled} = props;
  const [loading, setLoading] = useState(false);

  const downloadDetailedReport = async () => {
    try {
      setLoading(true);
      const reportData: AbsenceReportLine[] = (await downloadAbsenceReport(fromDate, toDate));
      reportData.sort(firstBy('lastName').thenBy('firstName').thenBy('date'))
      toCsvDownload(reportData, `Absence report`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <OutlineButton size={"medium"}
                   disabled={disabled || loading}
                   text="reports-page.holidays-report.report"
                   click={downloadDetailedReport} />
  )
}

interface Props {
  fromDate: Moment;
  toDate: Moment;
  disabled: boolean;
}
