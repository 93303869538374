import React, { useMemo } from 'react';
import IconButton, { IconButtonProps } from "../../../../UI/molecules/icon-button/IconButton";
import { canUserEditTeam } from "../../../../../utils/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import { redirectTo, selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { TeamEntity } from "../../../../../models/team.models";
import { Column } from "../../../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import { desktopSmall } from "../../../../UI/atoms/MediaQueries";
import { deepLinkTo, isInTeams } from "../../../../../utils/TeamsUtils";
import { teamsTabs, uiUrl } from "../../../../../services/EnvironmentVariables";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";

interface Props {
  team?: TeamEntity;
  visible?: boolean;
  showTeamDetails: boolean;
}

export function EditTeamControlsRow(props: Props) {
  const dispatch = useDispatch();
  const {team} = props;
  const current = useSelector(selectCurrentUser);
  const enableEditTeamButton = useMemo(() => canUserEditTeam(current, team), [current, team]);

  const onEditTeamClick = () => {
    if (!team?.id) return;

    if (isInTeams()) {
      const externalUrl = `${uiUrl}/manage/team/${team.id}`
      deepLinkTo(teamsTabs.settings, externalUrl, 'team', team.id);
    } else {
      dispatch(redirectTo(`/manage/team/${team.id}`))
    }
  }

  if (!team || props.visible) {
    return null;
  }

  return (
    <Row>
      {props.showTeamDetails && <Column>
        <BodyRegular weight={600}>{team.name}</BodyRegular>
      </Column>}

      {enableEditTeamButton && <EditButton dataTest="editTeamButton" onClick={onEditTeamClick} icon={IconTypes.Edit} text="edit-team.title"/>}
    </Row>
  )
}

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: 8px;
  @media (${desktopSmall}) {
    margin-top: 0;
    padding: 16px;
  }
`

export const EditButton = styled<any>(IconButton)<IconButtonProps>`
  justify-self: flex-end;
  margin-left: auto;
  i {
    font-size: 30px;
  }
`
