import React, { useMemo } from 'react';
import { Moment } from "moment";
import styled from "styled-components/macro";
import { DATE_FORMAT } from "../../../../../../utils/DateUtils";
import { BodyVerySmall, BodyXXLSmall } from "../../../../atoms/fonts/Body";
import Colours from "../../../../atoms/Colours";
import { tablet } from "../../../../atoms/MediaQueries";

export function MovementOfficeLabel(props: Props) {
  const {amLabel, pmLabel, amOfficeId, pmOfficeId, amDeskId, pmDeskId, amParkingLabel, pmParkingLabel, date} = props;

  const amOfficeLink = useMemo(() => {
    if (!amOfficeId) return;
    let qs = `view=floor&office=${amOfficeId}&date=${date?.format(DATE_FORMAT)}`;
    if (amDeskId) qs += `&desk=${amDeskId}`;
    return `/whereabouts/company/all?${qs}`;
  }, [amOfficeId, amDeskId, date]);

  const pmOfficeLink = useMemo(() => {
    if (!pmOfficeId) return;
    let qs = `view=floor&office=${pmOfficeId}&date=${date?.format(DATE_FORMAT)}`;
    if (pmDeskId) qs += `&desk=${pmDeskId}`;
    return `/whereabouts/company/all?${qs}`;
  }, [pmOfficeId, pmDeskId, date])

  if (!amLabel && !pmLabel) return null;

  if (amLabel === pmLabel) {
    return (
      <UsersOfficeLabel title={amLabel}
                        data-test={'whereabouts-location-label'}>
        <a className="noDecoration" href={amOfficeLink}>{amLabel}</a>
        {amParkingLabel && <BodyXXLSmall>{amParkingLabel}</BodyXXLSmall>}
      </UsersOfficeLabel>
    )
  } else if (amLabel !== pmLabel && (amLabel || pmLabel)) {
    return <>
      {amLabel && <UsersOfficeLabel title={amLabel}
                                    data-test={'whereabouts-location-label-am'}>
        <a className="noDecoration" href={amOfficeLink}>{amLabel}</a>
        {amParkingLabel && <BodyXXLSmall>{amParkingLabel}</BodyXXLSmall>}
      </UsersOfficeLabel>}

      {pmLabel && <UsersOfficeLabel title={pmLabel}
                                    data-test={'whereabouts-location-label-pm'}>
        <a className="noDecoration" href={pmOfficeLink}>{pmLabel}</a>
        {pmParkingLabel && <BodyXXLSmall>{pmParkingLabel}</BodyXXLSmall>}
      </UsersOfficeLabel>}
    </>
  } else {
    return null;
  }
}

interface Props {
  amLabel: string;
  pmLabel: string;
  amOfficeId?: any;
  pmOfficeId?: any;
  amDeskId?: any;
  pmDeskId?: any;
  amParkingLabel?: string;
  pmParkingLabel?: string;
  date?: Moment;
}

export const UsersOfficeLabel = styled<any>(BodyVerySmall)<any>`
  text-align: left;
  align-self: flex-start;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  text-overflow: ellipsis;
  white-space: unset;
  overflow: hidden;
  max-width: 100%;
  color: ${Colours.black};

  @media (${tablet}) {
    white-space: nowrap;
  }
  
  a {
    color: ${Colours.black};
    font-weight: 400;
    &:hover {
      text-decoration: underline;
      color: ${Colours.blue};
    }
  }
`;
