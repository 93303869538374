import { BasicUserInfo, Permission, User } from "../models/user.models";
import { TeamEntity, TeamWithCount } from "../models/team.models";
import { AccountInfo } from "../services/AccountService";
import { MemberMovements } from "../models/movements.models";

export function canUserEditTeam(user?: User, team?: TeamEntity | TeamWithCount): boolean {
  return canUserEditTeamId(user, team?.id);
}

export function canUserEditTeamId(user?: User, teamId?: string): boolean {
  if (!user) return false;
  if (user.role === Permission.CompanyAdmin) return true;
  if (user.companyEntity?.restrictTeamManagement) return false;
  if (user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin) return true;
  if (!teamId) return false;
  else if (user.role === Permission.TeamAdmin && user?.teamEntity?.id === teamId) return true;
  return false;
}

export function canUserEditUser(user?: User, memberMovements?: MemberMovements): boolean {
  if (!user) {
    return false;
  }
  if (user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin) {
    return true;
  }
  if (!memberMovements) {
    return false;
  }
  if (isDepartmentAdmin(user) && user?.department && memberMovements.departmentId && user.department.id === memberMovements.departmentId) {
    return true;
  } else if (isTeamAdminUser(user) && user.teamEntity && user?.teamEntity.id === memberMovements.teamId) {
    return true;
  } else if (user?.id === memberMovements.id) {
    return true;
  }
  return false;
}

export function canUserEditGroups(user?: User): boolean {
  if (!user) return false;
  if (user.role === Permission.CompanyAdmin) return true;
  if (user.companyEntity.restrictTeamManagement) return false;
  return user.role === Permission.OfficeAdmin;
}

export function canUserMoveUsersIntoDifferentTeams(user?: User): boolean {
  if (!user) return false;
  if (user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.TeamAdmin || user.role === Permission.DepartmentAdmin) return true;
  return false;
}

export function canUserUpdateHolidaySettings(user?: User): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canUserCreateCompanyHolidays(user?: User): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canUserUpdateCompanySettings(user?: User): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canUserCreateTeamEvent(user?: User, team?: TeamEntity | TeamWithCount): boolean {
  if (user?.role === Permission.CompanyAdmin) {
    return true;
  } else if (user?.teamEntity?.id === team?.id) {
    return true;
  } else {
    return false;
  }
}

export function canCreateCompany(user?: User): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canUserAddAnotherTeam(user?: User): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canUserDeleteCompany(user?: User): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canUserViewOfficesInManagement(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin;
}

export function canUserEditOfficesInManagement(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin;
}

export function canUserCreateOffices(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canUserAccessReporting(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin;
}

export function canUserUnknownWhereaboutsReport(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.TeamAdmin;
}

export function isPaidUser(accountInfo?: AccountInfo): boolean {
  return !!accountInfo && accountInfo.billTotal > 0 && accountInfo.isActive;
}

export function isBasicUser(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.Basic;
}

export function isCompanyAdminUser(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function isOfficeAdminUser(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.OfficeAdmin;
}

export function isDepartmentAdmin(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.DepartmentAdmin;
}

export function isTeamAdminUser(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.TeamAdmin;
}

export function canAddOrMoveUsers(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin;
}

export function canEditCustomWhereabouts(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canManageWorkGroups(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canChangeCompanyHolidays(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin;
}

export function canChangeChangeUsersHolidays(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.DepartmentAdmin;
}

export function canChangeKeyResponsibilities(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canChangeUsersRole(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canEditAnyUser(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canManageLineReports(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.DepartmentAdmin;
}

export function canChangeUsersTeam(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin;
}

export function canChangeUsersWorkGroup(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canChangeUsersDefaultOffice(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canUserViewReportingPage(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canUserViewOfficeReports(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canUserViewTeamReports(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function canViewUserReport(user?: User | null): boolean {
  if (!user) return false;
  return user.role === Permission.CompanyAdmin || user.role === Permission.TeamAdmin || user.role === Permission.OfficeAdmin || user.role === Permission.DepartmentAdmin;
}

export function usersInSameDepartment(user?: User | null, userB?: BasicUserInfo): boolean {
  if (!user || !userB || !user.department || !userB.departmentId) return false;
  return user.department.id === userB.departmentId;
}
