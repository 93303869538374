import React, { useMemo, useState } from 'react';
import { ConfigurationSection } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular, BodyXXSmall } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { Switch } from "../../atoms/Switch";
import { requestDomainNameSignUp } from "../../../../services/CompanyService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, updateCurrentUser } from "../../../../store/ducks/auth.duck";
import { failureNotification } from "../../../../store/ducks/notification.duck";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { FlatContentCard } from "../../atoms/FlatContentCard";
import { Trans, useTranslation } from "react-i18next";
import { GreenPill } from "../../atoms/Pill";
import { HeadlineSmall } from "../../atoms/fonts/Headline";
import styled from "styled-components/macro";

export function DomainBasedSignUpSetting(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const isPending = useMemo(() => user?.companyEntity.domainNameSignupPending ?? false, [user]);
  const isActive = useMemo(() => !user?.companyEntity.domainNameSignupPending && !!user?.companyEntity.domainName && !!user?.companyEntity.domainNameSignupsEnabled, [user]);


  const onDomainNameChange = async (value: boolean) => {
    try {
      setIsLoading(true);
      await requestDomainNameSignUp(value);
      dispatch(updateCurrentUser(true));
    } catch (e: any) {
      dispatch(failureNotification('Failed to update setting, please wait a minute.'));
    } finally {
      setIsLoading(false);
    }
  };
  const domainName = useMemo(() => {
    if (user?.companyEntity.domainName) {
      return user?.companyEntity.domainName;
    }
    const split = user?.email.split('@');
    if (split && split.length === 2) {
      return split[1];
    } else {
      return;
    }
  }, [user]);

  return (
    <ConfigurationSection className={'domainBasedSignup__section'}>
      <GreenPill style={{marginBottom: 16}}><BodyXXSmall>{t('settings.domain-based-signup.everyone')}</BodyXXSmall></GreenPill>
      <SwitchWrapper>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.domainNameSignupsEnabled} label="" onChange={onDomainNameChange}/>}
      </SwitchWrapper>
      {!!props.settingsView ? <BodyLarge colour={Colours.blue} style={{marginBottom: 16}}>{t('settings.domain-based-signup.title')}</BodyLarge> : <HeadlineSmall style={{marginBottom: 16}}>{t('settings.domain-based-signup.title')}</HeadlineSmall>}
      <BodyRegular style={{marginBottom: 4}} weight={600}>{t('settings.domain-based-signup.subtitle')}</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>{
        !!domainName ? <Trans i18nKey={'settings.domain-based-signup.enabled-message'} components={[<strong>{{domainName}}</strong>]} domainName={domainName}  /> :
          <Trans i18nKey={'settings.domain-based-signup.disabled-message'} components={[<strong>{t('settings.domain-based-signup.your-domain-name')}</strong>]} />
      }</BodyRegular>

      {!props.hideExtraInfo && <>
      {isPending &&
          <FlatContentCard>
            <BodyLarge colour={Colours.blue}>{t('settings.domain-based-signup.pending-line-1')}</BodyLarge>
            <BodyRegular style={{marginBottom: 16}}>{t('settings.domain-based-signup.pending-line-2')}</BodyRegular>
            <BodyRegular style={{marginBottom: 16}}>{t('settings.domain-based-signup.pending-line-3')}</BodyRegular>
            <BodyRegular>{t('settings.domain-based-signup.pending-line-4')}</BodyRegular>
          </FlatContentCard>}

        {isActive &&
          <FlatContentCard>
            <BodyLarge colour={Colours.blue}>{t('settings.domain-based-signup.active-line-1')}</BodyLarge>
            <BodyRegular style={{marginBottom: 16}}>{t('settings.domain-based-signup.active-line-2')}</BodyRegular>
            <BodyRegular style={{marginBottom: 16}}>{t('settings.domain-based-signup.active-line-3')}</BodyRegular>
          </FlatContentCard>}
        </>}

    </ConfigurationSection>
  )
}

interface Props {
  hideExtraInfo?: boolean;
  settingsView?: boolean;
}

const SwitchWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 8px;
`
