import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { BodyRegular, BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { CompanyHoliday } from "../../../../services/CompanyHolidayService";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { SpaceBetweenRow } from "../../settings/StyleComponents";
import Colours from "../../../UI/atoms/Colours";

export enum PopupPosition {
  Left, Right, None,
}

export function CompanyHolidayInfoPopup(props: Props) {
  const {companyHoliday} = props;
  const popupPositioning = props.popupPositioning ?? PopupPosition.Left;
  const date = useMemo(() => moment(companyHoliday.date), [companyHoliday]);
  const {t} = useTranslation();

  const days = useMemo(() => {
    return 1
  }, []);

  if (days === 0) {
    return null;
  }

  return (
    <HolidayInfoPopupWrapper className="popup" popupPositioning={popupPositioning}>
      <SpaceBetweenRow style={{alignItems: 'center', marginBottom: 4}}>
        <BodyRegular colour={Colours.darkGreen} weight={600}>{t('holiday.company-holiday')}</BodyRegular>
        <Icon icon={IconTypes.Flag} />
      </SpaceBetweenRow>

      <BodyRegular style={{marginBottom: 2}} weight={600}>{companyHoliday.label}</BodyRegular>
      <BodyVerySmall>{`${date.format('Do MMMM')}`}</BodyVerySmall>
    </HolidayInfoPopupWrapper>
  )
}

interface Props {
  companyHoliday: CompanyHoliday;
  popupPositioning?: PopupPosition;
}

const HolidayInfoPopupWrapper = styled.div<any>`
  display: none;
  flex-direction: column;
  max-width: 448px;
  min-width: 300px;
  min-height: 50px;
  padding: 24px;
  position: absolute;
  top: 30px;
  left: ${props => props.popupPositioning === PopupPosition.Left ? 0 : 'auto'};
  right: ${props => props.popupPositioning === PopupPosition.Right ? 0 : 'auto'};
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  cursor: default;
  p {
    max-width: 100%;
  }
`
