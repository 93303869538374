import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import {
  getKeyResponsibilitiesConfig,
  KeyResponsibilitiesSetting, saveConfig
} from "../../../../services/KeyResponsibilitiesService";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { AppState } from "../../../../store/state/app.state";
import moment, { Moment } from "moment";
import { getKeyResponsibilitiesData, KeyResponsibilitiesUsageReportItem } from "../../../../services/ReportingService";

export enum KeyResponsibilitiesConfigKey {
  FIRE_WARDEN_PER_STAFF = 'FIRE_WARDEN_PER_STAFF',
  FIRST_AIDER_PER_STAFF = 'FIRST_AIDER_PER_STAFF',
}

export enum KeyResponsibilities {
  firstAider = 'firstAider',
  fireWarden = 'fireWarden'
}

export interface ReportDataItem {
  date: string;
  count: number;
  capacity: number;
  officeCapacity: number;
  officeAttendance: number;
}

export interface KeyResponsibilitiesReportingState {
  config: KeyResponsibilitiesSetting[];
  loading: boolean;
  selectedOffice?: OfficeEntity;
  selectedResponsibility: KeyResponsibilities;
  date: Moment;
  reportData: ReportDataItem[]
}

export const initialKeyResponsibilitiesReportingState: KeyResponsibilitiesReportingState = {
  config: [],
  loading: false,
  selectedResponsibility: KeyResponsibilities.firstAider,
  date: moment(),
  reportData: [],
}


export const loadConfig: any = createAsyncThunk(
  'keyResponsibilitiesReporting/loadConfig',
  async (office: OfficeEntity, thunkAPI) => {
    const response = await getKeyResponsibilitiesConfig(office.id);
    return response;
  }
)

export const updateConfig: any = createAsyncThunk(
  'keyResponsibilitiesReporting/updateConfig',
  async (config: KeyResponsibilitiesSetting, thunkAPI) => {
    await saveConfig(config);
    return await getKeyResponsibilitiesConfig(config.officeId);
  }
)

export const loadReportData: any = createAsyncThunk(
  'keyResponsibilitiesReporting/loadReportData',
  async (params: {office: OfficeEntity, date: Moment, selectedKeyResponsibility: KeyResponsibilities}, thunkAPI) => {
    const {office, date, selectedKeyResponsibility} = params;

    if (!office) {
      return [];
    }

    const response = await getKeyResponsibilitiesData(office, date, selectedKeyResponsibility);
    const extrapolatedData = response.map((item: KeyResponsibilitiesUsageReportItem) => ({
      ...item,
      dayOfWeekLetter: moment(item.date).format('ddd')[0],
      dateOfMonth: moment(item.date).format('DD'),
      freeSeats: Math.max(0, item.officeCapacity - item.count),
      keyRoleToAttendancediff: Math.max(0, item.officeAttendance - item.count,)
    }));

    return extrapolatedData;
  }
)

// @ts-ignore
const keyResponsibilitiesReporting = createSlice({
  name: 'auth',
  initialState: initialKeyResponsibilitiesReportingState,
  reducers: {
    reset: () => (initialKeyResponsibilitiesReportingState),
    setSelectedOffice: (state, action) => ({...state, selectedOffice: action.payload}),
    setSelectedResponsibility: (state, action) => ({...state, selectedResponsibility: action.payload}),
    setDate: (state, action) => ({...state, date: action.payload}),
  },
  extraReducers: {
    [loadConfig.pending]: (state, action) => ({...state, loading: true, config: [] }),
    [loadConfig.fulfilled]: (state, action) => ({...state, config: action.payload, loading: false }),
    [loadConfig.rejected]: (state, action) => ({...state, loading: false }),

    [updateConfig.pending]: (state, action) => ({...state, loading: true, }),
    [updateConfig.fulfilled]: (state, action) => ({...state, config: action.payload, loading: false }),
    [updateConfig.rejected]: (state, action) => ({...state, loading: false }),

    [loadReportData.pending]: (state, action) => ({...state, loading: true, }),
    [loadReportData.fulfilled]: (state, action) => ({...state, reportData: action.payload, loading: false }),
    [loadReportData.rejected]: (state, action) => ({...state, loading: false }),
  }
})

export default keyResponsibilitiesReporting.reducer
export const {
  reset,
  setSelectedOffice,
  setSelectedResponsibility,
  setDate,
} = keyResponsibilitiesReporting.actions;

// Selectors
export const selectIsLoading = (state: AppState) => state.keyResponsibilitiesReporting.loading;
export const selectSelectedOffice = (state: AppState) => state.keyResponsibilitiesReporting.selectedOffice;
export const selectSelectedKeyResponsibility = (state: AppState) => state.keyResponsibilitiesReporting.selectedResponsibility;
export const selectDate = (state: AppState) => state.keyResponsibilitiesReporting.date;
export const selectReportData = (state: AppState) => state.keyResponsibilitiesReporting.reportData;
export const selectMinimumRequirement = (state: AppState) => {
  const krr = state.keyResponsibilitiesReporting;
  switch (krr.selectedResponsibility) {
    case KeyResponsibilities.fireWarden: return selectFireWardensConfig(state).perUsers;
    case KeyResponsibilities.firstAider: return selectFirstAidersConfig(state).perUsers;
    default: return 0;
  }
};
export const selectFirstAidersConfig = (state: AppState) => {
  const configs = state.keyResponsibilitiesReporting.config;
  const aidersPerUsers = configs.find(c => c.configId === KeyResponsibilitiesConfigKey.FIRST_AIDER_PER_STAFF);
  return {
    perUsers: parseInt(aidersPerUsers?.value ?? '0'),
  }
};
export const selectFireWardensConfig = (state: AppState) => {
  const configs = state.keyResponsibilitiesReporting.config;
  const fireWardensPerUser = configs.find(c => c.configId === KeyResponsibilitiesConfigKey.FIRE_WARDEN_PER_STAFF);
  return {
    perUsers: parseInt(fireWardensPerUser?.value ?? '0'),
  }
};
