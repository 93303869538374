import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  FavouriteDesk,
  FavouriteOfficeEntity,
  OfficeEntity,
  toggleFavouriteDesk, toggleFavouriteOffice
} from "../../../../../services/AdvanceHotDeskingService";
import { Desk, getDesk } from "../../../../../services/DeskBookingService";
import {
  loadFavouriteOffices,
  selectAllOfficeEntities,
  selectFavouriteDesks,
  selectFavouriteOffices
} from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices, getFullOfficeTitleById } from "../../../../../utils/OfficeHelper";
import Colours from "../../../../UI/atoms/Colours";
import { LoadingOverlay } from "../../../../UI/molecules/LoadingOverlay";
import Icon, { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { SpaceBetweenRow } from "../../StyleComponents";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialog } from "../../../../../store/ducks/dialog.duck";

interface FavouriteOfficeWithEntity extends OfficeEntity, FavouriteOfficeEntity {}

export function UsersFavouriteOfficesAndDesksList() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [favoriteDeskEntities, setFavouriteDeskEntities] = useState<Desk[]>([]);
  const favoriteOffices = useSelector(selectFavouriteOffices);
  const favoriteDesks = useSelector(selectFavouriteDesks);
  const allOffices = useSelector(selectAllOfficeEntities);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(loadFavouriteOffices());
  }, [dispatch]);

  const offices = useMemo<FavouriteOfficeWithEntity[]>(() => {
    return favoriteOffices.map(fo => ({
      ...fo,
      ...(findOfficeInOffices(allOffices, fo.officeId) || {}),
    })) as FavouriteOfficeWithEntity[]
  }, [allOffices, favoriteOffices]);

  useEffect(() => {
    if (favoriteDesks.length > 0) {
      Promise.all(favoriteDesks.map((favDesk: FavouriteDesk) => getDesk(favDesk.deskId))).then(setFavouriteDeskEntities)
    } else {
      setFavouriteDeskEntities([]);
    }
  }, [favoriteDesks]);

  const removeFavouriteOffice = async (officeId: number) => {
    try {
      setLoading(true);
      await toggleFavouriteOffice(officeId);
      await new Promise((resolve: any) => setTimeout(() => resolve(), 250));
      dispatch(loadFavouriteOffices());
    } finally {
      setLoading(false);
    }
  }

  const removeFavouriteDesk = async (desk: Desk) => {
    try {
      setLoading(true);
      await toggleFavouriteDesk(desk.id);
      await new Promise((resolve: any) => setTimeout(() => resolve(), 250));
      dispatch(loadFavouriteOffices());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <Icon icon={IconTypes.FavouriteOutline} size="mediumlarge" style={{marginBottom: 8}}/>
      <SpaceBetweenRow style={{marginBottom: 12}}>
        <BodyLarge colour={Colours.blue}>{t('settings.fav-office')}</BodyLarge>
      </SpaceBetweenRow>
      <Column>
        {offices.map((office: FavouriteOfficeWithEntity, key: number) => (
          <FavouriteItemRow key={key}>
            <Row>
              <img src={"/assets/icons/standard/Office.svg"} alt={""} style={{marginRight: 8, height: 32, width: 32}}/>
              <BodyRegular weight={600}>{getFullOfficeTitleById(office.officeId, allOffices) || '[deleted]'}</BodyRegular>
            </Row>
            <IconButton icon={IconTypes.Bin} onClick={() => removeFavouriteOffice(office.officeId)} />
          </FavouriteItemRow>
        ))}
      </Column>
      <Column style={{marginBottom: 16}}>
        {favoriteDeskEntities.map((desk: Desk, key: number) => (
          <FavouriteItemRow key={key}>
            <Row>
              <Icon icon={IconTypes.Chair} size="medium" style={{marginRight: 8}}/>
              <BodyRegular weight={600}>{desk.label}</BodyRegular>
            </Row>
            <IconButton icon={IconTypes.Bin} onClick={() => removeFavouriteDesk(desk)} />
          </FavouriteItemRow>
        ))}
      </Column>

      <OutlineButton click={() => dispatch(openDialog(DialogIdentifiers.ManageFavouritesDialog))}
                     text={"button.add-new-favourite"}
                     fullWidth={true} />
    </Container>
  )
}

const FavouriteItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  p {
    align-self: center;
  }
`

const Container = styled.div`
  margin-bottom: 48px;
  padding-bottom: 48px;
  border-bottom: 1px solid ${Colours.lightGrey};
`
