import React from 'react';
import { HeadlineLarge } from '../../../../UI/atoms/fonts/Headline';
import styled from "styled-components";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { selectAccountInfo } from "../../../../../store/ducks/payments.duck";
import { useSelector } from "react-redux";
import { ProgressBar } from "../../../../UI/atoms/ProgressBar";
import { Row } from "../../../../UI/atoms/StructuralLayout";
import { ContentCard } from '../../../../UI/atoms/ContentCard';
import Colours from "../../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";

export default function PlanUsageContentCard() {
  const accountInfo = useSelector(selectAccountInfo);
  const {t} = useTranslation();

  const userLimit = accountInfo?.userLimit ?? 0;
  const totalUsersUsed = accountInfo?.totalUsers ?? 0;

  const teamLimit = accountInfo?.teamLimit ?? 0;
  const totalTeamsUsed = accountInfo?.totalTeams ?? 0;

  return (
    <>
      <BodyLarge style={{marginBottom: 24}}>{t('settings.account-usage')}</BodyLarge>
      <ContentCard style={{marginBottom: 48}}>
        <UsageBlock>
          <Row>
            <CountWrapper>
              <BodyRegular weight={600}>{t('settings.users-registered')}</BodyRegular>
              <HeadlineLarge style={{marginBottom: 8}}>{totalUsersUsed}</HeadlineLarge>
            </CountWrapper>
            <CountWrapper>
              <BodyRegular weight={600} colour={Colours.blue}>{t('settings.spaces-left')}</BodyRegular>
              <HeadlineLarge style={{marginBottom: 8}}>{userLimit - totalUsersUsed}</HeadlineLarge>
            </CountWrapper>
          </Row>
          <ProgressBar complete={totalUsersUsed / userLimit * 100}/>
        </UsageBlock>

        <UsageBlock>
          <Row>
            <CountWrapper>
              <BodyRegular weight={600}>{t('settings.teams-in-use')}</BodyRegular>
              <HeadlineLarge style={{marginBottom: 8}}>{totalTeamsUsed}</HeadlineLarge>
            </CountWrapper>
            <CountWrapper>
              <BodyRegular weight={600} colour={Colours.blue}>{t('settings.teams-left')}</BodyRegular>
              <HeadlineLarge style={{marginBottom: 8}}>{teamLimit - totalTeamsUsed}</HeadlineLarge>
            </CountWrapper>
          </Row>
          <ProgressBar complete={totalTeamsUsed / teamLimit * 100}/>
        </UsageBlock>
      </ContentCard>
    </>
  );
}

const UsageBlock = styled.div`
  margin-bottom: 48px;
`

const CountWrapper = styled.div`
  width: 114px;
  display: flex;
  flex-direction: column;
  flex: 1;
`
