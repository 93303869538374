import React, { useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { updateHolidayApprovalNotificationSetting } from "../../../../store/ducks/onboarding.duck";
import { useTranslation } from "react-i18next";

export function HolidayApprovalNotificationSettings(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onChange = (val: boolean) => {
    setIsLoading(true);
    try {
      dispatch(updateHolidayApprovalNotificationSetting(val));
    } finally {
      setIsLoading(false);
    }
  }

  if (!user?.companyEntity.holidayApprovalEnabled) {
    return null;
  }

  return (
    <ConfigurationSection>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.holiday-approval.title')}</BodyLarge>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.holidayApprovalNotificationsEnabled} label="" onChange={onChange}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 8}}>{t('settings.holiday-approval.message')}</BodyRegular>
      {user?.companyEntity.holidayApprovalNotificationsEnabled ? <>
      </> : <>
      </>}
    </ConfigurationSection>
  )
}

interface Props {
}
