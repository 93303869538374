import React, { useMemo, useState } from 'react';
import { HolidayActivityFill } from "./WallPlannerActivityBlocks";
import { daysBetween } from "../../../../utils/DateUtils";
import moment, { Moment } from "moment";
import { HolidayDay } from "../../holidays-v2/models/Holidays.model";
import { HolidayInfoAndApprovalPopup, PopupPosition } from "./HolidayInfoAndApprovalPopup";
import { BasicUserInfo } from "../../../../models/user.models";
import { ApprovalState } from "../../../../models/approval-requests.models";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";

function holidayBlockOutlineColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.outlineBlue;
    case ApprovalState.pending: return Colours.orange;
    default: return '#A6B4B4';
  }
}

function holidayBlockFillColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.blue;
    case ApprovalState.pending: return Colours.orange50;
    default: return Colours.veryLightGrey;
  }
}

function holidayBlockTextColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.white;
    default: return Colours.black;
  }
}

export function HolidayBlock(props: Props) {
  const {activity, firstDateOnCalendar} = props;
  const [popupVisible, setPopupVisible] = useState(false);

  const days = useMemo(() => daysBetween(activity.toDate, activity.fromDate) + 1, [activity]);

  const startsWithHalfDay = useMemo(() => {
    if (!activity.holidayBookingList || activity.holidayBookingList.length === 0) return false;
    let startDay = activity.holidayBookingList[0];
    activity.holidayBookingList.forEach(((bookingDay: HolidayDay) => {
      if (bookingDay.date < startDay.date) startDay = bookingDay;
    }));
    return !startDay.am;
  }, [activity]);

  const endsWithHalfDay = useMemo(() => {
    if (!activity.holidayBookingList || activity.holidayBookingList.length === 0) return false;
    let endDay = activity.holidayBookingList[0];
    activity.holidayBookingList.forEach(((bookingDay: HolidayDay) => {
      if (bookingDay.date > endDay.date) endDay = bookingDay;
    }));
    return !endDay.pm;
  }, [activity]);

  const popupPositioning = useMemo(() => {
    const daysDiff = moment(activity.fromDate).diff(firstDateOnCalendar, 'days');
    return daysDiff > 20 ? PopupPosition.Right : PopupPosition.Left;
  }, [activity, firstDateOnCalendar]);

  const holidayState = useMemo(() => {
    const bookingList = activity.holidayBookingList ?? [];
    const pending = bookingList.filter((booking: HolidayDay) => booking.state === ApprovalState.pending);
    const declined = bookingList.filter((booking: HolidayDay) => booking.state === ApprovalState.declined);

    if (declined.length > 0) return ApprovalState.declined;
    else if (pending.length > 0) return ApprovalState.pending;
    else return ApprovalState.approved;
  }, [activity]);

  return (
    <HolidayActivityFill start={daysBetween(activity.fromDate, firstDateOnCalendar)}
                         days={days}
                         startsWithHalfDay={startsWithHalfDay}
                         endsWithHalfDay={endsWithHalfDay}
                         fill={holidayBlockFillColour(holidayState)}
                         outline={holidayBlockOutlineColour(holidayState)}
                         textColour={holidayBlockTextColour(holidayState)}
                         onMouseOver={(e: any) => setPopupVisible(true)}
                         onMouseLeave={(e: any) => setPopupVisible(false)}
                         onTouchStart={(e: any) => setPopupVisible(true)}
                         onTouchEnd={(e: any) => setPopupVisible(false)}
                         onTouchCancel={(e: any) => setPopupVisible(false)}
                         title={activity?.name ?? 'Holiday'}>
      <Contents>
        {days === 1 && (endsWithHalfDay || startsWithHalfDay) ? <></> : <Icon icon={IconTypes.Holiday} />}
        {props.children && <>{props.children}</>}
      </Contents>
      <HolidayInfoAndApprovalPopup activity={activity}
                                   popupPositioning={popupPositioning}
                                   isVisible={popupVisible}
                                   user={props.user} />
    </HolidayActivityFill>
  )
}

interface Props {
  activity: any;
  children?: any;
  user?: BasicUserInfo;
  firstDateOnCalendar: Moment;
  weeksInMonth: number;
}

const Contents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
