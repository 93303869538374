import React from 'react';
import { useSelector } from "react-redux";
import { selectAddMemberProcessingState } from "../../../../store/ducks/addMemberDialog.duck";
import { AddTeamMemberSuccess } from "./components/AddTeamMemberSuccess";
import { AddTeamMemberInput } from "./components/AddTeamMemberInput";
import { selectIsAccountLimitReached } from "../../../../store/ducks/payments.duck";
import { AddTeamMemberLimitReached } from "./components/AddTeamMemberLimitReached";
import { TeamWithCount } from "../../../../models/team.models";

interface Props {
  team?: TeamWithCount;
  hideImages: boolean;
  onDone: () => any;
  message?: string;
  hideSuccessMessage?: boolean;
}

export function AddTeamMember(props: Props) {
  const addMemberProcessingState = useSelector(selectAddMemberProcessingState);
  const isAccountLimitReached = useSelector(selectIsAccountLimitReached);

  if (isAccountLimitReached) {
    return <AddTeamMemberLimitReached hideImages={props.hideImages} onDone={props.onDone} />
  } else if (addMemberProcessingState.success) {
    return <AddTeamMemberSuccess onDone={props.onDone} hideSuccessMessage={!!props.hideSuccessMessage} />
  } else {
    return <AddTeamMemberInput hideImages={props.hideImages} team={props.team} message={props.message} />
  }
}
