import React from 'react';
import { HeadlineLarge } from '../../UI/atoms/fonts/Headline';
import Layout from '../../UI/organisms/layout/Layout';
import './not-found-page.scss';
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const {t} = useTranslation();
  return (
    <Layout>
      <div className="notFoundPage">
        <HeadlineLarge>{t('404-error')}</HeadlineLarge>
        <img src="/assets/images/woman-on-laptop.svg" alt="Page not found" />
      </div>
    </Layout>
  );
}
