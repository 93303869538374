import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { canUserViewOfficeReports, isOfficeAdminUser } from "../../../utils/AccessControl";
import AccessRequired from "../settings/components/AccessRequired";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import moment, { Moment } from "moment";
import { OfficeConfirmationLineChart } from "./components/OfficeConfirmationLineChart";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { OfficeDropDownList } from "../../UI/organisms/OfficeDropDownList";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { getCheckInSummary } from "../../../services/ReportingService";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { useTranslation } from "react-i18next";

function parseReportData(list: any[]) {
  return list.map(item => ({
      toolTipTitle: moment(item.date).format('Do MMMM YYYY'),
      name: moment(item.date).format('ddd')[0],
      officeBookings: item.officeUsage,
      confirmations: item.numberOfCheckIns,
  }));
}

export function OfficeCheckInReportingPage(props: Props) {
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [reportData, setReportData] = useState<any>();
  const [selectedOffice, setSelectedOffice] = useState<OfficeEntity | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const canViewReport = useMemo(() => canUserViewOfficeReports(currentUser), [currentUser]);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (canViewReport && selectedOffice) {
      if (isOfficeAdminUser(currentUser) && currentUser?.defaultLocationId !== selectedOffice.id) {
        dispatch(failureNotification(`You can only run office report on your own office`));
        return;
      }

      setLoading(true)
      getCheckInSummary(selectedOffice, selectedDate)
        .then(summary => setReportData(parseReportData(summary)))
        .catch(() => dispatch(failureNotification("Failed to get report data")))
        .finally(() => setLoading(false));
    }
  }, [canViewReport, selectedDate, selectedOffice, dispatch, currentUser]);


  const setDate = (date: Moment) => {
    setSelectedDate(date);
  }

  return (
    <ReportingContainer>
      <GoBackButton url={"/manage/reporting"} />
      {loading && <LoadingSpinner fullScreen={true} />}
      <div>
        {canViewReport ? <>
          <HeadlineLarge style={{marginBottom: 24}}>{t('reports-page.office-usage-confirmation')}</HeadlineLarge>
          <SpaceBetweenRow>
            <DateFlicker dateScale={DateScale.Month} updateGlobalDate={false} onChange={date => setDate(date)} />

            <div style={{minWidth: 300}}>
              <OfficeDropDownList onSelect={setSelectedOffice} selectDefaultOffice={true} disabled={loading} />
            </div>
          </SpaceBetweenRow>

          {selectedOffice && reportData && <OfficeConfirmationLineChart reportData={reportData} />}

        </> : <>
          <AccessRequired visible={!canViewReport} teamAdminsRequired={false} companyAdminsRequired={true} />
        </>}
      </div>
    </ReportingContainer>
  )
}

interface Props {
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
`
