import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../state/app.state';
import { requestApprovals, sendApproveRequest, sendDeclineRequest } from "../../services/ApprovalRequests";
import { ApprovalsView, GroupedApprovalRequest } from "../../models/approval-requests.models";
import { failureNotification, successNotification } from "./notification.duck";
import { Moment } from "moment";

export interface ApprovalRequestsState {
  loading: boolean;
  activeView: ApprovalsView;
  numberOfApprovals: number;
  approvals: GroupedApprovalRequest[];
  selectedTeamId?: string;
  showPending: boolean,
  showApproved: boolean,
  showDeclined: boolean,
  nameFilter: string,
}

export const initialApprovalRequestsState: ApprovalRequestsState = {
  loading: false,
  activeView: ApprovalsView.pending,
  numberOfApprovals: 0,
  approvals: [],
  showPending: true,
  showApproved: true,
  showDeclined: false,
  nameFilter: '',
};

export const getApprovals: any = createAsyncThunk(
  'approvalRequests/getApprovals',
  async (params: { pending: boolean, approved: boolean, rejected: boolean, calendarDate: Moment, selectedGroupId?: string }, thunkAPI) => {
    const {pending, approved, rejected, calendarDate} = params;
    const approvalRequestState = (thunkAPI.getState() as AppState).approvalRequests;

    const from = calendarDate.clone().startOf('year');
    const to = from.clone().add(1, 'year');

    if (!approvalRequestState.selectedTeamId) {
      return approvalRequestState.approvals;
    }

    return await requestApprovals(pending, approved, rejected, from, to, approvalRequestState.selectedTeamId);
  })


export const setActiveView: any = createAsyncThunk(
  'approvalRequests/setActiveView',
  async (activeView: ApprovalsView, thunkAPI) => {
    return activeView;
  }
);

export const approveRequest: any = createAsyncThunk(
  'approvalRequests/approveRequest',
  async (approvalRequest: GroupedApprovalRequest, thunkAPI) => {
    await sendApproveRequest(approvalRequest);
    const state = (thunkAPI.getState() as AppState)
    thunkAPI.dispatch(getApprovals({
      pending: state.approvalRequests.showPending,
      approved: state.approvalRequests.showApproved,
      rejected: state.approvalRequests.showDeclined,
      calendarDate: state.dashboard.calendarDate,
      selectedGroupId: state.approvalRequests.selectedTeamId
    }))
    thunkAPI.dispatch(successNotification('Approved.'));
  }
);

export const declineRequest: any = createAsyncThunk(
  'approvalRequests/declineRequest',
  async (approvalRequest: GroupedApprovalRequest, thunkAPI) => {
    await sendDeclineRequest(approvalRequest);
    const state = (thunkAPI.getState() as AppState)
    thunkAPI.dispatch(getApprovals({
      pending: state.approvalRequests.showPending,
      approved: state.approvalRequests.showApproved,
      rejected: state.approvalRequests.showDeclined,
      calendarDate: state.dashboard.calendarDate,
      selectedGroupId: state.approvalRequests.selectedTeamId
    }))
    thunkAPI.dispatch(failureNotification('Declined.'));
  }
);


const approvalRequestsSlice = createSlice({
  name: 'approvalRequests',
  initialState: initialApprovalRequestsState,
  reducers: {
    resetApprovalRequests: (state) => (initialApprovalRequestsState),
    setNumberOfApprovals: (state, action) => ({
      ...state,
      numberOfApprovals: action.payload,
    }),
    setShowPending: (state, action) => ({ ...state, showPending: action.payload, }),
    setShowDeclined: (state, action) => ({ ...state, showDeclined: action.payload, }),
    setShowApproved: (state, action) => ({ ...state, showApproved: action.payload, }),
    setNameFilter: (state, action) => ({ ...state, nameFilter: action.payload, }),
    setSelectTeamForApprovalView: (state, action) => ({ ...state, selectedTeamId: action.payload, }),
  },
  extraReducers: {
    [getApprovals.pending]: (state) => ({...state, approvals: [], loading: true}),
    [getApprovals.failed]: (state) => ({...state, approvals: [], loading: false}),
    [getApprovals.fulfilled]: (state, action) => ({...state, approvals: action.payload, loading: false}),

    [approveRequest.pending]: (state) => ({...state, loading: true}),
    [approveRequest.failed]: (state) => ({...state, loading: false}),
    [approveRequest.fulfilled]: (state) => ({...state, loading: false}),

    [declineRequest.pending]: (state) => ({...state, loading: true}),
    [declineRequest.failed]: (state) => ({...state, loading: false}),
    [declineRequest.fulfilled]: (state) => ({...state, loading: false}),

    [setActiveView.fulfilled]: (state, action) => ({...state, activeView: action.payload}),
  },
});



export default approvalRequestsSlice.reducer;
export const {
  resetApprovalRequests,
  setNumberOfApprovals,
  setSelectTeamForApprovalView,
  setShowPending,
  setShowDeclined,
  setShowApproved,
  setNameFilter,
} = approvalRequestsSlice.actions;


// Selectors
export const selectIsApprovalsLoading = (state: AppState) => state.approvalRequests.loading;
export const selectActiveApprovalsView = (state: AppState) => state.approvalRequests.activeView;
export const selectApprovals = (state: AppState) => state.approvalRequests.approvals;
export const selectSelectedGroupId = (state: AppState) => state.approvalRequests.selectedTeamId;
export const selectShowPending = (state: AppState) => state.approvalRequests.showPending;
export const selectShowDeclined = (state: AppState) => state.approvalRequests.showDeclined;
export const selectShowApproved = (state: AppState) => state.approvalRequests.showApproved;
export const selectNameFilter = (state: AppState) => state.approvalRequests.nameFilter;

export const selectVisibleApprovalTypes = (state: AppState) => ({
  approved: state.approvalRequests.showApproved,
  pending: state.approvalRequests.showPending,
  declined: state.approvalRequests.showDeclined
});
