import React from 'react';
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { HeadlineMedium } from "../../../../../UI/atoms/fonts/Headline";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { setAddTeamMemberView } from "../ducks/add-users.duck";
import { AddTeamMemberView } from "../ducks/AddUsers.model";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ContentCard } from "../../../../../UI/atoms/ContentCard";
import { BodySmall } from "../../../../../UI/atoms/fonts/Body";
import { Link } from "react-router-dom";
import { isInTeams } from "../../../../../../utils/TeamsUtils";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import Colours from "../../../../../UI/atoms/Colours";

export function UserMovedSuccessfully(props: Props) {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser)
  const {t} = useTranslation();
  const showPromotion = (user?.companyEntity.userLimit === 10);
  return (
    <>
      <Header>
        <SuccessImage src={"/assets/images/onboarding-invite-sent-image.svg"} />
        <Icon icon={IconTypes.Tick} circle={true} color="blue" size="large"/>
      </Header>
      <HeadlineMedium style={{marginBottom: 24}}>{t('manage.add-new-user.team-member-added')}</HeadlineMedium>

      <OutlineButton fullWidth={true}
                     size="large"
                     dataTest="closeMoveTeamMemberDialog"
                     text="button.move-another-user"
                     style={{marginBottom: 16, marginTop: 'auto'}}
                     click={() => dispatch(setAddTeamMemberView(AddTeamMemberView.AddUser))}/>

      <PrimaryButton fullWidth={true}
                     size="large"
                     dataTest="closeMoveTeamMemberDialog"
                     text="button.done-for-now"
                     link={"/whereabouts/team"}/>

      {!isInTeams() && showPromotion && <ContentCard style={{marginTop: 48}}>
        <BodySmall weight={600}>{t('did-you-know')}</BodySmall>
        <BodySmall style={{marginBottom: 12}}>{t('manage.add-new-user.paid-account-promote')}</BodySmall>
        <Link to={"/manage/payments"}><BodySmall weight={600} colour={Colours.blue}>{t('manage.add-new-user.upgrade')}</BodySmall></Link>
      </ContentCard>}
    </>
  )
}

interface Props {
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  i {
    margin-top: auto;
    margin-bottom: 24px;
  }
  img {
    margin: 0 auto;
  }
`
const SuccessImage = styled.img`
  width: 224px;
  justify-content: center;
`
