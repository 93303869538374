import React from 'react';
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { saveAllHolidayAllowances, selectHolidayAllowanceEdits } from "../../models/holiday-allowances.duck";
import Colours from "../../../../UI/atoms/Colours";
import { BodySmall } from "../../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";

export function HolidayAllowanceSaveBar(props: Props) {
  const dispatch = useDispatch();
  const toSave = useSelector(selectHolidayAllowanceEdits);

  const saveAll = () => {
    dispatch(saveAllHolidayAllowances());
  }

  if (!toSave || toSave.length === 0) {
    return null;
  }
  return (
    <Container>
      <Content>
        <OutlineButton click={saveAll} text={"button.save"} />
        <BodySmall colour={Colours.white}>{toSave.length} record(s) to save</BodySmall>
      </Content>
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: ${Colours.darkBlue};
  z-index: 10000;
`

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 24px 0 0;
  }
`
