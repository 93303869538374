import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/ducks/dialog.duck";
import Dialog from "../UI/molecules/Dialog";
import DialogHeader from "./DialogHeader";
import { BodyRegular, BodySmall } from "../UI/atoms/fonts/Body";
import { selectCurrentUser, updateCurrentUser } from "../../store/ducks/auth.duck";
import { useMsal } from "@azure/msal-react";
import { updateOutlookSyncState } from "../../services/UserService";
import Colours from "../UI/atoms/Colours";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import { microsoftClientId, uiUrl } from "../../services/EnvironmentVariables";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";

export function AzurePermissionsRequired(props: Props) {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {instance} = useMsal();
  const {t} = useTranslation();

  const authUrl = `${uiUrl}/login?dialog=permissions`

  const adminAuthUrl = useMemo(() => {
    if (instance.getActiveAccount()?.tenantId) {
      return `https://login.microsoftonline.com/${instance.getActiveAccount()?.tenantId}/adminconsent?client_id=${microsoftClientId}`
    } else {
      return `https://login.microsoftonline.com/common/adminconsent?client_id=${microsoftClientId}`
    }
  }, [instance]);

  const close = async () => {
    setIsLoading(true);

    try {
      if (user) {
        await updateOutlookSyncState(false)
        dispatch(updateCurrentUser(true));
      }
    } finally {
      setIsLoading(false);
      dispatch(closeDialog())
    }
  }

  const onPermissionsClick = async () => {
    await updateOutlookSyncState(true)
    close();
  }

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="Permissions required" />

      <BodyRegular style={{marginBottom: 16}}>Additional permissions are required to sync with calendars which cannot be granted through Teams.</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>Clicking the button will open Team Today in the browser where you can log in and accept permissions.</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>Once done refresh the Team Today app in Teams and you shouldn't have to repeat the process.</BodyRegular>

      <PermissionsAnchorTag target="_blank" href={authUrl} rel="noreferrer">
        <PrimaryButton click={onPermissionsClick} fullWidth={true} text={'Confirm permissions'} />
      </PermissionsAnchorTag>

      <BodyRegular>You can also use the link below and accept permissions for your entire company and no one will have to do this.</BodyRegular>

      <LinkContentCard>
        <BodySmall weight={600}><a target="_blank" href={authUrl} rel="noreferrer">{adminAuthUrl}</a></BodySmall>
      </LinkContentCard>

      <BodyRegular style={{marginBottom: 8}}>You may require someone from IT to complete this for you.</BodyRegular>

      <BodyRegular style={{marginBottom: 16}}>For further assistance reach out to our support team at <a href="mailto:support@team-today.com">support@team-today.com</a> who will be able to answer any questions and assist further.</BodyRegular>

      <OutlineButton click={close}
                        text={'sync-to-outlook-dialog.no'}
                        fullWidth={true}
                        style={{marginBottom: 16}}
                        disabled={isLoading} />

      <BodySmall colour={Colours.darkGrey} style={{marginBottom: 32}}>{t('sync-to-outlook-dialog.message')}</BodySmall>
    </Dialog>
  )
}

interface Props {
}

const LinkContentCard = styled.div`
  padding: 12px 16px;
  margin: 12px 0;
  border: 1px solid ${Colours.blue};
  border-radius: 12px;
  overflow-wrap: break-word;
  background-color: ${Colours.blue05};
`

const PermissionsAnchorTag = styled.a`
  text-decoration: none;
  button {
    margin-bottom: 32px;
  }
  * {
    text-decoration: none;
  }
`
