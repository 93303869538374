import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useLocation } from 'react-router';
import OnboardingPage from '../pages/onboarding/Onboarding';
import PrivateRoute from './PrivateRoute';
import ManagementRouting from '../pages/settings/ManagementRouting';
import { selectCurrentUser, selectIsAuthLoading } from '../../store/ducks/auth.duck';
import WelcomeDialog from "../dialogs/welcome-dialog/WelcomeDialog";
import PermissionNeededDialog from "../dialogs/permission-needed-dialog/PermissionNeededDialog";
import DeleteTeamDialog from "../dialogs/delete-team-dialog/DeleteTeamDialog";
import DeleteCompanyDialog from "../dialogs/delete-company-dialog/DeleteCompanyDialog";
import SetupCompanyDialog from "../dialogs/setup-company-dialog/SetupCompanyDialog";
import NewPaymentDialog from "../dialogs/NewPaymentDialog";
import { getParameterByName, updateLastPath } from "../../utils/UrlUtils";
import { DialogIdentifiers, openDialog } from "../../store/ducks/dialog.duck";
import { LoadConfig } from "../../hooks/LoadConfig";
import { Route } from "react-router-dom";
import WallPlannerRouting from "../pages/settings/WallPlannerRouting";
import { LoadingSpinner } from "../UI/atoms/LoadingSpinner";
import { WithMsTeamsAppStoreRouting } from "../../hooks/WithMsTeamsAppStoreRouting";
import { FloorPlanEditPage } from "../pages/floor-plan-edit/FloorPlanEditPage";
import { MeetingRoomRouter } from "../pages/meeting-rooms/MeetingRoomRouter";
import WhereaboutsRouting from "../pages/team-movements/WhereaboutsRouting";
import { ManageUsersPage } from "../pages/settings/pages/manage-users-page-v2/ManageUsersPage";
import TagManagementPage from "../pages/settings/pages/tag-management-page/TagManagementPage";
import { ShowOnboarding } from "../../hooks/ShowOnboarding";
import { AddUsersPage } from "../pages/settings/pages/manage-users-page-v2/AddUsersPage";
import HolidaysRouting from "../pages/holidays-v2/HolidaysRouting";

export default function SecureApp() {
  document.body.style.backgroundColor = 'white';
  const dispatch = useDispatch();
  const location = useLocation();
  const current = useSelector(selectCurrentUser);
  const loading = useSelector(selectIsAuthLoading);
  const paymentState = useMemo(() => getParameterByName('state', location.search), [location]);
  const usersWhereaboutsEdited = useMemo(() => current?.whereaboutsEdited, [current]);

  const showOnboarding = ShowOnboarding();
  LoadConfig();
  WithMsTeamsAppStoreRouting();

  useEffect(() => {
    if (usersWhereaboutsEdited) {
      dispatch(openDialog(DialogIdentifiers.WhereaboutsModifiedDialog));
    }
  }, [dispatch, usersWhereaboutsEdited]);

  useEffect(() => {
    if (paymentState === 'canceled') dispatch(openDialog(DialogIdentifiers.StripeFailure));
    if (paymentState === 'success') dispatch(openDialog(DialogIdentifiers.StripeSuccess));
  }, [paymentState, dispatch]);

  useEffect(() => {
    updateLastPath(location.pathname);
  }, [location]);


  if (loading || !current) {
    return <LoadingSpinner fullScreen={true} message={"Secure app loading"}/>
  }

  if (showOnboarding && !location.pathname.includes('/onboarding')) {
    return (<Redirect to="/onboarding"/>)
  }

  return (
    <>
      <Switch>
        <PrivateRoute exact path="/onboarding" component={OnboardingPage}/>

        <Route path="/whereabouts">
          <WhereaboutsRouting/>
        </Route>

        <Route path="/holidays">
          <HolidaysRouting/>
        </Route>

        <Route path="/work-planner">
          <WallPlannerRouting/>
        </Route>

        {/* Header and footers are managed internally*/}
        <PrivateRoute exact path="/manage/users" component={ManageUsersPage} />
        <PrivateRoute exact path="/manage/add-users" component={AddUsersPage} />
        <PrivateRoute exact path="/manage/workgroups" component={TagManagementPage} />

        <Route path="/manage">
          <ManagementRouting/>
        </Route>


        <Route path="/edit-floor-plan">
          <FloorPlanEditPage/>
        </Route>

        <Route path="/meeting-rooms">
          <MeetingRoomRouter/>
        </Route>

      </Switch>
      <WelcomeDialog/>
      <PermissionNeededDialog/>
      <DeleteTeamDialog/>
      <DeleteCompanyDialog/>
      <SetupCompanyDialog/>
      <NewPaymentDialog/>
    </>
  )
}

