import { useEffect, useRef, useState } from "react";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { CapacityResponse, getAllOfficeCapacity } from "../services/OfficeCapacityService";
import { Moment } from "moment";

export function WithSeatsAvailableInParentOffice(activeDate: Moment, office?: OfficeEntity) {
  const hasRequested = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [capacities, setCapacities] = useState<CapacityResponse[]>([]);

  useEffect(() => {
    if (!hasRequested.current && activeDate && office) {
      hasRequested.current = true;
      setIsLoading(true);
      getAllOfficeCapacity(office.id, activeDate)
        .then((resp) => setCapacities(resp))
        .finally(() => setIsLoading(false))
    }
  }, [office, activeDate]);

  return {
    isLoading: isLoading,
    capacities: capacities,
  };
}
