import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import Dialog from "../../../UI/molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import { OfficeMultiSelectDropDownList } from "../../../UI/organisms/OfficeMultiSelectDropDownList";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import {
  selectCheckedTeamIds,
  selectCheckedWorkgroupIds,
  setCheckedTeamIds, setCheckedWorkgroupIds
} from "../../../../store/ducks/companyMovements.duck";
import { updateTeamAssignedOfficesRequest } from "../../../../services/TeamService";
import { updateTagsAssignedOfficesRequest } from "../../../../services/Tags";
import { loadConfig } from "../../../../store/ducks/config.duck";
import { successNotification } from "../../../../store/ducks/notification.duck";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";

export function LinkTeamOrWorkgroupToOfficeDialog() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedOffices, setSelectedOffices] = useState<OfficeEntity[]>([]);
  const checkedTeamIds = useSelector(selectCheckedTeamIds);
  const checkedWorkgroupIds = useSelector(selectCheckedWorkgroupIds);

  const close = () => {
    dispatch(closeDialog())
  };

  const onOfficeSelect = (office: OfficeEntity[]) => {
    setSelectedOffices(office);
  }

  const onLinkClick = async () => {
    if (selectedOffices.length === 0) return;
    setLoading(true);
    try {
      const officeIds = selectedOffices.map(o => o.id);
      for (const checkedTeamId of checkedTeamIds) {
        await updateTeamAssignedOfficesRequest(checkedTeamId, officeIds);
      }
      for (const checkedWorkgroupId of checkedWorkgroupIds) {
        await updateTagsAssignedOfficesRequest(checkedWorkgroupId, officeIds);
      }
      dispatch(setCheckedTeamIds([]));
      dispatch(setCheckedWorkgroupIds([]));
      dispatch(loadConfig());
      dispatch(successNotification('Linked groups to offices'));
    } catch (e) {
      dispatch(successNotification('Failed to link groups to offices'));
    } finally {
      setLoading(false);
      close();
    }
  }

  const onClearClick = async () => {
    setLoading(true);
    try {
      for (const checkedTeamId of checkedTeamIds) {
        await updateTeamAssignedOfficesRequest(checkedTeamId, []);
      }
      for (const checkedWorkgroupId of checkedWorkgroupIds) {
        await updateTagsAssignedOfficesRequest(checkedWorkgroupId, []);
      }
      dispatch(setCheckedTeamIds([]));
      dispatch(setCheckedWorkgroupIds([]));
      dispatch(loadConfig());
      dispatch(successNotification('Cleared offices from groups'));
    } catch (e) {
      dispatch(successNotification('Failed to clear offices from groups'));
    } finally {
      setLoading(false);
      close();
    }
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="link-group-to-office-dialog.title" />
      <OfficeMultiSelectDropDownList onSelect={onOfficeSelect} disabled={loading} label={'link-group-to-office-dialog.office-label'} />
      <PrimaryButton text={'link-group-to-office-dialog.link-button'}
                     disabled={loading}
                     style={{marginTop: 'auto', marginBottom: 16}}
                     click={onLinkClick}
                     fullWidth={true} />
      <OutlineButton text={'link-group-to-office-dialog.clear-button'}
                     disabled={loading}
                     click={onClearClick}
                     fullWidth={true} />
    </DialogWrapper>
  )
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 410px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 500px;
    min-height: 500px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
`
