import React, { useMemo } from 'react';
import { FloorContainer } from "./AreaAndDeskUsageView";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectActiveDay } from "../../../../../store/ducks/editMovements.duck";
import { selectedRootOffice } from "../../../../../store/ducks/officeView.duck";
import { BodyRegular, BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import { WithCarParkingBookings } from "../../../../../hooks/WithCarParkingBookings";
import { CarParkBooking } from "../../../../../services/OfficeCapacityService";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { Column } from "../../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { WithOfficeCapacity } from "../../../../../hooks/WithOfficeCapacity";
import { CarParkPlanBooking } from "../../../../../services/CarParkingPlanService";

export function OfficeCarParkingList(props: Props) {
  const {t} = useTranslation();
  const activeDate = useSelector(selectActiveDay);
  const rootOffice = useSelector(selectedRootOffice);
  const capacity = WithOfficeCapacity(rootOffice, activeDate);
  const carParkUsage = WithCarParkingBookings(rootOffice, activeDate);

  const carParkingSpaces = useMemo(() => {
    return capacity?.capacity?.carParkingCapacity ?? 0
  }, [capacity]);

  const usedCarParkingSpaces = useMemo(() => {
    if (carParkUsage) {
      if (carParkUsage.carParkPlanBookings && carParkUsage.carParkPlanBookings.length > 0) {
        return carParkUsage.carParkPlanBookings.length
      }
      if (carParkUsage.bookings && carParkUsage.bookings.length) {
        return carParkUsage.bookings.length;
      }
    }
    return 0;
  }, [carParkUsage]);

  return (
    <FloorContainer>
      <HeadlineSmall style={{marginBottom: 3}}>{t('whereabouts.car-park-bookings')}</HeadlineSmall>
      <BodyRegular>{t('whereabouts.car-park-capacity-and-usage', {spaces: carParkingSpaces, bookings:  usedCarParkingSpaces})}</BodyRegular>
      {carParkUsage.carParkPlanBookings.map((booking: CarParkPlanBooking, key: number) => (
        <Row key={key}>
          <UserAvatarAndName firstName={booking.firstName}
                             lastName={booking.lastName}
                             colour={'darkBlue'}
                             hideName={true} />
          <Column>
            <BodyRegular>{`${booking.firstName} ${booking.lastName}`}</BodyRegular>
            <BodyVerySmall style={{marginBottom: 3}}>{`${booking.label}`}</BodyVerySmall>
            {!!booking.carReg ? <BodyVerySmall>{booking.carReg}</BodyVerySmall> : <BodyVerySmall>No registered car</BodyVerySmall>}
          </Column>

          <BodyRegular className={"period"} colour={Colours.blue}>{booking.sideOfDay}</BodyRegular>
        </Row>
      ))}
      {carParkUsage.bookings.map((booking: CarParkBooking, key: number) => (<Row key={key}>
        <UserAvatarAndName firstName={booking.firstName}
                           lastName={booking.lastName}
                           colour={'darkBlue'}
                           hideName={true} />
        <Column>
          <BodyRegular>{`${booking.firstName} ${booking.lastName}`}</BodyRegular>
          <BodyVerySmall>{booking.registration}</BodyVerySmall>
        </Column>

        <BodyRegular className={"period"} colour={Colours.blue}>{booking.period}</BodyRegular>
      </Row>))}
    </FloorContainer>
  )
}

const Row = styled.div`
  display: flex;
  align-content: center;
  border-top: 1px solid ${Colours.lightGrey};
  margin-top: 20px;
  padding-top: 20px;
  .period {
    align-self: center;
    margin-left: auto;
  }
  .userAvatarAndName__avatar {
    align-self: center;
    margin-right: 16px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`

interface Props {
}
