import React from 'react';
import styled from "styled-components/macro";

export function ColourCircle(props: Props) {
  const style = props.style ?? {};
  return (
    <ColourCircleWrapper style={{...style, backgroundColor: props.color}} />
  )
}

interface Props {
  color: string;
  style?: any;
}

const ColourCircleWrapper = styled.div`
  align-self: center;
  justify-self: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
`

