import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectIsAdvanceHotDeskingLoading,
  selectOffices
} from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { HeadlineLarge, HeadlineMedium } from "../../../../UI/atoms/fonts/Headline";
import { canUserEditOfficesInManagement } from "../../../../../utils/AccessControl";
import AccessRequired from "../../components/AccessRequired";
import { Center, Column } from "../../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { OfficeList } from '../advance-hot-desking/components/OfficeList';
import { LinkButton } from "../../../../UI/atoms/buttons/LinkButton";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import Icon, { IconTypes } from "../../../../UI/atoms/icon/Icon";
import IconHeader from "../../../../UI/molecules/IconHeader";
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";

export default function OfficeManagementPage() {
  const {t} = useTranslation();
  return (
    <div className="management">
      <GoBackButton url="/manage"/>
      <IconHeader icon={IconTypes.Chair} style={{marginBottom: 32}} />
      <HeadlineLarge className="management__title">{t('settings.office-management')}</HeadlineLarge>
      <SimpleHotDesking />
    </div>
  );
}

function SimpleHotDesking() {
  const offices = useSelector(selectOffices);
  const current = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectIsAdvanceHotDeskingLoading);
  const isEditable = useMemo(() => canUserEditOfficesInManagement(current), [current]);
  const {t} = useTranslation();

  if (!isEditable) {
    return <AccessRequired visible={!isEditable} companyAdminsRequired={true} officeAdminsRequired={true}/>;
  } else if (isLoading) {
    return (<Center>
      <LoadingSpinner hideBorder={true} />
    </Center>)
  } else {
    return (
      <>
        <BodyLarge style={{marginBottom: 16}}>{t('settings.set-limits-on-offices')}</BodyLarge>

        {offices && offices.length > 0 ? <Column style={{width: '100%'}}>
            {offices.length > 0 && <HeadlineMedium className="management__title">{t('settings.offices')}</HeadlineMedium>}
            <OfficeList/>
          </Column> :
          <NoOfficesSetup />}

        <LinkButton style={{marginBottom: 24}}
                    dataTest="create-new-office-button"
                    fullWidth={true}
                    size="medium"
                    type="primary"
                    text="button.create-new-office"
                    link="/manage/hot-desking/add"/>
      </>);
  }
}

function NoOfficesSetup() {
  return <>
    <FlatContentCard style={{padding: 32, marginBottom: 32}}>
      <SalesRow>
        <Icon color="greenSolid" icon={IconTypes.Tick} size="medium" circle={true}/>
        <BodyRegular><Trans i18nKey={"settings.manage-office.no-setup-message-1"} /></BodyRegular>
      </SalesRow>
      <SalesRow>
        <Icon color="greenSolid" icon={IconTypes.Tick} size="medium" circle={true}/>
        <BodyRegular><Trans i18nKey={"settings.manage-office.no-setup-message-2"} /></BodyRegular>
      </SalesRow>
      <SalesRow>
        <Icon color="greenSolid" icon={IconTypes.Tick} size="medium" circle={true}/>
        <BodyRegular><Trans i18nKey={"settings.manage-office.no-setup-message-3"} /></BodyRegular>
      </SalesRow>
    </FlatContentCard>
  </>
}


const SalesRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .icon {
    font-weight: bolder;
    margin-right: 16px;
  }
  &:last-child {
    margin: 0;
  }
`
