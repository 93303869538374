import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import moment, { Moment } from "moment";
import { OfficeDropDownList } from "../../UI/organisms/OfficeDropDownList";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { getMonthlyOfficeUsage } from "../../../services/ReportingService";
import { CapacityResponse } from "../../../services/OfficeCapacityService";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Center } from "../../UI/atoms/StructuralLayout";
import { OfficeUsageLineChart } from "./components/OfficeUsageLineChart";
import { MountedContentCard, MountedContentCardContent } from "../../UI/atoms/MountedContentCard";
import { BodyLarge, BodyRegular } from "../../UI/atoms/fonts/Body";
import { OfficeUsageDownloadReportBar } from "./components/OfficeUsageDownloadReportBar";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";

export function OfficeUsageReportingPage(props: Props) {
  const [date, setDate] = useState<Moment>(moment());
  const [office, setOffice] = useState<OfficeEntity | undefined>();
  const [reportData, setReportData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const onDateChange = (date: Moment) => {
    setDate(date);
  }

  const onOfficeSelected = (office: OfficeEntity) => {
    setOffice(office);
  }

  useEffect(() => {
    if (date && office) {
      setReportData([]);
      setLoading(true)
      getMonthlyOfficeUsage(date, office).then(resp => {
        const data = resp.map((resp: CapacityResponse) => ({
          name: moment(resp.date).format('D'),
          capacity: resp.capacity,
          usage: Math.round((resp.amUsed + resp.pmUsed) / 2),
          toolTipTitle: moment(resp.date).format('ddd Do MMMM'),
        }));
        setReportData(data);
        setLoading(false);
      })
    }
  }, [date, office]);

  return (
    <ReportingContainer>
      <GoBackButton url={"/manage/reporting"} />
      <HeadlineLarge style={{marginBottom: 16}}>{t('reports-page.office-usage-report')}</HeadlineLarge>
      <SpaceBetweenRow>
        <DateFlicker dateScale={DateScale.Month} updateGlobalDate={false} onChange={onDateChange} disabled={loading} />
      </SpaceBetweenRow>
      {loading ? <>
        <Center>
          <LoadingSpinner hideBorder={true} />
        </Center>
      </> : <>
        {reportData.length === 0 ? <>
          <Center style={{marginTop: 32}}>
            <MountedContentCard>
              <MountedContentCardContent>
                <BodyLarge weight={600} colour={Colours.blue} style={{marginBottom: 12}}>{t('reports-page.no-data-found')}</BodyLarge>
                <BodyRegular>{t('reports-page.select-office-from-past-two-years')}</BodyRegular>
              </MountedContentCardContent>
            </MountedContentCard>
          </Center>
        </> : <>
          <OfficeUsageLineChart reportData={reportData} />
        </>}
      </>}

      <SpaceBetweenRow style={{marginTop: 32, marginBottom: 32}}>
        <div style={{minWidth: 300}}>
          <OfficeDropDownList onSelect={onOfficeSelected}
                              disabled={loading}
                              selectDefaultOffice={true}
                              label={"reports-page.choose-an-office"} />
        </div>
      </SpaceBetweenRow>

      <OfficeUsageDownloadReportBar selectedOffice={office} />

    </ReportingContainer>
  );
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
  .recharts-wrapper .recharts-cartesian-grid-horizontal line {
    stroke-opacity: 0;
  }
  .recharts-cartesian-axis-tick-line {
    display: none !important;
  }
`

interface Props {
}

