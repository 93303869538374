import moment, { Moment } from 'moment';
import { agent } from './agent';

export class AccountInfo {
  constructor(
    public quantity: string,
    public endDateString: string,
    public totalUsers: number,
    public isActive: boolean,
    public hasSubscription: boolean,
    public userLimit: number,
    public billTotal: number,
    public dueDateString: string,
    public teamLimit: number,
    public totalTeams: number,
    public usesStripe: boolean,

    public isFreeTrial: boolean,
    public paymentFrequency: PaymentFrequency,
    public enableHolidayBooking: boolean,
  ) {
  }

  public getEndDate(): Moment {
    return moment(this.endDateString);
  }

  public getDueDate(): Moment {
    return moment(this.dueDateString);
  }
}

export function hasUsedTeamAllocation(accountInfo?: AccountInfo): boolean {
  if (!accountInfo) return false;
  return accountInfo.totalTeams >= accountInfo.teamLimit;
}

export enum PaymentFrequency {
  Monthly = 'MONTHLY', Yearly = 'YEARLY'
}

export async function getAccountInfo(): Promise<AccountInfo> {
  const response = await agent.requests.get('/api/stripe/account-info');
  return new AccountInfo(
    response.quantity,
    response.endDate,
    response.totalUsers,
    response.active,
    response.hasSubscription,
    response.userLimit,
    response.billTotal,
    response.dueDate,
    response.teamLimit,
    response.totalTeams,
    response.usesStripe,
    response.freeTrial,
    response.paymentFrequency,
    response.enableHolidayBooking,
  )
}

export interface PaymentInfo {
  lastFour: string;
  cardExpiryDate: string;
}

