import React from 'react';
import styled from "styled-components/macro";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";

export function AccessToReportDenied(props: Props) {
  const message = props?.errorMessage ?? 'You currently don’t have the sufficient privileges to view this user';
  return (
    <>
      {!props?.hideHeader && <HeaderRow>
        <GoBackButton />
      </HeaderRow>}

      <PageWrapper>
        <Container>
          <img alt={""} style={{marginBottom: 30}} src={"/assets/images/shapes2.svg"} />
          <HeadlineSmall style={{marginBottom: 8}}>Insufficient Permissions to View</HeadlineSmall>
          <BodyLarge>{message}</BodyLarge>
        </Container>
      </PageWrapper>
    </>
  )
}

interface Props {
  errorMessage?: string;
  hideHeader?: boolean;
}

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding-top: 16px;
  .goBackButton {
    align-self: center;
    margin-bottom: 0;
  }
`

const PageWrapper = styled.div`
  padding: 5% 0 0 0;
  margin-bottom: 80px;
`

const Container = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Light-Grey, #EDEDED);
  background: var(--Neutrals-White, #FFF);
  padding: 16px;
  margin: 0 auto;
  max-width: 576px;
  @media (${tablet}) {
    padding: 64px 96px;
  }
`
