import React from 'react';
import { Accordian } from "../../../animations/AnimationVariants";
import { BodyError, BodyRegular } from "../atoms/fonts/Body";
import { Error } from "../../../utils/error-helper";

export function GeneralErrorMessage(props: Props) {
  const {error, style} = props;

  return (
    <Accordian isOpen={!!error}>
      {error && <div style={style ?? {}}>
        {error.title && <BodyError>{error.title}</BodyError>}
        {error.description && <BodyRegular>{error.description}</BodyRegular>}
      </div>}
    </Accordian>
  )
}

interface Props {
  error?: Error;
  style?: any;
}
