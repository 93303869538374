import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { AuthType, GetCurrentAuthType } from "../../../utils/AuthUtils";
import { SimpleSpinner } from "../atoms/SimpleSpinner";
import { BodyVerySmall } from "../atoms/fonts/Body";
import {
  SyncStatus,
  WithMicrosoftCalendarSyncActiveChecker
} from "../../../hooks/WithMicrosoftCalendarSyncActiveChecker";


function getColour(status: SyncStatus) {
  switch (status) {
    case SyncStatus.Active: return Colours.green;
    case SyncStatus.Pending: return Colours.orange;
    case SyncStatus.Disabled: return Colours.orange;
    case SyncStatus.Failed: return Colours.red;
    default: return Colours.darkGrey;
  }
}

function getMessage(status: SyncStatus) {
  switch (status) {
    case SyncStatus.Active: return 'Calendar sync active';
    case SyncStatus.Pending: return 'Calendar sync loading';
    case SyncStatus.Failed: return 'No calendar sync';
    case SyncStatus.Disabled: return 'Calendar sync disabled';
    default: return 'Status unknown';
  }
}

export function MicrosoftCalendarSyncActiveIndicator(props: Props) {
  const authType = GetCurrentAuthType();
  const {calendarSyncStatus, manuallyCheckStatus} = WithMicrosoftCalendarSyncActiveChecker();

  const onClick = () => {
    manuallyCheckStatus();
  }

  if (authType !== AuthType.Microsoft || calendarSyncStatus === SyncStatus.NA) return null;

  return <Row style={props.style ?? {}}>
    {calendarSyncStatus === SyncStatus.Pending ? <SimpleSpinner size={22} /> : <TrafficLightIndicator colour={getColour(calendarSyncStatus)} />}
    <BodyVerySmall weight={600} colour={Colours.darkGrey}>{getMessage(calendarSyncStatus)}</BodyVerySmall>
    {(calendarSyncStatus === SyncStatus.Failed || calendarSyncStatus === SyncStatus.Disabled) && <TurnOnButton weight={600} colour={Colours.darkGrey} onClick={onClick}>Turn on</TurnOnButton>}
  </Row>
}

interface Props {
  style?: any;
}

const TrafficLightIndicator = styled.div<any>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${props => props.colour};
  margin-right: 12px;
`

const Row = styled.div<any>`
  background: ${Colours.veryLightGrey};
  border-radius: 50px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  * {
    user-select: none ;
  }
`

const TurnOnButton = styled<any>(BodyVerySmall)<any>`
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
`
