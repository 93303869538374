import React, { useMemo, useState } from 'react';
import styled from "styled-components/macro";
import { signVisitorIn, VisitorBooking } from "../data/visitor-booking-service";
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { BodyRegular, BodySmall, BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import Colours from "../../../../../UI/atoms/Colours";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { cancelReservationRequest } from "../../../../../../services/DeskBookingService";
import { SideOfDay } from "../../../../../../models/movements.models";
import { loadVisitorBookings, updateVisitorBookingItem } from "../data/visitorBooking.duck";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";

export function VisitorBookingLine(props: Props) {
  const {booking} = props;
  const dispatch = useDispatch();
  const [signInProcessing, setSignInProcessing] = useState(false);
  const allOffices = useSelector(selectAllOfficeEntities);

  const isSignedIn = booking.checkedInTime && booking.bookedInByUserId && booking.bookedInByUserName;
  const isSignedOut = booking.checkedInTime && booking.bookedInByUserId && booking.bookedInByUserName && booking.signedOutTime;

  const timeString = useMemo(() => {
    const dateTime = moment();
    dateTime.hours(props.booking.timeDueHours);
    dateTime.minutes(props.booking.timeDueMinutes);
    return dateTime.format('HH : mm');
  }, [props.booking.timeDueMinutes, props.booking.timeDueHours]);

  const isBookingAfterToday = useMemo(() => {
    return moment(booking.date).add(1, 'day').isBefore(moment());
  }, [booking.date]);

  const officeLabel = useMemo(() => {
    return allOffices.find((o: OfficeEntity) => o.id === booking.officeId)?.label;
  }, [allOffices, booking.officeId]);

  const hasDeskBooking = !!booking.amDeskId || !!booking.pmDeskId;

  const signIn = async () => {
    setSignInProcessing(true);
    try {
      const updatedBooking = await signVisitorIn(booking.id);
      dispatch(updateVisitorBookingItem({visitorBooking: updatedBooking}))
      setSignInProcessing(false);
    } finally {
      setSignInProcessing(false);
    }
  }

  const bookDesk = () => {

  }

  const signOut = () => {

  }

  const cancelDesk = async () => {
    try {
      if (booking.amDeskId && booking.pmDeskId && booking.amDeskId === booking.pmDeskId) {
        await cancelReservationRequest(booking.amDeskId, SideOfDay.AllDay, moment(booking.date), `visitor-id-${booking.id}`);
      } else {
        if (booking.amDeskId) {
          await cancelReservationRequest(booking.amDeskId, SideOfDay.AM, moment(booking.date), `visitor-id-${booking.id}`);
        }
        if (booking.pmDeskId) {
          await cancelReservationRequest(booking.pmDeskId, SideOfDay.PM, moment(booking.date), `visitor-id-${booking.id}`);
        }
      }
      dispatch(loadVisitorBookings({date: booking.date}))
    } finally {
    }
  }

  return (
    <Container>
      <TimeContainer>
        <Icon icon={IconTypes.Time} size={'medium'} color={'darkGrey'} />
        <BodySmall weight={600} colour={Colours.darkGrey}>{`${timeString}`}</BodySmall>
      </TimeContainer>

      <Border />

      <NameContainer>
        <BodyRegular weight={600} style={{marginBottom: 4}}>{booking.visitorName}</BodyRegular>
        <BodyVerySmall weight={400} colour={Colours.darkGrey}>{booking.companyName}</BodyVerySmall>
        {(booking.amDeskLabel && officeLabel) && <BodyVerySmall weight={400} colour={Colours.darkGrey}>{booking.amDeskLabel}, {officeLabel}</BodyVerySmall>}
      </NameContainer>

      <Border />

      <NameContainer>
        <BodyRegular weight={600} style={{marginBottom: 4}}>{booking.visitingUserName}</BodyRegular>
        <BodyVerySmall weight={400} colour={Colours.darkGrey}>{booking.visitingUserTeamName}</BodyVerySmall>
      </NameContainer>

      <ButtonContainer>
        {!isBookingAfterToday && !isSignedIn && <PrimaryButton disabled={signInProcessing} text={"Sign In"} click={signIn} style={{marginBottom: 8}} />}
        {isSignedIn && !isSignedOut && <PrimaryButton text={"Sign Out"} click={signOut} style={{marginBottom: 8}} />}
        {hasDeskBooking ? <OutlineButton disabled={signInProcessing} borderColour={'grey'} text={"Cancel Desk"} click={cancelDesk} /> : <OutlineButton text={"Book Desk"} click={bookDesk} />}
      </ButtonContainer>

    </Container>
  )
}

interface Props {
  booking: VisitorBooking;
}

const Container = styled.div`
  background-color: ${Colours.white};
  padding: 24px 32px;
  box-shadow: 0 9px 22px 0 rgba(46, 74, 148, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
`

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
  i {
    width: unset;
  }
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
`

const Border = styled.div`
  width: 1px;
  height: 64px;
  background-color: ${Colours.iconGrey};
  margin: 0 2%;
`

const ButtonContainer = styled.div`
  width: 15%;
`
