import React, { useEffect, useState } from 'react';
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { BodyRegular, BodySmall, BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import styled from "styled-components";
import { Row } from "../../../../../UI/atoms/StructuralLayout";
import TextField, { TextFieldProps } from "../../../../../UI/atoms/TextField";
import { LinkButton } from "../../../../../UI/atoms/buttons/LinkButton";
import { getOfficeCapacity } from "../../../../../../services/OfficeCapacityService";
import moment from "moment";
import Colours from "../../../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";

export function ManageAreaControl(props: Props) {
  const {areas, parentOffice, numberOfActiveDesks} = props;
  const [areaCapacity, setAreaCapacity] = useState(0);
  const {t} = useTranslation();

  useEffect(() => {
    getOfficeCapacity(parentOffice.id, moment())
      .then(resp => setAreaCapacity(Math.max(resp.capacity - numberOfActiveDesks, 0)));
  }, [parentOffice, numberOfActiveDesks]);


  if (areas.length === 0) {
    return <ManageAreaControlContainer>
      <BodyRegular weight={600}>{t('settings.manage-area.create-area')}</BodyRegular>
      <BodySmall>{t('settings.manage-area.message-1')}</BodySmall>
      <BodySmall style={{marginBottom: 16}}>{t('settings.manage-area.message-2')}</BodySmall>
      <EditAreasButton>
        <LinkButton link={`/manage/hot-desking/edit/${parentOffice.id}/areas`}
                    text={"button.add-bookable-area"}
                    size={"medium"}
                    dataTest={"add-area-button"}
                    type={"outline"}
                    fullWidth={true} />
      </EditAreasButton>
    </ManageAreaControlContainer>
  } else {
    return <ManageAreaControlContainer>
      <BodyRegular weight={600}>{t('settings.manage-area.create-area')}</BodyRegular>
      <BodySmall>{t('settings.manage-area.message-1')}</BodySmall>
      <BodySmall style={{marginBottom: 16}}>{t('settings.manage-area.message-2')}</BodySmall>
      <BodyVerySmall colour={Colours.darkGrey}
                     style={{marginBottom: 4}}
                     weight={600}>{t('settings.manage-area.number-of-desks')}</BodyVerySmall>
      <Row style={{alignItems: 'center'}}>

        <SmallTextArea onChange={() => {}}
                       disabled={true}
                       dataTest={'area-capacity-field'}
                       value={`${areaCapacity}`} />

        <EditAreasButton>
          <LinkButton link={`/manage/hot-desking/edit/${parentOffice.id}/areas`}
                      text={"textinput.areas-names-and-avail"}
                      size={"medium"}
                      dataTest={"add-area-button"}
                      type={"outline"}
                      fullWidth={true} />
        </EditAreasButton>
      </Row>
    </ManageAreaControlContainer>
  }
}

interface Props {
  areas: OfficeEntity[];
  isEditable: boolean;
  parentOffice: OfficeEntity;
  numberOfActiveDesks: number;
}

const ManageAreaControlContainer = styled.div`
  margin-bottom: 48px;
`;

const EditAreasButton = styled.div`
  width: 250px;
  max-width: 250px;
  margin-right: 16px;
`;

const SmallTextArea = styled(TextField)<TextFieldProps>`
  max-width: 50px;
  margin: 0 16px 0 0;
  input {
    text-align: center;
  }
`;
