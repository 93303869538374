import React from 'react';
import { HALF_HOUR_TIME_SLOT_HEIGHT, MEETING_ROOM_HEADER_HEIGHT } from "./StructuralComponents";
import styled from "styled-components/macro";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { GridColumnFixedLeft } from "./meeting-room-grid/StructuralComponents";
import { smallTablet } from "../../../UI/atoms/MediaQueries";

export function TimeSwimlane(props: Props) {
  return (
    <Container className={'timeline__container'}>
      <div className="grid-item grid-item--header" style={{border: 'none', height: MEETING_ROOM_HEADER_HEIGHT, minHeight: MEETING_ROOM_HEADER_HEIGHT}}>
      </div>
      <TimeSlot>
        <BodyRegular weight={600}>00:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>01:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>02:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>03:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>04:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>05:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>06:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>07:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>08:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>09:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>10:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>11:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>12:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>13:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>14:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>15:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>16:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>17:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>18:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>19:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>20:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>21:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>22:00</BodyRegular>
      </TimeSlot>
      <TimeSlot>
        <BodyRegular weight={600}>23:00</BodyRegular>
      </TimeSlot>
    </Container>
  )
}

interface Props {
}

const Container = styled<any>(GridColumnFixedLeft)`
  padding: 0 16px 0 6px;
  @media (${smallTablet}) {
    padding: 0 22px 0 32px;
  }
`

const TimeSlot = styled.div`
  height: ${HALF_HOUR_TIME_SLOT_HEIGHT * 2}px;
  padding-top: 8px;
`
