import React from "react";
import { BodyRegular } from "../../atoms/fonts/Body";
import Dialog from "../../molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import { PrimaryButton } from "../../atoms/buttons/PrimaryButton";

export default function StripeFailedDialog() {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeDialog())
    window.history.pushState(null, document.title, window.location.origin + window.location.pathname);
  }

  return (
    <Dialog isOpen={true} onClose={() => dispatch(closeDialog())}>
      <DialogHeader title="dialog-headers.payment-failed"/>
      <BodyRegular style={{marginBottom: 32}}>The payment failed or was canceled, please try again.</BodyRegular>
      <PrimaryButton click={close} text="button.close" fullWidth={true} size="large"/>
    </Dialog>
  );
}
