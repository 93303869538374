import React, { useEffect } from 'react';
import styled from "styled-components/macro";
import { ManageUsersSidebar } from "./components/ManageUsersSidebar";
import Layout from "../../../../UI/organisms/layout/Layout";
import { FloatingSidebar, FullScreenContent } from "../../../company-movements/components/ViewComponents";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { ManageUsersHeader } from "./components/ManageUsersHeader";
import { ManageUsersList } from "./components/ManageUsersList";
import { useDispatch } from "react-redux";
import { loadDetails, loadUsers } from "./ducks/manage-users.duck";

export function ManageUsersPage(props: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDetails());
    dispatch(loadUsers());
  }, [dispatch]);

  return (
    <Layout hideFooter={true} fullScreenView={true} hideRibbon={true}>
      <FullScreenContent>
        <Sidebar>
          <ManageUsersSidebar />
        </Sidebar>
        <ManageUsersContent>
          <ManageUsersHeader />
          <ManageUsersList />
        </ManageUsersContent>
      </FullScreenContent>
    </Layout>
  )
}

interface Props {
}

const Sidebar = styled<any>(FloatingSidebar)<any>`
  @media (${tablet}) {
  }
`

const ManageUsersContent = styled.div`
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 24px;
  @media (${tablet}) {
    margin-right: 300px;
    margin-left: auto;
    max-width: calc(1400px - ((2200px - (100vw - 1400px)) - 1400px) / 2);
  }
  @media (min-width: 1100px) {
    margin-right: 330px;
  }
  @media (min-width: 2000px) {
    max-width: 1300px;
  }
`
