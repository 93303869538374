import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import DialogHeader from "./DialogHeader";
import Dialog from "../UI/molecules/Dialog";
import { isCompanyAdminUser } from "../../utils/AccessControl";
import { selectCurrentUser } from "../../store/ducks/auth.duck";
import AccessRequired from "../pages/settings/components/AccessRequired";
import { LoadingSpinner } from "../UI/atoms/LoadingSpinner";
import { BodyRegular, BodySmall } from '../UI/atoms/fonts/Body';
import Colours from "../UI/atoms/Colours";
import { ConfigurationList } from "../pages/settings/StyleComponents";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import { checkDeskUsage, DeskUsageSummary } from "../../services/DeskBookingService";
import { deleteDesk, selectIsEditDesksLoading } from "../../store/ducks/editDesk.duck";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  payload?: {deskId: number, officeId: number};
}

export default function DeleteDeskEntityDialog(props: Props) {
  const {payload} = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => isCompanyAdminUser(currentUser), [currentUser]);
  const [loading, setLoading] = useState(true);
  const [deskUsage, setDeskUsage] = useState<DeskUsageSummary | undefined>();

  const close = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  useEffect(() => {
    if (payload?.deskId) {
      setLoading(true)
      setDeskUsage(undefined);
      checkDeskUsage(payload.deskId).then(setDeskUsage).finally(() => setLoading(false))
    } else {
      console.error('No office entity id');
      close();
    }
  }, [close, dispatch, payload]);

  return (
    <Dialog isOpen={true}
            onClose={close}
            showLogo={true}>
      <AccessRequired teamAdminsRequired={false}
                      companyAdminsRequired={true}
                      visible={!hasAccess} />
      {hasAccess && <>
        {loading ? <>
          <LoadingSpinner hideBorder={true} />
        </> : <>
          {deskUsage && <>
            {deskUsage.floorPlans ? <>
              <DeskCannotBeDeleted />
            </> : <>
              <DeskItemsToDeleteMessage deskId={payload?.deskId ?? 0}
                                        deskUsage={deskUsage} />
            </>}
          </>}
        </>}
      </>}
    </Dialog>
  )
}

function DeskCannotBeDeleted() {
  const dispatch = useDispatch();
  return (
    <>
      <DialogHeader title="dialog-headers.cannot-be-deleted" />
      <BodyRegular style={{marginBottom: 32}}>
        <Trans i18nKey={'delete-desk-dialog.cannot-be-deleted'}
               components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} />
      </BodyRegular>
      <OutlineButton click={() => dispatch(closeDialog())} text={"button.close"} size={"large"} fullWidth={true} />
    </>
  )
}

function DeskItemsToDeleteMessage(props: {deskId: number, deskUsage?: DeskUsageSummary}) {
  const {deskId, deskUsage} = props;
  const dispatch = useDispatch();
  const loading = useSelector(selectIsEditDesksLoading);
  const {t} = useTranslation();

  const confirmDeleteClicked = () => {
    dispatch(deleteDesk(deskId));
  }

  return (
    <>
      <DialogHeader title="dialog-headers.are-you-sure" />
      <BodyRegular>{t('delete-desk-dialog.message')}</BodyRegular>
      {loading && <LoadingSpinner fullScreen={true} />}
      <ConfigurationList>
        <ul>
          <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-desk-dialog.a-desk')}</BodySmall></li>
          {!!deskUsage?.numberOfTimesBooked && <li style={{color: Colours.black}}><BodySmall style={{display: 'inline'}}>{t('delete-desk-dialog.bookings', {count: deskUsage.numberOfTimesBooked})}</BodySmall></li>}
        </ul>
      </ConfigurationList>
      <OutlineButton click={confirmDeleteClicked}
                     text={"button.yes-delete"}
                     disabled={loading}
                     size={"large"}
                     fullWidth={true} />
    </>
  )
}
