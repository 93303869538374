import React from 'react';
import { useDispatch } from "react-redux";
import { AuthType, clearLoginStorage, GetCurrentAuthType } from "../../../utils/AuthUtils";
import { redirectTo, setAuthLoading } from "../../../store/ducks/auth.duck";
import { Column } from "../../UI/atoms/StructuralLayout";
import { CurrentUserAvatarAndName } from "../../UI/molecules/UserAvatarAndName";
import { Link } from "react-router-dom";
import { HeadlineXtraSmall } from "../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { isInTeams } from "../../../utils/TeamsUtils";

export function MicrosoftTeamsLogoutButton(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {instance} = useMsal();

  const logout = async () => {
    clearLoginStorage();
    dispatch(setAuthLoading(true));
    props.onClick();
    try {
      await instance.logoutRedirect({postLogoutRedirectUri: `${window.location.origin}/login`,});
      dispatch(redirectTo('/logout/azure'))
    } finally {
      setAuthLoading(false);
    }
  };

  if (isInTeams() && GetCurrentAuthType() === AuthType.Microsoft) {
    return null;
  }

  return (
    <Column className="logoutMenuItem">
      <CurrentUserAvatarAndName className="logoutMenuItem__avatar" colour="blue" hideName={true}/>
      <Link to="#" onClick={logout} className="logoutMenuItem__link noSelect">
        <HeadlineXtraSmall className="logoutMenuItem__text">{t('button.logout')} </HeadlineXtraSmall>
        <img src="/assets/icons/font/Logout.svg" alt="" />
      </Link>
    </Column>
  )
}

interface Props {
  onClick: () => any;
}
