import React, { useMemo } from 'react';
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import styled from "styled-components";
import Colours from "../../../../UI/atoms/Colours";
import { BodySmall } from "../../../../UI/atoms/fonts/Body";
import { Column, Row, VerticallyCenteredRow } from "../../../../UI/atoms/StructuralLayout";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { UserResponsibilityBadges } from "../../../../UI/molecules/UserResponsibilityBadges";
import { useTranslation } from "react-i18next";

export function OfficeListUserRow(props: Props) {
  const {t} = useTranslation();
  const {firstName, lastName, amDeskLabel, pmDeskLabel, containerOffice, amOfficeId, pmOfficeId,
    fireWarden, firstAider, mentalHealthAider, keyHolder} = props;

  const allDayDeskLabel = useMemo(() => {
    if (amDeskLabel || pmDeskLabel) {
      if (amDeskLabel === pmDeskLabel) {
        return amDeskLabel;
      }
    }
    return '';
  }, [amDeskLabel, pmDeskLabel]);


  return (
    <UserRow>
      <VerticallyCenteredRow>
        <UserAvatarAndName firstName={firstName} lastName={lastName} colour={'darkBlue'} />
        <UserResponsibilityBadges fireWarden={fireWarden}
                                  firstAider={firstAider}
                                  mentalHealthAider={mentalHealthAider}
                                  keyHolder={keyHolder} />
      </VerticallyCenteredRow>

      <Column style={{justifyContent: 'center'}}>

        {amDeskLabel || pmDeskLabel ? <>
          {amDeskLabel && !allDayDeskLabel && <Row style={{justifyContent: 'flex-end'}}>
            <BodySmall colour={Colours.darkGrey} weight={400} style={{marginRight: 4}}>{amDeskLabel} -</BodySmall>
            <BodySmall colour={Colours.blue} weight={400}>{t('general.AM')}</BodySmall>
          </Row>}

          {pmDeskLabel && !allDayDeskLabel && <Row style={{justifyContent: 'flex-end'}}>
            <BodySmall colour={Colours.darkGrey} weight={400} style={{marginRight: 4}}>{pmDeskLabel} -</BodySmall>
            <BodySmall colour={Colours.blue} weight={400}>{t('general.PM')}</BodySmall>
          </Row>}

          {allDayDeskLabel && <BodySmall colour={Colours.darkGrey} weight={400} style={{marginRight: 4}}>{allDayDeskLabel}</BodySmall>}
        </> : <>
          {amOfficeId !== pmOfficeId && <>
            {amOfficeId === containerOffice.id && <BodySmall colour={Colours.blue} weight={400}>{t('general.am-only')}</BodySmall>}
            {pmOfficeId === containerOffice.id && <BodySmall colour={Colours.blue} weight={400}>{t('general.pm-only')}</BodySmall>}
          </>}
        </>}

      </Column>
    </UserRow>
  )
}

interface Props {
  firstName: string;
  lastName: string;
  amOfficeId?: number;
  pmOfficeId?: number;
  amDeskLabel?: string;
  pmDeskLabel?: string;
  containerOffice: OfficeEntity;
  fireWarden?: boolean;
  firstAider?: boolean;
  mentalHealthAider?: boolean;
  keyHolder?: boolean;
}

const UserRow = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colours.lightGrey};
  display: flex;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
`
