import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import { selectOfficeForReservation } from "../../../../store/ducks/deskReservation.duck";
import { MobileOnly } from "../../../UI/atoms/DeviceSpecific";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import DialogHeader from "../../DialogHeader";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { LinkButton } from "../../../UI/atoms/buttons/LinkButton";
import OfficeLocationContentCard from "../../../UI/molecules/OfficeLocationContentCard";
import styled from "styled-components/macro";
import { Center, Row } from "../../../UI/atoms/StructuralLayout";
import { selectActiveOffices } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";
import { WithSeatsAvailableInAllOffices } from "../../../../hooks/WithSeatsAvailableInAllOffices";
import { OfficeWithCapacity } from "../../../../services/OfficeCapacityService";
import { Moment } from "moment";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";

interface Props {
  activeDate: Moment;
}

export function ChooseOffice(props: Props) {
  const dispatch = useDispatch();
  const offices = useSelector(selectActiveOffices);
  const close = () => dispatch(closeDialog());
  const {t} = useTranslation();

  const {allCapacities, isLoading} = WithSeatsAvailableInAllOffices(props.activeDate);

  const allOfficesWithCapacities = useMemo(() => {
    return offices.map((office: OfficeEntity) => {
      const capacity = allCapacities.find(c => c.officeId === office.id);
      if (capacity) {
        return {
          office: office,
          capacity: capacity
        }
      } else {
        return undefined;
      }
    }).filter((owc?: OfficeWithCapacity) => !!owc);
  }, [offices, allCapacities]);

  const officeSelected = (office: OfficeEntity) => {
    if (office.active) {
      dispatch(selectOfficeForReservation(office));
    } else {
      dispatch(warningNotification('This office is not active.'));
    }
  };

  return (
    <>
      <MobileOnly>
        <GoBackButton onClick={close} text="back-to-whereabouts"/>
      </MobileOnly>
      <DialogHeader title="dialog-headers.choose-office"/>

      {offices.length === 0 && <FlatContentCard>
        <BodyRegular weight={600}>{t('whereabouts.no-offices')}</BodyRegular>
        <BodyRegular>{t('whereabouts.no-offices-description')}</BodyRegular>
        <LinkButton text="whereabouts.add-office-button" link="/manage/advance-hot-desking-setup"/>
      </FlatContentCard>}

      {isLoading ? <>
        <Center>
          <LoadingSpinner hideBorder={true} />
        </Center>
      </> : <>
        <WrappedRow>
          {allOfficesWithCapacities.map((owc: OfficeWithCapacity, key: number) => (
            <OfficeLocationContentCard onClick={() => officeSelected(owc.office)}
                                       key={key}
                                       capacity={owc.capacity}
                                       office={owc.office}/>
          ))}
        </WrappedRow>
      </>}


      <FlatContentCard>
        <BodyRegular weight={600}>{t('whereabouts.favouriting-suggestion-title')}</BodyRegular>
        <BodyRegular>{t('whereabouts.favouriting-suggestion-description')}</BodyRegular>
      </FlatContentCard>
    </>
  )
}

const WrappedRow = styled<any>(Row)`
  flex-wrap: wrap;
  margin-bottom: 24px;
`
