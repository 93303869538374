import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeskReservationSelection,
  selectSelectedFloor,
  selectSelectionOffice
} from "../../../../store/ducks/deskReservation.duck";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";

export function CurrentOfficeWhereaboutsSelection(props: Props) {
  const dispatch = useDispatch();
  const selectedOffice = useSelector(selectSelectionOffice);
  const selectedFloor = useSelector(selectSelectedFloor);

  // No office or No Date then No view
  if (!selectedOffice) {
    return null;
  }

  return (
    <Wrapper>
      <Row>
        <Icon size="mediumlarge" icon={IconTypes.Office} style={{marginRight: 16}} />
        <Column>
          <BodyRegular weight={600}>{selectedOffice.label ?? '???'}</BodyRegular>
          {!!selectedFloor && <BodyRegular weight={600}>{selectedFloor?.label}</BodyRegular>}
        </Column>
      </Row>
      <ChangeButtonWrapper>
        <OutlineButton click={() => dispatch(clearDeskReservationSelection())} text="button.change" />
      </ChangeButtonWrapper>
    </Wrapper>
  )
}

interface Props {
  children?: any;
}

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Wrapper = styled.div`
  display: flex;
  padding: 16px 16px 16px 24px;
  margin-top: 16px;
  border-top: 1px solid ${Colours.lightGrey};
  min-width: 350px;
  .teamDropDownList {
    margin: 0;
  }
  i {
    max-width: 32px;
    width: 32px;
  }
`

const ChangeButtonWrapper = styled.div`
  margin-left: auto;
  button {
    width: 80px;
    height: 32px;
    color: ${Colours.darkGrey};
    border-color: ${Colours.mildGrey};
  }
`
