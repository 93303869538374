import React, { useEffect, useState } from "react";
import { ConfigurationSection, DeskAvailabilityCount } from "../../StyleComponents";
import { SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import Colours from "../../../../UI/atoms/Colours";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { Switch } from "../../../../UI/atoms/Switch";
import { Accordian } from "../../../../../animations/AnimationVariants";
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectConfig, selectIsConfigLoading, updateConfig } from "../../../../../store/ducks/config.duck";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { isCompanyAdminUser } from "../../../../../utils/AccessControl";

interface Props {
  disabled: boolean;
}

export default function HolidayAllowanceSetting(props: Props) {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const isLoading = useSelector(selectIsConfigLoading);
  const [editedHolidayAllowance, setEditedHolidayAllowance] = useState(0);
  const canEditHolidayAllowances = isCompanyAdminUser(currentUser);

  useEffect(() => {
    setEditedHolidayAllowance(config?.companyHolidayAllowance ?? 0);
  }, [config])

  const saveHolidayAllowance = () => {
    dispatch(updateConfig({
      ...config,
      companyHolidayAllowance: editedHolidayAllowance
    }))
  }

  const onChange = (enabled: boolean) => {
    dispatch(updateConfig({
      ...config,
      holidayAllowance: enabled
    }))
  }

  return (
    <ConfigurationSection style={{borderBottom: 'none'}}>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.holiday-allowance.title')}</BodyLarge>
        <Switch disabled={props.disabled || isLoading}
                value={config.holidayAllowance} label="" switchClass=""
                onChange={enabled => onChange(enabled)}/>
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 8}}>{t('settings.holiday-allowance.message-1')}</BodyRegular>
      <Accordian isOpen={config.holidayAllowance} className="">

        <SpaceBetweenRow style={{marginBottom: 16, justifyContent: 'center'}}>
          <DeskAvailabilityCount type="number" onChange={setEditedHolidayAllowance} onBlur={saveHolidayAllowance}
                                 disabled={isLoading} style={{marginBottom: 0}}
                                 value={editedHolidayAllowance}
                                 rightText="onboarding.holiday-management-default-number"/>
          {isLoading && <SimpleSpinner size={24} />}
        </SpaceBetweenRow>

        {currentUser?.companyEntity.enableHolidayBooking && canEditHolidayAllowances && <OutlineButton link={'/holidays/settings/allowance'} size={'medium'}
                                                                                                       style={{'margin': '0 0 24px 0', width: '280px', maxWidth: '300px'}}
                                                                                                       text={'settings.holiday-allowance.edit-individual-allowances'} />}

        <FlatContentCard>
          <BodyLarge colour={Colours.blue}>{t('settings.holiday-allowance.please-note')}</BodyLarge>
          <BodyRegular style={{marginBottom: 8}}>{t('settings.holiday-allowance.message-2', {defaultHolidayCount: config?.companyHolidayAllowance ?? 0})}</BodyRegular>
        </FlatContentCard>
      </Accordian>
    </ConfigurationSection>
  )
}
