import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../../../store/state/app.state";
import { AddTeamMemberMethod, AddTeamMemberView } from "./AddUsers.model";
import { sendInvite } from "../../../../../../services/TeamService";
import { loadAccountInfo } from "../../../../../../store/ducks/payments.duck";
import { BasicUserInfo } from "../../../../../../models/user.models";
import { TeamEntity } from "../../../../../../models/team.models";
import { UpdateUserField, updateUserField } from "../../../../../../services/UserService";
import { parseErrorFromException } from "../../../../../../utils/error-helper";
import { loadConfig } from "../../../../../../store/ducks/config.duck";
import { failureNotification } from "../../../../../../store/ducks/notification.duck";

export interface AddUsersState {
  isLoading: boolean;
  addTeamMemberMethod: AddTeamMemberMethod;
  view: AddTeamMemberView;
  error?: string;
  team?: TeamEntity;
}

export const initialAddUsersState: AddUsersState = {
  isLoading: false,
  addTeamMemberMethod: AddTeamMemberMethod.NewUser,
  view: AddTeamMemberView.AddUser,
}

export const addNewUser: any = createAsyncThunk(
  'addUsers/addNewUser',
  async (params: {firstname: string, lastname: string, emailAddress: string, teamId: string}, thunkAPI) => {
    try {
      await sendInvite(params.emailAddress, params.firstname, params.lastname, params.teamId);
      await thunkAPI.dispatch(loadAccountInfo());
      await thunkAPI.dispatch(loadConfig());
    } catch (e: any) {
      throw e;
    }
  }
)

export const moveUser: any = createAsyncThunk(
  'addUsers/moveUser',
  async (params: {user: BasicUserInfo, team: TeamEntity}, thunkAPI) => {
    try {
      await updateUserField(params.user.userId, UpdateUserField.team, params.team.id)
      await thunkAPI.dispatch(loadConfig());
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to move user'))
      throw e;
    }
  }
)

const addUsersSlice = createSlice({
  name: 'addUsers',
  initialState: initialAddUsersState,
  reducers: {
    reset: () => ({...initialAddUsersState}),
    setAddTeamMemberMethod: (state, action) =>
      ({...state, addTeamMemberMethod: action.payload, error: undefined}),
    setAddTeamMemberView: (state, action) =>
      ({...state, view: action.payload, error: undefined}),
    setSelectedTeam: (state, action) =>
      ({...state, team: action.payload}),
  },
  extraReducers: {
    [addNewUser.pending]: (state) => ({...state, isLoading: true, error: undefined}),
    [addNewUser.rejected]: (state, action) => ({...state, isLoading: false, error: parseErrorFromException(action.error)}),
    [addNewUser.fulfilled]: (state, action) => ({...state, isLoading: false, view: AddTeamMemberView.UserAddedSuccessful, error: undefined}),

    [moveUser.pending]: (state) => ({...state, isLoading: true}),
    [moveUser.rejected]: (state, action) => ({...state, isLoading: false}),
    [moveUser.fulfilled]: (state, action) => ({...state, isLoading: false, view: AddTeamMemberView.UserMovedSuccessful}),
  }
})

export default addUsersSlice.reducer;
export const {
  reset,
  setAddTeamMemberMethod,
  setAddTeamMemberView,
  setSelectedTeam,
} = addUsersSlice.actions;

// Selectors
export const selectIsLoading = (state: AppState) => state.addUsers.isLoading;
export const selectAddTeamMemberMethod = (state: AppState) => state.addUsers.addTeamMemberMethod;
export const selectAddTeamMemberView = (state: AppState) => state.addUsers.view;
export const selectError = (state: AppState) => state.addUsers.error;
export const selectSelectedTeam = (state: AppState) => state.addUsers.team;
