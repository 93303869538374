import React from 'react';
import './movements-wall-chart.scss';
import HeaderRow from './components/team-member-row/HeaderRow';
import { Accordian } from '../../../../animations/AnimationVariants';
import { TeamWithMovements } from "../../../../models/movements.models";
import { EditTeamControlsRow } from "../../../pages/team-movements/components/edit-team-row/EditTeamRow";
import { useDispatch } from "react-redux";
import { expandTeamMovements } from "../../../../store/ducks/companyMovements.duck";
import { MediumLoadingSpinner } from "../../atoms/LoadingSpinner";
import { Center } from "../../atoms/StructuralLayout";
import EmptyTeamView from "./components/empty-team-view/EmptyTeamView";
import MovementsList from "./components/team-movements-list/MovementsList";
import RestrictedTeamView from "./components/empty-team-view/RestrictedTeamView";

interface TeamWallChartProps {
  movementsViewObject: TeamWithMovements;
  disableAccordian: boolean;
  enabledSetMovements: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
}

export function TeamWallChart(props: TeamWallChartProps) {
  const {movementsViewObject, disableAccordian, enabledSetMovements} = props;
  const dispatch = useDispatch();

  const onVisibilityStateChange = (val: boolean) => {
    if (!disableAccordian) {
      dispatch(expandTeamMovements({
        team: movementsViewObject,
        visibility: val,
      }));
    }
  }

  return (
    <React.Fragment>
      {(!disableAccordian || props.showHeader) && <HeaderRow team={movementsViewObject.team}
                                                             disableDropdown={disableAccordian}
                                                             defaultIsOpen={movementsViewObject.visible || !!props.showHeader}
                                                             onOpen={onVisibilityStateChange}/>}

      <Accordian isOpen={movementsViewObject.visible} className="wallchart__team">
        {movementsViewObject.movementsLoaded && movementsViewObject.movements ?
          <>
            {movementsViewObject.restricted ? <>
              <RestrictedTeamView />
            </> : <>
              {movementsViewObject.movements.length > 0 ?
                <MovementsList movements={movementsViewObject.movements}
                               enableSetMovements={enabledSetMovements}/> :
                <EmptyTeamView team={movementsViewObject.team}/>
              }
            </>}
            {props.showFooter && <EditTeamControlsRow team={movementsViewObject.team} showTeamDetails={true} />}
          </>
          :
          <Center style={{marginTop: 16}}>
            <MediumLoadingSpinner hideBorder={true} />
          </Center>
        }
      </Accordian>
    </React.Fragment>
  )
}
