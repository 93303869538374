import { createSlice, } from '@reduxjs/toolkit'
import { Permission } from "../../../models/user.models";
import { AppState } from "../../../store/state/app.state";
import { PermissionType } from "../../../models/permission-type.models";

export interface PermissionNeededDialogState {
  isOpen: boolean;
  permissionRequired?: String;
  permissionTypeRequired: PermissionType;
}

export const initialPermissionNeededDialogState: PermissionNeededDialogState = {
  isOpen: false,
  permissionRequired: Permission.TeamAdmin,
  permissionTypeRequired: PermissionType.AddUser,
}

const slice = createSlice({
  name: 'permissionNeeded',
  initialState: initialPermissionNeededDialogState,
  reducers: {
    setIsPermissionNeededDialogOpen: (state, action) => {
      return {
        ...initialPermissionNeededDialogState,
        ...action.payload,
      };
    },
  },
  extraReducers: {}
})

export default slice.reducer;
export const {
  setIsPermissionNeededDialogOpen,
} = slice.actions;

// Selectors
export const selectIsPermissionNeededDialogOpen = (state: AppState) => state.permissionNeededDialog.isOpen;
export const selectPermissionType = (state: AppState) => state.permissionNeededDialog.permissionRequired;
