import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../state/app.state";
import { createOrUpdateOffice, OfficeEntity, OfficeType, } from "../../services/AdvanceHotDeskingService";
import { fetchOfficeEntities } from "./advanceHotDeskingSetup.duck";
import { failureNotification } from "./notification.duck";
import { Error } from "../../utils/error-helper";
import { redirectTo } from "./auth.duck";
import { Desk } from "../../services/DeskBookingService";

export interface EditOfficeLayout {
  editingOffice?: OfficeEntity;
  editingOfficeDesks: Desk[];
  floors: OfficeEntity[];
  loading: boolean;
  error?: Error;
}

export const initialEditOfficeLayout: EditOfficeLayout = {
  floors: [],
  editingOfficeDesks: [],
  loading: false,
}

export const addFloorToOffice: any = createAsyncThunk(
  'editOfficeLayout/addFloorToOffice',
  async (parentOfficeId: number, thunkAPI) => {
    return {
      id: 0,
      label: '',
      capacity: '',
      officeId: 0,
      parentId: parentOfficeId
    }
  }
);

export const updateOfficeFloor: any = createAsyncThunk(
  'editOfficeLayout/updateOfficeFloor',
  async (props: {floor: OfficeEntity, field: string, value: any}, thunkAPI) => {
    const appState = (thunkAPI.getState() as AppState);
    const idx = appState.editOfficeLayout.floors.indexOf(props.floor);
    if (idx > -1) {
      let floorToUpdate = {
        ...appState.editOfficeLayout.floors[idx],
      };
      // @ts-ignore
      floorToUpdate[props.field] = props.value;
      let updatedArray = [...appState.editOfficeLayout.floors];
      updatedArray.splice(idx, 1, floorToUpdate);
      return updatedArray
    }
    return appState.editOfficeLayout.floors;
  }
);

export const createOrUpdateOfficeLayout: any = createAsyncThunk(
  'editOfficeLayout/createOrUpdateOfficeLayout',
  async (office: OfficeEntity, thunkAPI) => {
    try {
      const editingFloors = ((thunkAPI.getState() as AppState).editOfficeLayout.floors);
      const newOffice = await createOrUpdateOffice({
        id: office.id,
        name: office.label,
        postCode: office.postCode,
        capacity: office.capacity,
        floors: editingFloors,
        parentId: 0,
        officeType: OfficeType.OFFICE, //TODO Change to be dynamic
        capacityEnabled: office.capacityEnabled || false,
        carParkCapacity: office?.carParkCapacity,
        officeBookingWindowWeeks: office.officeBookingWindowWeeks,
        restrictBookingWindowForTeamAdmins: office.restrictBookingWindowForTeamAdmins,
      });

      await thunkAPI.dispatch(fetchOfficeEntities());
      await new Promise(((resolve: any) => setTimeout(() => resolve(), 500)));
      if (office.id) {
        // await thunkAPI.dispatch(setEditingOffice(selectOfficeById(thunkAPI.getState() as AppState, office.parentId)));
      } else {
        thunkAPI.dispatch(redirectTo(`/manage/hot-desking/edit/${newOffice.id}`));
      }
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to update office'));
    }
  }
);

const editOfficeLayoutSlice = createSlice({
  name: 'editOfficeLayout',
  initialState: initialEditOfficeLayout,
  reducers: {
    resetEditOfficeLayout: () => ({...initialEditOfficeLayout}),
    setError: (state, action) => ({...state, error: action.payload}),
    setEditingFloors: (state, action) => ({...state, floors: action.payload}),
    setEditingDesks: (state, action) => ({...state, editingOfficeDesks: action.payload}),
    addEditingDesks: (state, action) => ({...state, editingOfficeDesks: [...state.editingOfficeDesks, ...action.payload]}),
    setEditingOffice: (state, action) => ({
      ...initialEditOfficeLayout,
      editingOffice: action.payload.office,
      floors: action.payload.children ?? [],
      editingOfficeDesks: [],
    }),
  },
  extraReducers: {
    [createOrUpdateOfficeLayout.pending]: (state) => ({...state, loading: true}),
    [createOrUpdateOfficeLayout.failed]: (state) => ({...state, loading: false}),
    [createOrUpdateOfficeLayout.fulfilled]: () => ({...initialEditOfficeLayout, loading: false}),

    [addFloorToOffice.fulfilled]: (state, action) => ({...state, floors: [...state.floors, action.payload]}),
    [updateOfficeFloor.fulfilled]: (state, action) => ({...state, floors: action.payload}),
  }
})

export default editOfficeLayoutSlice.reducer;
export const {
  resetEditOfficeLayout,
  setEditingOffice,
  setError,
  setEditingFloors,
  setEditingDesks,
  addEditingDesks,
} = editOfficeLayoutSlice.actions;

export const selectEditingOffice = (state: AppState) => state.editOfficeLayout.editingOffice;
export const selectAllDesksInEditingOffice = (state: AppState) => state.editOfficeLayout.editingOfficeDesks;
export const selectFloors = (state: AppState) => state.editOfficeLayout.floors;
export const selectIsLoading = (state: AppState) => state.editOfficeLayout.loading;
export const selectEditOfficeError = (state: AppState) => state.editOfficeLayout.error;
