import React from 'react';
import './icon.scss';

export enum IconTypes {
  AppIcon = 'icon-AppIcon',
  Aeroplane = 'icon-Aeroplane',
  Alt = 'icon-Alt',
  Add = 'icon-Add',
  Alert = 'icon-Alert',
  Arrow = 'icon-Arrow',
  BackArrow = 'icon-BackArrow',
  Billing = 'icon-Billing',
  Boat = 'icon-Boat',
  Bin = 'icon-Bin',
  Coffee = 'icon-Coffee',
  CVV = 'icon-CVV',
  Comments = 'icon-Comments',
  Compass = 'icon-Compass',
  Chair = 'icon-Chair',
  CircleTick = 'icon-CircleTick',
  CircleAlert = 'icon-CircleAlert',
  Calendar = 'icon-Calendar',
  Close = 'icon-Close',
  Down = 'icon-Down',

  Up = 'icon-Up',
  More = 'icon-More',
  Office = 'icon-Office',
  Repeat = 'icon-Repeat',
  Message = 'icon-Message',
  Time = 'icon-Time',
  Next = 'icon-Next',
  Play = 'icon-Play',
  Previous = 'icon-Previous',
  Minus = 'icon-Minus',
  Logout = 'icon-Logout',
  Profile = 'icon-Profile',
  Users = 'icon-Users',
  Login = 'icon-Login',
  Tick = 'icon-Tick',
  Menu = 'icon-Menu',
  Search = 'icon-Search',
  Undo = 'icon-Undo',
  Redo = 'icon-Redo',
  Home = 'icon-Home',
  Notification = 'icon-Notification',
  Padlock = 'icon-Padlock',
  Email = 'icon-Email',
  Phone = 'icon-Phone',
  FavouriteFill = 'icon-FavouriteFill',
  FavouriteOutline = 'icon-FavouriteOutline',
  Settings = 'icon-Settings',
  Reporting = 'icon-Reporting',
  Info = 'icon-Info',
  Holiday = 'icon-Holiday',
  Flag = 'icon-Flag',
  Show = 'icon-Show',
  Hide = 'icon-Hide',
  Share = 'icon-Share',
  Zoom100 = 'icon-Zoom100',
  Offsite = 'icon-Offsite',
  Underweather = 'icon-UnderWeather',
  HardHat = 'icon-HardHat',
  Edit = 'icon-Edit',
  Copy = 'icon-Copy',

  Notworking = 'icon-NotWorking',
  ZoomIn = 'icon-ZoomIn',
  ZoomOut = 'icon-ZoomOut',
  LoadingSpinner = 'icon-LoadingSpinner',
  BuildingSite = 'icon-BuildingSite',
}

export interface IconProps {
  icon: IconTypes,
  className?: string,
  rotation?: string,
  onClick?: any,
  color?: string,
  size?: 'verySmall' | 'small' | 'medium' | 'mediumlarge' | 'big' | 'large',
  circle?: boolean
  style?: any,
  dataTest?: string;
}

export default function Icon(props: IconProps) {
  const rotation = props.rotation || 0;
  const className = props.className || '';
  const onClick = props.onClick || (() => {
  });
  const color = `icon--${props.color || 'black'}`;
  const large = `icon--${props.size || 'standard'}`;
  const icon = props.icon;
  const circle = props.circle ? 'icon--circle' : '';
  const style = {...(props.style ?? {}), transform: `transform: rotate(${rotation})`}

  let inputAttributes = {};
  if (props.dataTest) {
    // @ts-ignore
    inputAttributes['data-test'] = props.dataTest;
  }

  return (
    <i onClick={(e) => onClick(e)}
       className={`icon ${icon} ${circle} ${color} ${large} ${className}`.trim()}
       style={style} {...inputAttributes}/>
  )
}
