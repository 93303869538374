import React, { useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../../../store/ducks/auth.duck";
import { updateCompany } from "../../../../services/CompanyService";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";

export function DisableUpdatingPreviousWhereaboutsSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onChange = async (val: boolean) => {
    setIsLoading(true);
    if (user?.companyEntity) {
      try {
        await updateCompany({
          ...user.companyEntity,
          disableEditingPreviousWhereabouts: val
        });
        dispatch(setUser({
          ...user,
          companyEntity: {
            ...user.companyEntity,
            disableEditingPreviousWhereabouts: val
          }
        }))
      } finally {
        setIsLoading(false);
      }
    } else {
      dispatch(warningNotification('No company attached to user'))
    }
  }

  return (
    <ConfigurationSection style={{marginTop: 32, marginBottom: 48}}>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.edit-past-whereabouts-title')}</BodyLarge>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.disableEditingPreviousWhereabouts} label="" onChange={onChange}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 8}}>{user?.companyEntity.disableEditingPreviousWhereabouts ?
        t('settings.edit-past-whereabouts-enabled-description') : t('settings.edit-past-whereabouts-disabled-description')}</BodyRegular>
      <BodyRegular style={{marginBottom: 8}}>{t('settings.edit-past-whereabouts-recommendation')}</BodyRegular>
    </ConfigurationSection>
  )
}
