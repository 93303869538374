import { useEffect, useState } from 'react';
import { isInTeams } from "../../utils/TeamsUtils";
import { microsoftRequest } from "../../services/EnvironmentVariables";
import { app } from "@microsoft/teams-js";
import { useDispatch, useSelector } from "react-redux";
import { rawUserRequest } from "../../services/Auth";
import { redirectTo, selectCurrentUser, setAuthLoading, setUser } from "../../store/ducks/auth.duck";
import {
  AuthType,
  GetCurrentAuthType,
  GetMicrosoftLoginAccount,
  resetAllLoginStorages,
  teamsLogin
} from "../../utils/AuthUtils";
import { TrackJS } from "trackjs";
import { useMsal } from "@azure/msal-react";
import { isOnPublicPage } from "../../App";


export function WithTeamsAuth() {
  const dispatch = useDispatch();
  const [context, setContext] = useState<app.Context | null>(null);
  const user = useSelector(selectCurrentUser);
  const {instance} = useMsal();

  useEffect(() => {
    if (isInTeams() && !context) {
      app.initialize().then(() => app.getContext()).then((ctx: app.Context) => {
        setContext(ctx);
        // TODO: Refactor to after sign in (or if signin fails)
        setTimeout(() => app.notifySuccess(), 100);
      });
    }
  }, [context, dispatch]);

  useEffect(() => {
    if (!isInTeams() || !context || !!user) return;

    const requestUserToCheckAuth = async () => {
      const userResp = await rawUserRequest();
      dispatch(setUser(userResp));
    }

    const teamsAuthentication = async () => {
      try {
        const account = GetMicrosoftLoginAccount(instance);
        await teamsLogin(instance, microsoftRequest.login.scopes, dispatch, true, false, account);
      } catch (e) {
        console.log('TeamsAuth error', e);
        resetAllLoginStorages();
        if (!isOnPublicPage()) {
          dispatch(redirectTo('/'));
        }
      } finally {
        dispatch(setAuthLoading(false));
      }
    }

    const standardAuthentication = async () => {
      try {
        await requestUserToCheckAuth();
      } catch (e: any) {
        TrackJS.track('Failed to make /current request');
        resetAllLoginStorages();
        if (!isOnPublicPage()) {
          dispatch(redirectTo('/'));
        }
      } finally {
        dispatch(setAuthLoading(false));
      }
    }

    if (GetCurrentAuthType() === AuthType.Standard) {
      standardAuthentication();
    } else {
      teamsAuthentication();
    }

  }, [instance, context, dispatch, user]);
}
