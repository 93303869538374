import React, { useEffect, useMemo, useState } from 'react';
import styled from "styled-components/macro";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import { Center } from "../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { canViewUserReport, isCompanyAdminUser, isDepartmentAdmin } from "../../../utils/AccessControl";
import { useTranslation } from "react-i18next";
import { getDepartmentsWhereaboutsSummary } from "../../../services/ReportingService";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { MountedContentCard, MountedContentCardContent } from "../../UI/atoms/MountedContentCard";
import { BodyLarge, BodyRegular } from '../../UI/atoms/fonts/Body';
import { selectAllDepartments } from "../departments/data/departments.duck";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import Colours from "../../UI/atoms/Colours";
import { WhereaboutsSummaryBarChart } from "./components/WhereaboutsSummaryBarChart";
import { Spacer } from "../../UI/atoms/VerticalSpacer";
import moment, { Moment } from "moment/moment";
import { calculateMaxValue, transformWhereaboutsUsage } from "./WhereaboutsReportingPage";
import { WhereaboutsBookingDownloadReportBar } from "./components/WhereaboutsBookingDownloadReportBar";

export function DepartmentWhereaboutsReport() {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>(0);
  const [date, setDate] = useState<Moment>(moment());

  const [reportData, setReportData] = useState<any>([]);
  const [keyTableData, setKeyTableData] = useState<any>([]);
  const [maxYAxisValue, setMaxYAxisValue] = useState(0);

  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => canViewUserReport(currentUser),[currentUser]);
  const {t} = useTranslation();
  const departments = useSelector(selectAllDepartments);

  const visibleDepartments = useMemo(() => {
    if (isCompanyAdminUser(currentUser)) {
      return departments;
    } else if (isDepartmentAdmin(currentUser) && currentUser?.department) {
      return departments.filter(d => d.id === currentUser?.department?.id);
    } else {
      return [];
    }
  }, [currentUser, departments]);

  useEffect(() => {
    if (visibleDepartments.length === 1) {
      setSelectedDepartmentId(visibleDepartments[0].id);
    }
  }, [visibleDepartments]);

  const onDepartmentSelected = (department?: number) => {
    setReportData([]);
    if (department) {
      setSelectedDepartmentId(department);
    }
  }

  useEffect(() => {
    if (date && selectedDepartmentId) {
      setReportData([]);
      setLoading(true)
      getDepartmentsWhereaboutsSummary(date, selectedDepartmentId).then(resp => {
        const optionList = resp.whereaboutsOptionList;
        const whereaboutsMonthlySummaryKeys = Object.keys(resp.whereaboutsUsage);
        const data = whereaboutsMonthlySummaryKeys.map(key => ({
          name: moment(key).format('ddd')[0],
          dateOfMonth: moment(key).format('DD'),
          date: key,
          // @ts-ignore
          ...transformWhereaboutsUsage(optionList, resp.whereaboutsUsage[key])
        }))
        data.sort((a, b) => a.date < b.date ? -1 : 1);
        setReportData(data);
        setKeyTableData(optionList);
        setMaxYAxisValue(calculateMaxValue(resp.whereaboutsUsage));
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [date, selectedDepartmentId]);

  const onDateChange = (date: Moment) => {
    setDate(date);
  }


  return (
    <ReportingContainer>
      {hasAccess &&
        <>
          <Header>
            <HeadlineLarge style={{marginBottom: 16}}>{t('reports-page.department-report')}</HeadlineLarge>
            <SpaceBetweenRow>
              <DateFlicker dateScale={DateScale.Month}
                           updateGlobalDate={false}
                           onChange={onDateChange}
                           disabled={loading} />
              <div style={{width: 230, maxWidth: '100%'}}>
                <select disabled={loading}
                        className={`teamDropDownList`}
                        name="Team" value={selectedDepartmentId}
                        onChange={(e: any) => onDepartmentSelected(e.target.value)}>
                  <option>{''}</option>
                  {visibleDepartments.map((t, k) => <option key={k} value={t.id}>{t.name}</option>)}
                </select>
              </div>
            </SpaceBetweenRow>
          </Header>

          {!!selectedDepartmentId ? <>
            {loading ? <>
              <Center>
                <LoadingSpinner hideBorder={true} />
              </Center>
            </> : <>
              {reportData.length === 0 ? <>
                <Center style={{marginTop: 32}}>
                  <MountedContentCard>
                    <MountedContentCardContent>
                      <BodyLarge weight={600} colour={Colours.blue} style={{marginBottom: 12}}>{t('reports-page.whereabouts-page.no-date')}</BodyLarge>
                      <BodyRegular>{t('reports-page.whereabouts-page.select-a-date')}</BodyRegular>
                    </MountedContentCardContent>
                  </MountedContentCard>
                </Center>
              </> : <>
                <WhereaboutsSummaryBarChart reportData={reportData}
                                            maxValueOfYAxis={maxYAxisValue}
                                            keyTableData={keyTableData} />
              </>}
            </>}
            <Spacer />
            <WhereaboutsBookingDownloadReportBar selectedDepartmentId={selectedDepartmentId}
                                                 selectedDepartmentName={departments.find(d => d.id === selectedDepartmentId)?.name}/>
          </> : <>
          </>}

        </>
      }
    </ReportingContainer>
  );
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
`

const Header = styled.div`
  margin-bottom: 24px;
`
