import styled from "styled-components/macro";
import Colours from "../../../../atoms/Colours";
import { desktopMidWide, tablet } from "../../../../atoms/MediaQueries";

export const SubHeaderContainer = styled.div<any>`
  padding-top: 8px;
  min-height: 42px;
  width: 100%;
  background-color: ${Colours.veryDarkBlue};
  position: ${props => props.fullScreenView ? 'fixed' : 'relative'};
  margin-top: ${props => props.fullScreenView ? '56px' : '0'};
`

export const SubHeaderContent = styled.div`
  max-width: 1400px;
  margin: 0 16px;
  display: flex;
  @media (${desktopMidWide}) {
    margin: 0 auto;
  }
`

export const SubHeaderMenuItem = styled.div<any>`
  padding: 10px 16px 8px 16px;
  background-color: ${props => props.selected ? 'white' : 'unset'};
  border-radius: 8px 8px 0 0;
  margin-right: 2px;
  cursor: pointer;
  max-width: 120px;
  display: flex;
  align-self: center;
  min-height: 50px;
  @media (${tablet}) {
    min-height: unset;
    max-width: unset;
    padding: 12px 20px 16px 20px;
    margin-right: 4px;
  }
  
  p {
    user-select: none;
    color: ${props => props.selected ? Colours.blue : 'white'};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-weight: 600;
    @media (${tablet}) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`
