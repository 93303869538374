import React from 'react';
import styled from "styled-components/macro";
import { FilterControlBar } from "../../../../UI/organisms/FilterControlBar";

export function FilterBar(props: Props) {

  return (
    <Wrapper>
      <FilterControlBar onGroupSelected={props.onGroupSelected} />
    </Wrapper>
  )
}

interface Props {
  onGroupSelected: (selection: any) => any;
}

const Wrapper = styled.div`
  margin-bottom: 24px;
`
