import React, { useMemo } from 'react';
import { BasicMovementIcon } from "../../../../UI/atoms/BasicMovementIcon";
import { WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import styled from "styled-components";
import { Row } from "../../../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import Colours from "../../../../UI/atoms/Colours";
import { useDispatch, useSelector } from "react-redux";
import { setEditingWhereaboutsOption } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { DialogIdentifiers, openDialog } from "../../../../../store/ducks/dialog.duck";
import { canEditCustomWhereabouts } from "../../../../../utils/AccessControl";
import { selectCurrentUser, selectIconPack } from "../../../../../store/ducks/auth.duck";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";

export function EditableWhereaboutsOption(props: Props) {
  const {whereabouts} = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const iconPack = useSelector(selectIconPack)
  const canEdit = useMemo(() => !whereabouts.required && canEditCustomWhereabouts(currentUser), [whereabouts, currentUser]);

  const onClick = () => {
    dispatch(setEditingWhereaboutsOption(whereabouts));
    dispatch(openDialog(DialogIdentifiers.AddCustomWhereabouts));
  }

  return (
    <EditableWhereaboutsRow>
      <BasicMovementIcon onClick={() => {}} option={whereabouts} iconPack={iconPack} />
      <BodyRegular>{whereabouts.label}</BodyRegular>
      <Row style={{marginLeft: 'auto'}}>
        {canEdit && <div style={{marginLeft: 16}}>
          <IconButton onClick={onClick} icon={IconTypes.Edit} size={"mediumlarge"} />
        </div>}
      </Row>
    </EditableWhereaboutsRow>
  )
}

interface Props {
  whereabouts: WhereaboutsOption;
}

const EditableWhereaboutsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  & > div {
    margin-right: 16px;
  }
  &:hover {
    background-color: ${Colours.blue10};
  }
`;
