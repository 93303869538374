export enum NotificationMessages {
  teamDeleted = 'tdel',
  unknownError = 'unk',
  errorLoadingTeam = 'loadTea',
  userDeleted = 'udel',
  companyUpdated = 'compup',
  accessDenied = '401',
  officeDeleted = 'odel',
  officeCreated = 'ocr',
  officeUpdated = 'oup',
  loginRequired = 'log',
  azureLoginFailed = 'azu',
  noAccountFound = 'reg',
  cancelledByUser = 'canByUser',
  accountAlreadyRegistered = 'exis',
}

export function getNotificationMessage(notificationMessage: NotificationMessages) {
  switch (notificationMessage) {
    case NotificationMessages.teamDeleted: return 'Team deleted';
    case NotificationMessages.unknownError: return 'Unknown error';
    case NotificationMessages.errorLoadingTeam: return 'Error loading team';
    case NotificationMessages.userDeleted: return 'User deleted';
    case NotificationMessages.companyUpdated: return 'Company name updated';
    case NotificationMessages.accessDenied: return 'Access denied - have you logged in?';
    case NotificationMessages.officeDeleted: return 'Office deleted';
    case NotificationMessages.officeCreated: return 'Office created';
    case NotificationMessages.officeUpdated: return 'Office updated';
    case NotificationMessages.loginRequired: return 'Log in required';
    case NotificationMessages.noAccountFound: return 'Could not find account, have you registered?';
    case NotificationMessages.cancelledByUser: return 'Authentication with Microsoft has not been completed';
    case NotificationMessages.accountAlreadyRegistered: return 'That account is already registered';
    case NotificationMessages.azureLoginFailed: return 'Failed to log in with Microsoft.';
  }
}

export function redirectWithNotification(notificationMessage: NotificationMessages, url: string, replaceCurrentUrl?: boolean) {
  if (replaceCurrentUrl) {
    window.history.replaceState({}, '', `${url}?not=${notificationMessage}&t=${Date.now()}`);
    window.location.href = `${url}?not=${notificationMessage}&t=${Date.now()}`;
  } else {
    window.location.href = `${url}?not=${notificationMessage}&t=${Date.now()}`;
  }
}

export function parseErrorResponse(errorMessage: string, fallback: NotificationMessages): NotificationMessages {
  try {
    if (errorMessage.toLowerCase().includes('user not registered')) {
      return NotificationMessages.noAccountFound;
    } else {
      return fallback;
    }
  } catch (e: any) {
    return fallback;
  }
}
