import React, { useMemo } from 'react';
import { WallPlannerTeamRow } from "./WallPlannerTeamRow";
import { WallPlannerUserRow } from "./WallPlannerUserRow";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { selectWorkGroupDetails } from "../wallPlanner.duck";
import { Moment } from "moment";
import { Tag } from "../../../../models/tags.models";
import { GroupWallPlannerWhereabouts } from "../../../../models/wall-planner.models";

interface Props {
  workGroup?: Tag;
  isFirst: boolean;
  weeksInMonth: Moment[];
}

export function WallPlannerWorkGroupPlan(props: Props) {
  const {weeksInMonth, isFirst, workGroup} = props;
  const allWorkGroupDetails = useSelector(selectWorkGroupDetails);
  const workGroupDetails: GroupWallPlannerWhereabouts = useMemo(() => {
    if (workGroup) {
      const details = allWorkGroupDetails[workGroup.id];
      return {
        whereabouts: details?.whereabouts ?? [],
        restricted: details?.restricted,
      }
    } else {
      return {whereabouts: []};
    }
  }, [allWorkGroupDetails, workGroup]);

  if (workGroup) {
    return (
      <TeamContainer columns={weeksInMonth.length + (window.innerWidth < 768 ? 0 : 1)}>
        <WallPlannerTeamRow isFirst={isFirst} teamName={workGroup?.label ?? 'No name set'} restricted={workGroupDetails.restricted}/>
        {!workGroupDetails.restricted && <>
          {workGroupDetails.whereabouts.map((user: any, k: any) => (
            <WallPlannerUserRow key={k} user={user.userInfo} working={user.working} holidays={user.holidays}/>
          ))}
          <WallPlannerUserRow />
        </>}
      </TeamContainer>
    )
  } else {
    return null;
  }
}

export const TeamContainer = styled.div<any>`
  width: 100%;
  height: 100%;
  &:last-child {
    margin-bottom: 55px;
  }
  .userColumn__wrapper {
    z-index: 999;
  }
`
