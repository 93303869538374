import styled, { css } from "styled-components";
import Colours from "./Colours";

export const Pill = styled.div<{ colour?: string, backgroundColour?: string }>`
  background-color: ${Colours.veryLightGrey};
  border: 1px solid;
  display: inline-flex;
  border-radius: 16px;
  margin-bottom: 4px;
  padding: 4px 16px;
  ${props => css`color: ${props.colour ? props.colour : Colours.darkGrey};`};
  ${props => css`border-color: ${props.colour ? props.colour : Colours.darkGrey};`};
  ${props => css`background-color: ${props.backgroundColour ? props.backgroundColour : Colours.veryLightGrey};`};
  & > * {
    ${props => css`color: ${props.colour ? props.colour : Colours.darkGrey};`};
  }
`

export const GreenPill = styled<any>(Pill)<any>`
  background-color: #BDFAC8;
  border-color: #BDFAC8;
  color: #0D5C32;
`
