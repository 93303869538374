import React from 'react';
import styled from "styled-components";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import Colours from "../atoms/Colours";
import { Center } from "../atoms/StructuralLayout";

export function LoadingOverlay(props: Props) {
  return (
    <LoadingOverlayWrapper onClick={(event) => event.stopPropagation()}>
      <Center>
        <LoadingSpinner hideBorder={true} />
      </Center>
    </LoadingOverlayWrapper>
  )
}

interface Props {
}

const LoadingOverlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${Colours.black50};
  z-index: 1000;
`
