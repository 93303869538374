import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import { Column } from "../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import {
  CarParkAvailability,
  CarParkingPlan,
  getCarParkAvailability
} from "../../../../services/CarParkingPlanService";
import {
  MapView,
  selectSelectedCarParkingPlan,
  selectSelectedMapView,
  setSelectedCarPark,
  setSelectedMapView
} from "../../../../store/ducks/officeView.duck";
import { setSelectedParkingPlanId } from "../ducks/carParkingMapView.duck";
import { Moment } from "moment";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";

interface Props {
  plan: CarParkingPlan;
  onSelect?: () => any;
  date?: Moment;
}

export function OfficeCarParkPlanButton(props: Props) {
  const {plan, date} = props;
  const dispatch = useDispatch();
  const selectedMapView = useSelector(selectSelectedMapView);
  const selectedCarParkPlan = useSelector(selectSelectedCarParkingPlan);
  const [availability, setAvailability] = useState<CarParkAvailability |  undefined>();
  const dateString = date?.format(DATE_FORMAT);

  const onClick = () => {
    dispatch(setSelectedMapView(MapView.CarPark));
    dispatch(setSelectedCarPark(plan));
    dispatch(setSelectedParkingPlanId(plan.id));
  }

  useEffect(() => {
    if (dateString) {
      getCarParkAvailability(dateString, plan.id).then(setAvailability)
    }
  }, [dateString, plan.id]);

  return (
    <Wrapper>
      <CarParkButton onClick={onClick} isActive={selectedMapView === MapView.CarPark && selectedCarParkPlan?.id === plan.id}>
        <BodyRegular weight={600}>{plan.name}</BodyRegular>
        {availability ? <>
          <BodyRegular>{availability.totalSpaces - availability.usedSpaces} total spaces</BodyRegular>
        </> : <>
          <SimpleSpinner size={16} />
        </>}
      </CarParkButton>
    </Wrapper>
  )
}

const Wrapper = styled<any>(Column)`
  //padding-right: 32px;
  margin-bottom: 16px;
`

const CarParkButton = styled.div<any>`
  width: 100%;
  border: ${props => props.isActive ? `2px solid ${Colours.blue}` : `1px solid ${Colours.mildGrey}`};
  padding: ${props => props.isActive ? `15px 23px` : `16px 24px`};
  border-radius: 8px;
  cursor: pointer;
`
