import React from 'react';
import styled from "styled-components";
import DateFlicker, { DateScale } from "../../../../UI/molecules/DateFlicker";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import moment, { Moment } from "moment";
import { addOrReplaceQueryParam } from "../../../../../utils/UrlUtils";
import { useSelector } from "react-redux";
import { selectCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import { PageTitle } from "../../../../UI/atoms/PageTitle";
import { selectConfig } from "../../../../../store/ducks/config.duck";
import { toAirBnbDate } from "../../../../../utils/DateUtils";

export function UsersAnnualLeaveHeader(props: Props) {
  const calendarDate = useSelector(selectCalendarDate);
  const config = useSelector(selectConfig);
  const onDateChange = (date: Moment) => {
    addOrReplaceQueryParam('year', `${date.year()}`);
  }

  return (
    <Container>
      <PageTitle text={'holidays.holidays'} style={{marginTop: 0}} />
      <DateFlicker dateScale={DateScale.Year}
                   updateGlobalDate={true}
                   onChange={onDateChange}
                   dateLabelOverride={toAirBnbDate(config.getHolidayYearStartDate(calendarDate), config.getHolidayYearEndDate(calendarDate) || moment())}
                   date={config.getHolidayYearStartDate(calendarDate)}
                   showDateRange={true}
                   fontWeight={600} />
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 10px 15px -20px #000000;
  max-width: 100%;
  @media (${tablet}) {
    flex-direction: row;
    padding-bottom: 12px;
  }
`
