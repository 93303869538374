import React from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconTypes } from '../../atoms/icon/Icon';
import './icon-button.scss';
import { BodyRegular } from "../../atoms/fonts/Body";
import { useTranslation } from "react-i18next";

export default function IconButton(props: IconButtonProps) {
  if (props.link) {
    return (
      <Link to={props.link} className={`iconButton__link ${props.className || ''}`.trim()}>
        <Button {...props} className="" />
      </Link>
    )
  }
  return <Button {...props} />
}

function Button(props: IconButtonProps) {
  const {t} =useTranslation();
  const iconColor = props.color ? 'iconButton--' + props.color : '';
  const iconDisabled = props.disabled ? 'iconButton--disabled' : '';
  const fullWidth = props.fullWidth ? 'iconButton--fullWidth' : '';
  const size = props.size ? `iconButton--${props.size}` : '';
  const className = props.className || '';
  const click = (e: Event) => {
    e.stopPropagation();
    if (props.onClick) props.onClick(e);
  }
  let buttonAttributes = {};
  if (!!props.dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = props.dataTest;
  }
  return <div style={props?.style ?? {}} className={`noSelect iconButton__button ${iconColor} ${iconDisabled} ${className} ${size} ${fullWidth}`.trim()} onClick={(e: any) => click(e)}>
    <Icon {...buttonAttributes} icon={props.icon} color={props.color} size={props.size || 'medium'} />
    { props.text ? <BodyRegular weight={600} style={{marginLeft: 8}}>{t(props.text)}</BodyRegular> : null }
  </div>
}

export interface IconButtonProps {
  icon: IconTypes;
  text?: string;
  link?: string;
  onClick?: any;
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  dataTest?: string;
  size?: 'medium' | 'mediumlarge' | 'large';
  style?: any;
}
