import React, { useMemo, useState } from 'react';
import Dialog from "../../../UI/molecules/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import DialogHeader from "../../../dialogs/DialogHeader";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import { PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import { Column } from "../../../UI/atoms/StructuralLayout";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";
import { WithOfficeInformation } from "../../../../hooks/WithOfficeInformation";
import TextField from "../../../UI/atoms/TextField";
import { TimeRangeSelector } from "../components/TimeRangeSelector";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import {
  failureNotification,
  successNotification,
  warningNotification
} from "../../../../store/ducks/notification.duck";
import { Moment } from "moment";
import { bookMeetingRoomRequest } from "../../../../services/MeetingRoomsService";
import { refreshBookings } from "../models/room-finder.duck";
import { Switch } from "../../../UI/atoms/Switch";
import moment from "moment/moment";
import { selectIsCalendarSyncActive } from "../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import { MeetingRoomLoadingSpinner } from "../components/MeetingRoomLoadingSpinner";

export function MeetingRoomBookingDialog(props: Props) {
  const {hour, minutes, meetingRoom} = props.payload;
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);
  const calendarSyncActive = useSelector(selectIsCalendarSyncActive);
  const {t} = useTranslation();

  const [meetingName, setMeetingName] = useState('');
  const [fromTime, setFromTime] = useState<Moment | undefined>();
  const [toTime, setToTime] = useState<Moment | undefined>();
  const [loading, setLoading] = useState(false);
  const [addToCalendar, setAddToCalendar] = useState(true);

  const officeInfo = WithOfficeInformation(meetingRoom.officeId);

  const isOutlookRoom = useMemo(() => {
    return !!meetingRoom.microsoftRoomId;
  }, [meetingRoom]);

  const close = () => {
    dispatch(closeDialog());
  }

  const onMeetingNameChange = (meetingName: string) => {
    setMeetingName(meetingName);
  }

  const confirmBooking = async () => {
    if (isOutlookRoom && !calendarSyncActive) {
      dispatch(warningNotification('Cannot book this meeting room without calendar permissions.'));
      return;
    }

    if (!activeDay || !fromTime || !toTime) {
      dispatch(warningNotification('No date or times identified'));
      return;
    }

    const bookingFromDateTime = activeDay.clone();
    const bookingToDateTime = activeDay.clone();
    bookingFromDateTime.hour(fromTime.hour());
    bookingFromDateTime.minutes(fromTime.minutes());
    bookingFromDateTime.seconds(0);
    bookingFromDateTime.milliseconds(0);
    bookingToDateTime.hour(toTime.hour());
    bookingToDateTime.minutes(toTime.minutes());
    bookingToDateTime.seconds(0);
    bookingToDateTime.milliseconds(0);


    if (bookingToDateTime.isBefore(moment())) {
      dispatch(failureNotification('Cannot book meeting room in the past'));
      throw new Error('Cannot book meeting room in the past');
    }

    try {
      setLoading(true);
      await bookMeetingRoomRequest(meetingRoom, bookingFromDateTime, bookingToDateTime, meetingName, addToCalendar);
      dispatch(refreshBookings());
      close();
      dispatch(successNotification('Meeting room booked'));
    } catch (e: any) {
      dispatch(failureNotification('Failed booking meeting room'));
      TrackJS?.track(e);
    } finally {
      setLoading(false);
    }
  }

  const onTimeChange = (from: Moment, to?: Moment) => {
    if (!to) return;
    setFromTime(from);
    setToTime(to);
  }

  return (
    <>

      <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
        {loading ? <MeetingRoomLoadingSpinner isOutlook={isOutlookRoom} /> : <>
          <DialogHeader title={'room-booking-dialog.title'} />

          {isOutlookRoom && !calendarSyncActive && <>
            <BodyLarge weight={600}>{t('room-booking-dialog.cannot-book-outlook-room')}</BodyLarge>
            <BodyRegular style={{marginBottom: 24}}>{t('room-booking-dialog.cannot-book-outlook-room-description')}</BodyRegular>
          </>}

          <SummaryBox>
            <Icon icon={IconTypes.AppIcon} size={'mediumlarge'} />
            <Column>
              <BodyRegular weight={600}>{meetingRoom.name}</BodyRegular>
              <BodyRegular>{activeDay?.format(PRETTY_DATE_FORMAT)}</BodyRegular>
              <BodyRegular>{(officeInfo?.officeNamePath ?? []).join(', ')}</BodyRegular>
              <BodyRegular>Approx. {meetingRoom.capacity} people</BodyRegular>
            </Column>
          </SummaryBox>

          {(!isOutlookRoom || (isOutlookRoom && calendarSyncActive)) ? <>
            <TextField onChange={onMeetingNameChange}
                       label={'room-booking-dialog.meeting-title'}
                       value={meetingName} />
            <Switch value={addToCalendar} label={'room-booking-dialog.add-to-calendar'}
                    onChange={setAddToCalendar}
                    style={{marginBottom: 12}} />
            <TimeRangeSelector style={{marginBottom: 16}}
                               hour={hour}
                               minutes={minutes}
                               onTimeChange={onTimeChange} />
            <PrimaryButton click={confirmBooking}
                           text={'room-booking-dialog.confirm-booking'}
                           fullWidth={true} />
            <div style={{marginBottom: 80}}></div>
          </> : <>

            <PrimaryButton click={close}
                           text={'button.close'}
                           style={{marginTop: 64}}
                           fullWidth={true} />

          </>}
        </>}
      </DialogWrapper>
    </>
  )
}

interface Props {
  payload: any;
}


export const DialogWrapper = styled<any>(Dialog)<any>`
`

const SummaryBox = styled<any>(FlatContentCard)`
  display: flex;
  padding: 24px;
  margin-bottom: 24px;
  i {
    margin-right: 16px;
  }
`
