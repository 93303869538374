import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ToggleableOutlineButton } from "../../UI/atoms/buttons/ToggleableOutlineButton";
import { BodyRegular, BodyVerySmall } from "../../UI/atoms/fonts/Body";
import { getOffice, OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { getOfficeLayout } from "../../../services/DeskBooking";
import { getParameterByName } from "../../../utils/UrlUtils";
import {
  createDesk,
  deleteDeskRequest,
  Desk,
  EditableDesk,
  getDesksInOffice,
  updateDesk
} from "../../../services/DeskBookingService";
import { EditableDeskSpot } from "./EditableDeskSpot";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Row } from "../../UI/atoms/StructuralLayout";
import TextField from "../../UI/atoms/TextField";
import Icon, { IconTypes } from "../../UI/atoms/icon/Icon";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { loadMeetingRooms, selectAllMeetingRooms } from "../settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { getFloorPlanUrl } from "../company-movements/components/OfficeFloorMap";
import { MeetingRoom } from "../meeting-rooms/models/MeetingRooms";
import { updateMeetingRoomPosition } from "../../../services/MeetingRoomsService";
import { EditableMeetingRoomSpot } from "./EditableMeetingRoomSpot";

export function FloorPlanEditPage(props: Props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<any>();
  const [lockedDesk, setLockedDesk] = useState<EditableDesk | undefined>();
  const [selectedDesk, setSelectedDesk] = useState<EditableDesk | undefined>();
  const [renameDeskEnabled, setRenameDeskEnabled] = useState<boolean>(false);
  const [editedDeskName, setEditedDeskName] = useState('');
  const [plan, setPlan] = useState<any>();
  const [office, setOffice] = useState<OfficeEntity | undefined>(undefined);
  const [editableDesks, setEditableDesks] = useState<EditableDesk[]>([]);
  const mapRef = useRef<any>(null);
  const lastMousePos = useRef({x: 0, y: 0});

  const allMeetingRooms = useSelector(selectAllMeetingRooms);
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState<MeetingRoom | undefined>(undefined);
  const [editableMeetingRooms, setEditableMeetingRooms] = useState<MeetingRoom[]>([])

  useEffect(() => {
    if (office && office.floorPlanEnabled) {
      getOfficeLayout(office.officePlan).then(setPlan);
    }
  }, [office]);

  useEffect(() => {
    dispatch(loadMeetingRooms())
  }, [dispatch]);

  useEffect(() => {
    const officeId = getParameterByName('officeId', window.location.href);
    if (officeId) {
      getOffice(parseInt(officeId)).then(setOffice);
    }
  }, []);

  useEffect(() => {
    const rooms: MeetingRoom[] = allMeetingRooms.filter(mr => mr.officeId === office?.id)
      .map(room => ({
        ...room,
        x: room.x ?? 100,
        y: room.y ?? 100,
        width: room.width ?? 0,
        height: room.height ?? 0,
        rotate: 0,
      }))
    setEditableMeetingRooms(rooms);
  }, [allMeetingRooms, office?.id]);

  useEffect(() => {
    const findDeskInPlan = (desk: Desk): any | undefined => {
      try {
        return plan?.desks.find((d: any) => d.deskId === desk.id) as any | undefined;
      } catch {
        return;
      }
    }

    if (office && plan) {
      getDesksInOffice(office.id).then((desks: Desk[]) => {
        const desksWithDefaults: EditableDesk[] = desks.map((d: Desk)=> {
          const deskFromPlan = findDeskInPlan(d);
          return {
            ...d,
            x: d?.x || deskFromPlan?.x || 450,
            y: d?.y || deskFromPlan?.y || 250,
            width: d?.width || deskFromPlan?.width || 48,
            height: d?.height || deskFromPlan?.height || 96,
            rotation: d?.rotation || deskFromPlan?.rotate || 0,
            editted: (!d.x && !!deskFromPlan),
          }
        })
        setEditableDesks(desksWithDefaults);
      });
    }
  }, [office, plan])

  const onEditDeskClick = (e: any, desk: EditableDesk) => {
    setSelectedDesk(desk);
  }

  const onDeskLock = (desk: EditableDesk) => {
    setLockedDesk(desk);
    setSelectedDesk(undefined);
  }

  const onDeskRelease = (desk: EditableDesk) => {
    setLockedDesk(undefined);
  }

  const clearSelection = () => {
    setLockedDesk(undefined);
  }

  const addDesk = async () => {
    setLoading(true);
    if (editableDesks.length === 0) {
      alert('You need to add atleast one desk via the setting panel')
      throw new Error("No desks");
    }
    try {
      clearSelection();
      const createdDesk = await createDesk({
          ...editableDesks[0],
          label: `Desk ${editableDesks.length+1}`,
          id: 0,
          active: true,
          x: 250,
          y: 250,
          width: 48,
          height: 96,
          rotation: 0,
        });

      setEditableDesks([
        ...editableDesks,
        createdDesk,
      ])
    } finally {
      setLoading(false);
    }
  }

  const changeDeskSize = (width: number, height: number) => {
    if (selectedDesk) {
      const filteredDesks = editableDesks.filter((ed: EditableDesk) => ed.id !== selectedDesk.id);
      const updatedDesk = {
        ...selectedDesk,
        editted: true,
        height: height,
        width: width,
      }
      setEditableDesks([
        ...filteredDesks,
        updatedDesk
      ]);
    }
  }

  const rotateDesk = (deg: number) => {
    if (selectedDesk) {
      const filteredDesks = editableDesks.filter((ed: EditableDesk) => ed.id !== selectedDesk.id);
      const updatedDesk = {
        ...selectedDesk,
        // @ts-ignore
        rotation: selectedDesk.rotation += deg,
        editted: true,
      }
      setEditableDesks([
        ...filteredDesks,
        updatedDesk
      ]);
    }
  }

  const renameDesk = () => {
    if (selectedDesk) {
      setRenameDeskEnabled(true);
      setEditedDeskName(selectedDesk.label);
    }
  }

  const updateDeskName = (label: string) => {
    if (selectedDesk) {
      setEditedDeskName('');
      const filteredDesks = editableDesks.filter((ed: EditableDesk) => ed.id !== selectedDesk.id);
      const updatedDesk = {
        ...selectedDesk,
        editted: true,
        label: label
      }
      setEditableDesks([
        ...filteredDesks,
        updatedDesk
      ]);
    }
  }

  const onMouseMove = (e: any) => {
    const nowX = e.clientX;
    const nowY = e.clientY;
    const diffX = nowX - lastMousePos.current.x;
    const diffY = nowY - lastMousePos.current.y;
    if (lockedDesk) {
      e.stopPropagation();
      const filteredDesks = editableDesks.filter((d: any) => d !== lockedDesk);
      const updatedDesk = {
        ...lockedDesk,
        x: lockedDesk.x += (diffX / (mapRef?.current?.state?.scale ?? 1)),
        y: lockedDesk.y += (diffY / (mapRef?.current?.state?.scale ?? 1)),
        editted: true,
      }
      setLockedDesk(updatedDesk);
      setEditableDesks([...filteredDesks, updatedDesk])
    }
    if (selectedMeetingRoom) {
      e.stopPropagation();
      const filteredRooms = editableMeetingRooms.filter(mr => mr !== selectedMeetingRoom);
      const updatedRoom = {
        ...selectedMeetingRoom,
        x: (selectedMeetingRoom?.x ?? 0) + (diffX / (mapRef?.current?.state?.scale ?? 1)),
        y: (selectedMeetingRoom?.y ?? 0) + (diffY / (mapRef?.current?.state?.scale ?? 1)),
      }
      setSelectedMeetingRoom(updatedRoom);
      setEditableMeetingRooms([...filteredRooms, updatedRoom]);
    }
    lastMousePos.current = {x: nowX, y: nowY};
  }

  const deleteDeskClick = () => {
    if (selectedDesk) {
      setLoading(true);
      deleteDeskRequest(selectedDesk.id)
        .then(() => {
          const filteredDesks = editableDesks.filter((ed: EditableDesk) => ed.id !== selectedDesk.id);
          clearSelection();
          setEditableDesks([
            ...filteredDesks,
          ]);
        })
        .finally(() => setLoading(false));
    }
  }

  const saveDesks = async () => {
    setLoading(true);
    try {
      const edittedDesks = editableDesks.filter(ed => ed.editted && ed.id);
      const newDesks = editableDesks.filter(ed => ed.editted && !ed.id);
      await Promise.all(edittedDesks.map(ed => updateDesk(ed)));
      await Promise.all(newDesks.map(ed => createDesk(ed)));
      await Promise.all(editableMeetingRooms.map(mr => updateMeetingRoomPosition(mr.id, mr?.x ?? 0, mr?.y ?? 0)));
    } finally {
      setLoading(false);
    }
  }

  const onMeetingRoomMouseDown = (e: any, room: MeetingRoom) => {
    e.stopPropagation();
    setSelectedMeetingRoom(room);

  }

  const onMeetingRoomMouseRelease = (e: any, room: MeetingRoom) => {
    setSelectedMeetingRoom(undefined);
  }

  const onMouseUp = (e: any) => {
    setSelectedMeetingRoom(undefined);
    setLockedDesk(undefined);
  }

  return (
    <>
      {loading && <LoadingSpinner fullScreen={true} />}
      <FloorMapContainer onMouseMove={onMouseMove} onMouseUp={onMouseUp}>

        <TransformWrapper centerOnInit={plan?.centerOnInit ?? true}
                          initialScale={plan?.initialScale ?? 0.5}
                          maxScale={plan?.maxScale ?? 2}
                          minScale={plan?.minScale ?? 0.5}
                          ref={mapRef}
                          panning={{disabled: !!lockedDesk}}>
          {(params: any) => (
            <div style={{position: 'relative', height: '100%', width: '100%'}} onClick={clearSelection}>
              <ButtonRow>
                <ToggleableOutlineButton click={() => addDesk()} text="Add desk" isActive={true}/>
                <ToggleableOutlineButton click={() => saveDesks()} text="Save" isActive={true}/>
                {selectedDesk && <DeskControls>

                  <BodyRegular weight={600}>{selectedDesk?.label} selected</BodyRegular>
                  <ToggleableOutlineButton click={() => renameDesk()} text="Rename desk" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(48, 96)} text="Desk P (48, 96)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(48, 120)} text="Desk P (48, 120)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(82, 123)} text="Desk P (82, 123)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(92, 92)} text="Desk P (92, 92)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(163, 161)} text="Corner desk P (161, 163)" isActive={true}/>

                  <ToggleableOutlineButton click={() => changeDeskSize(96, 48)} text="Desk L (96, 48)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(120, 48)} text="Desk L (120, 48)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(123, 82)} text="Desk L (123, 82)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(98, 82)} text="Desk L (98, 82)" isActive={true}/>
                  <ToggleableOutlineButton click={() => changeDeskSize(161, 163)} text="Corner desk L (163, 161)" isActive={true}/>

                  <div className={'deskControls__buttonRow'}>
                    <ToggleableOutlineButton text={"Rotate <-"} click={() => rotateDesk(-1)} isActive={true} />
                    <ToggleableOutlineButton text={"Rotate ->"} click={() => rotateDesk(1)} isActive={true} />
                  </div>

                  <div className={'deskControls__buttonRow'}>
                    <ToggleableOutlineButton text={"Rotate 22.5 <-"} click={() => rotateDesk(-22.5)} isActive={true} />
                    <ToggleableOutlineButton text={"Rotate 22.5 ->"} click={() => rotateDesk(22.5)} isActive={true} />
                  </div>

                  <ToggleableOutlineButton click={() => deleteDeskClick()} text="Delete desk" isActive={true}/>

                  {renameDeskEnabled && editedDeskName && <Row style={{justifyContent: 'center'}}>
                    <TextField onChange={(val) => setEditedDeskName(val)} value={editedDeskName} />
                    <Icon className={"saveIcon"} icon={IconTypes.Tick} size={"small"} onClick={() => updateDeskName(editedDeskName)} />
                  </Row>}
                </DeskControls>}
                <MapControlsContainer className="tools">
                  <MapControlButton onClick={() => params.zoomIn()}>+</MapControlButton>
                  <MapControlButton onClick={() => params.zoomOut()}>-</MapControlButton>
                  <MapControlButton onClick={() => params.resetTransform()}><BodyVerySmall style={{fontSize: 8}} weight={600}>100%</BodyVerySmall></MapControlButton>
                </MapControlsContainer>
              </ButtonRow>

              <TransformComponent wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}>
                {plan && <FloorPlan src={getFloorPlanUrl(plan.floorPlan)} />}

                {editableDesks.length > 0 && editableDesks.map((desk: EditableDesk, key: number) => (
                  <EditableDeskSpot key={key}
                                    onClick={onEditDeskClick}
                                    selected={lockedDesk?.id === desk.id || selectedDesk?.id === desk.id}
                                    onMouseDown={onDeskLock}
                                    onMouseUp={onDeskRelease}
                                    desk={desk}/>
                ))}

                {editableMeetingRooms.map((meetingRoom: any, key: number) => <EditableMeetingRoomSpot key={key}
                                                                                                      currentDate={moment()}
                                                                                                      meetingRoom={meetingRoom}
                                                                                                      onMouseDown={onMeetingRoomMouseDown}
                                                                                                      onMouseUp={onMeetingRoomMouseRelease}
                                                                                                      mapScale={(mapRef?.current?.state?.scale ?? 1)} />)}

              </TransformComponent>
            </div>
          )}
        </TransformWrapper>

      </FloorMapContainer>
    </>
  )
}

interface Props {
}

const FloorMapContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${Colours.veryLightGrey};
  height: 100vh;
  max-height: 100vh;
`

const ButtonRow = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  padding: 8px 16px;
  width: 100%;
`

const MapControlsContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const DeskControls = styled.div`
  position: absolute;
  left: 16px;
  top: 100px;
  width: 240px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  border: 1px solid black;
  .toggleableOutlineButton {
    margin-bottom: 8px;
    width: 100%;
    button {
      max-width: unset !important;
    }
  }
  .saveIcon {
    background-color: white !important;
    border-radius: 100% !important;
    border: solid 1px ${Colours.green} !important;
    padding: 8px !important;
  }
  .textField {
    margin: 0 8px 0 0;
  }
  .deskControls__buttonRow {
    display: flex;
    justify-content: space-between;
    
    .toggleableOutlineButton {
      margin-right: 0;
      width: 100px;
    }
    .toggleableOutlineButton button {
      min-width: unset !important;
    }
  }
`


const MapControlButton = styled.div`
  border-radius: 50%;
  height: 48px;
  width: 48px;
  background-color: white;
  border: solid 1px ${Colours.mildGrey};
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: ${Colours.mildGrey};
  }
`

const FloorPlan = styled.img`
`
