

function getUrl(floorPlan: string) {
  if (floorPlan.startsWith('s3/')) {
    return `https://team-today-floorplans.s3.eu-west-2.amazonaws.com/${floorPlan}`;
  } else {
    return '/assets/office-layouts/' + floorPlan;
  }
}

export async function getOfficeLayout(officePlan: string): Promise<any> {
  return await fetch(getUrl(officePlan))
    .then(response => response.json());
}
