import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { deleteTag, selectAllTags, selectTagsLoading, updateTag } from "../../../store/ducks/userTags.duck";
import { Tag } from "../../../models/tags.models";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isDepartmentAdmin, isOfficeAdminUser } from "../../../utils/AccessControl";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import Dialog from "../../UI/molecules/Dialog";
import DialogHeader from "../DialogHeader";
import AccessRequired from "../../pages/settings/components/AccessRequired";
import { LoadingOverlay } from "../../UI/molecules/LoadingOverlay";
import TextField from "../../UI/atoms/TextField";
import Icon, { IconTypes } from "../../UI/atoms/icon/Icon";
import { Chip, ChipMode } from "../../UI/atoms/Chip";
import { Column } from "../../UI/atoms/StructuralLayout";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { Accordian } from "../../../animations/AnimationVariants";

interface Props {
  payload?: any;
}

export default function EditTagDialog(props: Props) {
  const dispatch = useDispatch();
  const [newTagName, setNewTagName] = useState('');
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(undefined);
  const loading = useSelector(selectTagsLoading);
  const tags = useSelector(selectAllTags);
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser) || isDepartmentAdmin(currentUser), [currentUser]);

  useEffect(() => {
    if (props && props.payload && props.payload.workgroup) {
      setNewTagName(props.payload.workgroup.label);
      setSelectedTag(props.payload.workgroup);
    } else {
      setSelectedTag(undefined);
      setNewTagName('');
    }
  }, [tags, props]);

  const close = () => {
    cancelTagSelect();
    dispatch(closeDialog())
  };
  const updateTagClick = () => {
    dispatch(updateTag({
      ...selectedTag,
      label: newTagName,
    }))
  };
  const deleteTagClick = () => {
    dispatch(deleteTag(selectedTag?.id));
  }
  const cancelTagSelect = () => {
    setNewTagName('');
    setSelectedTag(undefined);
  }

  const selectTag = (tag: Tag) => {
    if (tag === selectedTag) {
      cancelTagSelect();
    } else {
      setNewTagName(tag.label);
      setSelectedTag(tag);
    }
  };

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="dialog-headers.edit-tags" />
      <AccessRequired teamAdminsRequired={false} departmentAdminRequired={true} companyAdminsRequired={true} officeAdminsRequired={true} visible={!hasAccess} />

      {loading && <LoadingOverlay />}

      {hasAccess && <div style={{marginBottom: 32}}>
        <InputRow style={{marginBottom: 32}}>
          <TextField onChange={setNewTagName} value={newTagName} disabled={!selectedTag}  />
          {selectedTag &&
            <div style={{marginLeft: 8, cursor: 'pointer'}}>
              <Icon icon={IconTypes.Bin} onClick={deleteTagClick} size="mediumlarge" />
            </div>
          }
        </InputRow>

        <TagsContainer isOpen={tags && tags.length > 0}>
          {tags && tags.map((tag: Tag, key: number) => (
            <Chip key={key} label={tag.label}
                  isActive={tag?.id === selectedTag?.id}
                  onClick={() => selectTag(tag)}
                  mode={ChipMode.Edit} />
          ))}
        </TagsContainer>
      </div>}

      <Column>
        <PrimaryButton disabled={!selectedTag} fullWidth={true} style={{marginBottom: 16}} click={updateTagClick} text="Save" />
        <OutlineButton fullWidth={true} click={close} text="Cancel" />
      </Column>
    </Dialog>
  )
}

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .textField {
    margin-bottom: 0;
    flex: 1;
  }
`

const TagsContainer = styled<any>(Accordian)<any>`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-bottom: 24px;
  overflow-y: auto;
  max-height: 350px;
  &>* {
    margin-left: 8px;
    margin-bottom: 8px;
  }
`
