import { useSelector } from "react-redux";
import { selectCurrentUser } from "../store/ducks/auth.duck";
import { selectCalendarDate } from "../store/ducks/dashboard.duck";
import { useMemo } from "react";

export function WithDaysInWorkWeek() {
  const current = useSelector(selectCurrentUser);
  const calendarDate = useSelector(selectCalendarDate);
  const workWeek = useMemo(() => {
    const weekStart = calendarDate.clone().startOf('isoWeek');
    let days = [
      weekStart,
      weekStart.clone().add(1, 'days'),
      weekStart.clone().add(2, 'days'),
      weekStart.clone().add(3, 'days'),
      weekStart.clone().add(4, 'days'),
    ];

    if (current?.companyEntity.sevenDayWhereaboutsWeekEnabled) {
      days.push(weekStart.clone().add(5, 'days'));
      days.push(weekStart.clone().add(6, 'days'));
    }
    return days;
  }, [current, calendarDate])

  return workWeek;
}
