import React, { useMemo, useState } from 'react';
import { BasicUserInfo, Permission } from "../../../../models/user.models";
import styled from "styled-components/macro";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { Center, Row } from '../../../UI/atoms/StructuralLayout';
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { HolidayDaysPill } from "../../../UI/atoms/HolidayDaysPill";
import { HolidayDay } from "../../holidays-v2/models/Holidays.model";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentUserLineReports } from "../../../../store/ducks/auth.duck";
import { HorizontalSpacer } from "../../../UI/atoms/VerticalSpacer";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { isCompanyAdminUser } from "../../../../utils/AccessControl";
import { submitMultipleApprovalsByDateAndType } from "../../../../services/ApprovalRequests";
import { ApprovalState, ApprovalType } from "../../../../models/approval-requests.models";
import { WallPlannerActivity } from "./WallPlannerUserRow";
import { failureNotification, warningNotification } from "../../../../store/ducks/notification.duck";
import { loadTeamHolidays } from "../../../../store/ducks/holidays-v2.duck";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";
import { parseErrorFromException } from "../../../../utils/error-helper";
import {
  UsersHolidayUsageViewWithDataProvider
} from "../../holidays-v2/components/UsersHolidayUsageViewWithDataProvider";
import { CheckLineReport } from "../../../../hooks/management-structure/CheckLineReport";

export enum PopupPosition {
  Left, Right, None,
}

export interface HolidayActivity extends WallPlannerActivity {
  name: string;
  holidayBookingList?: HolidayDay[];
}

export function HolidayInfoAndApprovalPopup(props: Props) {
  const {user, activity, isVisible} = props;

  const holidayBookingList = useMemo(() => activity.holidayBookingList ?? [], [activity]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentUserLineReports = useSelector(selectCurrentUserLineReports);
  const popupPositioning = props.popupPositioning ?? PopupPosition.Left;
  const isLineReport = CheckLineReport(currentUser, user?.userId);
  const usesHolidayBooking = currentUser?.companyEntity.enableHolidayBooking;

  const days = useMemo(() => {
    let count = 0;
    holidayBookingList.forEach((day: HolidayDay) => {
      if (day.am) count += 1;
      if (day.pm) count += 1;
    })
    return count / 2;
  }, [holidayBookingList]);

  const showApprovalPane = useMemo(() => {
    return (currentUser?.companyEntity.holidayApprovalEnabled &&
      (isCompanyAdminUser(currentUser) || !!currentUserLineReports.find((report) => user?.userId === report.userId))) &&
      holidayBookingList.filter((booking: HolidayDay) => booking.state === ApprovalState.pending).length > 0;
  }, [currentUserLineReports, currentUser, user, holidayBookingList]);

  const canUserViewHolidayUsage = useMemo(() => {
    if (currentUser?.role === Permission.CompanyAdmin) {
      return true;
    }

    if (currentUser?.role === Permission.TeamAdmin && currentUser.teamEntity !== null && currentUser.teamEntity.id === user?.teamId) {
      return true;
    }

    return isLineReport;
  }, [currentUser?.role, currentUser?.teamEntity, user?.teamId, isLineReport]);

  const onApproveOptionClicked = async (approvalAction: ApprovalState) => {
    const pendingDays = holidayBookingList.filter((booking: HolidayDay) => booking.state === ApprovalState.pending);

    if (pendingDays.length === 0) {
      dispatch(warningNotification('This holiday contains no pending approvals'));
    } else if (pendingDays.length > 0) {
      if (!user?.userId) {
        dispatch(failureNotification('Could not find that user'));
      } else {
        try {
          setLoading(true)
          await submitMultipleApprovalsByDateAndType(pendingDays.map((day: HolidayDay) => day.date), user?.userId, approvalAction, ApprovalType.holiday);
          dispatch(loadTeamHolidays());
        } catch (e: any) {
          dispatch(failureNotification(parseErrorFromException(e)));
        } finally {
          setLoading(false);
        }
      }
    }
  }

  if (days === 0) {
    return null;
  }

  return (
    <HolidayInfoPopupWrapper className="popup" popupPositioning={popupPositioning}>
      <>
        {user && <Row style={{alignItems: 'center', alignSelf: 'center', marginBottom: 8}}>
          <UserAvatarAndName firstName={user.firstName}
                             lastName={user.lastName}
                             hideName={true}  />
          <BodyRegular style={{marginLeft: 8}}>{`${user.firstName} ${user.lastName}`}</BodyRegular>
        </Row>}

        <BodyRegular style={{textAlign: 'center', marginBottom: 8}}>{`${props.activity.fromDate.format('Do MMMM')} - ${props.activity.toDate.format('Do MMMM')}`}</BodyRegular>
        <HolidayPillWrapper>
          <HolidayDaysPill length={days} />
        </HolidayPillWrapper>

        {usesHolidayBooking && canUserViewHolidayUsage && isVisible && user && <UsersHolidayUsageViewWithDataProvider userId={user.userId} date={props.activity.fromDate} />}

        {showApprovalPane && <>
          <HorizontalSpacer />
          {loading ? <Center>
              <SimpleSpinner size={24} />
            </Center> :
            <ApprovalButtons>
              <OutlineButton text={"button.approve"} size={"small"} click={() => onApproveOptionClicked(ApprovalState.approved)} />
              <OutlineButton text={"button.decline"} size={"small"} click={() => onApproveOptionClicked(ApprovalState.declined)} />
            </ApprovalButtons>}

        </>}
      </>
    </HolidayInfoPopupWrapper>
  )
}

interface Props {
  activity: any;
  user?: BasicUserInfo;
  popupPositioning?: PopupPosition;
  isVisible: boolean;
}

const ApprovalButtons = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
  }
`

const HolidayInfoPopupWrapper = styled.div<any>`
  display: none;
  flex-direction: column;
  max-width: 448px;
  min-width: 300px;
  min-height: 50px;
  padding: 24px;
  position: absolute;
  top: 30px;
  left: ${props => props.popupPositioning === PopupPosition.Left ? 0 : 'auto'};
  right: ${props => props.popupPositioning === PopupPosition.Right ? 0 : 'auto'};
  z-index: 100;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  cursor: default;
  p {
    max-width: 100%;
  }
`

const HolidayPillWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  p {
    max-width: 100% !important;
  }
`
