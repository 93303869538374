import React from 'react';
import Layout from "../UI/organisms/layout/Layout";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import { SecondaryButton } from "../UI/atoms/buttons/SecondaryButton";
import { BorderlessButton } from "../UI/atoms/buttons/BorderlessButton";
import { GoBackButton } from "../UI/atoms/buttons/GoBackButton";
import { SearchButton } from "../UI/organisms/search/SearchButton";
import { WhereaboutsUsage } from "../UI/organisms/reports/WhereaboutsUsage";


export default function DebugPage() {

  return (
    <Layout style={{maxWidth: 768, marginTop: 60}}>

      <SpacingRow>
        <WhereaboutsUsage />
      </SpacingRow>

      <SpacingRow>
        <SearchButton />
      </SpacingRow>

      <SpacingRow>
        <PrimaryButton click={() => {}} text="My button" />
        <PrimaryButton click={() => {}} text="My disabled button" disabled={true} />
      </SpacingRow>

      <SpacingRow>
        <PrimaryButton click={() => {}} text="My large button" size="large" />
        <PrimaryButton click={() => {}} text="My medium button" size="medium" />
        <PrimaryButton click={() => {}} text="My small button" size="small" />
      </SpacingRow>


      <SpacingRow>
        <SecondaryButton click={() => {}} text="My button" />
        <SecondaryButton click={() => {}} text="My disabled button" disabled={true} />
      </SpacingRow>

      <SpacingRow>
        <SecondaryButton click={() => {}} text="My large button" size="large" />
        <SecondaryButton click={() => {}} text="My medium button" size="medium" />
        <SecondaryButton click={() => {}} text="My small button" size="small" />
      </SpacingRow>

      <SpacingRow>
        <BorderlessButton click={() => {}} text="My button" />
        <BorderlessButton click={() => {}} text="My disabled button" disabled={true} />
      </SpacingRow>

      <SpacingRow>
        <BorderlessButton click={() => {}} text="My large button" size="large" />
        <BorderlessButton click={() => {}} text="My medium button" size="medium" />
        <BorderlessButton click={() => {}} text="My small button" size="small" />
      </SpacingRow>

      <SpacingRow>
        <GoBackButton />
      </SpacingRow>

    </Layout>
  );
}

const SpacingRow = styled.div`
  display: flex;
  margin-bottom: 32px;
  position: relative;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`
