import React from 'react';
import { UsersHolidayInfo } from "../models/Holidays.model";
import { BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { ProgressBar } from "../../../UI/atoms/ProgressBar";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

export function UsersHolidayUsageView(props: Props) {
  const {usersHolidayInfo} = props;
  const {t} = useTranslation();

  return (
    <Wrapper style={props?.style ?? {}}>
      <HolidaysUsedRow>
        <DaysUsedContainer>
          <BodyVerySmall>{t('holidays.days-booked')}</BodyVerySmall>
          <HeadlineSmall>{usersHolidayInfo.daysUsed}</HeadlineSmall>
        </DaysUsedContainer>
        <DaysLeftContainer>
          <BodyVerySmall>{t('holidays.days-left')}</BodyVerySmall>
          <HeadlineSmall>{usersHolidayInfo.holidayAllowance - usersHolidayInfo.daysUsed}</HeadlineSmall>
        </DaysLeftContainer>
      </HolidaysUsedRow>

      <ProgressBar style={{marginBottom: 4}} complete={usersHolidayInfo.daysUsed / usersHolidayInfo.holidayAllowance * 100}/>

      <BodyVerySmall style={{textAlign: 'center'}}>{t('holidays.allowance', {number: usersHolidayInfo.holidayAllowance})}</BodyVerySmall>

    </Wrapper>
  )
}

interface Props {
  usersHolidayInfo: UsersHolidayInfo;
  style?: any;
}


const HolidaysUsedRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const DaysLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  * {
    text-align: center;
  }
`
const DaysUsedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  * {
    text-align: center;
  }
`

const Wrapper = styled.div`
  width: 100%;
`
