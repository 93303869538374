import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllOfficeEntities, selectOffices } from "../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { getParameterByName } from "../utils/UrlUtils";
import { selectCurrentUser } from "../store/ducks/auth.duck";
import { selectOffice } from "../store/ducks/officeView.duck";
import { findOfficeInOffices, getOfficeChildren, getRootOfficeById } from "../utils/OfficeHelper";

export function WithDefaultOfficeSelection(isActive: boolean, autoSelectRootOffice?: boolean) {
  const isDone = useRef(false);
  const dispatch = useDispatch();
  const officeIdParam = parseInt(getParameterByName('office', window.location.href) ?? '0');

  const rootOffices = useSelector(selectOffices);
  const offices = useSelector(selectAllOfficeEntities);
  const currentUser = useSelector(selectCurrentUser);

  const findOffice = useCallback((id: number) => {
    return offices.find((office: OfficeEntity) => office.id === id)
  }, [offices]);

  const filterFloorsFor = useCallback((office: OfficeEntity) => {
    return getOfficeChildren(office.id, offices).filter((o: OfficeEntity) => o.floor);
  }, [offices]);

  useEffect(() => {
    if (isDone.current || !offices || !isActive) return;

    const getDefaultOfficeId = () => {
      const officeId = officeIdParam || currentUser?.defaultLocationId;

      if (officeId && findOfficeInOffices(offices, officeId)) {
        return officeId;
      } else {
        return (rootOffices.length > 0 ? rootOffices[0].id : 0);
      }
    }

    const dispatchSelectOfficeRequest = (office: OfficeEntity) => {
      if (autoSelectRootOffice) {
        const rootOffice = getRootOfficeById(offices, office.id)
        dispatch(selectOffice(rootOffice));
      } else {
        dispatch(selectOffice(office));
      }
    }

    const selectDefaultOffice = () => {
      // Fetch from query param or fall back to default user location
      const officeId = getDefaultOfficeId();

      // Using the office id
      if (officeId) {
        const office: OfficeEntity = findOffice(officeId);
        if (office) {
          const officeChildren = getOfficeChildren(office.id, offices);
          if (office.area) {
            dispatchSelectOfficeRequest(office);
          } else if (office.office && officeChildren.length > 0) {
            // Select first floor or fall back to office
            dispatchSelectOfficeRequest(filterFloorsFor(office)[0]);
          } else {
            dispatchSelectOfficeRequest(office);
          }
          isDone.current = true;
        } else {
          // Just wait
          console.log('Waiting to load - cannot find office from officeId', officeId)
        }
      } else {
        // console.log('Waiting to load - cannot find officeId')
      }
    }

    selectDefaultOffice();

  }, [dispatch, offices, officeIdParam, rootOffices, currentUser, findOffice, filterFloorsFor, isActive, autoSelectRootOffice]);
}
