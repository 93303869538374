import React from 'react';
import { CompanyHoliday } from "../../../../../../services/CompanyHolidayService";
import styled from "styled-components/macro";
import { Column } from "../../../../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { EditButton } from "../../../../../UI/molecules/EditButton";
import Colours from "../../../../../UI/atoms/Colours";
import { useDispatch } from "react-redux";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";
import moment from "moment";

export function CompanyHolidayItem(props: Props) {
  const dispatch = useDispatch();

  const onEditClick = () => {
    dispatch(redirectTo(`/manage/company-holidays/view/${props.companyHoliday.id}`));
  }

  return (
    <Row>
      <Column>
        <BodyRegular weight={600}>{props.companyHoliday.label}</BodyRegular>
        <BodyRegular>{moment(props.companyHoliday.date).format('DD MMMM YYYY')}</BodyRegular>
      </Column>
      <EditButton disabled={props.disabled} onClick={onEditClick} hideText={true} size={'mediumlarge'} />
    </Row>
  )
}

interface Props {
  companyHoliday: CompanyHoliday;
  disabled: boolean;
}

const Row = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colours.lightGrey};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:last-child {
    border-bottom: none;
  }
`

