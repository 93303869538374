import { agent } from './agent';

export const requestInviteDetails = (invitationId: string) => agent.requests.get(`/api/invite/accept/${invitationId}`, true);

export function emitRegisterEvent() {
  try {
    const utm = localStorage.get('tt.utm');
    if (utm) {
      agent.requests.post('/ping&t=' + utm + ':register', {}, true);
      localStorage.clear();
    }
  } catch (e: any) {
  }
}
