import styled from "styled-components/macro";
import TextField, { TextFieldProps } from "../atoms/TextField";
import Colours from "../atoms/Colours";


export const DateInputField = styled<any>(TextField)<TextFieldProps>`
  opacity: 1 !important;
  input {
    border: 1px solid ${Colours.darkGrey} !important;
  }
`
