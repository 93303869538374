import React, { useMemo } from 'react';
import { ApprovalState, GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { BodyRegular, BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import moment from "moment";
import styled from "styled-components/macro";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import Colours from "../../../../UI/atoms/Colours";
import { Pill } from "../../../../UI/atoms/Pill";
import { HolidayDaysPill } from "../../../../UI/atoms/HolidayDaysPill";
import { desktop } from "../../../../UI/atoms/MediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { approveRequest, declineRequest } from "../../../../../store/ducks/approvalRequests.duck";
import { useTranslation } from "react-i18next";
import { toAirBnbDate } from "../../../../../utils/DateUtils";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isOfficeAdminUser } from "../../../../../utils/AccessControl";

function getBackgroundColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.blue02;
    case ApprovalState.declined: return Colours.veryLightGrey;
    case ApprovalState.cancelled: return Colours.veryLightGrey;
    default: return Colours.white;
  }
}

function getAvatarColour(state: ApprovalState): 'blue'|'grey'|'darkBlue' {
  switch (state) {
    case ApprovalState.approved: return 'blue';
    case ApprovalState.declined: return 'grey';
    case ApprovalState.cancelled: return 'grey';
    default: return 'darkBlue';
  }
}

function getBorderColour(state: ApprovalState) {
  switch (state) {
    case ApprovalState.approved: return Colours.blue20;
    default: return Colours.mildGrey;
  }
}

export function ApprovalLine(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const currentUser = useSelector(selectCurrentUser);

  const approval = props.groupedApproval;
  const formattedDate = useMemo(() => {
    const from = moment(approval.fromDate);
    const to = moment(approval.toDate);
    return toAirBnbDate(from, to);
  }, [approval]);

  const canUserApprove = useMemo(() => {
    if (isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser)) {
      return true;
    }
    if (currentUser?.id !== approval.userId) {
      return true;
    }
    return false;
  }, [currentUser, approval]);

  const approve = () => {
    dispatch(approveRequest(approval));
  }

  const decline = () => {
    dispatch(declineRequest(approval));
  }

  return (
    <ApprovalLineWrapper state={approval.approvalState}>
      <Row>
        <UserAvatarAndName firstName={approval.firstName}
                           lastName={approval.lastName}
                           colour={getAvatarColour(approval.approvalState)}
                           hideName={true} />

        <Column className={'approvalLine__name'}>
          <BodyRegular weight={600}>{approval.firstName} {approval.lastName}</BodyRegular>
          <BodyRegular weight={400}>{formattedDate}</BodyRegular>
        </Column>
      </Row>

      {approval.approvalState === ApprovalState.pending && <PillWrapper>
        <HolidayDaysPill length={approval.duration} />
      </PillWrapper>}


      <ApprovalsControl>
        {(approval.approvalState === ApprovalState.approved || approval.approvalState === ApprovalState.cancelled) && <>
          {approval.approvalState === ApprovalState.approved && <>
            <Pill colour={Colours.blue} backgroundColour={Colours.blue02}>
              <BodyVerySmall weight={600}>{t('approvals.approved')}</BodyVerySmall>
            </Pill>
            <Pill colour={Colours.blue} style={{marginLeft: 16}} backgroundColour={Colours.blue02}>
              <BodyVerySmall weight={600}>{approval.duration} {t('approvals.days')}</BodyVerySmall>
            </Pill>
          </>}
          {approval.approvalState === ApprovalState.cancelled && <>
            <Pill backgroundColour={Colours.veryLightGrey}>
              <BodyVerySmall weight={600}>{t('approvals.cancelled')}</BodyVerySmall>
            </Pill>
            <Pill style={{marginLeft: 16}} backgroundColour={Colours.veryLightGrey}>
              <BodyVerySmall weight={600}>{approval.duration} {t('approvals.days')}</BodyVerySmall>
            </Pill>
          </>}
        </>}
        {canUserApprove && approval.approvalState === ApprovalState.pending && <>
          <OutlineButton borderColour={Colours.darkGrey}
                         click={() => decline()}
                         text={'button.decline'}
                         size={'small'} />
          <OutlineButton borderColour={Colours.blue}
                         click={() => approve()}
                         text={'button.approve'}
                         size={'small'}
                         style={{marginLeft: 16}} />
        </>}
        {approval.approvalState === ApprovalState.declined && <>
          <Pill colour={Colours.darkGrey} style={{marginLeft: 16}} backgroundColour={Colours.veryLightGrey}>
            <BodyVerySmall weight={600}>{t('approvals.declined')}</BodyVerySmall>
          </Pill>
        </>}
      </ApprovalsControl>
    </ApprovalLineWrapper>
  )
}

interface Props {
  groupedApproval: GroupedApprovalRequest;
}

const ApprovalLineWrapper = styled.div<any>`
  margin-bottom: 16px;
  border: 1px solid ${Colours.mildGrey};
  padding: 20px 24px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
  background-color: ${props => getBackgroundColour(props.state)};
  border: 1px solid ${props => getBorderColour(props.state)};
  
  
  .approvalLine__name {
    margin: 0 50px 0 20px;
  }
  
  @media (${desktop}) {
    align-items: center;
    flex-direction: row;
  }
`

const ApprovalsControl = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 8px;
`

const PillWrapper = styled.div`
  align-self: flex-start;
  @media (${desktop}) {
    align-self: center;
  }
`
