import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import Select from "react-select";
import Colours from "../atoms/Colours";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import makeAnimated from "react-select/animated";
import { BodySmall } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { findAllRootOffices, findOfficeInOffices, getFullOfficeTitle, sortOffice } from "../../../utils/OfficeHelper";

const styles = {
  option: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    backgroundColor: 'white',
    color: Colours.black,
    cursor: 'pointer',
    '&:hover': {
      color: Colours.black,
      backgroundColor: Colours.blue10,
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
    fontSize: '18px',
  }),
  menu: (css: any) => ({
    ...css,
    width: "max-content",
    minWidth: "250px",
    zIndex: 100,
  }),
  placeholder: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: 'hsl(0, 0%, 80%)',
  }),
  noOptionsMessage: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  input: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: Colours.black,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Colours.darkGrey,
      color: 'white',
    },
  }),
};

interface OfficeDropDownItem extends OfficeEntity {
  value: number;
  label: string;
}

const animatedComponents = makeAnimated();

export function OfficeMultiSelectDropDownList(props: Props) {
  const {onSelect, selectedOfficeIds} = props;
  const {t} = useTranslation();
  const allOffices = useSelector(selectAllOfficeEntities);
  const [selectedOffices, setSelectedOffices] = useState<OfficeEntity[]>([]);

  const rootOffices = useMemo(() => {
    return findAllRootOffices(allOffices);
  }, [allOffices])

  const dropDownOffice: OfficeDropDownItem[] = useMemo(() => {
    return rootOffices.map((o: OfficeEntity) => {
      return {...o, label: getFullOfficeTitle(o, rootOffices), value: o.id}
    }).sort(sortOffice);
  }, [rootOffices]);

  useEffect(() => {
    if (selectedOfficeIds && selectedOfficeIds.length > 0) {
      const offices = selectedOfficeIds
        .map(id => findOfficeInOffices(rootOffices, id))
        .filter(office => !!office) as OfficeEntity[];
      setSelectedOffices(offices);
    }
  }, [rootOffices, selectedOfficeIds]);

  const onItemSelected = (item: any) => {
    setSelectedOffices(item);
    onSelect(item);
  }

  return (
    <>
      <BodySmall className="textField__label" weight={600}>{t(props.label)}</BodySmall>
      <Select
        options={dropDownOffice}
        onChange={onItemSelected}
        value={selectedOffices}
        styles={styles}
        closeMenuOnSelect={false}
        components={{ ...animatedComponents, ClearIndicator:() => null, IndicatorSeparator:() => null }}
        isMulti={true}
        isDisabled={props.disabled}
      />
    </>
  )
}

interface Props {
  onSelect: (office: OfficeEntity[]) => any;
  selectedOfficeIds?: number[];
  disabled: boolean;
  label: string;
}

