import React from 'react';
import { Accordian } from "../../../../../animations/AnimationVariants";
import { SimpleUserWithLocation } from "../../../../../models/movements.models";
import { OfficeListUserRow } from "./OfficeListUserRow";
import { Center } from "../../../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { useTranslation } from "react-i18next";

export function ExpandableUserList(props: Props) {
  const {isLoading, isVisible, users, office} = props;
  const {t} = useTranslation();

  return (
    <>
      {isVisible && <Accordian isOpen={isVisible}>
        {users.length > 0 ? <>
          {users.map((usersInArea: SimpleUserWithLocation, key: number) => (
            <OfficeListUserRow key={key} containerOffice={office}
                               firstName={usersInArea.firstName}
                               lastName={usersInArea.lastName}
                               amOfficeId={usersInArea.officeId}
                               pmOfficeId={usersInArea.pmOfficeId}
                               fireWarden={usersInArea.fireWarden}
                               firstAider={usersInArea.firstAider}
                               mentalHealthAider={usersInArea.mentalHealthAider}
                               keyHolder={usersInArea.keyHolder}  />
          ))}
        </> : <>
          <Center>
            {isLoading ? <SimpleSpinner size={24} /> : <>
              <BodyRegular weight={600} style={{marginTop: 16, marginBottom: 24}}>{t('company-view-page.no-one-booked-in-area')}</BodyRegular>
            </>}
          </Center>
        </>}
      </Accordian>}
    </>
  )
}

interface Props {
  isVisible: boolean;
  isLoading: boolean;
  users: SimpleUserWithLocation[];
  office: OfficeEntity;
}
