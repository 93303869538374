import React from 'react';
import SettingsSectionContentCard from "../pages/user-management-page/components/SettingsSectionContentCard";
import { HorizontalSpacer } from "../../../UI/atoms/VerticalSpacer";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";

export function CompanyWideHolidaySetting(props: Props) {
  return (
    <>
      <ContentCardWrapper>
        <SettingsSectionContentCard title="Add a company wide or national holiday"
                                    icon={IconTypes.Calendar} linkTo="/manage/company-holidays"
                                    bullets={['Stop users booking into the office on a chosen day', 'Adds a note to users calendars']} />
      </ContentCardWrapper>

      <HorizontalSpacer style={{marginBottom: 32, marginTop: 24}} />
    </>
  )
}

interface Props {
}

const ContentCardWrapper = styled.div`
  margin-top: 16px;
  a {
    width: 100%;
  }
`
