import React, { useMemo, useState } from 'react';
import styled from "styled-components/macro";
import { Moment } from "moment";
import { User } from "../../../../../models/user.models";
import { Period } from "../../../../../models/movements.models";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { DeskSpotOnMap } from "../../data/OfficeMap.model";
import Icon, { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { AssignedGroupsPopup } from "./AssignedGroupsPopup";
import { BookedDeskHoverPopup } from "./BookedDeskHoverPopup";
import { isVisitorUser } from "../../../../../services/UserService";
import { DeskReservations } from "../../../../../services/DeskBookingService";

interface Props {
  currentDate: Moment;
  deskSpot: DeskSpotOnMap;
  activeDeskSpot: any;
  onSelect: any;
  currentUser?: User;
  activePeriod: Period;
  selectedDesksForManageDesks: number[];
  isManageDesksEnabled?: boolean;
}

function isDeskAssignedToSingleUser(desk: DeskSpotOnMap) {
  return ((desk?.users ?? []).length === 1);
}

function isDeskAssignedToMultipleUsers(desk: DeskSpotOnMap) {
  return ((desk?.users ?? []).length > 1);
}

function countNumberOfGroupsAssignedTo(desk: DeskSpotOnMap) {
  return (desk?.teamIds ?? []).length + (desk?.workgroupIds ?? []).length
}

function isDeskBooked(desk: DeskSpotOnMap) {
  return desk?.amReservation.id || desk?.pmReservation.id
}

function isDeskBookedByCurrentUser(desk: DeskSpotOnMap, currentUser?: User) {
  if (!currentUser || !desk || !desk.amReservation) return false;
  return (desk.amReservation.id || desk.pmReservation.id) && (desk.amReservation.userId === currentUser.id || desk.pmReservation.userId === currentUser.id);
}

export function OfficeDeskSpotOverlay(props: Props) {
  const {deskSpot, activeDeskSpot, currentUser, isManageDesksEnabled, onSelect, selectedDesksForManageDesks} = props;
  const isDeskSelected = deskSpot === activeDeskSpot;
  const isSelectedForManageDesks = useMemo(() => !!selectedDesksForManageDesks.find(id => id === deskSpot.id), [deskSpot, selectedDesksForManageDesks]);
  const [deskHover, setDeskHover] = useState(false);
  const isDeskAvailable = useMemo(() => {
    if (isManageDesksEnabled) return true;
    if (isDeskBooked(deskSpot)) {
      return isDeskBookedByCurrentUser(deskSpot, currentUser);
    }
    if (!deskSpot.active) return false;
    return deskSpot.canUserBook === undefined || deskSpot.canUserBook;
  }, [currentUser, deskSpot, isManageDesksEnabled])

  const onClick = (e: any) => {
    e.stopPropagation();
    onSelect(deskSpot);
  }

  return (
    <>
      <DeskOverlay id={`desk-${deskSpot.deskId}`} shadedOut={(!isSelectedForManageDesks && isManageDesksEnabled) || !isDeskAvailable}
                   x={deskSpot.x} y={deskSpot.y}
                   isVisible={isDeskSelected || deskSpot.debug || isSelectedForManageDesks}
                   width={deskSpot.landscape ? (deskSpot?.height ?? 98) : (deskSpot?.width ?? 50)}
                   height={deskSpot.landscape ? (deskSpot?.width ?? 50) : (deskSpot?.height ?? 98)}
                   rotate={deskSpot?.rotation ?? deskSpot?.rotate ?? 0}
                   onMouseOver={() => setDeskHover(true)}
                   onTouchStart={() => setDeskHover(true)}
                   onMouseLeave={() => setDeskHover(false)}
                   onTouchEnd={() => setDeskHover(false)}
                   onTouchCancel={() => setDeskHover(false)}
                   onClick={onClick}>

        <DeskContents {...props} />

      </DeskOverlay>

      {(deskHover) && <>
        {isDeskBooked(deskSpot) ? <BookedDeskHoverPopup deskSpot={deskSpot} x={deskSpot.x + (deskSpot?.width ?? 10) + 3} y={deskSpot.y + 5} /> :
          <AssignedGroupsPopup deskSpot={deskSpot}
                               x={deskSpot.x + (deskSpot.landscape ? (deskSpot?.height ?? 98) : (deskSpot?.width ?? 50)) + 10}
                               y={deskSpot.y} />}
      </>}
    </>
  )
}

function DeskContents(props: Props) {
  const {deskSpot, activePeriod} = props;
  const assignedToSingleUser = isDeskAssignedToSingleUser(deskSpot);
  const assignedToMultipleUsers = isDeskAssignedToMultipleUsers(deskSpot);
  const numberOfGroupsAssignedTo = countNumberOfGroupsAssignedTo(deskSpot);

  const showDeskBookedAvatar = ((activePeriod === Period.AllDay && (deskSpot.isAmBooked || deskSpot.isPmBooked)) ||
      (activePeriod === Period.AM && (deskSpot.isAmBooked)) ||
      (activePeriod === Period.PM && (deskSpot.isPmBooked)));

  if (showDeskBookedAvatar) {
    return <DeskBookedContent {...props} />
  } else if (!deskSpot.active) {
    return <DeskInactiveContent {...props} />;
  } else if (assignedToMultipleUsers || numberOfGroupsAssignedTo > 0) {
    return <DeskAssignedToMultipleGroups {...props} />
  } else if (assignedToSingleUser) {
    return <DeskAssignedToSingleUserContent {...props} />
  } else if (deskSpot.label) {
    return <DeskLabelText weight={600}>{deskSpot.label}</DeskLabelText>
  }

  return null;
}

function DeskAssignedToSingleUserContent(props: Props) {
  const {deskSpot} = props;

  const assignedUser = (deskSpot?.users ?? [])[0];
  if (assignedUser) {
    return <>
      <UserAvatarAndName firstName={assignedUser.firstname}
                         lastName={assignedUser?.lastname}
                         colour={'transparent'}
                         hideName={true} />
    </>
  } else {
    return null;
  }
}

function DeskInactiveContent(props: Props) {
  const {deskSpot} = props;
  return <>
    {deskSpot.label && <DeskLabelText weight={600}>{deskSpot.label}</DeskLabelText>}
    <AssignedContainer>
      <Icon icon={IconTypes.Padlock} color={'grey'} />
    </AssignedContainer>
  </>
}

function DeskAssignedToMultipleGroups(props: Props) {
  const {deskSpot} = props;

  const assignedToMultipleUsers = isDeskAssignedToMultipleUsers(deskSpot);
  const numberOfGroupsAssignedTo = countNumberOfGroupsAssignedTo(deskSpot);

  return <>
    {deskSpot.label && !assignedToMultipleUsers && <DeskLabelText weight={600}>{deskSpot.label}</DeskLabelText>}
    {numberOfGroupsAssignedTo > 0 && <AssignedContainer>
      {numberOfGroupsAssignedTo > 0 && <IsAssignedDot />}
      {numberOfGroupsAssignedTo > 1 && <IsAssignedDot />}
      {numberOfGroupsAssignedTo > 2 && <IsAssignedDot />}
      {numberOfGroupsAssignedTo > 3 && <IsAssignedDot />}
      {numberOfGroupsAssignedTo > 4 && <IsAssignedDot />}
    </AssignedContainer>}
  </>
}

function DeskBookedContent(props: Props) {
  const {deskSpot} = props;
  const bookedAvatars = useMemo(() => {
    if (deskSpot.amReservation && deskSpot.pmReservation && deskSpot.amReservation.userId === deskSpot.pmReservation.userId) {
      return [deskSpot.amReservation];
    } else if (!deskSpot.amReservation && !deskSpot.pmReservation) {
      return [];
    } else {
      return [deskSpot.amReservation, deskSpot.pmReservation];
    }
  }, [deskSpot]);

  return <>
    {bookedAvatars.map((reservations: DeskReservations, key: number) => (
      <React.Fragment key={key}>
        {(!!reservations && !!reservations.id) ? <>
          <UserAvatarAndName firstName={reservations?.firstName ?? ''}
                             lastName={reservations?.lastName ?? ''}
                             colour={props.currentUser?.id === reservations.userId ? 'darkBlue' : isVisitorUser(reservations.userId) ? 'visitor' : 'grey'}
                             hideName={true} />
        </> : <>
          <EmptyAvatar />
        </>}
      </React.Fragment>
    ))}
  </>
}

const DeskLabelText = styled<any>(BodyVerySmall)`
  z-index: 10;
`

const DeskOverlay = styled.div<any>`
  width: ${props => `${props.width}px`};
  min-height: ${props => `${props.height}px`};
  border-radius: 3px;
  background-color: ${props => props.shadedOut ? Colours.opaqueMildGrey : Colours.transparent};
  z-index: ${props => props.shadedOut ? 1 : 100};
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  border: ${props => props.isVisible ? `3px solid ${Colours.green}` : `3px solid transparent`};
  transform: rotate(${props => props.rotate}deg);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: border-color .1s ease-in-out;
  padding: 2px;
  text-align: center;
  &:hover {
    border: 3px solid ${Colours.green};
  }

  .userAvatarAndName p {
    color: white;
  }
  p {
    color: ${props => props.shadedOut ? Colours.darkGrey : Colours.black};
  }
`

const EmptyAvatar = styled.div`
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 100%;
  border: 3px dashed ${Colours.darkGrey};
`

const AssignedContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 4px;
`

const IsAssignedDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: ${Colours.darkGrey};
  margin: 0 1px;
`
