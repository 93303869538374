import { useEffect, useState } from "react";
import { BasicUserInfo, User } from "../models/user.models";
import { getUsersLineReports } from "../services/CompanyHierarchyService";

export function WithUsersLineReports(user?: User): any {
  const [loading, setLoading] = useState(false);
  const [lineReports, setLineReports] = useState<BasicUserInfo[]>([]);

  useEffect(() => {
    if (!!user && user.lineManagementEnabled) {
      setLoading(true);
      getUsersLineReports(user).then(setLineReports).finally(() => setLoading(false));
    }
  }, [user]);

  return {
    loading: loading,
    lineReports: lineReports,
  };
}
