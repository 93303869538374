import React from 'react';
import { BodyXXSmall } from "../fonts/Body";
import Colours from "../Colours";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { redirectTo } from "../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: any;
  url?: string;
  text?: string;
  iconColor?: string;
  backgroundHoverColour?: string;
  iconHoverColor?: string;
  iconHoverBackgroundColor?: string;
}

export function GoBackButton(props: Props) {
  const text = props.text ?? 'Back';
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const iconColor = props?.iconColor ?? Colours.darkGrey;
  const backgroundHoverColour = props?.backgroundHoverColour ?? Colours.veryLightGrey;
  const iconHoverBackgroundColor = props?.iconHoverBackgroundColor ?? Colours.white;
  const iconHoverColor = props?.iconHoverColor ?? Colours.blue;
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (props.url) {
      dispatch(redirectTo(props.url));
    } else {
      window.history.back();
    }
  }
  return (
    <GoBackButtonContainer className="goBackButton"
                           onClick={() => onClick()}
                           data-test="goBackBtn"
                           backgroundHoverColour={backgroundHoverColour}
                           iconHoverBackgroundColor={iconHoverBackgroundColor}
                           iconColor={iconColor}
                           iconHoverColor={iconHoverColor}>
      <GoBackButtonIcon className={`icon-Previous`.trim()} iconColor={iconColor} />
      <BodyXXSmall weight={600} colour={Colours.blue}>{t(text)}</BodyXXSmall>
    </GoBackButtonContainer>
  );
}

export const GoBackButtonContainer = styled.div<any>`
  padding: 0 12px 0 0;
  display: inline-flex !important;
  cursor: pointer;
  align-self: flex-start;
  transition: background-color .25s;
  margin-bottom: 24px;
  align-items: center;
  border-radius: 64px;
  p {
    color: ${props => props.iconColor};
  }
  &:hover {
    background-color: ${props => props.backgroundHoverColour};
    p {
      color: ${props => props.iconHoverColor};
    }
    i {
      background-color: ${props => props.iconHoverBackgroundColor};
      color: ${props => props.iconHoverColor};
      border-color: ${props => props.iconHoverColor};
    }
  }
`

export const GoBackButtonIcon = styled.i<any>`
  margin-right: 12px;
  border: solid 1px ${props => props.iconColor};
  color: ${props => props.iconColor};
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
  font-weight: 600;
  transition: padding .1s ease-in-out, color .1s ease-in-out, border .1s ease-in-out;
`
