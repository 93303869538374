import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsSectionContentCard from "./components/SettingsSectionContentCard";
import styled from "styled-components";
import { isInTeams } from "../../../../../utils/TeamsUtils";
import { app } from "@microsoft/teams-js";
import { useLocation } from "react-router";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { PageTitle } from "../../../../UI/atoms/PageTitle";

export default function SettingsPage() {
  const user = useSelector(selectCurrentUser);
  const company = user?.companyEntity;
  const location = useLocation();
  const dispatch = useDispatch();


  useEffect(() => {
    if (isInTeams()) {
      app.getContext().then((context: app.Context) => {
        if (context.page.subPageId) {
          // setCtx(JSON.stringify(context.page.subPageId))
          const parts = context.page.subPageId.split(':');
          if (parts.length >= 2 && parts[0] === 'user' && !location.pathname.includes(parts[1])) {
            window.location.href = `/manage/user/${parts[1]}`;
          } else if (parts[0] === 'personal') {
            window.location.href = `/manage/calendar`;
          } else if (parts.length >= 2 && parts[0] === 'team' && !location.pathname.includes(parts[1])) {
            window.location.href = `/manage/team/${parts[1]}`;
          }
        }
      });
    }
  }, [location, dispatch]);

  return (
    <SettingsPageWrapper>
      <PageTitle text={"settings-page.title"} style={{marginBottom: 24}} />
      <div className="manage__sections">

        <SettingsSection>

          <SettingsSectionContentCard title="settings-page.manage-users" icon={IconTypes.Profile} linkTo="/manage/users"
                                      bullets={['settings-page.manage-users-1', 'settings-page.manage-users-2', 'settings-page.manage-users-3']}/>

          <SettingsSectionContentCard title="settings-page.manage-teams" icon={IconTypes.Users} linkTo="/manage/teams-and-users"
                                      bullets={['settings-page.manage-teams-1', 'settings-page.manage-teams-2']}/>

          <SettingsSectionContentCard title="settings-page.office" icon={IconTypes.Chair} linkTo="/manage/hot-desking"
                                      bullets={['settings-page.office-1', 'settings-page.office-2', 'settings-page.office-3']}/>

          <SettingsSectionContentCard title="settings-page.whereabouts" icon={IconTypes.Compass} linkTo="/manage/whereabouts"
                                      bullets={['settings-page.whereabouts-1', 'settings-page.whereabouts-2']}/>

          <SettingsSectionContentCard title="settings-page.holiday"
                                      icon={IconTypes.Holiday}
                                      linkTo="/manage/holidays"
                                      bullets={['settings-page.holiday-1', 'settings-page.holiday-2', 'settings-page.holiday-3']}/>

          <SettingsSectionContentCard title="settings-page.meeting-rooms" icon={IconTypes.Message} linkTo="/manage/meeting-rooms"
                                      bullets={['settings-page.meeting-rooms-1', 'settings-page.meeting-rooms-2']}/>

          <SettingsSectionContentCard dataTest="globalSettingsSection"
                                      title="settings-page.company"
                                      icon={IconTypes.Settings}
                                      linkTo={`/manage/company/${company?.id}`}
                                      bullets={[
                                        'settings-page.company-1',
                                        'settings-page.company-2',
                                        'settings-page.company-3'
                                      ]}/>

          <SettingsSectionContentCard title="settings-page.reports"
                                      icon={IconTypes.Reporting}
                                      linkTo="/manage/reporting"
                                      bullets={['settings-page.report-1', 'settings-page.report-2', 'settings-page.report-3']}/>

          <SettingsSectionContentCard title="settings-page.personal" icon={IconTypes.Calendar} linkTo="/manage/calendar"
                                      bullets={[
                                        'settings-page.personal-pref-1',
                                        'settings-page.personal-pref-2',
                                        'settings-page.personal-pref-3']}/>

          {!isInTeams() && <SettingsSectionContentCard title="settings-page.billing" icon={IconTypes.Billing}
                                                       linkTo="/manage/payments" bullets={['settings-page.billing-1', 'settings-page.billing-2']}/>}

        </SettingsSection>

      </div>
    </SettingsPageWrapper>
  );
}

const SettingsSection = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
`

const SettingsPageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  @media (${tablet}) {
    padding: 24px 32px;
  }
`
