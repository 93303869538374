import { CreateOfficeRequest, OfficeEntity, OfficeType } from "../services/AdvanceHotDeskingService";
import { dedup, naturalComparator } from "./ArrayUtils";
import { CapacityResponse } from "../services/OfficeCapacityService";
import { SimpleUserWithLocation } from "../models/movements.models";
import { Desk } from "../services/DeskBookingService";

export function getRootOfficeById(allOffices: OfficeEntity[], officeId?: number): OfficeEntity | undefined {
  if (!officeId) return;
  const office = findOfficeInOffices(allOffices, officeId);
  if (office) {
    return getRootOffice(allOffices, office);
  }
}

export function findOfficeInOffices(allOffices: OfficeEntity[], officeId: number): OfficeEntity | undefined {
  return allOffices.find(o => o.id === officeId)
}

export function findAllDirectAreasOfOffice(allOffices: OfficeEntity[], parentOfficeId: number): OfficeEntity[] {
  return allOffices.filter(o => o.parentId === parentOfficeId);
}

export function getOfficeChildren(officeId?: number, offices?: OfficeEntity[]): OfficeEntity[] {
  if (!officeId || !offices) return [];
  return offices.filter((o) => o.parentId === officeId)
}

export function getRootOffice(allOffices: OfficeEntity[], office: OfficeEntity): OfficeEntity {
  if (office.parentId) {
    const parentOffice = findOfficeInOffices(allOffices, office.parentId);
    if (parentOffice) {
      return getRootOffice(allOffices, parentOffice);
    }
  }
  return office;
}

export function getFullOfficeHierarchy(allOffices: OfficeEntity[], path: OfficeEntity[], office?: OfficeEntity): OfficeEntity[] {
  if (!office) {
    return [];
  }
  if (office.parentId) {
    const parentOffice = findOfficeInOffices(allOffices, office.parentId);
    if (parentOffice) {
      return getFullOfficeHierarchy(allOffices, [office, ...path], parentOffice);
    }
  }
  return [office, ...path]
}

export function getFullOfficeTitleById(officeId: number, allOffices: OfficeEntity[], seperator?: string) {
  if (!officeId) return '';
  const office = findOfficeInOffices(allOffices, officeId);
  if (!office) return '';
  return getFullOfficeHierarchy(allOffices, [], office).map(o => o.label).join(seperator ?? ' - ')
}

export function getFullOfficeTitle(office: OfficeEntity, allOffices: OfficeEntity[], seperator?: string) {
  if (!office) return '';
  return getFullOfficeHierarchy(allOffices, [], office).map(o => o.label).join(seperator ?? ' - ')
}

export function groupUsersAndOffices(allOffices: OfficeEntity[], allCapacities: CapacityResponse[], users: SimpleUserWithLocation[], office: OfficeEntity) {
  if (!office) {
    return [];
  }

  const getCapacity = (office: OfficeEntity) => {
    return allCapacities.find(c => c.officeId === office.id);
  }

  const getUsersInOffice = (officeId: number) => {
    const usersInOffice = [
      ...users.filter((user) => user.officeId === officeId),
      ...users.filter((user) => user.pmOfficeId === officeId)
    ];
    return dedup(usersInOffice, (a: SimpleUserWithLocation, b: SimpleUserWithLocation) => a.userId === b.userId)
  }

  const officeChildren = getOfficeChildren(office.id, allOffices);
  const officeEntitiesToDisplay = officeChildren.length > 0 ? officeChildren : [office];
  return officeEntitiesToDisplay.map((officeEntity: OfficeEntity) => {
    return {
      users: getUsersInOffice(officeEntity.id),
      office: officeEntity,
      officeLabel: getFullOfficeTitle(officeEntity, allOffices),
      capacity: getCapacity(officeEntity),
    }
  })
}

export function calculateAmSpacesAvailable(capacity?: CapacityResponse): number {
  const spaces = (capacity?.capacity ?? 0) - (capacity?.amUsed ?? 0);
  return spaces < 1 ? 0 : spaces;
}

export function calculatePmSpacesAvailable(capacity?: CapacityResponse): number {
  const spaces = (capacity?.capacity ?? 0) - (capacity?.pmUsed ?? 0);
  return spaces < 1 ? 0 : spaces;
}

export function sortOffice(a: OfficeEntity, b: OfficeEntity) {
  if (a.order === b.order) {
    return naturalComparator(a, b, 'label');
  }
  return a.order > b.order ? 1 : -1;
}

export function officeToCreateOfficeRequest(office: OfficeEntity): CreateOfficeRequest {
  return {
    id: office.id,
    name: office.label,
    postCode: office.postCode,
    capacity: office.capacity,
    parentId: office.parentId,
    floors: [],
    officeType: (office.area ? OfficeType.AREA : office.floor ? OfficeType.FLOOR : OfficeType.OFFICE),
    capacityEnabled: office.capacity > 0,
    carParkCapacity: office.carParkCapacity,
    officeBookingWindowWeeks: office.officeBookingWindowWeeks,
  }
}

export function getOfficeType(office: OfficeEntity) {
  if (office.office) return
}


export function findDeskInDesks(desks: Desk[], deskId: number): Desk | undefined {
  return desks.find(d => d.id === deskId);
}

export function findAllRootOffices(offices: OfficeEntity[]): OfficeEntity[] {
  return offices.filter(o => o.office);
}

export function doesRootOfficeHaveCarParkingPlan(offices: OfficeEntity[], office?: OfficeEntity): boolean {
  if (!office) {
    return false;
  }
  const root = getRootOffice(offices, office);
  return root && root.hasCarParkPlan;
}
