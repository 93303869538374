import React from "react";
import Colours from "../atoms/Colours";
import { BodySmall } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";

interface DropDownListProps {
  onSelect: any;
  selectedId?: any;
  className?: string;
  label?: string;
  disabled?: boolean;
  items: {id: any, name: string, disabled?: boolean}[];
  placeholder: string;
  fullWidth?: boolean;
}

export function GenericDropDownList(props: DropDownListProps) {
  const {selectedId, items, label, disabled, className, fullWidth, placeholder, onSelect} = props;
  const classNames = ['teamDropDownList']
  const {t} = useTranslation();
  if (className) classNames.push(className);
  if (fullWidth) classNames.push('teamDropDownList--fullWidth');
  return (
    <>
      {!!label && <BodySmall colour={Colours.darkGrey} className="teamDropDownList__label">{t(label)}</BodySmall>}
      <select disabled={disabled} className={classNames.join(' ')} name="Team" value={selectedId || 0} onChange={(e) => onSelect(e.target.value)}>
        <option disabled={true} value={0}> -- {t(placeholder)} --</option>
        {items.map((item: any, k) => <option key={k} disabled={!!item.disabled} value={item.id}>{`${item.name}${!!item.disabled ? ' (Not active)' : ''}`}</option>)}
      </select>
    </>
  )
}
