import { useEffect, useRef, useState } from "react";
import { CapacityResponse, getAllOfficeCapacities } from "../services/OfficeCapacityService";
import { Moment } from "moment";

export function WithSeatsAvailableInAllOffices(activeDate: Moment) {
  const hasRequested = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allCapacities, setAllCapacities] = useState<CapacityResponse[]>([]);

  useEffect(() => {
    if (!hasRequested.current && activeDate) {
      hasRequested.current = true;
      setIsLoading(true);
      getAllOfficeCapacities(activeDate)
        .then((resp) => setAllCapacities(resp))
        .catch(() => setAllCapacities([]))
        .finally(() => setIsLoading(false))
    }
  }, [activeDate]);

  return {
    isLoading: isLoading,
    allCapacities: allCapacities,
  };
}
