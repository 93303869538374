import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import { Moment } from "moment";
import { selectedOffice, selectOfficeUsers } from "../../../../../store/ducks/officeView.duck";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { User } from "../../../../../models/user.models";
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import { Period } from "../../../../../models/movements.models";
import { BookingPopup } from "./BookingPopup";
import { warningNotification } from "../../../../../store/ducks/notification.duck";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { findOfficeInOffices } from "../../../../../utils/OfficeHelper";
import { WithOfficeCapacity } from "../../../../../hooks/WithOfficeCapacity";
import { CapacityResponse } from "../../../../../services/OfficeCapacityService";
import { useTranslation } from "react-i18next";

export interface AreaSpot {
  x: number;
  y: number;
  landscape: boolean;
  id: number;
  label: string;
  officeId: number;

  debug?: boolean;

  height: number;
  width: number;
  rotate?: number;
}

interface Props {
  currentUser?: User;
  currentDate: Moment;
  areaSpot: AreaSpot;
  activeSpot: any;
  onSelect: any;
  activePeriod: Period;
  allOffices: OfficeEntity[];
}

export function OfficeAreaSpotOverlay(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const {areaSpot, activeSpot, onSelect, currentUser, activePeriod, allOffices} = props;
  const isSelected = useMemo(() => areaSpot === activeSpot, [areaSpot, activeSpot]);
  const officeWithUsers = useSelector(selectOfficeUsers)
  const areaOfficeEntity = findOfficeInOffices(props.allOffices, props.areaSpot.officeId);
  const activeOffice = useSelector(selectedOffice);

  const officeCapacity = WithOfficeCapacity(areaOfficeEntity, props.currentDate);

  const usersInOffice = useMemo(() => officeWithUsers.find(o => o.office?.id === activeOffice?.id), [activeOffice, officeWithUsers]);
  const areaLabel = useMemo(() => findOfficeInOffices(allOffices, areaSpot.officeId)?.label ?? 'Not found', [areaSpot, allOffices]);
  const isAreaActive = useMemo(() => findOfficeInOffices(allOffices, areaSpot.officeId)?.active, [allOffices, areaSpot]);

  const usersInArea = useMemo(() => {
    const areaOfficeId = areaSpot.officeId;
    const usersWithLocation = usersInOffice?.users ?? []
    return usersWithLocation.filter(u => (activePeriod === Period.AllDay && (u.officeId === areaOfficeId || u.pmOfficeId === areaOfficeId)) ||
        (activePeriod === Period.AM && u.officeId === areaOfficeId) ||
        (activePeriod === Period.PM && u.pmOfficeId === areaOfficeId));
  }, [activePeriod, usersInOffice, areaSpot]);

  const spacesAvailable = useMemo(() => {
    if (!officeCapacity || !officeCapacity || !officeCapacity.capacity) return '';
    const capacity = officeCapacity.capacity as CapacityResponse;

    const amSpaces = capacity.capacity - capacity.amUsed;
    const pmSpaces = capacity.capacity - capacity.pmUsed;

    if (activePeriod === Period.AllDay) {
      return Math.min(amSpaces, pmSpaces);
    } else if (activePeriod === Period.AM) {
      return amSpaces;
    } else if (activePeriod === Period.PM) {
      return pmSpaces;
    } else {
      return ''
    }
  }, [officeCapacity, activePeriod])


  const onClick = (e: any) => {
    if (isAreaActive) {
      e.stopPropagation();
      onSelect(areaSpot);
    } else {
      dispatch(warningNotification('That area is not active'));
    }
  }


  return (
    <>
      <AreaOverlay id={`area-${areaSpot.officeId}`}
                   x={areaSpot.x} y={areaSpot.y}
                   height={areaSpot.height} width={areaSpot.width}
                   disabled={!isAreaActive}
                   rotate={areaSpot.rotate ?? 0}
                   isVisible={isSelected || areaSpot.debug} onClick={onClick}
                   isLandscape={areaSpot.landscape}>

        <BookingPopup label={areaLabel}
                      isSelected={isSelected}
                      onSelect={onSelect}
                      officeId={areaSpot.officeId}
                      date={props.currentDate} />

        <AreaLabels title={areaLabel}>
          <Row>
            {isAreaActive ? <SpacesAvailable className="areaOverlay__spacesAvailable">
              {officeCapacity && officeCapacity.capacity && <BodyRegular colour={Colours.white} weight={600}>{spacesAvailable}</BodyRegular>}
            </SpacesAvailable> :
              <div style={{width: 24}}/>
            }
            <Row>
              <Column>
                <BodyRegular className="areaOverlay__label" weight={600}>{isAreaActive ? t('general.spaces-available') : t('company-view-page.area-unavailable')}</BodyRegular>
                <BodyRegular className="areaOverlay__label">{areaLabel}</BodyRegular>
              </Column>
            </Row>
          </Row>
        </AreaLabels>
        <UsersInAreaList>
          {usersInArea.map((user, key) => (
            <UserAvatarAndName key={key} hideName={true} firstName={user.firstName} lastName={user.lastName} colour={user.userId === currentUser?.id ? "blue" : "none"} />
          ))}
        </UsersInAreaList>
      </AreaOverlay>
    </>
  )
}

const AreaOverlay = styled.div<any>`
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
  background-color: ${Colours.transparent};
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  border: ${props => props.isVisible ? `3px solid ${Colours.green}` : `3px solid transparent`};
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: border .1s ease-in-out, background-color .1s ease-in-out;
  padding: 12px 16px;
  border-radius: 16px;
  transform: rotate(${props => props.rotate}deg);
  &:hover {
    background: rgba(255, 255, 255, 0.6);
    mix-blend-mode: normal;
    border: 4px solid ${props => props.disabled ? Colours.darkGrey : Colours.blue};
    box-sizing: border-box;
    border-radius: 8px;
    .areaOverlay__spacesAvailable {
      background-color: ${props => props.disabled ? Colours.darkGrey : Colours.blue};
    }
    .userAvatarAndName p {
      background-color: ${props => props.disabled ? Colours.darkGrey : Colours.blue};
    }
  }
`

const SpacesAvailable = styled.div`
  border-radius: 50%;
  background-color: ${Colours.green};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  align-self: center;
  transition: background-color .1s ease-in-out;
`

const AreaLabels = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 52px;
  padding: 6px 20px 6px 6px;
  .areaOverlay__label {
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
  }
`

const UsersInAreaList = styled.div`
  width: 98%;
  max-height: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  .userAvatarAndName {
    margin: 4px;
  }
  .userAvatarAndName p {
    width: 32px;
    height: 32px;
    transition: background-color .1s ease-in-out;
  }
`
