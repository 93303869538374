import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getCarParkBookings,
  selectCarParkingPlanBookings,
  selectSelectedParkingPlanId
} from "../../ducks/carParkingMapView.duck";
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CarParkSpace } from "../../../../../services/CarParkingPlanService";
import { ParkingSpaceOverlay } from "./ParkingSpaceOverlay";
import { selectActiveDay } from "../../../../../store/ducks/editMovements.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import { MapButtonRow, MapControlButton, MapControlsContainer } from "../ViewComponents";
import {
  selectSelectedCarParkingPlan,
  selectSelectedCarParkSpace,
  setSelectedCarParkSpace
} from "../../../../../store/ducks/officeView.duck";
import { SelectedCarParkingSpaceOverlay } from "./SelectedCarParkingSpaceOverlay";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { IsSuperAdmin } from "../../../../../hooks/IsSuperAdmin";

export function CarParkingMapView(props: Props) {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const selectedParkingPlan = useSelector(selectSelectedCarParkingPlan);
  const selectedCarParkPlanId = useSelector(selectSelectedParkingPlanId);
  const carParkBookings = useSelector(selectCarParkingPlanBookings);
  const selectedDate = useSelector(selectActiveDay);
  const selectedCarParkSpace = useSelector(selectSelectedCarParkSpace);
  const dateString = selectedDate?.format(DATE_FORMAT);

  const carParkMapUrl = selectedParkingPlan?.carParkPlan;
  const carParkSpaces = selectedParkingPlan?.parkingSpaces;

  const carParkPlanId = selectedCarParkPlanId || selectedParkingPlan?.id;

  useEffect(() => {
    if (dateString && carParkPlanId) {
      dispatch(getCarParkBookings({carParkPlanId: carParkPlanId, dateString: dateString}));
    }
  }, [dispatch, dateString, carParkPlanId]);

  const clearSelection = () => {
    dispatch(setSelectedCarParkSpace(undefined));
  }

  const onCarParkSpaceClicked = (carParkSpace: CarParkSpace) => {
    if (props.onCarParkingSpaceSelected) {
      props.onCarParkingSpaceSelected(carParkSpace);
    }
  }

  const onBackgroundClick = (e: MouseEvent) => {
    e.stopPropagation();
  }

  const superAdminButtonsEnabled = IsSuperAdmin();

  const onAddNewSpaceClicked = () => {
    dispatch(openDialogWithPayload({
      payload: { parkingSpace: {carParkPlanId: carParkPlanId, label: 'New space'} },
      activeDialog: DialogIdentifiers.EditParkingSpaceDialog,
    }));
  }

  return (
    <Container onClick={(e: any) => onBackgroundClick(e)}>
      <MapWrapper>

        {carParkMapUrl && <>
          <TransformWrapper centerOnInit={true} initialScale={0.5} maxScale={2} minScale={0.5} ref={myRef}>
            {(params: any) => (
              <div style={{position: 'relative', height: '100%', width: '100%'}} onClick={clearSelection}>
                <MapButtonRow>
                  {superAdminButtonsEnabled && <>
                    <OutlineButton text={"Add new space"} size={"small"} click={onAddNewSpaceClicked} />
                  </>}
                  <MapControlsContainer className="tools">
                    <MapControlButton onClick={() => params.zoomIn()}>+</MapControlButton>
                    <MapControlButton onClick={() => params.zoomOut()}>-</MapControlButton>
                    <MapControlButton onClick={() => params.resetTransform()}><BodyVerySmall style={{fontSize: 8}} weight={600}>100%</BodyVerySmall></MapControlButton>
                  </MapControlsContainer>
                </MapButtonRow>

                <TransformComponent wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}>
                  <img src={`/assets/office-layouts/${carParkMapUrl}`} alt={""}/>

                  {carParkSpaces && carParkSpaces.map((parkingSpace, key) => (
                    <ParkingSpaceOverlay parkingSpace={parkingSpace} booking={carParkBookings.find(cpb => cpb.spaceId === parkingSpace.id)}
                                         onClicked={onCarParkSpaceClicked} isSelected={selectedCarParkSpace?.id === parkingSpace.id}
                                         key={key} />
                  ))}

                  {selectedCarParkSpace && <SelectedCarParkingSpaceOverlay carParkingSpace={selectedCarParkSpace} carParkBookings={carParkBookings} />}
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </>}
      </MapWrapper>
    </Container>
  )
}

interface Props {
  onCarParkingSpaceSelected?: (carParkSpace: CarParkSpace) => any;
}

const Container = styled.div`
  display: flex;
  max-height: 100%;
`

const MapWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${Colours.veryLightGrey};
  max-height: 100%;
`
