import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../UI/organisms/layout/Layout';
import { HeadlineLarge } from '../../UI/atoms/fonts/Headline';
import Calendar from '../../UI/molecules/calendar/Calendar';
import './styles/team-movements.scss';
import { redirectTo, selectCurrentUser } from "../../../store/ducks/auth.duck";
import { Column, Row } from '../../UI/atoms/StructuralLayout';
import { selectCalendarDate, setCalendarDate } from "../../../store/ducks/dashboard.duck";
import { VerticalSpacer } from "../../UI/atoms/VerticalSpacer";
import { TeamMovementsContent } from "./styles/TeamMovementsStyles";
import WallChartHeader from "../../UI/organisms/movements-wallchart/components/WallChartHeader/WallChartHeader";
import { useParams } from "react-router";
import { TagWithMovements } from "../../../models/movements.models";
import { getMovementsByTags } from "../../../services/Movements";
import { createUserTagRequest, getTag, removeUserTagRequest } from "../../../services/Tags";
import TagMovementsWallChart from "../../UI/organisms/movements-wallchart/TagMovementsWallChart";
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import IconButton from "../../UI/molecules/icon-button/IconButton";
import styled from "styled-components/macro";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { deepLinkTo, isInTeams } from "../../../utils/TeamsUtils";
import { teamsTabs, uiUrl } from "../../../services/EnvironmentVariables";
import { DialogIdentifiers, openDialogWithPayload } from "../../../store/ducks/dialog.duck";
import { Tag } from "../../../models/tags.models";
import { IconTypes } from "../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import UserSearchDialog, { UserSelectType } from "../../dialogs/user-search-dialog/UserSearchDialog";
import { BasicUserInfo } from "../../../models/user.models";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";

export default function TagMovementsPage() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const calendarDate = useSelector(selectCalendarDate);
  const params: any = useParams();
  const [tag, setTag] = useState<Tag | undefined>();
  const [isSearchUsersDialogOpen, setIsSearchUsersDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const tagId = useMemo(() => params.tagId, [params]);
  const [tagMovements, setTagMovements] = useState<TagWithMovements | undefined>(undefined);
  const {t} = useTranslation();

  const usersInWorkGroupAsBasicUserInfo = useMemo(() => {
    if (tagMovements && tagMovements.movements) {
      const users = tagMovements.movements.map(tm => ({...tm, userId: tm.id})) as any;
      return users as BasicUserInfo[];
    } else {
      return [];
    }
  }, [tagMovements]);

  useEffect(() => {
    const load = async () => {

      const tag = await getTag(tagId);
      setTag(tag);

      const memberMovements = await getMovementsByTags(calendarDate, [tag])
      setTagMovements(new TagWithMovements(tag, true, true, memberMovements))
    }

    if (tagId && calendarDate) {
      load()
    }
  }, [tagId, calendarDate]);

  const editTag = () => {
    if (!tag) {
      return;
    }
    dispatch(openDialogWithPayload({
      payload: { tag: tag },
      activeDialog: DialogIdentifiers.EditUsersInTagDialog
    }));
  }

  const openEditTagDialog = () => {
    dispatch(openDialogWithPayload({
      payload: { workgroup: tag },
      activeDialog: DialogIdentifiers.EditTagNameDialog,
    }));
  }

  const openSearchUsersDialog = () => {
    setIsSearchUsersDialogOpen(true);
  }

  const onGoBackClick = () => {
    if (isInTeams()) {
      deepLinkTo(teamsTabs.company, `${uiUrl}/whereabouts/company/all`);
    } else {
      dispatch(redirectTo('/whereabouts/company/all'));
    }
  }

  const updateUsersInWorkGroup = async (user: any) => {
    if (!tag || !tagMovements || !tagMovements.movements || !user || !user.userId) return;
    const isUserTagged = tagMovements.movements.find(m => m.id === user.userId);
    if (isUserTagged) {
      await removeUserTagRequest(user.userId, tag.id);
    } else {
      await createUserTagRequest(user.userId, tag.id)
    }
    const memberMovements = await getMovementsByTags(calendarDate, [tag]);
    setTagMovements(new TagWithMovements(tag, true, true, memberMovements));
  }

  const onUserSearchDialogClose = async () => {
    if (!tag) return;

    setLoading(true);
    setIsSearchUsersDialogOpen(false);

    try {
      const memberMovements = await getMovementsByTags(calendarDate, [tag]);
      setTagMovements(new TagWithMovements(tag, true, true, memberMovements));
    } finally {
      setLoading(false);
    }
  }

  return <>
    <Layout>
      <Row className="new-teamMovements">
        <TeamMovementsContent style={{maxWidth: current?.companyEntity.sevenDayWhereaboutsWeekEnabled ? 1100 : 1000}}>

          <GoBackButton onClick={onGoBackClick} text="back-to-company-view" url="/whereabouts/company/all" />

          <HeadlineLarge data-test="headline" style={{marginBottom: 16}}>{tagMovements?.tag.label ?? t('tag-view')}</HeadlineLarge>
          <WallChartHeader/>
          <TagMovementsWallChart tagWithMovements={tagMovements} />
          {tagMovements && (tagMovements?.movements ?? [])?.length > 0 && <TagFootRow>
            <BodyRegular>{t('whereabouts.num-of-group-members', {count: tagMovements.movements?.length})}</BodyRegular>
            {tag && <IconButton icon={IconTypes.Edit} text={"edit-workgroup.edit-workgroup"} onClick={editTag} />}
          </TagFootRow>}
        </TeamMovementsContent>
        <VerticalSpacer />
        <Column className="teamMovementsSidebar">
          <Calendar initialDate={calendarDate}
                    highlightToday={true}
                    onDateSelect={(date) => dispatch(setCalendarDate(date))}/>
          <OutlineButton click={openSearchUsersDialog}
                         style={{marginTop: 32}}
                         fullWidth={true}
                         text={'edit-workgroup.add-or-remove-members'} />
          <OutlineButton click={openEditTagDialog}
                         style={{marginTop: 16}}
                         fullWidth={true}
                         text={'edit-workgroup.edit-workgroup-name'} />
        </Column>
      </Row>
    </Layout>
    {loading && <LoadingSpinner fullScreen={true} />}
    {isSearchUsersDialogOpen && <UserSearchDialog onClose={onUserSearchDialogClose}
                                                  title={'edit-workgroup.select-users-to-add'}
                                                  description={'edit-workgroup.add-users-to-group'}
                                                  selectedUsers={usersInWorkGroupAsBasicUserInfo}
                                                  type={UserSelectType.multiSelect}
                                                  onUserSelect={updateUsersInWorkGroup} />}
  </>
}

const TagFootRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
`
