import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import { selectFloorForReservation, selectSelectionOffice } from "../../../../store/ducks/deskReservation.duck";
import { MobileOnly } from "../../../UI/atoms/DeviceSpecific";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import DialogHeader from "../../DialogHeader";
import FloorLocationContentCard from "../../../UI/molecules/FloorLocationContentCard";
import { Center, Row } from "../../../UI/atoms/StructuralLayout";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { CurrentOfficeWhereaboutsSelection } from "./CurrentOfficeWhereaboutsSelection";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { WithOfficeChildren } from "../../../../hooks/WithOfficeChildren";
import { Moment } from "moment";
import { WithSeatsAvailableInParentOffice } from "../../../../hooks/WithSeatsAvailableInParentOffice";
import { CapacityResponse, OfficeWithCapacity } from "../../../../services/OfficeCapacityService";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import styled from "styled-components/macro";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { BodyLarge } from "../../../UI/atoms/fonts/Body";

interface Props {
  activeDate: Moment;
}

export function ChooseFloor(props: Props) {
  const dispatch = useDispatch();
  const close = () => dispatch(closeDialog());
  const offices = useSelector(selectAllOfficeEntities);
  const selectedOffice = useSelector(selectSelectionOffice);
  const selectedOfficeChildren = WithOfficeChildren(selectedOffice?.id);
  const date = useSelector(selectActiveDay);

  const {isLoading, capacities} = WithSeatsAvailableInParentOffice(props.activeDate, selectedOffice);

  const officesWithCapacities: OfficeWithCapacity[] = useMemo(() => {
    return selectedOfficeChildren.map((o: OfficeEntity) => {
      const capacity = capacities.find((c: CapacityResponse) => c.officeId === o.id);
      if (!capacity) return undefined;
      return {
        capacity: capacity,
        office: o,
      }
    }).filter(owc => !!owc) as OfficeWithCapacity[];
  }, [selectedOfficeChildren, capacities]);

  const onSelectFloor = (floor: OfficeEntity) => {
    if (floor.active) {
      dispatch(selectFloorForReservation(offices.find((o: OfficeEntity) => o.id === floor.id)));
    } else {
      dispatch(warningNotification('This floor is not active.'));
    }
  };

  // TODO Show selected floor loading spinner
  return (
    <>
      <MobileOnly>
        <GoBackButton onClick={close} text="back-to-whereabouts"/>
      </MobileOnly>
      <DialogHeader title="dialog-headers.choose-floor"/>
      <BodyLarge weight={600} style={{marginBottom: 16}}>{date?.format('dddd DD MMMM')}</BodyLarge>

      <CurrentOfficeWhereaboutsSelection/>

      {isLoading ? <>
        <Center>
          <LoadingSpinner hideBorder={true} />
        </Center>
      </> : <>
        {officesWithCapacities.length > 0 && <WrappedRow>
          {officesWithCapacities.map((floor: OfficeWithCapacity, key: number) => (
            <FloorLocationContentCard onClick={() => onSelectFloor(floor.office)}
                                      activeDate={props.activeDate}
                                      capacity={floor.capacity}
                                      key={key}
                                      floor={floor.office} />
          ))}
        </WrappedRow>}
      </>}
    </>
  )
}

const WrappedRow = styled<any>(Row)`
  flex-wrap: wrap;
  margin-bottom: 24px;
  max-height: 40vh;
  overflow-y: auto;
  .dialog__title {
    margin-bottom: 0;
  }
`
