import React, { useEffect, useMemo, useState } from 'react';
import { GenericDropDownList } from "../../../../UI/molecules/GenericDropDownList";
import { UserListItem } from "../../../../../models/user.models";
import { getUsersHolidaysCanBeAddedTo } from "../../../../../services/HolidayService";

export function ListOfUsersHolidaysCanBeAddedTo(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserListItem[]>([]);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const dropDownItems = useMemo(() => {
    return users;
  }, [users]);

  useEffect(() => {
    setLoading(true);
    getUsersHolidaysCanBeAddedTo().then(setUsers).finally(() => setLoading(false));
  }, [])

  const onSelect = (selectedId: string) => {
    setSelectedId(selectedId);
    console.log('ListOfUsersHolidaysCanBeAddedTo', selectedId);
    props.onSelect(users.find(u => u.id === selectedId));
  }

  return (
    <>
      <GenericDropDownList disabled={loading} label=""
                           selectedId={selectedId}
                           onSelect={onSelect}
                           items={dropDownItems}
                           placeholder="textinput.please-select" />
    </>
  )
}

interface Props {
  onSelect: (user?: UserListItem) => any;
}
