import React, { useEffect } from 'react';
import Dialog from "../../UI/molecules/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../../store/ducks/dialog.duck";
import {
  loadUserReservationDialog,
  SelectionState,
  selectIsDeskReservationLoading,
  selectSelectionState,
  resetDeskReservationDialog,
  selectShowFloorMap,
  selectShowCarParkMap,
  selectSelectedFloor,
  setSelectedParkingSpace,
  setShowCarParkMap
} from '../../../store/ducks/deskReservation.duck';
import { ChooseOffice } from "./components/ChooseOffice";
import { ChooseFloor } from "./components/ChooseFloor";
import { LocationSelectionDone } from "./components/LocationSelectionDone";
import { ChooseArea } from "./components/ChooseArea";
import { MediumLoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { OfficeFloorMapWithDataProviders } from "./components/OfficeFloorMapWithDataProviders";
import { selectActiveDay } from "../../../store/ducks/editMovements.duck";
import { CarParkMapWithDataProviders } from "./components/CarParkMapWithDataProviders";
import { CarParkSpace } from "../../../services/CarParkingPlanService";

export default function ChooseLocationDialog() {
  const dispatch = useDispatch();
  const selectionState = useSelector(selectSelectionState);
  const activeDate = useSelector(selectActiveDay);
  const isLoading = useSelector(selectIsDeskReservationLoading);
  const selectedFloor = useSelector(selectSelectedFloor);
  const showFloorMap = useSelector(selectShowFloorMap);
  const showCarParkMap = useSelector(selectShowCarParkMap);

  useEffect(() => {
    dispatch(loadUserReservationDialog());
  }, [dispatch]);

  const close = () => {
    dispatch(closeDialog());
    dispatch(resetDeskReservationDialog());
  };

  const onCarParkingSpaceSelected = (carParkSpace: CarParkSpace) => {
    dispatch(setSelectedParkingSpace(carParkSpace));
    dispatch(setShowCarParkMap(false));
  }

  return (
    <>
      <Dialog isOpen={true} onClose={close}>
        {isLoading && !activeDate ? <>
          <MediumLoadingSpinner />
        </> : <>
          {selectionState === SelectionState.Office && activeDate && <ChooseOffice activeDate={activeDate} />}
          {selectionState === SelectionState.Floor && activeDate && <ChooseFloor activeDate={activeDate}/>}
          {selectionState === SelectionState.Area && <ChooseArea />}
          {selectionState === SelectionState.Done && <LocationSelectionDone/>}
        </> }
      </Dialog>

      {showFloorMap && <OfficeFloorMapWithDataProviders />}
      {showCarParkMap && <CarParkMapWithDataProviders selectedOffice={selectedFloor} onCarParkingSpaceSelected={onCarParkingSpaceSelected} />}
    </>
  )
}

