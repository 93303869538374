import React, { useEffect, useState } from 'react';
import { Switch } from "../../../../../UI/atoms/Switch";
import TextField from "../../../../../UI/atoms/TextField";
import { Accordian } from "../../../../../../animations/AnimationVariants";
import { useSelector } from "react-redux";
import { selectIsCarParkingEnabled } from "../../../../../../store/ducks/config.duck";

export function OfficeCarParkingSetting(props: Props) {
  const {carParkingCapacity, onCarParkCapacityChange, isEditable} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const isCarParkingEnabled = useSelector(selectIsCarParkingEnabled);

  const onEnabled = (val: boolean) => {
    setIsOpen(val);
    if (!val) {
      onValueChange('0')
    }
  }

  useEffect(() => {
    setIsOpen(carParkingCapacity > 0);
  }, [carParkingCapacity])

  const onValueChange = (val: string) => {
    try {
      setError('');
      onCarParkCapacityChange(parseInt(val));
    } catch (e) {
      setError('Must be a number');
    }
  }

  if (!isCarParkingEnabled) {
    return null;
  }

  return (
    <>
      <Switch value={isOpen}
              label={"settings.manage-office.enable-car-parking"}
              weight={600}
              style={{marginBottom: 12}}
              onChange={(val) => onEnabled(val)} />

      <Accordian isOpen={isOpen}>
        {isOpen && <TextField disabled={!isEditable}
                              onChange={onValueChange}
                              label="settings.manage-office.car-parking-capacity"
                              dataTest="office-capacity-input"
                              error={error}
                              value={`${carParkingCapacity}`}
                              fullWidth={true}
        />}
      </Accordian>

    </>
  )
}

interface Props {
  carParkingCapacity: number;
  onCarParkCapacityChange: (val: number) => any;
  isEditable: boolean;
}
