import React, { useEffect, useMemo } from 'react';
import { clearLastPath, getLastPath, getParameterByName } from '../../../utils/UrlUtils';
import { useDispatch, useSelector } from 'react-redux';
import { redirectTo, selectCurrentUser, selectTenantInfo } from '../../../store/ducks/auth.duck';
import AuthWrapper from '../auth/components/AuthWrapper';
import StandardLogin from './StandardLogin';
import { Redirect } from "react-router";
import i18next from "i18next";
import { isInTeams } from "../../../utils/TeamsUtils";
import { MsTeamsLoginPage } from "./MsTeamsLoginPage";


export default function Login() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const invitationId = getParameterByName('invitation', window.location.href);
  const splash = getParameterByName('splash', window.location.href);
  const tenantInfo = useSelector(selectTenantInfo);
  const title = useMemo(() => !!tenantInfo ? '' : i18next.t('login.title', 'test'), [tenantInfo]);
  const logoUrl = useMemo(() => !!tenantInfo ? tenantInfo?.logoUrl || 'team-today-logo.svg' : undefined, [tenantInfo]);

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    clearLastPath();
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      dispatch(redirectTo(getLastPath('/whereabouts/team')));
    }
  }, [currentUser, dispatch]);

  if (!!invitationId) {
    return (
      <Redirect to={`/invite?id=${invitationId}`} />
    )
  } else if (isInTeams() && !splash) {
    return <MsTeamsLoginPage />
  } else {
    return (
      <AuthWrapper dataTest="login-standard" hideLoginLink={true} title={title} logoUrl={logoUrl}>
        <StandardLogin />
      </AuthWrapper>
    )
  }
}
