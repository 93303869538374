import { agent } from './agent';
import { Moment } from "moment";
import { DATE_FORMAT } from "../utils/DateUtils";
import { MovementDay } from "../models/movements.models";

export async function getWeeklyTeamNotes(teamIds?: string[], date?: Moment): Promise<WeekNote[]> {
  if (!teamIds || !date) return [];
  const response = await Promise.all(teamIds.map(async (teamId) => await agent.requests.get(`/api/week-notes/${teamId}?date=${date.format(DATE_FORMAT)}`)));
  return response.flat();
}

export async function getWeeklyTeamNoteForUser(userId?: string, date?: Moment): Promise<WeekNote> {
  if (!userId || !date) throw new Error('No userId or date');
  return await agent.requests.get(`/api/week-notes/user/${userId}?date=${date.format(DATE_FORMAT)}`);
}

export async function saveWeeklyNotes(userId: string, teamId: string, date: Moment, note: string): Promise<void> {
  return await agent.requests.post(`/api/week-notes`, {
    userId: userId,
    teamId: teamId,
    date: date.format(DATE_FORMAT),
    note: note,
  })
}

export interface WeekNote {
  notesId: number;
  userId: string;
  teamId: string;
  teamName: string;
  firstName: string;
  lastName: string;
  note: string;
  lastUpdated: string;
  whereabouts: MovementDay[];
}
