import React from 'react';
import { HeadlineLarge } from "../../../../../UI/atoms/fonts/Headline";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { ToggleableOutlineButton } from "../../../../../UI/atoms/buttons/ToggleableOutlineButton";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAddTeamMemberMethod, selectIsLoading, setAddTeamMemberMethod } from "../ducks/add-users.duck";
import { AddTeamMemberMethod } from "../ducks/AddUsers.model";
import { AddNewUserForm } from "./AddNewUserForm";
import { AddExistingUserForm } from "./AddExistingUserForm";
import { selectNumberOfRemainingUsers } from "../../../../../../store/ducks/payments.duck";

export function AddUser(props: Props) {
  const dispatch = useDispatch()
  const {t} = useTranslation();
  const isLoading = useSelector(selectIsLoading);
  const addTeamMemberMethod = useSelector(selectAddTeamMemberMethod);
  const remainingUsers = useSelector(selectNumberOfRemainingUsers);


  return (
    <>
      <Image src={"/assets/images/add-member-image.png"} />
      <HeadlineLarge style={{marginBottom: 12}}>{t('manage.add-new-user.title')}</HeadlineLarge>
      <BodyRegular style={{marginBottom: 24}}>{t('manage.add-new-user.subtitle', {count: remainingUsers})}</BodyRegular>
      <ControlRow>
        <ToggleableOutlineButton isActive={addTeamMemberMethod === AddTeamMemberMethod.NewUser}
                                 text={"manage.add-new-user.invite-new-user"}
                                 disabled={isLoading}
                                 click={() => dispatch(setAddTeamMemberMethod(AddTeamMemberMethod.NewUser))} />
        <ToggleableOutlineButton isActive={addTeamMemberMethod === AddTeamMemberMethod.ExistingUser}
                                 text={"manage.add-new-user.add-existing-user"}
                                 disabled={isLoading}
                                 click={() => dispatch(setAddTeamMemberMethod(AddTeamMemberMethod.ExistingUser))} />
      </ControlRow>

      {addTeamMemberMethod === AddTeamMemberMethod.NewUser && <AddNewUserForm />}
      {addTeamMemberMethod === AddTeamMemberMethod.ExistingUser && <AddExistingUserForm />}
    </>
  )
}

interface Props {
}

const ControlRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .toggleableOutlineButton {
    width: 48%;
  }
  .toggleableOutlineButton button {
    width: 100%;
    max-width: 100%;
  }
  .toggleableOutlineButton:last-child {
    margin-right: 0 !important;
  }
`

const Image = styled.img`
  max-width: 100%;
`
