import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import DialogHeader from "../../dialogs/DialogHeader";
import Dialog from "../../UI/molecules/Dialog";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { Department } from "./data/departments.models";
import Colours from "../../UI/atoms/Colours";
import TextField from "../../UI/atoms/TextField";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { BorderlessButton } from "../../UI/atoms/buttons/BorderlessButton";
import { deleteDepartment } from "./data/departmentsService";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { selectAllDepartments, setDepartments } from "./data/departments.duck";

export function EditDepartmentDialog(props: Props) {
  const [loading, setLoading] = useState(false);
  const departments = useSelector(selectAllDepartments);
  const dispatch = useDispatch();

  const onDeleteClick = async () => {
    try {
      setLoading(true);
      await deleteDepartment(props.payload.department);
      dispatch(setDepartments(departments.filter(d => d.id !== props.payload.department.id)));
      dispatch(closeDialog());
    } catch (e) {
      dispatch(failureNotification('Failed to delete department'))
    } finally {
      setLoading(false);
    }
  }

  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <>
      <DialogWrapper>
        <Dialog isOpen={true} onClose={close}>
          <DialogHeader title={'department-dialog.title'} />

          <TextField onChange={() => {}}
                     disabled={true}
                     label={"department-dialog.name"}
                     value={props.payload.department.name} />
          <TextField onChange={() => {}}
                     disabled={true}
                     style={{marginBottom: 48}}
                     label={"department-dialog.users"}
                     value={props.payload.department.count.toString()} />

          <PrimaryButton text={"button.close"}
                         click={close}
                         style={{marginBottom: 24}}
                         fullWidth={true} />

          <BorderlessButton text={"button.delete"}
                            click={onDeleteClick}
                            disabled={props.payload.department.count > 0 || loading}
                            fullWidth={true} />

        </Dialog>
      </DialogWrapper>
    </>
  )
}

interface Props {
  payload: {
    department: Department;
  };
}

const DialogWrapper = styled.div`
  .dialog {
    min-width: 600px;
  }
  .userAvatarAndName {
    margin-bottom: 24px;
  }
  .switch p {
    font-weight: 600;
    color: ${Colours.darkGrey};
  }
`
