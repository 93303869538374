import React from 'react';
import styled from "styled-components/macro";
import { BodyVerySmall } from "../../../../atoms/fonts/Body";
import Colours from "../../../../atoms/Colours";
import { WhereaboutsOption } from "../../../../../../services/WhereaboutOptions";

export function MovementCustomLabel(props: Props) {
  const {amOption, pmOption} = props;

  if (!amOption || !pmOption) return null;

  const isCustomAmOption = !amOption.required && amOption.pickable;
  const isCustomPmOption = !amOption.required && amOption.pickable;
  if (isCustomAmOption || isCustomPmOption) {
    if (amOption.label === pmOption.label) {
      return <UsersWhereaboutsLabel title={amOption.label}
                                    weight={600}
                                    data-test={'whereabouts-location-label'}>{amOption.label}</UsersWhereaboutsLabel>
    } else {
      return null
    }

  } else {
    return null;
  }
}

interface Props {
  amOption?: WhereaboutsOption;
  pmOption?: WhereaboutsOption;
}

export const UsersWhereaboutsLabel = styled<any>(BodyVerySmall)<any>`
  text-align: left;
  align-self: flex-start;
  margin-top: 4px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  color: ${Colours.black};
  a {
    color: ${Colours.black};
  }
`;
