import { useEffect, useState } from "react";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { Moment } from "moment";
import { CarParkBooking, getCarParkBookings } from "../services/OfficeCapacityService";
import { CarParkPlanBooking, requestCarParkPlanBookingsInOffice } from "../services/CarParkingPlanService";
import { DATE_FORMAT } from "../utils/DateUtils";

export function WithCarParkingBookings(office?: OfficeEntity, selectedDate?: Moment): any {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState<CarParkBooking[]>([]);
  const [carParkPlanBookings, setCarParkPlanBookings] = useState<CarParkPlanBooking[]>([]);

  const dateString = selectedDate?.format(DATE_FORMAT);
  const officeId = office?.id;
  const hasCarParkPlan = office?.hasCarParkPlan;

  useEffect(() => {
    if (!officeId || !dateString) {
      return;
    }

    const loadCarParkBookings = async () => {
      try {
        setLoading(true);
        const carParkBookings = await getCarParkBookings(officeId, dateString);
        setBookings(carParkBookings);
      } finally {
        setLoading(false);
      }
    }

    const loadCarParkPlanBookings = async () => {
      try {
        setLoading(true);
        const carParkBookings = await requestCarParkPlanBookingsInOffice(officeId, dateString);
        setCarParkPlanBookings(carParkBookings);
      } finally {
        setLoading(false);
      }
    }

    if (hasCarParkPlan) {
      loadCarParkPlanBookings();
    } else {
      loadCarParkBookings();
    }
  }, [dateString, officeId, hasCarParkPlan]);

  return {
    loading: loading,
    bookings: bookings,
    carParkPlanBookings: carParkPlanBookings,
  };
}
