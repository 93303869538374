import React, { useEffect, useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import TextField from "../../../../UI/atoms/TextField";
import { Checkbox } from "../../../../UI/atoms/Checkbox";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovals,
  selectNameFilter,
  selectSelectedGroupId,
  selectShowApproved,
  selectShowDeclined,
  selectShowPending,
  setNameFilter,
  setShowApproved,
  setShowDeclined,
  setShowPending
} from "../../../../../store/ducks/approvalRequests.duck";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import DateFlicker, { DateScale } from "../../../../UI/molecules/DateFlicker";
import { Moment } from "moment/moment";
import moment from "moment";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";

export function ApprovalsUserSectionHeader(props: Props) {
  const dispatch = useDispatch();
  const selectedGroupId = useSelector(selectSelectedGroupId);
  const showPending = useSelector(selectShowPending);
  const showApproved = useSelector(selectShowApproved);
  const showDeclined = useSelector(selectShowDeclined);
  const nameFilter = useSelector(selectNameFilter);

  const holidayStartYear = props?.holidayYearStartDate ?? moment();
  const holidayYearEndDate = props?.holidayYearEndDate ?? moment().add(1, 'year');

  const dateRangeString = useMemo(() => {
    const start = holidayStartYear.format('MMM YY');
    const end = holidayYearEndDate.format('MMM YY');
    return `${start} - ${end}`;
  }, [holidayStartYear, holidayYearEndDate]);

  const onTextChange = (val: any) => {
    dispatch(setNameFilter(val));
  }

  useEffect(() => {
    dispatch(getApprovals({
      pending: showPending,
      approved: showApproved,
      rejected: showDeclined,
      calendarDate: holidayStartYear,
      selectedGroupId: selectedGroupId
    }));
  }, [dispatch, holidayStartYear, selectedGroupId, showPending, showApproved, showDeclined]);

  return (
    <Wrapper>
      <Column>
        <TextField className={'userSectionHeader__search'} onChange={onTextChange} value={nameFilter} icon={IconTypes.Search} placeholder={'textinput.filter-users'} />
        <VisibleApprovalsWrapper>
          <Checkbox value={showPending}
                    onChange={(val: boolean) => dispatch(setShowPending(val))}
                    text={'button.pending'} />
          <Checkbox value={showApproved}
                    onChange={(val: boolean) => dispatch(setShowApproved(val))}
                    text={'button.approved'} />
          <Checkbox value={showDeclined}
                    onChange={(val: boolean) => dispatch(setShowDeclined(val))}
                    text={'button.declined'} />
        </VisibleApprovalsWrapper>
      </Column>
      <Row>
        <BodyRegular weight={600}>{dateRangeString}</BodyRegular>
        <DateFlicker className={'userSectionHeader__date'}
                     date={holidayStartYear}
                     hideDateText={true}
                     dateScale={DateScale.Year}
                     updateGlobalDate={true} />
      </Row>
    </Wrapper>
  )
}

interface Props {
  holidayYearStartDate?: Moment;
  holidayYearEndDate?: Moment;
}

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .weekFlicker {
    width: unset;
  }
`

const Column = styled.div`
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px ${Colours.mildGrey};
  align-items: center;
  padding: 24px 0 14px 0;
  .checkbox__container:first-child {
    margin: 0;
  }
  .textField {
    margin-bottom: 0;
  }
  .userSectionHeader__search {
    display: none;
    @media (${tablet}) {
      display: block;
    }
  }
  .userSectionHeader__date {
    align-self: flex-start;
  }
  .weekFlicker__control {
    justify-content: flex-end;
  }
`

const VisibleApprovalsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  width: 100%;
  max-width: 100%;
  justify-content: space-evenly;
  margin: 16px 0 0 0;
  @media (${tablet}) {
    justify-content: flex-end;
    &>* {
      margin-left: 32px;
    }
  }
`
