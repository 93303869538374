import React, { useEffect, useState } from 'react';
import { BodyRegular } from "./fonts/Body";
import { useTranslation } from "react-i18next";

export function Switch({value, label, switchClass, disabled, weight = 500, style = {}, onChange = () => {}}: Props) {
  const [enabled, setEnabled] = useState(value);
  useEffect(() => setEnabled(value), [value]);
  const {t} = useTranslation()
  if (disabled) {
    return null;
  }
  const changed = () => {
    if (disabled) return;
    onChange(!enabled);
    setEnabled(!enabled);
  }
  return (
    <div className={`noSelect switch row ${switchClass ?? ''}`} style={style}>
      {label && <BodyRegular weight={weight} className="noSelect switch__label">{t(label)}</BodyRegular>}
      <label className="noSelect switch__control">
        <input className="noSelect" type="checkbox" checked={enabled} onChange={(e) => changed()} />
        <span className="noSelect switch__slider switch__slider--round" />
      </label>
    </div>
  );
}

interface Props {
  value: boolean;
  label?: string;
  weight?: number
  switchClass?: string;
  disabled?: boolean;
  onChange?: (enabled: boolean) => void;
  style?: any
}
