import React from 'react';
import './_button.scss';
import styled from "styled-components/macro";
import Icon from "../icon/Icon";
import { BaseButton, NewButtonProps } from "./ButtonStyling";
import Colours from "../Colours";
import { useTranslation } from "react-i18next";

export function BorderlessButton(props: NewButtonProps) {
  const {t} = useTranslation();
  let buttonAttributes = {}
  if (!!props.dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = props.dataTest;
  }
  return (
    <BorderlessButtonWrapper {...buttonAttributes}
                             size={props.size || 'medium'}
                             disabled={!!props.disabled}
                             type={props.buttonType ?? 'button'}
                             fullWidth={!!props.fullWidth}
                             style={props?.style ?? {}}
                             onClick={(e: any) => props.click(e)}>
      {props.icon && <Icon style={{marginRight: 8}} icon={props.icon ?? ''}/>}
      {props.text && <span>{t(props.text)}</span>}
    </BorderlessButtonWrapper>
  );
}

const BorderlessButtonWrapper = styled<any>(BaseButton)<any>`
  background-color: white;
  color: ${props => !!props.disabled ? Colours.mildGrey : Colours.blue};

  border: none;

  &:hover {
    background-color: ${props => !!props.disabled ? Colours.white : Colours.veryLightGrey};
    color: ${props => !!props.disabled ? Colours.mildGrey : Colours.darkBlue};
    span {
      text-decoration: ${props => !!props.disabled ? 'none' : 'underline'};
    }
  }
`
