import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../state/app.state";
import { getUpcomingHolidays, requestUsersHolidayOverview } from "../../services/HolidayService";
import moment from 'moment';

export interface HolidaysState {
  usersUpcomingHolidays: any[];
  usersPreviousHolidays: any[];
  holidaysBooked: number;
  remainingAllowance: number;
  totalAllowance: number;
  loading: boolean;
  upcomingCompanyHolidays: any[];
}

export const initialHolidays: HolidaysState = {
  usersPreviousHolidays: [],
  usersUpcomingHolidays: [],
  holidaysBooked: 0,
  remainingAllowance: 0,
  totalAllowance: 0,
  loading: false,
  upcomingCompanyHolidays: [],
}

export const loadUsersHolidays: any = createAsyncThunk(
  'holidays/loadUsersHolidays',
  async (force: any, thunkAPI) => {

    const overview = await requestUsersHolidayOverview();
    const today = moment();

    const previous = overview.holidays.filter(h => h.getToDate().isBefore(today));
    const upcoming = overview.holidays.filter(h => !previous.includes(h));

    return {
      remainingAllowance: overview.daysRemaining,
      holidaysBooked: overview.daysTaken,
      totalAllowance: overview.totalAllowance,
      previous, upcoming
    }
  }
);

export const loadUpcomingCompanyHolidays: any = createAsyncThunk(
  'holidays/loadUpcomingCompanyHolidays',
  async (force: any, thunkAPI) => {
    return await getUpcomingHolidays();
  }
);

const holidaysSlice = createSlice({
  name: 'holidays',
  initialState: initialHolidays,
  reducers: {
    reset: () => initialHolidays,
    setHolidayView: (state, action) => ({
      ...state,
      activeView: action.payload,
    }),
    clearHolidays: () => initialHolidays
  },
  extraReducers: {
    [loadUsersHolidays.pending]: (state) => ({...state, loading: true, }),
    [loadUsersHolidays.reject]: (state) => ({...state, loading: false, }),
    [loadUsersHolidays.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      usersUpcomingHolidays: action.payload.upcoming,
      usersPreviousHolidays: action.payload.previous,
      holidaysBooked: action.payload.holidaysBooked,
      remainingAllowance: action.payload.remainingAllowance,
      totalAllowance: action.payload.totalAllowance,
    }),

    [loadUpcomingCompanyHolidays.fulfilled]: (state, action) => ({
      ...state,
      upcomingCompanyHolidays: action.payload
    }),
  }
});

export const {
  reset,
} = holidaysSlice.actions;
export default holidaysSlice.reducer;

// Selectors
export const selectUpcomingCompanyHolidays = (state: AppState) => state.holidays.upcomingCompanyHolidays;
