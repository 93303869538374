import React from 'react';
import { BodyRegular, BodyVerySmall } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { DROP_DOWN_STYLES, groupStyles } from "./DropDownStyles";
import makeAnimated from "react-select/animated";

export interface DropDownListItem {
  value: any;
  label: string;
}

const animatedComponents = makeAnimated();

function formatOptionLabel(data: any) {
  return (<div {...data} style={groupStyles}>
    <BodyRegular weight={400} colour={Colours.black}>{data.label}</BodyRegular>
    {data.value.memberCount && <BodyVerySmall weight={400} className={'memberCount'}
                                              colour={Colours.black}>{data.value.memberCount}</BodyVerySmall>}
  </div>)
};

export function ReactSelectDropDownList(props: Props) {
  const {onItemSelected, items,   disabled, className, selectedItem} = props;
  const {t} = useTranslation();


  return (
    <Select
      className={className ?? ''}
      value={selectedItem}
      options={items}
      formatOptionLabel={formatOptionLabel}
      onChange={onItemSelected}
      styles={DROP_DOWN_STYLES}
      isDisabled={disabled}
      placeholder={t('Select...')}
      closeMenuOnSelect={true}
      components={{ ...animatedComponents, ClearIndicator:() => null, IndicatorSeparator:() => null }}
    />
  )
}

interface Props {
  disabled?: boolean;
  className?: string;
  selectedItem?: any;
  onItemSelected: (selection: any) => void;
  items: DropDownListItem[];
}
