import React from 'react';
import styled from "styled-components/macro";

export function MeetingRoomSourceIcon(props: Props) {
  return (
    <>
      {props.isOutlook ? <IconImage src={"/assets/icons/Outlook.svg"}
                                    title={'Managed via Outlook'}
                                    style={{marginLeft: props?.marginLeft ?? 0}}
                                    alt={"Outlook"} /> : <IconImage src={"/assets/icons/shape.svg"}
                                                                    title={'Managed via Team Today'}
                                                                    alt={"TeamToday"} />}
    </>
  )
}

interface Props {
  isOutlook: boolean;
  marginLeft?: number;
}

const IconImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
