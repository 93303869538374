import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../UI/atoms/buttons/Button';
import Dialog from '../../UI/molecules/Dialog';
import { HeadlineLarge } from '../../UI/atoms/fonts/Headline';
import TextField from '../../UI/atoms/TextField';
import { createCompany, selectIsSetupCompanyOpen, selectIsSetupCompanyProcessing, selectIsSetupCompanySuccess, setupCompanyActions } from '../../../store/ducks/setupCompany.duck';
import './setup-company-dialog.scss';
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import DialogHeader from '../DialogHeader';
import styled from 'styled-components';
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { IconTypes } from "../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";
import { DialogIdentifiers, openDialog } from "../../../store/ducks/dialog.duck";

export default function SetupCompanyDialog() {
  const isOpen = useSelector(selectIsSetupCompanyOpen);
  const dispatch = useDispatch();
  const isProcessing = useSelector(selectIsSetupCompanyProcessing);
  const successful = useSelector(selectIsSetupCompanySuccess);

  const close = () => {
    dispatch(setupCompanyActions.setIsOpen(false));
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => close()}>
      <div className="setupCompanyDialog">
        { isProcessing && <LoadingSpinner hideBorder={true}/> }
        { successful && <SetupCompanyComplete close={close} /> }
        { (!isProcessing && !successful) && <SetupCompanyInput /> }
      </div>
    </Dialog>
  )
}

function SetupCompanyInput() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [companyName, setCompanyName] = useState('');
  return (
    <React.Fragment>
      <HeadlineLarge className="setupCompanyDialog__title">{t('settings.setup-a-company')}</HeadlineLarge>
      <TextField dataTest="setupCompanyDialogCompanyInput" onChange={setCompanyName} label="settings.company-name" value={companyName} />
      <PrimaryButton dataTest="setupCompanyDialogCreateButton"
                     size="large"
                     fullWidth={true}
                     text="button.create-company"
                     click={() => dispatch(createCompany(companyName))} />
    </React.Fragment>
  )
}

function SetupCompanyComplete(props: {close: () => any}) {
  const dispatch = useDispatch();
  const setupOtherTeams = () => {
    props.close();
    dispatch(openDialog(DialogIdentifiers.CreateTeamDialog));
  }
  return (
    <React.Fragment>
      <DialogHeader title="dialog-headers.company-created" icon={IconTypes.Tick} image="/assets/images/woman-laptop-no-speech-bubble.svg" />
      <SetupCompanyButton dataTest="setupCompanyDialogTeamsButton" size="large" fullWidth={true} text="button.setup-more-teams" onClick={() => setupOtherTeams()} />
      <SetupCompanyButton dataTest="setupCompanyDialogCloseButton" type="outline" size="large" fullWidth={true} text="button.done-for-now" onClick={() => props.close()} />
    </React.Fragment>
  )
}

export const SetupCompanyButton = styled(Button)<any>`
  margin-bottom: 24px;
`
