import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../store/ducks/auth.duck';
import { BodyRegular } from "../../atoms/fonts/Body";
import { setMenuOpen } from "../../../../store/ducks/general.duck";
import UserMenu from "./components/user-menu/UserMenu";
import { Accordian } from "../../../../animations/AnimationVariants";
import { SearchBar } from "../search/SearchBar";
import { BrandingLogo } from './components/BrandingLogo';
import { OutlineButton } from "../../atoms/buttons/OutlineButton";
import { isInTeams } from "../../../../utils/TeamsUtils";
import { useTranslation } from "react-i18next";
import { SubHeader } from "./components/sub-headers/SubHeader";
import { selectIsSearchBarVisible } from "../../../../store/ducks/dashboard.duck";
import styled from "styled-components/macro";
import Colours from "../../atoms/Colours";

export default function Header(props: Props) {
  const location = useLocation();
  const pathname = location.pathname;
  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = !!currentUser;
  const isSearchBarVisible = useSelector(selectIsSearchBarVisible);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const isLoginPage = useMemo(() => pathname.includes('/login'), [pathname]);
  const closeMenu = () => dispatch(setMenuOpen(false));

  if (isInTeams()) {
    return (<>
      <div className="header__container">
        {currentUser && <>
          <SubHeader />
        </>}
      </div>
      <Accordian isOpen={isSearchBarVisible}>
        <SearchBar />
      </Accordian>
    </>)
  }

  return (
    <>
      <div className={props.fullScreenView ? 'header__container--fullscreen' : 'header__container'}>
        <HeaderBackground>
          <div className="header row">
            <BrandingLogo />

            {!isAuthenticated && !isLoginPage ? <nav className="row navigation">
              <OutlineButton className="navigation__item navigation__item--login" link={"/login"} text={"general.login"} />
            </nav> : null}

            {(isAuthenticated && !currentUser?.runOnboarding) ? <nav className="subnav subnav--desktop row">
              <div className={`subnav__item ${location.pathname.includes('/whereabouts/') && !location.pathname.includes('/whereabouts/company') ? 'subnav__item--selected' : ''}`}>
                <Link to="/whereabouts/team" onClick={closeMenu} data-test="header-teamWhereabouts-link">
                  <BodyRegular weight={600}>{t('team-view.whereabouts')}</BodyRegular>
                </Link>
              </div>
              <div className={`subnav__item ${location.pathname.includes('/company') ? 'subnav__item--selected' : ''}`}>
                <Link to="/whereabouts/company/all" onClick={closeMenu} data-test="header-companyWide-link">
                  <BodyRegular weight={600}>{t('button.company-view')}</BodyRegular>
                </Link>
              </div>
              {currentUser?.companyEntity?.enableHolidayBooking && <div className={`subnav__item ${location.pathname.includes('/holidays') ? 'subnav__item--selected' : ''}`}>
                <Link to="/holidays/leave-planner" onClick={closeMenu} data-test="header-holidays-link">
                  <BodyRegular weight={600}>{t('header.leave-planner')}</BodyRegular>
                </Link>
              </div>}
              <div className={`subnav__item ${location.pathname.includes('/meeting-rooms') ? 'subnav__item--selected' : ''}`}>
                <Link to="/meeting-rooms" onClick={closeMenu} data-test="header-workplanner-link">
                  <BodyRegular weight={600}>{t('header.meeting-rooms')}</BodyRegular>
                </Link>
              </div>
            </nav> : null}

            {!!currentUser ? <UserMenu /> : null}
          </div>
          <SubHeader />
        </HeaderBackground>
        <Accordian isOpen={isSearchBarVisible}>
          <SearchBar />
        </Accordian>
      </div>
    </>
  );
}

interface Props {
  fullScreenView?: boolean;
}

const HeaderBackground = styled.div`
  background-color: ${Colours.veryDarkBlue};
`
