import { createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../store/state/app.state";
import { TeamEntity } from "../../../models/team.models";

export interface DeleteTeamDialogState {
  isOpen: boolean;
  isLoading: boolean;
  team?: TeamEntity;
}

export const initialDeleteTeamDialogState: DeleteTeamDialogState = {
  isOpen: false,
  isLoading: false,
  team: undefined,
}

const slice = createSlice({
  name: 'deleteTeamDialog',
  initialState: initialDeleteTeamDialogState,
  reducers: {
    setIsDeleteTeamDialogOpen: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    setIsDeleteTeamDialogProcessing: (state, action) =>
      ({ ...state, isLoading: action.payload }),
  },
  extraReducers: {}
})

export default slice.reducer;
export const {
  setIsDeleteTeamDialogOpen,
  setIsDeleteTeamDialogProcessing,
} = slice.actions;

// Selectors
export const selectIsDeleteTeamDialogOpen = (state: AppState) => state.deleteTeamDialog.isOpen;
export const selectTeamToDeleteTeam = (state: AppState) => state.deleteTeamDialog.team;
export const selectIsDeleteTeamDialogProcessing = (state: AppState) => state.deleteTeamDialog.isLoading;
