import { agent } from './agent';
import { Tag, TagWithCount, UserTag } from "../models/tags.models";
import { BasicUserInfo } from "../models/user.models";

export function getAllTags(): Promise<TagWithCount[]> {
  return agent.requests.get('/api/tags')
}

export function getTag(tagId: number): Promise<Tag> {
  return agent.requests.get(`/api/tags/${tagId}`)
}

export function createTagRequest(label: string): Promise<void> {
  return agent.requests.post('/api/tags', {
    label: label
  }, true)
}

export function updateTagRequest(tag: Tag): Promise<void> {
  return agent.requests.put('/api/tags', tag, true);
}

export function deleteTagRequest(tagId: number): Promise<void> {
  return agent.requests.del('/api/tags/' + tagId, true)
}

export function getAllUserTags(): Promise<UserTag[]> {
  return agent.requests.get('/api/userTags', true)
}

export function createUserTagRequest(userId: string, tagId: number): Promise<void> {
  return agent.requests.post('/api/userTags/create', {
    userId: userId, tagId: tagId,
  })
}

export function addMultipleUsersToWorkGroup(userIds: string[], tagId: number): Promise<void> {
  return agent.requests.post('/api/userTags/add-all', {
    userIds: userIds, tagId: tagId,
  })
}

export function removeUserTagRequest(userId: string, tagId: number): Promise<void> {
  return agent.requests.post('/api/userTags/del', {
    userId: userId, tagId: tagId,
  })
}

export function getAllUsersInTag(tagId: number): Promise<BasicUserInfo[]> {
  return agent.requests.get(`/api/tags/users/${tagId}`, true);
}

export async function updateTagsAssignedOfficesRequest(tagId: number, officeIds: number[]) {
  await agent.requests.put('/api/tags/office', {
    tagId: tagId,
    officeIds: officeIds,
  });
}
