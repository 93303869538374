import React from 'react';
import Dialog from "../UI/molecules/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import { closeNewPaymentDialog, isNewPaymentDialogOpen } from "../../store/ducks/payments.duck";
import SetupPaymentFlow from "../UI/organisms/stripe/SetupPaymentFlow";

export default function NewPaymentDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector(isNewPaymentDialogOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => dispatch(closeNewPaymentDialog())}>
      <SetupPaymentFlow/>
    </Dialog>
  )
}
