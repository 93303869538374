import React from 'react';
import { Button } from '../atoms/buttons/Button';
import { Row } from '../atoms/StructuralLayout';

export default function DoThisLaterButtonRow(props: {dataTest: string, disabled?: boolean, onClick: () => any}) {
  return (
    <Row className="onboarding__controlRow">
      <Button dataTest={props.dataTest}
              disabled={!!props.disabled}
              type="outline-borderless"
              className="teamMemberControls__later"
              onClick={() => props.onClick()}
              text="I'll do this later" />
    </Row>
  )
}
