import React, { useMemo } from 'react';
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { CapacityResponse, OfficeWithCapacity } from "../../../../../services/OfficeCapacityService";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { Column, SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import { useSelector } from "react-redux";
import { DesksAvailableLabel } from "../../../../UI/molecules/DesksAvailableLabel";
import { AUTO_EXPAND_LIMIT, DailyOfficeArea } from "./DailyOfficeArea";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { DeskReservations } from "../../../../../services/DeskBookingService";
import { selectActiveDay, selectActivePeriod } from "../../../../../store/ducks/editMovements.duck";
import { OfficeListUserRow } from "./OfficeListUserRow";
import { OfficesOfParentWithCapacities } from "../../../../../hooks/OfficesOfParentWithCapacities";
import { DeskReservationsInOffice } from "../../../../../hooks/DeskReservationsInOffice";
import { isInTeams } from "../../../../../utils/TeamsUtils";

export function FloorUserListView(props: Props) {
  const {floor, capacity} = props;
  const selectedDate = useSelector(selectActiveDay);
  const activePeriod = useSelector(selectActivePeriod);

  const areasWithCapacities = OfficesOfParentWithCapacities(floor, selectedDate);
  const deskReservations = DeskReservationsInOffice(floor, selectedDate);

  const hasDesksOrAreas = useMemo(() => {
    return  floor && (floor.hasAreas || floor.deskBookingEnabled);
  }, [floor]);

  if (!floor) {
    return null;
  }

  return (
    <>
      {hasDesksOrAreas && <SpaceBetweenRow>
        <Column>
          <HeadlineSmall>{floor.label}</HeadlineSmall>
          {selectedDate && <DesksAvailableLabel office={floor}
                                                date={selectedDate}
                                                period={activePeriod} />}
        </Column>

        {!isInTeams() && <div>
          <OutlineButton text={'button.view'} size={'small'}
                         click={() => window.location.href = `/whereabouts/company/all?office=${floor.id}&view=floor`} />
        </div>}
      </SpaceBetweenRow>}

      {hasDesksOrAreas ? <>
          {deskReservations.deskBookings.map((user: DeskReservations, key: number) => (
            <OfficeListUserRow key={key} containerOffice={floor}
                               firstName={user.firstName}
                               lastName={user.lastName}
                               amDeskLabel={user.amDeskLabel}
                               pmDeskLabel={user.pmDeskLabel}
                               fireWarden={user.fireWarden}
                               firstAider={user.firstAider}
                               mentalHealthAider={user.mentalHealthAider}
                               keyHolder={user.keyHolder}
            />
          ))}

          {areasWithCapacities.officesWithCapacities.map((area: OfficeWithCapacity, key: number) =>
            <DailyOfficeArea key={key} capacity={area.capacity}
                             initialOpenState={areasWithCapacities.officesWithCapacities.length < AUTO_EXPAND_LIMIT}
                             entity={area.office} />)}
        </> :
        <>
          <DailyOfficeArea capacity={capacity}
                           entity={floor}
                           initialOpenState={true}
          />
        </>}
    </>
  )
}

interface Props {
  floor: OfficeEntity;
  capacity?: CapacityResponse;
  loadUsers?: boolean;
  rootOffice: OfficeEntity
}
