import React, { useEffect, useState } from 'react';
import { useAccount, useMsal } from "@azure/msal-react";
import { agent } from "../../../services/agent";
import { AuthenticationResult } from "@azure/msal-common";
import { microsoftRequest } from "../../../services/EnvironmentVariables";
import { AuthType } from "../../../utils/AuthUtils";
import ReactGA from "react-ga";
import AuthWrapper from "./components/AuthWrapper";
import { AzureErrorHandlingMessages, MsalErrorCodes } from "../../UI/organisms/auth/AzureErrorHandlingMessages";
import { setRegisterAuthType } from "../../../store/ducks/auth.duck";
import { DialogIdentifiers, openDialog } from "../../../store/ducks/dialog.duck";
import { useDispatch } from "react-redux";
import { emitRegisterEvent } from "../../../services/RegisterService";


export default function RegisterWithAzure() {
  const [error, setError] = useState('');
  const {accounts, instance, inProgress} = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.event({category: 'NewUser', action: 'Registered with Microsoft success'});
  }, []);

  useEffect(() => {
    const onSuccess = async (result: AuthenticationResult) => {
      localStorage.setItem('accessToken', result.accessToken)
      localStorage.setItem('idToken', result.idToken);
      localStorage.setItem('type', AuthType.Microsoft);
      emitRegisterEvent();
      await registerWithServer();
      window.location.href = '/whereabouts/team';
    }

    if (account) {
      instance.setActiveAccount(account);
      instance.acquireTokenSilent({ ...microsoftRequest.register, account: account })
        .then((resp) => onSuccess(resp))
        .catch((err) => {
          console.error(err)
          instance.acquireTokenRedirect({...microsoftRequest.register})
        });
    }
  }, [instance, account, inProgress])

  const registerWithServer = async () => {
    const informMe = localStorage.getItem('informMe') === 'true';

    try {
      await agent.requests.post('/register/azure', {informMe: informMe});
    } catch (e: any) {
      console.error(e)
    }
    try {
      window.location.href = "/whereabouts/team"
    } catch (e: any) {
      console.error(e)
      setError(e)
    }
  }

  const registerWithMs = () => {
    dispatch(setRegisterAuthType(AuthType.Microsoft));
    dispatch(openDialog(DialogIdentifiers.SignUpTermsDialog));
    ReactGA.event({category: 'NewUser', action: 'Register with Microsoft'});
  }

  return (
    <AuthWrapper dataTest="register-with-azure" title="" spinner={!error} hideLoginLink={!!error}>
      {error && <AzureErrorHandlingMessages goBackLink="/register"
                                            onRetryClicked={registerWithMs}
                                            title="Register failed"
                                            errorCode={error as MsalErrorCodes}/>}
    </AuthWrapper>
  )
}
