import React from 'react';
import styled from "styled-components/macro";

export function FireWardenBadge(props: Props) {
  return (
    <FireWardenWrapper data-test="fire-warden-badge" style={props.style ?? {}} title="This person is a designated fire warden">
      <img src="/assets/icons/fire-warden.svg" alt="Fire warden" />
    </FireWardenWrapper>
  )
}

interface Props {
  style?: any;
}

const FireWardenWrapper = styled.div`
`
