import React, { useMemo } from 'react';
import { TeamEntity } from "../../../../models/team.models";
import { PrimaryButton } from "./PrimaryButton";
import { OutlineButton } from "./OutlineButton";
import { isInCompanyTab, isInTeams, isInTeamsSettingsTab, isInTeamsTab } from "../../../../utils/TeamsUtils";
import { canUserEditTeam } from "../../../../utils/AccessControl";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";

interface Props {
  team?: TeamEntity;
  buttonStyle?: 'outline' | 'primary';
  size?: 'large' | 'medium' | 'small';
  style?: any;
  disabled?: boolean;
}

export function AddTeamMemberButton(props: Props) {
  const buttonStyle = props.buttonStyle || 'outline';
  const currentUser = useSelector(selectCurrentUser);
  const canEditTeam = canUserEditTeam(currentUser, props.team)

  const link = useMemo(() => {
    if (!isInTeams() || isInTeamsSettingsTab()) {
      return '/manage/add-users';
    } else if (isInCompanyTab()) {
      return '/whereabouts/company/add-users'
    } else if (isInTeamsTab()) {
      return '/whereabouts/team/add-users'
    } else {
      return '/manage/add-users';
    }
  }, [])

  if (!canEditTeam) {
    return null;
  }

  if (buttonStyle === 'primary') {
    return (
      <PrimaryButton dataTest="addTeamMember-button" size={props?.size ?? 'small'}
                     text={!!props.team ? 'settings.button-add-team-member' : 'settings.button-add-user'}
                     style={props.style ?? {}}
                     disabled={props?.disabled}
                     link={link} />
    )
  } else {
    return (
      <OutlineButton dataTest="addTeamMember-button" size={props?.size ?? 'medium'}
                     text={!!props.team ? 'settings.button-add-team-member' : 'settings.button-add-user'}
                     link={link}
                     disabled={props?.disabled}
                     style={props.style ?? {}} />
    )
  }
}
