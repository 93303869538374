import React from 'react';
import { BodyRegular, BodyVerySmall } from '../../atoms/fonts/Body';
import './footer.scss';
import { Row } from "../../atoms/StructuralLayout";
import styled from "styled-components/macro";
import { breakpoints, desktop, tablet } from "../../atoms/MediaQueries";
import { Link } from "react-router-dom";
import Colours from "../../atoms/Colours";
import { isInTeams } from "../../../../utils/TeamsUtils";
import { useTranslation } from "react-i18next";
import { buildNumber } from "../../../../services/EnvironmentVariables";

export default function Footer() {
  const {t} = useTranslation();

  if (isInTeams()) {
    return null;
  }

  return (
    <FooterFull>
      <FooterContainer>
        <FooterRow>
          <FooterLogoColumn>
            <img src="/assets/icons/shape.svg" alt="shape" aria-hidden={true} style={{height: 48, width: 48}}/>
          </FooterLogoColumn>
          <FooterColumn>
            <FooterTitle>{t('upgrade')}</FooterTitle>
            <FooterLink to="/manage/payments">{t('change-pricing-plan')}</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>{t('company')}</FooterTitle>
            <FooterAnchorLink href="https://team-today.com/get-in-touch.html">{t('get-in-touch')}</FooterAnchorLink>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>{t('help')}</FooterTitle>
            <FooterAnchorLink href="https://team-today.com/faqs.html">{t('faqs')}</FooterAnchorLink>
          </FooterColumn>
        </FooterRow>
        <FooterSocialRow>
          <FooterColumn>
          </FooterColumn>
          <FooterColumn style={{flex: 2}}>
            <BodyVerySmall style={{marginBottom: 4}}>Build: {buildNumber}</BodyVerySmall>
            <BodyVerySmall>{t('copyright')}</BodyVerySmall>
          </FooterColumn>
          <FooterSocialColumn>
            <a href="https://twitter.com/TeamTracker5">
              <img src="/assets/icons/Twitter.svg" style={{marginRight: 24}} alt="Twitter"/>
            </a>
            <a href="https://www.linkedin.com/company/team-tracker" >
              <img src="/assets/icons/Linkedin.svg" alt="Linkedin"/>
            </a>
          </FooterSocialColumn>
        </FooterSocialRow>
      </FooterContainer>
    </FooterFull>
  )
}

const FooterFull = styled.div`
  background-color: ${Colours.veryLightGrey};
  padding: 48px 24px 24px 24px;
  margin-top: auto;
  @media (${tablet}) {
    padding: 32px 0 48px 0;
  }
`

const FooterContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  @media (${desktop}) {
    width: ${breakpoints.desktop}px;
  }
`

const FooterRow = styled<any>(Row)`
  flex-direction: column;
  @media (${tablet}) {
    margin-bottom: 36px;
    flex-direction: row;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const FooterColumn = styled.div`
  margin: 0 auto 24px auto;
  flex: 1;
  width: 100%;
  @media (${tablet}) {
    margin: 0 auto;
  }
`

const FooterSocialColumn = styled<any>(FooterColumn)`
  justify-content: flex-end;
  display: flex;
  @media (${tablet}) {
    justify-content: flex-start;
  }
`

const FooterLogoColumn = styled<any>(FooterColumn)`
  display: none;
  @media (${tablet}) {
    display: flex;
  }
`

const FooterSocialRow = styled<any>(FooterRow)`
  flex-direction: column-reverse;
  margin-top: -36px;
  @media (${tablet}) {
    margin-top: 0;
    flex-direction: row;
  }
`

const FooterTitle = styled<any>(BodyRegular)`
  font-weight: bold;
  @media (${tablet}) {
    margin-bottom: 24px;
  }
`

const FooterLink = styled<any>(Link)`
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: none;
  text-decoration: none;
  color: ${Colours.black};
`

const FooterAnchorLink = styled.a`
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: none;
  text-decoration: none;
  color: ${Colours.black};
`
