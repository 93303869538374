import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import DialogHeader from "../../../../../dialogs/DialogHeader";
import Dialog from "../../../../../UI/molecules/Dialog";
import { closeDialog } from "../../../../../../store/ducks/dialog.duck";
import { OfficeDropDownList } from "../../../../../UI/organisms/OfficeDropDownList";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { assignUsersToOffice, selectAllCheckedUsers, selectIsManageUsersLoading } from "../ducks/manage-users.duck";

export default function AssignToOfficeDialog(props: Props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<OfficeEntity | undefined>();
  const selectedUsers = useSelector(selectAllCheckedUsers);
  const isLoading = useSelector(selectIsManageUsersLoading);

  const close = () => {
    dispatch(closeDialog());
  }

  const done = () => {
    if (selection && selectedUsers.length > 0) {
      dispatch(assignUsersToOffice(selection.id));
      close();
    }
  }

  const onSelect = (office: OfficeEntity) => {
    setSelection(office);
  };

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-users-page.assign-to-office" />
      <OfficeDropDownList onSelect={onSelect} disabled={isLoading} />
      <PrimaryButton fullWidth={true} disabled={isLoading}
                     style={{marginBottom: 16, marginTop: 'auto'}}
                     className={'saveChangesButton'}
                     click={done}
                     text="button.save-changes" />
    </DialogWrapper>
  )
}

interface Props {
  payload: any;
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 400px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 300px;
    min-height: 410px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
  .saveChangesButton {
    margin-top: auto;
  }
`
