import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { Moment } from 'moment';
import {
  cancelReservationRequest,
  Desk,
  DeskReservations,
  getDeskReservationsInOffice,
  getDesksInOffice,
  makeReservationRequest
} from "../../services/DeskBookingService";
import { failureNotification, successNotification } from "./notification.duck";
import { refreshAllMovements } from "./companyMovements.duck";
import { selectOffice } from "./officeView.duck";
import { OfficeEntity } from "../../services/AdvanceHotDeskingService";
import { getErrorMessage } from "../../services/agent";
import { DialogIdentifiers, openDialogWithPayload } from "./dialog.duck";
import { Period, toSideOfDay } from "../../models/movements.models";

export interface DeskBookingState {
  desks: Desk[];
  deskReservations: DeskReservations[]
  isLoading: boolean;
}

export const initialDeskBookingState: DeskBookingState = {
  desks: [],
  deskReservations: [],
  isLoading: false,
}

export const fetchDesks: any = createAsyncThunk(
  'deskBookingDuck/fetchDesks',
  async (officeId: number, thunkAPI) => {
    return await getDesksInOffice(officeId);
  }
)

export const fetchDeskReservations: any = createAsyncThunk(
  'deskBookingDuck/fetchDeskReservations',
  async (params: {officeId: number, date: Moment}, thunkAPI) => {
    return await getDeskReservationsInOffice(params.officeId, params.date);
  }
)

export const makeDeskReservation: any = createAsyncThunk(
  'deskBookingDuck/makeDeskReservation',
  async (params: {deskId: number, period: Period, date: Moment, office: OfficeEntity}, thunkAPI) => {
    const user = (thunkAPI.getState() as AppState).auth.currentUser;
    const requestParkingSpaceByDefault = !!user?.carParkingEnabled;
    const sideOfDay = toSideOfDay(params.period)
    try {
      const response = await makeReservationRequest(params.deskId, sideOfDay, params.date, requestParkingSpaceByDefault);

      if (response.errors && response.errors.length > 0) {
        thunkAPI.dispatch(openDialogWithPayload({
          payload: { errors: response.errors, response: response },
          activeDialog: DialogIdentifiers.SavedWhereaboutsErrors
        }))
      } else {
        await Promise.all([
          await thunkAPI.dispatch(fetchDeskReservations({officeId: params.office.id, date: params.date})),
          await thunkAPI.dispatch(refreshAllMovements()),
          await thunkAPI.dispatch(selectOffice(params.office)),
        ]);

        thunkAPI.dispatch(successNotification(`Desk booked.`));
      }

    } catch (err: any) {
      thunkAPI.dispatch(failureNotification(getErrorMessage(err, `That's already been booked, please select another seat.`)));
    }
  }
)

export const cancelDeskReservation: any = createAsyncThunk(
  'deskBookingDuck/cancelDeskReservation',
  async (params: {deskId: number, period: Period, date: Moment, bookingUserId: string; office: OfficeEntity}, thunkAPI) => {
    const sideOfDay = toSideOfDay(params.period)
    try {
      await cancelReservationRequest(params.deskId, sideOfDay, params.date, params.bookingUserId);

      await Promise.all([
        await thunkAPI.dispatch(fetchDeskReservations({officeId: params.office.id, date: params.date})),
        await thunkAPI.dispatch(refreshAllMovements()),
        await thunkAPI.dispatch(selectOffice(params.office)),
      ]);

      thunkAPI.dispatch(successNotification(`Booking cancelled`));
    } catch (err: any) {
      thunkAPI.dispatch(failureNotification(getErrorMessage(err, `That's already been booked, please select another seat.`)));
    }
  }
)

const deskBookingSlice = createSlice({
  name: 'deskBookingDuck',
  initialState: initialDeskBookingState,
  reducers: {
    resetDeskBooking: () => ({...initialDeskBookingState}),
    clearDesks: (state) => ({ ...state, desks: [], deskReservations: [], })
  },
  extraReducers: {
    [fetchDesks.pending]: (state) => ({...state, desks: []}),
    [fetchDesks.failed]: (state) => ({...state, desks: []}),
    [fetchDesks.fulfilled]: (state, action) => ({
      ...state,
      desks: action.payload || [],
    }),

    [fetchDeskReservations.pending]: (state) => ({...state, deskReservations: []}),
    [fetchDeskReservations.failed]: (state) => ({...state, deskReservations: []}),
    [fetchDeskReservations.fulfilled]: (state, action) => ({
      ...state,
      deskReservations: action.payload,
    }),

    [makeDeskReservation.pending]: (state) => ({...state, isLoading: true}),
    [makeDeskReservation.failed]: (state) => ({...state, isLoading: false}),
    [makeDeskReservation.fulfilled]: (state) => ({...state, isLoading: false}),
  }
})

export default deskBookingSlice.reducer;
export const {
  resetDeskBooking,
  clearDesks,
} = deskBookingSlice.actions;

// Selectors
export const selectAllOfficeDesks = (state: AppState) => state.deskBooking.desks;
export const selectAllOfficeDeskReservations = (state: AppState) => state.deskBooking.deskReservations;
export const selectIsDeskBookingLoading = (state: AppState) => state.deskBooking.isLoading;
