import React, { useMemo } from 'react';
import styled from "styled-components";
import Calendar, { CalendarProps } from "../../../../UI/molecules/calendar/Calendar";
import moment, { Moment } from "moment";
import {
  companyHolidayToHighlightedDayConverter,
  HolidayDay,
  holidayToHighlightedDayConverter
} from "../../models/Holidays.model";
import { CompanyHoliday } from "../../../../../services/CompanyHolidayService";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { CompanyHolidayInfoPopup } from "../../../wall-planner/components/CompanyHolidayInfoPopup";
import { PopupPosition } from "../../../wall-planner/components/HolidayInfoAndApprovalPopup";
import { SimpleHolidayInfoPopup } from "../../../wall-planner/components/SimpleHolidayInfoPopup";

function filterHolidaysOnMonth(h: HolidayDay | CompanyHoliday, monthDate: Moment) {
  return !moment(h.date).isBefore(monthDate.startOf('month')) && !moment(h.date).isAfter(monthDate.endOf('month'))
}

export function HolidayCalendarMonthView(props: Props) {
  const {monthDate, holidaysBooked, colourOverride, companyHolidays} = props;
  const daysBookedInMonth = useMemo(() => {
    const usersHolidays = holidaysBooked
      .filter(h => filterHolidaysOnMonth(h, monthDate))
      .map((bookedHoliday) => holidayToHighlightedDayConverter(bookedHoliday))
      .map(highlightedDay => !!colourOverride ? ({...highlightedDay, color: colourOverride}) : highlightedDay)

    const filteredCompanyHolidays = companyHolidays.filter(ch => filterHolidaysOnMonth(ch, monthDate))
      .map((bookedHoliday) => companyHolidayToHighlightedDayConverter(bookedHoliday))

    return [...usersHolidays, ...filteredCompanyHolidays];
  }, [holidaysBooked, monthDate, colourOverride, companyHolidays]);

  const onDateSelect = () => {
  }

  return (
    <CalendarWrapper onDateSelect={onDateSelect}
                     initialDate={monthDate}
                     highlightedDays={daysBookedInMonth}
                     hideActiveDayHighlight={true}
                     hideFlicker={true}
                     hideDaysOutsideOfMonth={true}
                     enabledStartDate={props.enabledStartDate}
                     enabledEndDate={props.enabledEndDate}
                     renderOnHover={(date: Moment) => <OnHover date={date} companyHolidays={companyHolidays} holidays={holidaysBooked} />}/>
  )
}

interface Props {
  monthDate: Moment;
  companyHolidays: CompanyHoliday[];
  holidaysBooked: HolidayDay[];
  colourOverride?: string;
  enabledStartDate?: Moment;
  enabledEndDate?: Moment;
}

function OnHover(props: {holidays: HolidayDay[], companyHolidays: CompanyHoliday[], date: Moment}) {
  const {companyHolidays, holidays, date} = props;
  const holiday = useMemo(() => holidays.find(h => h.date === date.format(DATE_FORMAT)), [holidays, date])
  const companyHoliday = useMemo(() => companyHolidays.find(h => h.date === date.format(DATE_FORMAT)), [companyHolidays, date])

  if (!!holiday && !!companyHoliday) {
    return null;
  } else if (holiday) {
    return <PopupWrapper>
      <SimpleHolidayInfoPopup holiday={holiday} popupPositioning={PopupPosition.None} />
    </PopupWrapper>
  } else if (companyHoliday) {
    return <PopupWrapper>
      <CompanyHolidayInfoPopup companyHoliday={companyHoliday} popupPositioning={PopupPosition.None} />
    </PopupWrapper>
  } else {
    return null;
  }
}


const CalendarWrapper = styled<any>(Calendar)<CalendarProps>`
  margin: 16px;
`

const PopupWrapper = styled.div`
  position: absolute;
  .popup {
    display: flex !important;
  }
`
