import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import { fetchTags } from "../../../store/ducks/userTags.duck";
import { Tag } from "../../../models/tags.models";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import Dialog from "../../UI/molecules/Dialog";
import DialogHeader from "../DialogHeader";
import { LoadingOverlay } from "../../UI/molecules/LoadingOverlay";
import TextField from "../../UI/atoms/TextField";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { updateTagRequest } from "../../../services/Tags";
import { SimpleSpinner } from "../../UI/atoms/SimpleSpinner";

interface Props {
  payload?: { workgroup: Tag };
}

export default function EditTagNameDialog(props: Props) {
  const dispatch = useDispatch();
  const [newTagName, setNewTagName] = useState(props.payload?.workgroup.label ?? '');
  const [loading, setLoading] = useState(false);

  const close = () => {
    dispatch(closeDialog())
  };

  const updateTagClick = async () => {
    if (!props || !props.payload || !props.payload.workgroup) return;

    setLoading(true);

    try {
      await updateTagRequest({
        ...props.payload.workgroup,
        label: newTagName,
      });
      dispatch(fetchTags());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="edit-workgroup.edit-workgroup-name" />

      {loading && <LoadingOverlay />}
      <InputRow>
        <TextField onChange={setNewTagName}
                   value={newTagName}
                   disabled={loading}
                   fullWidth={true}
                   style={{marginBottom: 16}}  />
        {loading && <SimpleSpinner size={32} />}
      </InputRow>

      <PrimaryButton disabled={loading}
                     fullWidth={true}
                     click={updateTagClick}
                     text="button.save-changes" />
    </Dialog>
  )
}

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  .textField {
    margin-bottom: 0;
    flex: 1;
  }
`
