import React from 'react';
import styled from "styled-components/macro";
import Calendar from "../../../UI/molecules/calendar/Calendar";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { NumberOfPeopleOption } from "./NumberOfPeopleOption";
import { refreshBookings, selectedOffice, setOffice, setSidebarOpen } from "../models/room-finder.duck";
import { MeetingRoomFacilitiesOptions } from "./MeetingRoomFacilitiesOptions";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { selectActiveDay, setActiveDay } from "../../../../store/ducks/editMovements.duck";
import { selectAllRootOffices } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { addOrReplaceQueryParam } from "../../../../utils/UrlUtils";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import IconButton from "../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { OfficeDropDownList } from "../../../UI/organisms/OfficeDropDownList";
import { FloatingSidebar } from "../../company-movements/components/ViewComponents";
import { MicrosoftCalendarSyncActiveIndicator } from "../../../UI/organisms/MicrosoftCalendarSyncActiveIndicator";

export function RoomFinderSidebar(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectActiveDay);
  const office = useSelector(selectedOffice);
  const allOfficesForMeetingRooms = useSelector(selectAllRootOffices);

  const onOfficeSelected = (office: OfficeEntity) => {
    dispatch(setOffice(office));
  }

  const onDateSelected = (date: Moment) => {
    addOrReplaceQueryParam('date', date.format(DATE_FORMAT))
    dispatch(setActiveDay(date));
    dispatch(refreshBookings())
  }

  return (
    <FloatingSidebar className={'officeFloorMap__sidebar--open'}>
      {props.showCloseButton && <IconButton icon={IconTypes.Close}
                                            size={"large"} className={"sidebar__closeBtn"}
                                            onClick={() => dispatch(setSidebarOpen(false))} />}
      <Calendar onDateSelect={onDateSelected}
                initialDate={calendarDate ?? moment()}
                activeDate={calendarDate} style={{margin: '0 auto 24px auto'}} />
      <MicrosoftCalendarSyncActiveIndicator style={{marginBottom: 24}} />
      <OfficeDropDownListWrapper>
        <BodyRegular weight={700} style={{marginBottom: 8}}>Office</BodyRegular>
        <OfficeDropDownList onSelect={onOfficeSelected} offices={allOfficesForMeetingRooms} disabled={false} preSelectedOffice={office?.id} />
      </OfficeDropDownListWrapper>
      <NumberOfPeopleOption />
      <MeetingRoomFacilitiesOptions />
    </FloatingSidebar>
  )
}

interface Props {
  showCloseButton: boolean;
}

const OfficeDropDownListWrapper = styled.div`
  margin: 16px 0;
  width: 100%;
`
