import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTeams } from "../../../../../../store/ducks/config.duck";
import { setSelectedTeam } from "../ducks/manage-users.duck";

export function WithTeams() {
  const teams = useSelector(selectAllTeams);
  const dispatch = useDispatch();
  return useMemo(() => {
    const totalUsers = teams.map(t => t.memberCount).reduce((partialSum, a) => partialSum + a, 0);
    const teamsList = [
      {id: 'ALL', name: 'All Users', memberCount: totalUsers},
      ...teams
    ]

    dispatch(setSelectedTeam(teamsList[0]));

    return teamsList;
  }, [teams, dispatch]);
}
