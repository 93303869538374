import React, { useEffect, useMemo, useState } from 'react';
import { HeadlineLarge } from "../../../../UI/atoms/fonts/Headline";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../../../../store/ducks/config.duck";
import { BodyRegular, BodySmall } from "../../../../UI/atoms/fonts/Body";
import moment, { Moment } from "moment";
import TextField from "../../../../UI/atoms/TextField";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { Center } from "../../../../UI/atoms/StructuralLayout";
import { BorderlessButton } from "../../../../UI/atoms/buttons/BorderlessButton";
import { useParams } from "react-router";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import {
  CompanyHoliday,
  createCompanyHoliday,
  deleteCompanyHoliday,
  getCompanyHoliday
} from "../../../../../services/CompanyHolidayService";
import { redirectTo, selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import FullScreenDateRangeSelector, {
  SelectionType
} from "../../../../UI/molecules/calendar/FullScreenDateRangeSelector";
import { DateInputRow } from "../../../wall-planner/WallPlannerNewEventPage";
import { DateInputField } from "../../../../UI/molecules/DateInputField";
import { canUserCreateCompanyHolidays } from "../../../../../utils/AccessControl";
import AccessRequired from "../../components/AccessRequired";
import { failureNotification } from "../../../../../store/ducks/notification.duck";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { getInt } from "../../../../../utils/MathUtils";
import { selectOffices } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { useTranslation } from "react-i18next";
import { OfficeRow } from "./components/OfficeRow";

export function CrudCompanyHolidayPage(props: Props) {
  const dispatch = useDispatch();
  const params: any = useParams();
  const config = useSelector(selectConfig);
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const offices = useSelector(selectOffices);
  const [label, setLabel] = useState('');
  const [fromDate, setFromDate] = useState<Moment | undefined>(undefined);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestAttempted, setRequestAttempted] = useState(false);
  const [checkedOfficeIds, setCheckOfficeIds] = useState<number[]>([]);
  const holidayId = useMemo(() => getInt(params.id), [params]);
  const canEdit = useMemo(() => canUserCreateCompanyHolidays(user), [user])

  useEffect(() => {
    if (holidayId && !loading && !requestAttempted) {
      setLoading(true);
      setRequestAttempted(true);
      getCompanyHoliday(holidayId)
        .then((holiday: CompanyHoliday) => {
          setFromDate(moment(holiday.date));
          setLabel(holiday.label);
          setCheckOfficeIds(holiday.companyHolidayOffices)
        })
        .finally(() => setLoading(false))
    }
  }, [holidayId, loading, requestAttempted]);

  const onDatesSelected = (date: Moment) => {
    setFromDate(date);
    setIsCalendarOpen(false);
  }

  const onAddClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (!fromDate || !user) {
        return;
      }
      await createCompanyHoliday({
        id: holidayId || 0,
        companyId: user.companyEntity.id,
        date: fromDate.format(DATE_FORMAT),
        label: label,
        companyHolidayOffices: checkedOfficeIds,
      });
      dispatch(redirectTo('/manage/company-holidays'));
    } catch (err: any) {
      dispatch(failureNotification(JSON.parse(err.message).message));
    } finally {
      setLoading(false);
    }
  }

  const onDeleteClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await deleteCompanyHoliday(holidayId);
      dispatch(redirectTo('/manage/company-holidays'));
    } finally {
      setLoading(false);
    }
  }

  const onOfficeSelection = async (state: boolean, office?: OfficeEntity) => {
    if (!office) {
      setCheckOfficeIds([]);
    } else {
      if (checkedOfficeIds.find(o => o === office.id)) {
        setCheckOfficeIds(checkedOfficeIds.filter(o => o !== office.id));
      } else {
        setCheckOfficeIds([...checkedOfficeIds, office.id]);
      }
    }
  }

  return (
    <div className="managePage">
      {loading && <LoadingSpinner fullScreen={true} />}
      <GoBackButton url={'/manage/company-holidays'} />
      <HeadlineLarge style={{marginBottom: 12}}>{holidayId ? 'Edit company holiday' : 'Add company holiday'}</HeadlineLarge>

      {canEdit ? <>

        <BodyRegular style={{marginBottom: 24}}>Holiday year {config.getHolidayYearStartDate().format('Do MMMM')} - {config.getHolidayYearEndDate()?.format('Do MMMM')}</BodyRegular>

        <TextField onChange={setLabel}
                   value={label}
                   fullWidth={true}
                   label={'textinput.holiday-title-or-reason'} />

        <DateInputRow>

          <DateInputField onClick={() => setIsCalendarOpen(true)}
                          placeholder="textinput.add-date" style={{width: '100%'}}
                          value={fromDate ? fromDate.format('D MMMM YYYY') : ''}
                          fullWidth={true}
                          disabled={!!holidayId}
                          label="textinput.date"
                          editableOnly={true}/>
        </DateInputRow>

        {!holidayId && <FullScreenDateRangeSelector onSingleDateSelect={((date) => onDatesSelected(date))}
                                                     open={isCalendarOpen}
                                                     onClose={() => setIsCalendarOpen(false)}
                                                     selectionType={SelectionType.SingleDate}
                                                     initialDate={fromDate} />}


        <BodySmall className="textField__label" weight={600}>{t('holidays.company-holidays-applies-to')}</BodySmall>

        <OfficeRow label={'Company wide holiday (everyone)'}
                   isChecked={!checkedOfficeIds || checkedOfficeIds.length === 0}
                   onChange={(state: boolean) => onOfficeSelection(state, undefined)} />

        {offices.map((office: OfficeEntity, key: number) => (
          <React.Fragment key={key}>
            <OfficeRow office={office}
                       label={office.label}
                       isChecked={!!checkedOfficeIds.find(o => o === office.id)}
                       onChange={(state: boolean) => onOfficeSelection(state, office)} />
          </React.Fragment>
        ))}

        <Center style={{marginBottom: 24, marginTop: 36}}>
          <PrimaryButton click={onAddClick}
                         size={'large'}
                         text={holidayId ? 'button.save-company-holiday' : 'button.add-company-holiday'} />
        </Center>

        {!!holidayId &&
          <Center>
            <BorderlessButton click={onDeleteClick}
                              size={'large'}
                              borderColour={''}
                              icon={IconTypes.Bin}
                              text={'button.delete-holiday'} />
          </Center>
        }
      </> : <>
        <AccessRequired visible={true} />
      </>}

    </div>
  )
}

interface Props {
}
