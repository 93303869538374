import { useEffect, useState } from "react";
import { MeetingRoom } from "../models/MeetingRooms";
import { getMeetingRoomById } from "../../../../services/MeetingRoomsService";

export function LoadMeetingRoom(meetingRoomId: string) {
  const [meetingRoom, setMeetingRoom] = useState<MeetingRoom | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!meetingRoomId || loading || !!meetingRoom) return;

    setLoading(true);
    getMeetingRoomById(meetingRoomId)
      .then(resp => setMeetingRoom(resp))
      .finally(() => setLoading(false));

  }, [loading, meetingRoomId, meetingRoom]);

  return {
    meetingRoomsLoading: loading, meetingRoom,
  }
}
