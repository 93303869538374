import React from 'react';
import './_button.scss';
import styled from "styled-components/macro";
import { NewButtonProps } from "./ButtonStyling";
import Colours from "../Colours";
import { OutlineButton } from "./OutlineButton";
import { desktopSmall } from "../MediaQueries";

export function ToggleableOutlineButton(props: Props) {
  return (
    <Wrapper isActive={props.isActive} isMobileOnly={props?.isMobileOnly ?? false} className="toggleableOutlineButton">
      <OutlineButton {...props} />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  margin-right: 16px;
  @media (${desktopSmall}) {
    margin-right: ${props => props.isMobileOnly ? '0' : '16px'};
  }
  &:last-child {
    margin-right: 0;
  }
  button {
    border-width: ${props => !!props.isActive ? '2px' : '1px'};
    border-color: ${props => !!props.isActive ? Colours.blue : Colours.mildGrey} !important;
    background-color: ${props => !!props.isActive ? Colours.white : Colours.veryLightGrey} !important;
    min-width: unset;
    
    span {
      color: ${props => !!props.isActive ? Colours.blue : Colours.darkGrey} !important;
    }
    
    &:hover {
      border-color: ${Colours.blue} !important;
      background-color: ${Colours.white} !important;
      span {
        color: ${Colours.blue} !important;
      }
    }

    @media (${desktopSmall}) {
      min-width: 120px;
      display: ${props => props.isMobileOnly ? 'none' : 'block'};
    }
  }
`;

interface Props extends NewButtonProps {
  isActive: boolean;
  isMobileOnly?: boolean;
}
