import styled from "styled-components/macro"
import { tablet } from "./MediaQueries";
import Colours from "./Colours";

export const FlatContentCard = styled.div<any>`
  width: 100%;
  padding: 16px 16px 24px 16px;
  background-color: ${props => props.backgroundColour ? props.backgroundColour : Colours.veryLightGrey};
  border-radius: 8px;
  @media (${tablet}) {
    padding: 32px 48px;
    margin: ${props => props.centre ? '0 auto' : '0'};
  }
`
