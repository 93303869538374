import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { CapacityResponse, getAllOfficeCapacity, OfficeWithCapacity } from "../services/OfficeCapacityService";
import { Moment } from "moment";
import { findOfficeInOffices } from "../utils/OfficeHelper";

export function OfficesOfParentWithCapacities(parentOffice: OfficeEntity, activeDate?: Moment) {
  const [capacities, setCapacities] = useState<CapacityResponse[]>([]);
  const allOffices = useSelector(selectAllOfficeEntities);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHadLoaded] = useState(false);

  const officeHasChildren = useMemo(() => {
    return allOffices.filter((o: OfficeEntity) => o.parentId === parentOffice.id).length > 0
  }, [allOffices, parentOffice]);

  useEffect(() => {
    if (!parentOffice || !activeDate || isLoading || capacities.length > 0 || hasLoaded) {
      return;
    }

    if (!officeHasChildren) {
      return;
    }

    setIsLoading(true);
    getAllOfficeCapacity(parentOffice.id, activeDate)
      .then((cr: CapacityResponse[]) => setCapacities(cr))
      .finally(() => {
        setIsLoading(false);
        setHadLoaded(true);
      });
  }, [parentOffice, activeDate, isLoading, officeHasChildren, capacities, hasLoaded]);

  const officesWithCapacities: OfficeWithCapacity[] = useMemo(() => {
    if (allOffices.length === 0 || capacities.length === 0) return [];

    const resp = capacities.map((capacity: CapacityResponse) => {
      const office = findOfficeInOffices(allOffices, capacity.officeId);
      return !!office ? { office, capacity, } : undefined;
    }).filter((owc: OfficeWithCapacity | undefined) => !!owc);

    return resp as OfficeWithCapacity[];
  }, [allOffices, capacities]);


  return {
    loading: isLoading,
    officesWithCapacities: officesWithCapacities,
  };
}
