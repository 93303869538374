import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { findAllOrphanedUsers } from '../../services/ManagementService';
import { updateCompany } from '../../services/CompanyService';
import { TeamWithCount } from '../../models/team.models';
import { failureNotification, successNotification } from './notification.duck';
import { BasicUserInfo } from '../../models/user.models';
import { putUserInTeam } from "../../services/TeamService";
import { loadConfig } from "./config.duck";

export interface ManagementState {
  teams: TeamWithCount[];
  orphanedUsers: BasicUserInfo[];
  deleteUserDialogOpen: boolean;
}

export const initialManagementState: ManagementState = {
  teams: [],
  orphanedUsers: [],
  deleteUserDialogOpen: false,
}

export const loadOrphanedUsers = createAsyncThunk(
  'management/loadOrphanedUsers',
  async () => await findAllOrphanedUsers()
);

export const updateUserTeam = createAsyncThunk('management/updateUserTeam',
  async (payload: { user: BasicUserInfo, teamId: string }, thunkAPI) => {
    try {
      await putUserInTeam(payload.user.userId, payload.teamId);
      await thunkAPI.dispatch(loadOrphanedUsers());
      await thunkAPI.dispatch(loadConfig());
      thunkAPI.dispatch(successNotification('User assigned to team'));
    } catch (err: any) {
      thunkAPI.dispatch(failureNotification('Request failed'));
    }
  }
);

export const updateCompanyName = createAsyncThunk(
  'management/updateCompany',
  async (companyName: any, thunkAPI) => {
    const appState = (thunkAPI.getState() as AppState);
    if (companyName === appState.auth.currentUser?.companyEntity.name) {
      return;
    }
    try {
      await updateCompany({...appState.auth.currentUser?.companyEntity, name: companyName});
      await thunkAPI.dispatch(loadConfig());
    } catch {
      await thunkAPI.dispatch(failureNotification('Failed to update company name'));
    }
  }
);


const slice = createSlice({
  name: 'management',
  initialState: initialManagementState,
  reducers: {
    reset: () => initialManagementState,
    setDeleteUserDialogOpen: (state, action) => ({...state, deleteUserDialogOpen: action.payload}),
  },
  extraReducers: {
  }
})

export default slice.reducer;
export const {
  reset,
  setDeleteUserDialogOpen,
} = slice.actions;

// Selectors
export const selectIsDeleteUserDialogOpen = (state: AppState) => state.management.deleteUserDialogOpen;
export const selectOrphanedUsers = (state: AppState) => state.management.orphanedUsers;
