import React, { useEffect, useMemo, useState } from 'react';
import { TeamWallChart } from '../../../UI/organisms/movements-wallchart/TeamWallChart';
import WallChartHeader from "../../../UI/organisms/movements-wallchart/components/WallChartHeader/WallChartHeader";
import DeskAvailability from "../../../UI/organisms/movements-wallchart/components/desk-availability/DeskAvailability";
import { TeamWithMovements } from "../../../../models/movements.models";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { CurrentUsersWeeklyWhereabouts } from "../../../UI/organisms/CurrentUsersWeeklyWhereabouts";
import { selectAllTags } from "../../../../store/ducks/userTags.duck";
import TagMovementsWallChart from "../../../UI/organisms/movements-wallchart/TagMovementsWallChart";
import { TeamMultiSelectWithFavourites } from "../../../UI/organisms/team-drop-down-list/TeamMultiSelectWithFavourites";
import { selectAllTeams } from "../../../../store/ducks/config.duck";
import { loadWhereabouts, selectWhereabouts } from "../models/team-movements.duck";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { LineReportsWallChart } from "../../../UI/organisms/movements-wallchart/LineReportsWallChart";
import { TeamEntity } from "../../../../models/team.models";
import { Tag } from "../../../../models/tags.models";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { setWhereaboutsLineReportSelected } from "../../../../store/ducks/LineReportsWhereabouts.duck";

export default function TeamMovementsList(props: Props) {
  const dispatch = useDispatch();
  const teams = useSelector(selectAllTeams);
  const workGroups = useSelector(selectAllTags);
  const currentUser = useSelector(selectCurrentUser);
  const whereaboutsGroups = useSelector(selectWhereabouts);
  const calendarDate = useSelector(selectCalendarDate);
  const [lineReportsSelected, setLineReportsSelected] = useState(false);
  const hasLineReportsEnabled = !!currentUser?.companyEntity?.lineManagementEnabled;

  const updatedTeams = useMemo(() => {
    return teams.map((t) => t.id === currentUser?.teamEntity?.id ? ({...t, name: `My team (${t.name})` }) : t);
  }, [teams, currentUser]);

  useEffect(() => {
    if (calendarDate) {
      dispatch(loadWhereabouts({calendarDate: calendarDate}));
    }
  }, [dispatch, calendarDate]);

  useEffect(() => {
    setLineReportsSelected(window.localStorage.getItem('lineReportsSelected') === 'true');
  }, []);

  const onSelect = (team: TeamEntity[], workGroups: Tag[], offices: OfficeEntity[], showLineReports?: boolean) => {
    setLineReportsSelected(!!showLineReports);
    window.localStorage.setItem('lineReportsSelected', (!!showLineReports).toString())
  }

  useEffect(() => {
    dispatch(setWhereaboutsLineReportSelected(lineReportsSelected));
  }, [dispatch, lineReportsSelected]);

  return (
    <div className="teamMovements__content">
      <WallChartHeader/>
      <DeskAvailability className="teamMovements__deskAvailabilityRow--desktop"/>
      <CurrentUsersWeeklyWhereabouts />
      <div style={{maxWidth: '100%', minWidth: 350, marginTop: 24, marginBottom: 24}}>
        <TeamMultiSelectWithFavourites teams={updatedTeams}
                                       workGroups={workGroups}
                                       includeLineReportOption={hasLineReportsEnabled}
                                       isLineReportOptionSelected={lineReportsSelected}
                                       onSelect={onSelect}
                                       reloadTeamWhereaboutsOnSelect={true} />
      </div>

      {lineReportsSelected && <LineReportsWallChart enabledSetMovements={true} />}

      {whereaboutsGroups.teams.map((teamMovements, key) => (
        <TeamWallChart key={key}
                       movementsViewObject={teamMovements}
                       disableAccordian={true}
                       showHeader={true}
                       showFooter={true}
                       enabledSetMovements={true}/>
      ))}
      {whereaboutsGroups.workgroups.map((tagMovements, key) => (
        <TagMovementsWallChart key={key}
                               tagWithMovements={tagMovements}
                               showHeader={true}
                               showFooter={true} />
      ))}
    </div>
  )
}

interface Props {
  teamMovements?: TeamWithMovements;
}
