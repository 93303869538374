import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { deleteWhereaboutsOption, getAllWhereaboutsOptions, WhereaboutsOption } from "../../services/WhereaboutOptions";

export enum LoadingState {
  Idle, Success, Failed, Pending,
}

export interface WhereaboutsOptionsState {
  loadingState: LoadingState;
  whereaboutsOptions: WhereaboutsOption[];
  editingWhereaboutsOptions?: WhereaboutsOption;
}

export const initialWhereaboutsOptionsState: WhereaboutsOptionsState = {
  loadingState: LoadingState.Idle,
  whereaboutsOptions: [],
}

export const loadWhereaboutsOptions: any = createAsyncThunk(
  'whereaboutsOptions/loadWhereaboutsOptions',
  async (force: boolean, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState).whereaboutsOptions;
    if (state.loadingState === LoadingState.Idle || force) {
      thunkAPI.dispatch(setLoadingState(LoadingState.Pending));
      const options = await getAllWhereaboutsOptions();
      return options;
    } else {
      return state.whereaboutsOptions;
    }
  }
)

export const deleteWhereabouts: any = createAsyncThunk(
  'whereaboutsOptions/deleteWhereabouts',
  async (option: WhereaboutsOption, thunkAPI) => {
    thunkAPI.dispatch(setLoadingState(LoadingState.Pending));
    await deleteWhereaboutsOption(option);
    thunkAPI.dispatch(loadWhereaboutsOptions(true));
  }
)

const whereaboutsOptionsSlice = createSlice({
  name: 'whereaboutsOptions',
  initialState: initialWhereaboutsOptionsState,
  reducers: {
    setLoadingState: (state, action) => ({...state, loadingState: action.payload}),
    setEditingWhereaboutsOption: (state, action) => ({...state, editingWhereaboutsOptions: action.payload}),
    setWhereaboutsOptions: (state, action) => ({...state, whereaboutsOptions: action.payload}),
    clearEditingWhereaboutsOption: (state) => ({...state, editingWhereaboutsOptions: undefined}),
  },
  extraReducers: {
    [loadWhereaboutsOptions.fulfilled]: (state, action) => ({...state, whereaboutsOptions: action.payload, loadingState: LoadingState.Success}),
    [loadWhereaboutsOptions.rejected]: (state) => ({...state, loadingState: LoadingState.Failed}),
  }
});

export const {
  setLoadingState,
  setEditingWhereaboutsOption,
  clearEditingWhereaboutsOption,
  setWhereaboutsOptions,
} = whereaboutsOptionsSlice.actions;
export default whereaboutsOptionsSlice.reducer;

// Selectors
export const selectLoadingState = (state: AppState) => state.whereaboutsOptions.loadingState;
export const selectAllSelectableWhereaboutsOptions = (state: AppState) => {
  return state.whereaboutsOptions.whereaboutsOptions.filter(option => option.pickable && (option.required || option.enabled))
};
export const selectAllWhereaboutsOptions = (state: AppState) => state.whereaboutsOptions.whereaboutsOptions;
export const selectWhereaboutsOptionByKey = (state: any, key: string) => {
  return state.whereaboutsOptions.whereaboutsOptions.find((option: any) => option.key === key);
};
export const selectWhereaboutsOptionByStatus = (state: any, status: string) => {
  return state.whereaboutsOptions.whereaboutsOptions.find((option: any) => option.status === status);
};
export const selectEditingWhereaboutsOption = (state: AppState) => state.whereaboutsOptions.editingWhereaboutsOptions;

