import React from 'react';
import styled from "styled-components/macro";
import { Pill } from "../../../../../UI/atoms/Pill";
import { BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import Colours from "../../../../../UI/atoms/Colours";

export function VisitorBookingColumnHeaders(props: Props) {
  return (
    <Container>
      <PillWrapper style={{marginLeft: 10}} colour={Colours.white} backgroundColour={Colours.darkBlue}>
        <BodyVerySmall>Time In</BodyVerySmall>
      </PillWrapper>
      <PillWrapper style={{marginLeft: 60}} colour={Colours.white} backgroundColour={Colours.darkBlue}>
        <BodyVerySmall>Visitor</BodyVerySmall>
      </PillWrapper>
      <PillWrapper style={{marginLeft: 260}} colour={Colours.white} backgroundColour={Colours.darkBlue}>
        <BodyVerySmall>Meeting With</BodyVerySmall>
      </PillWrapper>
    </Container>
  )
}

interface Props {
}

const PillWrapper = styled<any>(Pill)`
  padding: 8px 12px;
`

const Container = styled.div`
  display: flex;
  border-radius: 62px;
  margin-bottom: 24px;
  background-color: white;
  padding: 10px 12px 8px 12px;
  margin-right: 24px;
`
