import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { BodyLarge, BodyRegular } from "../atoms/fonts/Body";
import { selectIsAdvanceHotDeskingLoading } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { CapacityResponse } from "../../../services/OfficeCapacityService";

interface Props {
  onClick: any;
  floor: OfficeEntity;
  activeDate: Moment;
  capacity: CapacityResponse;
}

export default function FloorLocationContentCard(props: Props) {
  const {capacity} = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsAdvanceHotDeskingLoading);
  const {t} = useTranslation();

  const remainingCapacity =  ((capacity.capacity ?? 0) - (capacity.amUsed ?? 0));

  const click = () => {
    if (remainingCapacity > 0) {
      props.onClick();
    } else if (remainingCapacity < 1) {
      dispatch(failureNotification('That floor is full'));
    } else {
      TrackJS?.track('Unknown error - floorLocationContentCard')
      dispatch(failureNotification('Unknown error'));
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner fullScreen={true}/>}
      <OfficeContentCard onClick={click} data-test={'floor-location-content-card'}>
        <OfficeDetails>
          <BodyLarge weight={600} style={{lineHeight: '26px'}}>{props.floor.label}</BodyLarge>
          <BodyRegular>{t('whereabouts.seats-available', {remaining: remainingCapacity})}</BodyRegular>
        </OfficeDetails>
      </OfficeContentCard>
    </>
  );
}

const OfficeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 16px;
`

const OfficeContentCard = styled.div`
  box-shadow: 0px 9px 22px 0px rgba(46, 74, 148, 0.08);
  padding: 24px 32px;
  border-radius: 12px;
  border: 1px solid ${Colours.lightGrey};
  background: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  margin: 0 8px 24px 8px;
  position: relative;
`
