import React from 'react';
import { SubHeaderContainer, SubHeaderContent, SubHeaderMenuItem } from "./ViewComponents";
import { BodyRegular } from "../../../../atoms/fonts/Body";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";

export function LeavePlannerSubHeader(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation();

  return (
    <SubHeaderContainer fullScreenView={props.fullScreenView}>
      <SubHeaderContent>
        <SubHeaderMenuItem selected={location.pathname.includes('/holidays/leave-planner')}
                           onClick={() => dispatch(redirectTo('/holidays/leave-planner'))}>
          <BodyRegular weight={600}>Leave planner</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/holidays/me')}
                           onClick={() => dispatch(redirectTo('/holidays/me'))}>
          <BodyRegular weight={600}>Holidays</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/holidays/view-approvals')}
                           onClick={() => dispatch(redirectTo('/holidays/view-approvals'))}>
          <BodyRegular weight={600}>{t('button.view-approvals')}</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/holidays/reports')}
                           onClick={() => dispatch(redirectTo('/holidays/reports'))}>
          <BodyRegular weight={600}>{t('header.reports')}</BodyRegular>
        </SubHeaderMenuItem>
      </SubHeaderContent>
    </SubHeaderContainer>
  )
}

interface Props {
  fullScreenView?: boolean;
}
