import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../UI/molecules/Dialog';
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import './delete-user-dialog.scss';
import { UserAvatarAndName } from "../../UI/molecules/UserAvatarAndName";
import { selectIsDeleteUserDialogOpen, setDeleteUserDialogOpen } from "../../../store/ducks/management.duck";
import { BasicUserInfo } from "../../../models/user.models";
import { deleteUserRequest } from "../../../services/ManagementService";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { NotificationMessages, redirectWithNotification } from "../../../models/notification.models";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { useTranslation } from "react-i18next";
import { Center } from "../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";

export default function DeleteUserDialog(props: { userInfo: BasicUserInfo }) {
  const isOpen = useSelector(selectIsDeleteUserDialogOpen);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const close = () => {
    dispatch(setDeleteUserDialogOpen(false));
  }

  const deleteUser = async () => {
    try {
      setIsLoading(true);
      await deleteUserRequest(props.userInfo.userId);
      redirectWithNotification(NotificationMessages.userDeleted, "/manage/users?view=user", true)
    } catch (e: any) {
      console.error(e)
      dispatch(failureNotification("User failed to be deleted"))
    } finally {
      setIsLoading(false)
    }
  }

  if (!props.userInfo) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => close()} className="deleteUserDialog">
      {isLoading ?
        <LoadingSpinner hideBorder={true}/> :
        <>
          <HeadlineLarge style={{marginBottom: 12}}>{t('delete-user-dialog.title')}</HeadlineLarge>
          <BodyRegular>{t('delete-user-dialog.description')}</BodyRegular>
          <Center style={{margin: '16px 0px 40px 0px'}}>
            <UserAvatarAndName className="deleteUserDialog__user" firstName={props.userInfo.firstName}
                               lastName={props.userInfo.lastName}/>
          </Center>
          <PrimaryButton className="deleteUserDialog__button"
                         text="button.confirm-delete-user"
                         fullWidth={true}
                         size="large"
                         click={deleteUser}/>
          <OutlineButton text="button.reject-delete-user" fullWidth={true} size="large" click={close}/>
        </>
      }
    </Dialog>
  )
}
