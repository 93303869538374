import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BodySmall } from '../../../UI/atoms/fonts/Body';
import { HeadlineLarge } from '../../../UI/atoms/fonts/Headline';
import Header from '../../../UI/organisms/header/Header';
import Icon, { IconTypes } from '../../../UI/atoms/icon/Icon';
import { FadeAndPopOnLoad } from '../../../../animations/AnimationVariants';
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { Center } from '../../../UI/atoms/StructuralLayout';
import { useTranslation } from "react-i18next";


interface Props {
  dataTest: string;
  children: any;
  title?: string;
  icon?: IconTypes;
  logoUrl?: string;
  spinner?: boolean;
  hideLoginLink?: boolean;
  hideHeader?: boolean;
}

export default function AuthWrapper(props: Props) {
  const {t} = useTranslation();

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
  }, []);

  return (
    <div className="auth__page" {...{'data-test': props.dataTest ?? ''}}>
      {props.hideHeader ? null : <Header/>}

      <FadeAndPopOnLoad className="auth__container">
        {!!props.icon ? <Icon icon={props.icon} circle={true} color="blue" size="large" className="auth__icon"/> : null}
        {!!props?.logoUrl && <img className={"auth__headerImage"} alt={""} src={`/assets/images/logos/${props.logoUrl}`} />}
        {props.spinner ?
          <Center>
            <LoadingSpinner className="auth__icon" hideBorder={true} hideText={true}/>
          </Center> : null}

        {props.title && <HeadlineLarge data-test={`${props.dataTest}-title`} className="auth__title">{t(props.title)}</HeadlineLarge>}

        <div className="forgotPassword__content">
          {props.children}
        </div>

      </FadeAndPopOnLoad>

      {props.hideLoginLink ? null :
        <div className="auth__backLink">
          <BodySmall weight={600}>{t('login.have-an-account')} <Link to={'/login?splash=hide'}>{t('general.login')}</Link></BodySmall>
        </div>}
    </div>
  );
}

