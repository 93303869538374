import moment, { Moment } from "moment";
import { ApprovalState } from "../../../../models/approval-requests.models";
import { CompanyHoliday } from "../../../../services/CompanyHolidayService";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { Period } from "../../../../models/movements.models";


export interface HolidayDay {
  status: string;
  date: string;
  am: boolean;
  pm: boolean;
  userId: string;
  state?: ApprovalState;
}

export interface HolidayGrouping {
  fromDate: string;
  toDate: string;
  userId: string;
  holidayBookingList: HolidayDay[];
  numberOfDays: number;
}

export interface UserHolidayInfoResponse {
  holidayAllowance: number;
  holidaysTaken: number;
  holidayBookings: HolidayDay[];
  holidayGroupings: HolidayGrouping[];
  companyHolidays: CompanyHoliday[];
  firstName: string;
  lastName: string;
  teamName?: string;
  userId: string;
}

export function holidayToHighlightedDayConverter(holiday: HolidayDay) {
  return {
    date: moment(holiday.date),
    am: holiday.am,
    pm: holiday.pm,
    color: holiday.state === ApprovalState.pending ? 'yellow' : 'blue',
  }
}

export function companyHolidayToHighlightedDayConverter(holiday: CompanyHoliday) {
  return {
    date: moment(holiday.date),
    am: 'HOLIDAY',
    pm: 'HOLIDAY',
    color: 'lightGreen',
  }
}

export class HolidayBookingRequest {
  public date: string;
  public am: boolean;
  public pm: boolean;
  constructor(private bookingDate: Moment, public userId: string, period: Period) {
    this.date = bookingDate.format(DATE_FORMAT);
    this.am = period === Period.AllDay || period === Period.AM;
    this.pm = period === Period.AllDay || period === Period.PM;
  }
}

export interface HolidayGroupBookingRequest {
  holidayBookingList: HolidayBookingRequest[];
  originalDates?: string[];
}

export interface UsersHolidayInfo {
  userId: string;
  firstName: string;
  lastName: string;
  holidayAllowance: number;
  daysUsed: number;
  daysApproved: number;
  daysPending: number;
}

