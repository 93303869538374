import styled, { css } from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";

export const WEEK_WIDTH = 230;
export const DAY_WIDTH = WEEK_WIDTH / 7;
export const ROW_HEIGHT = 60;
export const USER_ROW_HEIGHT_MOBILE = 100;
export const PROJECT_ROW_HEIGHT = 32;

export const WallPlannerRow = styled.div`
  display: flex;
  position: relative;
`

export const WeekCell = styled.div`
  display: flex;
  flex: 1;
  min-width: ${WEEK_WIDTH}px;
  max-width: ${WEEK_WIDTH}px;
`

export const DayCell = styled.div<any>`
  flex: 1;
  height: 100%;
  ${props => css`background-color: ${props.weekend ? Colours.veryLightGrey : 'white'};`};
  ${props => css`background-color: ${props.weekend ? Colours.veryLightGrey : 'white'};`};
`

export const BorderDayCell = styled<any>(DayCell)<any>`
  border-right: 1px solid #DEDEDE;
`
