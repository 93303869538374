import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../UI/organisms/layout/Layout';
import Calendar from '../../UI/molecules/calendar/Calendar';
import TeamMovementsList from './components/TeamMovementsList';
import './styles/team-movements.scss';
import { AddTeamMemberButton } from "../../UI/atoms/buttons/AddTeamMemberButton";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { Column, Row } from '../../UI/atoms/StructuralLayout';
import { selectCalendarDate, setCalendarDate } from "../../../store/ducks/dashboard.duck";
import { VerticalSpacer } from "../../UI/atoms/VerticalSpacer";
import ReactGA from "react-ga";
import { isInTeams } from "../../../utils/TeamsUtils";
import { PersonalPreferencesRowLink } from '../../UI/molecules/PersonalPreferencesRowLink';
import { WeekNotesRowLink } from "../../UI/molecules/WeekNotesRowLink";
import { MeetingRoomRowLink } from "../../UI/molecules/MeetingRoomRowLink";
import { showNewUserOnboardingButton } from "../../../services/EnvironmentVariables";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialog } from "../../../store/ducks/dialog.duck";
import { PageTitle } from "../../UI/atoms/PageTitle";
import { MicrosoftCalendarSyncActiveIndicator } from "../../UI/organisms/MicrosoftCalendarSyncActiveIndicator";

export default function MyTeamMovementsPage() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const calendarDate = useSelector(selectCalendarDate);
  const onCompleteAccountClick = () => dispatch(openDialog(DialogIdentifiers.NewUserOnboardingDialog));

  useEffect(() => {
    if (isInTeams()) {
      ReactGA.event({category: 'MsTeams', action: 'signed in'});
    }
  }, [dispatch])

  return <>
    <Layout>
      <Row className="new-teamMovements">
        <Column className="teamMovementsContent" style={{maxWidth: current?.companyEntity.sevenDayWhereaboutsWeekEnabled ? 1100 : 1000}}>
          <PageTitle text={'team-view.team-view'} />
          <TeamMovementsList />
          {current?.teamEntity && <AddTeamMember className="teamMovements__add--mobile"/>}
        </Column>
        <VerticalSpacer />
        <Column className="teamMovementsSidebar">
          {showNewUserOnboardingButton && <OutlineButton text={"button.finish-setting-up-your-account"}
                                                         style={{marginBottom: 24}}
                                                         click={onCompleteAccountClick}
                                                         fullWidth={true}
                                                         size={"medium"} />}

          <Calendar initialDate={calendarDate}
                    highlightToday={true}
                    onDateSelect={(date) => dispatch(setCalendarDate(date))}/>

          <Column className={"teamMovements__sideBarOptions"}>
            {current?.teamEntity && <AddTeamMember/>}
            {current?.companyEntity.weeklyNotesEnabled && <WeekNotesRowLink />}
            <MeetingRoomRowLink />
            <PersonalPreferencesRowLink />
          </Column>
          <MicrosoftCalendarSyncActiveIndicator />
        </Column>
      </Row>
    </Layout>
  </>
}

function AddTeamMember(props: { className?: string }) {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <Column className={`addTeamMember ${props.className ?? ''}`.trim()} >
      <AddTeamMemberButton team={currentUser?.teamEntity}/>
    </Column>
  )
}


