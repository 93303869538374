import React, { useEffect, useMemo, useRef } from 'react';
import { AuthType, clearLoginStorage, HandleAuthenticationResult, LoginWithRedirect } from "../../../utils/AuthUtils";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setAuthLoading } from "../../../store/ducks/auth.duck";
import { AuthenticationResult } from "@azure/msal-common";
import { microsoftRequest } from "../../../services/EnvironmentVariables";
import { isInTeams } from "../../../utils/TeamsUtils";
import {
  NotificationMessages,
  parseErrorResponse,
  redirectWithNotification
} from "../../../models/notification.models";
import { getParameterByName } from "../../../utils/UrlUtils";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import Colours from "../../UI/atoms/Colours";

export function MicrosoftLoginButton(props: Props) {
  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();
  const timerId = useRef(0);
  const errorNotificationCode = useMemo(() => getParameterByName('not', window.location.href), []);

  const loginWithMsTeams = async () => {
    dispatch(setAuthLoading(true));

    try {
      console.log('MicrosoftLoginButton')
      const result = await instance.acquireTokenSilent({...microsoftRequest.login});
      await HandleAuthenticationResult(result, dispatch, true);
    } finally {
      dispatch(setAuthLoading(false));
    }
  }

  const loginWithMs = async () => {
    try {
      dispatch(setAuthLoading(true));

      if (accounts.length === 1 && errorNotificationCode !== NotificationMessages.azureLoginFailed) {
        const account = accounts[0];
        try {
          // Faster login
          console.log('MLB.loginWithMs')
          const result: AuthenticationResult = await instance.acquireTokenSilent({
            ...microsoftRequest.login,
            account: account,
            forceRefresh: true,
          });
          await HandleAuthenticationResult(result, dispatch, true);

        } catch (ex: any) {
          if (ex.statusCode === 401) {
            redirectWithNotification(parseErrorResponse(ex.message, NotificationMessages.azureLoginFailed), '/login');
          } else {
            await LoginWithRedirect(instance, dispatch, true);
          }
        }
      } else {
        await LoginWithRedirect(instance, dispatch, true);
      }

    } finally {
      dispatch(setAuthLoading(false));
    }
  }

  const onClick = () => {
    clearLoginStorage();
    localStorage.setItem('type', AuthType.Microsoft)
    if (isInTeams()) {
      loginWithMsTeams()
    } else {
      loginWithMs();
    }
  }

  useEffect(() => {
    return () => {
      if (timerId.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timerId.current);
      }
    }
  }, []);

  return (
    <>
      <OutlineButton dataTest="outlookLogin"
                     className="login__button"
                     text="login.with-office365"
                     borderColour={Colours.black}
                     click={() => onClick()}
                     fullWidth={true} size="large"
                     imageUrl="/assets/icons/microsoft-office-logo.svg"/>
    </>
  )
}

interface Props {
  onFailure: () => any;
}
