import React, { useEffect, useMemo, useState } from 'react';
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { UserAvatarAndName } from "../../../../../UI/molecules/UserAvatarAndName";
import { Tag, UserTag } from "../../../../../../models/tags.models";
import { useDispatch, useSelector } from "react-redux";
import { SimpleUser } from "../../../../../../models/user.models";
import {
  loadAllUserTags,
  selectAllUserTags,
  selectFilteredUsers,
} from "../../../../../../store/ducks/userManagement.duck";
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import styled from "styled-components";
import { SimpleSpinner } from "../../../../../UI/atoms/SimpleSpinner";
import { createUserTagRequest, removeUserTagRequest } from "../../../../../../services/Tags";
import { failureNotification, successNotification } from "../../../../../../store/ducks/notification.duck";
import { HeadlineLarge } from "../../../../../UI/atoms/fonts/Headline";
import { Row } from "../../../../../UI/atoms/StructuralLayout";
import { UserManagementSearchFilter } from "./UserManagementSearchFilter";
import { OutlineChip, PrimaryChip } from "../../../../../UI/atoms/Chip";
import { DialogIdentifiers, openDialog, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";
import { selectAllTags } from "../../../../../../store/ducks/userTags.duck";
import { tablet } from "../../../../../UI/atoms/MediaQueries";
import { useTranslation } from "react-i18next";

export function TagManager(props: Props) {
  const dispatch = useDispatch();
  const allUsers = useSelector(selectFilteredUsers);
  const allTags = useSelector(selectAllTags);
  const allUserTags = useSelector(selectAllUserTags);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(loadAllUserTags());
  }, [dispatch]);

  const findUserTag = (tag: Tag, user: SimpleUser): UserTag | undefined => {
    return allUserTags.find(userTag => userTag.tagId === tag.id && userTag.userId === user.userId)
  }

  const TagCell = (props: {tag: Tag, user: SimpleUser}) => {
    const [loading, setLoading] = useState(false);
    const {tag, user} = props;
    const userTag = useMemo(() => findUserTag(tag, user), [tag, user])
    const onClick = async () => {
      try {
        setLoading(true);
        if (userTag) {
          await removeUserTagRequest(user.userId, tag.id);
        } else {
          await createUserTagRequest(user.userId, tag.id)
        }
        dispatch(loadAllUserTags());
        await new Promise((resolve: any) => setTimeout(() => resolve(), 500))
        dispatch(successNotification('Tag updated'));
      } catch (e: any) {
        dispatch(failureNotification('Failed to update tag'));
      } finally {
        setLoading(false);
      }
    }
    return <TagCellContainer isActive={!!userTag || loading} onClick={onClick}>
      {loading ? <SimpleSpinner size={24} /> : <Icon icon={!!userTag ? IconTypes.Tick : IconTypes.Add}
                                                     color={!!userTag ? "greenFill" : "greyFill"}
                                                     size="small" circle={true} />}

    </TagCellContainer>
  }

  return (
    <div>
      <HeadlineRow style={{marginBottom: 36}}>
        <HeadlineLarge style={{marginRight: 16}}>{t('tag-manager')}</HeadlineLarge>
        <Row>
          <PrimaryChip label="button.add-new-tag" onClick={() => dispatch(openDialogWithPayload({ payload: { }, activeDialog: DialogIdentifiers.CreateTagDialog, }))} icon={IconTypes.Add} />
          <OutlineChip label="button.edit-tags" onClick={() => dispatch(openDialog(DialogIdentifiers.EditTagDialog))} icon={IconTypes.Edit} />
        </Row>
      </HeadlineRow>
      <UserManagementSearchFilter />
      <TagManagerTableContainer className="tableWrapper">
        <table>
          <thead>
          <tr>
            <th className="table__userCell"><BodyRegular weight={600}>{t('User')}</BodyRegular></th>
            {allTags.map((tag: Tag, key: number) => (<th key={key} className="table__infoCell"><BodyRegular weight={600}>{tag.label}</BodyRegular></th>))}
          </tr>
          </thead>
          <tbody>
          {allUsers.map((user: SimpleUser, key: number) => (
            <tr key={key}>
              <th className="table__userCell">
                <UserAvatarAndName colour="blue" firstName={user.firstName} lastName={user.lastName}
                                   showEditLink={true} userId={user.userId} />
              </th>
              {allTags.map((tag: Tag, key: number) => (<th key={key} className="table__infoCell">
                <TagCell tag={tag} user={user} />
              </th>))}
            </tr>
          ))}
          </tbody>
        </table>
      </TagManagerTableContainer>
    </div>
  )
}

interface Props {
}

const TagCellContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
  ${props => props.isActive ? 'opacity: 1;' : 'opacity: 0;'};
  &:hover {
    ${props => props.isActive ? '' : 'opacity: 1;'};
  }
`

const TagManagerTableContainer = styled.div<any>`
  overflow: auto;
`

const HeadlineRow = styled.div`
  display: flex;
  flex-direction: column;
  .chip {
    margin-right: 16px;
  }
  h1 {
    margin-bottom: 16px;
  }
  @media (${tablet}) {
    align-items: center;
    flex-direction: row;
    .chip {
      margin-left: 16px;
    }
    h1 {
      margin-bottom: 0;
    }
  }
`
