import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import {promiseMiddleware} from './middleware';
import createRootReducer from './reducers'
import {initialAppState} from './state/app.state';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
// import {logger} from "redux-logger";

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {

  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  })

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        promiseMiddleware,
        thunk,
        // localStorageMiddleware,
        // logger,
      ),
    ),
  )

  return store;
}

export const store = configureStore(initialAppState);
