import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectMenuOpen, setMenuOpen } from "../../../../../../store/ducks/general.duck";
import { Link, useLocation } from "react-router-dom";
import Icon, { IconTypes } from "../../../../atoms/icon/Icon";
import { BodyVerySmall } from "../../../../atoms/fonts/Body";
import { CurrentUserAvatarAndName } from "../../../../molecules/UserAvatarAndName";
import DropDownList from "../drop-down-list/DropDownList";
import './user-menu.scss';
import Colours from "../../../../atoms/Colours";
import { VerticallyCenteredRow } from '../../../../atoms/StructuralLayout';
import NotificationCounter from '../NotificationCounter';
import { useTranslation } from "react-i18next";

export default function UserMenu() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(selectMenuOpen);
  const {t} = useTranslation();

  const open = () => dispatch(setMenuOpen(!isMenuOpen));
  return (
    <>

      {isMenuOpen ? <div className="userMenu__closeContainer" onClick={() => dispatch(setMenuOpen(false))}/> : null}
      <nav className={`row navigation userMenu`}>
        <div className={`userMenu__item userMenu__manage ${location.pathname.includes('/manage') ? 'userMenu__manage--selected' : ''}`.trim()}>
          <Link to="/manage" data-test="userMenu-manage-link" className="noDecoration">
            <VerticallyCenteredRow>
              <Icon className="userMenu__icon" icon={IconTypes.Settings} color="white"/>
              <BodyVerySmall weight={600} colour={Colours.white} className="userMenu__label">{t('settings.settings')}</BodyVerySmall>
            </VerticallyCenteredRow>
          </Link>
        </div>

        <NotificationCounter count={0}>
          <CurrentUserAvatarAndName onClick={() => dispatch(setMenuOpen(!isMenuOpen))}
                                    hideName={true}
                                    colour="blue" />
        </NotificationCounter>

        <div className={isMenuOpen ? 'userMenu__dropdown--open' : ''}>
          <Icon className="userMenu__dropDownButton"
                icon={isMenuOpen ? IconTypes.Close : IconTypes.Menu}
                color={'white'}
                onClick={open} />
          <DropDownList/>
        </div>
      </nav>
    </>
  )
}
