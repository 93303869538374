import React, { useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Colours from "../../../UI/atoms/Colours";
import { FixedWhereabouts, WhereaboutsOption } from "../../../../services/WhereaboutOptions";
import { whereaboutsToColour } from "../../../../utils/RandomColour";
import styled from "styled-components/macro";
import { CustomToolTip } from "./CustomToolTip";
import { roundUpNearest10 } from "../../../../utils/MathUtils";
import { Checkbox } from "../../../UI/atoms/Checkbox";
import { ColourCircle } from "../../../UI/atoms/ColourCircle";
import { desktopSmall, desktopWide, fromMobile } from "../../../UI/atoms/MediaQueries";

export function WhereaboutsSummaryBarChart(props: Props) {
  const {reportData, keyTableData} = props;
  const [activeOptions, setActiveOptions] = useState<string[]>(['Holiday', 'Office', 'Working from home']);
  const options = useMemo(() => {
    let opts = keyTableData.filter(wo => wo.enabled && wo.label && wo.key !== FixedWhereabouts.Unknown);
    return opts.sort((a, b) => a.required ? -1 : 1);
  }, [keyTableData]);

  const onToggleButtonClick = (value: WhereaboutsOption) => {
    if (activeOptions.includes(value.label)) {
      setActiveOptions(activeOptions.filter(si => si !== value.label))
    } else {
      setActiveOptions([...activeOptions, value.label])
    }
  }

  return (
    <>
      <div style={{height: 400}}>
        <ResponsiveContainer width="100%" height="100%" className={"whereaboutsSummaryBarChart"}>
          <BarChart
            width={500}
            height={400}
            data={reportData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke={Colours.mildGrey} />

            <XAxis dataKey="name"
                   fontFamily={'proxima-nova'}
                   fill={Colours.darkGrey}
                   fontWeight={400}
                   fontSize={14}
                   xAxisId="0"
                   dy={4}
                   axisLine={false} />

            <XAxis dataKey="dateOfMonth"
                   fontFamily={'proxima-nova'}
                   fill={Colours.darkGrey}
                   fontWeight={700}
                   fontSize={14}
                   dy={-10}
                   xAxisId="1"
                   axisLine={false} />

            <YAxis fontFamily={'proxima-nova'}
                   fill={Colours.veryLightGrey}
                   fontSize={14}
                   axisLine={false}
                   domain={[0, roundUpNearest10(props.maxValueOfYAxis)]}  />

            <Tooltip content={<CustomToolTip title={"Whereabouts"} />} />

            {options.map((option, key) =>
              <Bar key={key}
                   dataKey={option.label}
                   hide={!activeOptions.includes(option.label)}
                   label={option.label}
                   stackId="a"
                   fill={whereaboutsToColour(options, option.key)} />)}

          </BarChart>

        </ResponsiveContainer>
      </div>

      <ToggleContainer>
        {options.map((value, key) => (
          <ToggleWrapper key={key}>
            <ColourCircle style={{marginRight: 12}} color={whereaboutsToColour(options, value.key)} />
            <Checkbox value={activeOptions.includes(value.label)}
                      onChange={() => onToggleButtonClick(value)}
                      text={value.label} />
          </ToggleWrapper>
        ))}
      </ToggleContainer>
    </>
  )
}

interface Props {
  reportData: any[];
  keyTableData: WhereaboutsOption[];
  maxValueOfYAxis: number;
}

const ToggleContainer = styled.div`
  & > * {
    margin: 0 0 12px 0;
    cursor: pointer;
    user-select: none;
  }
`

const ToggleWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  @media (${fromMobile}) {
    width: 50%;
  }
  @media (${desktopSmall}) {
    width: 33.3333%;
  }
  @media (${desktopWide}) {
    width: 25%;
  }
`
