import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { DeskSpotOnMap } from "../../data/OfficeMap.model";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";

export function BookedDeskHoverPopup(props: Props) {
  const {x, y, deskSpot} = props;
  const isAllDay = !!deskSpot.amReservation.userId && !!deskSpot.pmReservation.userId && deskSpot.amReservation.userId === deskSpot.pmReservation.userId;
  const isAm = !!deskSpot.amReservation.userId;
  const isPm = !!deskSpot.pmReservation.userId;

  return (
    <Wrapper x={x} y={y}>
      {isAllDay ? <>
        <BodyRegular colour={Colours.white}
                     weight={600}>{`${deskSpot.amReservation.firstName} ${deskSpot.amReservation.lastName}`}</BodyRegular>
      </> : <>
        <BodyRegular colour={Colours.white}
                     weight={600}>AM: {isAm ? `${deskSpot.amReservation.firstName} ${deskSpot.amReservation.lastName}` : 'N/A'}</BodyRegular>
        <BodyRegular colour={Colours.white}
                     weight={600}>PM: {isPm ? `${deskSpot.pmReservation.firstName} ${deskSpot.pmReservation.lastName}` : 'N/A'}</BodyRegular>
      </>}

      <BodyRegular colour={Colours.white}>{`${deskSpot.label}`}</BodyRegular>
    </Wrapper>
  )
}

interface Props {
  deskSpot: DeskSpotOnMap;
  x: number;
  y: number;
}


const Wrapper = styled.div<any>`
  min-width: 100px;
  min-height: 80px;
  position: absolute;
  padding: 12px;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  background-color: ${Colours.darkBlue};
  box-shadow: 0px 16px 22px 0px rgba(48, 48, 50, 0.23);
  border-radius: 8px;
  z-index: 9999999;
`
