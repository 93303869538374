import React from 'react';
import Dialog from '../../UI/molecules/Dialog';
import './welcome-dialog.scss';
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import { closeFirstTimeView, selectCurrentUser } from "../../../store/ducks/auth.duck";
import { LinkButton } from "../../UI/atoms/buttons/LinkButton";
import { useTranslation } from "react-i18next";

export default function WelcomeDialog() {
  const current = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const done = () => {
    dispatch(closeFirstTimeView());
  }
  return (
    <Dialog isOpen={current?.firstTime ?? false}>
      <div>
        <img className="welcomeDialog__image" aria-hidden={true} src="/assets/images/welcome-image.svg" alt="welcome image" />

        <HeadlineMedium className="welcomeDialog__title">{t('onboarding.welcome')}</HeadlineMedium>
        <BodyRegular className="welcomeDialog__description">{t('onboarding.welcome-to-existing-company')}</BodyRegular>

        <LinkButton dataTest="welcome-got-it-btn"
                    className="welcomeDialog__button"
                    text="button.got-it"
                    fullWidth={true}
                    link="/whereabouts/team"
                    size="large"
                    onClick={() => done()} />
      </div>
    </Dialog>
  )
}
