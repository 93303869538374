import React, { useMemo } from 'react';
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import { Row } from "../../../UI/atoms/StructuralLayout";
import Colours from "../../../UI/atoms/Colours";

interface Props extends TooltipProps<ValueType, NameType> {
  title?: string;
}

export function CustomToolTip(props: Props) {
  const {active, payload, title} = props;
  const date = useMemo(() => {
    if (payload && payload.length > 0 && payload[0].payload.toolTipTitle) {
      return payload[0].payload.toolTipTitle;
    }

    return title ?? '';
  }, [payload, title]);

  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <Title>
          <BodyLarge weight={600}>{date}</BodyLarge>
        </Title>
        {payload.map((value: any, key) => (
          <Row key={key}>
            <ColourCircle style={{backgroundColor: value.color}} />
            <BodyRegular style={{textTransform: 'capitalize'}}>{value.name}: {value.payload[value.dataKey]}</BodyRegular>
          </Row>
        ))}
      </ToolTipContainer>
    )
  }
  return null;
}

const ToolTipContainer = styled.div`
  background-color: white;
  padding: 24px 32px;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  min-width: 200px;
`

const Title = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  padding-bottom: 8px;
  margin-bottom: 16px;
`

const ColourCircle = styled.div`
  align-self: center;
  justify-self: center;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
`

