import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import Dialog from "../../../UI/molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { LoadingOverlay } from "../../../UI/molecules/LoadingOverlay";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import { TeamEntity } from "../../../../models/team.models";
import { Tag } from "../../../../models/tags.models";
import { selectAllTags } from "../../../../store/ducks/userTags.duck";
import TeamMultiSelect from "../../../UI/organisms/team-drop-down-list/TeamMultiSelect";
import { updateDeskOwnershipsForWorkgroups } from "../../../../services/DeskOwnershipService";
import { clearSelectedDeskIds, selectSelectedDeskIds } from "../ducks/manageDesks.duck";
import { successNotification } from "../../../../store/ducks/notification.duck";
import { fetchDesks } from "../../../../store/ducks/deskBooking.duck";
import { selectSelectedOffice } from "../../../../store/ducks/officeView.duck";

export function AssignDesksToWorkgroupDialog() {
  const dispatch = useDispatch();
  const allTags = useSelector(selectAllTags);
  const [selectedWorkGroups, setWorkGroups] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(false);
  const selectedDeskIds = useSelector(selectSelectedDeskIds);
  const selectedOffice = useSelector(selectSelectedOffice);

  const close = () => {
    dispatch(closeDialog())
  };

  const updateTagClick = async () => {
    setLoading(true);
    try {
      await updateDeskOwnershipsForWorkgroups(selectedDeskIds, selectedWorkGroups);
      dispatch(clearSelectedDeskIds());
      if (selectedOffice) {
        dispatch(fetchDesks(selectedOffice.id));
      }
      dispatch(successNotification('Desks assigned to workgroup'))
      close();
    } finally {
      setLoading(false);
    }
  };

  const selectWorkgroup = (teams: TeamEntity[], workgroups: Tag[]) => {
    setWorkGroups(workgroups)
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-desks-sidebar.assign-desks-to-workgroup" />

      {loading && <LoadingOverlay />}

      <TeamMultiSelect onSelect={selectWorkgroup}
                       disabled={loading}
                       workGroups={allTags}
                       selectedWorkGroups={selectedWorkGroups} />

      <PrimaryButton disabled={loading}
                     fullWidth={true}
                     click={updateTagClick}
                     style={{marginTop: 'auto'}}
                     text="button.save-changes" />
    </DialogWrapper>
  )
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 410px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 500px;
    min-height: 500px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
`
