import React from 'react';
import styled from "styled-components/macro";
import Icon, { IconTypes } from "../atoms/icon/Icon";
import Colours from "../atoms/Colours";


interface Props {
  icon: IconTypes;
  style?: any;
}

export default function IconHeader(props: Props) {
  return (
    <IconHeaderWrapper style={props?.style ?? {}}>
      <Icon icon={props.icon} size="large" />
    </IconHeaderWrapper>
  )
}

const IconHeaderWrapper = styled.div`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: ${Colours.veryLightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`
