import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import {
  loadUsers,
  selectIsManageUsersLoading,
  selectNumberOfUsersSkipped,
  selectSelectedMisc,
  selectSelectedOffice,
  selectSelectedSortOption,
  selectSelectedTeam,
  selectTotalUsersInQuery,
  selectTotalUsersReturned
} from "../ducks/manage-users.duck";
import { tablet } from "../../../../../UI/atoms/MediaQueries";

export function ManageUsersPaginationControls(props: Props) {
  const dispatch = useDispatch();
  const totalUsersInQuery = useSelector(selectTotalUsersInQuery);
  const totalUsersReturned = useSelector(selectTotalUsersReturned);
  const skipped = useSelector(selectNumberOfUsersSkipped);
  const selectedTeam = useSelector(selectSelectedTeam);
  const selectedOffice = useSelector(selectSelectedOffice);
  const selectedMisc = useSelector(selectSelectedMisc);
  const sortOptions = useSelector(selectSelectedSortOption);
  const isManageUsersLoading = useSelector(selectIsManageUsersLoading);

  const previous = () => {
    const toSkip = (skipped - 50 < 0 ? 0 : skipped - 50);
    if (toSkip === skipped) return;
    dispatch(loadUsers({team: selectedTeam, office: selectedOffice, misc: selectedMisc, orderBy: sortOptions, skip: toSkip}));
  }

  const next = () => {
    const toSkip = skipped + 50;
    if (toSkip >= totalUsersInQuery) return;
    dispatch(loadUsers({team: selectedTeam, office: selectedOffice, misc: selectedMisc, orderBy: sortOptions, skip: toSkip}));
  }

  return (
    <Row>
      <OutlineButton icon={IconTypes.Previous}
                     click={previous}
                     disabled={isManageUsersLoading} />
      <div>
        <BodyRegular>Showing {skipped} - {(skipped + totalUsersReturned)} of {totalUsersInQuery}</BodyRegular>
      </div>
      <OutlineButton icon={IconTypes.Next}
                     click={next}
                     disabled={isManageUsersLoading} />
    </Row>
  )
}

interface Props {
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-bottom: 16px;
    width: 100%;
    max-width: 100%;
  }
  p {
    margin-bottom: 16px;
  }
  @media (${tablet}) {
    flex-direction: row;
    justify-content: space-between;
    button, p {
      margin: 0;
    }
    button {
      max-width: 192px;
    }
  }
`
