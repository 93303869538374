import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { HolidayCalendarMonthView } from "../../user-annual-holidays/components/HolidayCalendarMonthView";
import { Moment } from "moment";

export function ApprovalsCalendarList(props: Props) {
  const {holidayYearStartDate} = props;
  const endDate = useMemo(() => holidayYearStartDate.clone().add(1, 'year').subtract(1, 'day'), [holidayYearStartDate]);

  return (
    <>
      <Wrapper>
        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone()}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(1, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(2, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(3, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(4, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(5, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(6, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(7, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(8, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(9, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(10, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />

        <HolidayCalendarMonthView enabledStartDate={holidayYearStartDate}
                                  enabledEndDate={endDate}
                                  monthDate={holidayYearStartDate.clone().add(11, 'month')}
                                  holidaysBooked={[]}
                                  companyHolidays={[]} />
      </Wrapper>
    </>
  )
}

interface Props {
  holidayYearStartDate: Moment;
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 8px;
`
