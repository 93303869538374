import React, { useEffect, useMemo } from 'react';
import Lottie from "lottie-react";
import animation from '../../../animations/LoadingAnimation.json'
import styled, { css } from "styled-components/macro";
import Colours from "./Colours";
import { BodyVerySmall } from "./fonts/Body";
import { FadeAndPopOnLoad } from "../../../animations/AnimationVariants";
import { useTranslation } from "react-i18next";

interface Props {
  size?: number | string;
  className?: string;
  style?: any;
  fullScreen?: boolean;
  message?: string;
  hideBorder?: boolean;
  hideText?: boolean;
  ignoreScroll?: boolean;
}


export const LargeLoadingSpinner = styled(LoadingSpinner)<Props>`
  width: 256px;
  height: 256px;
`

export const MediumLoadingSpinner = styled(LoadingSpinner)<Props>`
  width: 180px;
  height: 180px;
`

export function LoadingSpinner(props: Props) {
  return props.fullScreen ? <FullScreenSpinner {...props} style={{width: 256, height: 256}} /> : <Spinner {...props}/>
}

function FullScreenSpinner(props: Props) {
  const {ignoreScroll} = props;
  useEffect(() => {
    if (!ignoreScroll) {
      document.body.classList.add('noscroll');
    }
    return () => document.body.classList.remove('noscroll');
  }, [ignoreScroll]);
  return <FullScreen>
    <Spinner {...props}/>
  </FullScreen>
}

function Spinner(props: Props) {
  const {t} = useTranslation();
  const hideBorder = useMemo(() => {
    if (props.fullScreen) return true;
    return props.hideBorder ?? false;
  }, [props]);
  return (
    <FadeAndPopOnLoad>
      <Container data-test="loading-spinner" className={props.className ?? ''} style={props.style ?? {}} hideBorder={hideBorder}>
        <Lottie className="lotti" animationData={animation} loop={true} autoplay={true} />
        {!props.hideText && <BodyVerySmall soleil={true} weight={600} colour={Colours.darkGrey}>{t(props.message ?? 'general.loading')}</BodyVerySmall>}
      </Container>
    </FadeAndPopOnLoad>
  )
}

export const Container = styled.div<any>`
  background-color: ${Colours.white};
  border-radius: 24px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 16px 0;
  ${props => css`box-shadow: ${props.hideBorder ? 'none' : '0 0 17px 2px rgba(0, 87, 255, 0.15)'};`};

  .lotti {
    height: 80%;
    width: 80%;
  }
`

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.blue02};
  z-index: 9999999;
`
