import React, { useMemo, useState } from 'react';
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import TextField from "../../../../UI/atoms/TextField";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import styled from "styled-components/macro";
import { Switch } from "../../../../UI/atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { warningNotification } from "../../../../../store/ducks/notification.duck";
import { isNumber } from "../../../../../utils/TypeUtils";
import { HorizontalSpacer } from "../../../../UI/atoms/VerticalSpacer";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices, getRootOffice } from "../../../../../utils/OfficeHelper";
import { selectAllDesksInEditingOffice } from "../../../../../store/ducks/editOfficeLayout.duck";
import { Row } from "../../../../UI/atoms/StructuralLayout";
import { SpaceBetweenRow } from "../../StyleComponents";
import { Checkbox } from "../../../../UI/atoms/Checkbox";

export function DeskBookingWindowSetting(props: Props) {
  const {office} = props;
  const dispatch = useDispatch();
  const desks = useSelector(selectAllDesksInEditingOffice);
  const allOffices = useSelector(selectAllOfficeEntities);
  const [enabled, setEnabled] = useState(office.officeBookingWindowWeeks > 0);
  const [days, setDays] = useState(office?.officeBookingWindowWeeks ?? 0);
  const [restrictAccessToTeamAdmins, setRestrictAccessToTeamAdmins] = useState(office?.restrictBookingWindowForTeamAdmins ?? false);
  const {t} = useTranslation();

  const shouldBeVisible = useMemo(() => {
    if (!office) return false;
    for (let desk of desks) {
      const desksOffice = findOfficeInOffices(allOffices, desk.officeId);
      if (desksOffice) {
        const rootOffice = getRootOffice(allOffices, desksOffice);
        if (rootOffice && rootOffice.id === office.id) {
          return true;
        }
      }
    }
    return false;
  }, [desks, office, allOffices])

  const updateText = (text: string) => {
    if (isNumber(text)) {
      const d = parseInt(text);
      setDays(d);
      props.onOfficeBookingWindowChanged(days)
    } else {
      dispatch(warningNotification('Must be a number'))
    }
  }

  const onEnabledChanged = (changed: boolean) => {
    if (!changed) {
      setDays(0);
      props.onOfficeBookingWindowChanged(0);
    }

    setEnabled(changed);
  }

  const update = () => {
    props.onOfficeBookingWindowChanged(days);
  }

  const updateRestrictBookingWindowForTeamAdmins = (val: boolean) => {
    setRestrictAccessToTeamAdmins(val);
    props.onRestrictBookingWindowForTeamAdminsChange(val);

  }

  if (!shouldBeVisible) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <BodyRegular weight={600}>{t('settings.manage-office.desk-booking-window-title')}</BodyRegular>
        <Switch value={enabled} onChange={onEnabledChanged} disabled={props.disabled} />
      </Wrapper>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.manage-office.desk-booking-window-description')}</BodyRegular>
      {enabled && <>
        <SpaceBetweenRow>
          <Checkbox value={true} disabled={true} onChange={() => {}}
                    text={'settings.editing-future-whereabouts.applies-to-standard-users'} />
        </SpaceBetweenRow>

        <SpaceBetweenRow style={{marginBottom: 20}}>
          <Checkbox value={restrictAccessToTeamAdmins}
                    onChange={(val: boolean) => updateRestrictBookingWindowForTeamAdmins(val)}
                    text={'settings.editing-future-whereabouts.applies-to-team-admins'} />
        </SpaceBetweenRow>

        <Row>
          <BodyRegular weight={600} style={{marginTop: 6, marginRight: 24}}>{t('settings.manage-office.desk-booking-window-weeks')}</BodyRegular>
          <TextField onChange={updateText}
                     onBlur={update}
                     value={days.toString()}
                     style={{width:60, marginBottom: 0}}
                     disabled={props.disabled} />
        </Row>
      </>}
      <HorizontalSpacer />
    </>
  )
}

interface Props {
  office: OfficeEntity;
  onOfficeBookingWindowChanged: (days: number) => void;
  onRestrictBookingWindowForTeamAdminsChange: (val: boolean) => void;
  disabled: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
  
  .textField {
    max-width: 120px;
    margin-left: auto;
    margin-bottom: 0;
  }
  .switch {
    margin-left: 16px;
  }
`
