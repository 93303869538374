import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from "../../../../store/state/app.state";


export interface WallPlannerHoverBoxState {
  isVisible: boolean;
  position: {x: number, y: number};
  userId?: string;
  fromDate?: string;
}

export const initialWallPlannerHoverBoxState: WallPlannerHoverBoxState = {
  isVisible: false,
  position: {x: 0, y: 0},
};

export const declineRequest: any = createAsyncThunk(
  'wallPlannerHoverBox/declineRequest',
  async (test: any, thunkAPI) => {
  }
);


const wallPlannerHoverBoxSlice = createSlice({
  name: 'wallPlannerHoverBox',
  initialState: initialWallPlannerHoverBoxState,
  reducers: {
    resetWallPlannerHoverBox: (state) => (initialWallPlannerHoverBoxState),
    setIsVisible: (state, action) => ({ ...state, isVisible: action.payload, }),
    setPositionAndMakeVisible: (state, action) => ({ ...state, position: action.payload, isVisible: true, userId: action.payload.userId, fromDate: action.payload.fromDate }),
    setPosition: (state, action) => ({ ...state, position: action.payload, }),
  },
  extraReducers: {
    // [getApprovals.pending]: (state) => ({...state, approvals: [], loading: true}),
    // [getApprovals.failed]: (state) => ({...state, approvals: [], loading: false}),
    // [getApprovals.fulfilled]: (state, action) => ({...state, approvals: action.payload, loading: false}),
  },
});



export default wallPlannerHoverBoxSlice.reducer;
export const {
  resetWallPlannerHoverBox,
  setIsVisible,
  setPositionAndMakeVisible,
  setPosition,
} = wallPlannerHoverBoxSlice.actions;


// Selectors
export const selectIsBoxVisible = (state: AppState) => state.wallPlannerHoverBox.isVisible;
export const selectPosition = (state: AppState) => state.wallPlannerHoverBox.position;
export const selectRowUserId = (state: AppState) => state.wallPlannerHoverBox.userId;
export const selectRowFromDate = (state: AppState) => state.wallPlannerHoverBox.fromDate;
