import React from 'react';
import Icon, { IconTypes } from '../../../UI/atoms/icon/Icon';
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";


export default function SelectableContentCard(props: {children: any, title: string, selected: boolean, disabled?: boolean, onClick: any}) {
  const selectedClassName = props.selected ? 'selectableContentCard--selected' : '';
  const disabledClassName = props.disabled ? 'selectableContentCard--disabled' : '';
  const {t} = useTranslation();
  return (
    <div className={`selectableContentCard ${selectedClassName} ${disabledClassName}`.trim()} onClick={() => props.onClick()}>
      <div className="selectableContentCard__titleRow">
        <BodyRegular weight={600} className="selectableContentCard__title">{t(props.title)}</BodyRegular>
        {props.selected ? <Icon icon={IconTypes.Tick} color="blue" circle={true} /> : null}
      </div>
      {props.children}
    </div>
  )
}
