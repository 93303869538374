import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { history, store } from './store/store';
import App from './App';
import ReactGA from 'react-ga';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./services/EnvironmentVariables";
import { MsalProvider } from "@azure/msal-react";
import { TrackJS } from 'trackjs';

if (process.env.NODE_ENV === 'production') {
  TrackJS.install({
    token: "c2bbd906babe4cb5871ba829f7f393b0"
  });
}
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

ReactGA.initialize('UA-193948034-1', {
  testMode: process.env.NODE_ENV !== 'production',
  debug: false
});

ReactDOM.render((
  <Provider store={store}>
      <ConnectedRouter history={history}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ConnectedRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
