import React, { useMemo } from 'react';
import { BodySmall } from './fonts/Body';
import Colours from "./Colours";
import { useTranslation } from "react-i18next";

export function Checkbox({text, value, children, color = Colours.darkGrey, onChange = () => {}, disabled, flip, dataTest, className, style = {}}: Props) {
  const {t} = useTranslation();

  const classNames = useMemo(() => {
    let cs = 'checkbox__container';
    if (!!flip) cs += ' checkbox--flip';
    if (!!disabled) cs += ' checkbox__container--disabled';
    if (className) cs += " " + className;

    return cs;
  }, [className, disabled, flip]);

  const onClick = (e: any) => {
    e.stopPropagation();
  }

  return (
    <label data-test={dataTest ?? ''} className={classNames} style={style} onClick={onClick}>
      {text && !children && <BodySmall>{t(text)}</BodySmall>}
      {!text && children}
      <input type="checkbox" checked={!!value} onChange={e => onChange(e.target.checked)} disabled={!!disabled}/>
      <span className="checkbox__checkmark" style={{borderColor: color}}/>
    </label>
  );
}

interface Props {
  text?: string;
  disabled?: boolean;
  value: boolean;
  onChange: any
  children?: any;
  dataTest?: string;
  flip?: boolean;
  color?: string;
  className?: string;
  style?: any;
}
