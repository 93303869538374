import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../UI/atoms/buttons/Button';
import { BodyError, BodyLarge } from '../../../UI/atoms/fonts/Body';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import TextField from '../../../UI/atoms/TextField';
import {
  next,
  OnboardingScreen,
  selectActiveScreen,
  selectError,
  setAddedTeam,
  setError,
  skip
} from '../../../../store/ducks/onboarding.duck';
import { FadeAndPop } from "../../../../animations/AnimationVariants";
import { Column } from "../../../UI/atoms/StructuralLayout";
import { OnboardingContainer } from "../../../UI/molecules/OnboardingContainer";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import { failureNotification } from "../../../../store/ducks/notification.duck";
import { createTeamRequest } from "../../../../services/TeamService";
import { loadConfig, selectIsConfigLoading } from "../../../../store/ducks/config.duck";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { CompanyNameSetting } from "../../../UI/organisms/settings/CompanyNameSetting";
import { isCompanyAdminUser } from "../../../../utils/AccessControl";


export default function OnboardingCreateTeam() {
  const errorMessage = useSelector(selectError);
  const [teamName, setTeamName] = useState('');
  const [loading, setLoading] = useState(false);
  const isConfigLoading = useSelector(selectIsConfigLoading);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const currentUser = useSelector(selectCurrentUser);
  const activeScreen = useSelector(selectActiveScreen);

  const createTeam = async () => {
    if (!teamName.trim()) {
      dispatch(failureNotification('Team name required'))
      return;
    }

    setLoading(true);

    try {
      const createTeamResponse = await createTeamRequest(teamName, true);
      dispatch(loadConfig());
      dispatch(setError(''));
      dispatch(next());
      return createTeamResponse;
    } catch (err: any) {
      dispatch(setError(err?.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (activeScreen === OnboardingScreen.CreateTeam && currentUser?.teamEntity) {
      dispatch(setAddedTeam(currentUser?.teamEntity))
      dispatch(skip());
    }
  }, [dispatch, currentUser, activeScreen]);

  return (
    <OnboardingContainer dataTest="onboarding-welcomeAddTeam">
      {(loading || isConfigLoading) && <LoadingSpinner fullScreen={true} hideText={true} />}
      <Column className="onboarding__content">
        <img className="onboarding__image" src="/assets/images/onboarding/teams-shapes.svg" alt="" aria-hidden={true} />
        <HeadlineMedium className="onboarding__title">{t('onboarding.create-team')}</HeadlineMedium>
        <BodyLarge className="onboarding__description">{t('onboarding.create-team-description')}</BodyLarge>
        <FadeAndPop hidden={!errorMessage}>
          <BodyError>{errorMessage}</BodyError>
        </FadeAndPop>
        <CompanyNameSetting editable={isCompanyAdminUser(currentUser)}
                            fullWidth={true}
                            style={{marginBottom: 32}}
                            fieldLabel={'settings.company-name'} />
        <TextField dataTest="welcomeTeamName"
                   className="onboarding__input"
                   label="textinput.team-name"
                   fullWidth={true}
                   value={teamName}
                   onChange={(val) => setTeamName(val)}/>
        <Button dataTest="welcomeTeamNextSubmit" className="onboarding__button" text="button.create-team" onClick={createTeam}/>
      </Column>
    </OnboardingContainer>
  )
}
