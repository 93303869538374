import React from 'react';
import DropDownMenuItem from "../drop-down-menu-item/DropDownMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setMenuOpen } from "../../../../../../store/ducks/general.duck";
import './drop-down-list.scss';
import LogoutMenuItem from "../drop-down-logout-menu-item/LogoutMenuItem";
import { selectAccountInfo } from "../../../../../../store/ducks/payments.duck";
import styled from 'styled-components';
import { tablet } from '../../../../atoms/MediaQueries';
import { redirectTo, selectCurrentUser } from '../../../../../../store/ducks/auth.duck';
import { IconTypes } from "../../../../atoms/icon/Icon";
import { selectAllMeetingRooms } from "../../../../../pages/settings/pages/meeting-rooms/meeting-rooms-settings.duck";

export default function DropDownList() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const accountInfo = useSelector(selectAccountInfo);
  const meetingRooms = useSelector(selectAllMeetingRooms);
  const onCloseMenu = () => {
    dispatch(setMenuOpen(false));
  };

  const onHolidayApprovalsClick = () => {
    dispatch(redirectTo('/holidays/view-approvals'));
    onCloseMenu();
  }

  return (
    <DropDownListWrapper className="dropdownList">
      <DropDownMenuItem mobileOnly={true} text="team-view.whereabouts" onClick={onCloseMenu} url="/whereabouts/team"/>
      {accountInfo?.enableHolidayBooking && <DropDownMenuItem mobileOnly={true} text="header.leave-planner" onClick={onCloseMenu} url="/holidays/leave-planner"/>}
      {meetingRooms && meetingRooms.length > 0 && <DropDownMenuItem mobileOnly={true} text="settings-page.meeting-rooms" onClick={onCloseMenu} url="/meeting-rooms"/>}
      <DropDownMenuItem mobileOnly={true} text="button.company-view" onClick={onCloseMenu} url="/whereabouts/company/all"/>
      <DropDownMenuItem mobileOnly={true} text="button.settings" onClick={onCloseMenu} url="/manage"/>
      {currentUser?.companyEntity.holidayApprovalEnabled && <>
        <DropDownMenuItem icon={IconTypes.Holiday}
                          mobileOnly={false}
                          text="button.holiday-requests"
                          onClick={onHolidayApprovalsClick} />
      </>}

      <LogoutMenuItem onClick={onCloseMenu}/>
    </DropDownListWrapper>
  )
}


const DropDownListWrapper = styled.div`
  display: none;
  background-color: #f9f9f9;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  padding-top: 32px;
  padding-bottom: 180px;
  flex-direction: column;

  @media (${tablet}) {
    display: none;
    right: 0;
    top: 59px;
    left: unset;
    height: unset;
    width: 340px;
    position: absolute;
    z-index: 20;
    padding: 16px 32px 32px 32px;
    max-height: 400px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: max-height .2s;
    min-height: 120px;

    border: 1px solid #BCC6C7;
    border-top: none;
    box-sizing: border-box;
    box-shadow: 0 20px 34px rgba(145, 155, 173, 0.15);
  }
`
