import React from 'react';
import { useTranslation } from "react-i18next";
import { BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";

export function ManageMeetingRoomsIntroduction(props: Props) {
  const {t} = useTranslation();
  return (
    <Container>
      <img src={"assets/images/meeting-room-intro.svg"} alt={""} />
      <BodyLarge weight={600} style={{marginBottom: 12}}>{t('settings.meeting-rooms.introduction.how')}</BodyLarge>
      <BodyLarge style={{marginBottom: 12}}>{t('settings.meeting-rooms.introduction.intro')}</BodyLarge>
      <ul>
        <li><BodyLarge>{t('settings.meeting-rooms.introduction.intro-1')}</BodyLarge></li>
        <li><BodyLarge>{t('settings.meeting-rooms.introduction.intro-2')}</BodyLarge></li>
        <li><BodyLarge>{t('settings.meeting-rooms.introduction.intro-3')}</BodyLarge></li>
      </ul>
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  ul {
    margin-left: 32px;
  }
  img {
    max-width: 350px;
    align-self: center;
    margin-bottom: 24px;
  }
`
