import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, DialogIdentifiers, openDialog } from "../../store/ducks/dialog.duck";
import Dialog from "../UI/molecules/Dialog";
import DialogHeader from "./DialogHeader";
import { BodySmall } from "../UI/atoms/fonts/Body";
import { selectCurrentUser, updateCurrentUser } from "../../store/ducks/auth.duck";
import { GetMicrosoftLoginAccount, stepUpAzureCalendarPermissionsWithPopup, teamsLogin } from "../../utils/AuthUtils";
import { useMsal } from "@azure/msal-react";
import { updateOutlookSyncState } from "../../services/UserService";
import Colours from "../UI/atoms/Colours";
import { BorderlessButton } from "../UI/atoms/buttons/BorderlessButton";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";
import { isInTeams } from "../../utils/TeamsUtils";
import { microsoftRequest } from "../../services/EnvironmentVariables";
import { Center } from "../UI/atoms/StructuralLayout";
import { useTranslation } from "react-i18next";

export function AzureCalendarSyncDisabledDialog(props: Props) {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {instance} = useMsal();
  const {t} = useTranslation();

  useEffect(() => {
    if (isInTeams()) {
      dispatch(openDialog(DialogIdentifiers.AzurePermissionsRequired));
    }
  }, [dispatch])

  const tryAgain = async () => {
    setIsLoading(true);
    try {
      await updateOutlookSyncState(true);
      const account = GetMicrosoftLoginAccount(instance);
      if (isInTeams()) {
        try {
          await teamsLogin(instance, microsoftRequest.calendarScopes, dispatch, true, true, account)
        } catch (e: any) {
          console.log('Failed to get permissions')
          console.error('Failed to get permissions', e);
          await updateOutlookSyncState(false)
          dispatch(openDialog(DialogIdentifiers.AzureCalendarSyncDisabledDialog));
        }

      } else {
        // @ts-ignore
        stepUpAzureCalendarPermissionsWithPopup(instance, account, dispatch);
      }
      setIsLoading(false);
      dispatch(closeDialog())
    } finally {
      setIsLoading(false);
    }
  }

  const close = async () => {
    setIsLoading(true);

    try {
      if (user) {
        await updateOutlookSyncState(false)
        dispatch(updateCurrentUser(true));
      }
    } finally {
      setIsLoading(false);
      dispatch(closeDialog())
    }
  }

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="dialog-headers.sync-to-outlook" />
      <Center>
        <img src="/assets/images/CalendarSync.svg"
             style={{width: '90%'}}
             alt={"Calendar sync"} />
      </Center>

      <PrimaryButton click={tryAgain}
                     text={'sync-to-outlook-dialog.yes'}
                     fullWidth={true}
                     style={{marginBottom: 16}}
                     disabled={isLoading} />

      <BorderlessButton click={close}
                        text={'sync-to-outlook-dialog.no'}
                        fullWidth={true}
                        style={{marginBottom: 16}}
                        disabled={isLoading} />

      <BodySmall colour={Colours.darkGrey} style={{marginBottom: 32}}>{t('sync-to-outlook-dialog.message')}</BodySmall>
    </Dialog>
  )
}

interface Props {
}
