import React from 'react';
import { MeetingRoom } from "../../../../meeting-rooms/models/MeetingRooms";
import { BodySmall, BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { Pill } from "../../../../../UI/atoms/Pill";
import { facilityToLabel } from "../../../../meeting-rooms/models/MeetingRoomHelper";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";

export function MeetingRoomContentCard(props: Props) {
  const {t} = useTranslation();
  const {meetingRoom} = props;
  return (
    <ContentCard className={'meetingRoom__contentCard'}>
      <HeadlineSmall style={{marginBottom: 8}}>{meetingRoom.name}</HeadlineSmall>
      <BodySmall style={{marginBottom: 16}} colour={Colours.black} weight={400}>{meetingRoom.capacity} people</BodySmall>
      <FooterContainer>
        <Row>
          {meetingRoom.facilities.map((facility, key) => (<Pill colour={'#1EE1FF'} backgroundColour={'#E6FCFF'} key={key} style={{marginRight: 8}}>
            <BodyVerySmall weight={600} colour={Colours.black}>{t(facilityToLabel(facility))}</BodyVerySmall>
          </Pill>))}
        </Row>
        <OutlineButton style={{}} link={`/manage/meeting-rooms/edit/${meetingRoom.id}`} size={'small'} text={'button.edit'} />
      </FooterContainer>
    </ContentCard>
  )
}

interface Props {
  meetingRoom: MeetingRoom;
}

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colours.lightGrey};
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
  &:first-child {
    margin-top: 16px;
  }
  &:hover {
    margin-top: 16px;
    background-color: ${Colours.blue02};
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    color: ${Colours.black};
    align-self: center;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .linkButton {
    width: 120px;
    max-width: unset;
    margin: unset;
  }
  button {
    max-width: 100px;
  }
`
