import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import { AccountInfo, AuthenticationResult } from "@azure/msal-common";
import { setUser } from "../store/ducks/auth.duck";
import { microsoftRequest } from "../services/EnvironmentVariables";
import { TrackJS } from "trackjs";
import { rawUserRequest } from "../services/Auth";
import { User } from "../models/user.models";
import { DialogIdentifiers, openDialog } from "../store/ducks/dialog.duck";
import { isInTeams, isInTeamsApp } from "./TeamsUtils";
import { getParameterByName } from "./UrlUtils";
import { NotificationMessages } from "../models/notification.models";
import { authentication } from "@microsoft/teams-js";
import { agent } from "../services/agent";
import jwt_decode from "jwt-decode";

export enum AuthType {
  Microsoft = 'MS',
  Standard = 'TT',
  Google = 'GG'
}

export function GetCurrentAuthType(): AuthType {
  return localStorage.getItem('type') as AuthType;
}

export function GetMicrosoftLoginAccount(instance: IPublicClientApplication, account?: AccountInfo): AccountInfo | undefined {
  let retVal = undefined;
  if (account) retVal = account;
  if (instance.getActiveAccount()) retVal = instance.getActiveAccount() || undefined;
  if (instance.getAllAccounts().length > 0) retVal = instance.getAllAccounts()[0];

  if (retVal) {
    instance.setActiveAccount(retVal);
  }

  return retVal;
}

export async function AzureAcquireTokenSilent(dispatch: any, instance: IPublicClientApplication, inProgress: InteractionStatus, account: AccountInfo | null, redirectUri?: string, skipStepUpPermission?: boolean) {
  if (account && inProgress === InteractionStatus.None) {
    localStorage.setItem('type', AuthType.Microsoft)
    if (redirectUri) {
      localStorage.setItem('redirect', redirectUri)
    }
    console.log('account', account)
    console.log('microsoftRequest.login', microsoftRequest.login)

    const result: AuthenticationResult = await instance.acquireTokenSilent({
      ...microsoftRequest.login,
      redirectUri: `${window.location.origin}/blank.html`,
      account: account,
      forceRefresh: false,
    });

    await HandleAuthenticationResult(result, dispatch, false);

    const user: User = await rawUserRequest();
    if (!skipStepUpPermission && user.enableOutlookSync && user.companyEntity.calendarSyncEnabled) {
      TrackJS?.track(`Silent request, stepUpPermissions, ${user?.email}`)
      await stepUpAzureCalendarPermissions(instance, account, dispatch);
    }

    dispatch(setUser(user));
  }
}

export async function LoginWithRedirect(instance: IPublicClientApplication, dispatch: any, selectAccount: boolean, redirectUri?: string) {
  try {
    if (redirectUri) {
      localStorage.setItem('redirect', redirectUri)
    }

    let requestConfig = microsoftRequest.login;
    if (selectAccount ?? instance.getAllAccounts().length > 1) {
      // @ts-ignore
      requestConfig.prompt = 'select_account';
    } else if (instance.getAllAccounts().length === 1 && !instance.getActiveAccount()) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }

    await instance.acquireTokenRedirect({...requestConfig});
  } catch (err: any) {
    TrackJS.track(err.errorCode ? `LoginWithRedirect error: ${err.errorCode}` : err);
    window.location.href = `/login`;
  }
}

export async function HandleAuthenticationResult(result: AuthenticationResult, dispatch: any, requestUser: boolean) {
  localStorage.setItem('type', AuthType.Microsoft);
  localStorage.setItem('accessToken', result.accessToken);
  localStorage.setItem('idToken', result.idToken);
  localStorage.setItem('tokenExpires', (result.expiresOn ?? new Date()).toUTCString());

  if (requestUser) {
    await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
    const user = await rawUserRequest();
    dispatch(setUser(user));
  }
}

export function hasTokenExpired() {
  if (GetCurrentAuthType() === AuthType.Microsoft) {
   return new Date(localStorage.getItem('tokenExpires') ?? 0) < new Date();
  } else {
    return false;
  }
}

export async function stepUpAzureCalendarPermissions(instance: IPublicClientApplication, account: AccountInfo, dispatch: any) {
  // Check current token to see if it contains scopes
  const makeRequest = async (): Promise<AuthenticationResult> => {
    try {
      return await instance.acquireTokenSilent({
        ...microsoftRequest.login,
        scopes: microsoftRequest.calendarScopes,
        redirectUri: `${window.location.origin}/blank.html`,
        account: account,
        forceRefresh: true,
      });
    } catch (e: any) {
      throw e;
    }
  }

  try {
    if (isInTeams()) {
      await teamsLogin(instance, microsoftRequest.calendarScopes, dispatch, true, true, account)
    } else {
      const response = await makeRequest();
      await HandleAuthenticationResult(response, dispatch, true);
    }

  } catch (e: any) {
    if (!!e.errorMessage && e.errorMessage.includes('AADSTS65001')) {
      if (isInTeams()) {
        dispatch(openDialog(DialogIdentifiers.AzurePermissionsRequired));
      } else {
        dispatch(openDialog(DialogIdentifiers.AzureCalendarSyncDisabledDialog));
      }
    } else {
      TrackJS.track(e.errorCode ? `stepUpAzureCalendarPermissions error: ${e.errorCode}` : e);
    }

  }
}



export async function stepUpAzureCalendarPermissionsWithPopup(instance: IPublicClientApplication, account: AccountInfo, dispatch: any) {
  const makeRequest = async (): Promise<AuthenticationResult> => {
    try {
      return await instance.acquireTokenSilent({
        ...microsoftRequest.login,
        scopes: microsoftRequest.calendarScopes,
        redirectUri: `${window.location.origin}/blank.html`,
        account: account,
        forceRefresh: true,
      });
    } catch (e: any) {
      return await instance.acquireTokenPopup({
        ...microsoftRequest.login,
        scopes: microsoftRequest.calendarScopes,
        redirectUri: `${window.location.origin}/blank.html`,
        account: account,
      })
    }
  }

  try {
    if (isInTeams()) {
      await teamsLogin(instance, microsoftRequest.calendarScopes, dispatch, true, true, account)
    } else {
      const response = await makeRequest();
      await HandleAuthenticationResult(response, dispatch, true);
    }

  } catch (e: any) {
    TrackJS.track(e.errorCode ? `stepUpAzureCalendarPermissionsWithPopup error: ${e.errorCode}` : e);
  }
}

export function getLoginAttemptNumber() {
  try {
    return parseInt(localStorage.getItem('attempt') || '1');
  } catch (e: any) {
    return 1;
  }
}

export function clearLoginAttempt() {
  localStorage.removeItem('attempt');
}

export function clearLoginStorage() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('tokenExpires');
  localStorage.removeItem('redirect');
}

  export function resetAllLoginStorages() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('tokenExpires');
  localStorage.removeItem('redirect');
  localStorage.removeItem('type');
  localStorage.removeItem('attempt');
}

export function stopLoginAttempts() {
  const loginAttempt = getLoginAttemptNumber();
  const notificationParam = getParameterByName('not', window.location.href);
  return loginAttempt > 4 || notificationParam === NotificationMessages.noAccountFound || notificationParam?.includes(NotificationMessages.azureLoginFailed);
}

export async function teamsLogin(instance: any, scopes: string[], dispatch: any, requestUser: boolean, fallbackToPopup?: boolean, loginAccount?: AccountInfo) {
  try {
    const authToken = await authentication.getAuthToken({silent: true});

    localStorage.setItem('type', AuthType.Microsoft);
    localStorage.setItem('accessToken', authToken);
    localStorage.setItem('idToken', authToken);

    try {
      const result = await agent.requests.post('/sso', localStorage.getItem('idToken'), true);
      if (result.idToken) localStorage.setItem('idToken', result.idToken);
      if (result.accessToken) localStorage.setItem('accessToken', result.accessToken);

      if (!doesTokenContainScopes(result.accessToken, scopes)) {
        await teamsLoginWithPopup(instance, scopes, dispatch);
      }


    } catch (e: any) {
      if (e.toString().includes('Consent required')) {
        await teamsLoginWithPopup(instance, scopes, dispatch);
      }
    }

    if (requestUser) {
      await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
      const user = await rawUserRequest();
      dispatch(setUser(user));
    }
  } catch (e) {
    console.log('teamsLogin error', e)
    if (fallbackToPopup || true) {
      try {
        await teamsLoginWithPopup(instance, scopes, dispatch, requestUser);
      } catch (popupError) {
        throw popupError;
      }
    } else {
      throw e;
    }
  }
}

export async function teamAuthenticate(scopes: string[]) {
  try {
    const authToken = await authentication.getAuthToken();

    localStorage.setItem('type', AuthType.Microsoft);
    localStorage.setItem('accessToken', authToken);
    localStorage.setItem('idToken', authToken);
  } catch (e) {
    await teamAuthenticateWithPopup(scopes);
  }
}

export async function teamAuthenticateWithPopup(scopes: string[]) {
  try {
    const response = await authentication.authenticate({
      url: window.location.origin + '/login/tab-auth?scopes=' + scopes.join(' ')
    });
    const result = JSON.parse(response);

    localStorage.setItem('type', AuthType.Microsoft);
    localStorage.setItem('accessToken', result.accessToken);
    localStorage.setItem('idToken', result.idToken);
    localStorage.setItem('tokenExpires', (result.expiresOn ?? new Date()).toUTCString());
  } catch (e) {
    throw e;
  }
}

export async function teamsLoginWithPopup(instance: any, scopes: string[], dispatch: any, requestUser?: boolean) {
  try {
    localStorage.removeItem('simple.error')
    localStorage.removeItem('simple.url')
    localStorage.removeItem('simple.state')
    localStorage.setItem('simple.scopes', scopes.join(' '));

    let result: any = {};
    if (await isInTeamsApp()) {
      const response = await authentication.authenticate({
        url: window.location.origin + '/login/tab-auth?scopes=' + scopes.join(' '),
        width: 600,
        height: 535
      });
      result = JSON.parse(response);
    } else {
      result = await instance.acquireTokenPopup({
        ...microsoftRequest.login,
        scopes: scopes,
        redirectUri: `${window.location.origin}/blank.html`,
      })
    }


    localStorage.setItem('type', AuthType.Microsoft);
    localStorage.setItem('accessToken', result.accessToken);
    localStorage.setItem('idToken', result.idToken);
    localStorage.setItem('tokenExpires', (result.expiresOn ?? new Date()).toUTCString());

    if (requestUser) {
      await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
      const user = await rawUserRequest();
      dispatch(setUser(user));
    }
  } catch (e) {
    throw e;
  }
}

function doesTokenContainScopes(accessToken: string, scopes: string[]): boolean {

  const parsedToken = jwt_decode(accessToken) as any;
  const parsedScopes = (parsedToken?.scp ?? '').toUpperCase().split(' ');

  const diff = scopes.filter(s => !parsedScopes.includes(s.toUpperCase()))
                              .filter(s => s.toUpperCase() !== 'OFFLINE_ACCESS');

  return diff.length === 0;
}
