import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../state/app.state";
import { AccountInfo, getAccountInfo, PaymentInfo } from "../../services/AccountService";

export interface PaymentsState {
  loading: boolean;
  isUpgradeDialogOpen: boolean;
  isUpdatePaymentMethodOpen: boolean;
  isNewPaymentDialogOpen: boolean;
  accountInfo?: AccountInfo;
  paymentInfo?: PaymentInfo;
}

export const initialPaymentsState: PaymentsState = {
  loading: false,
  isUpgradeDialogOpen: false,
  isUpdatePaymentMethodOpen: false,
  isNewPaymentDialogOpen: false,
}

export const loadAccountInfo: any = createAsyncThunk(
  'payments/loadAccountInfo',
  async (force: any, thunkAPI) => {
    const accountInfo = await getAccountInfo();
    return accountInfo;
  }
)

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: initialPaymentsState,
  reducers: {
    reset: () => initialPaymentsState,
    clearHolidays: () => initialPaymentsState,
    closeNewPaymentDialog: (state) => ({...state, isNewPaymentDialogOpen: false}),
    openNewPaymentDialog: (state) => ({...state, isNewPaymentDialogOpen: true}),
    closeUpgradeDialog: (state) => ({...state, isUpgradeDialogOpen: false}),
    openUpgradeDialog: (state) => ({...state, isUpgradeDialogOpen: true}),
    closeUpdatePaymentMethodDialog: (state) => ({...state, isUpdatePaymentMethodOpen: false}),
    openUpdatePaymentMethodDialog: (state) => ({...state, isUpdatePaymentMethodOpen: true}),
    setAccountInfo: (state, action) => ({...state, accountInfo: action.payload}),
  },
  extraReducers: {
    [loadAccountInfo.fulfilled]: (state, action) => ({...state, accountInfo: action.payload, }),
  }
});

export const {
  reset,
  closeNewPaymentDialog,
  openNewPaymentDialog,
  setAccountInfo,
} = paymentsSlice.actions;
export default paymentsSlice.reducer;

// Selectors
export const isLoading = (state: AppState) => state.payments.loading;
export const isNewPaymentDialogOpen = (state: AppState) => state.payments.isNewPaymentDialogOpen;
export const selectAccountInfo = (state: AppState) => state.payments.accountInfo;
export const selectIsAccountActive = (state: AppState) => state.payments.accountInfo?.isActive;
export const selectNumberOfRemainingUsers = (state: AppState) => (state.payments.accountInfo?.userLimit ?? 0) - (state.payments.accountInfo?.totalUsers ?? 0);
export const selectIsFreeAccount = (state: AppState) => (state.payments.accountInfo?.billTotal ?? 0) === 0;
export const selectIsAccountLimitReached = (state: AppState) => (state.payments.accountInfo?.userLimit ?? 0) - (state.payments.accountInfo?.totalUsers ?? 0) < 1;
