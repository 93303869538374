import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import styled from "styled-components/macro";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { getWhereaboutsReport, toCsvDownload } from "../../../../services/ReportingService";
import { DateInputField } from '../../../UI/molecules/DateInputField';
import { OutlineButton } from '../../../UI/atoms/buttons/OutlineButton';
import FullScreenDateRangeSelector from "../../../UI/molecules/calendar/FullScreenDateRangeSelector";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { Checkbox } from "../../../UI/atoms/Checkbox";

export function WhereaboutsBookingDownloadReportBar(props: Props) {
  const {selectedTeamId, selectedTeamName, selectedDepartmentId, selectedDepartmentName} = props;
  const dispatch = useDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [loading, setLoading] = useState(false);
  const [hideUnknownWhereabouts, setHideUnknownWhereabouts] = useState(true);
  const current = useSelector(selectCurrentUser);

  const onDatesSelected = (startDate?: Moment, endDate?: Moment) => {
    if (startDate) setFromDate(startDate);
    if (endDate) setToDate(endDate);
  }

  const onClick = () => {
    if (!fromDate) {
      dispatch(warningNotification("Start date required."));
      return;
    }
    if (!toDate) {
      dispatch(warningNotification("End date required."));
      return;
    }
    if (!selectedTeamId && !selectedDepartmentId) {
      dispatch(warningNotification("Select a group."));
      return;
    }
    if (!current) {
      dispatch(warningNotification("Current user not found."));
      return;
    }

    setLoading(true);
    getWhereaboutsReport(current.companyEntity.id, fromDate, toDate, selectedTeamId, selectedDepartmentId, hideUnknownWhereabouts)
      .then(response => toCsvDownload(response, 'Whereabouts report'))
      .finally(() => setLoading(false));
  }

  return (<>
      <BodyRegular weight={600}>Download whereabouts booking report</BodyRegular>
      <ReportRowContainer>
        <BodyRegular style={{paddingBottom: 8}}>{selectedTeamName || selectedDepartmentName}</BodyRegular>

        <DateInputField onClick={() => setIsCalendarOpen(true)}
                        placeholder="textinput.add-date"
                        value={fromDate ? fromDate.format('D MMMM YYYY') : ''}
                        label="textinput.from"
                        editableOnly={true}/>

        <DateInputField onClick={() => setIsCalendarOpen(true)}
                        placeholder="textinput.add-date"
                        value={toDate ? toDate.format('D MMMM YYYY') : ''}
                        label="textinput.to"
                        editableOnly={true}/>

        <OutlineButton size={"medium"}
                       text="button.download-csv"
                       disabled={loading}
                       click={onClick} />

        <Checkbox value={hideUnknownWhereabouts}
                  className={'downloadReportBar__checkbox'}
                  onChange={setHideUnknownWhereabouts}
                  text={"Hide unknown whereabouts"} />
      </ReportRowContainer>
      <FullScreenDateRangeSelector open={isCalendarOpen}
                                   onDateSelect={(startDate, endDate) => onDatesSelected(startDate, endDate)}
                                   onClose={() => setIsCalendarOpen(false)} startDate={fromDate} endDate={toDate}
                                   initialDate={fromDate ?? moment()}/>
    </>
  )
}

interface Props {
  selectedTeamId?: string;
  selectedTeamName?: string;

  selectedDepartmentId?: number;
  selectedDepartmentName?: string;
}

const ReportRowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  .textField {
    margin-bottom: 0;
  }
  .teamDropDownList {
    margin-bottom: 0;
  }
  > * {
    width: 20%;
    padding-right: 24px;
  }
  .downloadReportBar__checkbox {
    margin: 0 0 16px auto;
  }
`
