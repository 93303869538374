import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Colours from "../../../../UI/atoms/Colours";
import { roundUpNearest10 } from "../../../../../utils/MathUtils";
import {
  KeyResponsibilities,
  ReportDataItem,
  selectMinimumRequirement,
  selectReportData,
  selectSelectedKeyResponsibility
} from "../key-responsibilities.duck";
import { useSelector } from "react-redux";
import { ToolTip } from "./ToolTip";

function getTitle(key: KeyResponsibilities) {
  switch (key) {
    case KeyResponsibilities.fireWarden: return 'Fire wardens';
    case KeyResponsibilities.firstAider: return 'First aiders';
    default: return 'Key responsibilities'
  }
}

function getColourOfBar(usersInOffice: number, keyPersonsInOffice: number, keyPersonsPerUserCount: number): string {
  return usersInOffice <= keyPersonsInOffice * keyPersonsPerUserCount ? Colours.green : Colours.red;
}

function getBackgroundColourOfBar(usersInOffice: number, keyPersonsInOffice: number, keyPersonsPerUserCount: number): string {
  return usersInOffice <= keyPersonsInOffice * keyPersonsPerUserCount ? '#96E3BA' : '#FFC8D4';
}

export function KeyResponsibilitiesBarChart(props: Props) {
  const reportData = useSelector(selectReportData);
  const selectedKeyResponsibility = useSelector(selectSelectedKeyResponsibility);
  const minimumRequirement = useSelector(selectMinimumRequirement);

  const title = useMemo(() => getTitle(selectedKeyResponsibility), [selectedKeyResponsibility]);

  const maxValueOfYAxis = useMemo(() => {
    let maxValue = 0;
    reportData.forEach((d: ReportDataItem) => d.count > maxValue ? maxValue = d.count : maxValue);
    reportData.forEach((d: ReportDataItem) => d.officeAttendance > maxValue ? maxValue = d.officeAttendance : maxValue);
    return maxValue;
  }, [reportData]);

  return (
    <>
      <div style={{height: 400}}>
        <ResponsiveContainer width="100%" height="100%" className={"keyResponsibilitiesBarChart"}>

          <BarChart
            width={500}
            height={400}
            data={reportData}
            margin={{top: 20, right: 30, left: 20, bottom: 5}}>

            <Tooltip content={<ToolTip title={title} />} />
            <CartesianGrid stroke={Colours.mildGrey} />

            <XAxis dataKey="dayOfWeekLetter"
                   fontFamily={'proxima-nova'}
                   fill={Colours.darkGrey}
                   fontWeight={400}
                   fontSize={13}
                   xAxisId="0"
                   dy={4}
                   axisLine={false} />

            <XAxis dataKey="dateOfMonth"
                   fontFamily={'proxima-nova'}
                   fill={Colours.darkGrey}
                   fontWeight={700}
                   fontSize={13}
                   dy={-10}
                   xAxisId="1"
                   axisLine={false} />


            <YAxis fontFamily={'proxima-nova'}
                   fill={Colours.veryLightGrey}
                   fontSize={14}
                   axisLine={true}
                   domain={[0, roundUpNearest10(maxValueOfYAxis)]}  />
            <Bar dataKey={'count'} fill={Colours.darkGrey} stackId={1}>
              {reportData.map((entry, index) => (
                <Cell fill={getColourOfBar(entry.officeAttendance, entry.count, minimumRequirement)} key={`fg-cell-${index}`} />
              ))}
            </Bar>

            <Bar dataKey={'keyRoleToAttendancediff'} fill={Colours.darkGrey} stackId={1}>
              {reportData.map((entry, index) => (
                <Cell fill={getBackgroundColourOfBar(entry.officeAttendance, entry.count, minimumRequirement)} key={`bg-cell-${index}`} />
              ))}
            </Bar>

          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

interface Props {
}
