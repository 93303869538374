import { Period } from "../../../../models/movements.models";
import { BodyRegular } from "../../atoms/fonts/Body";
import React from "react";
import { selectActivePeriod, setActivePeriod } from "../../../../store/ducks/editMovements.duck";
import { useDispatch, useSelector } from "react-redux";
import './period-list.scss';

export default function PeriodList(props: {horizontal?: boolean}) {
  const dispatch = useDispatch();
  const activePeriod = useSelector(selectActivePeriod);
  const setPeriod = (period: Period) => dispatch(setActivePeriod(period));

  return (
    <div className={`periodList ${props.horizontal ? 'periodList--horizontal' : ''}`.trim()}>
      <div className={`periodList__period ${activePeriod === Period.AllDay ? 'periodList__period--selected' : ''}`.trim()}
           data-test={'period-all-day'}
           onClick={() => setPeriod(Period.AllDay)}>
        <BodyRegular weight={600}>All day</BodyRegular>
      </div>
      <div className={`periodList__period ${activePeriod === Period.AM ? 'periodList__period--selected' : ''}`}
           data-test={'period-am'}
           onClick={() => setPeriod(Period.AM)}>
        <BodyRegular weight={600}>AM</BodyRegular>
      </div>
      <div className={`periodList__period ${activePeriod === Period.PM ? 'periodList__period--selected' : ''}`}
           data-test={'period-pm'}
           onClick={() => setPeriod(Period.PM)}>
        <BodyRegular weight={600}>PM</BodyRegular>
      </div>
    </div>
  )
}
