import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadWallPlannerTeamsActivities,
  loadWallPlannerWorkGroupActivities
} from "../components/pages/wall-planner/wallPlanner.duck";
import { selectCalendarDate } from "../store/ducks/dashboard.duck";
import { selectFollowingTeams, selectFollowingWorkGroups } from "../store/ducks/followingTeamsAndTags.duck";

export function WithWorkPlannerUserActivities() {
  const dispatch = useDispatch();
  const followedTeams = useSelector(selectFollowingTeams);
  const followedWorkGroups = useSelector(selectFollowingWorkGroups);
  const calendarDate = useSelector(selectCalendarDate);

  useEffect(() => {
    const id = setTimeout(() => {
      followedTeams.forEach((team) => dispatch(loadWallPlannerTeamsActivities(team)))
    }, 250);

    return () => {
      clearTimeout(id);
    }
  }, [dispatch, followedTeams, calendarDate]);

  useEffect(() => {
    const id = setTimeout(() => {
      followedWorkGroups.forEach((tag) => dispatch(loadWallPlannerWorkGroupActivities(tag)))
    }, 350);

    return () => {
      clearTimeout(id);
    }
  }, [dispatch, followedWorkGroups, calendarDate]);
}
