import React, { useState } from 'react';
import Dialog from "../../../UI/molecules/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import DialogHeader from "../../../dialogs/DialogHeader";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { Center, Column } from "../../../UI/atoms/StructuralLayout";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { resetDeskOwnership } from "../../../../services/DeskOwnershipService";
import { selectSelectedDeskIds, setSelectedDeskIds } from "../../company-movements/ducks/manageDesks.duck";
import { failureNotification, successNotification } from "../../../../store/ducks/notification.duck";
import { fetchDesks } from "../../../../store/ducks/deskBooking.duck";
import { selectSelectedOffice } from "../../../../store/ducks/officeView.duck";

export function ResetDesksDialog() {
  const {t} = useTranslation();
  const selectedDesks = useSelector(selectSelectedDeskIds);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedOffice = useSelector(selectSelectedOffice);

  const close = () => dispatch(closeDialog());

  const resetDesks = async () => {
    setLoading(true);
    try {
      await resetDeskOwnership(selectedDesks);
      dispatch(setSelectedDeskIds([]));
      dispatch(successNotification('Cleared desks of any assigned users, groups or teams'));
      if (selectedOffice) {
        dispatch(fetchDesks(selectedOffice.id));
      }
      close();
    } catch (e) {
      console.log(e);
      dispatch(failureNotification('Failed to reset desks'))
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog isOpen={true} onClose={close} showLogo={true}>
        <DialogHeader title={'reset-desks-dialog.reset-desks'} />
        {loading ? <Center>
          <LoadingSpinner hideBorder={true} hideText={true} />
        </Center> : <>

          <FlatContentCard style={{marginBottom: 16}}>
            <Column>
              <BodyRegular>{t('reset-desks-dialog.reset-all-desks', {count: selectedDesks.length})}</BodyRegular>
            </Column>
          </FlatContentCard>

          <PrimaryButton click={resetDesks}
                         style={{marginBottom: 16}}
                         text={'reset-desks-dialog.reset-desks'}
                         fullWidth={true} />

          <OutlineButton click={close}
                         text={'reset-desks-dialog.cancel'}
                         fullWidth={true} />
        </>}
      </Dialog>
    </>
  )
}
