import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../store/state/app.state";
import { HolidayAllowanceData, UsersHolidayAllowances } from "./holidayAllowances.model";
import { agent } from "../../../../services/agent";
import { updateHolidayAllowance } from "../../../../services/HolidayService";
import { warningNotification } from "../../../../store/ducks/notification.duck";

export interface HolidayAllowancesState {
  loading: boolean;
  holidayAllowances: UsersHolidayAllowances[];
  totalRecords: number;
  count: number;
  skip: number;
  groupSelection: string;
}

export const initialHolidayAllowancesState: HolidayAllowancesState = {
  loading: false,
  holidayAllowances: [],
  totalRecords: 0,
  count: 10,
  skip: 0,
  groupSelection: '',
}

export const loadUsersAllowances: any = createAsyncThunk(
  'holidayAllowances/loadUsersAllowances',
  async (params: {groupSelection?: string, skip?: number}, thunkAPI) => {

    const state = (thunkAPI.getState() as AppState).holidayAllowances;

    const groupSelection = params?.groupSelection ?? '';
    const skip = params?.skip ?? state.skip;

    if (!groupSelection) {
      return state;
    } else {
      const response = await agent.requests.get(`/api/holidays/allowances?id=${groupSelection}&skip=${skip}&count=10`);

      return {
        ...state,
        totalRecords: response.total,
        holidayAllowances: response.usersHolidayAllowances,
        groupSelection: groupSelection,
        skip: skip,
      }
    }
  }
)

export const editHolidayAllowance: any = createAsyncThunk(
  'holidayAllowances/editHolidayAllowance',
  async (params: {user: string, year: number, basicAllowance: number, additionalDays: number}, thunkAPI) => {

    const state = (thunkAPI.getState() as AppState).holidayAllowances;
    const allowances = state.holidayAllowances;

    const usersAllowances = allowances.find((a: UsersHolidayAllowances) => a.userId === params.user)
    if (usersAllowances) {
      const allowancePeriod = usersAllowances.allowanceData.find((ad: HolidayAllowanceData) => ad.holidayYear === params.year);
      if (allowancePeriod) {
        const updatedAllowancePeriod = {
          ...allowancePeriod,
          basicAllowance: params.basicAllowance,
          additionalDays: params.additionalDays,
          edited: true,
        }

        const updatedPeriods = usersAllowances.allowanceData.map(obj => obj.holidayYear === updatedAllowancePeriod.holidayYear ? updatedAllowancePeriod : obj);
        const updatedUsersAllowance = {...usersAllowances, allowanceData: updatedPeriods};

        const updatedAllowances = allowances.map(obj => obj.userId === updatedUsersAllowance.userId ? updatedUsersAllowance : obj);
        return updatedAllowances;
      }
    }
    return allowances;
  }
)

export const saveAllHolidayAllowances: any = createAsyncThunk(
  'holidayAllowances/saveHolidayAllowance',
  async (params: {}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState).holidayAllowances;
    const allowances = state.holidayAllowances;

    const flatend = allowances.map(a =>
      a.allowanceData.map(ad => ({...a, ...ad}))).flat().filter(a => !!a.edited);

    if (flatend.length > 0) {
      await Promise.all(flatend.map(async (a) => {
        return updateHolidayAllowance({
          holidayYear: a.holidayYear,
          userId: a.userId,
          basicAllowance: a.basicAllowance,
          additionalDays: a.additionalDays,
        })
      }));
      thunkAPI.dispatch(loadUsersAllowances( {groupSelection: state.groupSelection, skip: state.skip}));
    } else {
      thunkAPI.dispatch(warningNotification('Nothing to update'));
    }
  }
)

const holidayAllowancesSlice = createSlice({
  name: 'holidayAllowances',
  initialState: initialHolidayAllowancesState,
  reducers: {
    reset: () => initialHolidayAllowancesState,
  },
  extraReducers: {
    [loadUsersAllowances.pending]: (state) => ({...state, holidayAllowances: [], loading: true,}),
    [loadUsersAllowances.reject]: (state) => ({...state, loading: false,}),
    [loadUsersAllowances.fulfilled]: (state, action) => ({
      ...state,
      totalRecords: action.payload.totalRecords,
      holidayAllowances: action.payload.holidayAllowances,
      groupSelection: action.payload.groupSelection,
      skip: action.payload.skip,
      loading: false,
    }),

    [saveAllHolidayAllowances.pending]: (state) => ({...state, loading: true,}),
    [saveAllHolidayAllowances.reject]: (state) => ({...state, loading: false,}),

    [editHolidayAllowance.fulfilled]: (state, action) => {
      return {
        ...state,
        holidayAllowances: action.payload,
      }
    },

  }
})

export default holidayAllowancesSlice.reducer;
export const {
  reset,

} = holidayAllowancesSlice.actions;

// Selectors
export const selectIsLoading = (state: AppState) => state.holidayAllowances.loading;
export const selectGroupSelection = (state: AppState) => state.holidayAllowances.groupSelection;
export const selectHolidayAllowances = (state: AppState) => state.holidayAllowances.holidayAllowances;
export const selectPaginationInfo = (state: AppState) => ({
  totalRecords: state.holidayAllowances.totalRecords,
  count: state.holidayAllowances.count,
  skip: state.holidayAllowances.skip,
});
export const selectIncludesAnyEdits = (state: AppState) => state.holidayAllowances.holidayAllowances.filter(ha => ha.allowanceData.filter(ad => !!ad.edited)).flat().length > 0
export const selectHolidayAllowanceEdits = (state: AppState) => state.holidayAllowances.holidayAllowances.filter(ha => ha.allowanceData.filter(ad => !!ad.edited).length > 0)
