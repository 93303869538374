import React, { useState } from 'react';
import { ConfigurationSection } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { useDispatch, useSelector } from "react-redux";
import { selectIconPack } from "../../../../store/ducks/auth.duck";
import { updateWhereaboutsIconPack } from "../../../../store/ducks/onboarding.duck";
import { useTranslation } from "react-i18next";
import { IconPack } from "../../../../models/company.models";
import { ToggleableOutlineButton } from "../../atoms/buttons/ToggleableOutlineButton";
import { Row } from "../../atoms/StructuralLayout";

export function WhereaboutsIconPackSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const iconPack = useSelector(selectIconPack);
  const {t} = useTranslation();

  const onClick = (iconPack: IconPack) => {
    setIsLoading(true);
    try {
      dispatch(updateWhereaboutsIconPack(iconPack));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ConfigurationSection style={{marginTop: 32, marginBottom: 32}}>
      <BodyLarge colour={Colours.blue}>{t('settings.icon-pack.title')}</BodyLarge>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.icon-pack.description')}</BodyRegular>
      <Row>
        <ToggleableOutlineButton isActive={iconPack === IconPack.STANDARD}
                                 click={() => onClick(IconPack.STANDARD)}
                                 disabled={isLoading}
                                 text={'settings.icon-pack.standard'} />
        <ToggleableOutlineButton isActive={iconPack === IconPack.BASIC}
                                 click={() => onClick(IconPack.BASIC)}
                                 disabled={isLoading}
                                 text={'settings.icon-pack.basic'} />
      </Row>
    </ConfigurationSection>
  )
}
