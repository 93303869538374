import { failureNotification } from "../store/ducks/notification.duck";

export function safePrintError(err: any, dispatch: any, fallBackMessage?: string) {
  try {
    if (err.message) {
      const parsed = JSON.parse(err.message);
      if (parsed.message) {
        dispatch(failureNotification(parsed.message));
        return;
      }
    }
  } catch (e: any) {

  }
  dispatch(failureNotification(fallBackMessage || 'Action failed'));
}

export function parseErrorFromException(err: any): string {
  try {
    return JSON.parse(err.message).message
  } catch (e) {
    return 'Unknown error';
  }
}

export enum ErrorCode {
  VerificationRequired,
  Unknown,
  TeamNameAlreadyExists,
  EmailAlreadyExists,
  FailedLogin,
  OfficeContainsArea,
  LoginRequired,
}

export interface Error {
  title: string;
  description: string;
  id: ErrorCode;
}

const messages: any = {
  'Email verification required': {
    title: 'Email verification required',
    description: 'Check your email for a verification email.',
    id: ErrorCode.VerificationRequired,
  },
  'default': {
    title: 'Unknown Error',
    description: '',
    id: ErrorCode.Unknown,
  },
  'unk': {
    title: 'Unknown Error',
    description: '',
    id: ErrorCode.Unknown,
  },
  'Team already exists': {
    title: 'Team already exists',
    description: 'You already have a team with that name.',
    id: ErrorCode.TeamNameAlreadyExists,
  },
  'Email already exists': {
    title: 'Email already exists',
    description: 'That email address is already taken.',
    id: ErrorCode.EmailAlreadyExists,
  },
  'Failed login': {
    title: 'Invalid email or password',
    description: "Try clicking 'Forgot Password' if you're having trouble logging in.",
    id: ErrorCode.FailedLogin,
  },
  'Login failed - account users Google': {
    title: 'Wrong type of log in used.',
    description: "This account uses Google to log in. Select 'Log in with Google' and follow the steps to log into this account.",
    id: ErrorCode.FailedLogin,
  },
  'Login failed - account users Office 365': {
    title: 'Wrong type of log in used.',
    description: "This account uses Office 365 to log in. Select 'Log in with Office 365' and follow the steps to log into this account.",
    id: ErrorCode.FailedLogin,
  },
  'This office contains an area': {
    title: 'This office contains an area.',
    description: "You cannot delete an office with an associated area. Please contact hello@team-today.com to make amends to this office.",
    id: ErrorCode.OfficeContainsArea,
  },
  'reg': {
    title: 'No account registered.',
    description: "You can register for an account or ask a colleague for an invite. If you think you already have an account please contact hello@team-today.com.",
    id: ErrorCode.OfficeContainsArea,
  },
  'azu': {
    title: 'Failed to log in with Microsoft.',
    description: "Failed to log in with Microsoft, please try again or is the problem persists contact hello@team-today.com.",
    id: ErrorCode.FailedLogin,
  },
  'goog': {
    title: 'Failed to log in with Google.',
    description: "Failed to log in with Google, please try again or is the problem persists contact hello@team-today.com.",
    id: ErrorCode.FailedLogin,
  },
  '401': {
    title: '',
    description: "",
    id: ErrorCode.LoginRequired,
  },
  'canByUser': {
    title: 'Failed to log in with Microsoft.',
    description: "This step is required to advance, a popup will appear and you can use your Microsoft account to log in. If you have any questions contact us at hello@team-today.com",
    id: ErrorCode.FailedLogin,
  },
}

function defaultError(message: string) {
  return {
    title: message,
    description: message,
    id: ErrorCode.Unknown,
  }
}

export function enrichError(message: string | undefined): Error {
  if (!message) {
    return messages('default');
  }
  if (messages[message]) {
    return messages[message]
  } else {
    return defaultError(message);
  }
}
