import React, { useMemo, useState } from 'react';
import { agent } from '../../../services/agent';
import { OutlineButton } from "../atoms/buttons/OutlineButton";
import { useSelector } from "react-redux";
import { DATE_FORMAT } from "../../../utils/DateUtils";
import styled from "styled-components/macro";
import { selectSelectedOffice } from "../../../store/ducks/officeView.duck";
import { toCsvDownload } from "../../../services/ReportingService";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { canUserViewReportingPage, isTeamAdminUser } from "../../../utils/AccessControl";
import { selectActiveDay } from "../../../store/ducks/editMovements.duck";
import { selectAllOfficeEntities } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import { getFullOfficeHierarchy } from "../../../utils/OfficeHelper";

export function DownloadDailyCarParkReportButton(props: Props) {
  const activeDate = useSelector(selectActiveDay);
  const selectedOffice = useSelector(selectSelectedOffice);
  const allOffices = useSelector(selectAllOfficeEntities);
  const currentUser = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);

  const officeHasCarParking = useMemo(() => {
    return getFullOfficeHierarchy(allOffices, [], selectedOffice).filter(o => !!(o?.carParkCapacity)).length > 0
  }, [selectedOffice, allOffices]);

  const onClick = async () => {
    try {
      setLoading(true);
      if (!selectedOffice || !activeDate) return;
      const dateString = activeDate.format(DATE_FORMAT)
      const response = await agent.requests.get(`/api/report/car-park-attendees?date=${dateString}&officeId=${selectedOffice.id}`);
      toCsvDownload(response,
        `${selectedOffice.label} Car park attendees for ${dateString}`,
        ["firstName", "lastName", "colour", "make", "registration", "period"]);
    } finally {
      setLoading(false);
    }
  }

  if (!canUserViewReportingPage(currentUser) || officeHasCarParking || isTeamAdminUser(currentUser)) return null;

  return (
    <Wrapper>
      <OutlineButton click={onClick} fullWidth={true} disabled={loading}
                     text={"button.download-car-park-attendees"} />
    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 6px 0;
  padding: 0 12px;
`
