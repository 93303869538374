import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { sendInvite } from '../../services/TeamService';
import { failureNotification } from "./notification.duck";
import { enrichError } from "../../utils/error-helper";
import { TeamEntity } from "../../models/team.models";
import { loadAccountInfo } from "./payments.duck";
import { loadTeamMovementById } from "./companyMovements.duck";
import { BasicUserInfo } from "../../models/user.models";
import { updateUsersTeam } from "../../services/UserService";
import { setCalendarDate } from "./dashboard.duck";
import moment from "moment";
import { loadConfig } from "./config.duck";

export enum AddMemberDialogMode {
  Add, Move,
}

export interface AddMemberDialogState {
  isOpen: boolean;
  processing: boolean;
  success: boolean;
  error: string;
  mode: AddMemberDialogMode;
  teamToAddTo: TeamEntity | undefined;
  failedRequest: {
    firstname: string;
    lastname: string;
    email: string;
  }
}

export const initialAddMemberState: AddMemberDialogState = {
  isOpen: false,
  processing: false,
  success: false,
  error: '',
  mode: AddMemberDialogMode.Move,
  teamToAddTo: undefined,
  failedRequest: {
    firstname: '',
    lastname: '',
    email: '',
  }
}


export const moveTeamMember: any = createAsyncThunk(
  'addMemberDialog/moveTeamMember',
  async (params: {user: BasicUserInfo, team: TeamEntity}, thunkAPI) => {
    await updateUsersTeam(params.user.userId, params.team.id);
    thunkAPI.dispatch(loadConfig());
  }
)

export const addNewTeamMember = createAsyncThunk(
  'addMemberDialog/addMember',
  async (addMemberDetails: any, thunkAPI) => {
    const { firstname, lastname, email, teamId } = addMemberDetails;
    await thunkAPI.dispatch(setProcessing());
    try {
      await sendInvite(email, firstname, lastname, teamId);
      await thunkAPI.dispatch(setSuccess());

      await thunkAPI.dispatch(loadTeamMovementById(teamId));
      await thunkAPI.dispatch(loadAccountInfo());

      // Hack to refresh team
      await thunkAPI.dispatch(setCalendarDate(moment((thunkAPI.getState() as AppState).dashboard.calendarDate)));

    } catch (err: any) {
      const errResp = enrichError(JSON.parse(err.message).message);
      await thunkAPI.dispatch(setError({ message: errResp.description, details: addMemberDetails }));
      await thunkAPI.dispatch(failureNotification(errResp.description));
    }
  }
);

const addMemberDialogSlice = createSlice({
  name: 'editMovements',
  initialState: initialAddMemberState,
  reducers: {
    setTeamToAddUserTo: (state, action) => ({...state, teamToAddTo: action.payload}),
    closeAddMemberDialog: () => initialAddMemberState,
    setProcessing: (state) => ({...state, processing: true, success: false, error: ''}),
    setSuccess: (state) => ({...state, processing: false, success: true, error: ''}),
    setAddMemberDialogMode: (state, action) => ({
      ...state,
      isOpen: state.isOpen,
      mode: action.payload,

    }),
    setError: (state, action) => ({
      ...state,
      processing: false,
      success: false,
      error: action.payload.message,
      failedRequest: action.payload.details
    }),
    resetAddMember: (state) => ({
      ...initialAddMemberState,
      mode: AddMemberDialogMode.Add,
      teamToAddTo: state.teamToAddTo,
      success: false,
      processing: false,
      isOpen: state.isOpen,
    }),
    resetAddExistingMember: (state) => ({
      ...initialAddMemberState,
      mode: AddMemberDialogMode.Move,
      teamToAddTo: state.teamToAddTo,
      success: false,
      processing: false,
      isOpen: state.isOpen,
    }),
  },
  extraReducers: {
    [moveTeamMember.pending]: (state) => ({...state, processing: true, success: false}),
    [moveTeamMember.reject]: (state) => ({...state, processing: false, success: false}),
    [moveTeamMember.fulfilled]: (state) => ({...state, processing: false, success: true}),
  }
})

export default addMemberDialogSlice.reducer;
export const {
  closeAddMemberDialog,
  setTeamToAddUserTo,
  setAddMemberDialogMode,
  setProcessing,
  setSuccess,
  setError,
  resetAddMember,
  resetAddExistingMember,
} = addMemberDialogSlice.actions;

// Selectors
export const selectIsAddMemberDialogOpen = (state: AppState) => state.addMemberDialog.isOpen;
export const selectActiveAddMemberDialogMode = (state: AppState) => state.addMemberDialog.mode;
export const selectTeamToAddTo = (state: AppState) => {
  return state.addMemberDialog.teamToAddTo
};
export const selectAddMemberProcessingState = (state: AppState) => ({
  processing: state.addMemberDialog.processing,
  error: state.addMemberDialog.error,
  success: state.addMemberDialog.success,
  failedRequest: state.addMemberDialog.failedRequest,
});
