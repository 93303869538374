import React from 'react';
import { FadeAndPopOnLoad } from '../../../animations/AnimationVariants';

interface Props {
  dataTest: string;
  children: any;
}

export function OnboardingContainer(props: Props) {
  return (
    <FadeAndPopOnLoad className="onboarding">
      <div data-test={props.dataTest}>
        {props.children}
      </div>
    </FadeAndPopOnLoad>
  )
}