import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { DeskSpotOnMap } from "../../data/OfficeMap.model";
import { useSelector } from "react-redux";
import { selectAllTeams } from "../../../../../store/ducks/config.duck";
import { selectAllTags } from "../../../../../store/ducks/userTags.duck";
import { TeamWithCount } from "../../../../../models/team.models";
import { TagWithCount } from "../../../../../models/tags.models";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { AssignedUsers } from "../../../../../services/DeskBookingService";

export function AssignedGroupsPopup(props: Props) {
  const {x, y, deskSpot} = props;
  const allTeams = useSelector(selectAllTeams);
  const allTags = useSelector(selectAllTags);

  const assignedTeams = useMemo(() => {
    return (deskSpot?.teamIds ?? [])
      .map(id => allTeams.find(t => t.id === id))
      .filter(t => !!t) as TeamWithCount[];
  }, [allTeams, deskSpot]);

  const assignedTags = useMemo(() => {
    return (deskSpot?.workgroupIds ?? [])
      .map(id => allTags.find(t => t.id === id))
      .filter(t => !!t) as TagWithCount[];
  }, [allTags, deskSpot]);

  if (assignedTeams.length === 0 && assignedTags.length === 0 && (deskSpot?.users ?? []).length === 0) {
    return null;
  }

  return (
    <Wrapper x={x} y={y}>

      {(deskSpot?.users ?? []).map((user: AssignedUsers, key: number) => <BodyRegular key={key}
                                                                                      colour={Colours.white}
                                                                                      weight={600}>{`${user.firstname} ${user.lastname}`}</BodyRegular>)}

      {assignedTeams.map((t: TeamWithCount, key: number) => <BodyRegular key={key}
                                                                         colour={Colours.white}>{t.name}</BodyRegular>)}

      {assignedTags.map((t: TagWithCount, key: number) => <BodyRegular key={key}
                                                                       colour={Colours.white}>{t.label}</BodyRegular>)}

    </Wrapper>
  )
}

interface Props {
  deskSpot: DeskSpotOnMap;
  x: number;
  y: number;
}


const Wrapper = styled.div<any>`
  min-width: 100px;
  min-height: 80px;
  position: absolute;
  padding: 12px;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  background-color: ${Colours.darkBlue};
  box-shadow: 0px 16px 22px 0px rgba(48, 48, 50, 0.23);
  border-radius: 8px;
  z-index: 9999999;
`
