import React from 'react';
import { useLocation } from "react-router-dom";
import { WhereaboutsSubHeader } from "./WhereaboutsSubHeader";
import { CompanyViewSubHeader } from "./CompanyViewSubHeader";
import { LeavePlannerSubHeader } from "./LeavePlannerSubHeader";
import { MeetingRoomsSubHeader } from "./MeetingRoomsSubHeader";
import { BackButtonOnlySubHeader } from "./BackButtonOnlySubHeader";

enum HeaderView {
  None, TeamView, CompanyView, LeavePlanner, MeetingRooms, BackButtonOnly
}

function showBackButtonOnlyHeader(url: string): boolean {
  return url.includes('/holidays/settings/allowance');
}

function getHeaderViewToShow(url: string): HeaderView {
  if (showBackButtonOnlyHeader(url)) {
    return HeaderView.BackButtonOnly;
  }

  if (url.includes('/whereabouts/team') || url.includes('/whereabouts/visitor-booking') || url.includes('/whereabouts/work-planner') || url.includes('/whereabouts/floor-planner') || url.includes('/whereabouts/office-view')) {
    return HeaderView.TeamView;
  } else if (url.includes('/whereabouts/company')) {
    return HeaderView.CompanyView;
  } else if (url.includes('/holidays')) {
    return HeaderView.LeavePlanner;
  } else if (url.includes('/meeting-rooms')) {
    return HeaderView.MeetingRooms;
  }

  return HeaderView.None;
}

export function SubHeader(props: Props) {
  const location = useLocation();
  const headerType = getHeaderViewToShow(location.pathname);

  return (
    <>
      {headerType === HeaderView.BackButtonOnly && <BackButtonOnlySubHeader />}
      {headerType === HeaderView.TeamView && <WhereaboutsSubHeader />}
      {headerType === HeaderView.CompanyView && <CompanyViewSubHeader />}
      {headerType === HeaderView.LeavePlanner && <LeavePlannerSubHeader />}
      {headerType === HeaderView.MeetingRooms && <MeetingRoomsSubHeader />}
    </>
  )
}

interface Props {
}
