import React from 'react';
import styled from "styled-components/macro";
import { Logo } from "../../UI/atoms/Logo";
import { BodyLarge, BodySmall } from "../../UI/atoms/fonts/Body";
import { Trans, useTranslation } from "react-i18next";
import { HorizontalSpacer } from "../../UI/atoms/VerticalSpacer";
import { Link } from "react-router-dom";
import Colours from "../../UI/atoms/Colours";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { setRegisterAuthType } from "../../../store/ducks/auth.duck";
import { AuthType } from "../../../utils/AuthUtils";
import { DialogIdentifiers, openDialog } from "../../../store/ducks/dialog.duck";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { desktop, desktopSmall, desktopSuperWide, tablet } from "../../UI/atoms/MediaQueries";

export function MsTeamsLoginPage(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const registerWithMs = () => {
    dispatch(setRegisterAuthType(AuthType.Microsoft));
    dispatch(openDialog(DialogIdentifiers.SignUpTermsDialog));
    ReactGA.event({category: 'NewUser', action: 'Register with Microsoft'});
  }

  return (
    <Screen>
      <BackgroundImage src={"/assets/images/teams-login-background.svg"} />
      <Content>
        <Left>
          <SignupContent>
            <Logo />
            <BodyLarge style={{marginBottom: 32, fontSize: 32, textAlign: 'center'}}>
              <Trans
                i18nKey="teams-register.title"
                components={{ italic: <i />, bold: <strong /> }}
              />
            </BodyLarge>
            <ul>
              <li>
                <ListIcon src={"/assets/images/circle-tick.svg"} alt={""} />
                <BodyLarge>
                  <Trans
                    i18nKey="teams-register.list-item-1"
                    components={{ italic: <i />, bold: <strong /> }}
                  />
                </BodyLarge>
              </li>
              <li>
                <ListIcon src={"/assets/images/circle-tick.svg"} alt={""} />
                <BodyLarge>
                  <Trans
                    i18nKey="teams-register.list-item-2"
                    components={{ italic: <i />, bold: <strong /> }}
                  />
                </BodyLarge>
              </li>
              <li>
                <ListIcon src={"/assets/images/circle-tick.svg"} alt={""} />
                <BodyLarge>
                  <Trans
                    i18nKey="teams-register.list-item-3"
                    components={{ italic: <i />, bold: <strong /> }}
                  />
                </BodyLarge>
              </li>
            </ul>
            <StartedMessage>{t('teams-register.started')}</StartedMessage>

            <LoginButton dataTest="outlookLogin"
                         className="login__button"
                         text="register.office365"
                         click={registerWithMs}
                         borderColour={Colours.black}
                         size="large"
                         imageUrl="/assets/icons/microsoft-office-logo.svg"/>

            <HorizontalSpacer style={{marginTop: 48, marginBottom: 36}} colour={Colours.iconGrey} />
            <BodySmall>
              <Trans
                i18nKey="teams-register.create-account"
                components={[<Link to="/register?splash=hide" />]}
              />
            </BodySmall>
            <BodySmall>
              <Trans
                i18nKey="teams-register.have-account"
                components={[<Link to="/login?splash=hide" />]}
              />
            </BodySmall>
          </SignupContent>
        </Left>
        <Right></Right>

      </Content>
    </Screen>
  )
}

interface Props {

}

const SignupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  min-height: 80vh;
  max-height: 90vh;
  height: 100vh;
  margin: 0 auto;
  p {
    white-space: pre-line;
  }
  p a {
    text-decoration: none;
    color: ${Colours.blue};
  }
  .teamToday__logo {
    width: 280px;
    margin-bottom: 32px;
  }
  ul {
    margin-bottom: 56px;
    list-style: none;
  }
  ul li {
    margin-bottom: 12px;
    display: flex;
  }
  ul li img {
    margin-right: 16px;
  }
  @media (${tablet}) {
    margin: 5vh 0 0 auto;
  }
`

const ListIcon = styled.img`
  width: 32px;
  height: 32px;
`

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
`

const Left = styled.div`
  width: 100%;
  @media (${tablet}) {
    width: 60%;
  }
  @media (${desktop}) {
    width: 50%;
  }
`

const Right = styled.div`
  width: 40%;
  display: none;
  @media (${tablet}) {
    display: block;
  }
  @media (${desktop}) {
    width: 50%;
  }
`

const BackgroundImage = styled.img`
  min-height: 100vh;
  max-height: 100vh;
  height: 900px;
  position: absolute;
  top: 0;
  left: 55%;
  transform: translate(-45%, 0);
  display: none;
  @media (${tablet}) {
    display: block;
  }
  @media (${desktopSmall}) {
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (${desktopSuperWide}) {
    left: unset;
    transform: unset;
    right: 0;
  }
`

const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`

const StartedMessage = styled<any>(BodyLarge)<any>`
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 16px;
  letter-spacing: -0.32px;
`

const LoginButton = styled<any>(OutlineButton)<any>`
  padding: 20px 64px;
  max-height: unset;
  width: unset;
  max-width: unset;
  height: unset;
  min-height: unset;
`
