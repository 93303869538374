import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BodyError, BodyRegular } from '../../UI/atoms/fonts/Body';
import { LinkButton } from '../../UI/atoms/buttons/LinkButton';
import TextField from '../../UI/atoms/TextField';
import {
  resetPassword,
  selectError,
  selectIsComplete,
  selectIsLoading,
  selectIsStaleToken
} from '../../../store/ducks/forgotPassword.duck';
import { getParameterByName } from '../../../utils/UrlUtils';
import AuthWrapper from './components/AuthWrapper';
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  document.body.style.backgroundColor = '#FAFAFA';
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const isComplete = useSelector(selectIsComplete);
  const isStaleToken = useSelector(selectIsStaleToken);
  const error = useSelector(selectError);
  const resetId = getParameterByName('reset', window.location.href) || '';
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [validationError, setValidationError] = useState('');
  const {t} = useTranslation();

  const submit = () => {
    if (password !== repeatPassword) {
      setValidationError('reset-password.passwords-not-match');
    } else if (password === ''){
      setValidationError('reset-password.passwords-not-blank');
    } else {
      dispatch(resetPassword({password, resetId}));
    }
  }

  if (isLoading) {
    return (
      <ResetPasswordWrapper loading={true}>
      </ResetPasswordWrapper>
    )
  }

  if (isStaleToken) {
    return (
      <ResetPasswordWrapper>
        <BodyRegular>{t('reset-password.stale-token')}</BodyRegular>
        <LinkButton fullWidth={true} size="large" className="resetPassword__button" text="reset-password.forgot-password" link="/forgot-password"/>
      </ResetPasswordWrapper>
    )
  }

  if (isComplete && !error) {
    return (
      <ResetPasswordWrapper>
        <BodyRegular>{t('reset-password.success')}</BodyRegular>
        <LinkButton fullWidth={true} size="large" className="resetPassword__button" text="reset-password.go-to-login" link="/login"/>
      </ResetPasswordWrapper>
    )
  }

  if (error) {
    return (
      <ResetPasswordWrapper>
        <BodyError>{t(error)}</BodyError>
        <BodyRegular>{t('reset-password.try-requesting-new-email-reset')}</BodyRegular>
        <LinkButton fullWidth={true} className="resetPassword__button" text="reset-password.request-new-email-reset" link="/forgot-password"/>
      </ResetPasswordWrapper>
    )
  }

  return (
    <ResetPasswordWrapper>
      <BodyRegular>{t('reset-password.reset-pass-to-login')}</BodyRegular>
      <BodyError>{t(validationError)}</BodyError>
      <TextField label="reset-password.new-pass" type="password" onChange={(e: string) => setPassword(e)} value={password} />
      <TextField label="login.repeat-password" type="password" onChange={(e: string) => setRepeatPassword(e)} value={repeatPassword} />
      <PrimaryButton size="large"
                     fullWidth={true}
                     text="reset-password.reset-pass"
                     click={() => submit()}
                     className="resetPassword__button" />
    </ResetPasswordWrapper>
  );
}

function ResetPasswordWrapper(props: {children: any, loading?: boolean}) {
  return (
    <AuthWrapper dataTest="resetPass" title="reset-password.reset-pass" spinner={props.loading}>
      <div className="forgotPassword__content">
        {props.children}
      </div>
    </AuthWrapper>
  );
}
