import React, { useEffect, useState } from 'react';
import TextField from "../../atoms/TextField";
import { useDispatch, useSelector } from "react-redux";
import { updateCompanyName } from "../../../../services/CompanyService";
import { selectCurrentUser, setUser } from "../../../../store/ducks/auth.duck";
import { warningNotification } from "../../../../store/ducks/notification.duck";

export function CompanyNameSetting(props: Props) {
  const {editable} = props;
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    setCompanyName(user?.companyEntity?.name ?? '');
  }, [user]);

  const saveCompanyName = async () => {
    if (loading) return;
    if (!companyName || companyName.trim().length === 0) {
      dispatch(warningNotification('Company name cannot be empty'))
    }
    setLoading(true);
    try {
      await updateCompanyName(companyName);
      if (user) {
        dispatch(setUser({...user, companyEntity: {...user.companyEntity, name: companyName} }))
      }
    } finally {
      setLoading(false);
    }
  }

  const updateCompanyNameValue = (e: any) => {
    setCompanyName(e)
  }

  return (
    <>
      <TextField className="management__textBox" disabled={!editable || loading}
                 label={props?.fieldLabel ?? 'textinput.company-settings'}
                 fullWidth={!!props.fullWidth}
                 value={companyName}
                 style={props?.style ?? {}}
                 onChange={updateCompanyNameValue}
                 onBlur={saveCompanyName}/>
    </>
  )
}

interface Props {
  editable: boolean;
  fieldLabel?: string;
  fullWidth?: boolean;
  style?: any;
}
