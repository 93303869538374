import React, { CSSProperties } from 'react';
import { AnimatePresence, motion } from "framer-motion";

export const FadeAndPopVariant = {
  hidden: {
    opacity: 0,
    display: 'none',
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}
export const FadeVariant = {
  hidden: {
    opacity: 0,
    display: 'none',
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

export function Fade(props: {hidden: boolean, children: any, className?: string}) {
  return (
    <motion.div animate={props.hidden ? "hidden" : "visible"} variants={FadeVariant} className={props.className || ''}>
      {props.children}
    </motion.div>
  )
}

export function FadeAndPop(props: {hidden: boolean, children: any, className?: string, onClick?: (e?: any) => any}) {
  return (
    <motion.div onClick={props.onClick} animate={props.hidden ? "hidden" : "visible"} variants={FadeAndPopVariant} className={props.className || ''}>
      {props.children}
    </motion.div>
  )
}

export function SlideAndFadeUp(props: {hidden: boolean, children: any, className?: string, onClick?: (e?: any) => any}) {
  return (
    <motion.div onClick={props.onClick} animate={props.hidden ? "hidden" : "visible"} variants={{
      hidden: {
        opacity: 0,
        display: 'none',
        y: -30,
      },
      visible: {
        opacity: 1,
        y: -65,
        transition: {
          delay: .5
        }
      },
    }} className={props.className || ''}>
      {props.children}
    </motion.div>
  )
}

export function Accordian(props: {isOpen: boolean, children: any, className?: string, delay?: number, style?: CSSProperties}) {
  const {isOpen, children, className, style} = props;
  const delay = props.delay ?? 0;
  return (
    <AnimatePresence initial={false}>
      {isOpen &&
      <motion.div
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        style={style ?? {}}
        variants={{
          open: { opacity: 1, height: "auto" },
          collapsed: { opacity: 0, height: 0 }
        }}
        transition={{ delay: delay, duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
        className={className ?? ''}>
        {children}
      </motion.div>

      }
    </AnimatePresence>
  )
}

export function DisableFadeOut(props: {isOpen: boolean, children: any, className: string}) {
  const {isOpen, children, className} = props;
  return (
    <motion.div
      key="content"
      initial={isOpen ? "enabled" : "disabled"}
      animate={isOpen ? "enabled" : "disabled"}
      exit="disabled"
      variants={{
        enabled: { opacity: 1, },
        disabled: { opacity: .5, }
      }}
      transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
      className={className}>
      {children}
    </motion.div>
  )
}

export function FadeAndPopOnLoad(props: {children: any, className?: string}) {
  return (
    <AnimatePresence initial={true}>
      <motion.div
        initial={{ opacity: 0, y: 40, }}
        animate={{ opacity: 1, y: 0, }}
        exit={{ opacity: 0 }} className={props.className ?? ''}>
          {props.children}
      </motion.div>
    </AnimatePresence>
  )
}
