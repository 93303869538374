import React, { useEffect, useMemo } from 'react';
import styled from "styled-components/macro";
import TeamDropDownList from "../../../../UI/organisms/team-drop-down-list/TeamDropDownList";
import { useDispatch, useSelector } from "react-redux";
import DateFlicker, { DateScale } from "../../../../UI/molecules/DateFlicker";
import { selectSelectedGroupId, setSelectTeamForApprovalView } from "../../../../../store/ducks/approvalRequests.duck";
import { naturalSort } from "../../../../../utils/ArrayUtils";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import {
  isBasicUser,
  isCompanyAdminUser,
  isOfficeAdminUser,
  isTeamAdminUser
} from "../../../../../utils/AccessControl";
import { TeamWithCount } from "../../../../../models/team.models";
import { selectAllTeams } from "../../../../../store/ducks/config.duck";
import { PageTitle } from "../../../../UI/atoms/PageTitle";
import { HasCurrentUserGotLineReports } from "../../../../../hooks/management-structure/HasCurrentUserGotLineReports";
import { Moment } from "moment";


export const SELECTED_GROUP_ID = 'selectedApprovalsGroupId';

export function HolidayApprovalsHeader(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const teams = useSelector(selectAllTeams);
  const selectedGroupId = useSelector(selectSelectedGroupId);

  const hasCurrentUserGotLineReports = HasCurrentUserGotLineReports();
  const isCurrentUserBasicUser = isBasicUser(currentUser);
  const userCanViewOtherApprovals = hasCurrentUserGotLineReports || !isCurrentUserBasicUser;

  const accessibleTeams = useMemo(() => {
    if (isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser)) {
      return teams;
    } else if (isTeamAdminUser(currentUser) && currentUser?.teamEntity) {
      return teams.filter((t: TeamWithCount) => t.id === currentUser?.teamEntity?.id);
    } else {
      return [];
    }
  }, [teams, currentUser]);

  const dropDownListContents = useMemo(() => {
    let selectionList = [
      { id: 'ME', name: 'Me', memberCount: 0 },
      { id: 'LINE_REPORTS', name: 'Line reports', memberCount: 0 },
      { id: 'INDIRECT_LINE_REPORTS', name: 'Indirect line reports', memberCount: 0 },
    ] as TeamWithCount[];
    const sortedTeams = naturalSort([...accessibleTeams], 'name');
    if (isCompanyAdminUser(currentUser)) {
      selectionList = [
        ...selectionList,
        ...sortedTeams,
      ]
    } else if (isTeamAdminUser(currentUser) && currentUser?.teamEntity) {
      selectionList.push(currentUser.teamEntity);
    }
    return selectionList;
  }, [accessibleTeams, currentUser]);

  const onTeamSelect = (teamId: string) => {
    if (teamId) {
      localStorage.setItem(SELECTED_GROUP_ID, teamId);
      dispatch(setSelectTeamForApprovalView(teamId));
    }
  }

  useEffect(() => {
    if (userCanViewOtherApprovals) {
      localStorage.removeItem(SELECTED_GROUP_ID);
      dispatch(setSelectTeamForApprovalView('ME'));
    } else {
      const preSelectedGroupId = localStorage.getItem(SELECTED_GROUP_ID) || 'ME';
      dispatch(setSelectTeamForApprovalView(preSelectedGroupId));
    }
  }, [dispatch, userCanViewOtherApprovals]);

  return (
    <>
      <Wrapper>
        <PageTitle style={{marginTop: 0}} text={'approvals.approvals-title'} />
        <DateFlicker showDateRange={true} dateScale={DateScale.Year} updateGlobalDate={true} />
        {userCanViewOtherApprovals && <TeamDropDownList teams={dropDownListContents} onSelect={onTeamSelect} selectedTeamId={selectedGroupId} />}
      </Wrapper>
    </>
  )
}

interface Props {
  holidayYearStartDate?: Moment;
}


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 15px -20px #000000;
  flex-direction: column;
  padding-bottom: 20px;
  @media (${tablet}) {
    flex-direction: row;
  }
  .weekFlicker {
    @media (${tablet}) {
      display: none !important;
    }
  }
  .teamDropDownList {
    margin-bottom: 0;
    max-height: 50px;
    @media (${tablet}) {
      max-width: 300px;
    }
  }
`
