import { agent } from "../../../../services/agent";
import { Department } from "./departments.models";
import { BasicUserInfo } from "../../../../models/user.models";
import { WhereaboutsDayV2 } from "../../../../models/movements.models";
import { UNKNOWN_OPTION, WhereaboutsOption } from "../../../../services/WhereaboutOptions";
import { UsersWorkBlock } from "../../../../models/wall-planner.models";
import moment from "moment/moment";
import { companyMovementsForConjoiningTypes } from "../../../../utils/Movement";

export async function getDepartmentsWhereabouts(departmentId: number, dateString: string): Promise<DepartmentsWhereabouts> {
  return agent.requests.get(`/api/wall-planner/department/${departmentId}/activities/${dateString}`)
}

export interface DepartmentsWhereabouts {
  departmentsEntity: Department;
  usersWhereabouts: UsersGroupedWhereabouts[];
}

export interface UsersGroupedWhereabouts {
  userInfo: BasicUserInfo;
  whereabouts: WhereaboutsDayV2[];
}

export interface UsersGroupedWhereaboutsAsWorkBlocks {
  userInfo: BasicUserInfo;
  whereabouts: UsersWorkBlock[];
}

export async function deleteDepartment(department: Department) {
  return await agent.requests.del(`/api/department/${department.id}`, true);
}

export function parseUsersGroupedWhereabouts(whereaboutsOptions: WhereaboutsOption[], whereaboutsDays: WhereaboutsDayV2[]): UsersWorkBlock[] {

  const getOptionFor = (statusId: number) => {
    return whereaboutsOptions.find(wo => wo.id === statusId) ?? UNKNOWN_OPTION;
  }

  let failSafeCounter = 0;
  let summerizedWorkList: UsersWorkBlock[] = [];

  // Enrich worklist with custom whereabouts
  let workList: UsersWorkBlock[] = whereaboutsDays.map((whereaboutsDay) => ({
    ...whereaboutsDay,
    amOption: getOptionFor(whereaboutsDay.amStatusId),
    pmOption: getOptionFor(whereaboutsDay.pmStatusId),
    fromDate: whereaboutsDay.date,
    toDate: whereaboutsDay.date,
    collection: [],
  }));

  workList.sort((a, b) => moment(a.fromDate).isBefore(moment(b.fromDate)) ? 1 : -1)

  while (workList.length > 0) {
    let current: UsersWorkBlock | undefined = workList.pop();
    if (!current) {
      console.log('Unexpectedly had nothing left in work list');
      break;
    }

    // Add current movement to collection list
    current = {
      ...current,
      collection: [current]
    }

    while (workList.length > 0) {
      const next = workList[workList.length - 1];
      if (moment(next.fromDate).diff(moment(current?.toDate), 'days') <= 1 &&
        companyMovementsForConjoiningTypes(getOptionFor(next.amStatusId), getOptionFor(current?.pmStatusId)) && // Check that next is the same as this day
        current?.amStatusId === current?.pmStatusId && next.amStatusId === next.pmStatusId) { // On join on full and matching current and next days
        current = {
          ...current,
          toDate: next.fromDate,
          amStatusId: next.amStatusId,
          pmStatusId: next.pmStatusId,
          collection: [...(current.collection || []), next]
        };
        workList.pop();
      } else {
        break;
      }
    }

    summerizedWorkList.push(current);

    failSafeCounter++;
    if (failSafeCounter > 1000) {
      break;
    }
  }

  return summerizedWorkList;
}
