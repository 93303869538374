import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import isMobile from "is-mobile";

export function CarParkingPendingIcon(props: Props) {
  const {am, pm} = props;

  const carParkIcon = useMemo(() => {
    return isMobile() ? "/assets/icons/car-park-icon-pending.svg" : "/assets/icons/car-park-icon-pending.svg";
  }, [])

  if (!am && !pm) return null;
  return (<>
    {am && <AmOrAllDayParking src={carParkIcon} alt={"Car parking pending"} />}
    {!am && pm && <PmParking src={carParkIcon} alt={"Afternoon car parking pending"} />}
  </>)
}

interface Props {
  am: boolean;
  pm: boolean;
}

const AmOrAllDayParking = styled.img`
  position: absolute;
  z-index: 10;
  top: -6px;
  left: -6%;
`

const PmParking = styled.img`
  position: absolute;
  z-index: 10;
  top: -6px;
  right: -6%;
`
