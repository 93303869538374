import React from 'react';
import { Desk } from "../../../../../../services/DeskBookingService";
import { BodyRegular, BodySmall, BodyVerySmall } from "../../../../../UI/atoms/fonts/Body";
import styled from "styled-components";
import { Row } from "../../../../../UI/atoms/StructuralLayout";
import TextField, { TextFieldProps } from "../../../../../UI/atoms/TextField";
import { LinkButton } from "../../../../../UI/atoms/buttons/LinkButton";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import Colours from "../../../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";

export function ManageDeskControl(props: Props) {
  const {desks, parentOffice} = props;
  const {t} = useTranslation();

  if (desks.length === 0) {
    return <ManageDeskControlContainer>
      <BodyRegular weight={600}>{t('settings.manage-desk.create-desk')}</BodyRegular>
      <BodySmall style={{marginBottom: 16}}>{t('settings.manage-desk.message-1')}</BodySmall>
      <EditDeskButton>
        <LinkButton link={`/manage/hot-desking/edit/${parentOffice.id}/desks`}
                    type={"outline"}
                    size={"medium"}
                    fullWidth={true}
                    dataTest={"add-desk-button"}
                    text={"textinput.add-individual-desks"} />
      </EditDeskButton>
    </ManageDeskControlContainer>
  } else {
    return <ManageDeskControlContainer>
      <BodyRegular weight={600}>{t('settings.manage-desk.create-desk')}</BodyRegular>
      <BodySmall style={{marginBottom: 16}}>{t('settings.manage-desk.message-1')}</BodySmall>
      <BodyVerySmall colour={Colours.darkGrey}
                     style={{marginBottom: 4}}
                     weight={600}>{t('settings.manage-desk.number-of-desks')}</BodyVerySmall>
      <Row style={{alignItems: 'center'}}>

        <SmallTextField onChange={() => {}}
                   disabled={true}
                   dataTest={'desks-capacity-field'}
                   value={`${desks.filter((d: Desk) => d.active).length}`} />

        <EditDeskButton>
          <LinkButton link={`/manage/hot-desking/edit/${parentOffice.id}/desks`}
                      type={"outline"}
                      size={"medium"}
                      fullWidth={true}
                      dataTest={"add-desk-button"}
                      text={"textinput.desk-names-and-avail"} />
        </EditDeskButton>

      </Row>
    </ManageDeskControlContainer>
  }
}

interface Props {
  desks: Desk[];
  isEditable: boolean;
  parentOffice: OfficeEntity;
}

const ManageDeskControlContainer = styled.div`
  margin-bottom: 48px;
`;

const EditDeskButton = styled.div`
  width: 250px;
  max-width: 250px;
  margin-right: 16px;
`;

const SmallTextField = styled(TextField)<TextFieldProps>`
  max-width: 50px;
  margin: 0 16px 0 0;
  input {
    text-align: center;
  }
`;
