import React from 'react';
import { Link } from 'react-router-dom';
import { UserAvatarAndName } from '../../molecules/UserAvatarAndName';
import './user-list.scss';

export default function UserList(props: {users: any[], edittable: boolean}) {
  return (
    <div>
      {props.users.map((user, key) => (
        <div key={key} className="userList__row">
          { props.edittable ? <EditableUser user={user} /> : <SimpleUser user={user} /> }
        </div>
      ))}
    </div>
  )
}

function SimpleUser(props: {user: any}) {
  return (<div>
    <UserAvatarAndName firstName={props.user.firstName} lastName={props.user.lastName} isAdmin={props.user.teamAdmin} />
  </div>)
}

function EditableUser(props: {user: any}) {
  return (<Link to={`/manage/user/${props.user.id}`} className="noDecoration">
    <UserAvatarAndName colour="blue" firstName={props.user.firstName} lastName={props.user.lastName} isAdmin={props.user.teamAdmin} />
  </Link>)
}
