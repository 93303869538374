import React from 'react';
import styled, { css } from "styled-components/macro";
import Colours from "../Colours";
import { tablet } from "../MediaQueries";

interface HeadlineProps {
  color?: string;
  weight?: number;
}

const HeadlineBase = styled.h1<HeadlineProps>`
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1px;
  ${props => css`color: ${props.color ? props.color : Colours.black};`};
  font-family: "soleil", sans-serif;
`

const HeadlineXtraLarge = styled<any>(HeadlineBase)<HeadlineProps>`
  font-size: 48px;
  line-height: 50px;
  @include tablet {
    font-size: 62px;
    line-height: 64px;
  }
`

const HeadlineLarge = styled<any>(HeadlineBase)<HeadlineProps>`
  font-size: 38px;
  line-height: 38px;
  @media (${tablet}) {
    font-size: 44px;
    line-height: 46px;
  }
`

const HeadlineMedium = styled.h3<Props>`
  font-family: "soleil", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 32px;
  line-height: 34px;
  letter-spacing: -0.01em;
  ${props => css`color: ${props.color ? props.color : Colours.black};`};
  @media (${tablet}) {
    font-size: 32px;
    line-height: 34px;
  }
`

const HeadlineSmall = styled.h3<Props>`
  font-family: "soleil", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.01em;
  ${props => css`color: ${props.color ? props.color : Colours.black};`};
`

export {
  HeadlineXtraLarge,
  HeadlineLarge,
  HeadlineMedium,
  HeadlineSmall,
}

export function HeadlineXtraSmall(props: Props) {
  return (
    <h3 style={props.style ?? {}} className={`headline__xsmall ${props.className || ''}`.trim()}>{props.children}</h3>
  )
}

export function HeadlineVerySmall(props: Props) {
  const color = !!props.color ? `font--${props.color}` : '';
  return (
    <h3 style={props.style ?? {}} className={`headline__verysmall ${props.className || ''} ${color}`.trim()}>{props.children}</h3>
  )
}

interface Props {
  children: any;
  className?: string | null;
  color?: string;
  style?: any;
}
