import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ConfigurationSection } from "../../StyleComponents";
import { SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import Colours from "../../../../UI/atoms/Colours";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { selectHolidayAllowance, updateHolidayRecurringTime } from "../edit-company-page/editCompany.duck";
import { useDispatch, useSelector } from "react-redux";
import { HolidayRecurringDay } from "../../../../../models/Holiday.model";
import { GenericDropDownList } from "../../../../UI/molecules/GenericDropDownList";
import { useTranslation } from "react-i18next";


export const START_OPTIONS = [
  { name: 'First Monday in', id: 0 },
  { name: 'First day in', id: 1 },
]

export const MONTHS = [
  { name: 'January', id: 1 },
  { name: 'February', id: 2 },
  { name: 'March', id: 3 },
  { name: 'April', id: 4 },
  { name: 'May', id: 5 },
  { name: 'June', id: 6 },
  { name: 'July', id: 7 },
  { name: 'August', id: 8 },
  { name: 'September', id: 9 },
  { name: 'October', id: 10 },
  { name: 'November', id: 11 },
  { name: 'December', id: 12 },
]

interface Props {
  disabled: boolean;
  onEnableChange: (state: boolean) => any;
}

export default function HolidayYearSetting(props: Props) {
  const dispatch = useDispatch();
  const [holidayRecurringDay, setHolidayRecurringDay] = useState(HolidayRecurringDay.firstMonday);
  const [holidayRecurringMonth, setHolidayRecurringMonth] = useState(1);
  const holidayAllowanceConfig = useSelector(selectHolidayAllowance);
  const {t} = useTranslation();

  useEffect(() => {
    setHolidayRecurringDay(holidayAllowanceConfig.holidayYearRecurringDay);
    setHolidayRecurringMonth(holidayAllowanceConfig.holidayYearRecurringMonth);
  }, [holidayAllowanceConfig]);

  const onSelectionChange = (day: HolidayRecurringDay, month: number) => {
    setHolidayRecurringDay(day);
    setHolidayRecurringMonth(month);
    dispatch(updateHolidayRecurringTime({
      recurringDay: day,
      recurringMonth: month,
    }));
  }

  return (
    <ConfigurationSection style={{borderBottom: 'none'}}>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.holiday-setting.year')}</BodyLarge>
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 24}}>{t('settings.holiday-setting.month')}</BodyRegular>


      <HolidayRecurringRow>
        <StartDateDropDown items={START_OPTIONS}
                           onSelect={(v: any) => onSelectionChange(v, holidayRecurringMonth)}
                           selectedId={holidayAllowanceConfig.holidayYearRecurringDay}
                           placeholder="textinput.select-start-day" />
        <StartDateDropDown items={MONTHS}
                           onSelect={(v: any) => onSelectionChange(holidayRecurringDay, parseInt(v))}
                           selectedId={holidayAllowanceConfig.holidayYearRecurringMonth}
                           placeholder="textinput.select-month" />
      </HolidayRecurringRow>
    </ConfigurationSection>
  )
}

export const HolidayRecurringRow = styled<any>(SpaceBetweenRow)`
  flex-direction: column;
  @media(${tablet}) {
    flex-direction: row;
  }
`

export const StartDateDropDown = styled(GenericDropDownList)`
  flex: 1;
  @media(${tablet}) {
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
`
