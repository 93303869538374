import React, { useEffect, useState } from 'react';
import { GoBackButton } from "../../../../../UI/atoms/buttons/GoBackButton";
import { HeadlineLarge } from "../../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import TextField from "../../../../../UI/atoms/TextField";
import { useDispatch } from "react-redux";
import { loadUsers, setSearchQuery } from "../ducks/manage-users.duck";
import { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import Colours from "../../../../../UI/atoms/Colours";
import { tablet } from "../../../../../UI/atoms/MediaQueries";

export function ManageUsersHeader(props: Props) {
  const {t} = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const dispatch = useDispatch();

  const onChange = (result: string) => {
    setSearchTerm(result);
    dispatch(setSearchQuery(result));
  }

  const resetSearch = () => {
    onChange('');
    dispatch(loadUsers({}));
  }

  const onBlur = () => {
    if (hasChanged && searchTerm.length === 0) {
      setHasChanged(false);
      dispatch(loadUsers({}));
    }
  }

  useEffect(() => {
    if (searchTerm.length < 3) return;

    setHasChanged(true);
    const id = setTimeout(() => {
      dispatch(loadUsers({searchTerm: searchTerm}));
    }, 250);

    return () => {
      clearTimeout(id);
    }
  }, [searchTerm, dispatch]);

  return (
    <Wrapper>
      <GoBackButton />
      <HeaderRow>
        <HeadlineLarge>{t('manage-users-page.title')}</HeadlineLarge>
        <SearchBarWrapper>
          <TextField onChange={onChange}
                     icon={searchTerm.length > 0 ? IconTypes.Close : IconTypes.Search}
                     placeholder={'manage-users-page.search'}
                     onIconClick={searchTerm.length > 0 ? resetSearch : undefined}
                     onBlur={onBlur}
                     value={searchTerm} />
        </SearchBarWrapper>
      </HeaderRow>
    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled.div`
  margin: 0 16px;

  @media (${tablet}) {
    margin: 0;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 24px;
  }

  @media (${tablet}) {
    flex-direction: row;
    justify-content: space-between;
    h1 {
      margin-bottom: 0;
    }
  }
`

const SearchBarWrapper = styled.div`
  display: flex;
  input {
    width: 100%;
  }
  p {
    white-space: nowrap;
    color: ${Colours.darkGrey};
  }
  * {
    box-shadow: none !important;
  }

  @media (${tablet}) {
    margin-right: 16px;
    input {
      width: 300px;
    }
  }
`
