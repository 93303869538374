import styled from "styled-components/macro";
import { smallTablet } from "./MediaQueries";

export const SmallTablet = styled.div`
  display: block;
  @media (${smallTablet}) {
    display: none;
  }
`

export const BiggerThanSmallTablet = styled.div`
  display: none;
  @media (${smallTablet}) {
    display: block;
  }
`
