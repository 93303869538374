import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from "../../UI/atoms/fonts/Body";
import Colours from "../../UI/atoms/Colours";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/state/app.state";
import { selectMeetingRoomById } from "../meeting-rooms/models/room-finder.duck";
import { User } from "../../../models/user.models";
import { Moment } from "moment/moment";
import { updateMeetingRoomSize } from "../../../services/MeetingRoomsService";
import { MeetingRoom } from "../meeting-rooms/models/MeetingRooms";

interface Props {
  currentUser?: User;
  currentDate: Moment;
  meetingRoom: MeetingRoom;
  onMouseDown: (e: any, room: any) => void;
  onMouseUp: (e: any, room: any) => void;
  mapScale: number;
}

export function EditableMeetingRoomSpot(props: Props) {
  const lastMousePos = useRef({x: 0, y: 0});
  const {meetingRoom, onMouseDown, onMouseUp, mapScale} = props;
  const meetingRoomDetails = useSelector((appState: AppState) => selectMeetingRoomById(appState, meetingRoom.id));

  const [isSizing, setIsSizing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);

  const x = !!meetingRoom.x ? meetingRoom.x : 0;
  const y = !!meetingRoom.y ? meetingRoom.y : 0;
  const isActiveOnMap = meetingRoom.x && meetingRoom.y && meetingRoom.height && meetingRoom.width;

  useEffect(() => {
    if (meetingRoom.width) {
      setWidth(meetingRoom.width)
    }
    if (meetingRoom.height) {
      setHeight(meetingRoom.height)
    }
  }, [meetingRoom.height, meetingRoom.width]);


  const onClick = () => {
    if (disabled) {
      return;
    }
    setIsSizing(false);
  }

  const onDraggableMouseDown = (e: any) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    setIsSizing(true);
  }

  const onDraggableMouseMove = (e: any) => {
    e.stopPropagation();
    const nowX = e.clientX;
    const nowY = e.clientY;
    if (isSizing && !disabled) {
      const diffX = nowX - lastMousePos.current.x;
      const diffY = nowY - lastMousePos.current.y;
      const newWidth = width + (diffX / mapScale);
      const newHeight = height + (diffY / mapScale);
      setWidth(newWidth);
      setHeight(newHeight)
    }
    lastMousePos.current = {x: nowX, y: nowY};
  }

  const onDraggableMouseUp = async (e: any) => {
    e.stopPropagation();

    if (isSizing) {
      setIsSizing(false);
      await saveRoom();
    }
  }

  const saveRoom = async () => {
    setDisabled(true);
    try {
      await updateMeetingRoomSize(meetingRoom.id, width, height);
    } finally {
      setDisabled(false);
    }
  }

  return (
    <>
      <MeetingRoomOverlay id={`meetingRoom-${meetingRoom.id}`}
                          x={x} y={y} height={height} width={width} disabled={!isActiveOnMap}
                          rotate={meetingRoom.rotate ?? 0} onClick={onClick}
                          onMouseDown={(e: any) => onMouseDown(e, meetingRoom)}
                          onMouseUp={(e: any) => onMouseUp(e, meetingRoom)}
                          isVisible={true}>

        {meetingRoomDetails && <BodyLarge weight={600}>{meetingRoomDetails.name}</BodyLarge>}
        {meetingRoomDetails && <BodyRegular>Capacity: {meetingRoomDetails.capacity}</BodyRegular>}

        <DragableControl onMouseUp={onDraggableMouseUp}
                         onMouseLeave={onDraggableMouseUp}
                         onMouseDown={onDraggableMouseDown}
                         onMouseMove={onDraggableMouseMove} />

      </MeetingRoomOverlay>
    </>
  )
}

const MeetingRoomOverlay = styled.div<any>`
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
  background-color: ${props => props.disabled ? Colours.mildGrey : Colours.transparent};
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  border: ${props => props.isVisible ? `3px solid ${Colours.green}` : `3px solid transparent`};
  border-color: ${props => props.disabled ? Colours.darkGrey : props.isVisible ? Colours.green : Colours.transparent};
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: border .1s ease-in-out, background-color .1s ease-in-out;
  padding: 12px 16px;
  border-radius: 16px;
  transform: rotate(${props => props.rotate}deg);
  &:hover {
    background: rgba(255, 255, 255, 0.6);
    mix-blend-mode: normal;
    border: 4px solid ${props => props.disabled ? Colours.darkGrey : Colours.blue};
    box-sizing: border-box;
    border-radius: 8px;
  }
`

const DragableControl = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 32px;
  width: 32px;
  background-color: greenyellow;
`
