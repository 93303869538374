import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../atoms/Colours";
import { useTranslation } from "react-i18next";
import { BodyRegular, BodySmall } from "../../atoms/fonts/Body";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../store/ducks/config.duck";
import { OutlineButton } from "../../atoms/buttons/OutlineButton";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { Moment } from "moment/moment";
import { DATE_FORMAT, PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import { Tick } from "../../atoms/Tick";
import { Row } from "../../atoms/StructuralLayout";
import Icon, { IconTypes } from "../../atoms/icon/Icon";

export function DailyOfficeVisitors(props: Props) {
  const {t} = useTranslation();
  const config = useSelector(selectConfig)

  const officeId = props.office?.id;
  const dateString = props.date?.format(DATE_FORMAT);

  if (!config.visitorBookingEnabled || !officeId || !dateString) {
    return null;
  }

  return (
    <Wrapper>
      <OfficeIconContainer>
        <Icon color={'grey'} icon={IconTypes.Office} />
      </OfficeIconContainer>
      <TitleWrapper>
        <BodyRegular weight={600} style={{maxWidth: '80%', marginBottom: -4}}>{t('visitor-booking.daily-visitors')}</BodyRegular>
        <BodySmall weight={400} style={{marginBottom: 6}}>{props.date?.format(PRETTY_DATE_FORMAT)}</BodySmall>
      </TitleWrapper>
      <Row style={{marginBottom: 16}}>
        <Tick style={{marginRight: 12}} />
        <BodySmall weight={600}>{t('visitor-booking.visitors-in-today', {count: 0})}</BodySmall>
      </Row>
      <OutlineButton text={'visitor-booking.manage-visitors'}
                     fullWidth={true}
                     link={`/whereabouts/visitor-booking/${officeId}?date=${dateString}`} />
    </Wrapper>
  )
}

interface Props {
  office?: OfficeEntity;
  date?: Moment;
}

const TitleWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colours.iconGrey};
  margin-bottom: 16px;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: 24px;
  background-color: ${Colours.veryLightGrey};
  border-radius: 24px;
  margin-bottom: 16px;
`

const OfficeIconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 100%;
  background-color: white;
  padding: 8px;
  i {
    font-size: 24px;
    padding: 4px;
  }
`
