import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditMovementsWrapper from '../edit-movements-wrapper/EditMovementsWrapper';
import EditMovementsContent from '../edit-movements-content/EditMovementsContent';
import './edit-movements-panel.scss';
import EditMovementsMobile from "../edit-movements-mobile/EditMovementsMobile";
import { Accordian } from '../../../../../../animations/AnimationVariants';
import {
  selectFocusedUserId,
  updateMovement
} from "../../../../../../store/ducks/editMovements.duck";
import { loadFavouriteOffices } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectConfig } from "../../../../../../store/ducks/config.duck";
import isMobile from "is-mobile";
import { FixedWhereabouts } from "../../../../../../services/WhereaboutOptions";

interface Props {
  userId: string;
  isEditing: boolean;
}

export default function EditMovementsPanel(props: Props) {
  const {userId, isEditing} = props;
  const dispatch = useDispatch();
  const focusedUser = useSelector(selectFocusedUserId);
  const config = useSelector(selectConfig);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentFocusedWhereabouts = {};

  const isOpen = useMemo(() => isEditing && !!focusedUser && focusedUser === userId, [isEditing, focusedUser, userId])

  useEffect(() => {
    if (isEditing) {
      dispatch(loadFavouriteOffices());
    }
  }, [isEditing, dispatch, config]);

  useEffect(() => {
    if (isEditing && currentFocusedWhereabouts === FixedWhereabouts.CompanyHoliday) {
      setTimeout(() => dispatch(updateMovement({skip: true})), 100)
    }
  }, [dispatch, isEditing, currentFocusedWhereabouts])

  return (
    <>
      {isMobile() ?
        <>{isOpen && <EditMovementsMobile/>}</>
      :
        <Accordian isOpen={isOpen}>
          <EditMovementsWrapper>
            <EditMovementsContent/>
          </EditMovementsWrapper>
        </Accordian>
      }

    </>
  )
}
