import React from 'react';
import { Center, Row } from "../../../atoms/StructuralLayout";
import { HeadlineMedium } from "../../../atoms/fonts/Headline";
import { BodyLarge } from "../../../atoms/fonts/Body";
import { resetAddMember } from "../../../../../store/ducks/addMemberDialog.duck";
import { SuggestionMessage } from "../../../molecules/SuggestionMessage";
import { useDispatch, useSelector } from "react-redux";
import { selectIsFreeAccount, selectNumberOfRemainingUsers } from "../../../../../store/ducks/payments.duck";
import styled from "styled-components/macro";
import { isInTeams } from "../../../../../utils/TeamsUtils";
import { PrimaryButton } from "../../../atoms/buttons/PrimaryButton";
import { OutlineButton } from "../../../atoms/buttons/OutlineButton";
import { useTranslation } from "react-i18next";

interface Props {
  onDone: () => any;
  hideSuccessMessage: boolean;
}

export function AddTeamMemberSuccess(props: Props) {
  const dispatch = useDispatch();
  const remainingUsers = useSelector(selectNumberOfRemainingUsers);
  const isFreeAccount = useSelector(selectIsFreeAccount);
  const {t} = useTranslation();

  const reset = () => {
    dispatch(resetAddMember());
  }

  return (
    <React.Fragment>
      {!props.hideSuccessMessage && <>
        <Row className="addMember__header">
          <Center>
            <img style={{width: 180}} className="addMember__image" src="/assets/images/onboarding-invite-sent-image.svg" alt="" aria-hidden={true} />
          </Center>
        </Row>
        <HeadlineMedium style={{marginBottom: 16}}>{t('team.team-member-invited')}</HeadlineMedium>

        <BodyLarge style={{marginBottom: 'auto'}}>{t('payments.users-available-message', {remainingUsers: remainingUsers})}</BodyLarge>
      </>}

      <ButtonWithSpacing dataTest="addAnotherButton"
                         size="large"
                         fullWidth={true} text="button.add-another-team-member"
                         click={() => reset()}/>

      <PrimaryButton dataTest="doneButton"
                     size="large"
                     fullWidth={true}
                     text="button.done-for-now"
                     click={props.onDone}/>

      {isFreeAccount && !isInTeams() && <SuggestionMessage
        style={{padding: 24, marginTop: 32}}
        title="did-you-know"
        linkMessage="payments.upgrade-account-in-settings"
        content="payments.with-as-many-users"
        link="/manage/payments"/>}
    </React.Fragment>
  )
}

const ButtonWithSpacing = styled<any>(OutlineButton)<any>`
  margin-bottom: 16px
`
