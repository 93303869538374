import React from 'react';
import { useDispatch } from "react-redux";
import { BodyRegular } from "../../../../atoms/fonts/Body";
import { SubHeaderContainer, SubHeaderContent, SubHeaderMenuItem } from "./ViewComponents";
import { TeamsHeaderComponents } from "../TeamsHeaderComponents";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";
import { useLocation } from "react-router-dom";

export function WhereaboutsSubHeader(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <SubHeaderContainer fullScreenView={props.fullScreenView}>
      <SubHeaderContent>
        <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/team')}
                           onClick={() => dispatch(redirectTo(`/whereabouts/team`))}>
          <BodyRegular weight={600}>Team View</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/work-planner')}
                           onClick={() => dispatch(redirectTo('/whereabouts/work-planner'))}>
          <BodyRegular weight={600}>Work Planner</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/floor-planner')}
                           onClick={() => dispatch(redirectTo('/whereabouts/floor-planner'))}>
          <BodyRegular weight={600}>Floor Plan</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/office-view')}
                           onClick={() => dispatch(redirectTo('/whereabouts/office-view'))}>
          <BodyRegular weight={600}>Office View</BodyRegular>
        </SubHeaderMenuItem>
        <TeamsHeaderComponents/>
      </SubHeaderContent>
    </SubHeaderContainer>
  )
}

interface Props {
  fullScreenView?: boolean;
}

