import styled from "styled-components/macro";
import { SpaceBetweenRow } from "../../../UI/atoms/StructuralLayout";
import { desktopSmall, smallTablet } from "../../../UI/atoms/MediaQueries";

export const SpaceBetweenHeaderRow = styled<any>(SpaceBetweenRow)<any>`

  @media (${smallTablet}) {
    padding-right: 16px;
  }
`

export const TeamMovementsContent = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 16px 0;
  @media (${desktopSmall}) {
    max-width: 1000px;
  }
`
