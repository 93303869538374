import React, { useState } from 'react';
import Select, { components } from "react-select";
import { Center, Column, VerticallyCenteredRow } from "../atoms/StructuralLayout";
import { UserAvatarAndName } from "./UserAvatarAndName";
import { BodyRegular, BodySmall } from "../atoms/fonts/Body";
import Colours from "../atoms/Colours";
import { findUserRequest } from "../../../services/UserService";
import { BasicUserInfo } from "../../../models/user.models";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DROP_DOWN_STYLES } from "../organisms/drop-down-list/DropDownStyles";

export function UserSearchDropDownList(props: Props) {
  const [selected, setSelected] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const {t} = useTranslation();

  const onInputChange = async (query: string, query2: any) => {
    setInputValue(query)
    if (query.length < 3) return

    try {
      const searchResponse = await findUserRequest(query);
      const parsedOptions = searchResponse.map((userInfo: BasicUserInfo) => ({...userInfo, value: userInfo.userId, label: `${userInfo.firstName} ${userInfo.lastName}`}));
      setOptions(parsedOptions);
    } catch (err: any) {
      setOptions([]);
    }
  }

  const CustomOption = (props: any) => {
    return (
      <UserDropDownItem {...props.innerProps}>
        <Column style={{padding: '4px 2px'}}>
          <VerticallyCenteredRow>
            <UserAvatarAndName firstName={props.data.firstName} lastName={props.data.lastName} hideName={true} />
            <BodyRegular style={{padding: '4px 0 4px 12px'}}>{props.data.firstName} {props.data.lastName}</BodyRegular>
          </VerticallyCenteredRow>
          <BodySmall style={{marginLeft: 44, marginTop: -4}} colour={Colours.darkGrey}>{props.data.teamName ?? t('general.no-team')}</BodySmall>
        </Column>
      </UserDropDownItem>
    );
  }

  const CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Center style={{padding: '0 8px'}}>
          <i className="icon-Search" />
        </Center>
      </components.DropdownIndicator>
    );
  }

  const onSelect = (selectedUser: BasicUserInfo) => {
    setSelected(selectedUser);
    props.onSelect(selectedUser);
  }
  return (
    <>
      <Select
        styles={DROP_DOWN_STYLES}
        value={selected}
        inputValue={inputValue}
        placeholder={t('textinput.find-user')}
        onInputChange={onInputChange}
        options={options} isDisabled={!!props?.disabled}
        closeMenuOnSelect={props.closeOnSelect}
        components={{
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        onChange={(selectedUser: any) => onSelect(selectedUser)}
      />
    </>
  )
}

interface Props {
  closeOnSelect: boolean;
  onSelect: (user: BasicUserInfo) => any;
  disabled?: boolean;
}

const UserDropDownItem = styled.div`
  cursor: pointer;
  padding: 0 16px 0 4px;
  &:hover {
    background-color: ${Colours.blue10};
  }
`
