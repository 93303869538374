import React, { useState } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { CarParkPlanBooking, CarParkSpace } from "../../../../../services/CarParkingPlanService";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";

export function ParkingSpaceOverlay(props: Props) {
  const {parkingSpace, booking, isSelected} = props;
  const [parkingSpaceHover, setParkingSpaceHover] = useState(false);
  const onClicked = (e: any) => {
    e.stopPropagation();
    props.onClicked(parkingSpace);
  }
  return (
    <ParkingSpaceContainer id={`parking-space-${parkingSpace.id}`}
                           shadedOut={!parkingSpace.active}
                           x={parkingSpace.x} y={parkingSpace.y}
                           isVisible={parkingSpaceHover || isSelected}
                           width={parkingSpace.height}
                           height={parkingSpace.width}
                           rotate={parkingSpace.rotation}
                           onMouseOver={() => setParkingSpaceHover(true)}
                           onTouchStart={() => setParkingSpaceHover(true)}
                           onMouseLeave={() => setParkingSpaceHover(false)}
                           onTouchEnd={() => setParkingSpaceHover(false)}
                           onTouchCancel={() => setParkingSpaceHover(false)}
                           onClick={(e: any) => onClicked(e)}>

      {booking ? <>
        <UserAvatarAndName firstName={booking.firstName} lastName={booking.lastName} hideName={true} showEditLink={false} colour={"blue"} />
      </> : <>
        <img src={"/assets/icons/car-front.svg"} alt={""} />
        <span>{parkingSpace.label}</span>
      </>}
    </ParkingSpaceContainer>
  )
}

interface Props {
  parkingSpace: CarParkSpace;
  booking?: CarParkPlanBooking;
  onClicked: (parkingSpace: CarParkSpace) => any;
  isSelected?: boolean;
}


const ParkingSpaceContainer = styled.div<any>`
  width: ${props => `${props.width}px`};
  min-height: ${props => `${props.height}px`};
  border-radius: 3px;
  background-color: ${props => props.shadedOut ? Colours.opaqueMildGrey : Colours.transparent};
  z-index: ${props => props.shadedOut ? 1 : 100};
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  border: ${props => props.isVisible ? `3px solid ${Colours.green} !important` : `3px solid ${Colours.darkGrey}`};
  transform: rotate(${props => props.rotate}deg);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: border-color .1s ease-in-out;
  padding: 2px;
  text-align: center;
  overflow: hidden;
  &:hover {
    border: 3px solid ${Colours.darkBlue};
    z-index: 9999;
  }

  .userAvatarAndName p {
    color: white;
  }
  span {
    color: ${props => props.shadedOut ? Colours.darkGrey : Colours.darkBlue};
    font-size: 10px;
    font-style: normal;
    font-family: "soleil", sans-serif;
    font-weight: 700;
    line-height: 12px;
    margin-top: -2px;
  }
  img {
    height: 12px;
    width: 12px;
  }
`
