import { createSlice, CreateSliceOptions } from "@reduxjs/toolkit";


export interface HttpRequestState {
  isOpen: boolean;
  processing: boolean;
  success: boolean;
  error: string;
}

export interface ErrorAction {
  type: string;
  payload: {
    message: string,
    details: string,
  }
}


export function createHttpRequestSlice(sliceOptions: CreateSliceOptions) {
  return createSlice({
    name: sliceOptions.name,
    initialState: sliceOptions.initialState,
    reducers: {
      reset: () => sliceOptions.initialState,
      setIsOpen: (state, action) => {
        return {...state, isOpen: action.payload};
      },
      setProcessing: (state) => ({...state, processing: true, success: false, error: ''}),
      setSuccess: (state) => ({...state, processing: false, success: true, error: ''}),
      setError: (state, action: ErrorAction) => ({
        ...state,
        processing: false,
        success: false,
        error: action.payload.message,
        failedRequest: action.payload.details
      }),
      ...sliceOptions.reducers
    },
    extraReducers: {
      ...sliceOptions.extraReducers
    },
  });
} 