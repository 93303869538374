import React from 'react';
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import { LinkButton } from "../../../../../UI/atoms/buttons/LinkButton";
import Colours from "../../../../../UI/atoms/Colours";
import { desktop, tablet } from "../../../../../UI/atoms/MediaQueries";
import { useTranslation } from "react-i18next";

export function ReportingContentCardSmall(props: Props) {
  const {imageUrl, title, description, link, disabled, isVisible} = props;
  const {t} = useTranslation();
  if (!isVisible) {
    return null;
  }
  return (
    <Wrapper>
      <Image src={imageUrl} alt={""} />
      <div>
        <HeadlineSmall style={{marginBottom: 8}}>{t(title)}</HeadlineSmall>
        <BodyLarge style={{marginBottom: 24}}>{t(description)}</BodyLarge>
        <LinkButton text={"button.view-report"}
                    type={"outline"}
                    link={link}
                    disabled={disabled} />
      </div>
    </Wrapper>
  )
}

interface Props {
  imageUrl: string;
  title: string;
  isVisible: boolean;
  description: string;
  link: string;
  disabled?: boolean;
}

const Wrapper = styled.div`
  padding: 32px;
  background-color: ${Colours.white};
  background: ${Colours.white};
  margin-bottom: 32px;
  width: 100%;
  border-radius: 16px;

  @media (${tablet}) {
    padding: 32px 56px;
    min-width: 48%;
    max-width: 48%;
  }

  @media (${desktop}) {
    padding: 32px 56px;
    min-width: 32%;
    max-width: 32%;
  }
`

const Image = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 12px;
`
