import React, { useMemo } from 'react';
import styled from "styled-components";
import { Moment } from "moment";
import { HolidayCalendarMonthView } from "./HolidayCalendarMonthView";
import { HolidayDay } from "../../models/Holidays.model";
import { desktop, tablet } from "../../../../UI/atoms/MediaQueries";
import { CompanyHoliday } from "../../../../../services/CompanyHolidayService";

export function YearContainer(props: Props) {
  const {startDate, holidaysBooked, colourOverride, companyHolidays} = props;
  const endDate = useMemo(() => startDate.clone().add(1, 'year').subtract(1, 'day'), [startDate]);

  return (
    <Container>
      <Row>
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone()}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(1, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(2, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(3, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(4, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(5, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(6, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(7, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(8, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(9, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(10, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        <HolidayCalendarMonthView enabledStartDate={startDate}
                                  enabledEndDate={endDate}
                                  colourOverride={colourOverride}
                                  monthDate={startDate.clone().add(11, 'months')}
                                  holidaysBooked={holidaysBooked}
                                  companyHolidays={companyHolidays} />
        {startDate.date() > 1 && <HolidayCalendarMonthView
          enabledStartDate={startDate}
          enabledEndDate={endDate}
          colourOverride={colourOverride}
          monthDate={startDate.clone().add(12, 'months')}
          holidaysBooked={holidaysBooked}
          companyHolidays={companyHolidays} />}
      </Row>
    </Container>
  )
}

interface Props {
  companyHolidays: CompanyHoliday[];
  holidaysBooked: HolidayDay[];
  colourOverride?: string;
  startDate: Moment;
}

const Container = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media (${tablet}) {
    justify-content: flex-start;
    &>* {
      margin-left: 10%;
    }
  }
  @media (${desktop}) {
    &>* {
      margin-left: 2.5%;
    }
  }
`
