import React from 'react';
import { WhereaboutsBookingError, WhereaboutsBookingErrorType } from "../../../services/DeskBookingService";
import { OutOfBookingRangeError } from "./OutOfBookingRangeError";
import { NoneBlockingWhereaboutsError } from "./NoneBlockingWhereaboutsError";


export function SavedWhereaboutsErrors(props: Props) {
  const errors = props.payload.errors as WhereaboutsBookingError[];

  if (!errors || errors.length === 0) return null;

  if (errors.filter(e => e.type === WhereaboutsBookingErrorType.OUT_OF_BOOKING_RANGE).length > 0) {
    return <OutOfBookingRangeError errors={errors} />
  }

  return <NoneBlockingWhereaboutsError errors={errors} response={props.payload.response} />
}

interface Props {
  payload?: any;
}

