import React, { CSSProperties, useEffect, useMemo } from 'react';
import Select from "react-select";
import styled from "styled-components";
import { GroupType, TeamWithCount } from "../../../../../models/team.models";
import { BodyRegular, BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { INDIRECT_LINE_REPORT_ID, LINE_REPORT_ID } from "../../../../../services/HolidayService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import { Tag } from "../../../../../models/tags.models";
import { loadFollowingGroups } from "../../../../../store/ducks/followingTeamsAndTags.duck";
import { SELECTED_GROUP_ID } from "../TeamsMonthlyHolidayView";


export interface GroupedOption {
  readonly label: string;
  readonly options: any[];
}

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <BodyRegular weight={600} colour={Colours.blue}>{data.label}</BodyRegular>
    <BodyVerySmall weight={400} colour={Colours.blue}>{data.options.length}</BodyVerySmall>
  </div>
);

export function HolidayViewerDropdownList(props: Props) {
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFollowingGroups(false));
  }, [dispatch]);

  const groupedOptions: GroupedOption[] = useMemo(() => {
    const lineManagementOptions = (currentUser?.companyEntity.lineManagementEnabled ?
      [ { label: 'Management', options: [ {label: 'Line reports', id: LINE_REPORT_ID}, {label: 'Indirect line reports', id: INDIRECT_LINE_REPORT_ID} ] } ] :
      []);
    return [
      ...lineManagementOptions,
      {
        label: 'Teams',
        options: props.teams.map((team: TeamWithCount) => ({...team, type: GroupType.Team, label: `${team.name} (${team.memberCount})`})),
      },
      {
        label: 'Workgroups',
        options: props.workGroups.map((group: Tag) => ({...group, type: GroupType.WorkGroup, label: group.label})),
      },
    ]
  }, [currentUser, props]);

  const selectedOption = useMemo(() => {
    for (let group of groupedOptions) {
      for (let option of group.options) {
        if (option.id === props.selectedOptionId) {
          return option;
        }
      }
    }
    return undefined;
  }, [props, groupedOptions]);

  const styles = {
    option: (provided: any) => ({
      ...provided,
      fontFamily: '"soleil", sans-serif',
      backgroundColor: 'white',
      color: Colours.black,
      '&:hover': {
        color: Colours.black,
        backgroundColor: Colours.blue10,
      }
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontFamily: '"soleil", sans-serif',
      color: Colours.black,
      fontSize: '18px',
    }),
    menu: (css: any) => ({
      ...css,
      width: "max-content",
      minWidth: "250px",
      zIndex: 100,
    }),
    placeholder: (css: any) => ({
      ...css,
      fontFamily: '"soleil", sans-serif',
      fontSize: '18px',
      lineHeight: '28px',
      color: 'hsl(0, 0%, 80%)',
    }),
    noOptionsMessage: (css: any) => ({
      ...css,
      fontFamily: '"soleil", sans-serif',
      fontSize: '18px',
      lineHeight: '28px'
    }),
    input: (css: any) => ({
      ...css,
      fontFamily: '"soleil", sans-serif',
      fontSize: '18px',
      lineHeight: '28px'
    }),
  };

  const onTeamSelectChange = (value: any) => {
    if (!!value) {
      localStorage.setItem(SELECTED_GROUP_ID, `${value.id}::${value.type}`);
      props.onSelect(value.id, value.type);
    }
  }

  return (
    <DropDownContainer style={props.style ?? {}} className={'dropdown__container'}>
      <Select
        value={selectedOption}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        styles={styles}
        placeholder={t('textinput.select-team')}
        className="searchbar"
        classNamePrefix="searchbar"
        onChange={(value: any) => onTeamSelectChange(value)}
      />
    </DropDownContainer>
  )
}

interface Props {
  teams: TeamWithCount[];
  workGroups: Tag[];
  onSelect: (optionId: string | number, groupType: GroupType) => any;
  selectedOptionId?: string | number;
  style?: CSSProperties;
}


const DropDownContainer = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  z-index: 250;
  padding: 2px 8px;
`

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
