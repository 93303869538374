import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  next,
  OnboardingScreen,
  selectActiveScreen,
  selectHolidayManagementEnabled,
  skip
} from '../../../../store/ducks/onboarding.duck';
import { Column, SpaceBetweenRow } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import DoThisLaterButtonRow from '../../../UI/molecules/DoThisLaterButtonRow';
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import { DeskAvailabilityCount } from "../../settings/StyleComponents";
import { SuggestionMessage } from "../../../UI/molecules/SuggestionMessage";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";
import { selectConfig, selectIsConfigLoading, updateConfig } from "../../../../store/ducks/config.duck";

export default function SetupHolidayAllowance() {
  const dispatch = useDispatch();
  const isEnabled = useSelector(selectHolidayManagementEnabled);
  const activeScreen = useSelector(selectActiveScreen);
  const config = useSelector(selectConfig);
  const isLoading = useSelector(selectIsConfigLoading);
  const [editedHolidayAllowance, setEditedHolidayAllowance] = useState(0);
  const {t} = useTranslation();

  useEffect(() => {
    setEditedHolidayAllowance(config?.companyHolidayAllowance ?? 0);
  }, [config]);

  const saveHolidayAllowance = () => {
    dispatch(updateConfig({
      ...config,
      companyHolidayAllowance: editedHolidayAllowance
    }));
  }

  useEffect(() => {
    if (activeScreen === OnboardingScreen.SetupHolidayAllowance && !isEnabled) {
      dispatch(skip());
    }
  }, [dispatch, isEnabled, activeScreen]);

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <img style={{width: 240, marginBottom: 32}} className="onboarding__image" src="/assets/images/onboarding-holidays-image.svg" alt="" aria-hidden={true} />

        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.holiday-management')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 24}}>{t('onboarding.holiday-management-description')}</BodyLarge>

        <SpaceBetweenRow style={{marginBottom: 16, justifyContent: 'center'}}>
          <DeskAvailabilityCount type="number" onChange={setEditedHolidayAllowance} onBlur={saveHolidayAllowance}
                                 disabled={isLoading} style={{marginBottom: 0}}
                                 value={editedHolidayAllowance}
                                 rightText="onboarding.holiday-management-default-number"/>
          {isLoading && <SimpleSpinner size={24} />}
        </SpaceBetweenRow>

        <SuggestionMessage
          style={{marginBottom: 48}}
          title="did-you-know"
          content="onboarding.holiday-management-tip"
        />

        <PrimaryButton dataTest="onboarding-holidays-next"
                size="large"
                text="button.got-it"
                 style={{marginBottom: 16}}
                click={() => dispatch(next())}
                fullWidth={true}/>

        <DoThisLaterButtonRow dataTest="onboard-holidayAllowanceSkip" onClick={() => dispatch(skip())}/>
      </Column>
    </div>
  )
}
