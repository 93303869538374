import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllCheckedUsers } from "../ducks/manage-users.duck";
import { UserInfoV2 } from "../ducks/ManageUsers.model";

export function WithIsUserChecked(user: UserInfoV2) {
  const checkedUsers = useSelector(selectAllCheckedUsers);
  return useMemo(() => {
    return !!checkedUsers.find(cu => cu.id === user.id);
  }, [checkedUsers, user]);
}
