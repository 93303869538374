import { agent } from "../../../../services/agent";

export function createCheckoutSession(priceId: string, quantity: number) {
  return agent.requests.post('/api/stripe/create-checkout-session', {
    priceId: priceId,
    quantity: quantity,
  });
}

export function getCheckoutSession(sessionId: string) {
  return agent.requests.get(`/api/stripe/checkout-session?sessionId=${sessionId}`);
}

export function getCustomerPortal() {
  return agent.requests.get('/api/stripe/customer-portal')
}
