import React from 'react';
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { BodyXXSmall } from "../atoms/fonts/Body";
import { deepLinkTo, isInTeams } from "../../../utils/TeamsUtils";
import { Link } from 'react-router-dom';
import styled from "styled-components/macro";
import { teamsTabs, uiUrl } from "../../../services/EnvironmentVariables";
import { useTranslation } from "react-i18next";

interface Props {
}

export function PersonalPreferencesRowLink(props: Props) {
  const {t} = useTranslation();

  const onClick = () => {
    const externalUrl = `${uiUrl}/manage/calendar`
    deepLinkTo(teamsTabs.settings, externalUrl, 'personal', '000');
  }

  if (isInTeams()) {
    return (
      <SpaceBetweenRow onClick={onClick}>
        <Icon size={'mediumlarge'} icon={IconTypes.Profile} />
        <BodyXXSmall weight={600}>{t('personal-perf.personal-perf')}</BodyXXSmall>
      </SpaceBetweenRow>
    )
  } else {
    return (
      <Link to={"/manage/calendar"} style={{textDecoration: 'none'}}>
        <SpaceBetweenRow>
          <Icon size={'mediumlarge'} icon={IconTypes.Profile} />
          <BodyXXSmall weight={600}>{t('personal-perf.personal-perf')}</BodyXXSmall>
        </SpaceBetweenRow>
      </Link>
    )
  }
}


const SpaceBetweenRow = styled.div` 
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    margin-right: 16px;
  }
`
