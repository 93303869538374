import React from 'react';
import styled from "styled-components";
import Colours from "./Colours";

export function PendingSaveDot(props: Props) {
  if (!props.visible) return null;
  return (
    <Dot style={props?.style ?? {}} visible={props.visible} />
  )
}

interface Props {
  style?: any;
  visible: boolean
}

const Dot = styled.div<any>`
  align-self: center;
  justify-self: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.visible ? Colours.black : 'transparent'};
`
