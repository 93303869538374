import React, { useMemo, useState } from 'react';
import { Moment } from "moment";
import { FloatingUserCell } from "./FloatingUserCell";
import {
  BorderDayCell,
  ROW_HEIGHT,
  USER_ROW_HEIGHT_MOBILE,
  WallPlannerRow,
  WEEK_WIDTH,
  WeekCell
} from '../WallPlannerStructuralComponents';
import styled from "styled-components/macro";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { BasicUserInfo, Permission } from "../../../../models/user.models";
import { useDispatch, useSelector } from "react-redux";
import { redirectTo, selectCurrentUser, selectIconPack } from "../../../../store/ducks/auth.duck";
import { smallTablet } from "../../../UI/atoms/MediaQueries";
import { WithWeeksInCurrentCalendarMonth } from "../../../../hooks/WithWeekInCurrentCalendarMonth";
import { Period } from "../../../../models/movements.models";
import { HolidayUsagePopup } from "../../holidays-v2/components/HolidayUsagePopup";
import { CheckLineReport } from "../../../../hooks/management-structure/CheckLineReport";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { ActivityBlockV2 } from "./WallPlannerActivityBlocksV2";
import { UsersWorkBlock } from "../../../../models/wall-planner.models";

export interface WallPlannerActivity {
  startDate: Moment;
  endDate: Moment;
}

interface Props {
  user: BasicUserInfo;
  whereaboutsDays: UsersWorkBlock[];
  children?: any;
  id?: string;
}

export function WallPlannerUserRowV2(props: Props) {
  const {user, whereaboutsDays, children} = props;
  const [popupVisible, setPopupVisible] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const iconPack = useSelector(selectIconPack);
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();
  const dispatch = useDispatch();
  const holidayBookingEnabled = currentUser?.companyEntity.enableHolidayBooking;

  const isLineReport = CheckLineReport(currentUser, user?.userId);

  const canUserViewPopup = useMemo(() => {
    if (!window.location.href.includes('/holidays')) {
      return false;
    }

    if (!holidayBookingEnabled) {
      return false;
    }

    if (currentUser?.role === Permission.CompanyAdmin) {
      return true;
    }

    if (currentUser?.role === Permission.TeamAdmin && currentUser.teamEntity !== null && currentUser.teamEntity.id === user?.teamId) {
      return true;
    }

    return isLineReport;
  }, [holidayBookingEnabled, currentUser?.role, currentUser?.teamEntity, user?.teamId, isLineReport]);

  const onUserAvatarClicked = () => {
    if (user && canUserViewPopup) {
      window.history.pushState({}, 'Leave planner', window.location.pathname)
      dispatch(redirectTo(`/holidays/${user.userId}`))
    }
  }

  if (!weeksInMonth || weeksInMonth.length === 0) {
    return null;
  }

  const firstDay = weeksInMonth[0];

  return (
    <WallPlannerUserRowContainer weekCount={weeksInMonth.length} id={props?.id ?? ''}>
      <FloatingUserCell>
        {children && <>
            {children}
        </>}
        {user && !children && <UserAvatarAndName colour={currentUser?.id === user.userId ? 'blue' : 'darkBlue'}
                                                 onClick={canUserViewPopup ? onUserAvatarClicked : undefined}
                                                 onHoverIcon={canUserViewPopup ? IconTypes.Holiday : undefined}
                                                 mouseOver={() => setPopupVisible(true)}
                                                 mouseLeave={() => setPopupVisible(false)}
                                                 firstName={user.firstName}
                                                 lastName={user.lastName}/>}

        {holidayBookingEnabled && canUserViewPopup && popupVisible && user && <HolidayUsagePopup user={user} date={firstDay} />}

      </FloatingUserCell>
      {weeksInMonth.map((timeFrame, k) => (
        <WeekCell key={k}>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell weekend={true}/>
          <BorderDayCell weekend={true}/>
        </WeekCell>
      ))}

      <UserActivitiesContainer>
        {whereaboutsDays?.map((whereabouts: UsersWorkBlock, key) => (
          <React.Fragment key={key}>
            {(whereabouts?.amStatusId !== whereabouts?.pmStatusId) ? <HalfDayColumn>
                <ActivityBlockV2 iconPack={iconPack} style={{top: 0, height: 14}} whereabouts={whereabouts} sideOfDay={Period.AM} firstDay={firstDay} />
                <ActivityBlockV2 iconPack={iconPack} style={{top: 18, height: 14}} whereabouts={whereabouts} sideOfDay={Period.PM} firstDay={firstDay} />
              </HalfDayColumn> :
              <ActivityBlockV2 iconPack={iconPack} whereabouts={whereabouts} firstDay={firstDay} sideOfDay={Period.AllDay} />
            }
          </React.Fragment>
        ))}

        {/*{holidays?.map((activity: any, key) => (*/}
        {/*  <HolidayBlock activity={activity} key={key}*/}
        {/*                firstDateOnCalendar={firstDay}*/}
        {/*                weeksInMonth={weeksInMonth.length}*/}
        {/*                user={props.user}>*/}
        {/*    <BodyVerySmall weight={600}>{activity?.name ?? ''}</BodyVerySmall>*/}
        {/*  </HolidayBlock>*/}
        {/*))}*/}

        {/*{companyHolidays?.map((activity: any, key) => (*/}
        {/*  <CompanyHolidayBlock activity={activity} key={key}*/}
        {/*                firstDateOnCalendar={firstDay}*/}
        {/*                weeksInMonth={weeksInMonth.length}>*/}
        {/*    <BodyVerySmall weight={600}>{activity?.name ?? ''}</BodyVerySmall>*/}
        {/*  </CompanyHolidayBlock>*/}
        {/*))}*/}
      </UserActivitiesContainer>

    </WallPlannerUserRowContainer>
  )
}


export const WallPlannerUserRowContainer = styled<any>(WallPlannerRow)<any>`
  height: ${USER_ROW_HEIGHT_MOBILE}px;
  width: 100%;
  @media (${smallTablet}) {
    height: ${ROW_HEIGHT}px;
    left: 0;
  }
`

export const UserActivitiesContainer = styled.div`
  position: absolute;
  left: -${WEEK_WIDTH}px;
  top: 40px;
  @media (${smallTablet}) {
    left: 0;
    top: 12px;
  }
`

export const HalfDayColumn = styled.div`
  display: flex;
  flex-direction: column;
`
