import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { next, updateWhereaboutsIconPack } from '../../../../store/ducks/onboarding.duck';
import { Column } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { BodyLarge, BodySmall } from "../../../UI/atoms/fonts/Body";
import { Button } from "../../../UI/atoms/buttons/Button";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { IconPack } from "../../../../models/company.models";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";

export default function OnboardingIcons() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);

  const basic = user?.companyEntity.iconPack === IconPack.BASIC;
  const standard = user?.companyEntity.iconPack === IconPack.STANDARD;

  const onSelect = async (iconPack: IconPack) => {
    if (iconPack === user?.companyEntity.iconPack) return;

    try {
      setLoading(true);
      dispatch(updateWhereaboutsIconPack(iconPack));
      await new Promise((resolve: any) => setTimeout(() => resolve(), 150));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.icons-title')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 48}}>{t('onboarding.icons-description')}</BodyLarge>

        <IconRow>
          <IconWrapper selected={standard} onClick={() => onSelect(IconPack.STANDARD)}>
            {standard && <SelectedIcon icon={IconTypes.Tick} circle={true} size={'small'} color={'blue'} />}
            <Image src="/assets/icons/standard/House.svg" alt="detailed icons" aria-hidden={true} />
            <BodySmall weight={600}>{t('onboarding.icons-detailed')}</BodySmall>
          </IconWrapper>
          <IconWrapper selected={basic} onClick={() => onSelect(IconPack.BASIC)}>
            {basic && <SelectedIcon icon={IconTypes.Tick} circle={true} size={'small'} color={'blue'} />}
            <Image src="/assets/icons/basic/House-Available.svg" alt="simple icons" aria-hidden={true} />
            <BodySmall weight={600}>{t('onboarding.icons-simple')}</BodySmall>
          </IconWrapper>
        </IconRow>

        <Button dataTest="onboarding-custom-whereabouts-next"
                size="large"
                text="button.got-it"
                disabled={loading}
                type="primary"
                onClick={() => dispatch(next())}
                fullWidth={true}/>
      </Column>
    </div>
  )
}


const IconRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`

const SelectedIcon = styled<any>(Icon)<any>`
  position: absolute;
  top: 8px;
  right: 8px;
`

const IconWrapper = styled.div<any>`
  position: relative;
  width: 150px;
  max-width: 50%;
  border-radius: 12px;
  border: ${props => props.selected ? `2px solid ${Colours.blue}` : `1px solid ${Colours.lightGrey}`};
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 24px 16px;
  &:hover {
    border: 2px solid ${Colours.blue};
  }
  &:first-child {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 8px;
  }
`

const Image = styled.img`
  margin-bottom: 6px;
`
