import Colours from "../../atoms/Colours";


export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const DROP_DOWN_STYLES = {
  option: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    backgroundColor: 'white',
    color: Colours.black,
    cursor: 'pointer',
    '&:hover': {
      color: Colours.black,
      backgroundColor: Colours.blue10,
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
    fontSize: '18px',
  }),
  menu: (css: any) => ({
    ...css,
    width: "max-content",
    minWidth: "250px",
    zIndex: 100,
  }),
  placeholder: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: 'hsl(0, 0%, 80%)',
  }),
  noOptionsMessage: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  input: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
    '.memberCount': {
      display: 'none',
    }
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: Colours.black,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Colours.darkGrey,
      color: 'white',
    },
  }),
};
