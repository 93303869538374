import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectAllTeams } from "../../../store/ducks/config.duck";
import { selectCurrentUser, setUser } from "../../../store/ducks/auth.duck";
import { TeamWithCount } from "../../../models/team.models";
import styled from "styled-components/macro";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import Colours from "../../UI/atoms/Colours";
import { UpdateUserField, updateUserField } from "../../../services/UserService";
import { failureNotification } from "../../../store/ducks/notification.duck";

export function TeamSelect(props: Props) {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const teams = useSelector(selectAllTeams);
  const [loading, setLoading] = useState(false);

  const onTeamSelect = async (team: TeamWithCount) => {
    if (!user || loading) {
      return;
    }

    try {
      setLoading(true);
      await updateUserField(user.id, UpdateUserField.team, team.id);
      dispatch(setUser({ ...user, teamEntity: team, }));
    } catch (e) {
      console.error(e);
      dispatch(failureNotification('Failed to update team'))
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <TeamsList>
        {teams.map((team: TeamWithCount, key) => (<TeamOption onClick={() => onTeamSelect(team)} key={key}>
          <BodyRegular>{team.name}</BodyRegular>
          <BodyRegular>{team.memberCount}</BodyRegular>
        </TeamOption>))}
      </TeamsList>
    </>
  )
}

interface Props {
  onSelect: (team: TeamWithCount) => any;
}

const TeamsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: auto;
`
const TeamOption = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${Colours.mildGrey};
  margin-bottom: 16px;
  margin-right: 24px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: ${Colours.blue05};
  }
`
