import React, { useEffect, useMemo, useState } from 'react';
import Layout from "../UI/organisms/layout/Layout";
import { FadeAndPop } from "../../animations/AnimationVariants";
import { HeadlineLarge } from "../UI/atoms/fonts/Headline";
import { BodyError, BodyRegular } from "../UI/atoms/fonts/Body";
import styled from "styled-components";
import { LinkButton } from "../UI/atoms/buttons/LinkButton";
import { useLocation } from "react-router";
import { getParameterByName } from "../../utils/UrlUtils";
import { unsubscribeRequest } from "../../services/UserService";
import { LoadingSpinner } from "../UI/atoms/LoadingSpinner";
import { Trans, useTranslation } from "react-i18next";


enum State {
  loading, successful, failed,
}

export default function UnsubscribePage() {
  const [state, setState] = useState(State.loading);
  const location = useLocation();
  const id = useMemo(() => getParameterByName('id', location.search), [location]);

  useEffect(() => {
    setState(State.loading)
    if (id) {
      unsubscribeRequest(id)
        .then(() => setState(State.successful))
        .catch(() => setState(State.failed))

    }
  }, [id]);

  return (
    <Layout>
      {state === State.loading && <LoadingSpinner fullScreen={true}/>}
      <FadeAndPop hidden={false}>
        <UnsubscribeWrapper>
          {state === State.successful && <Success/>}
          {state === State.failed && <Failed/>}
        </UnsubscribeWrapper>
      </FadeAndPop>
    </Layout>
  );
}

const Success = () => {
  const {t} = useTranslation();
  return (<>
    <HeadlineLarge style={{marginBottom: 24}}>{t('unsubscribe-page.unsubscribed-title')}</HeadlineLarge>
    <BodyRegular style={{marginBottom: 16}}>{t('unsubscribe-page.unsubscribed-message-1')}</BodyRegular>
    <BodyRegular style={{marginBottom: 48}}>{t('unsubscribe-page.unsubscribed-message-2')}</BodyRegular>
    <LinkButton text="button.log-in" link="/login" fullWidth={true} size="large"/>
  </>)

};

const Failed = () => {
  const {t} = useTranslation();
  return (<>
      <HeadlineLarge style={{marginBottom: 24}}>{t('unsubscribe-page.unsubscribed-failed-title')}</HeadlineLarge>
      <BodyError style={{marginBottom: 16}}>{t('unsubscribe-page.unsubscribed-failed-message')}</BodyError>
      <BodyRegular style={{marginBottom: 48}}>
        <Trans i18nKey={'unsubscribe-page.unsubscribed-contact'} components={[<a href={'mailto:hello@team-today.com'}>hello@team-today.com</a>]} />
      </BodyRegular>
      <LinkButton text="button.log-in" link="/login" fullWidth={true} size="large"/>
    </>)
};


export const UnsubscribeWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`
