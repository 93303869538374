import React, { useEffect, useMemo, useState } from 'react';
import { MovementImage } from "../../../atoms/MovementImage";
import { Column, SpaceBetweenRow } from "../../../atoms/StructuralLayout";
import { BodyRegular, BodyVerySmall } from "../../../atoms/fonts/Body";
import Colours from "../../../atoms/Colours";
import { EditHotDeskingButton } from "./EditHotDeskingButton";
import { FavouriteDesk, OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { SimpleSpinner } from '../../../atoms/SimpleSpinner';
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveDay,
  selectActivePeriod, selectUserRequiredParking,
} from "../../../../../store/ducks/editMovements.duck";
import { isDeskAvailable } from "../../../../../services/DeskBookingService";
import {
  confirmDeskForReservation,
  openLocationDialog,
  selectFloorForReservation
} from "../../../../../store/ducks/deskReservation.duck";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { CarParkingCapacityLabel } from "./CarParkingCapacityLabel";
import { selectIsCarParkingEnabled } from "../../../../../store/ducks/config.duck";
import { doesRootOfficeHaveCarParkingPlan, getRootOffice, getRootOfficeById } from "../../../../../utils/OfficeHelper";
import { WithOfficeCapacity } from "../../../../../hooks/WithOfficeCapacity";
import { Moment } from "moment";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";

export function FavouritedDeskActivity(props: Props) {
  const {favouriteDesk} = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [attempted, setAttempted] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const activeDay = useSelector(selectActiveDay);
  const activePeriod = useSelector(selectActivePeriod);
  const offices = useSelector(selectAllOfficeEntities);
  const isCarParkingEnabledForCompany = useSelector(selectIsCarParkingEnabled);
  const userRequiresParking = useSelector(selectUserRequiredParking);
  const iconPack = useSelector(selectIconPack);

  const parentOffice = useMemo(() => offices.find((o: OfficeEntity) => o.id === favouriteDesk.parentOfficeId), [offices, favouriteDesk])
  const rootOffice = useMemo(() => {
    if (parentOffice) {
      return getRootOfficeById(offices, parentOffice.id)
    }
  }, [offices, parentOffice])

  useEffect(() => {
    setAttempted(false);
    setIsAvailable(false);
    setLoading(false);
  }, [activeDay, activePeriod])

  useEffect(() => {
    if (!loading && !attempted && activePeriod && activeDay && rootOffice && parentOffice) {
      setLoading(true);
      setAttempted(true);
      setTimeout(() => setLoading(false), 1500);
      isDeskAvailable(favouriteDesk.deskId, activeDay, activePeriod)
        .then(resp => setIsAvailable(resp))
        .finally(() => setLoading(false));
    }
  }, [loading, attempted, activeDay, activePeriod, favouriteDesk, rootOffice, parentOffice]);

  const onClick = async () => {
    if (!isAvailable || loading) return;

    const parent: OfficeEntity = offices.find((o: OfficeEntity) => o.id === favouriteDesk.parentOfficeId);
    if (userRequiresParking && doesRootOfficeHaveCarParkingPlan(offices, parent)) {
      const rootOffice = getRootOffice(offices, parent);
      dispatch(openLocationDialog({selectedOffice: rootOffice, floor: parent?.floor ? parent : undefined, deskId: favouriteDesk.deskId}));
    } else {
      if (parent?.floor) {
        dispatch(selectFloorForReservation(parent));
      }
      setTimeout(() => dispatch(confirmDeskForReservation({id: favouriteDesk.deskId, officeId: favouriteDesk.parentOfficeId})), 250)
    }
  }

  if (!rootOffice || !parentOffice) return null;

  return (
    <div className={`activity ${isAvailable ? '' : 'activity--disabled'}`.trim()} onClick={onClick}>
      {loading ? <SimpleSpinner size={24} /> : <MovementImage src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="At the desk"/>}
      <SpaceBetweenRow style={{padding: 0, alignItems: 'center'}}>
        <Column>
          <BodyRegular weight={600}>{favouriteDesk.rootOfficeLabel}</BodyRegular>
          <BodyVerySmall colour={Colours.mildGrey}>{favouriteDesk.parentOfficeLabel}</BodyVerySmall>
          {isCarParkingEnabledForCompany && <CarParkingCapacityLabelWrapper rootOffice={rootOffice} selectedDate={activeDay} />}
        </Column>
        <EditHotDeskingButton />
      </SpaceBetweenRow>
    </div>
  )
}

function CarParkingCapacityLabelWrapper(props: {rootOffice?: OfficeEntity, selectedDate?: Moment}) {
  const capacity = WithOfficeCapacity(props.rootOffice, props.selectedDate);
  return <CarParkingCapacityLabel isCarParkingEnabledForCompany={true} capacity={capacity.capacity} />;
}

interface Props {
  openEditLocationDialog: (e: Event, selectedOffice?: OfficeEntity) => any;
  favouriteDesk: FavouriteDesk;
}
