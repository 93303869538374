import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { createTeamRequest } from '../../services/TeamService';
import { AppState } from '../state/app.state';
import { updateCurrentUser } from './auth.duck';
import { failureNotification, successNotification } from './notification.duck';
import { enrichError } from "../../utils/error-helper";
import { TeamEntity } from "../../models/team.models";
import { loadAccountInfo } from "./payments.duck";
import { loadConfig } from "./config.duck";

export interface AddTeamDialogState {
  processing: boolean;
  success: boolean;
  addedTeam: TeamEntity | null;
  error: string;
  failedRequest: {
    teamName: string;
    addUserToTeam: boolean;
  }
}

export const initialAddTeamDialogState: AddTeamDialogState = {
  processing: false,
  success: false,
  addedTeam: null,
  error: '',
  failedRequest: {
    teamName: '',
    addUserToTeam: false,
  }
}

export const addNewTeam = createAsyncThunk(
  'addteamDialog/addTeam',
  async (addTeamDetails: any, thunkAPI) => {
    try {
      await thunkAPI.dispatch(setProcessing());
      const response = await createTeamRequest(addTeamDetails.teamName, addTeamDetails.addUserToTeam);
      await thunkAPI.dispatch(setAddedTeam(response));

      if (addTeamDetails.addUserToTeam) {
        await thunkAPI.dispatch(updateCurrentUser(true));
      }

      await thunkAPI.dispatch(loadAccountInfo());
      thunkAPI.dispatch(loadConfig());

      await thunkAPI.dispatch(setSuccess());
      await thunkAPI.dispatch(successNotification('Team added'));
    } catch (err: any) {
      const enrichedError = enrichError(err?.message);
      await thunkAPI.dispatch(setError({message: enrichedError.description, details: addTeamDetails}));
      await thunkAPI.dispatch(failureNotification(enrichedError.description))
    }
  }
);

const slice = createSlice({
  name: 'addTeamDialog',
  initialState: initialAddTeamDialogState,
  reducers: {
    setAddedTeam: (state, action) => ({...state, addedTeam: action.payload}),
    setProcessing: (state) => ({...state, processing: true, success: false, error: ''}),
    setSuccess: (state) => ({...state, processing: false, success: true, error: ''}),
    setError: (state, action) => ({
      ...state,
      processing: false,
      success: false,
      error: action.payload.message,
      failedRequest: action.payload.details
    }),
    resetAddTeamDialog: () => ({ ...initialAddTeamDialogState, })
  },
  extraReducers: {
    // eslint-disable-next-line
    ['addTeamDialog/addTeam/fulfilled']: (state, action) => {
      return {
        ...state,
      }
    },
  }
})

export default slice.reducer;
export const {
  setProcessing,
  setSuccess,
  setError,
  resetAddTeamDialog,
  setAddedTeam,
} = slice.actions;

// Selectors
export const selectAddTeamProcessingState = (state: AppState) => ({
  processing: state.addTeamDialog.processing,
  error: state.addTeamDialog.error,
  success: state.addTeamDialog.success,
  failedRequest: state.addTeamDialog.failedRequest,
});
export const selectLastAddedTeam = (state: AppState) => state.addTeamDialog.addedTeam;
