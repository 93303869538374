import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import DialogHeader from "./DialogHeader";
import Dialog from "../UI/molecules/Dialog";
import TextField from "../UI/atoms/TextField";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import styled from "styled-components";
import {
  createTag,
  deleteTag,
  fetchTags,
  selectAllTags,
  selectTagsLoading,
  updateTag
} from "../../store/ducks/userTags.duck";
import { canManageWorkGroups } from "../../utils/AccessControl";
import { selectCurrentUser } from "../../store/ducks/auth.duck";
import AccessRequired from "../pages/settings/components/AccessRequired";
import { Accordian } from "../../animations/AnimationVariants";
import { LoadingOverlay } from "../UI/molecules/LoadingOverlay";
import { BodyError, BodyRegular } from "../UI/atoms/fonts/Body";
import { TagWithCount } from "../../models/tags.models";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";
import { FlatContentCard } from "../UI/atoms/FlatContentCard";
import { useTranslation } from "react-i18next";

interface Props {
  payload: {
    tag?: TagWithCount
  }
}

export default function CreateTagDialog(props: Props) {
  const {t} = useTranslation();
  const tag = props.payload.tag;
  const dispatch = useDispatch();
  const [newTagName, setNewTagName] = useState('');
  const [tagsToCreate, setTagsToCreate] = useState<string[]>([]);
  const loading = useSelector(selectTagsLoading);
  const tags = useSelector(selectAllTags);
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => canManageWorkGroups(currentUser), [currentUser]);
  const [error, setError] = useState('');

  useEffect(() => {
    setNewTagName('');
    setTagsToCreate([]);
  }, [dispatch])

  useEffect(() => {
    setNewTagName(tag?.label ?? '');
  }, [tag])

  const close = () => {
    dispatch(closeDialog());
    dispatch(fetchTags());
  }

  const createNewTag = () => {
    if (tags.filter(tag => tag.label === newTagName).length > 0) {
      setError('There is a tag with a duplicate name.')
    } else {
      dispatch(createTag(newTagName));
      close();
    }
  }

  const updateTagClicked = () => {
    if (tag?.label === newTagName) {
      setError('Workgroup name has not changed');
      return;
    }
    if (tag) {
      dispatch(updateTag({...tag, label: newTagName}));
      close();
    }
  }

  const deleteTagClicked = () => {
    if (!!tag) {
      dispatch(deleteTag(tag.id));
      close();
    }
  }

  const onTextFieldChange = (val: string) => {
    setNewTagName(val);
    if (tagsToCreate.filter(tag => tag === val).length > 0 || tags.filter(tag => tag.label === val).length > 0) {
      setError('There is already a tag with that name')
    } else {
      setError('');
    }
  }

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title={!!tag ? 'manage-tag-dialog.edit-title' : 'manage-tag-dialog.create-title'} />
      <AccessRequired teamAdminsRequired={true} companyAdminsRequired={true} visible={!hasAccess} />

      {loading && <LoadingOverlay />}

      <Accordian isOpen={!!error}>
        <BodyError style={{paddingBottom: 24}}>{error}</BodyError>
      </Accordian>

      {hasAccess && <div style={{marginBottom: 32}}>
        <InputRow style={{marginBottom: 32}}>
          <TextField onChange={onTextFieldChange} value={newTagName} />
        </InputRow>
      </div>}

      {!!tag && <FlatContentCard style={{marginBottom: 36}}>
        <BodyRegular>{t('manage-tag-dialog.group-summary', {count: tag?.memberCount})}</BodyRegular>
      </FlatContentCard>}

      <PrimaryButton click={!!tag ? updateTagClicked : createNewTag} style={{marginBottom: 16}}
                     text={!!tag ? 'manage-tag-dialog.update' : 'button.save-changes'}
                     fullWidth={true} />
      {!!tag && <OutlineButton click={deleteTagClicked} text={"manage-tag-dialog.delete"} fullWidth={true} />}
    </Dialog>
  )
}

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .textField {
    margin-bottom: 0;
    flex: 1;
  }
`
