import { agent } from './agent';

export async function getPickableWhereaboutsOptions(): Promise<WhereaboutsOption[]> {
  return (await agent.requests.get('/api/whereabouts-options'));
}

export async function getAllWhereaboutsOptions(): Promise<WhereaboutsOption[]> {
  return (await agent.requests.get('/api/whereabouts-options/all'));
}

export async function deleteWhereaboutsOption(option: WhereaboutsOption): Promise<void> {
  return (await agent.requests.del(`/api/whereabouts-options/${option.id}`, true));
}

export async function createUpdateWhereaboutsOption(request: WhereaboutsOption): Promise<void> {
  return (await agent.requests.post('/api/whereabouts-options', request, true));
}

export interface WhereaboutsOption {
  id: number;

  dataTest: string;
  alt: string;
  image: string;

  key: any;
  companyId: string;
  status: string;
  label: string;
  outlookNote: string;
  freeBusyStatus: FreeBusyStatus;
  slackNote: string;

  pickable: boolean;
  freeTextEnabled: boolean;
  required: boolean;
  enabled: boolean;

  colour: string;
}

export enum FixedWhereabouts {
  HomeRejected = 'HOME_REJECT',
  HomeApproved = 'HOME_APPROVED',
  Home = 'HOME',
  HomePending = 'HOME_PENDING',
  Office = 'OFFICE',
  HolidayPending = 'HOLIDAY_PENDING',
  HolidayRejected = 'HOLIDAY_REJECTED',
  HolidayApproved = 'HOLIDAY_APPROVED',
  Holiday = 'HOLIDAY',
  NotWorking = 'NOT_WORKING',
  Unknown = 'UNKNOWN',
  CompanyHoliday = 'COMPANY_HOLIDAY'
}

export const FixedWhereaboutsValues = Object.keys(FixedWhereabouts);

export enum FreeBusyStatus {
  FREE = 'Free',
  BUSY = 'Busy',
  OOF = 'Oof',
  TENTATIVE = 'Tentative',
  WORKING_ELSEWHERE = 'WorkingElsewhere',
}

export const UNKNOWN_OPTION: WhereaboutsOption = {
  id: 3,
  dataTest: 'unknown-option',
  alt: 'Unknown',
  image: '',
  key: FixedWhereabouts.Unknown,
  companyId: '',
  status: 'Unknown',
  label: 'Unknown',
  outlookNote: '',
  freeBusyStatus: FreeBusyStatus.FREE,
  slackNote: '',
  pickable: false,
  freeTextEnabled: false,
  required: false,
  enabled: false,
  colour: 'grey',
}

export const OFFICE_OPTION: WhereaboutsOption = {
  id: 2,
  dataTest: 'office-option',
  alt: 'Office',
  image: 'Office.svg',
  key: 'OFFICE',
  companyId: '',
  status: 'OFFICE',
  label: 'Office',
  outlookNote: '',
  freeBusyStatus: FreeBusyStatus.FREE,
  slackNote: '',
  pickable: false,
  freeTextEnabled: false,
  required: false,
  enabled: false,
  colour: 'green',
}
