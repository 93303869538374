import React, { useEffect, useState } from 'react';
import { BaseProps } from "../../../models/Props.models";
import { BodyError, BodyRegular, BodySmall } from "./fonts/Body";
import styled from "styled-components";
import Icon from "./icon/Icon";
import { useTranslation } from "react-i18next";
import Colours from "./Colours";

export default function TextField(props: TextFieldProps) {
  const [value, setValueText] = useState(props.value ?? '');
  const {t} = useTranslation();
  const isDisabled = props.disabled;
  const disabledClass = props.disabled ? 'textField--disabled' : '';
  const className = props.className || '';

  const setValue = (value: any) => {
    setValueText(value);
    props.onChange(value);
  }

  useEffect(() => {
    setValueText(props.value || '')
  }, [props])

  let inputAttributes = {};
  if (props.dataTest) {
    // @ts-ignore
    inputAttributes['data-test'] = props.dataTest;
  }
  if (props.enableAutoComplete) {
    // @ts-ignore
    inputAttributes.autoComplete = "false";
  }
  if (props.editableOnly) {
    // @ts-ignore
    inputAttributes.readOnly = props.editableOnly;
  }

  return (
    <div className={`textField${!!props.error ? ' textField--error' : ''} ${className} ${disabledClass}`.trim()} style={props?.style ?? {}}>
      {props.label && <BodySmall className="textField__label" weight={600}>{t(props.label)}</BodySmall>}
      <TextInputRow style={{alignItems: 'center'}} className="textField__inputRow" onClick={() => props.onClick ? props.onClick() : null} fullWidth={props?.fullWidth ?? false}>
        {props.leftText && <BodyRegular weight={600} className="textField__leftText">{t(props.leftText)}</BodyRegular>}
        <input disabled={isDisabled}
               onBlur={props?.onBlur}
               className="textField__input"
               type={props.type || 'text'}
               name={props.name || ''}
               value={value}
               onChange={e => setValue(e.target.value)}
               placeholder={t(props?.placeholder ?? '') ?? ''}
               {...inputAttributes} />
        {props.rightText && <BodyRegular weight={600} className="textField__rightText">{t(props.rightText)}</BodyRegular>}
        {props.icon && <TextInputIcon size="medium" icon={props.icon} onClick={props.onIconClick} hasClick={!!props.onIconClick} />}
      </TextInputRow>
      {props.error && <BodyError size={16} weight={400} className="textField__error"><img src="/assets/icons/error.svg" alt="Error" />{props.error}</BodyError>}
    </div>
  )
}

export interface TextFieldProps extends BaseProps {
  dataTest?: string;
  label?: string;
  leftText?: string;
  rightText?: string;
  type?: string;
  name?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  editableOnly?: boolean;
  enableAutoComplete?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  onIconClick?: () => void;
  icon?: string;
  fullWidth?: boolean;
  style?: any;
  onBlur?: () => void;
}

const TextInputRow = styled.div<any>`
  display: flex;
  position: relative;
  max-width: ${props => props.fullWidth ? '100%' : '400px'};
`


const TextInputIcon = styled<any>(Icon)<any>`
  position: absolute;
  right: 12px;
  &::before {
    padding: 2px;
  }
  &:hover {
    cursor: ${props => props.hasClick ? 'pointer': 'default'};
    &::before {
      background-color: ${props => props.hasClick ? Colours.blue10: 'unset'};
      border-radius: 4px;
    }
  }
`
