import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BodyLarge, BodyRegular } from '../../../../UI/atoms/fonts/Body';
import { HeadlineLarge } from '../../../../UI/atoms/fonts/Headline';
import { LinkButton } from '../../../../UI/atoms/buttons/LinkButton';
import { selectOrphanedUsers } from '../../../../../store/ducks/management.duck';
import { selectCurrentUser } from '../../../../../store/ducks/auth.duck';
import { Permission } from "../../../../../models/user.models";
import { AddAnotherTeamButton } from "../../../../UI/atoms/buttons/AddAnotherTeamButton";
import LookingAfterMultipleTeams from "./components/LookingAfterMultiTeams";
import Colours from "../../../../UI/atoms/Colours";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { isBasicUser } from "../../../../../utils/AccessControl";
import AccessRequired from "../../components/AccessRequired";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { selectAllTeams } from "../../../../../store/ducks/config.duck";

export default function ManageTeamsAndUsersPage() {
  const current = useSelector(selectCurrentUser);
  const company = current?.companyEntity;
  const teams = useSelector(selectAllTeams);
  const orphanedUsers = useSelector(selectOrphanedUsers);
  const hasAccessToManageTeams = useMemo(() => isBasicUser(current), [current]);
  const {t} = useTranslation();

  const myTeam = useMemo(() => teams.filter(t => t.id === current?.teamEntity?.id), [teams, current]);
  const otherTeams = useMemo(() => teams.filter(t => t.id !== current?.teamEntity?.id), [teams, current]);

  return (
    <React.Fragment>
      <GoBackButton url={'/manage'} />
      <HeadlineLarge className="teamList__header">{t('settings.manage-teams-and-users')}</HeadlineLarge>
      <div className="manage__sections">

        <AccessRequired visible={hasAccessToManageTeams} companyAdminsRequired={true} teamAdminsRequired={true}  />

        {orphanedUsers.length > 0 && (current?.role === Permission.CompanyAdmin || current?.role === Permission.TeamAdmin) ?
          <React.Fragment>
            <BodyLarge className="manage__subtitle" colour={Colours.red}>{t('settings.users-with-no-teams')}</BodyLarge>
            <div className="manage__section">
              <BodyRegular>{`${orphanedUsers.length}`} {t('users')}</BodyRegular>
              <LinkButton className="management__button"
                          text="button.assign-users-to-team"
                          type="grey"
                          link="/manage/assign-users"/>
            </div>
          </React.Fragment>
          : null}

        <BodyLarge className="manage__subtitle">{t('settings.my-team')}</BodyLarge>
        {myTeam.map((teamInfo, key) => (
          <TeamSection key={key} teamInfo={teamInfo}/>
        ))}

        <BodyLarge className="manage__subtitle">{t('settings.other-teams')}</BodyLarge>
        {otherTeams.map((teamInfo, key) => (
          <TeamSection key={key} teamInfo={teamInfo}/>
        ))}

        {!company?.enabled && <LookingAfterMultipleTeams/>}

      </div>
      {company?.enabled && <AddAnotherTeamButton size="medium" fullWidth={true} type="primary"/>}
    </React.Fragment>
  );
}

function TeamSection(props: { teamInfo: any }) {
  const {teamInfo} = props;
  const teamName = teamInfo.name;
  const teamButtonClass = useMemo(() => 'manage__section team-' + teamName.replace(' ', '-'), [teamName]);
  const {t} = useTranslation();
  return (
    <TeamContentCard className={teamButtonClass}>
      <BodyRegular weight={600} colour={Colours.blue} style={{marginBottom: 10}}>{teamName}</BodyRegular>
      <BodyRegular className="manage__teamMemberCount" style={{marginBottom: 16}}>{teamInfo.memberCount} {t('general.team-members')}</BodyRegular>
      <LinkButton text="button.edit-and-manage-team" type="outline"
                  className="management__button"
                  link={`/manage/team/${teamInfo.id}`}/>
    </TeamContentCard>
  )
}

export const TeamContentCard = styled.div`
  margin-bottom: 24px;
  border: 1px solid ${Colours.mildGrey};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 24px 24px 24px;
`
