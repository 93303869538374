import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { getOfficeChildren } from "../utils/OfficeHelper";

export function WithOfficeChildren(officeId?: number): OfficeEntity[] {
  const offices = useSelector(selectAllOfficeEntities);
  return useMemo(() => {
    if (officeId) {
      return getOfficeChildren(officeId, offices);
    } else {
      return [];
    }
  }, [officeId, offices]);
}

export function WithActiveOfficeChildren(officeId?: number): OfficeEntity[] {
  return WithOfficeChildren(officeId).filter((o: OfficeEntity) => o.active);
}

export function hasOfficeChildren(officeId?: number, offices?: OfficeEntity[]): boolean {
  return getOfficeChildren(officeId, offices).length > 0
}
