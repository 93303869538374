import React, { useMemo } from 'react';
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import { GenericDropDownList } from "../../../UI/molecules/GenericDropDownList";
import { useDispatch, useSelector } from "react-redux";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import Colours from "../../../UI/atoms/Colours";
import { updateUserField, UpdateUserField } from "../../../../services/UserService";
import { failureNotification, successNotification } from "../../../../store/ducks/notification.duck";
import { setUser } from "../../../../store/ducks/auth.duck";
import { UserInfo } from "../../../../models/user.models";
import styled from "styled-components/macro";
import { Auth } from "../../../../services/Auth";
import { getFullOfficeTitle } from "../../../../utils/OfficeHelper";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { useTranslation } from "react-i18next";


interface Props {
  user: UserInfo;
  onLocationChange: any;
  disabled: boolean;
}

export default function SelectLocationSection(props: Props) {
  const dispatch = useDispatch();
  const offices = useSelector(selectAllOfficeEntities);
  const {t} = useTranslation();

  const dropDownItems = useMemo(() => {
    const items = offices
      .map((office: OfficeEntity) => {
        return {id: office.id, name: getFullOfficeTitle(office, offices, ', ')};
      })
    return naturalSort(items, 'name');
  }, [offices]);

  const onChange = async (id: any) => {
    try {
      await updateUserField(props.user.userId, UpdateUserField.defaultOfficeId, id)
      dispatch(successNotification('Default office updated'))
      Auth.current(false).then((user) => dispatch(setUser(user)));
      props.onLocationChange();
    } catch (err: any) {
      dispatch(failureNotification('Office not updated'))
    }
  }

  if (!offices || offices.length === 0) {
    return null;
  }

  return (
    <Wrapper className="" data-test="location-section">
      <BodyLarge colour={Colours.blue} style={{marginBottom: 6}}>{t('settings-page.edit-user.location-title')}</BodyLarge>
      <BodyRegular style={{marginBottom: 6}}>{t('settings-page.edit-user.location-message')}</BodyRegular>
      <GenericDropDownList disabled={props.disabled} label="settings-page.edit-user.office-base"
                           selectedId={props.user?.defaultLocationId}
                           onSelect={onChange} items={[{id: 0, name: 'None'}, ...dropDownItems]}
                           placeholder="textinput.please-select" />
    </Wrapper>
  )
}


const Wrapper = styled.div`
  .teamDropDownList {
    width: 100%;
  }
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 24px;
  padding-bottom: 12px;
`
