import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from "../../../../services/Auth";
import AuthWrapper from "../components/AuthWrapper";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import { clearLoginStorage } from "../../../../utils/AuthUtils";
import { useTranslation } from "react-i18next";


export default function LogoutWithAzure() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation()

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    clearLoginStorage();
    Auth.logout(dispatch);
  }, [dispatch]);

  return <AuthWrapper dataTest="logout-azure" hideLoginLink={true} spinner={!!currentUser}>
    {!currentUser && <BodyLarge>{t('general.successfully-logged-out')}</BodyLarge>}
  </AuthWrapper>
}
