import React from 'react';
import styled from "styled-components";
import Colours from "./Colours";

interface Props {
  complete: number;
  style?: any;
}

export function ProgressBar(props: Props) {
  return (
    <Background style={props?.style ?? {}}>
      <Fill style={{width: `${props.complete}%`}}/>
    </Background>
  );
}

export const Background = styled.div`
  width: 100%;
  border-radius: 4px;
  height: 8px;
  background: #CCDDFF;
  overflow: hidden;
`

export const Fill = styled.div`
  width: 0;
  height: 8px;
  background-color: ${Colours.blue};
`
