import React, { useEffect } from 'react';
import { Moment } from "moment";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import DateFlicker, { DateScale } from "./DateFlicker";
import { BodyRegular } from "../atoms/fonts/Body";
import { selectActiveDay, setActiveDay } from "../../../store/ducks/editMovements.duck";
import { getParameterByName } from "../../../utils/UrlUtils";
import { WithDaysInWorkWeek } from "../../../hooks/WithDaysInWorkWeek";
import { selectCalendarDate, setCalendarDate } from "../../../store/ducks/dashboard.duck";
import { Column } from "../atoms/StructuralLayout";
import { desktopSmall, smallTablet } from "../atoms/MediaQueries";
import Colours from "../atoms/Colours";
import { PageTitle } from "../atoms/PageTitle";
import { OfficeListSidebar } from "../../pages/company-movements/components/daily-office-list-view/OfficeListSidebar";
import { TabletOnly } from "../atoms/DeviceSpecific";

export function ContainerViewDateWrapper(props: Props) {
  const dispatch = useDispatch();
  const currentWeek = WithDaysInWorkWeek();
  const selectedDate = useSelector(selectActiveDay);
  const globalDate = useSelector(selectCalendarDate);

  useEffect(() => {
    const date = getParameterByName('date', window.location.href);
    if (date) {
      try {
        dispatch(setActiveDay(moment(date)));
      } catch (e) {
      }
    }
  }, [dispatch])

  const onDateChange = (date: Moment) => {
    dispatch(setActiveDay(date));
    dispatch(setCalendarDate(date));
  }

  return (
    <OfficeUsageViewWrapper>
      {props.title && <PageTitle text={props.title} />}
      <TabletOnly>
        <OfficeListSidebar />
      </TabletOnly>

      <DateNavigation>
        <DateFlickerWrapper>
          <DateFlicker date={globalDate} dateScale={DateScale.Week} updateGlobalDate={true} onChange={onDateChange} />
        </DateFlickerWrapper>
        <WeekHeader data-test="office-week-header">
          {currentWeek.map((day: Moment, key: number) =>
            <DayHeader key={key} isActive={day.format('YYYYMMDD') === selectedDate?.format('YYYYMMDD')} onClick={() => onDateChange(day)}>
              <BodyRegular weight={600}>{day.format('ddd')}</BodyRegular>
              <BodyRegular weight={600}>{day.format('D')}</BodyRegular>
            </DayHeader>)}
        </WeekHeader>
      </DateNavigation>
      <ChildContainer>
        {props.children}
      </ChildContainer>
    </OfficeUsageViewWrapper>
  )
}

interface Props {
  title?: string;
  children: any;
}

const OfficeUsageViewWrapper = styled<any>(Column)`
  @media (${smallTablet}) {
    padding-right: 32px;
  }
`

const DayHeader = styled.div<any>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.isActive ? Colours.veryLightGrey : 'transparent'};
  min-height: 64px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: ${props => props.isActive ? `solid 2px ${Colours.mildGrey}` : ``};
  border-bottom: ${props => props.isActive ? `solid 2px ${Colours.veryLightGrey}` : `none`};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 9;
  cursor: pointer;
  p {
    color: ${props => props.isActive ? Colours.blue : Colours.darkGrey};
  }
`

const ChildContainer = styled.div`
  border: 2px solid ${Colours.mildGrey};
  border-radius: 12px;
  min-height: 500px;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-top: -2px;
`

const DateNavigation = styled.div`
  display: flex;
  flex-direction: column;
  @media (${desktopSmall}) {
    flex-direction: row;
    margin-top: 24px;
  }
`

const DateFlickerWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
  @media (${desktopSmall}) {
    width: 25%;
    margin-bottom: 0;
  }
`

const WeekHeader = styled.div`
  min-height: 64px;
  display: flex;
  flex: 1;
`
