import React, { useState } from 'react';
import styled from "styled-components";
import { Center, Column, VerticallyCenteredRow } from '../../atoms/StructuralLayout';
import { UserAvatarAndName } from "../../molecules/UserAvatarAndName";
import { findUserRequest } from "../../../../services/UserService";
import { BasicUserInfo } from "../../../../models/user.models";
import Select, { components } from "react-select";
import { BodyRegular, BodySmall } from '../../atoms/fonts/Body';
import { tablet } from "../../atoms/MediaQueries";
import Colours from "../../atoms/Colours";
import { useDispatch } from "react-redux";
import { redirectTo } from "../../../../store/ducks/auth.duck";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { deepLinkTo, isInTeams } from "../../../../utils/TeamsUtils";
import { teamsTabs, uiUrl } from "../../../../services/EnvironmentVariables";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { useTranslation } from "react-i18next";
import { setIsSearchBarVisible } from "../../../../store/ducks/dashboard.duck";

export function SearchBar(props: Props) {
  const dispatch = useDispatch();
  const {onItemSelected} = props;
  const [selected, setSelected] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const {t} = useTranslation();

  const onInputChange = async (query: string, query2: any) => {
    setInputValue(query)
    if (query.length < 3) return

    try {
      const searchResponse = await findUserRequest(query);
      const parsedOptions = searchResponse.map((userInfo: BasicUserInfo) => ({...userInfo, value: userInfo.userId, label: `${userInfo.firstName} ${userInfo.lastName}`}));
      const sortedOptions = naturalSort(parsedOptions, 'label');
      setOptions(sortedOptions);
    } catch (err: any) {
      setOptions([]);
    }
  }

  const CustomOption = (props: any) => {
    const click = () => {
      if (onItemSelected) {
        onItemSelected(props.data);
      } else {
        if (props.data.teamId) {
          dispatch(redirectTo(`/whereabouts/team/search/${props.data.teamId}`));
        } else {
          if (isInTeams()) {
            const externalUrl = `${uiUrl}/manage/user/${props.data.userId}`
            deepLinkTo(teamsTabs.settings, externalUrl, 'user', props.data.userId);
          } else {
            dispatch(warningNotification('That user does not belong to a team.'));
            dispatch(redirectTo(`/manage/user/${props.data.userId}`));
          }
        }
      }
    }
    return (
      <SearchResultDropDownItem {...props.innerProps} onClick={click}>
        <Column style={{padding: '4px 2px'}}>
          <VerticallyCenteredRow>
            <UserAvatarAndName firstName={props.data.firstName} lastName={props.data.lastName} hideName={true} />
            <BodyRegular style={{padding: '4px 0 4px 12px'}}>{props.data.firstName} {props.data.lastName}</BodyRegular>
          </VerticallyCenteredRow>
          <BodySmall style={{marginLeft: 44, marginTop: -4}} colour={Colours.darkGrey}>{props.data.teamName ?? t('general.no-team')}</BodySmall>
        </Column>
      </SearchResultDropDownItem>
    );
  }

  const CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Center style={{padding: '0 8px'}}>
          <i className="icon-Search" />
        </Center>
      </components.DropdownIndicator>
    );
  }

  const styles = {
    menu: (css: any) => ({
      ...css,
      width: "max-content",
      minWidth: "250px"
    }),
    placeholder: (css: any) => ({
      ...css,
      fontFamily: '"soleil", sans-serif',
      fontSize: '18px',
      lineHeight: '28px',
      color: 'hsl(0, 0%, 80%)',
    }),
    noOptionsMessage: (css: any) => ({
      ...css,
      fontFamily: '"soleil", sans-serif',
      fontSize: '18px',
      lineHeight: '28px'
    }),
    input: (css: any) => ({
      ...css,
      fontFamily: '"soleil", sans-serif',
      fontSize: '18px',
      lineHeight: '28px'
    }),
  };

  return (
    <VerticallyCenteredRow style={{boxShadow: '0 10px 15px -20px #000000', backgroundColor: 'white'}}>
      <Center>
        <SearchBarContainer style={{maxWidth: 500, width: '100%'}} className={props.className ?? ''}>
          <Select
            styles={styles}
            value={selected}
            inputValue={inputValue}
            placeholder={t('textinput.search')}
            onInputChange={onInputChange}
            options={options}
            className="searchbar"
            classNamePrefix="searchbar"
            autoFocus={true}
            onBlur={() => dispatch(setIsSearchBarVisible(false))}
            components={{
              Option: CustomOption,
              DropdownIndicator: CustomDropdownIndicator,
            }}
            onChange={setSelected}
          />
        </SearchBarContainer>
      </Center>
    </VerticallyCenteredRow>
  )
}

interface Props {
  style?: any;
  className?: string;
  onItemSelected?: (obj: any) => any;
}

const SearchBarContainer = styled.div`
  display: flex;
  max-width: 100%;
  align-self: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 16px;
  @media (${tablet}) {
    max-width: 300px;
    width: 100%;
  }
`

const SearchResultDropDownItem = styled.div`
  cursor: pointer;
  padding: 0 16px 0 4px;
  &:hover {
    background-color: ${Colours.blue10};
  }
`
