import React from 'react';
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../../UI/atoms/fonts/Body";

export function ApprovalsNotEnabled(props: Props) {
  const {t} = useTranslation();
  return (
    <Container>
      <Image src={"/assets/images/spacing-image.svg"} alt={""} />
      <HeadlineSmall style={{marginBottom: 12}}>{t('settings.approvals.approvals-disabled-title')}</HeadlineSmall>
      <BodyLarge>{t('settings.approvals.approvals-disabled-description')}</BodyLarge>
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  max-width: 500px;
  margin: 50px auto 200px auto;
`

const Image = styled.img`
  width: 100%;
  margin-bottom: 30px;
`
