import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge, HeadlineSmall } from '../../../UI/atoms/fonts/Headline';
import TextField from '../../../UI/atoms/TextField';
import AccessRequired from '../components/AccessRequired';
import { useParams } from 'react-router';
import { TeamWithCount } from '../../../../models/team.models';
import {
  getTeamMembers,
  updateTeamAssignedDepartmentsRequest,
  updateTeamAssignedOfficesRequest,
  updateTeamRequest
} from '../../../../services/TeamService';
import UserList from '../../../UI/organisms/user-list/UserList';
import { redirectTo, selectCurrentUser } from '../../../../store/ducks/auth.duck';
import { Button } from '../../../UI/atoms/buttons/Button';
import { selectIsAddMemberDialogOpen } from '../../../../store/ducks/addMemberDialog.duck';
import IconButton from '../../../UI/molecules/icon-button/IconButton';
import { NotificationMessages, redirectWithNotification } from '../../../../models/notification.models';
import { failureNotification, successNotification } from '../../../../store/ducks/notification.duck';
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import { canUserEditTeam, canUserMoveUsersIntoDifferentTeams } from "../../../../utils/AccessControl";
import { setIsDeleteTeamDialogOpen } from '../../../dialogs/delete-team-dialog/deleteTeamDialog.duck';
import { Column } from '../../../UI/atoms/StructuralLayout';
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";
import { loadConfig, selectAllTeams } from "../../../../store/ducks/config.duck";
import { OfficeMultiSelectDropDownList } from "../../../UI/organisms/OfficeMultiSelectDropDownList";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import styled from "styled-components/macro";
import { DepartmentsMultiSelectDropDownList } from "../../../UI/organisms/DepartmentsMultiSelectDropDownList";
import { Department } from "../../departments/data/departments.models";
import { selectAllDepartments } from "../../departments/data/departments.duck";

export default function EditTeamPage() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const teams = useSelector(selectAllTeams);
  const addMemberDialogOpenState = useSelector(selectIsAddMemberDialogOpen);
  const departments = useSelector(selectAllDepartments);
  const [selectedTeam, setSelectedTeam] = useState<TeamWithCount | undefined>(undefined);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [selectedOfficeIds, setSelectedOfficeIds] = useState<number[]>([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<number[]>([]);
  const isTeamEditable = useMemo(() => canUserEditTeam(current, selectedTeam), [current, selectedTeam]);
  const canMoveUsers = useMemo(() => canUserMoveUsersIntoDifferentTeams(current), [current]);
  const {t} = useTranslation();

  useEffect(() => {
    if (!addMemberDialogOpenState && isDialogOpen) {
      const loadTeamDetails = async () => {
        if (selectedTeam) {
          setLoading(true);
          try {
            const teamMembers = await getTeamMembers(selectedTeam.id);
            setTeamMembers(teamMembers);
          } catch (err: any) {
            console.error(err)
            redirectWithNotification(NotificationMessages.errorLoadingTeam, '/manage/teams-and-users')
          } finally {
            setLoading(false)
          }
        }
      }
      setIsDialogOpen(false);
      loadTeamDetails();
    }
  }, [selectedTeam, addMemberDialogOpenState, isDialogOpen])

  useEffect(() => {
    const team = teams.find(t => t.id === params.teamId);
    if (team) {
      setLoading(true);
      setSelectedTeam(team);
      setTeamName(team?.name ?? '');
      setSelectedOfficeIds(team?.officeIds ?? [])
      setSelectedDepartmentIds(team?.departmentIds ?? [])
      setLoading(true);
      getTeamMembers(team.id)
        .then(teamMembers => {
          setTeamMembers(teamMembers);
        })
        .then(() => setLoading(false))
        .catch(() => redirectWithNotification(NotificationMessages.errorLoadingTeam, '/manage/teams-and-users'))
    } else if (teams.length > 0) {
      console.error('Error, there has been an error finding the team id')
      redirectWithNotification(NotificationMessages.unknownError, '/manage/teams-and-users')
    } else {
      setLoading(true);
      dispatch(loadConfig());
    }
  }, [teams, params, dispatch]);

  const openDeleteTeamDialog = () => {
    if (!selectedTeam) return;
    dispatch(setIsDeleteTeamDialogOpen({isOpen: true, team: selectedTeam}));
  }

  const saveChanges = async () => {
    if (selectedTeam) {
      setLoading(true);
      try {
        await updateTeamRequest(selectedTeam.id, teamName);
        await updateTeamAssignedOfficesRequest(selectedTeam.id, selectedOfficeIds);

        if (departments.length > 0) {
          await updateTeamAssignedDepartmentsRequest(selectedTeam.id, selectedDepartmentIds);
        }

        dispatch(loadConfig());
        dispatch(successNotification('Team updated'));
        dispatch(redirectTo('/manage/teams-and-users'));
      } catch {
        dispatch(failureNotification('Team failed to update'));
      } finally {
        setLoading(false);
      }
    }
  }

  const onOfficesSelected = (offices: OfficeEntity[]) => {
    const officeIds = offices.map(o => o.id);
    setSelectedOfficeIds(officeIds);
  }

  const onDepartmentsSelected = (departments: Department[]) => {
    setSelectedDepartmentIds(departments.map(d => d.id));
  }

  return (
    <React.Fragment>
      {loading && <LoadingSpinner fullScreen={true}/>}
      <div className="management">

        <GoBackButton/>
        <HeadlineLarge className="management__title">{t('edit-team.title')}</HeadlineLarge>
        <AccessRequired visible={!isTeamEditable && !canMoveUsers} companyAdminsRequired={true} teamAdminsRequired={true}/>

        <Column className="management__row">
          <TextField dataTest="editTeamPage-teamName-input" label={'edit-team.team-name'} value={teamName} onChange={setTeamName}
                     disabled={!isTeamEditable}/>

          <SelectOfficesWrapper>
            <OfficeMultiSelectDropDownList label={'edit-team.select-office-description'}
                                           selectedOfficeIds={selectedOfficeIds}
                                           onSelect={onOfficesSelected}
                                           disabled={false}/>
          </SelectOfficesWrapper>

          {departments.length > 0 && <SelectOfficesWrapper style={{marginBottom: 48}}>
            <DepartmentsMultiSelectDropDownList label={'edit-team.select-departments-description'}
                                                selectedDepartmentIds={selectedDepartmentIds}
                                                onSelect={onDepartmentsSelected}
                                                disabled={false}/>
          </SelectOfficesWrapper>}

          {isTeamEditable ?
            <Button dataTest="editTeamPage-save" size="large"
                    text="button.save-changes" type="outline-black" fullWidth={false}
                    onClick={saveChanges}/> : null}
        </Column>


        <div className={`${canMoveUsers ? '' : 'management__disabled'}`.trim()}>
          <div className="editTeam__membersHeader">
            <HeadlineSmall className="management__row">{t('members')}</HeadlineSmall>
            <OutlineButton dataTest="editTeamPage-addTeamMember-button"
                           className="management__row"
                           size="medium"
                           text="button.add-team-member"
                           disabled={!canMoveUsers}
                           link={"/manage/add-users"}/>
          </div>
          <UserList users={teamMembers} edittable={canMoveUsers}/>
        </div>

        {isTeamEditable ?
          <div className="management__loneControl">
            <IconButton className="editTeam__deleteButton" color="darkGrey" icon={IconTypes.Bin} text="button.delete-team"
                        onClick={() => openDeleteTeamDialog()}/>
          </div> : null}

      </div>
    </React.Fragment>
  );
}

const SelectOfficesWrapper = styled.div`
  margin-bottom: 24px;
`
