import React, { useEffect } from 'react';
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import styled from "styled-components/macro";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { setShowCarParkMap } from "../../../../store/ducks/deskReservation.duck";
import { useDispatch } from "react-redux";
import { CarParkingMapView } from "../../../pages/company-movements/components/car-parking-map-view/CarParkingMapView";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { CarParkSpace } from "../../../../services/CarParkingPlanService";
import { loadCarParkingPlansForActiveOffice } from "../../../../store/ducks/officeView.duck";

export function CarParkMapWithDataProviders(props: Props) {
  const dispatch = useDispatch();
  const selectedOfficeId = props.selectedOffice?.id;

  const close = (e?: InputEvent) => {
    if (e) {
      e.stopPropagation();
    }
    if (props.onCloseClicked) {
      props.onCloseClicked();
    }
    dispatch(setShowCarParkMap(false));
  }

  useEffect(() => {
    if (selectedOfficeId) {
      dispatch(loadCarParkingPlansForActiveOffice({rootOfficeId: selectedOfficeId, defaultToAnyPlan: true}));
    }
  }, [dispatch, selectedOfficeId]);

  return (
    <FullPageWrapper onClick={(e: any) => close(e)}>
      <Container>
        <CarParkingMapView onCarParkingSpaceSelected={props.onCarParkingSpaceSelected} />
        <OutlineButton text={"button.close"} className={"floorMap__close"} click={close} />
      </Container>
    </FullPageWrapper>
  )
}

interface Props {
  selectedOffice?: OfficeEntity;
  onCarParkingSpaceSelected?: (carParkSpace: CarParkSpace) => any;
  onCloseClicked?: () => any;
}

const FullPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  z-index: 99999;
`

const Container = styled.div`
  position: relative;
  background-color: white;
  border-radius: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;
  .floorMap__close {
    position: absolute;
    bottom: 20px;
    right: 24px;
    width: 100%;
  }
  @media (${tablet}) {
    border-radius: 12px;
    width: 80vw;
    height: 80vh;
    margin: 10vh 10vw;
  }
`
