import React, { useMemo } from 'react';
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { useSelector } from "react-redux";
import { selectRootOfficeCapacity } from "../../../../../store/ducks/officeView.duck";
import { useTranslation } from "react-i18next";
import { selectActivePeriod } from "../../../../../store/ducks/editMovements.duck";
import { Period } from "../../../../../models/movements.models";

export function CarParkingAvailability(props: Props) {
  const rootOfficeCapacity = useSelector(selectRootOfficeCapacity);
  const period = useSelector(selectActivePeriod);
  const {t} = useTranslation();

  const spacesAvailable = useMemo(() => {
    switch (period) {
      case Period.AM: return (rootOfficeCapacity?.carParkingCapacity ?? 0) - (rootOfficeCapacity?.carParkingUsedAm ?? 0)
      case Period.PM: return (rootOfficeCapacity?.carParkingCapacity ?? 0) - (rootOfficeCapacity?.carParkingUsedPm ?? 0)
      case Period.AllDay: return (rootOfficeCapacity?.carParkingCapacity ?? 0) - Math.max((rootOfficeCapacity?.carParkingUsedAm ?? 0), (rootOfficeCapacity?.carParkingUsedPm ?? 0))
    }
  }, [period, rootOfficeCapacity]);

  if (!rootOfficeCapacity?.carParkingCapacity) {
    return null;
  }

  return (<>
    <BodyRegular>{t('whereabouts.parking-available')}: {spacesAvailable}</BodyRegular>
  </>)
}

interface Props {
}
