import { createAsyncThunk } from '@reduxjs/toolkit';
import { createHttpRequestSlice, HttpRequestState } from './baseDucks/HttpRequestDuck';
import { AppState } from '../state/app.state';
import { updateCompany } from '../../services/CompanyService';
import { updateCurrentUser } from './auth.duck';
import { loadCompanyStructure } from "./companyStructure.duck";

export interface SetupCompanyState extends HttpRequestState {
}

export const initialSetupCompanyState: SetupCompanyState = {
    isOpen: false,
    processing: false,
    success: false,
    error: '',
};

export const createCompany = createAsyncThunk(
    'setupCompany/createCompany',
    async (companyName: string, thunkAPI) => {
        await thunkAPI.dispatch(setupCompanyActions.setProcessing());

        try {
            const appState = (thunkAPI.getState() as AppState);
            await updateCompany({
                ...appState.auth.currentUser?.companyEntity,
                name: companyName,
            });
            await thunkAPI.dispatch(updateCurrentUser(true));
            await thunkAPI.dispatch(loadCompanyStructure());
            await thunkAPI.dispatch(setupCompanyActions.setSuccess());
        } catch (err: any) {
            console.error(err)
            thunkAPI.dispatch(setupCompanyActions.setError({
                message: err?.message || 'Unknown error',
                details: '',
            }));
        }
    }
);

const setupCompanySlice = createHttpRequestSlice({
    name: 'setupCompany',
    initialState: initialSetupCompanyState,
    reducers: {},
    extraReducers: {},
});



export default setupCompanySlice.reducer;
export const setupCompanyActions = setupCompanySlice.actions


// Selectors
export const selectIsSetupCompanyOpen = (state: AppState) => state.setupCompany.isOpen;
export const selectIsSetupCompanyProcessing = (state: AppState) => state.setupCompany.processing;
export const selectIsSetupCompanySuccess = (state: AppState) => state.setupCompany.success;
