import React from 'react';
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";

export function Intro(props: Props) {
  const {t} = useTranslation();
  return (
    <>
      <BodyRegular>{t('new-user-onboarding-dialog.intro')}</BodyRegular>
      <PrimaryButton text={"new-user-onboarding-dialog.next"}
                     fullWidth={true}
                     style={{marginTop: 'auto'}}
                     click={() => props.next()} />
    </>
  )
}

interface Props {
  next: () => any;
}

