import React from 'react';
import { BigMobileButton, ButtonProps } from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { canUserAddAnotherTeam } from "../../../../utils/AccessControl";
import { setIsPermissionNeededDialogOpen } from "../../../dialogs/permission-needed-dialog/permissionNeeded.duck";
import { Permission } from "../../../../models/user.models";
import { PermissionType } from "../../../../models/permission-type.models";
import { DialogIdentifiers, openDialog } from "../../../../store/ducks/dialog.duck";

export function AddAnotherTeamButton(props: ButtonProps) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const open = () => {
    if (canUserAddAnotherTeam(currentUser)) {
      dispatch(openDialog(DialogIdentifiers.CreateTeamDialog));
    } else {
      dispatch(setIsPermissionNeededDialogOpen({
        isOpen: true,
        permissionRequired: Permission.CompanyAdmin,
        permissionTypeRequired: PermissionType.AddTeam,
      }));
    }
  }

  return (
    <BigMobileButton size={props.size ?? 'small'}
                     type={props.type ?? 'primary'}
                     fullWidth={!!props.fullWidth}
                     className={`wallchart__addTeams ${props.className || ''}`.trim()}
                     text="button.add-another-team"
                     dataTest="addAnotherTeam-button"
                     onClick={() => open()}/>
  )
}
