import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  MapView,
  selectedOffice,
  selectOffice,
  selectSelectedArea,
  selectSelectedMapView,
  setSelectedCarPark,
  setSelectedMapView
} from "../../../../store/ducks/officeView.duck";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import { Column } from "../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { Accordian } from "../../../../animations/AnimationVariants";
import { Moment } from "moment";
import { AreaButton } from "./AreaButton";
import { sortOffice } from "../../../../utils/OfficeHelper";
import { WithActiveOfficeChildren } from "../../../../hooks/WithOfficeChildren";
import { WithOfficeAvailability } from "../../../../hooks/WithOfficeAvailability";
import { setSelectedParkingPlanId } from "../ducks/carParkingMapView.duck";

interface Props {
  office: OfficeEntity;
  date: Moment;
  onSelect?: () => any;
}

export function FloorAndAreaButton(props: Props) {
  const {office, date} = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const currentSelectedOffice = useSelector(selectedOffice);
  const selectedArea = useSelector(selectSelectedArea);
  const selectedMapView = useSelector(selectSelectedMapView);
  const activeChildren = WithActiveOfficeChildren(office.id);

  const officeAvailability = WithOfficeAvailability(office, date);

  const orderedOfficeChildren = useMemo(() => {
    return [...activeChildren].sort(sortOffice);
  }, [activeChildren]);

  const onOfficeClick = () => {
    if (activeChildren && activeChildren.length > 0) {
      setIsOpen(!isOpen);
    } else if (props.onSelect) {
      props.onSelect();
    }
    dispatch(selectOffice(office));

    dispatch(setSelectedMapView(MapView.Office));
    dispatch(setSelectedCarPark(undefined));
    dispatch(setSelectedParkingPlanId(0));
  }

  useEffect(() => {
    // If child office is open
    if (office.id === currentSelectedOffice?.parentId) {
      setIsOpen(true);
    }

    // If this office is open
    if (office.id === currentSelectedOffice?.id) {
      setIsOpen(true);
    }

    officeAvailability.reloadAvailability();

  }, [currentSelectedOffice, office, officeAvailability])

  return (
    <Wrapper>
      <OfficeButton onClick={onOfficeClick} isActive={selectedMapView === MapView.Office && currentSelectedOffice?.id === office.id} data-test={`office-button-${office.label}`}>
        <BodyRegular weight={600}>{office.label}</BodyRegular>
        <BodyRegular data-test={`office-button-${office.label}-capacity`}>{officeAvailability.spacesAvailable()} desks available</BodyRegular>
      </OfficeButton>
      {orderedOfficeChildren && orderedOfficeChildren.length > 0 && <Accordian isOpen={office.id === currentSelectedOffice?.id}>
        <FloorsList>
          {orderedOfficeChildren.filter(o => o.area).map((areaEntity: OfficeEntity, key: number) => (
            <AreaButton key={key} area={areaEntity}
                        onSelect={props.onSelect}
                        date={date} data-test={`floor-button-${areaEntity.label}`}
                        isActive={selectedMapView === MapView.Office && selectedArea?.id === areaEntity.id}/>
          ))}
        </FloorsList>
      </Accordian>}

    </Wrapper>
  )
}

const Wrapper = styled<any>(Column)`
  //padding-right: 32px;
  margin-bottom: 16px;
`

const FloorsList = styled<any>(Column)`
  border-left: solid 2px ${Colours.blue};
  padding-left: 30px;
  margin-top: 16px;
`

const OfficeButton = styled.div<any>`
  width: 100%;
  border: ${props => props.isActive ? `2px solid ${Colours.blue}` : `1px solid ${Colours.mildGrey}`};
  padding: ${props => props.isActive ? `15px 23px` : `16px 24px`};
  border-radius: 8px;
  cursor: pointer;
`
