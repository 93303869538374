import { useEffect, useRef } from "react";
import { isInTeams } from "../utils/TeamsUtils";
import { app } from "@microsoft/teams-js";
import { setTenantInfo } from "../store/ducks/auth.duck";
import { useDispatch } from "react-redux";
import { checkTenant } from "../services/AccessService";
import { useMsal } from "@azure/msal-react";

export function WithTenantChecker() {
  const dispatch = useDispatch();
  const {instance} = useMsal();
  const loading = useRef(false);

  useEffect(() => {
    if (loading.current || !!localStorage.getItem('idToken')) return;

    if (isInTeams()) {
      app.initialize().then(() => app.getContext()).then((ctx: app.Context) => {
        if (ctx.user && ctx.user.tenant?.id) {
          loading.current = true;
          checkTenant(ctx.user.tenant.id)
            .then(resp => dispatch(setTenantInfo(resp || undefined)))
            .catch(() => {})
            .finally(() => loading.current = false);
        }
      })
    } else {
      loading.current = true;
      checkTenant(instance.getActiveAccount()?.tenantId)
        .then(resp => dispatch(setTenantInfo(resp || undefined)))
        .catch(() => {})
        .finally(() => loading.current = false);
    }
  }, [dispatch, instance]);
}
