import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Moment } from "moment";
import { KeyResponsibilitiesConfig } from './components/KeyResponsibilitiesConfig';
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { GoBackButton } from '../../../UI/atoms/buttons/GoBackButton';
import { HeadlineLarge } from "../../../UI/atoms/fonts/Headline";
import { SpaceBetweenRow } from "../../settings/StyleComponents";
import { OfficeDropDownList } from "../../../UI/organisms/OfficeDropDownList";
import DateFlicker, { DateScale } from "../../../UI/molecules/DateFlicker";
import { Center, Row } from "../../../UI/atoms/StructuralLayout";
import { MountedContentCard } from "../../../UI/atoms/MountedContentCard";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { ToggleableOutlineButton } from "../../../UI/atoms/buttons/ToggleableOutlineButton";
import { KeyResponsibilitiesBarChart } from "./components/KeyResponsibilitiesBarChart";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyResponsibilities,
  loadConfig, loadReportData, selectDate,
  selectIsLoading,
  selectReportData,
  selectSelectedKeyResponsibility,
  selectSelectedOffice,
  setDate,
  setSelectedOffice,
  setSelectedResponsibility
} from './key-responsibilities.duck';
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";

export function KeyResponsibilitiesPage(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const selectedKeyResponsibility = useSelector(selectSelectedKeyResponsibility);
  const office = useSelector(selectSelectedOffice);
  const date = useSelector(selectDate);
  const loading = useSelector(selectIsLoading);
  const reportData = useSelector(selectReportData);

  useEffect(() => {
    if (office) {
      dispatch(loadConfig(office));
    }
  }, [dispatch, office]);

  useEffect(() => {
    dispatch(loadReportData({office, date, selectedKeyResponsibility}))
  }, [dispatch, office, date, selectedKeyResponsibility]);


  const onDateChange = (date: Moment) => {
    dispatch(setDate(date));
  }

  const onOfficeSelect = (office: OfficeEntity) => {
    dispatch(setSelectedOffice(office));
  }

  return (
    <ReportingContainer>
      <GoBackButton url={"/manage/reporting"} />
      <HeadlineLarge style={{marginBottom: 16}}>{t('reports-page.key-responsib.title')}</HeadlineLarge>
      <SpaceBetweenRow>
        <DateFlicker dateScale={DateScale.Month} updateGlobalDate={false} onChange={onDateChange} disabled={loading} />
        <DropDownListWrapper>
          <OfficeDropDownList onSelect={onOfficeSelect} disabled={loading} />
        </DropDownListWrapper>
      </SpaceBetweenRow>
      {loading ? <>
        <Center style={{marginTop: 48}}><LoadingSpinner message={'reports-page.key-responsib.loading'} hideBorder={true} /></Center>
      </> : <>
        {reportData.length > 0 ? <KeyResponsibilitiesBarChart /> : <>
          <Center style={{margin: '48px auto'}}>
            <MountedContentCard>
              <BodyRegular weight={600}>{t('reports-page.key-responsib.nothing-selected')}</BodyRegular>
            </MountedContentCard>
          </Center>
        </>}

        <Row>
          <ToggleableOutlineButton text={"reports-page.key-responsib.fire-warden"}
                                   isActive={selectedKeyResponsibility === KeyResponsibilities.fireWarden}
                                   disabled={loading}
                                   click={() => dispatch(setSelectedResponsibility(KeyResponsibilities.fireWarden))} />

          <ToggleableOutlineButton text={"reports-page.key-responsib.first-aider"}
                                   isActive={selectedKeyResponsibility === KeyResponsibilities.firstAider}
                                   disabled={loading}
                                   click={() => dispatch(setSelectedResponsibility(KeyResponsibilities.firstAider))} />
        </Row>

        <KeyResponsibilitiesConfig />
      </>}

    </ReportingContainer>
  )
}

interface Props {
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
  .recharts-wrapper .recharts-cartesian-grid-horizontal line {
    stroke-opacity: 0;
  }
  .recharts-cartesian-axis-tick-line {
    display: none !important;
  }
`

const DropDownListWrapper = styled.div`
  width: 300px;
`
