import styled, { css } from 'styled-components/macro';
import { desktop, desktopWide, smallTablet } from "../../../atoms/MediaQueries";
import Colours from "../../../atoms/Colours";


export const MovementsRow = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px ${Colours.mildGrey} solid;
  margin: 0 0 16px 0;
  width: 100%;
  transition: background-color .1s;
  -webkit-tap-highlight-color: transparent;

  @media (${smallTablet}) {
    margin: 0 0 0 0;
    flex-direction: row;
    padding: 16px;
  }
`

export const MovementsHeaderRow = styled<any>(MovementsRow)`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 16px 24px 0;
  @media (${smallTablet}) {
    padding: 0 24px 24px 16px;
    margin: 0;
    flex-direction: row;
  }
  &:hover {
    background-color: unset;
  }
`

export const UsersMovementsRow = styled<any>(MovementsRow)<{isEditting?: boolean}>`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 16px 0;
  &:hover {
    background-color: unset;
  }
  @media (${smallTablet}) {
    flex-direction: column;
    margin: 0 0 0 0;
  }
`

export const UsersMovements = styled.div`
  display: flex;
  flex-direction: column;
  @media (${smallTablet}) {
    flex-direction: row;
  }
  .movement__icon {
    cursor: pointer;
  }
`

export const MovementsName = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  @media (${smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
    width: 32%;
  }
  @media (${desktopWide}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const MovementsDailies = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  margin: 16px 0;
  @media (${smallTablet}) {
    width: 68%;
    margin: 0;
  }
`


export const MovementsDayContainer = styled.div<MovementsDayContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  width: 17vw;
  max-width: 100px;
  min-width: 60px;
  .movement__icon {
    max-height: 81px;
    min-height: 50px;
    height: 13.44vw;
  }
  ${props => props.active && css`
    .movement__icon {
      border: 2px solid ${Colours.blue}; 
    }
  `}
  &:last-child {
  margin-right: 0;
  }
  @media (${smallTablet}) {
    max-width: 15%;
    min-width: 66px;
    .movement__icon {
      height: 56px;
    }
  }
  @media (${desktop}) {
    max-width: 90px;
  }
`

interface MovementsDayContainerProps {
  active?: boolean;
}
