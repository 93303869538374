import React, { useEffect, useState } from 'react';
import { HeadlineVerySmall } from '../../atoms/fonts/Headline';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { agent } from "../../../../services/agent";
import { useTranslation } from "react-i18next";


export function WhereaboutsUsage(props: Props) {
  const {t} = useTranslation();

  const [dataSets, setDataSets] = useState<any>([
    {name: 'M', day: 'Monday', avg: 0.0},
    {name: 'T', day: 'Tuesday', avg: 0.0},
    {name: 'W', day: 'Wednesday', avg: 0.0},
    {name: 'T', day: 'Thursday', avg: 0.0},
    {name: 'F', day: 'Friday', avg: 0.0},
  ]);

  useEffect(() => {
    agent.requests.get(`/api/report/office/usage/average?office=216`)
      .then((resp: any) => {
        setDataSets([
          {name: 'M', day: 'Monday', avg: resp.monday},
          {name: 'T', day: 'Tuesday', avg: resp.tuesday},
          {name: 'W', day: 'Wednesday', avg: resp.wednesday},
          {name: 'T', day: 'Thursday', avg: resp.thursday},
          {name: 'F', day: 'Friday', avg: resp.friday},
        ])
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <div style={{width: 350, height: 150}}>
        <HeadlineVerySmall style={{marginBottom: 24}}>{t('reports-page.typical-office-usage')}</HeadlineVerySmall>
        <ResponsiveContainer>
          <BarChart
            width={300}
            height={100}
            data={dataSets}
            barSize={50}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" fontFamily={'"soleil", sans-serif'} />
            <YAxis fontFamily={'"soleil", sans-serif'} domain={[0, 1]} hide={true} />
            <Tooltip labelStyle={{fontFamily: '"soleil", sans-serif'}} contentStyle={{fontFamily: '"soleil", sans-serif'}} />
            <Bar dataKey="avg" fill="colour" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

interface Props {
}
