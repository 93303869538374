import { Moment } from "moment";
import { agent } from "./agent";


export async function getOfficeAvailability(officeId: number, date: Moment): Promise<DailyOfficeAvailability> {
  return await agent.requests.get(`/api/office/availability/${officeId}/date/${date.format('YYYY-MM-DD')}`);
}

interface DailyOfficeAvailability {
  amTotalSpacesAvailable: number;
  pmTotalSpacesAvailable: number;
}
