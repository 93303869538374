import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBookings,
  selectIsMeetingRoomBookingsLoading,
  selectUsersGroupedBookings
} from "../models/meeting-room.duck";
import { MeetingRoomBookingList } from "./MeetingRoomBookingList";
import { isLoading, loadMeetingRooms } from "../../settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { Center } from "../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import { PageTitle } from "../../../UI/atoms/PageTitle";

export function MeetingRoomBookings(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const meetingRoomBookings = useSelector(selectUsersGroupedBookings);
  const isBookingsLoading = useSelector(selectIsMeetingRoomBookingsLoading);
  const isMeetingRoomsLoading = useSelector(isLoading);

  const isDataLoading = isMeetingRoomsLoading && isBookingsLoading;

  useEffect(() => {
    dispatch(loadBookings());
    dispatch(loadMeetingRooms());
  }, [dispatch]);

  return (
    <Container>
      <PageTitle text={"room-finder.navigation.bookings"} />

      {isDataLoading ? <>
        <Center>
          <LoadingSpinner hideText={true} fullScreen={false} hideBorder={true} />
        </Center>
      </> : <>
        {Object.keys(meetingRoomBookings).length > 0 ? <>
          <MeetingRoomBookingList groupedBookings={meetingRoomBookings} />
        </> : <>
          <Center>
            <BodyLarge>{t('room-finder.no-meeting-bookings')}</BodyLarge>
          </Center>
        </>}
      </>}

    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  margin: 16px auto 0 auto;
  max-width: 800px;
  .roomFinder__roomFinderBtn {
    margin: 0;
    max-width: 120px;
  }
`
