import React, { useMemo } from 'react';
import { WhereaboutsBookingError, WhereaboutsBookingErrorType } from "../../../services/DeskBookingService";
import Dialog from "../../UI/molecules/Dialog";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { WhereaboutsErrorRow } from "./WhereaboutsErrorRow";
import Colours from "../../UI/atoms/Colours";
import { BodyLarge } from "../../UI/atoms/fonts/Body";

enum ErrorViewType {
  ListSummary, BookingOverApprovedHoliday
}

export function NoneBlockingWhereaboutsError(props: Props) {
  const {errors, response} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const close = () => {
    dispatch(closeDialog());
  };

  const errorViewType = useMemo(() => {
    const bookedOverApprovedHoliday = errors.filter((e: WhereaboutsBookingError) => e.type === WhereaboutsBookingErrorType.CANNOT_BOOK_OVER_APPROVED_HOLIDAY)

    if (bookedOverApprovedHoliday.length === errors.length) {
      return ErrorViewType.BookingOverApprovedHoliday;
    }

    return ErrorViewType.ListSummary;
  }, [errors])

  return (
    <Wrapper>
      <Dialog isOpen={true} onClose={close}>
        {errorViewType === ErrorViewType.ListSummary && <ImageWrapper>
          <CarImage src={"/assets/icons/font/Alert.svg"} alt={""} />
        </ImageWrapper>}
        {errorViewType === ErrorViewType.BookingOverApprovedHoliday && <HolidayImage src={"/assets/icons/font/Holiday.svg"} alt={""} />}
        <HeadlineMedium style={{marginBottom: 16, marginTop: 16}}>{t('dialog.general-whereabouts-errors-title')}</HeadlineMedium>

        {errorViewType === ErrorViewType.ListSummary && <ErrorList errors={errors} response={response} />}
        {errorViewType === ErrorViewType.BookingOverApprovedHoliday && <BookedOverApprovedHolidayError errors={errors} response={response} />}

        <PrimaryButton click={close} fullWidth={true} size={'large'} text={"button.done"} />
      </Dialog>
    </Wrapper>
  )
}

function ErrorList(props: Props) {
  const {response, errors} = props;
  return (<ErrorListContainer>
    {response.savedWhereaboutsDays.map((day: any, key: number) =>
      <WhereaboutsErrorRow key={key} errors={errors} day={day} />)
    }
  </ErrorListContainer>)
}

function BookedOverApprovedHolidayError(props: Props) {
  const {t} = useTranslation();
  return (<BookedOverApprovedHolidayErrorWrapper>
    <HeadlineMedium style={{marginBottom: 16}}>{t('approved-holiday-whereabouts-errors.title')}</HeadlineMedium>
    <BodyLarge style={{marginBottom: 8}}><Trans i18nKey="approved-holiday-whereabouts-errors.line1" /></BodyLarge>
    <ol type="1">
      <li><BodyLarge><Trans i18nKey="approved-holiday-whereabouts-errors.line2" /></BodyLarge></li>
      <li><BodyLarge><Trans i18nKey="approved-holiday-whereabouts-errors.line3" /></BodyLarge></li>
      <li><BodyLarge><Trans i18nKey="approved-holiday-whereabouts-errors.line4" /></BodyLarge></li>
      <li><BodyLarge style={{marginBottom: 24}}><Trans i18nKey="approved-holiday-whereabouts-errors.line5" /></BodyLarge></li>
    </ol>
    <BodyLarge style={{marginBottom: 52}}><Trans i18nKey="approved-holiday-whereabouts-errors.line6" /></BodyLarge>
  </BookedOverApprovedHolidayErrorWrapper>)
}

interface Props {
  errors: WhereaboutsBookingError[];
  response: any;
}

const Wrapper = styled.div`
  .dialog {
    padding: 64px 32px 52px 32px;
    width: 500px;
  }
  .dialog__content {
    width: 100%;
    margin: unset;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .dialog__contentContainer {
    padding: 8px;
  }
`

const ErrorListContainer = styled.div`
  margin-bottom: 32px;
`

const CarImage = styled.img`
  width: 48px;
  height: 25px;
  position: absolute;
  top: 26px;
  left: 16px;
`

const HolidayImage = styled.img`
  margin: 0 auto;
  width: 48px;
  margin-bottom: -12px;
`

const ImageWrapper = styled.div`
  margin: 0 auto;
  background-color: ${Colours.veryLightGrey};
  border-radius: 100%;
  width: 80px;
  height: 80px;
  position: relative;
`


const BookedOverApprovedHolidayErrorWrapper = styled.div`
  ol {
    list-style-position: outside;
    list-style-type: decimal;
    padding-left: 30px;
  }
  ol > li::marker {
    font-size: 22px;
    line-height: 34px;
    color: ${Colours.black};
    font-weight: 400;
    font-family: 'proxima-nova';
  }
`
