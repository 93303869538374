import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  endEditingWhereabouts,
  selectActiveDay,
} from '../../../../../../store/ducks/editMovements.duck';
import IconButton from "../../../../molecules/icon-button/IconButton";
import './edit-movements-wrapper.scss';
import { IconTypes } from "../../../../atoms/icon/Icon";

const HALF_ARROW_WIDTH = 20;

export default function EditMovementsWrapper(props: { children?: any }) {
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);
  const [arrowPosition, setArrowPosition] = useState(0);

  useEffect(() => {
    // TODO Dont use a timeout
    setTimeout(() => {
      const rect = document.getElementsByClassName(`wallchartRow__day--${activeDay?.format('YYYYMMDD')}`).item(0)?.getBoundingClientRect();
      const rectParent = document.getElementsByClassName('editMovementsPanel').item(0)?.getBoundingClientRect() || {x: 0};
      if (rect) {
        const pos = rect.x - rectParent.x + rect.width * .5 - HALF_ARROW_WIDTH;
        setArrowPosition(pos);
      }

    }, 250)
  }, [activeDay]);

  return (
    <div className="editMovements editMovements--desktop" style={{marginTop: -12}}>
      <div className="editMovementsPanel">
        <div className="editMovements__pointer" style={{
          display: !!arrowPosition ? 'block' : 'none',
          transform: `translateX(${arrowPosition}px) translateY(-17px)`
        }}>
          <div className="editMovementsPanel__arrow"/>
        </div>

        {props.children || null}

        <IconButton className="editMovements__close" icon={IconTypes.Close} onClick={() => dispatch(endEditingWhereabouts())}/>
      </div>
    </div>
  )
}
