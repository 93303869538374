import React from 'react';
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { FlatContentCard } from "../../../../../UI/atoms/FlatContentCard";
import { useTranslation } from "react-i18next";

export function NoSetup(props: Props) {
  const {t} = useTranslation();
  return (
    <FlatContentCard style={{marginBottom: 32}}>
      <BodyRegular>{t('settings.manage-office.nosetup')}</BodyRegular>
    </FlatContentCard>
  )
}

interface Props {
}
