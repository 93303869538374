import React from 'react';
import styled from "styled-components/macro";
import { FreeBusyStatus } from "../../../services/WhereaboutOptions";
import { ToggleableOutlineButton } from "../../UI/atoms/buttons/ToggleableOutlineButton";
import { tablet } from "../../UI/atoms/MediaQueries";
import { BodyRegular, BodySmall } from "../../UI/atoms/fonts/Body";
import { Trans, useTranslation } from "react-i18next";

export function WhereaboutsAvailabilityToggle(props: Props) {
  const {onSelect, selected} = props;
  const {t} = useTranslation();

  const onClick = (view: FreeBusyStatus) => {
    onSelect(view);
  }

  return (
    <>
      <BodyRegular weight={600} style={{marginBottom: 12}}>{t('whereaboutsAvailability.show-avail-calendar-like')}</BodyRegular>
      <ButtonRow>
        <ToggleableOutlineButton isActive={selected === FreeBusyStatus.FREE}
                                 click={() => onClick(FreeBusyStatus.FREE)}
                                 text="button.full" />
        <ToggleableOutlineButton isActive={selected === FreeBusyStatus.TENTATIVE}
                                 click={() => onClick(FreeBusyStatus.TENTATIVE)}
                                 text="button.partial" />
        <ToggleableOutlineButton isActive={selected === FreeBusyStatus.BUSY}
                                 click={() => onClick(FreeBusyStatus.BUSY)}
                                 text="button.not-available" />
      </ButtonRow>
      <ButtonRow>
        <ToggleableOutlineButton isActive={selected === FreeBusyStatus.WORKING_ELSEWHERE}
                                 click={() => onClick(FreeBusyStatus.WORKING_ELSEWHERE)}
                                 text="button.working-elsewhere" />
        <ToggleableOutlineButton isActive={selected === FreeBusyStatus.OOF}
                                 click={() => onClick(FreeBusyStatus.OOF)}
                                 text="button.oof" />
      </ButtonRow>
      {selected === FreeBusyStatus.FREE && <BodySmall><Trans i18nKey="whereaboutsAvailability.show-as-free" /></BodySmall>}
      {selected === FreeBusyStatus.TENTATIVE && <BodySmall><Trans i18nKey="whereaboutsAvailability.show-as-tentative" /></BodySmall>}
      {selected === FreeBusyStatus.BUSY && <BodySmall><Trans i18nKey="whereaboutsAvailability.show-as-busy" /></BodySmall>}
      {selected === FreeBusyStatus.OOF && <BodySmall><Trans i18nKey="whereaboutsAvailability.show-as-oof" /></BodySmall>}
      {selected === FreeBusyStatus.WORKING_ELSEWHERE && <BodySmall><Trans i18nKey="whereaboutsAvailability.show-as-working-elsewhere" /></BodySmall>}

    </>
  )
}

interface Props {
  onSelect: (status: FreeBusyStatus) => any;
  selected: FreeBusyStatus;
}

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  width: 100%;
  & > * {
    flex: 1;
  }
  button {
    flex: 1;
    @media (${tablet}) {
      min-width: unset;
    }
  }
`

