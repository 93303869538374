import React, { useState } from 'react';
import { Switch } from "../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { selectIsLoading, setSync } from "../../../store/ducks/outlookSync.duck";
import { useMsal } from "@azure/msal-react";
import { stepUpAzureCalendarPermissions } from "../../../utils/AuthUtils";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { SimpleSpinner } from "../atoms/SimpleSpinner";

export default function CalendarSwitch() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectIsLoading);
  const [isAzureLoading, setIsAzureLoading] = useState(false);
  const {instance} = useMsal();

  const onChange = async (val: boolean) => {
    if (val) {
      setIsAzureLoading(true);
      try {
        const account = instance.getAllAccounts()[0];
        await stepUpAzureCalendarPermissions(instance, account, dispatch);
        dispatch(setSync(true));
      } catch (e: any) {
        dispatch(failureNotification('Failed to enable calendar sync'))
      } finally {
        setIsAzureLoading(false);
      }
    } else {
      dispatch(setSync(false));
    }
  }

  return (
    <React.Fragment>
      {(isLoading || isAzureLoading) ? <SimpleSpinner size={16} /> : <Switch value={!!current?.enableOutlookSync} label="" onChange={onChange}/>}
    </React.Fragment>
  );
}
