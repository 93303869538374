import React from 'react';
import { SubHeaderContainer, SubHeaderContent } from "./ViewComponents";
import { GoBackButton } from "../../../../atoms/buttons/GoBackButton";
import Colours from "../../../../atoms/Colours";

export function BackButtonOnlySubHeader(props: Props) {

  return (
    <SubHeaderContainer fullScreenView={props.fullScreenView}>
      <SubHeaderContent>
        <GoBackButton iconColor={Colours.white}
                      iconHoverColor={Colours.white}
                      iconHoverBackgroundColor={'transparent'}
                      backgroundHoverColour={Colours.darkBlue} />
      </SubHeaderContent>
    </SubHeaderContainer>
  )
}

interface Props {
  fullScreenView?: boolean;
}
