import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { getAllMeetingRooms } from "../../../../../services/MeetingRoomsService";
import { MeetingRoom } from "../../../meeting-rooms/models/MeetingRooms";
import { AppState } from "../../../../../store/state/app.state";


export interface MeetingRoomSettingsState {
  meetingRooms: MeetingRoom[];
  loading: boolean;
}

export const initialMeetingRoomSettingsState: MeetingRoomSettingsState = {
  meetingRooms: [],
  loading: false,
}

export const loadMeetingRooms: any = createAsyncThunk(
  'meetingRoomSettings/loadMeetingRooms',
  async (params: {}, thunkAPI) => {
    return {
      meetingRooms: await getAllMeetingRooms(),
    }
  }
)

const meetingRoomSettings = createSlice({
  name: 'meetingRoomSettings',
  initialState: initialMeetingRoomSettingsState,
  reducers: {
    reset: () => initialMeetingRoomSettingsState,
    setMeetingRooms: (state, action) => ({...state, meetingRooms: action.payload}),
    setMeetingRoomBand: (state, action) => ({...state, meetingRoomCapacityBand: action.payload}),
    setMeetingRoomFacilities: (state, action) => ({...state, meetingRoomFacilities: action.payload}),
    setOffice: (state, action) => ({...state, selectedOffice: action.payload}),
  },
  extraReducers: {
    [loadMeetingRooms.pending]: (state, action) => ({...state, loading: true,}),
    [loadMeetingRooms.reject]: (state, action) => ({...state, loading: false,}),
    [loadMeetingRooms.fulfilled]: (state, action) => ({
      ...state,
      meetingRooms: action.payload.meetingRooms,
      loading: false,
    }),
  }
})

export default meetingRoomSettings.reducer;
export const {
  reset,
  setMeetingRooms,
} = meetingRoomSettings.actions;

// Selectors
export const selectAllMeetingRooms = (state: AppState) => state.meetingRoomSettings.meetingRooms;
export const selectMeetingRoomsInOffice = (state: AppState, officeId: number) => state.meetingRoomSettings.meetingRooms.filter(m => m.officeId === officeId);
export const isLoading = (state: AppState) => state.meetingRoomSettings.loading;
