import React, { useMemo } from 'react';
import { BodyError, BodySmall } from '../../UI/atoms/fonts/Body';
import { LinkButton } from "../../UI/atoms/buttons/LinkButton";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  errorMessage?: string;
}

const errorMap = {
  error: {
    message: 'We couldn\'t find that invite. Ask your team for a new invite or If you have already accepted one, try logging in.',
    showLoginLink: true,
    showForgotPasswordLink: false,
  },
  inviteAccepted: {
    message: 'That invite has already been accepted, you should be able to log in or if you can\'t remember your password use Forgot Password button',
    showLoginLink: true,
    showForgotPasswordLink: true,
  },
}

export default function InviteErrorMessage(props: Props) {
  const {t} = useTranslation();
  const error = useMemo(() => {
    // @ts-ignore
    const lookedUpError = errorMap[props.errorMessage];
    return lookedUpError ? lookedUpError : errorMap.error
  }, [props.errorMessage]);

  return (
    <>
      <BodyError style={{marginBottom: 32}}>{t(error.message)}</BodyError>

      {error.showLoginLink && <div style={{marginBottom: 16}}>
        <LinkButton size="large" type="primary" link="/login" fullWidth={true} text="general.login" />
      </div>}
      {error.showForgotPasswordLink && <div style={{marginBottom: 16}}>
        <LinkButton size="large" type="outline" link="/forgot-password" fullWidth={true} text="forgot-password.forgot-password" />
      </div>}

      <BodySmall style={{marginTop: 32, textAlign: 'center'}}><Trans i18nKey={'invite.issues'} /></BodySmall>
    </>
  )
}
