import React from 'react';
import { useSelector } from "react-redux";
import { isLoading, selectAllMeetingRooms } from "../meeting-rooms-settings.duck";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { Trans, useTranslation } from "react-i18next";
import { FlatContentCard } from "../../../../../UI/atoms/FlatContentCard";

export function MeetingRoomLimitReachedMessage(props: Props) {
  const {t} = useTranslation();
  const allMeetingRooms = useSelector(selectAllMeetingRooms);
  const loading = useSelector(isLoading);
  const user = useSelector(selectCurrentUser);
  if (user && !loading && allMeetingRooms.length >= user?.companyEntity.meetingRoomLimit) {
    return (
      <Container>
        <BodyLarge>{t('settings.meeting-rooms.limit-title')}</BodyLarge>


        <BodyRegular><Trans i18nKey={'settings.meeting-rooms.limit-description'}
                            components={[<a href="mailto:support@team-today.com">support@team-today.com</a>]} /></BodyRegular>
      </Container>
    )
  }

  return null;
}

interface Props {
}

const Container = styled<any>(FlatContentCard)`
  
`
