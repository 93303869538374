import React, { useEffect, useMemo, useRef } from 'react';
import { selectedOffice, selectOffice } from "../../../../store/ducks/officeView.duck";
import { useDispatch, useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { ContainerViewDateWrapper } from "../../../UI/molecules/ContainerViewDateWrapper";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { getRootOfficeById } from "../../../../utils/OfficeHelper";

export function OfficeViewDateWrapper(props: Props) {
  const dispatch = useDispatch();
  const currentOffice = useSelector(selectedOffice);
  const currentOfficeRef = useRef<OfficeEntity | undefined>();
  const offices = useSelector(selectAllOfficeEntities);
  const activeDate = useSelector(selectActiveDay);

  useEffect(() => {
    currentOfficeRef.current = currentOffice;
  }, [currentOffice]);

  useEffect(() => {
    if (currentOfficeRef.current) {
      dispatch(selectOffice(currentOfficeRef.current));
    }
  }, [activeDate, dispatch]);

  const title = useMemo(() => {
    if (currentOffice) {
      const rootOffice = getRootOfficeById(offices, currentOffice?.id)
      if (rootOffice) return rootOffice.label;
    }
    return 'Select an office';
  }, [offices, currentOffice]);

  return (
    <ContainerViewDateWrapper title={title}>
      {props.children}
    </ContainerViewDateWrapper>
  )
}

interface Props {
  children: any;
}
