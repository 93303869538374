import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../state/app.state';
import moment, { Moment } from 'moment';
import { resetEditMovements, setActiveDay } from './editMovements.duck';
import { addOrReplaceQueryParam, getParameterByName } from "../../utils/UrlUtils";
import { DATE_FORMAT } from "../../utils/DateUtils";

export interface DashboardState {
  calendarDate: moment.Moment;
  isSearchBarVisible: boolean;
}

export const initialDashboardState: DashboardState = {
  calendarDate: getDefaultCalendarDate(),
  isSearchBarVisible: false,
};

function getDefaultCalendarDate(): Moment {
  const dateFromUrl = getParameterByName('date', window.location.href);
  if (dateFromUrl) {
    return moment(dateFromUrl)
  } else {
    let d = moment();
    // If after 17:00 on Friday or a weekend. Go to next Monday
    if ((d.isoWeekday() === 5 && parseInt(d.format('HH')) >= 17) || d.isoWeekday() === 6 || d.isoWeekday() === 7) {
      d = moment().startOf('isoWeek').add(7, 'days');
    }
    return d;
  }
}

export const setCalendarDate: any = createAsyncThunk(
  'dashboard/setCalendarDate',
  async (weekCommencing: Moment, thunkAPI) => {
    try {
      const appState = thunkAPI.getState() as AppState;
      if (!!appState.editMovements.focusedUserId) {
        await thunkAPI.dispatch(resetEditMovements());
      }
      addOrReplaceQueryParam('date', weekCommencing.format(DATE_FORMAT));
      thunkAPI.dispatch(setActiveDay(weekCommencing.clone()));
    } catch {
    }
    return weekCommencing;
  }
)

const dashboardSlice: any = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    setIsSearchBarVisible: (state, action) =>
      ({ ...state, isSearchBarVisible: action.payload }),
  },
  extraReducers: {
    [setCalendarDate.fulfilled]: (state, action) => {
      return {
        ...state,
        calendarDate: action.payload,
      };
    },
  },
});

export default dashboardSlice.reducer;
export const {
  setIsSearchBarVisible
} = dashboardSlice.actions;

// Selectors
export const selectCalendarDate = (state: AppState) => state.dashboard.calendarDate;
export const selectWeekCommencingCalendarDate = (state: AppState) => state.dashboard.calendarDate.clone().isoWeekday('monday');
export const selectIsSearchBarVisible = (state: AppState) => state.dashboard.isSearchBarVisible;
