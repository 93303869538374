import React from 'react';
import { CapacityResponse } from "../../../../../services/OfficeCapacityService";
import styled from "styled-components/macro";
import { BodyVerySmall } from "../../../atoms/fonts/Body";
import { Column, Row } from "../../../atoms/StructuralLayout";
import { SimpleSpinner } from "../../../atoms/SimpleSpinner";
import { useTranslation } from "react-i18next";
import { CarParkingCapacityLabel } from "./CarParkingCapacityLabel";

export function WhereaboutsCapacityLabel(props: Props) {
  const {capacity, isCarParkingEnabledForCompany} = props;
  const {t} = useTranslation();

  return (
    <Column>
      <Row style={{alignItems: 'center', minHeight: 24}}>
        {!!capacity ? <DeskAvailabilityLabel>{capacity.capacity - Math.min(capacity.amUsed, capacity.pmUsed)}</DeskAvailabilityLabel> : <SimpleSpinner size={20} /> }
        <DeskAvailabilityLabel style={{marginLeft: 4}}>{t('whereabouts.desks-available')}</DeskAvailabilityLabel>
      </Row>

      <CarParkingCapacityLabel isCarParkingEnabledForCompany={isCarParkingEnabledForCompany} capacity={capacity} />

    </Column>
  )
}

interface Props {
  capacity?: CapacityResponse;
  isCarParkingEnabledForCompany: boolean;
}


const DeskAvailabilityLabel = styled<any>(BodyVerySmall)`
  margin-top: -4px;
`
