import React from 'react';
import { Moment } from "moment";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { HeadlineMedium } from "../../../../../UI/atoms/fonts/Headline";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { CompanyHolidayOverview } from "../../../../../../services/CompanyHolidayService";
import { CompanyHolidayItem } from "./CompanyHolidayItem";
import { useTranslation } from "react-i18next";

export function CompanyHolidaysList(props: Props) {
  const {companyHolidays, disabled} = props;
  const {t} = useTranslation();

  return (
    <Container>
      {companyHolidays ? <>
        <BodyRegular weight={600} color={Colours.mildGrey}>{t('company-holiday.number-of-holidays')}</BodyRegular>
        <HeadlineMedium style={{marginBottom: 24}}>{companyHolidays.totalCompanyHolidays}</HeadlineMedium>
        {!disabled && <PrimaryButton text={"button.add-company-holiday"}
                                    link={'/manage/company-holidays/add'}
                                    style={{marginBottom: 32}}
                                    disabled={disabled}
                                    fullWidth={true} />}

        {companyHolidays.companyHolidays.map((holiday, key) => (
          <CompanyHolidayItem companyHoliday={holiday} key={key} disabled={disabled} />
        ))}

      </> : <></>}
    </Container>
  )
}

interface Props {
  activeDate: Moment;
  companyHolidays?: CompanyHolidayOverview;
  disabled: boolean;
}

const Container = styled.div`
  border: 1px solid ${Colours.mildGrey};
  border-radius: 24px;
  padding: 32px;
`
