import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyEntity, IconPack } from '../../models/company.models';
import { updateCompany } from '../../services/CompanyService';
import { createTeamRequest, sendInvite } from '../../services/TeamService';
import { AppState } from '../state/app.state';
import { setUser, updateCurrentUser } from './auth.duck';
import { TeamEntity } from "../../models/team.models";
import { Auth } from "../../services/Auth";
import { onboardingNextFlow, onboardingSkipFlow } from "../../components/pages/onboarding/components/OnboardingFlow";

export enum OnboardingScreen {
    AddMember = 3,
    SetupOffice = 4,
    OfficeAdded = 5,
    SetupHolidayAllowance = 6,
    SetupHolidayRules = 7,
    Complete = 8,
    CreateTeam = 9,
    Welcome= 10,
    Features = 13,
    CustomWhereabouts = 14,
    Icons = 15
}

export interface OnboardingState {
    processing: boolean;
    success: boolean;
    error: string;
    activeScreen: OnboardingScreen;
    companyName: string;
    addedTeam?: TeamEntity;
    deskBookingEnabled: boolean;
    holidayManagementEnabled: boolean;
}

export const initialOnboardingState: OnboardingState = {
    processing: false,
    success: false,
    error: '',
    activeScreen: OnboardingScreen.Welcome,
    companyName: '',
    deskBookingEnabled: true,
    holidayManagementEnabled: true,
};

export const updateDeskBooking: any = createAsyncThunk(
  'onboarding/updateDeskBooking',
  async (state: boolean, thunkAPI) => {
      return state;
  }
);

export const updateHolidayBooking: any = createAsyncThunk(
  'onboarding/updateHolidayBooking',
  async (state: boolean, thunkAPI) => {
      const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
      if (currentUser?.companyEntity) {
          await updateCompany({
              ...currentUser.companyEntity,
              enableHolidayBooking: state,
          });
          await thunkAPI.dispatch(updateCurrentUser(true));
          return state;
      } else {
          throw new Error('No company entity set to update');
      }
  }
);

export const updateLineManagementSetting: any = createAsyncThunk(
  'onboarding/updateLineReport',
  async (state: boolean, thunkAPI) => {
      const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
      if (currentUser?.companyEntity) {
          await updateCompany({
              ...currentUser.companyEntity,
              lineManagementEnabled: state,
          });
          await thunkAPI.dispatch(updateCurrentUser(true));
          return state;
      } else {
          throw new Error('No company entity set to update');
      }
  }
);

export const updateSevenDayWorkingWeekSetting: any = createAsyncThunk(
  'onboarding/updateSevenDayWorkingWeekSetting',
  async (state: boolean, thunkAPI) => {
      const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
      if (currentUser?.companyEntity) {
          await updateCompany({
              ...currentUser.companyEntity,
              sevenDayWhereaboutsWeekEnabled: state,
          });
          await thunkAPI.dispatch(updateCurrentUser(true));
          return state;
      } else {
          throw new Error('No company entity set to update');
      }
  }
);


export const updateHolidayApprovalNotificationSetting: any = createAsyncThunk(
  'onboarding/updateHolidayApprovalNotificationSetting',
  async (state: boolean, thunkAPI) => {
    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
    if (currentUser?.companyEntity) {
      await updateCompany({
        ...currentUser.companyEntity,
        holidayApprovalNotificationsEnabled: state,
      });
      await thunkAPI.dispatch(updateCurrentUser(true));
      return state;
    } else {
      throw new Error('No company entity set to update');
    }
  }
);

export const updateWeeklyNotes: any = createAsyncThunk(
  'onboarding/updateWeeklyNotes',
  async (state: boolean, thunkAPI) => {
    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
    if (currentUser?.companyEntity) {
      await updateCompany({
        ...currentUser.companyEntity,
        weeklyNotesEnabled: state,
      });
      await thunkAPI.dispatch(updateCurrentUser(true));
      return state;
    } else {
      throw new Error('No company entity set to update');
    }
  }
);

export const updateWhereaboutsIconPack: any = createAsyncThunk(
  'onboarding/updateWhereaboutsIconPack',
  async (iconPack: IconPack, thunkAPI) => {
    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
    if (currentUser?.companyEntity) {
      await updateCompany({
        ...currentUser.companyEntity,
        iconPack: iconPack,
      });
      await thunkAPI.dispatch(updateCurrentUser(true));
      return iconPack;
    } else {
      throw new Error('No company entity set to update');
    }
  }
);

export const next: any = createAsyncThunk('onboarding/next', async (state: boolean, thunkAPI) => {
    const activeScreen = (thunkAPI.getState() as AppState).onboarding.activeScreen;
    return onboardingNextFlow(activeScreen);
});

export const skip: any = createAsyncThunk('onboarding/skip', async (state: boolean, thunkAPI) => {
    const activeScreen = (thunkAPI.getState() as AppState).onboarding.activeScreen;
    return onboardingSkipFlow(activeScreen);
});

export const createCompany = createAsyncThunk(
    'onboarding/createCompany',
    async (company: CompanyEntity, thunkAPI) => {
        await thunkAPI.dispatch(setLoading(true));
        try {
            await updateCompany(company);
            const user = await Auth.current(false);
            thunkAPI.dispatch(setUser(user));
            thunkAPI.dispatch(setError(''));
            thunkAPI.dispatch(next());
            thunkAPI.dispatch(setCompanyName(company.name));
        } catch (err: any) {
            thunkAPI.dispatch(setError(err?.message || 'Unknown error'));
        }
    }
);

export const createTeam: any = createAsyncThunk(
    'onboarding/createTeam',
    async (request: any, thunkAPI) => {
        await thunkAPI.dispatch(setLoading(true));

        try {
            const createTeamResponse = await createTeamRequest(request.teamName, true);
            thunkAPI.dispatch(setError(''));
            thunkAPI.dispatch(next());
            return createTeamResponse;
        } catch (err: any) {
            thunkAPI.dispatch(setError(err?.message || 'Unknown error'));
        }
    }
);

export const createMember = createAsyncThunk(
	'onboarding/createMember',
	async (request: any, thunkAPI) => {
		const { firstname, lastname, email } = request;

		await thunkAPI.dispatch(setLoading(true));

		try {
			await sendInvite(email, firstname, lastname);
			thunkAPI.dispatch(setError(''));
			thunkAPI.dispatch(next());
		} catch (err: any) {
		    console.log(err)
			thunkAPI.dispatch(setError(err?.message || 'Unknown error'));
		}
	}
);

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: initialOnboardingState,
    reducers: {
        setCompanyName: (state, action) => ({...state, companyName: action.payload}),
        setAddedTeam: (state, action) => ({...state, addedTeam: action.payload}),
        setActiveOnboardingScreen: (state, action) => {
            console.log(action)
            return {
                ...state,
                activeScreen: action.payload,
                error: '',
            }
        },
        setLoading: (state, action) => ({
            ...state,
            processing: action.payload,
        }),
        setError: (state, action) => ({
            ...state,
            error: action.payload,
            processing: false,
        }),
    },
    extraReducers: {
        [updateDeskBooking.fulfilled]: (state, action) => ({ ...state, deskBookingEnabled: action.payload, }),
        [updateHolidayBooking.fulfilled]: (state, action) => ({ ...state, holidayManagementEnabled: action.payload, }),

        [next.fulfilled]: (state, action) => ({ ...state, activeScreen: action.payload, }),
        [skip.fulfilled]: (state, action) => ({ ...state, activeScreen: action.payload, }),

        // eslint-disable-next-line
        ['onboarding/createCompany/fulfilled']: (state) => {
            return {
                ...state,
                processing: false,
            };
        },

        [createTeam.fulfilled]: (state, action) => ({ ...state, processing: false, addedTeam: action.payload }),

        // eslint-disable-next-line
        ['onboarding/createMember/fulfilled']: (state) => {
            return {
                ...state,
                processing: false,
            };
        },
    },
});

export default onboardingSlice.reducer;
export const {
    setActiveOnboardingScreen,
    setLoading,
    setError,
    setCompanyName,
    setAddedTeam,
} = onboardingSlice.actions;

// Selectors
export const selectActiveScreen = (state: AppState) =>
    state.onboarding.activeScreen;
export const selectIsLoading = (state: AppState) => state.onboarding.processing;
export const selectError = (state: AppState) => state.onboarding.error;
export const selectCompanyName = (state: AppState) => state.onboarding.companyName;
export const selectOnboardedTeam = (state: AppState) => state.onboarding.addedTeam;
export const selectDeskBookingEnabled = (state: AppState) => state.onboarding.deskBookingEnabled;
export const selectHolidayManagementEnabled = (state: AppState) => state.onboarding.holidayManagementEnabled;
