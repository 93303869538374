import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { BodyLarge, BodySmall } from '../../../UI/atoms/fonts/Body';
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { hexToRGBA, whereaboutsToColour } from "../../../../utils/RandomColour";
import { useSelector } from "react-redux";
import { selectAllWhereaboutsOptions } from "../../../../store/ducks/whereaboutsOptions.duck";
import Colours from "../../../UI/atoms/Colours";
import { FixedWhereaboutsValues, FreeBusyStatus } from "../../../../services/WhereaboutOptions";
import { iconPackPath } from "../../../../utils/WhereaboutsHelper";
import { selectIconPack } from "../../../../store/ducks/auth.duck";

export function SingleWhereaboutsUsage(props: Props) {
  const {label, icon, whereaboutsKey, usedDays, totalDays, subtext} = props;
  const customWhereaboutsList = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack)
  const isCustomWhereaboutsInAccount = useMemo(() => {
    if (FixedWhereaboutsValues.includes(whereaboutsKey)) return true;
    return customWhereaboutsList.filter((cw) => cw.key === whereaboutsKey).length > 0
  }, [customWhereaboutsList, whereaboutsKey])
  const {t} = useTranslation();

  const bgColour = useMemo(() =>  hexToRGBA(whereaboutsToColour(customWhereaboutsList, whereaboutsKey), 0.1), [customWhereaboutsList, whereaboutsKey])
  const fgColour = useMemo(() =>  hexToRGBA(whereaboutsToColour(customWhereaboutsList, whereaboutsKey), 1), [customWhereaboutsList, whereaboutsKey])

  return (
    <Wrapper>
      <WhereaboutsIconWrapper>
        {!!icon ? <WhereaboutsIcon src={iconPackPath(iconPack, icon, FreeBusyStatus.FREE)} alt={label} /> : <UnknownWhereaboutsIcon />}
      </WhereaboutsIconWrapper>
      <UsageVisualContainer>
        <BodyLarge weight={600} style={{marginBottom: 12}}>{isCustomWhereaboutsInAccount ? label : `Custom whereabouts not in account (${whereaboutsKey})`}</BodyLarge>
        <LineBarBackground colour={bgColour}>
          <LineBarFill percentage={usedDays / totalDays * 100} colour={fgColour} />
        </LineBarBackground>
      </UsageVisualContainer>
      <UsageMetricsContainer>
        <HeadlineSmall>{usedDays} {t('days')}</HeadlineSmall>
        {subtext && <BodySmall>{subtext}</BodySmall>}
      </UsageMetricsContainer>
    </Wrapper>
  )
}

interface Props {
  totalDays: number;
  usedDays: number;
  subtext?: string;
  label: string;
  icon: string;
  whereaboutsKey: string;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${Colours.lightGrey};
`

const UsageVisualContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 80%;
  padding-right: 52px;
  padding-left: 32px;
`

const LineBarBackground = styled.div<any>`
  width: 100%;
  height: 24px;
  position: relative;
  background-color: ${props => props.colour};
  overflow: hidden;
  border-radius: 40px;
`

const LineBarFill = styled.div<any>`
  width: ${props => `${props.percentage}%`};
  background-color: ${props => props.colour};
  height: 100%;
  position: absolute;
`

const UsageMetricsContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 15%;
  align-self: center;
`

const WhereaboutsIconWrapper = styled.div`
  width: 5%;
`

const WhereaboutsIcon = styled.img`
  width: 52px;
  height: 52px;
  margin-top: 4px;
`

const UnknownWhereaboutsIcon = styled.div`
  width: 52px;
  height: 52px;
  margin-top: 8px;
  border-radius: 8px;
  background-color: ${Colours.mildGrey};
`
