import React, { useEffect } from 'react';
import moment, { Moment } from 'moment';
import './wallchart-header.scss';
import WeekFlicker from '../../../../../pages/team-movements/components/WeekFlicker';
import { BodyRegular } from '../../../../atoms/fonts/Body';
import { MovementsDayContainer } from "../StructuralComponents";
import styled from "styled-components/macro";
import Colours from "../../../../atoms/Colours";
import { WithDaysInWorkWeek } from "../../../../../../hooks/WithDaysInWorkWeek";

export default function WallChartHeader() {
  const workWeekDays = WithDaysInWorkWeek();
  const todaysDate = moment().startOf('day');
  const color = (day: Moment) => {
    return day.isSame(todaysDate, 'days') ? Colours.blue : day.isSameOrBefore(todaysDate, 'days') ? Colours.mildGrey : Colours.darkGrey;
  }
  const isActive = (day: Moment) => day.isSame(todaysDate, 'days');

  useEffect(() => {
    setupSticky('daysOfWeek', 'wallchartHeader__daysOfWeekContainer--sticky');
  }, [])

  return (
    <div data-test="daysOfWeek" className="wallchartHeader__daysOfWeekContainer">
      <WeekFlicker className="wallchartHeader__weekFlicker" />
      <div className="wallchartHeader__daysOfWeek">
        {workWeekDays.map((day, key) => (
          <HeaderDay key={key}>
            <div className={`wallchartHeader__day ${isActive(day) ? 'wallchartHeader__day--active' : ''}`}>
              <BodyRegular weight={600} colour={color(day)}>{day.format('ddd')}</BodyRegular>
              <BodyRegular weight={600} className="wallchartHeader__date"
                           colour={color(day)}>{day.format('DD')}</BodyRegular>
            </div>
          </HeaderDay>
        ))}
      </div>
    </div>
  )
}

function setupSticky(id: string, className: string) {
  const header = document.getElementById(id);
  if (!header) return;

  const sticky = header.offsetTop;

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > sticky) {
      header.classList.add(className);
    } else {
      header.classList.remove(className);
    }
  });
}

export const HeaderDay = styled<any>(MovementsDayContainer)`
  justify-content: flex-end;
  min-height: 64px;
`
