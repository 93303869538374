import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import Dialog from "../../../UI/molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import Calendar from "../../../UI/molecules/calendar/Calendar";
import moment, { Moment } from "moment/moment";
import { selectActiveDay, setActiveDay } from "../../../../store/ducks/editMovements.duck";
import { setCalendarDate } from "../../../../store/ducks/dashboard.duck";

export default function ChangeCalendarDateDialog() {
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);

  const close = () => {
    dispatch(closeDialog());
  }

  const onDateSelect = (dateSelect: Moment) => {
    dispatch(setActiveDay(dateSelect));
    dispatch(setCalendarDate(dateSelect));
    close();
  }

  return (
    <Dialog isOpen={true} onClose={() => close()}>
      <DialogHeader title={'button.choose-day'} />

      <Calendar onDateSelect={onDateSelect} activeDate={activeDay}
                initialDate={activeDay ?? moment()}
                style={{margin: '0 auto'}}
                highlightToday={true}
                hideActiveDayHighlight={false} />
    </Dialog>
  )
}
