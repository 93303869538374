import React from 'react';
import { SearchButton } from "../../search/SearchButton";
import styled from "styled-components/macro";

export function TeamsHeaderComponents(props: Props) {
  return (
    <Wrapper>
      <SearchButton className="userMenu__search" />
    </Wrapper>
  )
}

interface Props {
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
