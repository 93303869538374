import React, { useMemo } from 'react';
import { Column } from "../atoms/StructuralLayout";
import { OutlineButton } from "../atoms/buttons/OutlineButton";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { DialogIdentifiers, openDialog, openDialogWithPayload } from "../../../store/ducks/dialog.duck";
import { HorizontalSpacer, VerticalSpacer } from "../atoms/VerticalSpacer";
import { selectAllTeams } from "../../../store/ducks/config.duck";
import { selectAllOfficeEntities } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices, sortOffice } from "../../../utils/OfficeHelper";
import { dedup } from "../../../utils/ArrayUtils";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import {
  selectActiveOfficeId,
  selectCheckedTeamIds,
  selectCheckedWorkgroupIds,
  setActiveOfficeId
} from "../../../store/ducks/companyMovements.duck";
import { canUserEditGroups } from "../../../utils/AccessControl";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";

export function CompanyTeamViewSidebar() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const onCreateWorkgroupClick = () => dispatch(openDialogWithPayload({ payload: { }, activeDialog: DialogIdentifiers.CreateTagDialog, }));
  const onCreateTeamClick = () => dispatch(openDialog(DialogIdentifiers.CreateTeamDialog));
  const onLinkGroupToOfficeClick = () => dispatch(openDialog(DialogIdentifiers.LinkTeamOrGroupToOfficeDialog));
  const selectedActiveOfficeId = useSelector(selectActiveOfficeId);
  const teams = useSelector(selectAllTeams);
  const offices = useSelector(selectAllOfficeEntities);
  const checkedTeamIds = useSelector(selectCheckedTeamIds);
  const checkedWorkgroupIds = useSelector(selectCheckedWorkgroupIds);
  const currentUser = useSelector(selectCurrentUser);
  const canUserEditOrAddGroups = canUserEditGroups(currentUser);

  const assignedOffices: OfficeEntity[] = useMemo(() => {
    const officeIds = teams
      .filter(t => t.officeIds && t.officeIds.length > 0)
      .map(t => t.officeIds)
      .flat()
    const deduppedOffices = dedup(officeIds, (p1: number, p2: number) => p1 === p2);
    return deduppedOffices.map((officeId: number) => findOfficeInOffices(offices, officeId))
      .filter((office: OfficeEntity | undefined) => !!office)
      .sort(sortOffice);
  }, [teams, offices]);

  const showOfficeFilterList = assignedOffices && assignedOffices.length > 0;

  const onOfficeSelect = (office: OfficeEntity) => {
    dispatch(setActiveOfficeId(office.id));
  }

  const resetSelectedOffice = () => {
    dispatch(setActiveOfficeId(undefined));
  }

  if (!showOfficeFilterList && !canUserEditOrAddGroups) {
    return null;
  }

  return (
    <>
      <VerticalSpacer/>
      <Column className="companyMovements__sidebar">
        <Wrapper>
          <img alt={""} src={"/assets/images/man-fishing.svg"} style={{marginBottom: 24}} />
          {canUserEditOrAddGroups && <>
            <OutlineButton text={"button.create-new-team"}
                           click={onCreateTeamClick}
                           style={{marginBottom: 16}}
                           fullWidth={true}
                           size={"medium"} />
            <OutlineButton text={"button.create-new-work-group"}
                           click={onCreateWorkgroupClick}
                           style={{marginBottom: 16}}
                           fullWidth={true}
                           size={"medium"} />
            <OutlineButton text={"button.link-team-or-workgroup"}
                           click={onLinkGroupToOfficeClick}
                           disabled={checkedWorkgroupIds.length + checkedTeamIds.length === 0}
                           fullWidth={true}
                           size={"medium"} />

            <HorizontalSpacer />
          </>}


          {showOfficeFilterList && <>
            <BodyRegular weight={600} style={{marginBottom: 16}}>{t('team-and-group-radio-button-list.filter-desks')}</BodyRegular>
            <div className={'radioButton__line'} onClick={() => resetSelectedOffice()}>
              <input type="radio"
                     id={`assignedTeam-reset`}
                     className={'radioButton__input'}
                     checked={!selectedActiveOfficeId}
                     name="fav_language"
                     value={undefined}
                     onSelect={() => resetSelectedOffice()} />
              <label htmlFor="html" className={'radioButton__label' + (!selectedActiveOfficeId ? ` radioButton__label--checked` : '')}>{t('team-and-group-radio-button-list.show-all')}</label>
            </div>

            {assignedOffices.map((office: OfficeEntity, key: number) => <div key={key} className={'radioButton__line'} onClick={() => onOfficeSelect(office)}>
              <input type="radio"
                     id={`assignedTeam${key}`}
                     className={'radioButton__input'}
                     checked={office.id === selectedActiveOfficeId}
                     name="fav_language"
                     value={office.id}
                     onSelect={() => onOfficeSelect(office)} />
              <label htmlFor="html" className={'radioButton__label' + (office.id === selectedActiveOfficeId ? ` radioButton__label--checked` : '')}>{office.label}</label>
            </div>)}
          </>}

        </Wrapper>
      </Column>
    </>
  )
}

const Wrapper = styled<any>(Column)`
  width: 100%;
  max-width: 250px;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  img {
    align-self: center;
    max-width: 108px;
  }
`
