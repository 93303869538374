import React, { useEffect, useState } from 'react';
import AuthWrapper from '../components/AuthWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { redirectTo } from '../../../../store/ducks/auth.duck';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { BodyRegular } from '../../../UI/atoms/fonts/Body';
import { Button } from '../../../UI/atoms/buttons/Button';
import './verification-required.scss';
import { successNotification, failureNotification } from '../../../../store/ducks/notification.duck';
import { requestValidationEmail } from '../../../../services/Auth';
import { selectEmail } from '../../login/login.duck';
import {useTranslation} from "react-i18next";

const TIMEOUT = 60000;

export default function VerificationRequired() {
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const [verificationTimer, setVerificationTimer] = useState(0);
  const {t} = useTranslation();

  useEffect(() => {
    if (!email) {
      dispatch(redirectTo('/login'));
    }
  }, [dispatch, email]);

  const requestNewVerificationEmail = async () => {
    if (verificationTimer < Date.now()) {
      try {
        setVerificationTimer(Date.now() + TIMEOUT);
        await requestValidationEmail(email)
        dispatch(successNotification(`Email sent, check your email inbox`));
      } catch (err) {
        console.log(err)
        dispatch(failureNotification(`Please wait a little longer before trying again`));
      }
    } else {
      const elapsed = Math.ceil((verificationTimer - Date.now()) / 1000);
      dispatch(failureNotification(`Please wait another ${elapsed} seconds before trying again`));
    }
  }

  return (
    <AuthWrapper dataTest="verificationReq" hideLoginLink={true} title="" className="verifyRequired">
      <img className="verifyRequied__icon" src="/assets/icons/font/Email.svg" alt="" aria-hidden={true} />
      <HeadlineMedium className="verifyRequired__header">{t('register.verification-required')}</HeadlineMedium>
      <BodyRegular weight={600} className="verifyRequired__info">{t('register.check-inbox')}</BodyRegular>
      <BodyRegular className="verifyRequired__description">{t('register.follow-link')}</BodyRegular>
      <Button className="verifyRequired__button" type="outline-borderless" onClick={() => requestNewVerificationEmail()} text="register.resend-email" />
    </AuthWrapper>
  )

}
