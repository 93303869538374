import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectCalendarDate } from "../../../store/ducks/dashboard.duck";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import WhereaboutsRow from "./movements-wallchart/components/user-row/WhereaboutsRow";
import { Accordian } from "../../../animations/AnimationVariants";
import {
  loadCurrentUsersWhereabouts,
  selectCurrentUsersWhereabouts
} from "../../pages/team-movements/models/team-movements.duck";

export function CurrentUsersWeeklyWhereabouts(props: Props) {
  const calendarDate = useSelector(selectCalendarDate);
  const currentUser = useSelector(selectCurrentUser);
  const currentUsersWeeklyWhereabouts = useSelector(selectCurrentUsersWhereabouts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (calendarDate && currentUser) {
      dispatch(loadCurrentUsersWhereabouts({calendarDate: calendarDate}));
    }
  }, [currentUser, calendarDate, dispatch]);

  return (
    <div style={{...props.style ?? {}, width: '100%'}}>
      <Accordian isOpen={!!currentUsersWeeklyWhereabouts}>
        {currentUsersWeeklyWhereabouts && <WhereaboutsRow member={currentUsersWeeklyWhereabouts}
                                                          enableSetMovements={true}
                                                          currentUser={currentUser} />}
      </Accordian>
    </div>
  )
}

interface Props {
  style?: any;
}
