import React, { useMemo } from 'react';
import { Column, Row } from '../../../UI/atoms/StructuralLayout';
import { CompanyTeamViewSidebar } from "../../../UI/molecules/CompanyTeamViewSidebar";
import { WithCompanyViewSelector } from "../../../../hooks/WithCompanyViewSelector";
import Layout from "../../../UI/organisms/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTeams } from "../../../../store/ducks/config.duck";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import {
  selectActiveOfficeId,
  selectCheckedTeamIds,
  setCheckedTeamIds
} from "../../../../store/ducks/companyMovements.duck";
import { useTranslation } from "react-i18next";
import { canUserEditGroups } from "../../../../utils/AccessControl";
import { TeamWithCount, TeamWithCountAndRestricted } from "../../../../models/team.models";
import { HeadlineLarge, HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { TeamContentCardList } from "./ViewComponents";
import { TeamContentCard } from "../../../UI/molecules/TeamContentCard";
import { CreateTeamContentCard } from "../../../UI/molecules/CreateTeamContentCard";

export function CompanyTeamView() {
  const dispatch = useDispatch();
  const teams = useSelector(selectAllTeams);
  const currentUser = useSelector(selectCurrentUser);
  const activeOfficeId = useSelector(selectActiveOfficeId);
  const {t} = useTranslation();
  const showEditIcon = canUserEditGroups(currentUser);
  const checkedTeamIds = useSelector(selectCheckedTeamIds);
  WithCompanyViewSelector();

  const visibleTeams = useMemo(() => {
    if (activeOfficeId) {
      return teams.filter(t => t.officeIds && t.officeIds?.includes(activeOfficeId));
    } else {
      return teams;
    }
  }, [teams, activeOfficeId]);

  const onTeamCheckboxSelect = (team: TeamWithCount, state: boolean) => {
    if (state) {
      dispatch(setCheckedTeamIds([...checkedTeamIds, team.id]));
    } else {
      dispatch(setCheckedTeamIds(checkedTeamIds.filter(id => id !== team.id)));
    }
  }

  return (
    <Layout>
      <Row>
        <Column className="companyMovements__movements">
          <>
            <HeadlineLarge style={{marginBottom: 12}}>{t('company-view-page.all-teams')}</HeadlineLarge>
            <HeadlineSmall style={{marginBottom: 24}}>{currentUser?.companyEntity.name}</HeadlineSmall>
            <TeamContentCardList style={{marginBottom: 36}}>
              {visibleTeams.map((team: TeamWithCountAndRestricted, key: number) => (
                <TeamContentCard key={key}
                                 link={`/whereabouts/company/team/${team.id}`}
                                 label={team.name}
                                 memberCount={team.memberCount}
                                 showEditIcon={showEditIcon}
                                 disabled={!!team.restricted}
                                 editLink={`/manage/team/${team.id}`}
                                 officeIds={team.officeIds}
                                 checkboxEnabled={showEditIcon}
                                 isChecked={checkedTeamIds.includes(team.id)}
                                 onCheckboxChecked={(state: boolean) => onTeamCheckboxSelect(team, state)} />
              ))}
              <CreateTeamContentCard />
            </TeamContentCardList>
          </>
        </Column>
        <CompanyTeamViewSidebar />
      </Row>
    </Layout>
  )
}
