import React, { useMemo } from 'react';
import Dialog from "../../UI/molecules/Dialog";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { selectAllOfficeEntities } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import { getRootOfficeById } from "../../../utils/OfficeHelper";
import moment from "moment";
import { PRETTY_SHORT_DATE_FORMAT } from "../../../utils/DateUtils";


export function UnableToBookDesksErrorDialog(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const allOffices = useSelector(selectAllOfficeEntities);

  const officeId = props?.payload?.officeId ?? 0;
  const rootOffice = getRootOfficeById(allOffices, officeId);

  const dateYouCanBookUpto = useMemo(() => {
    if (rootOffice && rootOffice.officeBookingWindowWeeks) {
      return moment().isoWeekday('monday').add(rootOffice.officeBookingWindowWeeks, 'weeks').format(PRETTY_SHORT_DATE_FORMAT);
    } else {
      return 'UNKNOWN';
    }
  }, [rootOffice])

  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <Wrapper>
      <Dialog isOpen={true} onClose={close}>
        <ImageWrapper>
          <Image src={"/assets/icons/standard/Office.svg"} alt={""} />
        </ImageWrapper>
        <HeadlineMedium style={{marginBottom: 16, marginTop: 16}}>{t('dialog.unable-to-book-desks-far-in-advance-title')}</HeadlineMedium>
        <BodyRegular style={{marginBottom: 16}}>{t('dialog.unable-to-book-desks-far-in-advance-para1', {officeName: rootOffice?.label ?? '', bookingWindow: rootOffice?.officeBookingWindowWeeks ?? 0})}</BodyRegular>
        <BodyRegular style={{marginBottom: 24}}>{t('dialog.unable-to-book-desks-far-in-advance-para2', {officeName: rootOffice?.label ?? '', weekCommencing: dateYouCanBookUpto})}</BodyRegular>
        <PrimaryButton click={close} fullWidth={true} size={'large'} text={"button.done"} />
      </Dialog>
    </Wrapper>
  )
}

const ImageWrapper = styled.div`
  margin: 0 auto;
  background-color: ${Colours.veryLightGrey};
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Wrapper = styled.div`
  .dialog {
    width: 500px;
  }
  .dialog__content {
    width: 100%;
    margin: unset;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .dialog__contentContainer {
    padding: 8px;
  }
`

const Image = styled.img`
  width: 80%;
  height: 80%;
`

interface Props {
  payload?: PayloadProps;
}

interface PayloadProps {
  officeId: number;
  weekCommencing: string;
}
