import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectOffices } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { BodyLarge, BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { NoSetup } from "./NoSetup";
import Colours from "../../../../../UI/atoms/Colours";
import { MountedContentCard, MountedContentCardContent } from "../../../../../UI/atoms/MountedContentCard";
import { SpaceBetweenRow } from "../../../StyleComponents";
import { Column, Row } from "../../../../../UI/atoms/StructuralLayout";
import { smallTablet } from "../../../../../UI/atoms/MediaQueries";
import { Button } from '../../../../../UI/atoms/buttons/Button';
import styled from "styled-components";
import { getOfficeCapacity } from "../../../../../../services/OfficeCapacityService";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { iconPackPath } from "../../../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../../../store/ducks/auth.duck";
import { IconPack } from "../../../../../../models/company.models";

export function OfficeList() {
  const rootOffices = useSelector(selectOffices);
  const iconPack = useSelector(selectIconPack);

  return (
    <div style={{marginBottom: 16}}>
      {(!rootOffices) && <NoSetup/>}
      {rootOffices.map((office: OfficeEntity, key: number) => (
        <OfficeElement office={office} iconPack={iconPack} key={key} />
      ))}
    </div>
  )
}


function OfficeElement (props: {office: OfficeEntity, iconPack: IconPack}) {
  const {office, iconPack} = props;
  const [officeCapacity, setOfficeCapacity] = useState(0);
  const {t} = useTranslation();

  useEffect(() => {
    if (office.capacityEnabled) {
      getOfficeCapacity(office.id, moment())
        .then(resp => setOfficeCapacity(resp.capacity));
    }
  }, [office]);

  return (
    <OfficeContentCard className="contentCard__office">

      <MountedContentCardContent>
        <SpaceBetweenRow>
          <Row style={{alignItems: 'flex-start', marginBottom: 10}}>
            <OfficeImage src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="Office image"/>
            <Column>
              <BodyLarge weight={600}>{office.label}</BodyLarge>
              {office.capacityEnabled && <BodyRegular weight={600} colour={Colours.blue} style={{marginBottom: 10}}>{t('whereabouts.seats-available', {remaining: officeCapacity})}</BodyRegular>}
              <BodyRegular weight={400}>{office.postCode}</BodyRegular>
            </Column>
          </Row>
        </SpaceBetweenRow>
        <Button fullWidth={true}
                size="large"
                text="button.edit-office"
                dataTest={`edit-office-${office.label}`}
                type="outline-black"
                onClick={() => window.location.href=`/manage/hot-desking/edit/${office.id}`}/>
      </MountedContentCardContent>

    </OfficeContentCard>
  )
}

const OfficeImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 8px;
`


const OfficeContentCard = styled<any>(MountedContentCard)<any>`
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  justify-content: center;
  border: 1px solid ${Colours.mildGrey};
  border-radius: 8px;
  @media (${smallTablet}) {
    padding: 48px;
  }
`
