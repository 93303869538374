import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge, HeadlineSmall } from '../../../UI/atoms/fonts/Headline';
import AccessRequired from '../components/AccessRequired';
import { selectCurrentUser } from '../../../../store/ducks/auth.duck';
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import { canEditCustomWhereabouts } from "../../../../utils/AccessControl";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { BodyRegular } from '../../../UI/atoms/fonts/Body';
import Colours from "../../../UI/atoms/Colours";
import { HorizontalSpacer } from "../../../UI/atoms/VerticalSpacer";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components";
import { WhereaboutsOption } from "../../../../services/WhereaboutOptions";
import { EditableWhereaboutsOption } from "./custom-whereabouts-page/EditableWhereaboutsOption";
import { DialogIdentifiers, openDialog } from "../../../../store/ducks/dialog.duck";
import {
  LoadingState,
  selectAllWhereaboutsOptions,
  selectLoadingState
} from "../../../../store/ducks/whereaboutsOptions.duck";
import { ManageOfficeLocationsOption } from "./custom-whereabouts-page/ManageOfficeLocationsOption";
import { CustomWhereaboutsPromo } from "./custom-whereabouts-page/CustomWhereaboutsPromo";
import { useTranslation } from "react-i18next";

export default function EditWhereaboutsIconsPage() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const canEdit = useMemo(() => canEditCustomWhereabouts(currentUser), [currentUser]);
  const {t} = useTranslation();

  const whereabouts = useSelector(selectAllWhereaboutsOptions);
  const loading = useSelector(selectLoadingState);

  const defaultWhereabouts = useMemo(() => whereabouts.filter(whereabouts => whereabouts.required && whereabouts.pickable), [whereabouts]);
  const optionalWhereabouts = useMemo(() => whereabouts.filter(whereabouts => !whereabouts.required && whereabouts.pickable), [whereabouts]);

  return (
    <React.Fragment>
      {loading === LoadingState.Pending && <LoadingSpinner fullScreen={true}/>}
      <div className="management">
        <GoBackButton/>

        <HeadlineLarge style={{marginBottom: 16}} className="management__title">{t('custom-whereabouts-page.whereabouts')}</HeadlineLarge>

        <AccessRequired visible={!canEdit} companyAdminsRequired={true} teamAdminsRequired={false}/>

        <BodyRegular style={{marginBottom: 24}}>{t('custom-whereabouts-page.available-in-team-view')}</BodyRegular>

        <HeadlineSmall style={{marginBottom: 12}}>{t('custom-whereabouts-page.how-it-works')}</HeadlineSmall>
        <BodyRegular style={{marginBottom: 16}}>{t('custom-whereabouts-page.custom-whereabouts-requirements')}</BodyRegular>


        {canEdit && <>
          {defaultWhereabouts.map((whereabouts: WhereaboutsOption, key: number) => (<EditableWhereaboutsOption key={key} whereabouts={whereabouts} />))}

          <HeadlineSmall style={{marginTop: 48, marginBottom: 24}}>{t('whereabouts.custom-whereabouts')}</HeadlineSmall>

          <ManageOfficeLocationsOption />

          <HorizontalSpacer style={{marginTop: 24, marginBottom: 24}} />

          {optionalWhereabouts.map((whereabouts: WhereaboutsOption, key: number) => (<EditableWhereaboutsOption key={key} whereabouts={whereabouts} />))}

          <HorizontalSpacer />

          <CustomWhereaboutsPromo />

          <HorizontalSpacer />

          <AddCustomWhereaboutsButton onClick={() => dispatch(openDialog(DialogIdentifiers.AddCustomWhereabouts))} data-test={'add-custom-whereabouts-button'}>
            <Icon icon={IconTypes.Add} circle={true} color={'blue'} size={"medium"} />
            <BodyRegular weight={600} colour={Colours.blue}>{t('whereabouts.add-custom-whereabouts')}</BodyRegular>
          </AddCustomWhereaboutsButton>
        </>}
      </div>
    </React.Fragment>
  );
}

const AddCustomWhereaboutsButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    margin-right: 16px;
  }
`;
