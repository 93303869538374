import { agent } from './agent';

export interface Car {
  userId: string;
  make: string;
  colour: string;
  registration: string;
}

export async function saveCar(car: Car) {
  return await agent.requests.post('/api/car', car);
}

export async function getCar(userId: string): Promise<Car> {
  return await agent.requests.get(`/api/car?userId=${userId}`);
}

