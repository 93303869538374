import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { BasicUserInfo, User } from '../../models/user.models';
import { Auth } from '../../services/Auth';
import { AuthType } from "../../utils/AuthUtils";
import { requestUsersHierarchy } from "../../services/CompanyHierarchyService";
import { TenantResponse } from "../../services/AccessService";
import { IconPack } from "../../models/company.models";

export enum TeamsAuthState {
  Loading = 0, SignedOut = 1, SignedIn = 2
}

export interface AuthState {
  currentUser?: User;
  rememberMe: boolean;
  errorMessage?: string;
  redirectTo?: string;
  loading: boolean;
  registerAuthType?: AuthType;
  teamsAuthState?: TeamsAuthState;
  hasTeamsLoaded: boolean;
  isInTeamsApp: boolean;
  currentUsersLineReports: BasicUserInfo[];
  tenantInfo?: TenantResponse;
  calendarSyncActive: boolean;
}

export const initialAuthState: AuthState = {
  currentUser: undefined,
  rememberMe: !!localStorage.getItem('rememberMe'),
  errorMessage: undefined,
  redirectTo: undefined,
  loading: true,
  registerAuthType: undefined,
  hasTeamsLoaded: false,
  teamsAuthState: undefined,
  isInTeamsApp: false,
  currentUsersLineReports: [],
  calendarSyncActive: false,
}

export const current = createAsyncThunk(
  'auth/current',
  async (passive: boolean = false, thunkAPI) => {
    await thunkAPI.dispatch(setAuthLoading(true));
    try {
      if (localStorage.getItem('idToken')) {
        const user = await Auth.current(passive);
        await thunkAPI.dispatch(setUser(user));
      } else {
        thunkAPI.dispatch(reset());
      }
    } finally {
      thunkAPI.dispatch(setAuthLoading(false));
    }
  }
)

export const currentUserLineReports: any = createAsyncThunk(
  'auth/currentUserLineReports',
  async (none: boolean, thunkAPI) => {
    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
    if (currentUser && currentUser.companyEntity.lineManagementEnabled) {
      const structure = await requestUsersHierarchy(currentUser.id);
      return structure.lineReports;
    } else {
      return [];
    }
  }
)

export const updateCurrentUser = createAsyncThunk(
  'auth/current',
  async (force: boolean = false, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    try {
      if (!force && appState.auth.currentUser) {
        return;
      }

      await thunkAPI.dispatch(setAuthLoading(true));

      if (localStorage.getItem('idToken')) {
        const user = await Auth.current(false);
        await thunkAPI.dispatch(setUser(user));
      } else {
        thunkAPI.dispatch(reset());
      }
    } finally {
      thunkAPI.dispatch(setAuthLoading(false));
    }
  }
)

// @ts-ignore
const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    reset: (state) => ({
      ...initialAuthState,
      loading: state.loading
    }),
    clearRegistration: (state) => ({
      ...initialAuthState,
      loading: state.loading,
      currentUser: state.currentUser,
      rememberMe: state.rememberMe,
      hasTeamsLoaded: state.hasTeamsLoaded,
      isInTeamsApp: state.isInTeamsApp,
    }),
    setTenantInfo: (state, action) => ({ ...state, tenantInfo: action.payload }),
    setAuthLoading: (state, action) => ({ ...state, loading: action.payload }),
    setUser: (state, action) => ({ ...state, currentUser: action.payload }),
    setAuthError: (state, action) => ({ ...state, errorMessage: action.payload }),
    setRegisterAuthType: (state, action) => ({ ...state, registerAuthType: action.payload }),
    setIsInTeamsApp: (state, action) => ({ ...state, isInTeamsApp: action.payload }),
    redirectTo: (state, action) => ({...state, redirectTo: action.payload}),
    setHasTeamsLoaded: (state, action) => ({...state, hasTeamsLoaded: action.payload}),
    setTeamsProviderState: (state, action) => ({...state, teamsAuthState: action.payload}),
    setCalendarSyncActive: (state, action) => ({...state, calendarSyncActive: action.payload}),
    closeFirstTimeView: (state) => {
      if (state.currentUser) {
        return {
          ...state,
          currentUser: { ...state.currentUser, firstTime: false },
        }
      } else {
        return state
      }
    },
  },
  extraReducers: {
    [currentUserLineReports.fulfilled]: (state, action) => ({...state, currentUsersLineReports: action.payload}),
  }
})

export default authSlice.reducer
export const {
  setAuthError,
  setAuthLoading,
  setRegisterAuthType,
  setUser,
  setIsInTeamsApp,
  setTenantInfo,
  reset,
  clearRegistration,
  redirectTo,
  closeFirstTimeView,
  setCalendarSyncActive,
  setHasTeamsLoaded,
  setTeamsProviderState
} = authSlice.actions;

// Selectors
export const selectCurrentUser = (state: AppState) => state.auth.currentUser;
export const selectIconPack = (state: AppState) => state.auth.currentUser?.companyEntity.iconPack ?? IconPack.STANDARD;
export const selectCurrentUserLineReports = (state: AppState) => state.auth.currentUsersLineReports;
export const selectTeamsProviderState = (state: AppState) => state.auth.teamsAuthState;
export const selectRedirectTo = (state: AppState) => state.auth.redirectTo;
export const selectIsAuthLoading = (state: AppState) => state.auth.loading;
export const selectAuthErrorMessage = (state: AppState) => state.auth.errorMessage;
export const selectRegisterAuthType = (state: AppState) => state.auth.registerAuthType;
export const selectIsInTeamsApp = (state: AppState) => state.auth.isInTeamsApp;
export const selectTenantInfo = (state: AppState) => state.auth.tenantInfo;
export const selectIsCalendarSyncActive = (state: AppState) => state.auth.calendarSyncActive;
export const selectCurrentUserTeam = (state: AppState) => {
  const mockTeam = () => ({
    id: 'No team assigned',
    companyId: state.auth.currentUser?.companyEntity.id || '',
    name: 'No team assigned'
  });
  return state.auth.currentUser?.teamEntity || mockTeam();
};
