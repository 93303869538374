import { agent } from './agent';
import { UserInfo } from "../models/user.models";

export async function getUserInfo(userId: string): Promise<UserInfo> {
    return await agent.requests.get(`/api/user-info/${userId}`);
}

export async function updatePermissions(userId: string, permission: string) {
    return await agent.requests.post(`/api/user/permissions`, {
        userId, permission
    }, true)
}

export async function deleteUserRequest(userId: string) {
    return await agent.requests.del(`/api/user/${userId}`, true);
}

export async function findAllOrphanedUsers() {
    return await agent.requests.get(`/api/user-info/no-teams`, true);
}

