import React, { useEffect, useRef, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import styled from "styled-components";
import Colours from "../../atoms/Colours";
import { BodyRegular, BodyXXSmall } from "../../atoms/fonts/Body";
import { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { getWeeklyOfficeUsage } from "../../../../services/ReportingService";
import { tablet } from "../../atoms/MediaQueries";


export function WeeklyOfficeUsageChart(props: Props) {
  const {office, style, date} = props;
  const timerRef = useRef(0);
  const {t} = useTranslation();
  const [dataSets, setDataSets] = useState<any>([
    {name: 'M', day: 'Monday', avg: 0.0},
    {name: 'T', day: 'Tuesday', avg: 0.0},
    {name: 'W', day: 'Wednesday', avg: 0.0},
    {name: 'T', day: 'Thursday', avg: 0.0},
    {name: 'F', day: 'Friday', avg: 0.0},
  ]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, [])

  useEffect(() => {
    const loadOfficeData = () => {
      if (!office || !date) return;
      getWeeklyOfficeUsage(office, date).then((resp: any) => {
          if (resp) {
            setDataSets([
              {name: 'M', day: 'Monday', colour: 'red', avg: resp.monday},
              {name: 'T', day: 'Tuesday', colour: 'red', avg: resp.tuesday},
              {name: 'W', day: 'Wednesday', colour: 'red', avg: resp.wednesday},
              {name: 'T', day: 'Thursday', colour: 'red', avg: resp.thursday},
              {name: 'F', day: 'Friday', colour: 'red', avg: resp.friday},
            ]);
          } else {
            throw new Error('Undefined office usage response');
          }
        })
        .catch(console.error);
    }

    if(office && date) {
      timerRef.current = setTimeout(() => loadOfficeData(), 2000);
    }

  }, [office, date])

  return (
    <ChartContainer style={style ?? {}}>
      <BodyRegular colour={Colours.darkGrey} weight={600} style={{marginBottom: 12}}>{t('reports-page.weeks-office-usage')}</BodyRegular>
      <ResponsiveContainer height={120} >
        <BarChart
          data={dataSets}
          barSize={28}
          margin={{}}
        >
          <XAxis dataKey="name" fontFamily={'proxima-nova'} fill={'#647478'} fontWeight={600} fontSize={18} axisLine={false} dy={-2} />
          <YAxis fontFamily={'"soleil", sans-serif'} domain={[0, 1]} hide={true} />
          <Bar dataKey="avg" fill={Colours.green} background={{ fill: Colours.lightGrey }} />
        </BarChart>
      </ResponsiveContainer>
      <BodyXXSmall colour={Colours.darkGrey} weight={400} style={{marginTop: 6, textAlign: 'right'}}>{t('reports-page.updated-hourly')}</BodyXXSmall>
    </ChartContainer>
  )
}

interface Props {
  office?: OfficeEntity;
  style?: any;
  date?: Moment;
}

const ChartContainer = styled.div`
  background-color: ${Colours.veryLightGrey};
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 16px;
  width: 100%;
  height: 225px;
  display: none;
  @media (${tablet}) {
    display: block;
  }
  .recharts-cartesian-axis-tick-line {
    display: none !important;
  }
`
