import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isInTeamsStoreApp } from "../utils/TeamsUtils";
import { TrackJS } from "trackjs";
import { redirectTo } from "../store/ducks/auth.duck";
import { useLocation } from "react-router";
import { app } from "@microsoft/teams-js";

const TEAMS_LOCATION_ROUTING_MAP = {
  routing: {
    'teamsapp-team-tracker-settings': {
      redirectPath: '/manage',
      wildcardPath: '/manage',
      subPaths: ['/manage']
    },
    'teamsapp-team-tracker-team': {
      redirectPath: '/whereabouts/team',
      wildcardPath: '/whereabouts/team',
      subPaths: ['/whereabouts/team', '/whereabouts/team/add-users', '/whereabouts/work-planner', '/whereabouts/work-planner/event', '/whereabouts/floor-planner', '/whereabouts/office-view']
    },
    'teamsapp-team-tracker-company': {
      redirectPath: '/whereabouts/company',
      wildcardPath: '/whereabouts/company',
      subPaths: ['/whereabouts/company/all', '/whereabouts/company/workgroups'],
    },
    'teamsapp-team-tracker-company-whereabouts': {
      redirectPath: '/whereabouts/company',
      wildcardPath: '/whereabouts/company',
      subPaths: ['/whereabouts/company'],
    },
    'teamsapp-team-tracker-planner': {
      redirectPath: '/work-planner',
      wildcardPath: '/work-planner',
      subPaths: ['/work-planner', '/work-planner/event'],
    },
    'teamsapp-team-tracker-holidays': {
      redirectPath: '/holidays',
      wildcardPath: '/holidays',
      subPaths: ['/holidays'],
    },
    'teamsapp-team-tracker-meetingrooms': {
      redirectPath: '/meeting-rooms',
      wildcardPath: '/meeting-rooms',
      subPaths: ['/meeting-rooms'],
    },
  },
  ignoredRoutes: ['/onboarding', '/meeting-rooms', '/meeting-rooms/my-bookings', '/work-planner/event'],
}

export function WithMsTeamsAppStoreRouting() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [context, setContext] = useState<app.Context | null>(null);

  useEffect(() => {
    if (isInTeamsStoreApp()) {
      app.initialize().then(() => app.getContext()).then((ctx: app.Context) => {
        setContext(ctx);
      });
    }
  }, []);

  useEffect(() => {
    if (!isInTeamsStoreApp()) {
      return;
    }

    if (TEAMS_LOCATION_ROUTING_MAP.ignoredRoutes.includes(location.pathname)) {
      return;
    }

    if (context) {
      // @ts-ignore
      const path = TEAMS_LOCATION_ROUTING_MAP.routing[context.page.id];
      console.log('path', path, location.pathname)
      if (!path) {
        console.error('Unknown route', path);
        TrackJS?.track(`Unknown route '${path}'`);
        dispatch(redirectTo('/404'));
        return;
      } else {
        if (!path.subPaths.includes(location.pathname) && !location.pathname.includes(path.wildcardPath)) {
          console.log('redirect teams to', path.redirectPath)
          dispatch(redirectTo(path.redirectPath));
        }
      }
    }

  }, [location, dispatch, context]);
}
