import React, { useState } from 'react';
import Dialog from "../../UI/molecules/Dialog";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { useDispatch, useSelector } from "react-redux";
import { Center } from "../../UI/atoms/StructuralLayout";
import { BodyLarge } from "../../UI/atoms/fonts/Body";
import { HorizontalSpacer } from "../../UI/atoms/VerticalSpacer";
import { WeeklyNotesView } from "./components/WeeklyNotesView";
import { SetWeeklyNote } from "./components/SetWeeklyNote";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

export enum WeeklyNotesMode {
  View, Edit
}

export function WeeklyNotesDialog(props: Props) {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(WeeklyNotesMode.View);
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const close = () => {
    dispatch(closeDialog());
  };

  return (
    <WeeklyNotesDialogWrapper>
      <Dialog isOpen={true} onClose={close}>
        <Center>
          <BodyLarge weight={600}>{t('dialog.week-notes')}</BodyLarge>
        </Center>
        <HorizontalSpacer />

        {mode === WeeklyNotesMode.View && props?.payload?.teamIds && props?.payload?.date && <WeeklyNotesView teamIds={props.payload.teamIds}
                                                                                                              date={props.payload.date}
                                                                                                              onEditClick={() => setMode(WeeklyNotesMode.Edit)} />}
        {mode === WeeklyNotesMode.Edit && props?.payload?.date && currentUser?.teamEntity && <SetWeeklyNote teamId={currentUser.teamEntity.id}
                                                                                                            date={props.payload.date}
                                                                                                            userId={currentUser.id}
                                                                                                            done={() => setMode(WeeklyNotesMode.View)} />}

      </Dialog>
    </WeeklyNotesDialogWrapper>
  )
}

interface Props {
  payload?: any;
}

const WeeklyNotesDialogWrapper = styled.div`
  .dialog {
    max-width: 100%;
    width: 860px;
  }
  .dialog__content {
    width: 100%;
    padding: 24px;
    margin: unset;
    max-width: 100%;
  }
`
