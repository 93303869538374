import React, { useMemo, useState } from 'react';
import { DateInputField } from "../../../UI/molecules/DateInputField";
import FullScreenDateRangeSelector from "../../../UI/molecules/calendar/FullScreenDateRangeSelector";
import moment, { Moment } from "moment";
import { Row, SpaceBetweenRow } from "../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import { ToggleableOutlineButton } from "../../../UI/atoms/buttons/ToggleableOutlineButton";
import { Period } from "../../../../models/movements.models";
import { daysBetween } from "../../../../utils/DateUtils";

export function HolidayDateRangePicker(props: Props) {
  const {disabled} = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isToDateActive, setIsToDateActive] = useState(false);
  const fromDate = useMemo(() => props.fromDate, [props]);
  const toDate = useMemo(() => props.toDate, [props]);

  const [fromDatePeriod, setFromDatePeriod] = useState(props?.toDatePeriod ?? Period.AllDay);
  const [toDatePeriod, setToDatePeriod] = useState(props?.fromDatePeriod ?? Period.AllDay);

  const onDateClick = (dateInputType: string) => {
    if (disabled) return;
    setIsToDateActive(dateInputType === 'toDate');
    setIsCalendarOpen(true);
  }

  const onDatesSelected = (startDate?: Moment, endDate?: Moment) => {
    if (disabled) return;
    props.onDatesSelected(fromDatePeriod, toDatePeriod, startDate ?? fromDate, endDate ?? toDate);
  }

  const setFromPeriod = (period: Period) => {
    if (disabled) return;
    setFromDatePeriod(period);
    if (daysBetween(fromDate, toDate) === 0) {
      setToDatePeriod(period);
      props.onDatesSelected(period, period, fromDate, toDate);
    } else {
      props.onDatesSelected(period, toDatePeriod, fromDate, toDate);
    }
  }

  const setToPeriod = (period: Period) => {
    if (disabled) return;
    setToDatePeriod(period);
    if (daysBetween(fromDate, toDate) === 0) {
      setFromDatePeriod(period);
      props.onDatesSelected(period, period, fromDate, toDate);
    } else {
      props.onDatesSelected(fromDatePeriod, period, fromDate, toDate);
    }
  }

  return (
    <>
      <SpaceBetweenRow>
        <DatePickerContainer>

          <DateInputField onClick={() => onDateClick('fromDate')}
                          placeholder="textinput.add-date"
                          disabled={disabled}
                          dataTest={"from-date-field"}
                          style={{width: '100%', margin: 0, marginBottom: 16, padding: 0}}
                          value={fromDate ? fromDate.format('D MMMM YYYY') : ''}
                          label="textinput.from"
                          editableOnly={true} />

          {!props.hidePeriodSelector &&
            <SideOfDayRow>
              <ToggleableOutlineButton size={"small"} text="button.all-day" dataTest={"from-all-day-button"}
                                       isActive={fromDatePeriod === Period.AllDay}
                                       disabled={disabled}
                                       click={() => setFromPeriod(Period.AllDay)} />
              <Row>
                <ToggleableOutlineButton size={"small"} text="button.am" dataTest={"from-am-button"}
                                         isActive={fromDatePeriod === Period.AM}
                                         disabled={disabled}
                                         click={() => setFromPeriod(Period.AM)} />
                <ToggleableOutlineButton size={"small"} text="button.pm" dataTest={"from-pm-button"}
                                         isActive={fromDatePeriod === Period.PM}
                                         disabled={disabled}
                                         click={() => setFromPeriod(Period.PM)} />
              </Row>
            </SideOfDayRow>
          }
        </DatePickerContainer>

        <DatePickerContainer>

          <DateInputField style={{margin: 0, marginBottom: 16, padding: 0}}
                          onClick={() => onDateClick('toDate')}
                          placeholder="textinput.add-date"
                          dataTest={"to-date-field"}
                          disabled={disabled}
                          value={toDate ? toDate.format('D MMMM YYYY') : ''}
                          label="textinput.to"
                          editableOnly={true}/>

          {!props.hidePeriodSelector &&
            <SideOfDayRow>
              <ToggleableOutlineButton size={"small"} text="button.all-day"
                                       dataTest={"from-all-day-button"}
                                       disabled={disabled}
                                       isActive={toDatePeriod === Period.AllDay}
                                       click={() => setToPeriod(Period.AllDay)} />
              <Row>

                <ToggleableOutlineButton size={"small"} text="button.am"
                                         dataTest={"am-day-button"}
                                         disabled={disabled}
                                         isActive={toDatePeriod === Period.AM}
                                         click={() => setToPeriod(Period.AM)} />
                <ToggleableOutlineButton size={"small"} text="button.pm"
                                         dataTest={"pm-day-button"}
                                         disabled={disabled}
                                         isActive={toDatePeriod === Period.PM}
                                         click={() => setToPeriod(Period.PM)} />
              </Row>
            </SideOfDayRow>
          }
        </DatePickerContainer>
      </SpaceBetweenRow>

      <FullScreenDateRangeSelector open={isCalendarOpen}
                                   onDateSelect={onDatesSelected}
                                   onClose={() => setIsCalendarOpen(false)}
                                   activeToDate={isToDateActive}
                                   startDate={fromDate} endDate={toDate}
                                   initialDate={fromDate ?? moment()} />
    </>
  )
}

interface Props {
  fromDate?: Moment;
  toDate?: Moment;
  disabled: boolean;
  onDatesSelected: (startDatePeriod: Period, endDatePeriod: Period, startDate?: Moment, endDate?: Moment) => any;
  hidePeriodSelector?: boolean;
  toDatePeriod?: Period;
  fromDatePeriod?: Period;
}

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48% !important;
  button {
    min-width: unset;
    height: unset;
    padding: 10px 12px;
    margin: 0;
  }
  span {
    font-weight: 500;
  }
`

const SideOfDayRow = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  &>* {
    margin-bottom: 8px;
  }
`
