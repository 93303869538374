import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BodyRegular, BodyVerySmall } from "../../../atoms/fonts/Body";
import { Column, SpaceBetweenRow } from "../../../atoms/StructuralLayout";
import { Moment } from "moment";
import { EditHotDeskingButton } from "./EditHotDeskingButton";
import { MovementImage } from "../../../atoms/MovementImage";
import { FavouriteDesk, FavouriteOfficeEntity, OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import {
  selectActivePeriod,
  selectUserRequiredParking,
  updateMovement
} from "../../../../../store/ducks/editMovements.duck";
import { warningNotification } from "../../../../../store/ducks/notification.duck";
import {
  selectAllOfficeEntities,
  selectFavouriteDesks,
  selectFavouriteOffices,
  selectOffices
} from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { openLocationDialog } from "../../../../../store/ducks/deskReservation.duck";
import { selectCurrentUser, selectIconPack } from "../../../../../store/ducks/auth.duck";
import {
  doesRootOfficeHaveCarParkingPlan,
  getFullOfficeHierarchy,
  getOfficeChildren
} from "../../../../../utils/OfficeHelper";
import { FreeBusyStatus, OFFICE_OPTION, WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import { CapacityResponse, getOfficeCapacity } from "../../../../../services/OfficeCapacityService";
import { Period } from "../../../../../models/movements.models";
import { FavouritedDeskActivity } from "./FavouritedDeskActivity";
import { WhereaboutsCapacityLabel } from "./WhereaboutsCapacityLabel";
import { selectIsCarParkingEnabled } from "../../../../../store/ducks/config.duck";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";

interface Props {
  activeDate: Moment;
}

export default function ActivityAdvanceHotDeskingOffice(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const allOffices = useSelector(selectAllOfficeEntities);
  const offices = useSelector(selectOffices);
  const favoriteOffices = useSelector(selectFavouriteOffices);
  const favoriteDesks = useSelector(selectFavouriteDesks);
  const defaultOffice = useMemo(() => allOffices.find((office: OfficeEntity) => office.id === currentUser?.defaultLocationId), [allOffices, currentUser]);
  const isCarParkingEnabledForCompany = useSelector(selectIsCarParkingEnabled);
  const userRequiresParking = useSelector(selectUserRequiredParking);
  const iconPack = useSelector(selectIconPack);

  const filteredFavouriteOffices = useMemo(() => {
    return !!defaultOffice ? favoriteOffices.filter(fo => fo.officeId !== defaultOffice.id) : (favoriteOffices || []);
  }, [favoriteOffices, defaultOffice]);

  const favoredOfficeEntities = useMemo(() => {
    if (!!filteredFavouriteOffices) {
      return filteredFavouriteOffices.map((fo: FavouriteOfficeEntity) => allOffices.find((o: OfficeEntity) => o.id === fo.officeId)).filter((office: FavouriteOfficeEntity) => !!office);
    } else {
      return [];
    }
  }, [allOffices, filteredFavouriteOffices]);


  const openEditLocationDialog = (e: Event, selectedOffice?: OfficeEntity) => {
    e.stopPropagation();
    dispatch(openLocationDialog({selectedOffice: selectedOffice}));
  }

  const updateSelectedDay = (e: Event, whereabouts: WhereaboutsOption, officeEntity?: OfficeEntity) => {
    e.stopPropagation();
    if (offices.length > 0) {

      const officeEntityChildren = getOfficeChildren(officeEntity?.id, allOffices);
      if (officeEntity && (officeEntityChildren.length > 0 || officeEntity.deskBookingEnabled)) {
        openEditLocationDialog(e, officeEntity);
      } else if (userRequiresParking && doesRootOfficeHaveCarParkingPlan(allOffices, officeEntity)) {
        openEditLocationDialog(e, officeEntity);
      } else {
        dispatch(updateMovement({selectedOption: whereabouts, locationId: officeEntity?.id ?? 0}));
      }

    } else {
      warningNotification("You have no offices setup for hot desking.");
      openEditLocationDialog(e);
    }
  }

  return <>
    {defaultOffice && <CustomOfficeActivity dataTest="whereabouts-default-office" activeDay={props.activeDate}
                                            office={defaultOffice}
                                            isCarParkingEnabledForCompany={isCarParkingEnabledForCompany}
                                            updateSelectedDay={updateSelectedDay}/>}

    {(favoredOfficeEntities.length === 0 && !defaultOffice) && <>
      <div className="activity" onClick={(e: any) => openEditLocationDialog(e)}>
        <MovementImage src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="At the office"/>
        <SpaceBetweenRow style={{padding: 0, alignItems: 'center'}}>
          <Column>
            <BodyRegular weight={600}>In the office</BodyRegular>
          </Column>
          <EditHotDeskingButton />
        </SpaceBetweenRow>
      </div>
    </>}

    {favoriteDesks && favoriteDesks.map((favouriteDesk: FavouriteDesk, key: number) =>
      <FavouritedDeskActivity openEditLocationDialog={openEditLocationDialog}
                              key={key}
                              favouriteDesk={favouriteDesk} />
    )}

    {favoredOfficeEntities && favoredOfficeEntities.map((favouritedOffice: OfficeEntity, key: number) =>
      (<CustomOfficeActivity key={key} activeDay={props.activeDate}
                             office={favouritedOffice}
                             isCarParkingEnabledForCompany={isCarParkingEnabledForCompany}
                             updateSelectedDay={updateSelectedDay}/>))}
  </>
}

interface CustomOfficeActivityProps {
  dataTest?: string;
  activeDay: Moment;
  office: OfficeEntity;
  isCarParkingEnabledForCompany: boolean;
  updateSelectedDay: (e: Event, whereabouts: WhereaboutsOption, officeEntity?: OfficeEntity) => any;
}

function CustomOfficeActivity(props: CustomOfficeActivityProps) {
  const {activeDay, office, updateSelectedDay, isCarParkingEnabledForCompany} = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [capacity, setOfficeCapacity] = useState<CapacityResponse | undefined>(undefined);

  const allOfficeEntities = useSelector(selectAllOfficeEntities);
  const activePeriod = useSelector(selectActivePeriod);
  const iconPack = useSelector(selectIconPack);

  const officeHierarchy = useMemo(() => getFullOfficeHierarchy(allOfficeEntities, [], office), [allOfficeEntities, office]);
  const officeParentName = useMemo(() => officeHierarchy && officeHierarchy.length > 0 ? officeHierarchy[0].label : '', [officeHierarchy]);
  const officeChildName = useMemo(() => officeHierarchy && officeHierarchy.length > 1 ? officeHierarchy.slice(1).map(o => o.label).join(' - ') : '', [officeHierarchy]);


  const fetchCapacity = useCallback((officeId: number, date: Moment) => {
    if (!isLoading) {
      setIsLoading(true);
      getOfficeCapacity(officeId, date)
        .then((resp) => setOfficeCapacity(resp))
        .finally(() => setIsLoading(false));
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !capacity && office.capacity > 0) {
      fetchCapacity(office.id, activeDay);
    }

  }, [isLoading, office, activeDay, capacity, fetchCapacity]);

  useEffect(() => {
    setOfficeCapacity(undefined);
  }, [activeDay])

  const onClick = (e: any) => {
    if (!office.active) {
      dispatch(warningNotification('That space is not available.'));
      return;
    }

    const amUsed = capacity?.amUsed ?? 0;
    const pmUsed = capacity?.pmUsed ?? 0;
    const totalAvailable = capacity?.capacity ?? 0;

    if (isLoading) {
      console.warn('Still loading');
      return;
    }

    if (activePeriod === Period.AM && (office.capacity === 0 || amUsed < totalAvailable)) {
      updateSelectedDay(e, OFFICE_OPTION, office);
    }  else if (activePeriod === Period.PM && (office.capacity === 0 || pmUsed < totalAvailable)) {
      updateSelectedDay(e, OFFICE_OPTION, office);
    }  else if (activePeriod === Period.AllDay && (office.capacity === 0 || (amUsed < totalAvailable && pmUsed < totalAvailable))) {
      updateSelectedDay(e, OFFICE_OPTION, office);
    } else {
      dispatch(warningNotification('That space is at capacity.'));
      console.error('failed to load office capacity')
      if (office.id && activeDay) {
        fetchCapacity(office.id, activeDay);
      }
    }
  }


  let inputAttributes = {};
  if (props.dataTest) {
    // @ts-ignore
    inputAttributes['data-test'] = props.dataTest;
  }

  return <div {...inputAttributes} className={`activity ${(isLoading || !office.active) ? 'activity--disabled' : ''}`.trim()} onClick={onClick}>
    <MovementImage src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="At the office"/>
    <SpaceBetweenRow style={{padding: 0, alignItems: 'center'}}>
      <Column>
        <BodyRegular weight={600}>{officeParentName}</BodyRegular>
        {officeChildName && <BodyVerySmall style={{marginBottom: 8, marginTop: -2}}>{officeChildName}</BodyVerySmall>}
        {office.capacity > 0 && <WhereaboutsCapacityLabel capacity={capacity}
                                                          isCarParkingEnabledForCompany={isCarParkingEnabledForCompany} />}
      </Column>
      <EditHotDeskingButton className="activity__editOfficeButton" office={office}/>
    </SpaceBetweenRow>
  </div>
}
