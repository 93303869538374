import React, { useEffect, useMemo, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { microsoftRequest } from "../../../../services/EnvironmentVariables";
import { AuthenticationResult } from "@azure/msal-common";
import { Column } from '../../../UI/atoms/StructuralLayout';
import { BodyRegular } from '../../../UI/atoms/fonts/Body';
import { SpaceBetweenRow } from '../StyleComponents';
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { getAllUsersRequest } from "../../../../services/UserService";
import { User } from "../../../../models/user.models";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { sendInvite } from "../../../../services/TeamService";
import {
  failureNotification,
  successNotification,
  warningNotification
} from "../../../../store/ducks/notification.duck";
import { useDispatch, useSelector } from "react-redux";
import TeamDropDownList from "../../../UI/organisms/team-drop-down-list/TeamDropDownList";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { selectAllTeams } from "../../../../store/ducks/config.duck";
import { useTranslation } from "react-i18next";

interface AzureUserDescription {
  businessPhones: any[];  // []
  displayName: string;  // "Hello from Team Tracker"
  givenName: string;  // null
  id: string;  // "98f44315-83e3-4c95-be4c-7c7e9c3e7020"
  jobTitle: string;  // null
  mail: string;  // "hello@team-tracker.com"
  mobilePhone: string;  // null
  officeLocation: string;  // null
  preferredLanguage: string;  // null
  surname: string;  // null
  userPrincipalName: string;  // "hello@team-tracker.com"
}

export function MicrosoftActiveDirectorySync(props: Props) {
  const {instance} = useMsal();
  const dispatch = useDispatch();
  const teamList = useSelector(selectAllTeams);
  const [azureUsers, setAzureUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamId, setTeamId] = useState<undefined | string>(undefined);
  const [scopes, setScopes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const {t} = useTranslation();

  const filteredUsers = useMemo(() => {
    return azureUsers.filter((au: AzureUserDescription) => !users.find((u: User) => u.email === au.mail))
  }, [azureUsers, users]);

  useEffect(() => {
    setLoading(true);
    getAllUsersRequest().then(setUsers);
    console.log('MADS.getAllUsersRequest')
    instance.acquireTokenSilent({
      ...microsoftRequest.login,
      scopes: ["email", "User.Read", "profile", "openid", "MailboxSettings.Read", "User.Read.All"],
    }).then((result: AuthenticationResult) => {
        setAccessToken(result.accessToken)
        setScopes(result.scopes);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [instance]);

  useEffect(() => {
    if (accessToken && scopes.includes('User.Read.All') && azureUsers.length === 0) {
      fetch(`https://graph.microsoft.com/v1.0/users`, {
        method: 'GET',
        headers: {
          'Authorization': accessToken
        }
      })
        .then(response => response.json())
        .then(data => {
          const parsedUsers = data.value.filter((user: AzureUserDescription) => !!user.mail)
          setAzureUsers(parsedUsers);
        });
    }
  }, [accessToken, scopes, azureUsers]);

  const increaseScopesWithPopup = () => {
    setLoading(true);
    instance.acquireTokenPopup({
      ...microsoftRequest.login,
      scopes: ["email", "User.Read", "profile", "openid", "MailboxSettings.Read", "User.Read.All"],
    }).then((result: AuthenticationResult) => {
      setAccessToken(result.accessToken);
      setScopes(result.scopes);
    }).finally(() => setLoading(false));
  }

  const addUser = async (user: AzureUserDescription) => {
    if (!teamId) {
      dispatch(warningNotification('Select a team to add users to'));
      return;
    }

    if (user.mail && user.givenName && user.surname && teamId) {
      await sendInvite(user.mail, user.givenName, user.surname, teamId)
      dispatch(successNotification(user.givenName + ' invited'));
    } else {
      dispatch(failureNotification('Failed to invite'));
    }
  }

  const onTeamSelected = (teamId: string) => {
    setTeamId(teamId);
  }

  if (!scopes.includes('User.Read.All')) {
    return (
      <Content>
        <BodyRegular>{t('ms-ad-sync.read-all-message-1')}</BodyRegular>
        <BodyRegular>{t('ms-ad-sync.read-all-message-2')}</BodyRegular>
        <PrimaryButton text={"button.fetch-users-from-microsoft"} click={() => increaseScopesWithPopup()} />
      </Content>
    )
  }

  return (
    <Content>
      {loading && <LoadingSpinner fullScreen={true} />}
      <div style={{marginBottom: 32}}>
        <BodyRegular>{t('ms-ad-sync.message-1')}</BodyRegular>
        <BodyRegular>{t('ms-ad-sync.message-2')}</BodyRegular>
        <BodyRegular>{t('ms-ad-sync.message-3')}</BodyRegular>
      </div>
      <TeamDropDownList teams={teamList} onSelect={onTeamSelected} />
      {filteredUsers.map((user: AzureUserDescription, key) => (
        <SpaceBetweenRow style={{marginBottom: 24}} key={key}>
          <Column>
            <BodyRegular weight={600}>{user.givenName} {user.surname}</BodyRegular>
            <BodyRegular>{user.mail}</BodyRegular>
          </Column>
          <OutlineButton click={() => addUser(user)} text={"button.send-invite"} />
        </SpaceBetweenRow>
      ))}
    </Content>
  )
}

interface Props {
}

const Content = styled.div`
  background-color: ${Colours.white};
  padding: 32px 24px;
`
