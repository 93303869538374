import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { Moment } from "moment";
import { User } from "../../../../../models/user.models";
import { useDispatch, useSelector } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { AppState } from "../../../../../store/state/app.state";
import { selectMeetingRoomById } from "../../../meeting-rooms/models/room-finder.duck";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { MeetingRoom } from "../../../meeting-rooms/models/MeetingRooms";

interface Props {
  currentUser?: User;
  currentDate: Moment;
  meetingRoom: MeetingRoom;
}

export function MeetingRoomSpotOverlay(props: Props) {
  const {meetingRoom} = props;
  const dispatch = useDispatch();
  const meetingRoomDetails = useSelector((appState: AppState) => selectMeetingRoomById(appState, meetingRoom.id));

  const onClick = () => {
    dispatch(openDialogWithPayload({
      payload: { meetingRoomId: meetingRoom.id },
      activeDialog: DialogIdentifiers.AdvanceMeetingRoomBookingDialog
    }))
  }

  if (!meetingRoom.x || !meetingRoom.y || !meetingRoom.width || !meetingRoom.height) {
    return null;
  }

  return (
    <>
      <MeetingRoomOverlay id={`meetingRoom-${meetingRoom.id}`}
                   x={meetingRoom.x} y={meetingRoom.y}
                   height={meetingRoom.height} width={meetingRoom.width}
                   rotate={meetingRoom.rotate ?? 0} onClick={onClick}
                   isVisible={true}>

        {meetingRoomDetails && <BodyLarge weight={600}>{meetingRoomDetails.name}</BodyLarge>}
        {meetingRoomDetails && <BodyRegular>Capacity: {meetingRoomDetails.capacity}</BodyRegular>}

      </MeetingRoomOverlay>
    </>
  )
}

const MeetingRoomOverlay = styled.div<any>`
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
  background-color: ${Colours.transparent};
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  border: ${props => props.isVisible ? `3px solid ${Colours.green}` : `3px solid transparent`};
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: border .1s ease-in-out, background-color .1s ease-in-out;
  padding: 12px 16px;
  border-radius: 16px;
  transform: rotate(${props => props.rotate}deg);
  &:hover {
    background: rgba(255, 255, 255, 0.6);
    mix-blend-mode: normal;
    border: 4px solid ${props => props.disabled ? Colours.darkGrey : Colours.blue};
    box-sizing: border-box;
    border-radius: 8px;
  }
`
