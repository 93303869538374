import React from 'react';
import { HeadlineMedium } from "../../../UI/atoms/fonts/Headline";
import styled from "styled-components/macro";
import { Center } from "../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import Colours from "../../../UI/atoms/Colours";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";

export function MeetingRoomLoadingSpinner(props: Props) {
  if (props.isOutlook) {
    return <Wrapper>
      <HeadlineMedium style={{marginBottom: 28}}>Booking room</HeadlineMedium>
      <LoadingIndicatorContainer>
        <Image alt={"Team Today"} src={"/assets/icons/shape.svg"} />
        <div className="infinateLoading">
          <div className="loadingBar"></div>
        </div>
        <Image alt={"Outlook"} src={"/assets/icons/Outlook.svg"} />
      </LoadingIndicatorContainer>
      <MessageContent>
        <BodyRegular weight={600}>Please wait</BodyRegular>
        <BodyRegular>Communicating with Outlook, this can take up to 30 seconds</BodyRegular>
      </MessageContent>
    </Wrapper>
  } else {
    return <Center>
      <LoadingSpinner hideBorder={true} hideText={true} />
    </Center>
  }
}

interface Props {
  isOutlook: boolean;
}

const Wrapper = styled.div`
  width: 400px;
  padding: 0 40px;
`

const MessageContent = styled.div`
  margin-top: 16px;
  p {
    font-style: normal;
    line-height: 28px;
    text-align: center;
  }
`

const Image = styled.img`
  height: 48px;
`

const LoadingIndicatorContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  
  .infinateLoading {
    width: 100%;
    align-self: center;
    height: 5px;
    margin: 0 24px 0 24px;
    border-radius: 10px;
    position: relative;
    padding: 1px;
  }
  .infinateLoading:before {
    content: '';
    border: 1px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
  .infinateLoading .loadingBar {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: ${Colours.blue};
    width: 0;
    animation: borealisBar 1.2s linear infinite;
  }

  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }
`
