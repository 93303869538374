import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canUserEditOfficesInManagement } from "../../../../../utils/AccessControl";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { selectIsLoading } from "../../../../../store/ducks/editOfficeLayout.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { useParams } from "react-router";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import DialogHeader from "../../../../dialogs/DialogHeader";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import TextField from "../../../../UI/atoms/TextField";
import { Switch } from "../../../../UI/atoms/Switch";
import styled from "styled-components";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { HeaderRow } from "./StructuralLayout";
import { PendingSaveDot } from "../../../../UI/atoms/PendingSaveDot";
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import {
  addDesk,
  EditableDesk,
  editDesk,
  fetchDesks,
  resetEditDesk,
  saveEditDeskChanges,
  selectEditingDesks,
  selectIsEditDesksDirty,
  selectIsEditDesksLoading
} from "../../../../../store/ducks/editDesk.duck";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { Trans, useTranslation } from "react-i18next";

export default function ManageDesksPage() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const loading = useSelector(selectIsLoading);
  const allOffices = useSelector(selectAllOfficeEntities);
  const isEditable = useMemo(() => canUserEditOfficesInManagement(currentUser), [currentUser]);
  const isDirty = useSelector(selectIsEditDesksDirty);
  const isLoading = useSelector(selectIsEditDesksLoading);
  const desks = useSelector(selectEditingDesks);
  const {t} = useTranslation();

  const parentOffice = useMemo(() => {
    return allOffices.find((area: OfficeEntity) => area.id === parseInt(params.officeId))
  }, [allOffices, params.officeId]);

  useEffect(() => {
    if (params.officeId) {
      dispatch(fetchDesks(parseInt(params.officeId)));
    }
    return () => {
      dispatch(resetEditDesk());
    }
  }, [dispatch, params]);

  const updateDeskLabel = (desk: EditableDesk, label: string, active: boolean) => {
    dispatch(editDesk({
      original: desk,
      updated: {
        ...desk, label: label, active: active,
      }
    }));
  }

  const addNewDesk = () => {
    const newDesk = {
      label: '',
      officeId: parentOffice.id,
      rootOfficeId: parentOffice.parentId,
      id: 0,
      active: true,
      dirty: true,
    } as any;
    dispatch(addDesk(newDesk))
  }

  const saveChanges = async () => {
    if (params.officeId) {
      dispatch(saveEditDeskChanges(params.officeId));
    }
  }

  const onDeleteClicked = (desk: EditableDesk) => {
    dispatch(openDialogWithPayload({
      payload: { deskId: desk.id, officeId: desk.officeId },
      activeDialog: DialogIdentifiers.DeleteDeskEntityDialog,
    }));
  }

  return (
    <>
      {(loading || isLoading) && <LoadingSpinner fullScreen={true}/>}

      <div className="management">
        <GoBackButton url={!!parentOffice ? `/manage/hot-desking/edit/${parentOffice.parentId}` : '/manage/hot-desking'} />
        <HeaderRow>
          <DialogHeader title={"textinput.desk-names-and-avail"}/>
        </HeaderRow>

        {parentOffice?.floorPlanEnabled && <>
          <FlatContentCard style={{marginBottom: 24}}>
            <BodyRegular style={{marginBottom: 8}} weight={600}>{t('settings.manage-desk.floor-plans-enabled')}</BodyRegular>
            <BodyRegular><Trans i18nKey={'settings.manage-desk.contact-us-to-change'}
                                components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} /></BodyRegular>
          </FlatContentCard>
        </>}

        {desks.map((desk: EditableDesk, key: number) => <DeskRow key={key}>
          <BodyRegular weight={600}>{t('settings.manage-desk.desk-name')}</BodyRegular>
          <ManageDeskRow>
            <LabelTextFieldContainer>
              <TextField onChange={(val: string) => updateDeskLabel(desk, val, desk.active)}
                         value={desk.label}
                         disabled={!isEditable}
                         fullWidth={true} />
            </LabelTextFieldContainer>
            <Switch value={desk.active}
                    disabled={!isEditable}
                    label={""}
                    onChange={enabled => updateDeskLabel(desk, desk.label, enabled)} />

            {!!desk.id && <div>
              <IconButton icon={IconTypes.Bin} onClick={() => onDeleteClicked(desk)} />
            </div>}


            <PendingSaveDot visible={desk.dirty ?? false} style={{marginLeft: 16}} />
          </ManageDeskRow>
        </DeskRow>)}

        <OutlineButton click={addNewDesk}
                       text={"button.add-new-desk"}
                       dataTest={"add-new-desk-button"}
                       size={"medium"}
                       fullWidth={true}
                       style={{marginBottom: 16, marginTop: 32}}
                       disabled={!isEditable} />

        <PrimaryButton click={saveChanges}
                       text={"button.save-changes"}
                       dataTest={"save-desks-button"}
                       fullWidth={true}
                       size={"large"}
                       disabled={isEditable && !isDirty} />
      </div>
    </>
  )
}

const DeskRow = styled.div`
  margin-bottom: 24px;
`
const ManageDeskRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const LabelTextFieldContainer = styled.div`
  display: flex;
  width: 70%;
  padding-right: 20px;
  .textField {
    margin: 0;
  }
`
