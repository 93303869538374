export const breakpoints: any = {
  mobile: 480,
  largeMobile: 600,
  smallTablet: 700,
  tablet: 767,
  desktopSmall: 900,
  desktop: 1048,
  desktop1100: 1100,
  desktopWide: 1300,
  desktopMidWide: 1400,
  desktopSuperWide: 1500,
}

export const fromMobile = `min-width: ${breakpoints.mobile}px`;
export const largeMobile = `min-width: ${breakpoints.largeMobile}px`;
export const smallTablet = `min-width: ${breakpoints.smallTablet}px`;
export const tablet = `min-width: ${breakpoints.tablet}px`;
export const desktopSmall = `min-width: ${breakpoints.desktopSmall}px`;
export const desktop = `min-width: ${breakpoints.desktop}px`;
export const desktop1100 = `min-width: ${breakpoints.desktop1100}px`;
export const desktopWide = `min-width: ${breakpoints.desktopWide}px`;
export const desktopMidWide = `min-width: ${breakpoints.desktopMidWide}px`;
export const desktopSuperWide = `min-width: ${breakpoints.desktopSuperWide}px`;
