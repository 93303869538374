import React from 'react';
import styled from "styled-components/macro";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { tablet } from "../../UI/atoms/MediaQueries";

export function DayHeaders(props: Props) {
  const user = useSelector(selectCurrentUser);
  return (
    <Row>
      <BodyRegular weight={600}>M</BodyRegular>
      <BodyRegular weight={600}>T</BodyRegular>
      <BodyRegular weight={600}>W</BodyRegular>
      <BodyRegular weight={600}>T</BodyRegular>
      <BodyRegular weight={600}>F</BodyRegular>
      {user?.companyEntity.sevenDayWhereaboutsWeekEnabled && <>
        <BodyRegular weight={600}>S</BodyRegular>
        <BodyRegular weight={600}>S</BodyRegular>
      </>}
    </Row>
  )
}

interface Props {
}

const Row = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  p {
    text-align: center;
    width: 17vw;
    max-width: 100px;
    min-width: 60px;
    @media (${tablet}) {
      max-width: 15%;
      min-width: 66px;
    }
  }
  
`
