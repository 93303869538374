import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './en/translation.json';
import translationFr from './fr/translation.json';
import translationDd from './dd/translation.json';
import { getParameterByName } from "../utils/UrlUtils";

export const defaultNS = 'ns1';

export const resources = {
  en: {
    translation
  },
  fr: {
    translation: translationFr
  },
  dd: {
    translation: translationDd
  }
};

function getLang() {
  return getParameterByName('lang', window.location.href) || 'en';
}

i18next.use(initReactI18next).init({
  lng: getLang(), // if you're using a language detector, do not define the lng option
  debug: false,//process.env.NODE_ENV !== 'production',
  resources,
});
