import React, { useRef, useState } from 'react';
import { Center, Row } from "../../../UI/atoms/StructuralLayout";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { saveWeeklyNotes } from "../../../../services/WeekNotesService";
import { Moment } from "moment";
import styled from "styled-components/macro";
import { WithSingleUserWeekNotes } from "./WithSingleUserWeekNotes";

export function SetWeeklyNote(props: Props) {
  const {userId, teamId, date} = props;
  const [disabled, setDisabled] = useState(false);
  const textRef = useRef<any>(null);

  const weekNote = WithSingleUserWeekNotes(userId, date);

  const save = async () => {
    try {
      setDisabled(true);
      await saveWeeklyNotes(userId, teamId, date, textRef.current.value);
      props.done();
    } finally {
      setDisabled(false);
    }
  }

  const cancel = () => {
    props.done();
  }

  return (
    <div>
      <TextArea ref={textRef} defaultValue={(weekNote?.notes?.note ?? '').replaceAll('', '')} disabled={disabled || weekNote.loading} />

      <Center>
        <Row style={{justifyContent: 'center'}}>
          <OutlineButton text={'button.cancel'} click={() => cancel()}
                         style={{marginRight: 16, maxWidth: '40%', width: 200}}
                         disabled={disabled || weekNote.loading} />
          <OutlineButton text={'button.done'} click={() => save()}
                         style={{marginLeft: 16, maxWidth: '40%', width: 200}}
                         disabled={disabled || weekNote.loading} />
        </Row>
      </Center>
    </div>
  )
}

interface Props {
  done: () => any;
  userId: string;
  teamId: string;
  date: Moment;
}

const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  margin-bottom: 16px;
  padding: 4px 8px;
  font-size: 16px;
  font-family: "soleil", sans-serif;
  white-space: pre-wrap;
`
