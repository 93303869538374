import { agent } from "./agent";
import { BasicUserInfo } from "../models/user.models";

export interface CompanyAdmins {
  companyAdmins: BasicUserInfo[];
  allTeamAdmins: BasicUserInfo[];
}

export function fetchAdmins(): Promise<CompanyAdmins> {
  return agent.requests.get('/api/company/admins');
}

export interface TenantResponse {
  id: string;
  logoUrl: string;
  name: string;
  companyId: string;
}

export function checkTenant(tenantId?: string): Promise<TenantResponse | null> {
  if (!tenantId) return new Promise<TenantResponse | null>((resolve => resolve(null)));
  return agent.requests.get(`/tenant-id/${tenantId}`)
}
