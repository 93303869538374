import React from 'react';
import { UsersHolidayInfo } from "../../models/Holidays.model";
import styled from "styled-components/macro";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { UsersHolidayUsageView } from "../../components/UsersHolidayUsageView";

export function UsersHolidayInfoContentCard(props: Props) {
  const {usersHolidayInfo} = props;

  return (
    <Wrapper>
      <UserAvatarAndName hideName={true} firstName={usersHolidayInfo.firstName} lastName={usersHolidayInfo.lastName} showEditLink={false} />
      <BodyRegular style={{marginBottom: 16}}>{`${usersHolidayInfo.firstName} ${usersHolidayInfo.lastName}`}</BodyRegular>

      <UsersHolidayUsageView usersHolidayInfo={usersHolidayInfo} style={{marginBottom: 16}} />

      <OutlineButton fullWidth={true} link={`/holidays/${usersHolidayInfo.userId}`} text={'holidays.view-holidays'} />

    </Wrapper>
  )
}

interface Props {
  usersHolidayInfo: UsersHolidayInfo;
}

const Wrapper = styled.div`
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: center;
  
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: white;
  box-shadow: 0 9px 22px 0 rgba(46, 74, 148, 0.08);

  min-height: 200px;
`
