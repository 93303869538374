import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge } from '../../../UI/atoms/fonts/Headline';
import AccessRequired from '../components/AccessRequired';
import { selectCurrentUser } from '../../../../store/ducks/auth.duck';
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import { canUserUpdateHolidaySettings } from "../../../../utils/AccessControl";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import IconHeader from "../../../UI/molecules/IconHeader";
import { Button } from "../../../UI/atoms/buttons/Button";
import { reset, saveChanges, selectEditCompanyState } from "./edit-company-page/editCompany.duck";
import HolidayLimitsSetting from "./holiday-settings-page/HolidayLimitsSetting";
import HolidayYearSetting from "./holiday-settings-page/HolidayYearSetting";
import HolidayApprovalsSetting from "./holiday-settings-page/HolidayApprovalsSetting";
import { HolidayBookingSetting } from "../../../UI/organisms/settings/HolidayBookingSetting";
import { Accordian } from "../../../../animations/AnimationVariants";
import { CompanyWideHolidaySetting } from "../components/CompanyWideHolidaySetting";
import {
  HolidayApprovalNotificationSettings
} from "../../../UI/organisms/settings/HolidayApprovalNotificationSettings";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { useTranslation } from "react-i18next";

export default function HolidaySettings() {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);
  const isEditable = useMemo(() => canUserUpdateHolidaySettings(current), [current]);
  const {t} = useTranslation();

  const state = useSelector(selectEditCompanyState);

  useEffect(() => {
    dispatch(reset())
    return () => dispatch(reset());
  }, [dispatch]);

  const done = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 400);
    dispatch(saveChanges());
  };

  return (
    <React.Fragment>
      {loading && <LoadingSpinner fullScreen={true}/>}
      <div className="management">
        <GoBackButton/>
        <IconHeader icon={IconTypes.Holiday} style={{marginBottom: 32}} />
        <HeadlineLarge className="management__title">{t('settings-page.holiday')}</HeadlineLarge>
        <AccessRequired visible={!isEditable} companyAdminsRequired={true} teamAdminsRequired={false}/>

        {isEditable && <>
          <CompanyWideHolidaySetting />

          <HolidayBookingSetting />

          {current?.companyEntity.enableHolidayBooking && <>
            <Accordian isOpen={!!current?.companyEntity.enableHolidayBooking} className="">
              <HolidayYearSetting disabled={!isEditable} onEnableChange={() => {}} />
              <HolidayLimitsSetting disabled={!isEditable} />
              <HolidayApprovalsSetting disabled={!isEditable} />
              <HolidayApprovalNotificationSettings />
            </Accordian>
          </>}

          {isEditable ?
            <Button disabled={!state.dirty} size="large" fullWidth={true} text="button.save-changes" onClick={() => done()}/> : null}
        </>}

      </div>
    </React.Fragment>
  );
}
