import React, { useMemo } from 'react';
import { CapacityResponse } from "../../../services/OfficeCapacityService";
import { BodyRegular } from "../atoms/fonts/Body";
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { SimpleSpinner } from "../atoms/SimpleSpinner";
import styled from "styled-components";
import { tablet } from "../atoms/MediaQueries";
import { useSelector } from "react-redux";
import { selectActivePeriod } from "../../../store/ducks/editMovements.duck";
import { Period } from "../../../models/movements.models";
import { useTranslation } from "react-i18next";

export function OfficeCapacityLabel(props: Props) {
  const {capacity} = props;
  const activePeriod = useSelector(selectActivePeriod);
  const {t} = useTranslation()
  const spacesAvailable = useMemo(() => {
    if (!capacity || !capacity.capacity) return undefined;
    switch (activePeriod) {
      case Period.AllDay: return capacity.capacity - Math.max(capacity.amUsed, capacity.pmUsed);
      case Period.AM: return capacity.capacity - capacity.amUsed;
      case Period.PM: return capacity.capacity - capacity.pmUsed;
    }
  }, [activePeriod, capacity]);

  if (!spacesAvailable) {
    return null;
  }

  return (
    <OfficeCapacityLabelContainer data-test={"capacity-label"}>
      {spacesAvailable !== undefined ?
        <AvailableMessage>{spacesAvailable} {t('general.spaces-available')}</AvailableMessage> :
        <>
          <SimpleSpinner size={16} />
          <AvailableMessage>{t('general.spaces-available')}</AvailableMessage>
        </>
      }


      <Icon size="medium" icon={IconTypes.Chair} style={{marginRight: 16}} />
    </OfficeCapacityLabelContainer>
  )
}

interface Props {
  capacity?: CapacityResponse;
}

const OfficeCapacityLabelContainer = styled.div`
  display: flex;
  align-items: center;
  .simple-spinner {
    margin-right: 4px;
  }
  .icon {
    margin-left: 16px;
  }
`

const AvailableMessage = styled<any>(BodyRegular)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  @media (${tablet}) {
    max-width: unset;
  }
`
