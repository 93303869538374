import React from 'react';
import styled from "styled-components/macro";
import Colours from "./Colours";

export function Tick(props: Props) {
  return (
    <TickImage style={props?.style ?? {}} src={'/assets/images/green-circle-tick.svg'} alt={''} />
  )
}

interface Props {
  style?: any;
}

const TickImage = styled.img`
  background-color: ${Colours.white};
  width: 20px;
  height: 20px;
  align-self: center;
  margin-right: 6px;
`
