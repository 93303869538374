import React, { useEffect, useState } from 'react';
import { BodySmall } from '../../UI/atoms/fonts/Body';
import { getParameterByName } from '../../../utils/UrlUtils';
import { Auth } from '../../../services/Auth';
import { Redirect } from 'react-router';
import AuthWrapper from "./components/AuthWrapper";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { IconTypes } from "../../UI/atoms/icon/Icon";


export default function CompleteRegistration() {
  const id = getParameterByName('reg', window.location.href) || '';
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    if (id) {
      Auth.validateEmail(id)
        .then(() => setIsSuccessful(true))
        .catch(() => setError('Failed'))
    }
  }, [id]);

  if (!id) {
    return <Redirect to="/"/>
  }

  if (isSuccessful) {
    return <Success/>
  }

  if (!isSuccessful && !!error) {
    return <Error/>
  }

  return <Processing/>
}

function Processing() {
  return (
    <AuthWrapper dataTest="completeReg-processing" title="Validating email" hideLoginLink={true} spinner={true}>
    </AuthWrapper>
  )
}

function Success() {
  return (
    <AuthWrapper dataTest="completeReg-success" title="Success" hideLoginLink={true} icon={IconTypes.Tick}>
      <BodySmall className="login__title" style={{marginBottom: 16}}>You’re email was validated, now just login to Team Today to start using it.</BodySmall>
      <PrimaryButton dataTest="completeReg-login-button"
                     link="/login"
                     text="Log in"
                     fullWidth={true}
                     size="large"/>
    </AuthWrapper>
  )
}

function Error() {
  return (
    <AuthWrapper dataTest="completeReg-error" title="Error">
      <BodySmall className="login__title">Try requesting the validation email again</BodySmall>
    </AuthWrapper>
  )
}
