import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import Dialog from "../../../UI/molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { ChooseOfficeControls } from "../components/ChooseOfficeControls";

export default function ChangeOfficeDialog() {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <Dialog isOpen={true} onClose={() => close()}>
      <DialogHeader title={'button.change-office'} />
      <ChooseOfficeControls onSelect={close} />
    </Dialog>
  )
}
