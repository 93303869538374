import { OnboardingScreen } from "../../../../store/ducks/onboarding.duck";


export function onboardingNextFlow(activeScreen: OnboardingScreen) {
  switch (activeScreen) {
    case OnboardingScreen.Welcome:
      return OnboardingScreen.Features;
    case OnboardingScreen.Features:
      return OnboardingScreen.CreateTeam;
    case OnboardingScreen.CreateTeam:
      return OnboardingScreen.SetupOffice;
    case OnboardingScreen.AddMember:
      return OnboardingScreen.CustomWhereabouts;
    case OnboardingScreen.SetupOffice:
      return OnboardingScreen.OfficeAdded;
    case OnboardingScreen.OfficeAdded:
      return OnboardingScreen.AddMember;
    case OnboardingScreen.SetupHolidayAllowance:
      return OnboardingScreen.CustomWhereabouts;
    case OnboardingScreen.Icons:
      return OnboardingScreen.Complete;

    case OnboardingScreen.CustomWhereabouts:
      return OnboardingScreen.Icons;
  }
  console.error('Fell through onboarding with active screen:', activeScreen);
  return OnboardingScreen.Complete;
}

export function onboardingSkipFlow(activeScreen: OnboardingScreen) {
  switch (activeScreen) {
    case OnboardingScreen.SetupOffice:
      return OnboardingScreen.CustomWhereabouts;
  }
  return onboardingNextFlow(activeScreen);
}
