import React, { useMemo } from 'react';
import { Center } from "../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { BodyError, BodyRegular } from "../../../UI/atoms/fonts/Body";
import { WithWeeklyNotes } from "./WithWeeklyNotes";
import { Moment } from "moment";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { groupBy, naturalSort } from "../../../../utils/ArrayUtils";
import { WeeklyTeamNotesView } from "./WeeklyTeamNotesView";
import { useTranslation } from "react-i18next";

export function WeeklyNotesView(props: Props) {
  const weeklyNotesData = WithWeeklyNotes(props.teamIds, props.date)
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const groupedNotes = useMemo(() => {
    if (weeklyNotesData.loading || !weeklyNotesData.hasRequested) return [];
    return groupBy(naturalSort(weeklyNotesData.notes, 'teamName'), 'teamId')
  }, [weeklyNotesData]);

  const groupedNotesKeys = useMemo(() => {
    return Object.keys(groupedNotes);
  }, [groupedNotes]);

  const getTeamNameFromId = (teamId: string) => {
    if (groupedNotes[teamId].length > 0) return groupedNotes[teamId][0].teamName;
    else return `TeamId: ${teamId}`;
  }

  return (
    <>
      {
        weeklyNotesData.loading ? <>
            <Center>
              <LoadingSpinner fullScreen={false}
                              hideText={true}
                              hideBorder={true} />
            </Center>
          </> :
          <>
            {
              weeklyNotesData.notes.length > 0 ? <>
                {groupedNotesKeys.map((groupKey: string, key: number) => (
                  <WeeklyTeamNotesView key={key} teamName={getTeamNameFromId(groupKey)} teamId={groupKey} weeklyNotesData={groupedNotes[groupKey]} />
                ))}
              </> : <>
                {weeklyNotesData.hasRequested ? <>
                  <Center>
                    <BodyRegular>{t('dialog.no-week-notes')}</BodyRegular>
                  </Center>

                </> : <>
                  <Center>
                    <BodyRegular>{t('dialog.week-note-request-note-made')}</BodyRegular>
                  </Center>
                </>}
              </>
            }

            <Center style={{marginTop: 24}}>
              {currentUser?.teamEntity ?
                <OutlineButton text={'dialog.add-note'} click={() => props.onEditClick()} /> :
                <BodyError>{t('dialog.week-notes-not-in-a-team')}</BodyError>
              }
            </Center>

          </>
      }
    </>
  )
}

interface Props {
  teamIds: string[];
  date: Moment;
  onEditClick: () => any;
}
