import React, { useMemo } from 'react';
import { AccountInfo } from "@azure/msal-common";
import { microsoftRequest } from "../../../services/EnvironmentVariables";
import { AuthType, clearLoginStorage, GetMicrosoftLoginAccount, teamsLogin } from "../../../utils/AuthUtils";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { TrackJS } from "trackjs";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { setAuthLoading } from "../../../store/ducks/auth.duck";
import { NotificationMessages } from "../../../models/notification.models";
import { loginActions } from "./login.duck";
import { enrichError } from "../../../utils/error-helper";

export function MicrosoftTeamsLoginButton(props: Props) {
  const {instance} = useMsal();
  const dispatch = useDispatch();
  const accounts = useMemo(() => instance.getAllAccounts(), [instance]);

  const onClick = async (account?: AccountInfo) => {
    clearLoginStorage();
    localStorage.setItem('type', AuthType.Microsoft);
    dispatch(setAuthLoading(true));

    dispatch(loginActions.clearSmartError());

    try {
      const loginAccount = GetMicrosoftLoginAccount(instance, account);
      await teamsLogin(instance, microsoftRequest.login.scopes, dispatch, true, true, loginAccount);
    } catch (e: any) {
      if (e.toString().includes('User not registered')) {
        dispatch(loginActions.setSmartError(enrichError(NotificationMessages.noAccountFound)));
      } else if (e.toString().includes('CancelledByUser')) {
        dispatch(loginActions.setSmartError(enrichError(NotificationMessages.cancelledByUser)));
      } else {
        console.log(e.toString())
        TrackJS?.track(e);
        dispatch(loginActions.setSmartError(enrichError(NotificationMessages.unknownError)));
      }
    } finally {
      dispatch(setAuthLoading(false));
    }
  }

  if (accounts && accounts.length > 1) {
    return (
      <>
        {accounts.map((account, key) => <OutlineButton key={key}
                                                       dataTest="outlookLogin"
                                                       className="login__button"
                                                       text={`login.with-office365`}
                                                       subtext={account.username}
                                                       click={() => onClick(account)}
                                                       fullWidth={true}
                                                       size="large" imageUrl="/assets/icons/microsoft-office-logo.svg" />)}
      </>
    )
  } else {
    return (
      <>
        <OutlineButton dataTest="outlookLogin" className="login__button" text="login.with-office365"
                       click={() => onClick()} fullWidth={true} size="large"
                       imageUrl="/assets/icons/microsoft-office-logo.svg"/>
      </>
    )
  }

}

interface Props {
  onError: (message: string) => void;
}
