import React, { useEffect, useMemo, useState } from 'react';
import './desk-availability-row.scss';
import { MovementsDailies, MovementsDayContainer, MovementsName, MovementsRow } from "../StructuralComponents";
import { BodyRegular } from "../../../../atoms/fonts/Body";
import styled from "styled-components/macro";
import Colours from "../../../../atoms/Colours";
import { smallTablet } from "../../../../atoms/MediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { selectOffices } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectCalendarDate } from "../../../../../../store/ducks/dashboard.duck";
import {
  loadWeeklyAvailability,
  selectWeeklyAvailability
} from "../../../../../../store/ducks/weeklyDeskAvailability.duck";
import { CapacityResponse } from "../../../../../../services/OfficeCapacityService";
import Chair from '../../../../../../images/icons/Chair.svg';
import { useTranslation } from "react-i18next";

export default function DeskAvailability(props: { className?: string }) {
  const rootOffices = useSelector(selectOffices);
  const date = useSelector(selectCalendarDate);
  const weeklyAvailability = useSelector(selectWeeklyAvailability);
  const filteredWeeklyAvailability = useMemo(() => weeklyAvailability.filter(wa => !!wa && !!wa.date), [weeklyAvailability]);
  const [isRequested, setIsRequested] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const showOfficeAvailability = useMemo(() => rootOffices.length === 1 && rootOffices[0].capacityEnabled, [rootOffices]);

  useEffect(() => {
    setIsRequested(false);
  }, [date]);

  useEffect(() => {
    if (showOfficeAvailability && !isRequested) {
      setIsRequested(true)
      dispatch(loadWeeklyAvailability(date));
    }
  }, [date, dispatch, showOfficeAvailability, isRequested]);

  if (showOfficeAvailability) {
    return (
      <DeskRow className={props?.className ?? ''}>
        <Label>
          <img src={Chair} alt="Chair"/>
          <BodyRegular>{t('general.desks-available')}</BodyRegular>
        </Label>
        <DeskDailies>
          {filteredWeeklyAvailability.map((deskUsage: CapacityResponse, key) => (
            <DeskDay key={key} title={deskUsage.date}>
              <div className="userRow__desks__number">{deskUsage.capacity - Math.max(deskUsage.amUsed, deskUsage.pmUsed)}</div>
            </DeskDay>
          ))}
        </DeskDailies>
      </DeskRow>
    )
  } else {
    return null;
  }
}

export const DeskRow = styled<any>(MovementsRow)`
  border: none;
  border-radius: 8px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  &:hover {
    background-color: ${Colours.veryLightGrey};
  }

  @media (${smallTablet}) {
    background-color: ${Colours.veryLightGrey};
    flex-direction: row;
    padding: 4px 16px;
  }
`

export const DeskDay = styled<any>(MovementsDayContainer)`
  height: unset;
  min-height: unset;
  @media (${smallTablet}) {
    display: flex;
    justify-content: center;
    min-height: 20px;
  }
`

export const DeskDailies = styled<any>(MovementsDailies)`
  margin: 0;
  padding-top: 5px;
`

export const Label = styled<any>(MovementsName)`
  padding: 8px;
  display: flex;
  flex-direction: row !important;

  img {
    justify-content: flex-start;
    align-items: center;
    margin-right: 12px;
  }

  @media (${smallTablet}) {
    justify-content: flex-start;
    width: 32%;
  }
`
