import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectedOffice } from "../../../../../store/ducks/officeView.duck";
import { getFullOfficeTitleById } from "../../../../../utils/OfficeHelper";
import styled from "styled-components/macro";
import DateFlicker, { DateScale } from "../../../../UI/molecules/DateFlicker";
import { PRETTY_DATE_FORMAT } from "../../../../../utils/DateUtils";
import { Moment } from "moment";
import { selectActiveDay, setActiveDay } from "../../../../../store/ducks/editMovements.duck";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { PageTitle } from "../../../../UI/atoms/PageTitle";
import { desktop, tablet } from "../../../../UI/atoms/MediaQueries";
import { DialogIdentifiers, openDialog } from "../../../../../store/ducks/dialog.duck";

export function FloorMapHeader(props: Props) {
  const office = useSelector(selectedOffice);
  const allOffices = useSelector(selectAllOfficeEntities);
  const activeDay = useSelector(selectActiveDay);
  const dispatch = useDispatch();

  const officeLabel = useMemo(() => {
    if (!office) return '';
    return getFullOfficeTitleById(office.id, allOffices)
  }, [allOffices, office]);

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <OfficeInfo>
            <PageTitle overrideText={officeLabel} text={'office-map.select-office'} />
            <ChangeOfficeButton text={'button.change-office'} size={"small"}
                                click={() => dispatch(openDialog(DialogIdentifiers.ChangeOfficeDialog))} />
          </OfficeInfo>
          <DateFlicker dateScale={DateScale.Day}
                       onChange={(date: Moment) => dispatch(setActiveDay(date))}
                       updateGlobalDate={true}
                       date={activeDay}
                       showDate={true} className={'floorMapHeader__datePicker'}
                       dateInTheMiddle={true}
                       dateFormat={PRETTY_DATE_FORMAT} />
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}

interface Props {
  onSideBarOpen: (val: boolean) => void;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  h3 {
    margin-top: 16px;
  }
  .floorMapHeader__datePicker {
    margin-top: 16px;
    @media (${desktop}) {
      align-self: center;
    }
  }
  @media (${desktop}) {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    padding: 12px 310px 12px 0;
    .floorMapHeader__datePicker {
      align-self: flex-end;
      margin-top: 0;
    }
    .pageTitle {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
    h3 {
      margin-top: 0;
    }
    .floorMapHeader__sideBarButton {
      display: none;
    }
  }
  @media (min-width: 1100px) { padding-right: 340px; }
  @media (min-width: 1400px) { padding-right: 340px; }
  @media (min-width: 1450px) { padding-right: 325px; }
  @media (min-width: 1500px) { padding-right: 310px; }
  @media (min-width: 1550px) { padding-right: 280px; }
  @media (min-width: 1600px) { padding-right: 250px; }
  @media (min-width: 1650px) { padding-right: 220px; }
  @media (min-width: 1700px) { padding-right: 190px; }
  @media (min-width: 1750px) { padding-right: 170px; }
  @media (min-width: 1800px) { padding-right: 140px; }
  @media (min-width: 1850px) { padding-right: 120px; }
  @media (min-width: 1900px) {
    padding-right: 100px;
  }
  @media (min-width: 2100px) {
    padding-right: 0;
  }
`

const Container = styled.div`
  padding: 0 16px;
  .floorMapHeader__datePicker {
    @media (${tablet}) {
      max-width: calc(100vw - 335px);
    }
    @media (${desktop}) {
      max-width: 300px;
    }
  }
  .floorMapHeader__datePicker .weekFlicker__control {
    padding: 8px 0;
  }
`
const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
`

const ChangeOfficeButton = styled<any>(OutlineButton)<any>`
  align-self: center;
  margin-top: 18px;
  @media (${desktop}) {
    display: none;
  }
`

const OfficeInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (${tablet}) {
    display: row;
  }
`
