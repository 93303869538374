import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NotificationType, selectIsNotification } from '../../../../store/ducks/notification.duck';
import { BodyRegular } from '../../atoms/fonts/Body';
import './notification.scss';

export default function Notification() {
  const state = useSelector(selectIsNotification);

  const cssClasses = useMemo(() => {
    let classes = ' ';
    if (state.iconColour) classes += ` notification--${state.iconColour}`;
    if (state.isOpen) classes += ` notification--open`;

    return classes;
  }, [state]);

  if (state.type === NotificationType.Success) {
    return <div className={`notification notification--success ${cssClasses}`}>
      <img src={"/assets/icons/notification-success.svg"} alt={"Success"} />
      <div className={'notification__messageContainer'}>
        {state.title && <BodyRegular weight={600}>{state.title}</BodyRegular>}
        {state.message && <BodyRegular className="notification__message">{state?.message}</BodyRegular>}
      </div>
    </div>
  } else if (state.type === NotificationType.Warning) {
    return <div className={`notification notification--warning ${cssClasses}`}>
      <img src={"/assets/icons/notification-alert.svg"} alt={"Warning"} />
      <div className={'notification__messageContainer'}>
        {state.title && <BodyRegular weight={600}>{state.title}</BodyRegular>}
        {state.message && <BodyRegular className="notification__message">{state?.message}</BodyRegular>}
      </div>
    </div>
  } else if (state.type === NotificationType.Error) {
    return <div className={`notification notification--error ${cssClasses}`}>
      <img src={"/assets/icons/notification-error.svg"} alt={"Error"} />
      <div className={'notification__messageContainer'}>
        {state.title && <BodyRegular weight={600}>{state.title}</BodyRegular>}
        {state.message && <BodyRegular className="notification__message">{state?.message}</BodyRegular>}
      </div>
    </div>
  }

  return null;
}
