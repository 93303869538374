import React from 'react';
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { BodyXXSmall } from "../atoms/fonts/Body";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectAllMeetingRooms } from "../../pages/settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { Link } from "react-router-dom";

interface Props {
}

export function MeetingRoomRowLink(props: Props) {
  const meetingRooms = useSelector(selectAllMeetingRooms);
  const {t} = useTranslation();

  if (meetingRooms.length === 0) return null;

  return (
    <Link to={"/meeting-rooms"} style={{textDecoration: 'none'}}>
      <SpaceBetweenRow>
        <Icon size={'mediumlarge'} icon={IconTypes.Time} />
        <BodyXXSmall weight={600}>{t('settings-page.meeting-rooms')}</BodyXXSmall>
      </SpaceBetweenRow>
    </Link>)
}


const SpaceBetweenRow = styled.div` 
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    margin-right: 16px;
  }
`
