import { agent } from './agent';
import moment, { Moment } from "moment";
import { CompanyHolidayUsageSummary, HolidayBooking } from "../models/Holiday.model";
import {
  HolidayGroupBookingRequest,
  HolidayGrouping,
  UserHolidayInfoResponse,
  UsersHolidayInfo
} from "../components/pages/holidays-v2/models/Holidays.model";
import { DATE_FORMAT } from "../utils/DateUtils";
import { BasicUserInfo, UserListItem } from "../models/user.models";
import { CompanyHoliday } from "./CompanyHolidayService";
import { naturalComparator } from "../utils/ArrayUtils";


export const LINE_REPORT_ID = 'lineReports';
export const INDIRECT_LINE_REPORT_ID = 'indirectLineReports';

export async function requestUsersHolidayOverview(): Promise<UserHolidayOverviewResponse> {
  const holidayOverview = await agent.requests.get('/api/holidays');
  return {
    ...holidayOverview,
    holidays: holidayOverview.holidays.map((booking: HolidayBookingEntity) => new HolidayBooking(booking))
  }
}

export async function postNewHoliday(name: string, emailNotification: boolean, from: Moment, to: Moment): Promise<HolidayBooking> {
  const booking: HolidayBookingEntity = await agent.requests.post('/api/holidays', {
    from: from.format('YYYY-MM-DD'),
    to: to.format('YYYY-MM-DD'),
    name: name,
    emailNotification: emailNotification,
  }, true);
  return new HolidayBooking(booking);
}

export async function updateNewHoliday(id: number, name: string, emailNotification: boolean, from: Moment, to: Moment): Promise<HolidayBooking> {
  const booking: HolidayBookingEntity = await agent.requests.put('/api/holidays', {
    id: id,
    from: from.format('YYYY-MM-DD'),
    to: to.format('YYYY-MM-DD'),
    name: name,
    emailNotification: emailNotification,
  }, true);
  return new HolidayBooking(booking);
}

export async function getHolidayBooking(id: number): Promise<HolidayBooking> {
  const booking = await agent.requests.get(`/api/holiday/${id}`);
  return new HolidayBooking(booking);
}

export async function getUpcomingHolidays(): Promise<HolidayBooking[]> {
  return await agent.requests.get(`/api/holidays/upcoming`);
}

export async function deleteHolidayBookingRequest(id: number) {
  return await agent.requests.del(`/api/holiday/${id}`);
}

export async function fetchAllUsersHolidaysInCompany(year: number): Promise<CompanyHolidayUsageSummary> {
  return await agent.requests.get(`/api/v2/holidays/company-usage?year=${year}`);
}

export async function fetchAllUsersHolidaysInTeam(year: number, teamId: string): Promise<CompanyHolidayUsageSummary> {
  return await agent.requests.get(`/api/v2/holidays/team-usage?year=${year}&teamId=${teamId}`);
}

export async function fetchUsersHolidayInfoV2(year?: number, userId?: string): Promise<UserHolidayInfoResponse> {
  let params = `year=${year ?? moment().year()}`
  if (userId) {
    params += `&userId=${userId}`;
  }
  return await agent.requests.get(`/api/v2/holidays/user?${params}`);
}

export async function bookHolidayV2(groupBookingRequest: HolidayGroupBookingRequest): Promise<void> {
  return await agent.requests.post(`/api/v2/holidays/book`, groupBookingRequest);
}

export async function deleteHolidayV2(groupBookingRequest: HolidayGroupBookingRequest): Promise<void> {
  return await agent.requests.post(`/api/v2/holidays/delete`, groupBookingRequest);
}

export async function getTeamHolidays(teamIds: string[], workGroupIds: number[], date: Moment): Promise<MonthlyHolidayView[]> {
  const result = await agent.requests.post(`/api/v2/holidays/team`, {
    teamIds: teamIds,
    workGroupIds: workGroupIds,
    date: date.format(DATE_FORMAT)
  });

  return result.sort((a: MonthlyHolidayView, b: MonthlyHolidayView) => naturalComparator(a.user, b.user, 'firstName'))
}

export async function getUsersHolidaysCanBeAddedTo(): Promise<UserListItem[]> {
  return await agent.requests.get('/api/v2/holidays/users')
}

export async function getUsersHolidayInfoForGroup(group: string, date: Moment): Promise<UsersHolidayInfo[]> {
  return await agent.requests.get(`/api/v2/holidays/user-info?group=${group}&date=${date.format(DATE_FORMAT)}`)
}

export async function getUsersHolidayUsage(userId: string, dateString: string): Promise<UsersHolidayInfo> {
  return await agent.requests.get(`/api/v2/holidays/user-info/${userId}/${dateString}`)
}

export async function updateHolidayAllowance(holidayAllowanceRequest: HolidayAllowanceRequest) {
  return await agent.requests.put(`/api/holiday/allowances`, holidayAllowanceRequest)
}

export interface HolidayAllowanceRequest {
  holidayYear: number;
  userId: string;
  basicAllowance: number;
  additionalDays: number;
}

export interface UserHolidayOverviewResponse {
  holidays: HolidayBooking[];
  daysTaken: number;
  daysRemaining: number;
  totalAllowance: number;
}

export interface HolidayBookingEntity {
  id: number;
  name: string;
  emailNotification: boolean;
  fromDate: string;
  toDate: string;
  bookerName: string;
}

export interface MonthlyHolidayView {
  user: BasicUserInfo;
  holidayBookings: HolidayBooking[];
  holidayGroupings: HolidayGrouping[];
  companyHolidays: CompanyHoliday[];
}
