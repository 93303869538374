import React from 'react';
import { FreeBusyStatus, WhereaboutsOption } from "../../../services/WhereaboutOptions";
import styled from "styled-components";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";
import { IconPack } from "../../../models/company.models";

export interface MovementIconProps {
  option: WhereaboutsOption;
  iconPack: IconPack;
  onClick: () => any;
}

export function BasicMovementIcon(props: MovementIconProps) {
  const {option, onClick, iconPack} = props;
  return (
    <MovementIconContainer className={`movement__icon--${props.option.colour.toLowerCase()}`.trim()}
         onClick={onClick}
         aria-hidden="true">
      {!!option.image && <img src={iconPackPath(iconPack, option.image, FreeBusyStatus.FREE)} alt={option.alt} />}
    </MovementIconContainer>
  )
}

const MovementIconContainer = styled.div`
  width: 92px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  img {
    width: 60px;
    padding: 8px;
  }
`;
