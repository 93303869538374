import React from 'react';
import { TeamWithCount } from '../../../../models/team.models';
import { BodySmall } from '../../atoms/fonts/Body';
import './team-drop-down-list.scss';
import Colours from "../../atoms/Colours";
import { useTranslation } from "react-i18next";

const NO_OPTION = '-- select a team --';

export const ALL_TEAMS = 'ALL';

export default function TeamDropDownList(props: Props) {
  const { onSelect, selectedTeamId, className, label } = props;
  const teams = props.teams ?? [];
  const {t} = useTranslation();

  const onItemSelected = (e: any) => {
    if (e.target.value && e.target.value !== NO_OPTION) {
      onSelect(e.target.value)
    }
  }

  return (
    <>
      {!!label && <BodySmall colour={Colours.darkGrey} weight={600} className="teamDropDownList__label">{t(label)}</BodySmall>}
      <select disabled={props.disabled}
              className={`teamDropDownList ${className ?? ''}`.trim()}
              name="Team" value={selectedTeamId}
              onChange={onItemSelected}>
        {props.includeAllTeams ? <option value={ALL_TEAMS}>All teams</option> : <option>{NO_OPTION}</option>}
        {teams.map((t, k) => <option key={k} value={t.id}>{t.name}</option>)}
      </select>
    </>
  )
}


interface Props {
  teams: TeamWithCount[],
  onSelect: (teamId: string) => any,
  selectedTeamId?: string,
  className?: string,
  label?: string,
  disabled?: boolean,
  includeAllTeams?: boolean,
}
