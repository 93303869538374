import React, { useMemo } from 'react';
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { UserInfo } from "../../../../models/user.models";
import { Checkbox } from "../../../UI/atoms/Checkbox";
import { Row } from "../../../UI/atoms/StructuralLayout";
import { FireWardenBadge } from "../../../UI/atoms/FireWardenBadge";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from '../../../../store/ducks/editUser.duck';
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { canChangeKeyResponsibilities } from "../../../../utils/AccessControl";
import { useTranslation } from "react-i18next";

interface Props {
  user: UserInfo;
}

export function AdditionalResponsibilitiesSection(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onFirstAiderChange = async (val: boolean) => dispatch(updateUser({ ...props.user, firstAider: val, }));
  const onFireWardenChange = async (val: boolean) => dispatch(updateUser({ ...props.user, fireWarden: val, }));
  const onMentalHealthChange = async (val: boolean) => dispatch(updateUser({ ...props.user, mentalHealthAider: val, }));
  const onKeyHolderChange = async (val: boolean) => dispatch(updateUser({ ...props.user, keyHolder: val, }));

  const canEdit = useMemo(() => canChangeKeyResponsibilities(currentUser), [currentUser])

  return (
    <Wrapper className="" data-test="additional-responsibilities-section">
      <Row style={{alignItems: 'center', marginBottom: 12}}>
        <BodyLarge colour={Colours.blue}>{t('settings-page.edit-user.additional-responsibilities')}</BodyLarge>
        <FireWardenBadge style={{marginLeft: 16}} />
      </Row>
      <BodyRegular style={{marginBottom: 16}}>{t('settings-page.edit-user.additional-responsibilities-message')}</BodyRegular>
      <WrappedList>
        <CheckboxWrapper>
          <Checkbox value={props.user.firstAider} onChange={onFirstAiderChange} text="checkbox.first-aider" disabled={!canEdit} dataTest="checkbox-first-aider" />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox value={props.user.fireWarden} onChange={onFireWardenChange} text="checkbox.fire-warden" disabled={!canEdit} dataTest="checkbox-fire-warden" />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox value={props.user.mentalHealthAider} onChange={onMentalHealthChange} text="checkbox.mental-first-aider" disabled={!canEdit} dataTest="checkbox-mental-health-aider" />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox value={props.user.keyHolder} onChange={onKeyHolderChange} text="checkbox.key-holder" disabled={!canEdit} dataTest="checkbox-key-holder" />
        </CheckboxWrapper>
      </WrappedList>
    </Wrapper>
  )
}

const CheckboxWrapper = styled.div`
  margin-right: 64px;
  margin-bottom: 16px;
  &:last-child {
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  .teamDropDownList {
    width: 100%;
  }
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 24px;
  padding-bottom: 12px;
`

const WrappedList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`
