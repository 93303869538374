import React from 'react';
import { DAY_WIDTH, WEEK_WIDTH } from '../WallPlannerStructuralComponents';
import styled, { css } from "styled-components/macro";
import { daysBetween } from "../../../../utils/DateUtils";
import Colours from "../../../UI/atoms/Colours";
import { isBusy, isBusyInWork, isWorkingFromOffice } from "../../../../utils/Movement";
import { UNKNOWN_OPTION } from "../../../../services/WhereaboutOptions";
import { Moment } from "moment";
import { Period } from "../../../../models/movements.models";
import { WhereaboutsIconOutline } from "../../../UI/atoms/WhereaboutsIconOutline";
import { IconPack } from "../../../../models/company.models";
import { useDispatch } from "react-redux";
import { setIsVisible, setPositionAndMakeVisible } from "../data/WallPlannerHoverBox.duck";
import { UsersWorkBlock } from "../../../../models/wall-planner.models";

interface Props {
  whereabouts: UsersWorkBlock;
  firstDay: Moment;
  sideOfDay: Period;
  iconPack: IconPack;
  style?: any;
}

export function ActivityBlockV2(props: Props) {
  const {whereabouts, firstDay, style, sideOfDay} = props;
  const option = sideOfDay === Period.PM ? whereabouts.amOption : whereabouts.pmOption;

  if (!option || (option.key || UNKNOWN_OPTION.key) === UNKNOWN_OPTION.key) {
    return (
      <PendingWorkingFill start={daysBetween(whereabouts.date, firstDay)}
                          days={daysBetween(whereabouts.toDate, whereabouts.fromDate) + 1}
                          title="Whereabouts waiting to be confirmed" style={style ?? {}}>

      </PendingWorkingFill>
    )
  } else if (isBusy(option)) {
    return (
      <HolidayActivity start={daysBetween(whereabouts.date, firstDay)}
                       days={daysBetween(whereabouts.toDate, whereabouts.fromDate) + 1}
                       iconPack={props.iconPack}
                       activity={whereabouts}
                       sideOfDay={sideOfDay} style={style ?? {}} />
    )
  } else if (isBusyInWork(option)) {
    return (
      <BusyWorkingCell start={daysBetween(whereabouts.date, firstDay)}
                       days={daysBetween(whereabouts.toDate, whereabouts.fromDate) + 1}
                       iconPack={props.iconPack}
                       sideOfDay={sideOfDay}
                       activity={whereabouts}
                       style={style ?? {}} />
    )
  }  else if (isWorkingFromOffice(option)) {
    return (
      <WorkingLightGreen start={daysBetween(whereabouts.date, firstDay)}
                         iconPack={props.iconPack}
                         days={daysBetween(whereabouts.toDate, whereabouts.fromDate) + 1}
                         sideOfDay={sideOfDay}
                         activity={whereabouts}
                         style={style ?? {}} />
    )
  } else {
    return (
      <WorkDarkGreen start={daysBetween(whereabouts.date, firstDay)}
                     days={daysBetween(whereabouts.toDate, whereabouts.fromDate) + 1}
                     sideOfDay={sideOfDay}
                     iconPack={props.iconPack}
                     activity={whereabouts}
                     style={style ?? {}} />
    )
  }
}

interface WorkingFillProps {
  activity: UsersWorkBlock;
  sideOfDay: Period;
  start: number;
  days: number;
  style?: any;
  hovering?: boolean;
  iconPack: IconPack;
}

function WorkDarkGreen(props: WorkingFillProps) {
  const dispatch = useDispatch();
  const onHover = (e: any, state: boolean) => {
    if (state) {
      const rect = e.target.getBoundingClientRect();
      const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      dispatch(setPositionAndMakeVisible({x: rect.left, y: rect.bottom + offsetTop, userId: props.activity.userId, fromDate: props.activity.date}))
    } else {
      dispatch(setIsVisible(false))
    }
  }
  return <ConfirmedWorkingFill start={props.start} days={props.days}
                               style={props?.style ?? {}}
                               onMouseOver={(e: any) => onHover(e, true)}
                               onMouseLeave={(e: any) => onHover(e, false)}
                               onTouchStart={(e: any) => onHover(e, true)}
                               onTouchEnd={(e: any) => onHover(e, false)}
                               onTouchCancel={(e: any) => onHover(e, false)}>
    <WorkingCell {...props} />
  </ConfirmedWorkingFill>
}

function HolidayActivity(props: WorkingFillProps) {
  const dispatch = useDispatch();
  const onHover = (e: any, state: boolean) => {
    if (state) {
      const rect = e.target.getBoundingClientRect()
      const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      dispatch(setPositionAndMakeVisible({x: rect.left, y: rect.bottom + offsetTop, userId: props.activity.userId, fromDate: props.activity.date}))
    } else {
      dispatch(setIsVisible(false))
    }
  }
  return <HolidayActivityFill {...props}
                               onMouseOver={(e: any) => onHover(e, true)}
                               onMouseLeave={(e: any) => onHover(e, false)}
                               onTouchStart={(e: any) => onHover(e, true)}
                               onTouchEnd={(e: any) => onHover(e, false)}
                               onTouchCancel={(e: any) => onHover(e, false)}>
    <WhereaboutsIconOutline sideOfDay={props.sideOfDay} activity={props.activity} />
  </HolidayActivityFill>
}

function WorkingLightGreen(props: WorkingFillProps) {
  const dispatch = useDispatch();
  const onHover = (e: any, state: boolean) => {
    if (state) {
      const rect = e.target.getBoundingClientRect()
      const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      dispatch(setPositionAndMakeVisible({x: rect.left, y: rect.bottom + offsetTop, userId: props.activity.userId, fromDate: props.activity.date}))
    } else {
      dispatch(setIsVisible(false))
    }
  }
  return <ConfirmedWorkingFromHomeFill start={props.start} days={props.days}
                                       style={props?.style ?? {}}
                                       onMouseOver={(e: any) => onHover(e, true)}
                                       onMouseLeave={(e: any) => onHover(e, false)}
                                       onTouchStart={(e: any) => onHover(e, true)}
                                       onTouchEnd={(e: any) => onHover(e, false)}
                                       onTouchCancel={(e: any) => onHover(e, false)}>
    <WorkingCell {...props} />
  </ConfirmedWorkingFromHomeFill>
}

function BusyWorkingCell(props: WorkingFillProps) {
  const dispatch = useDispatch();
  const onHover = (e: any, state: boolean) => {
    if (state) {
      const rect = e.target.getBoundingClientRect()
      const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      dispatch(setPositionAndMakeVisible({x: rect.left, y: rect.bottom + offsetTop, userId: props.activity.userId, fromDate: props.activity.date}))
    } else {
      dispatch(setIsVisible(false))
    }
  }
  return <BusyInWorkFill start={props.start} days={props.days}
                         style={props?.style ?? {}}
                         onMouseOver={(e: any) => onHover(e, true)}
                         onMouseLeave={(e: any) => onHover(e, false)}
                         onTouchStart={(e: any) => onHover(e, true)}
                         onTouchEnd={(e: any) => onHover(e, false)}
                         onTouchCancel={(e: any) => onHover(e, false)}>
    <WorkingCell {...props} />
  </BusyInWorkFill>
}

function WorkingCell(props: WorkingFillProps) {
  const {activity, sideOfDay} = props;
  return <>
    {activity.collection.map((m: any, k: number) => <WhereaboutsIconOutline key={k} sideOfDay={sideOfDay} activity={m} />)}
  </>
}

export const ActivityFill = styled.div<any>`
  position: absolute;
  height: 32px;
  align-self: center;
  border-radius: 4px;
  transition: background .25s;
  display: flex;
  align-items: center;
  padding-left: 4px;
  user-select: none;
  ${props => css`left: ${props.start ? ((props.start * (DAY_WIDTH) + 2) + WEEK_WIDTH + (props.startsWithHalfDay ? DAY_WIDTH * 0.5 : 0)) + 'px' : (WEEK_WIDTH + 2) + 'px'};`};
  ${props => css`width: ${props.days ? (props.days * (DAY_WIDTH) - 6 - ((props.startsWithHalfDay || props.endsWithHalfDay) ? DAY_WIDTH * 0.5 : 0)) + 'px' : 0};`};
  .whereabouts__outlineIcon {
    max-height: 80% !important;
  }
`

export const ConfirmedWorkingFill = styled<any>(ActivityFill)<any>`
  background: #BDFAC8;
  border: 1px solid #4DCD67;
`

export const ConfirmedWorkingFromHomeFill = styled<any>(ActivityFill)<any>`
  background: #E3FFAB;
  border: 1px solid #4DCD67;
`

export const PendingWorkingFill = styled<any>(ActivityFill)<any>`
  background: #F9FFDD;
  border: 1px solid #BCEB29;
`

export const BusyInWorkFill = styled<any>(ActivityFill)<any>`
  background: #FFF6A1;
  border: 1px solid #FFD027;
`

export const HolidayActivityFill = styled<any>(ActivityFill)<any>`
  background: ${props => !!props.fill ? props.fill : Colours.veryLightGrey};
  color: ${props => !!props.textColour ? props.textColour : Colours.black};
  border: 1px solid ${props => !!props.outline ? props.outline : '#A6B4B4'};
  transition: border .1s ease-in-out;
  cursor: pointer;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icon {
    color: ${props => !!props.textColour ? props.textColour : Colours.black} !important;
  }
  &:hover, &:active {
    border: 1px solid ${Colours.blue};
    .popup {
      display: flex;
    }
  }
`
