import React from 'react';
import { BodySmall } from "./fonts/Body";
import Colours from "./Colours";
import Icon, { IconTypes } from "./icon/Icon";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

export enum ChipMode {
  Add, Edit, None,
}

interface Props {
  label: string;
  onClick: () => any;
  mode?: ChipMode;
  icon?: IconTypes;
  isActive?: boolean;
  dataTest?: string;
  size?: 'verysmall' | 'small' | 'medium' | 'large';
}


export function Chip(props: Props) {
  const {label, onClick} = props;
  const {t} = useTranslation();
  return (
    <ChipWrapper onClick={onClick} mode={props.mode} isActive={props.isActive}>
      <BodySmall style={{marginRight: 16}} colour={props.mode === ChipMode.Edit ? props.isActive ? Colours.white : Colours.black : Colours.blue}>{t(label)}</BodySmall>
      {props.mode === ChipMode.Add && <Icon icon={IconTypes.Add} size="small" />}
      {props.mode === ChipMode.Edit && <Icon icon={IconTypes.Edit} size="small" />}
    </ChipWrapper>
  )
}

export function PrimaryChip(props: Props) {
  const {label, onClick, icon} = props;
  const {t} = useTranslation();
  let inputAttributes = {};
  if (props.dataTest) {
    // @ts-ignore
    inputAttributes['data-test'] = props.dataTest;
  }
  return (
    <PrimaryChipWrapper {...inputAttributes} className="chip" onClick={onClick} isActive={props.isActive} size={props.size || 'medium'}>
      <BodySmall colour={Colours.white}>{t(label)}</BodySmall>
      {icon && <Icon style={{marginLeft: 16}} icon={icon} size="small" />}
    </PrimaryChipWrapper>
  )
}

export function OutlineChip(props: Props) {
  const {label, onClick, icon} = props;
  const {t} = useTranslation();
  let inputAttributes = {};
  if (props.dataTest) {
    // @ts-ignore
    inputAttributes['data-test'] = props.dataTest;
  }
  return (
    <OutlineChipWrapper {...inputAttributes} className="chip" onClick={onClick} isActive={props.isActive} size={props.size || 'medium'}>
      <BodySmall colour={Colours.blue}>{t(label)}</BodySmall>
      {icon && <Icon style={{marginLeft: 16}} icon={icon} size="small" />}
    </OutlineChipWrapper>
  )
}

export function GreyChip(props: Props) {
  const {label, onClick, icon} = props;
  const {t} = useTranslation();
  return (
    <GreyChipWrapper className="chip" onClick={onClick} isActive={props.isActive} size={props.size || 'medium'}>
      <BodySmall colour={Colours.black}>{t(label)}</BodySmall>
      {icon && <Icon style={{marginLeft: 16}} icon={icon} size="small" />}
    </GreyChipWrapper>
  )
}

export function OrangeChip(props: Props) {
  const {label, onClick, icon} = props;
  const {t} = useTranslation();
  return (
    <OrangeChipWrapper className="chip" onClick={onClick} isActive={props.isActive} size={props.size || 'medium'}>
      <BodySmall colour={Colours.black}>{t(label)}</BodySmall>
      {icon && <Icon style={{marginLeft: 16}} icon={icon} size="small" />}
    </OrangeChipWrapper>
  )
}

const ChipWrapper = styled.div<any>`
  display: inline-flex;
  align-items: center;
  ${props => props.mode === ChipMode.Edit ? props.isActive ? `background: ${Colours.black}` : `background: ${Colours.veryLightGrey}` : `background: #FFFFFF`};
  ${props => props.mode === ChipMode.Edit ? `border: none` : `border: 1px solid #0057FF`};
  box-sizing: border-box;
  border-radius: 60px;
  padding: 4px 16px;
  width: fit-content;
  cursor: pointer;
  p {
    font-size: ${props => props.size === 'large' ? '18px' : props.size === 'small' ? '14px' : props.size === 'verysmall' ? '13px' : '16px'};
    line-height: ${props => props.size === 'large' ? '26px' : props.size === 'small' ? '18px' : props.size === 'verysmall' ? '18px' : '22px'};
  }
  i {
    ${props => props.mode === ChipMode.Edit ? props.isActive ? `color: ${Colours.white}` : `color: ${Colours.black}` : `color: ${Colours.blue}`};
    align-items: center;
    align-self: center;
  }
`

const PrimaryChipWrapper = styled<any>(ChipWrapper)<any>`
  background: ${Colours.blue};
  border: none;
  i {
    color: ${Colours.white};
  }
`

const OutlineChipWrapper = styled<any>(ChipWrapper)<any>`
  background: ${Colours.white};
  border: 1px solid ${Colours.blue};
  i {
    color: ${Colours.blue};
  }
`

const GreyChipWrapper = styled<any>(ChipWrapper)<any>`
  background: ${Colours.lightGrey};
  border: 1px solid ${Colours.lightGrey};
  i {
    color: ${Colours.black};
  }
`

const OrangeChipWrapper = styled<any>(ChipWrapper)<any>`
  background: ${Colours.orange10};
  border: 1px solid ${Colours.orange};
  i {
    color: ${Colours.black};
  }
`
