import React from 'react';
import { HeadlineLarge } from "../../../atoms/fonts/Headline";
import { BodyLarge } from "../../../atoms/fonts/Body";
import { LinkButton } from "../../../atoms/buttons/LinkButton";
import { Button } from "../../../atoms/buttons/Button";
import { isInTeams } from "../../../../../utils/TeamsUtils";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  onDone: () => any;
  hideImages: boolean;
}

export function AddTeamMemberLimitReached(props: Props) {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <HeadlineLarge style={{marginBottom: 12}}>{t('settings.member-limit-reached')}</HeadlineLarge>

      {isInTeams() ? <>
        <BodyLarge style={{marginBottom: 24}}>{t('settings.account-full-in-teams')}</BodyLarge>
      </> : <>
        <BodyLarge style={{marginBottom: 24}}><Trans i18nKey={'settings.account-full-normal'} /></BodyLarge>

        <div style={{marginBottom: 16}}>
          <LinkButton dataTest="upgradeButton" size="large" text="button.upgrade-my-account"
                      link="/manage/payments"
                      type="outline" onClick={props.onDone} fullWidth={true}/>
        </div>
      </>}

      <Button dataTest="doneButton" size="large" text="button.done-for-now" onClick={props.onDone}
              type="primary" fullWidth={true}/>

    </React.Fragment>
  )
}
