import React, { useEffect } from 'react';
import styled from "styled-components/macro";
import { breakpoints, desktopSmall, largeMobile, tablet } from "../../../UI/atoms/MediaQueries";
import { isInTeams } from "../../../../utils/TeamsUtils";
import Layout from "../../../UI/organisms/layout/Layout";
import isMobile from "is-mobile";
import { useDispatch, useSelector } from "react-redux";
import { loadUserHolidayInfo, selectListOfUsersHolidayInfo } from "../models/holiday-reports.duck";
import { HolidayReportViewHeader } from "./components/HolidayReportViewHeader";
import { UsersHolidayInfoContentCard } from "./components/UsersHolidayInfoContentCard";
import { HolidayReportSidebarContent } from "./components/HolidayReportSidebarContent";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isOfficeAdminUser, isTeamAdminUser } from "../../../../utils/AccessControl";
import { InsufficientAccessToReporting } from "./components/InsufficientAccessToReporting";
import { HolidayTrackingDisabledNoAccessToReporting } from "./components/HolidayTrackingDisabledNoAccessToReporting";

export default function HolidayReportsView() {
  const listOfUsersHolidayInfo = useSelector(selectListOfUsersHolidayInfo);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserHolidayInfo());
  }, [dispatch]);

  useEffect(() => {
    // Get elements and necessary element heights
    const contentDiv = document.getElementById("content");

    // Get view height
    const viewportHeight = document.getElementsByTagName('body')[0].clientHeight;

    // Compute the content height - we want to fill the whole remaining area
    // in browser window
    if (contentDiv && viewportHeight) {
      contentDiv.style.height = `${viewportHeight - contentDiv.offsetTop}px`;
    }
  }, []);

  if (!currentUser?.companyEntity.enableHolidayBooking) {
    return <HolidayTrackingDisabledNoAccessToReporting />
  } else if (isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser) || isTeamAdminUser(currentUser)) {
    return (
      <Layout hideFooter={true} fullScreenView={true} isInTeams={isInTeams()}>

        <OuterContainer>
          <ViewContainer isInTeams={isInTeams()}>
            <InnerContainer isInTeams={isInTeams()}>

              <PageContent isInTeams={isInTeams()}>
                <HolidayReportViewHeader />

                <ContentCardList>
                  {listOfUsersHolidayInfo.map((holidayInfo, key) => (
                    <UsersHolidayInfoContentCard usersHolidayInfo={holidayInfo} key={key} />
                  ))}
                </ContentCardList>
              </PageContent>
            </InnerContainer>
            {!isMobile() && <SidebarContainer isInTeams={isInTeams()}>
              <HolidayReportSidebarContent />
            </SidebarContainer>}

          </ViewContainer>
        </OuterContainer>

      </Layout>
    )
  } else {
    return (<Layout hideFooter={true} fullScreenView={true} isInTeams={isInTeams()}>
      <InsufficientAccessToReporting />
    </Layout>)
  }
}


const OuterContainer = styled.div`
  max-height: inherit;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${breakpoints.desktopMidWide}px;
`

const ViewContainer = styled.div<any>`
  display: flex;
  max-height: ${props => props.isInTeams ? 'calc(100vh - 60px)' : 'calc(100vh - 244px)'};
  @media (${tablet}) {
    display: flex;
    max-height: ${props => props.isInTeams ? 'calc(100vh - 60px)' : 'calc(100vh - 186px)'};
  }
`

const InnerContainer = styled.div<any>`
  width: 100%;
  //max-height: inherit;
  height: ${props => props.isInTeams ? 'calc(100vh - 56px)' : "calc(100vh - 116px)"};
  display: flex;
  overflow-y: auto;
`

const PageContent = styled.div<any>`
  width: 100%;
  padding: 16px;
  max-width: 1000px;
  //max-height: inherit;
  height: ${props => props.isInTeams ? 'calc(100vh - 56px)' : "calc(100vh - 116px)"};
`

const ContentCardList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  &>* {
    margin-bottom: 16px;
  }
  @media (${largeMobile}) {
    display: grid;
    gap: 16px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
  @media (${desktopSmall}) {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
  }
`

const SidebarContainer = styled.div<any>`
  padding: 32px;
  top: 0;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 9px 22px rgba(46, 74, 148, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 999999;
  overflow-y: auto;

  height: 100vh;
  position: fixed;
  width: 100vw;

  @media (${tablet}) {
    min-width: 340px;
    min-height: 600px;
    height: ${props => props.isInTeams ? 'calc(100vh - 56px)' : "calc(100vh - 116px)"};
    width: unset;
    position: relative;
    border-radius: 24px 0 0 0;
    margin-top: ${props => props.isInTeams ? 0 : -56};
  }
  
  .sidebar__closeBtn {
    position: absolute;
    right: 16px;
    top: 16px;

    @media (${tablet}) {
      display: none;
    }
  }
`
