import React from 'react';
import { Link } from "react-router-dom";
import { HeadlineXtraSmall } from "../../../../atoms/fonts/Headline";
import './drop-down-menu-item.scss';
import Icon, { IconTypes } from "../../../../atoms/icon/Icon";
import { useTranslation } from "react-i18next";

export default function DropDownMenuItem(props: Props) {
  const mobileOnly = props.mobileOnly ? 'dropDownMenuItem--mobileOnly' : '';
  const {t} = useTranslation();
  if (props.url) {
    return (
      <Link to={props.url} onClick={props.onClick} className={`dropDownMenuItem dropDownMenuItem__link noSelect ${mobileOnly}`.trim()}>
        {props.icon && <Icon className="dropDownMenuItem__icon" icon={props.icon} style={{marginRight: 16}}/>}
        <HeadlineXtraSmall className="dropDownMenuItem__text">{t(props.text)}</HeadlineXtraSmall>
      </Link>
    )
  } else {
    return <div onClick={props.onClick} className={`dropDownMenuItem dropDownMenuItem__link noSelect ${mobileOnly}`.trim()}>
      {props.icon && <Icon className="dropDownMenuItem__icon" icon={props.icon} style={{marginRight: 16}}/>}
      <HeadlineXtraSmall className="dropDownMenuItem__text">{t(props.text)}</HeadlineXtraSmall>
    </div>
  }
}

interface Props {
  text: string;
  onClick: () => any;
  mobileOnly: boolean
  url?: string;
  icon?: IconTypes;
}

