import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import { BodyRegular, BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import Colours from "../../../UI/atoms/Colours";
import { DeskPickerContentCard } from "./DeskPickerContentCard";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedSelectedParkingSpace,
  selectSelectionOffice,
  setSelectedParkingSpace,
  setShowCarParkMap
} from "../../../../store/ducks/deskReservation.duck";
import { CarParkSpace, requestCarParkingSpace } from "../../../../services/CarParkingPlanService";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { selectActiveDay, selectUserRequiredParking } from "../../../../store/ducks/editMovements.duck";
import { setSelectedCarPark } from "../../../../store/ducks/officeView.duck";
import {
  getCarParkDetails,
  selectCarParkingDetails
} from "../../../pages/company-movements/ducks/carParkingMapView.duck";

export function SelectedParkingContentCard(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isParkingSelected = useSelector(selectUserRequiredParking);
  const selectedParkingSpace = useSelector(selectedSelectedParkingSpace);
  const date = useSelector(selectActiveDay);
  const selectedOffice = useSelector(selectSelectionOffice);
  const carParkingPlans = useSelector(selectCarParkingDetails);
  const [error, setError] = useState('');

  const dateString = date?.format(DATE_FORMAT);
  const officeId = selectedOffice?.id;
  const officeUsesParkingPlan = selectedOffice?.hasCarParkPlan;

  useEffect(() => {
    if (officeUsesParkingPlan && isParkingSelected && officeId) {
      dispatch(getCarParkDetails({officeId}))
    }
  }, [dispatch, isParkingSelected, officeId, officeUsesParkingPlan]);

  useEffect(() => {
    const carParks = carParkingPlans.filter((carPark) => carPark.officeId === officeId);
    if (carParks.length > 0) {
      dispatch(setSelectedCarPark(carParks[0]));
    }
  }, [officeId, carParkingPlans, dispatch]);

  useEffect(() => {
    if (officeUsesParkingPlan && dateString && officeId && isParkingSelected) {
      requestCarParkingSpace(officeId, dateString)
        .then((parkingSpace: CarParkSpace) => {
          dispatch(setSelectedParkingSpace(parkingSpace));
        })
        .catch(() => setError('No parking spaces'));
    }
  }, [isParkingSelected, dateString, dispatch, officeId, officeUsesParkingPlan]);

  if (officeUsesParkingPlan && isParkingSelected) {
    return (
      <Container onClick={() => dispatch(setShowCarParkMap(true))}>
        <CarIcon src="/assets/icons/car-front.svg" alt="" />
        <ParkingInfoContainer>
          {!!error ? <>
            <BodyRegular weight={600} colour={Colours.red}>No spaces in car park!</BodyRegular>
            <BodyVerySmall weight={600} colour={Colours.blue}>{t('desk-booking.edit-parking')}</BodyVerySmall>
          </> : <>
            <BodyRegular weight={600}>{!!selectedParkingSpace ? selectedParkingSpace.label : t('desk-booking.no-space-selected')}</BodyRegular>
            <BodyVerySmall weight={600} colour={Colours.blue}>{t('desk-booking.edit-parking')}</BodyVerySmall>
          </>}
        </ParkingInfoContainer>
      </Container>
    )
  } else {
    return null;
  }
}

interface Props {
}

const Container = styled<any>(DeskPickerContentCard)<any>`
  display: flex;
  cursor: pointer;
`

const CarIcon = styled.img`
  margin-right: 20px;
`

const ParkingInfoContainer = styled.div`
`
