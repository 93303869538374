import React, { useMemo, useState } from 'react';
import { Switch } from "../../../../UI/atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { fetchDesks, selectAllOfficeDesks } from "../../../../../store/ducks/deskBooking.duck";
import { selectSelectedDeskIds } from "../../ducks/manageDesks.duck";
import { failureNotification, successNotification } from "../../../../../store/ducks/notification.duck";
import { selectSelectedOffice } from "../../../../../store/ducks/officeView.duck";
import { updateDeskActiveState } from "../../../../../services/DeskBookingService";

export function ActiveDeskControlForManageDesksSidebar(props: Props) {
  const dispatch = useDispatch();
  const selectedDeskIds = useSelector(selectSelectedDeskIds);
  const allDesks = useSelector(selectAllOfficeDesks)
  const [loading, setLoading] = useState(false);
  const selectedOffice = useSelector(selectSelectedOffice);

  const selectedDesks = useMemo(() => allDesks.filter(d => selectedDeskIds.includes(d.id)),
    [allDesks, selectedDeskIds]);

  const switchState = useMemo(() => {
    return !(selectedDesks.filter(d => !d.active).length > 0)
  }, [selectedDesks]);

  const updateDesksActiveState = async (state: boolean) => {
    setLoading(true);
    try {
      dispatch(successNotification(`Updated ${selectedDesks.length} desks`));
      console.log(state, selectedDesks)
      if (selectedOffice) {
        await updateDeskActiveState(selectedDeskIds, state);
        dispatch(fetchDesks(selectedOffice.id));
      }
    } catch (e) {
      dispatch(failureNotification(`Failed to update active state of desks`));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Switch style={{marginBottom: 48}}
              value={switchState}
              disabled={selectedDesks.length === 0 || loading}
              onChange={(enabled: boolean) => updateDesksActiveState(enabled)}
              label={'manage-desks-sidebar.active'} />
    </>
  )
}

interface Props {
}
