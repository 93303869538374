import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import TextField from '../../UI/atoms/TextField';
import {
  requestPasswordReset,
  reset,
  selectError,
  selectIsComplete,
  selectIsLoading
} from '../../../store/ducks/forgotPassword.duck';
import AuthWrapper from './components/AuthWrapper';
import { validateEmail } from "../../../utils/Validators";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { IconTypes } from "../../UI/atoms/icon/Icon";

export default function ForgotPassword() {
  document.body.style.backgroundColor = '#FAFAFA';
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const isComplete = useSelector(selectIsComplete);
  const error = useSelector(selectError);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const {t} = useTranslation();

  const resetPassword = () => {
    if (!email || !validateEmail(email)) {
      setEmailErrorMessage('Please enter a valid email address')
      return;
    }
    dispatch(requestPasswordReset(email));
  }

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  if (error) {
    return (
      <AuthWrapper dataTest="forgotPass-failed" title="general.failed">
        <BodyRegular>{error}</BodyRegular>
      </AuthWrapper>
    );
  }

  if (isLoading) {
    return (
      <AuthWrapper dataTest="forgotPass-loading" title="general.processing" spinner={true}>
        <BodyRegular>{t('forgot-password.request-processing')}</BodyRegular>
      </AuthWrapper>
    );
  }

  if (isComplete) {
    return (
      <AuthWrapper dataTest="forgotPass-complete" title="login.email-sent" icon={IconTypes.Tick}>
        <BodyRegular>{t('forgot-password.follow-link')}</BodyRegular>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper dataTest="forgotPass" title="forgot-password.forgot-password">
      <BodyRegular className="login__message">{t('forgot-password.enter-email-address')}</BodyRegular>
      <TextField label={'forgot-password.your-email-address'} type="email" onChange={(e: string) => setEmail(e)} value={email} error={emailErrorMessage} />
      <PrimaryButton size="large"
                     fullWidth={true}
                     text="forgot-password.send-password-reset"
                     click={() => resetPassword()}
                     className="resetPassword__button" />
    </AuthWrapper>
  );
}

