import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import './styles/app.scss';
import Login from './components/pages/login/Login';
import Register from './components/pages/auth/Register';
import ForgotPassword from './components/pages/auth/ForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import { redirectTo, selectIsAuthLoading, selectRedirectTo } from './store/ducks/auth.duck';
import ResetPassword from './components/pages/auth/ResetPassword';
import CompleteRegistration from './components/pages/auth/CompleteRegistration';
import NotFoundPage from './components/pages/not-found-page/NotFoundPage';
import SecureApp from './components/routing/SecureApp';
import Notification from './components/UI/molecules/notification/Notification';
import { getParameterByName } from './utils/UrlUtils';
import { successNotification } from './store/ducks/notification.duck';
import { getNotificationMessage, NotificationMessages } from './models/notification.models';
import VerificationRequired from './components/pages/auth/verification-required/VerificationRequired';
import { usePageName } from "./hooks/UsePageName";
import UnsubscribePage from "./components/pages/UnsubscribePage";
import ScrollToTop from "./components/routing/ScrollToTop";
import RegisterWithAzure from "./components/pages/auth/RegisterWithAzure";
import LoginWithAzure from "./components/pages/login/LoginWithAzure";
import LogoutWithAzure from "./components/pages/auth/logout/LogoutWithAzure";
import { DynamicDialogRenderer } from "./components/UI/organisms/DynamicDialogRenderer";
import RegisterWithTeamToday from "./components/pages/auth/RegisterWithTeamToday";
import DebugPage from "./components/pages/DebugPage";
import LoginWithGoogle from "./components/pages/login/LoginWithGoogle";
import InviteLogin from "./components/pages/login/InviteLogin";
import { LoadingSpinner } from "./components/UI/atoms/LoadingSpinner";
import { WithMsTeamsAppChecker } from "./hooks/WithMsTeamsAppChecker";
import { OfficeCheckinPage } from "./components/pages/office-checkin/OfficeCheckinPage";
import { WithAuth } from "./hooks/WithAuth";
import { resetAllLoginStorages } from "./utils/AuthUtils";
import { WithTenantChecker } from "./hooks/WithTenantChecker";
import './translations/config';
import LoginWithAzureInTeamsTab, { isOnTeamsTabAuthPage } from "./components/pages/login/LoginWithAzureInTeamsTab";

export const PUBLIC_ROUTES = ["/",
  "/404",
  "/unsubscribe",
  "/login",
  "/invite",
  "/login/azure",
  "/login/tab-auth",
  "/login/google",
  "/logout/azure",
  "/verification-required",
  "/register",
  "/register/team-today",
  "/register/azure",
  "/forgot-password",
  "/reset-password",
  "/complete-registration",
  "/debug",
  "/confirm"];

export function isOnPublicPage(): boolean {
  return PUBLIC_ROUTES.includes(window.location.pathname);
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const redirectToUrl = useSelector(selectRedirectTo);
  const isAuthLoading = useSelector(selectIsAuthLoading);
  document.body.style.backgroundColor = 'white';

  usePageName(location.pathname);

  WithAuth();
  WithTenantChecker();
  WithMsTeamsAppChecker();

  // Handle redirect notification
  useEffect(() => {
    const notificationParam = getParameterByName('not', window.location.href);
    const notificationTimeParam = getParameterByName('t', window.location.href) || '0';
    if (notificationParam === NotificationMessages.noAccountFound || notificationParam?.includes(NotificationMessages.azureLoginFailed)) {
      resetAllLoginStorages();
    }
    if (notificationParam && Date.now() - parseInt(notificationTimeParam) < 500) {
      dispatch(successNotification(getNotificationMessage(notificationParam as NotificationMessages)));
    }
  }, [dispatch]);

  if (isAuthLoading && !isOnTeamsTabAuthPage()) {
    return <LoadingSpinner fullScreen={true} message={"general.app-loading"}/>
  }

  if (redirectToUrl) {
    if (location.pathname !== redirectToUrl) {
      return (<Redirect to={redirectToUrl}/>);
    } else {
      dispatch(redirectTo(undefined));
    }
  }

  return (
    <React.Fragment>
      <ScrollToTop>
        <Switch>
          <Route exact path="/"><Redirect to="/login"/></Route>
          <Route exact path="/404" component={NotFoundPage}/>
          <Route exact path="/unsubscribe" component={UnsubscribePage}/>

          {/* Login and register */}
          <Route exact path="/login" component={Login}/>
          <Route exact path="/invite" component={InviteLogin}/>
          <Route exact path="/login/azure" component={LoginWithAzure}/>
          <Route exact path="/login/tab-auth" component={LoginWithAzureInTeamsTab}/>
          <Route exact path="/login/google" component={LoginWithGoogle}/>

          <Route exact path="/logout/azure" component={LogoutWithAzure}/>

          <Route exact path="/verification-required" component={VerificationRequired}/>

          <Route exact path="/register" component={Register}/>
          <Route exact path="/register/team-today" component={RegisterWithTeamToday}/>
          <Route exact path="/register/azure" component={RegisterWithAzure}/>

          <Route exact path="/forgot-password" component={ForgotPassword}/>
          <Route exact path="/reset-password" component={ResetPassword}/>
          <Route exact path="/complete-registration" component={CompleteRegistration}/>


          <Route exact path="/debug" component={DebugPage}/>
          <Route exact path="/confirm" component={OfficeCheckinPage}/>

          <SecureApp/>

        </Switch>
        <Notification/>
        <DynamicDialogRenderer/>
      </ScrollToTop>
    </React.Fragment>
  );
}

export default App;
