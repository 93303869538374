import { agent } from "../../../../../../services/agent";

export interface CreateVisitorBookingRequest {
  date: string;
  visitingUserId: string;
  visitorName: string;
  companyName: string;
  dueInHours: number;
  dueInMinutes: number;
  visitingOfficeId: number;
  amDeskId?: number;
  pmDeskId?: number;
  amParkingSpaceId?: number;
  pmParkingSpaceId?: number
}

export async function createVisitorBooking(request: CreateVisitorBookingRequest): Promise<any> {
  await agent.requests.post('/api/visitor-booking/new', request, true);
}

export async function getVisitorBookings(officeId: number, dateString: string): Promise<VisitorBooking[]> {
  return await agent.requests.get(`/api/visitor-booking/${officeId}?date=${dateString}`, true);
}

export async function signVisitorIn(bookingId: number): Promise<VisitorBooking> {
  return await agent.requests.post(`/api/visitor-booking/sign-in`, {visitorBookingId: bookingId}, true);
}
export interface VisitorBooking {
  id: number;
  visitingUserId: string;
  visitingUserName: string;
  visitingUserTeamName: string;
  officeId: number;
  date: string;
  visitorName: string;
  companyName: string;
  timeDueHours: number;
  timeDueMinutes: number;

  amDeskId: number;
  pmDeskId: number;
  amDeskLabel: string;
  pmDeskLabel: string;

  amParkingSpaceId?: number;
  pmParkingSpaceId?: number

  bookedInByUserId: string;
  bookedInByUserName: string;
  checkedInTime: any;

  signedOutTime: any;
}
