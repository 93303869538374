import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import styled from "styled-components/macro";
import Dialog from "../UI/molecules/Dialog";
import { smallTablet } from "../UI/atoms/MediaQueries";
import DialogHeader from './DialogHeader';
import { DomainBasedSignUpSetting } from "../UI/organisms/settings/DomainBasedSignUpSetting";
import { AzureScimIntegrationMessage } from "../UI/organisms/settings/AzureScimIntegrationMessage";
import { UserImportSpreadsheetMessage } from "../UI/organisms/settings/UserImportSpreadsheetMessage";
import Colours from "../UI/atoms/Colours";

export default function AddMultipleUsersDialog() {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeDialog());
  }

  return (
    <DialogWrapper isOpen={true} onClose={close}>
      <DialogHeader title="add-multiple-users-dialog.title" />
      <AzureScimIntegrationMessage style={{marginBottom: 32, paddingBottom: 16, borderBottom: `1px solid ${Colours.lightGrey}`}} />
      <DomainBasedSignUpSetting hideExtraInfo={true} />
      <UserImportSpreadsheetMessage />
    </DialogWrapper>
  )
}

const DialogWrapper = styled<any>(Dialog)<any>`
  width: 100%;
  height: 80vh;
  max-height: 80vh;

  @media (${smallTablet}) {
    width: 960px;
  }
  .dialog__title {
    text-align: center;
    margin: 0 auto 32px auto;
  }
  .dialog__content {
    width: 100%;
    margin: 0;
    max-width: 100%;
  }
  .dialog__contentContainer {
    overflow-x: hidden;
    overflow-y: auto;
    @media (${smallTablet}) {
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }
  .domainBasedSignup__section {
    border: none !important;
    border-bottom: 1px solid ${Colours.lightGrey} !important;
    margin-bottom: 32px;
    padding-bottom: 16px;
  }
`;
