import React, { useMemo } from 'react';
import DateFlicker, { DateScale } from "../../../../UI/molecules/DateFlicker";
import { Moment } from "moment/moment";
import { selectHolidayReportingDate, setHolidayReportingDate } from "../../models/holiday-reports.duck";
import { toAirBnbDate } from "../../../../../utils/DateUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../../../../store/ducks/config.duck";

export function HolidayReportDatePicker(props: Props) {
  const dispatch = useDispatch();
  const selectedDate = useSelector(selectHolidayReportingDate);
  const config = useSelector(selectConfig);

  const onDateChange = (date: Moment) => {
    dispatch(setHolidayReportingDate(date));
  }

  const dateLabel = useMemo(() => {
    let dateFrom = selectedDate.clone();
    dateFrom.month(config.holidayYearRecurringMonth-1);
    dateFrom.date(config.holidayYearRecurringDay);
    let dateTo = dateFrom.clone();
    dateTo.add(1, 'years');
    dateTo.subtract(1, 'days');
    return toAirBnbDate(dateFrom, dateTo);
  }, [selectedDate, config]);

  return (
    <>
      <DateFlicker dateScale={DateScale.Year} style={props?.style ?? {}}
                   updateGlobalDate={false} date={selectedDate} dateLabelOverride={dateLabel}
                   onChange={date => onDateChange(date)} />
    </>
  )
}

interface Props {
  style?: any;
}
