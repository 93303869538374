import React from 'react';
import { PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectedOffice, setSidebarOpen } from "../models/room-finder.duck";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { PageTitle } from "../../../UI/atoms/PageTitle";

export function MeetingRoomFinderPageHeader(props: Props) {
  const dispatch = useDispatch();
  const office = useSelector(selectedOffice);
  const activeDate = useSelector(selectActiveDay);

  const onFiltersClick = () => {
    dispatch(setSidebarOpen(true));
  }

  return (
    <HeaderRow className={props?.className ?? ''}>
      {office && <PageTitle text={"header.meeting-rooms"} overrideText={`${office.label} - ${activeDate?.format(PRETTY_DATE_FORMAT)}`} />}

      <Controls>
        <OutlineButton text={'room-finder.navigation.filters'}
                       size={'medium'}
                       className={"roomFinder__filterButton"}
                       fullWidth={true}
                       click={onFiltersClick} />
      </Controls>

    </HeaderRow>
  )
}

interface Props {
  className?: string;
}

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0 0;
  @media (${tablet}) {
    display: none;
  }
`

const HeaderRow = styled.div`
  display: flex;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 16px;
  flex-direction: column;
  h3 {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .goBackButton {
    margin: 0;
    align-self: center;
  }
  .roomFinder__navigation {
    margin: 0 auto;
  }
  .roomFinder__showFilters {
    margin-left: 16px;
    width: 120px;
  }
  .roomFinder__navButton {
    width: 120px;
  }
  .linkButton {
    margin: 0;
  }

  @media (${tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
