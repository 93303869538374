import React from 'react';
import './_button.scss';
import styled from "styled-components/macro";
import Icon from "../icon/Icon";
import { BaseButton, NewButtonProps } from "./ButtonStyling";
import Colours from "../Colours";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export function PrimaryButton(props: NewButtonProps) {
  const {t} = useTranslation();

  let buttonAttributes = {}
  if (!!props.dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = props.dataTest;
  }

  if (props.link) {
    const linkBtnProps = {
      ...buttonAttributes,
      ...props,
    }
    return <PrimaryLinkButton {...linkBtnProps} />
  }

  return (
    <PrimaryButtonWrapper {...buttonAttributes}
                          className={props.className ?? ''}
                          fullWidth={!!props.fullWidth}
                          size={props.size || 'medium'}
                          disabled={!!props.disabled}
                          type={props.buttonType ?? 'button'}
                          style={props?.style ?? {}}
                          onClick={(e: any) => props.click ? props.click(e) : () => {}}>
      {props.icon && <Icon style={{marginRight: 8}} icon={props.icon ?? ''}/>}
      {props.text && <span>{!!props.text ? t(props.text) : ''}</span>}
    </PrimaryButtonWrapper>
  );
}

function PrimaryLinkButton(props: NewButtonProps) {
  const linkButtonProps = {
    ...props,
    link: undefined,
  }
  return <Link to={props.link ?? '#'} style={props.style ?? {}} className={"linkButton"}>
    <PrimaryButton {...linkButtonProps} />
  </Link>
}

const PrimaryButtonWrapper = styled<any>(BaseButton)<any>`
  background-color: ${props => !!props.disabled ? Colours.mildGrey : Colours.blue};
  color: white;
  border: 0;
  box-shadow: ${props => props.size === 'small' ? `none` : !!props.disabled ? `3px 3px 0 0 ${Colours.mildGrey}` : `3px 3px 0 0 ${Colours.darkBlue}`};
  &:hover {
    background-color: ${props => !!props.disabled ? Colours.mildGrey : Colours.darkBlue};
  }
`
