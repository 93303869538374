import React, { useEffect, useState } from 'react';
import Layout from "../../UI/organisms/layout/Layout";
import { getParameterByName } from "../../../utils/UrlUtils";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Center } from '../../UI/atoms/StructuralLayout';
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { smallTablet } from "../../UI/atoms/MediaQueries";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { agent } from "../../../services/agent";
import { useTranslation } from "react-i18next";

function checkIn(id: string): Promise<void> {
  return agent.requests.post('/api/confirm-checkin', {id: id});
}

export function OfficeCheckinPage(props: Props) {
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    const id = getParameterByName('id', window.location.href);
    if (id) {
      checkIn(id)
        .finally(() => setLoading(false));
    } else {
      // set error
      setLoading(false);
    }
  }, []);

  return (
    <Layout>
      {loading ? <>
        <Center>
          <LoadingSpinner hideText={true} />
        </Center>
      </> : <>
        <Container>
          <TopContent>
            <HeadlineLarge style={{marginBottom: 16}}>{t('office-checkin-page.title')}</HeadlineLarge>
            <BodyRegular style={{marginBottom: 8}}>{t('office-checkin-page.confirmed')}</BodyRegular>
            <BodyRegular>{t('office-checkin-page.login-message')}</BodyRegular>
          </TopContent>
          <PrimaryButton text={"login.login"} link={"/login"} fullWidth={true} size={"large"} />
        </Container>
      </>}
    </Layout>
  )
}

interface Props {
}

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  min-height: 350px;
  background-color: ${Colours.veryLightGrey};
  padding: 24px;
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (${smallTablet}) {
    margin-top: 60px;
  }
`

const TopContent = styled.div`
  width: 100%;
`
