import React from 'react';
import { FloatingUserCell, UserColumn } from "./FloatingUserCell";
import { BorderDayCell, ROW_HEIGHT, WallPlannerRow, WeekCell } from '../WallPlannerStructuralComponents';
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import Colours from "../../../UI/atoms/Colours";
import styled from "styled-components";
import { smallTablet } from "../../../UI/atoms/MediaQueries";
import { WithWeeksInCurrentCalendarMonth } from "../../../../hooks/WithWeekInCurrentCalendarMonth";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";


interface Props {
  teamName: string;
  isFirst: boolean;
  restricted?: boolean;
}

export function WallPlannerTeamRow(props: Props) {
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();

  if (props.restricted) {
    return (<WallPlannerTeamErrorRowContainer weeksInMonth={weeksInMonth}>
        <FloatTeamCell className={'teamName__container'}>
          <HeadlineSmall>{props.teamName}</HeadlineSmall>
        </FloatTeamCell>
        <ErrorMessageColumn>
          <BodyRegular>Visibility of this group is restricted</BodyRegular>
          <BodyRegular>If you require access to this group please ask an administrator</BodyRegular>
        </ErrorMessageColumn>
      </WallPlannerTeamErrorRowContainer>)
  } else {
    return (
      <WallPlannerTeamRowContainer weeksInMonth={weeksInMonth}>
        <FloatTeamCell style={{}}>
          <HeadlineSmall>{props.teamName}</HeadlineSmall>
        </FloatTeamCell>
        {weeksInMonth.map((timeFrame, k) => (
          <WeekCell key={k}>
            <BorderDayCell/>
            <BorderDayCell/>
            <BorderDayCell/>
            <BorderDayCell/>
            <BorderDayCell/>
            <BorderDayCell weekend={true}/>
            <BorderDayCell weekend={true}/>
          </WeekCell>
        ))}
      </WallPlannerTeamRowContainer>
    )
  }
}

export const WallPlannerTeamRowContainer = styled<any>(WallPlannerRow)<any>`
  height: ${ROW_HEIGHT}px;
  width: 100%;
  h3 {
    padding-top: 12px;
  }
  @media (${smallTablet}) {
    h3 {
      padding-top: 0;
    }
  }
`

export const FloatTeamCell = styled(FloatingUserCell)<any>`
  @media (${smallTablet}) {
    border-bottom: 1px solid ${Colours.mildGrey};
  }
`

export const WallPlannerTeamErrorRowContainer = styled<any>(WallPlannerRow)<any>`
  height: unset;
  align-items: center;
  width: 100%;
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colours.mildGrey};
  .teamName__container, .floatingUser__userColumn {
    border: none;
  }
  h3 {
    padding-top: 12px;
  }
  @media (${smallTablet}) {
    h3 {
      padding-top: 0;
    }
  }
`

export const ErrorMessageColumn = styled<any>(UserColumn)<any>`
  width: 100%;
  border: none;
  margin-top: 40px;
  display: block;
  margin-bottom: 16px;
  @media (${smallTablet}) {
    padding: 16px 32px;
    margin: 0;
  }
`

