import React from 'react';
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";

export function Done(props: Props) {
  const dispatch = useDispatch();
  return (
    <div>
      <PrimaryButton text={"new-user-onboarding-dialog.done"}
                     fullWidth={true}
                     click={() => dispatch(closeDialog())} />
    </div>
  )
}

interface Props {
}
