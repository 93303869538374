import React from 'react';
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { BodySmall } from '../atoms/fonts/Body';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export function EditButton(props: Props) {
  const {t} = useTranslation();
  if (props.disabled) {
    return null;
  }
  return (
    <EditButtonWrapper onClick={props.onClick}>
      {!props.hideText && <BodySmall>{t('button.edit')}</BodySmall>}
      <Icon icon={IconTypes.Edit} size={props.size ?? 'medium'} />
    </EditButtonWrapper>
  )
}

interface Props {
  onClick: () => any;
  hideText?: boolean;
  disabled: boolean;
  size?: 'small' | 'medium' | 'mediumlarge' | 'big' | 'large';
}

const EditButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin-right: 8px;
    text-decoration: underline;
  }
`
