import { agent } from './agent';

export async function getFollowingTeams(): Promise<FollowedGroups> {
  return await agent.requests.get('/api/following-teams');
}

export async function unfollowTeam(teamId: string): Promise<FollowingTeams> {
  return await agent.requests.post('/api/unfollow-team', {teamId: teamId});
}

export async function followTeam(teamId: string): Promise<FollowingTeams> {
  return await agent.requests.post('/api/follow-team', {teamId: teamId});
}

export async function followWorkGroup(workGroupId: number): Promise<FollowingTeams> {
  return await agent.requests.post('/api/follow-work-group', {workGroupId: workGroupId});
}

export async function unfollowWorkGroup(workGroupId: number): Promise<FollowingTeams> {
  return await agent.requests.post('/api/unfollow-work-group', {workGroupId: workGroupId});
}

export interface FollowedGroups {
  followedTeams: FollowingTeams[];
  followedWorkGroups: any[];
}

export interface FollowingTeams {
  id: number;
  userId: string;
  teamId: string;
}

