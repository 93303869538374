import React, { useMemo } from 'react';
import { FloorContainer } from "./AreaAndDeskUsageView";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { Column, SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveDay, selectActivePeriod } from "../../../../../store/ducks/editMovements.duck";
import { Period } from "../../../../../models/movements.models";
import { bookSingleDayMovement } from "../../../../../store/ducks/officeUsage.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { UsersInOffice } from "../../../../../hooks/UsersInOffice";
import { ExpandableUserList } from "./ExpandableUserList";
import { WithOfficeCapacity } from "../../../../../hooks/WithOfficeCapacity";
import { OfficeCapacityLabel } from "../../../../UI/molecules/OfficeCapacityLabel";
import { isInTeams } from "../../../../../utils/TeamsUtils";

export function SingleOfficeUsageView(props: Props) {
  const {office, isVisible} = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const selectedDate = useSelector(selectActiveDay);
  const activePeriod = useSelector(selectActivePeriod);

  const { capacity } = WithOfficeCapacity(office, selectedDate);
  const usersInOffice = UsersInOffice(office, selectedDate, capacity);

  const hasRoomToBook = useMemo(() => {
    // Has no capacity enabled
    if (!office.capacityEnabled) return true;

    if (capacity) {
      if (activePeriod === Period.AM) {
        return capacity.amUsed < capacity.capacity;
      } else if (activePeriod === Period.PM) {
        return capacity.pmUsed < capacity.capacity;
      } else {
        return Math.max(capacity.amUsed, capacity.pmUsed) < capacity.capacity;
      }
    }
    return false;
  }, [capacity, activePeriod, office])


  const bookSingleDay = (period: Period) => {
    if (selectedDate && hasRoomToBook && currentUser) {
      dispatch(bookSingleDayMovement({
        date: selectedDate,
        office: office,
        userId: currentUser.id,
        period: period
      }));
    }
  }

  return (
    <FloorContainer>
      <SpaceBetweenRow>
        <Column>
          <HeadlineSmall>{office.label}</HeadlineSmall>
          <OfficeCapacityLabel capacity={capacity} />
        </Column>

        <div>

          <div style={{alignItems: 'center', display: 'flex'}}>
            {office.floorPlanEnabled && !isInTeams() && <OutlineButton text={'button.view'}
                                                                       size={'small'}
                                                                       style={{marginRight: 8}}
                                                                       click={() => window.location.href = `/whereabouts/company/all?office=${office.id}&view=floor`} />}

            {!office.deskBookingEnabled && <OutlineButton size={'small'}
                                                          disabled={!office.active || !hasRoomToBook}
                                                          click={() => bookSingleDay(activePeriod)}
                                                          text="button.book"
                                                          dataTest="book-into-office-button" />}

          </div>
        </div>
      </SpaceBetweenRow>

      <ExpandableUserList office={office}
                          isLoading={usersInOffice.loading}
                          users={usersInOffice.users}
                          isVisible={isVisible} />
    </FloorContainer>
  )
}

interface Props {
  office: OfficeEntity;
  isVisible: boolean;
}
