import React, { useEffect } from "react";
import { BodyRegular } from "../../atoms/fonts/Body";
import Dialog from "../../molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import { getCheckoutSession } from "./stripeService";
import { getParameterByName } from "../../../../utils/UrlUtils";
import { PrimaryButton } from "../../atoms/buttons/PrimaryButton";

export default function StripeSuccessDialog() {
  const dispatch = useDispatch();
  const sessionId = getParameterByName('session_id', window.location.href);

  useEffect(() => {
    if (sessionId) {
      getCheckoutSession(sessionId).then((session: any) => {
        console.log(session)
      })
    }
  }, [sessionId])

  const close = () => {
    dispatch(closeDialog())
    window.history.pushState(null, document.title, window.location.origin + window.location.pathname);
  }

  return (
    <Dialog isOpen={true} onClose={close}>
      <DialogHeader title="dialog-headers.payment-successful"/>
      <BodyRegular style={{marginBottom: 32}}>Payment complete successfully.</BodyRegular>
      <PrimaryButton click={close} text="button.close" fullWidth={true} size="large"/>
    </Dialog>
  );
}
