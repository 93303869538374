import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { getMyFutureBookings } from "../../../../services/MeetingRoomsService";
import { AppState } from "../../../../store/state/app.state";
import { groupBy } from "../../../../utils/ArrayUtils";

export interface MeetingRoomState {
  groupedBookings: any;
  loading: boolean;
}

export const initialMeetingRoomState: MeetingRoomState = {
  groupedBookings: {},
  loading: false,
}

export const loadBookings: any = createAsyncThunk(
  'meetingRoom/loadBookings',
  async (params: {}, thunkAPI) => {
    const bookings = await getMyFutureBookings();
    const groupedBookings = groupBy(bookings, 'dateString');

    return {
      groupedBookings: groupedBookings,
    }
  }
)

const meetingRoomSlice = createSlice({
  name: 'meetingRoom',
  initialState: initialMeetingRoomState,
  reducers: {
    reset: () => initialMeetingRoomState,
  },
  extraReducers: {
    [loadBookings.pending]: (state) => ({...state, loading: true,}),
    [loadBookings.reject]: (state) => ({...state, loading: false,}),
    [loadBookings.fulfilled]: (state, action) => ({ ...state, groupedBookings: action.payload.groupedBookings, loading: false, }),
  }
})

export default meetingRoomSlice.reducer;
export const {
  reset,
} = meetingRoomSlice.actions;

// Selectors
export const selectUsersGroupedBookings = (state: AppState) => state.meetingRoom.groupedBookings;
export const selectIsMeetingRoomBookingsLoading = (state: AppState) => state.meetingRoom.loading;
