import { agent } from './agent';
import {reset} from '../store/ducks/auth.duck';
import ReactGA from 'react-ga';
import {AuthType, resetAllLoginStorages} from "../utils/AuthUtils";

const login =  async (data) => {
  try {
    const resp = await agent.requests.post('/login', data, true);
    localStorage.setItem('idToken', resp.token);
    localStorage.setItem('type', AuthType.Standard);
    const userId = ((resp || {}).id) || 'not set';
    ReactGA.set({userId});
    return resp;
  } catch (err) {
    throw err;
  }
};

const loginWithInvitation =  async (data) => {
  try {
    const resp = await agent.requests.post('/loginWithInvite', data, true);
    localStorage.setItem('idToken', resp.token);
    localStorage.setItem('type', AuthType.Standard);
    const userId = ((resp || {}).id) || 'not set';
    ReactGA.set({userId});
    return resp;
  } catch (err) {
    throw err;
  }
};

const azureAcceptInvite =  async () => {
  try {
    const resp = await agent.requests.post('/loginWithAzureInvite', {}, true);
    const userId = ((resp || {}).id) || 'not set';
    ReactGA.set({userId});
    return resp;
  } catch (err) {
    throw err;
  }
};

const googleAcceptInvite =  async () => {
  try {
    const resp = await agent.requests.post('/loginWithGoogleInvite', {}, true);
    const userId = ((resp || {}).id) || 'not set';
    ReactGA.set({userId});
    return resp;
  } catch (err) {
    throw err;
  }
};

const register = (data) => agent.requests.post('/register', data);

const forgotPassword = (email) => agent.requests.post('/api/forgot-password/request', {email});

const resetPassword = (password, resetId) => agent.requests.post('/api/forgot-password/reset', {password, resetId}, true);

export function loginWithAzureRequest(refreshToken) {
  return agent.requests.post('/login/azure', {
    refreshToken: refreshToken,
  }, false);
}

const current = async (passive = false) => {
  const resp = await agent.requests.get('/current', passive);
  const userId = ((resp || {}).id) || 'not set';
  ReactGA.set({userId});
  return resp;
};

export const rawUserRequest = async () => {
  return await agent.requests.get('/current', true, true);
};

const authenticate = () => {
  const idToken = localStorage.getItem('idToken');
  const data = {
    type: 'google',
    data: {
      idToken
    },
  }
  return agent.requests.post('/authenticate', data);
}

const disableSlackSync = () => agent.requests.put('/api/integration/slack/disable');

const logout = (dispatch) => {
  resetAllLoginStorages();
  window.location.href = '/';
  dispatch(reset);
};

const validateEmail = (id) => {
  return agent.requests.post('/register/validate', {id});
}

export function requestValidationEmail(email) {
  return agent.requests.post('/register/resend-verification', {email})
}

export const Auth = {
  register,
  login,
  loginWithInvitation,
  current,
  authenticate,
  logout,
  forgotPassword,
  resetPassword,
  disableSlackSync,
  validateEmail,
  azureAcceptInvite,
  googleAcceptInvite,
}
