import React, { useEffect, useMemo, useState } from 'react';
import { SmallTablet } from "../../../UI/atoms/DeviceTypes";
import DateFlicker, { DateScale } from "../../../UI/molecules/DateFlicker";
import { WallPlannerDatesRow } from "../../wall-planner/components/WallPlannerDatesRow";
import { WorkPlannerContainer } from '../../../UI/molecules/work-planner/WorkPlannerStructure';
import { WallPlannerUserRow } from "../../wall-planner/components/WallPlannerUserRow";
import { useDispatch, useSelector } from "react-redux";
import { HolidayViewerDropdownList } from "./components/HolidayViewerDropdownList";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { INDIRECT_LINE_REPORT_ID, LINE_REPORT_ID, MonthlyHolidayView } from "../../../../services/HolidayService";
import { HolidayGrouping } from "../models/Holidays.model";
import moment from "moment";
import { currentUserLineReports, selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { loadTeamHolidays, selectIsLoading, selectMonthlyHolidayView } from "../../../../store/ducks/holidays-v2.duck";
import { usePageName } from "../../../../hooks/UsePageName";
import isMobile from "is-mobile";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import styled from "styled-components/macro";
import { selectAllTeams } from "../../../../store/ducks/config.duck";
import { selectAllTags } from "../../../../store/ducks/userTags.duck";
import { GroupType } from "../../../../models/team.models";
import { PageTitle } from "../../../UI/atoms/PageTitle";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { WithWeeksInCurrentCalendarMonth } from "../../../../hooks/WithWeekInCurrentCalendarMonth";
import { WorkPlannerLayout } from "../../../UI/molecules/work-planner/WorkPlannerLayout";
import { Permission } from "../../../../models/user.models";
import { WithUsersLineReports } from "../../../../hooks/WithUsersLineReports";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { safeParseInt } from "../../../../utils/MathUtils";

interface SelectedOption {
  id: string | number;
  groupType: GroupType;
}

function GroupHolidayToWallPlannerActivityConverter(groupings: HolidayGrouping[]): any[] {
  if (!groupings) return []
  return groupings.map(g => ({
    ...g,
    toDate: moment(g.toDate),
    fromDate: moment(g.fromDate),
    name: '',
  }))
}

export const SELECTED_GROUP_ID = 'selectedGroupId';

export function TeamsMonthlyHolidayView(props: Props) {
  const dispatch = useDispatch();
  const teams = useSelector(selectAllTeams);
  const workGroups = useSelector(selectAllTags);
  const calendarDate = useSelector(selectCalendarDate);
  const currentUser = useSelector(selectCurrentUser);
  const teamHolidays: MonthlyHolidayView[] = useSelector(selectMonthlyHolidayView);
  const isLoading = useSelector(selectIsLoading);
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();
  const {lineReports} = WithUsersLineReports(currentUser);
  const isHolidayTrackingEnabled = currentUser?.companyEntity.enableHolidayBooking;

  usePageName('Team Today - Team holidays');

  const [selectedOption, setSelectedOption] = useState<SelectedOption | undefined>(undefined);

  const onTeamSelect = async (selectedOptionId: string | number, groupType: GroupType) => {
    setSelectedOption({id: selectedOptionId, groupType: groupType});
    localStorage.setItem(SELECTED_GROUP_ID, `${selectedOptionId}::${groupType}`);
  }

  useEffect(() => {
    dispatch(currentUserLineReports());
  }, [dispatch]);

  const usersTeamId = currentUser?.teamEntity?.id;

  useEffect(() => {
    if (selectedOption) {
      return;
    }

    try {
      const selectedGroup: any[] = (localStorage.getItem(SELECTED_GROUP_ID) || '').split('::');
      if (selectedGroup.length === 2) {
        const groupId = selectedGroup[0];
        const groupType = safeParseInt(selectedGroup[1]);
        if (groupType === GroupType.WorkGroup && workGroups.find(w => w.id === parseInt(groupId))) {
          setSelectedOption({id: parseInt(groupId), groupType: GroupType.WorkGroup});
          return;
        } else if (groupType === GroupType.Team && teams.find(t => t.id === groupId)) {
          setSelectedOption({id: groupId, groupType: GroupType.Team});
          return;
        }
      } else {
        if (teams.find(t => usersTeamId === t.id)) {
          setSelectedOption({id: usersTeamId, groupType: GroupType.Team});
        }
      }
    } catch (e) {
      localStorage.removeItem(SELECTED_GROUP_ID);
    }
  }, [teams, workGroups, usersTeamId, selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.groupType === GroupType.Team) {
        dispatch(loadTeamHolidays({
          teamIds: [selectedOption.id],
          calendarDate: calendarDate
        }));
      } else if (selectedOption.groupType === GroupType.WorkGroup) {
        dispatch(loadTeamHolidays({
          workGroupIds: [selectedOption.id],
          calendarDate: calendarDate
        }));
      } else if (selectedOption.id === LINE_REPORT_ID) {
        dispatch(loadTeamHolidays({
          teamIds: [LINE_REPORT_ID],
          calendarDate: calendarDate
        }));
      } else if (selectedOption.id === INDIRECT_LINE_REPORT_ID) {
        dispatch(loadTeamHolidays({
          teamIds: [INDIRECT_LINE_REPORT_ID],
          calendarDate: calendarDate
        }));
      }
    }
  }, [dispatch, calendarDate, selectedOption]);

  const addHolidayUrl = useMemo(() => {
    if (lineReports.length > 0) {
      return '/holidays/add'
    }

    if (currentUser?.role === Permission.Basic || currentUser?.role === Permission.TeamAdmin) {
      return `/holidays/${currentUser.id}/add`
    } else {
      return '/holidays/add'
    }

  }, [currentUser, lineReports]);

  return (
    <>
      <WorkPlannerLayout pageContentStyles={{paddingTop: 24}} numberOfWeeksInMonth={weeksInMonth.length+1}>
        <HeaderRow style={{paddingTop: 0}}>
          <PageTitle text={'header.leave-planner'} style={{marginTop: 0}} />
          <SmallTablet style={{marginBottom: 16, padding: '0 16px'}}>
            <DateFlicker dateScale={DateScale.Month} updateGlobalDate={true} />
          </SmallTablet>

          {isHolidayTrackingEnabled && <OutlineButton link={addHolidayUrl}
                                                      text={"button.add-holiday-request"}
                                                      fullWidth={true}
                                                      style={{maxWidth: 200}} />}
        </HeaderRow>
        <WorkPlannerContainer id={'workPlannerContainer'}>
          {isLoading && <LoadingWrapper>
            <LoadingSpinner fullScreen={false} />
          </LoadingWrapper>}

          <WallPlannerDatesRow weeksInMonth={weeksInMonth}/>
          {!isMobile() &&
            <WallPlannerUserRow>
              <HolidayViewerDropdownList teams={teams}
                                         workGroups={workGroups}
                                         onSelect={onTeamSelect}
                                         selectedOptionId={selectedOption ? selectedOption?.id : undefined} />
            </WallPlannerUserRow>
          }

          {teamHolidays.map((monthView: MonthlyHolidayView, key: number) => (
            <WallPlannerUserRow key={key}
                                user={monthView.user}
                                working={[]}
                                holidays={GroupHolidayToWallPlannerActivityConverter(monthView.holidayGroupings)}
                                companyHolidays={monthView.companyHolidays}/>
          ))}

          {/* Padding at the bottom */}
          <WallPlannerUserRow id={'workPlannerRow'} />
          <WallPlannerUserRow />
          <WallPlannerUserRow />
          <WallPlannerUserRow />
          <WallPlannerUserRow />
        </WorkPlannerContainer>
      </WorkPlannerLayout>
    </>
  )
}

interface Props {
}

const LoadingWrapper = styled.div`
  z-index: 99999;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderRow = styled.div`
  margin-bottom: 16px;
  @media(${tablet}) {
    display: flex;
    justify-content: space-between;
    .dropdown__container {
      max-width: 300px;
    }
  }
`
