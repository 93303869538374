import React, { useState } from 'react';
import { UserInfo } from "../../../../../models/user.models";
import { SpaceBetweenRow } from "../../StyleComponents";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { Switch } from "../../../../UI/atoms/Switch";
import { updateCurrentUser } from "../../../../../store/ducks/auth.duck";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { UpdateUserField, updateUserField } from "../../../../../services/UserService";
import { Section } from "../../../../UI/organisms/layout/Layout";

export function UserAccessPermittedControl(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onChange = async (value: boolean) => {
    if (props.userInfo) {
      try {
        setIsLoading(true);
        await updateUserField(props.userInfo.userId, UpdateUserField.accessPermitted, value);
        dispatch(updateCurrentUser(true));
      } finally {
        setIsLoading(false);
      }
    }
  }

  if (!props?.userInfo) {
    return null;
  }

  return (
    <Section>
      <SpaceBetweenRow>
        <BodyRegular weight={600} colour={Colours.blue}>{t('settings-page.edit-user.access-permitted-title')}</BodyRegular>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!props?.userInfo?.accessPermitted} label="" onChange={onChange}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginTop: -8, marginBottom: 24}}>{t('settings-page.edit-user.access-permitted-description')}</BodyRegular>
    </Section>
  )
}

interface Props {
  userInfo?: UserInfo;
}
