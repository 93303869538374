import React from 'react';
import './_button.scss';
import styled from "styled-components/macro";
import Icon from "../icon/Icon";
import { BaseButton, NewButtonProps } from "./ButtonStyling";
import Colours from "../Colours";

export function SecondaryButton(props: NewButtonProps) {
  let buttonAttributes = {}
  if (!!props.dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = props.dataTest;
  }
  return (
    <SecondaryButtonWrapper {...buttonAttributes} fullWidth={!!props.fullWidth} size={props.size || 'medium'}
                            disabled={!!props.disabled} className={props.className ?? ''}
                            type={props.buttonType ?? 'button'} style={props?.style ?? {}}
                            onClick={(e: any) => props.click(e)}>
      {props.icon && <Icon style={{marginRight: 8}} icon={props.icon ?? ''}/>}
      {props.text && <span>{props.text ?? ''}</span>}
    </SecondaryButtonWrapper>
  );
}

const SecondaryButtonWrapper = styled<any>(BaseButton)<any>`
  background-color: white;
  color: ${props => !!props.disabled ? Colours.mildGrey : Colours.black};

  box-shadow: ${props => props.size === 'small' ? `none` : !!props.disabled ? `3px 3px 0 0 ${Colours.mildGrey}` : `3px 3px 0 0 ${Colours.black}`};
  
  border: solid 2px ${Colours.black};
  border-color: ${props => !!props.disabled ? Colours.mildGrey : Colours.black};
  
  &:hover {
    background-color: ${props => !!props.disabled ? Colours.white : Colours.veryLightGrey};
  }
`
