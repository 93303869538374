import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../../store/state/app.state";
import { loadConfig, updateConfig } from "../../../../../store/ducks/config.duck";
import { updateCompanyName } from "../../../../../store/ducks/management.duck";
import { BasicUserInfo } from "../../../../../models/user.models";
import { ConfigEntity } from "../../../../../services/ConfigService";
import { failureNotification, successNotification } from "../../../../../store/ducks/notification.duck";
import { HolidayRecurringDay } from '../../../../../models/Holiday.model';

export interface EditCompanyState {
  companyName: string;
  holidayAllowance: boolean;
  dirty: boolean;
  companyAdmins: BasicUserInfo[];
  numberOfDesksAvailable: number;
  companyHolidayAllowance: number;
  holidayYearRecurringMonth: number;
  holidayYearRecurringDay: HolidayRecurringDay;
}

export const initialEditCompanyState: EditCompanyState = {
  companyName: '',
  holidayAllowance: false,
  dirty: false,
  companyAdmins: [],
  numberOfDesksAvailable: 0,
  companyHolidayAllowance: 0,
  holidayYearRecurringMonth: 1,
  holidayYearRecurringDay: HolidayRecurringDay.firstMonday,
}

export const reset: any = createAsyncThunk(
  'editCompany/reset',
  async (blank, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;

    if (!state.config.configEntity) {
      await thunkAPI.dispatch(loadConfig());
      // await thunkAPI.dispatch(fetchConfig());
    }

    const companyName = state.auth?.currentUser?.companyEntity.name
    const holidayAllowance = state.config.configEntity.holidayAllowance;
    const numberOfDesksAvailable = state.config.configEntity.numberOfDesksAvailable;

    return { companyName, holidayAllowance, numberOfDesksAvailable, dirty: false }
  }
)


export const updateHolidayRecurringTime: any = createAsyncThunk('editCompany/updateHolidayRecurringTime',
  async (params: {recurringDay: HolidayRecurringDay, recurringMonth: number}, thunkAPI) => {
    const config = (thunkAPI.getState() as AppState).config.configEntity;
    config.holidayYearRecurringDay = params.recurringDay;
    config.holidayYearRecurringMonth = params.recurringMonth;
    await thunkAPI.dispatch(updateConfig(config));
  }
)

export const saveChanges: any = createAsyncThunk(
  'editCompany/saveChangedConfig',
  async (blank, thunkAPI) => {
    const editCompany = (thunkAPI.getState() as AppState).editCompany;
    const company = (thunkAPI.getState() as AppState).auth?.currentUser?.companyEntity;

    if (editCompany.numberOfDesksAvailable < 1) {
      await thunkAPI.dispatch(failureNotification("Number of desks available must be greater than 0."))
      return thunkAPI.rejectWithValue(undefined);
    }

    if (editCompany.holidayAllowance && (!editCompany.companyHolidayAllowance || editCompany.companyHolidayAllowance < 1)) {
      await thunkAPI.dispatch(failureNotification("Holiday allowance must be greater than 0."))
      return thunkAPI.rejectWithValue(undefined);
    }

    if (editCompany.dirty) {
      await thunkAPI.dispatch(updateConfig(
        new ConfigEntity(
          editCompany.holidayAllowance,
          editCompany.numberOfDesksAvailable,
          editCompany.companyHolidayAllowance,
          editCompany.holidayYearRecurringMonth,
          editCompany.holidayYearRecurringDay)
        )
      );
      if (company?.enabled) {
        await thunkAPI.dispatch(updateCompanyName(editCompany.companyName));
      }
      await thunkAPI.dispatch(successNotification("Changes saved"))
    } else {
      console.log('Nothing to update in edit company duck')
    }
  }
)

const editCompanySlice = createSlice({
  name: 'editCompany',
  initialState: initialEditCompanyState,
  reducers: {
    loaded: (state, action) => ({...state, ...action.payload}),
    setCompanyName: (state, action) => ({...state, companyName: action.payload, dirty: true}),
    setHolidayAllowanceEnabled: (state, action) => ({...state, holidayAllowance: action.payload, dirty: true}),
    setDefaultDaysHoliday: (state, action) => ({...state, companyHolidayAllowance: parseInt(action.payload), dirty: true}),
    setHolidayYearRecurringMonth: (state, action) => ({...state, holidayYearRecurringMonth: parseInt(action.payload), dirty: true}),
    setHolidayYearRecurringDay: (state, action) => ({...state, holidayYearRecurringDay: parseInt(action.payload), dirty: true}),
    updateEditCompanyConfig: (state, action) => ({
      ...state,
      holidayYearRecurringDay: parseInt(action.payload.holidayYearRecurringDay),
      holidayYearRecurringMonth: parseInt(action.payload.holidayYearRecurringMonth),
      companyHolidayAllowance: parseInt(action.payload.companyHolidayAllowance),
      holidayAllowance: action.payload.holidayAllowance,
      numberOfDesksAvailable: action.payload.numberOfDesksAvailable,
    })
  },
  extraReducers: {
    [reset.fulfilled]: (state, action) => ({...state, ...action.payload}),

    [saveChanges.fulfilled]: (state) => ({...state, dirty: false}),
  }
});

export default editCompanySlice.reducer;
export const {
  setCompanyName,
  setHolidayAllowanceEnabled,
  setDefaultDaysHoliday,
  setHolidayYearRecurringMonth,
  setHolidayYearRecurringDay,
  updateEditCompanyConfig,
} = editCompanySlice.actions;

export const selectEditCompanyState = (state: AppState) => {
  return {
    ...state.editCompany,
    companyEnabled: state.auth?.currentUser?.companyEntity.enabled
  }
};

export const selectHolidayAllowance = (state: AppState) => {
  return {
    holidayAllowance: state.editCompany.holidayAllowance,
    companyHolidayAllowance: state.editCompany.companyHolidayAllowance,
    holidayYearRecurringMonth: state.editCompany.holidayYearRecurringMonth,
    holidayYearRecurringDay: state.editCompany.holidayYearRecurringDay,
  }
};
