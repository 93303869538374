import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { next, OnboardingScreen, selectActiveScreen, skip } from '../../../../store/ducks/onboarding.duck';
import { Column, Row } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import DoThisLaterButtonRow from '../../../UI/molecules/DoThisLaterButtonRow';
import { BodyLarge } from '../../../UI/atoms/fonts/Body';
import TextField from "../../../UI/atoms/TextField";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { createOrUpdateOffice, OfficeType } from "../../../../services/AdvanceHotDeskingService";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { loadConfig, selectConfig, selectIsConfigLoading, updateConfig } from "../../../../store/ducks/config.duck";
import { Switch } from "../../../UI/atoms/Switch";
import Colours from "../../../UI/atoms/Colours";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";

export default function OnboardingCreateOffice() {
  const dispatch = useDispatch();
  const activeScreen = useSelector(selectActiveScreen);
  const config = useSelector(selectConfig);
  const configLoading = useSelector(selectIsConfigLoading);
  const allOffices = useSelector(selectAllOfficeEntities);
  const [officeName, setOfficeName] = useState('');
  const [desksAvailable, setDesksAvailable] = useState(0);
  const [capacityEnabled, setCapacityEnabled] = useState(false);
  const [carParkCapacity, setCarParkCapacity] = useState(0);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const [officeNameError, setOfficeNameError] = useState('');
  const [seatsAvailableError, setSeatsAvailableError] = useState('');

  useEffect(() => {
    if (activeScreen === OnboardingScreen.SetupOffice && allOffices.length > 0) {
      dispatch(skip());
    }
  }, [dispatch, allOffices, activeScreen]);

  const toggleCarParkingEnabled = async (state: boolean) => {
    if (!configLoading) {
      dispatch(updateConfig({...config, carParkingEnabled: state}));
    }
  }

  const createOffice = async () => {
    setOfficeNameError('');
    setSeatsAvailableError('');

    if (!officeName) {
      setOfficeNameError('Office name required');
    }

    if (officeName) {
      try {
        setLoading(true);
        await createOrUpdateOffice({
          id: 0,
          name: officeName,
          postCode: '',
          capacity: desksAvailable || 0,
          floors: [],
          parentId: 0,
          officeType: OfficeType.OFFICE, //TODO Change to be dynamic
          capacityEnabled: true,
          carParkCapacity: carParkCapacity,
          officeBookingWindowWeeks: 0,
        });
      } finally {
        setLoading(false);
      }

      dispatch(loadConfig());
      dispatch(next());
    }
  }

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <img style={{width: 272, marginBottom: 24}} className="onboarding__image" src="/assets/images/onboarding-offices-image.svg" alt="" aria-hidden={true} />
        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.create-an-office')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 16}}>{t('onboarding.create-office-reasoning')}</BodyLarge>

        <OnboardingRow>
          <TextField onChange={setOfficeName}
                     value={officeName}
                     fullWidth={true} style={{marginBottom: 8}}
                     dataTest="onboarding-office-name"
                     label="textinput.office-name"
                     disabled={loading}
                     error={officeNameError} />
        </OnboardingRow>

        <OnboardingRow>
          <Switch value={capacityEnabled}
                  weight={600}
                  label={"textinput.enable-desk-booking"}
                  disabled={loading}
                  onChange={(val) => setCapacityEnabled(val)} />

          {capacityEnabled && <DesksAvailableInput onChange={(val: string) => setDesksAvailable(parseInt(val))}
                                                   value={desksAvailable.toString()}
                                                   label="textinput.number-desks-available"
                                                   dataTest="onboarding-office-number"
                                                   style={{marginTop: 8, marginBottom: 8}}
                                                   fullWidth={true}
                                                   type="number"
                                                   error={seatsAvailableError} />}
        </OnboardingRow>
        <OnboardingRow>
        <Switch value={!!config?.carParkingEnabled}
                weight={600}
                disabled={loading}
                label={"textinput.enable-car-parking"}
                onChange={(val) => toggleCarParkingEnabled(val)} />

          {!!config?.carParkingEnabled && <TextField onChange={(val: string) => setCarParkCapacity(parseInt(val))}
                                                   value={carParkCapacity.toString()}
                                                   label="number of spaces"
                                                   disabled={loading}
                                                   style={{marginTop: 8, marginBottom: 8}}
                                                   fullWidth={true}
                                                   type="number" />}
        </OnboardingRow>

        <Row style={{marginBottom: 24, marginTop: 32}}>
          <PrimaryButton dataTest="onboarding-office-next"
                         size="large"
                         disabled={loading}
                         text="button.create-office"
                         click={createOffice}
                         fullWidth={true}/>
        </Row>

        <DoThisLaterButtonRow dataTest="onboard-hotDeskingSkip"
                              disabled={loading}
                              onClick={() => dispatch(skip())}/>
      </Column>
    </div>
  )
}

const DesksAvailableInput = styled<any>(TextField)`
  input {
    width: 150px;
  }
`

const OnboardingRow = styled.div`
  border-bottom: 1px solid ${Colours.lightGrey};
  margin-bottom: 16px;
  padding-bottom: 16px;
`
