import { useEffect } from "react";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { Moment } from "moment";
import { DATE_FORMAT } from "../utils/DateUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectOfficeCapacityByDateAndOfficeId, subscribeToOfficeUsage } from "../store/ducks/officeUsage.duck";
import { AppState } from "../store/state/app.state";

export function WithOfficeCapacity(office?: OfficeEntity, selectedDate?: Moment): any {
  const dispatch = useDispatch();
  const officeUsage = useSelector((state: AppState) => selectOfficeCapacityByDateAndOfficeId(state, selectedDate?.format(DATE_FORMAT), office?.id))

  useEffect(() => {
    if (!office || !selectedDate) {
      return;
    }

    dispatch(subscribeToOfficeUsage({officeId: office.id, date: selectedDate}));
  }, [selectedDate, office, dispatch]);

  const spacesAvailable = () => {
    if (officeUsage && !officeUsage.loading && !officeUsage.failed) {
      return Math.min(officeUsage.capacity - officeUsage.amUsed, officeUsage.capacity - officeUsage.pmUsed)
    }
    return '??';
  }

  return {
    loading: officeUsage?.loading === undefined ? true : officeUsage.loading,
    capacity: officeUsage,
    spacesAvailable: spacesAvailable,
  };
}
