import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { TeamEntity } from "../../models/team.models";
import { Tag } from "../../models/tags.models";
import {
  followTeam,
  followWorkGroup,
  getFollowingTeams,
  unfollowTeam,
  unfollowWorkGroup
} from "../../services/FollowingTeamsService";
import { warningNotification } from "./notification.duck";
import { loadWhereabouts } from "../../components/pages/team-movements/models/team-movements.duck";

export interface FollowingTeamsAndTagsState {
  teams: TeamEntity[];
  workGroups: Tag[];
  isLoading: boolean;
}

export const initialFollowingTeamsAndTagsState: FollowingTeamsAndTagsState = {
  teams: [],
  workGroups: [],
  isLoading: false,
}

export const loadFollowingGroups: any = createAsyncThunk(
  'followingTeamsAndTags/loadFollowingTeams',
  async (forceLoad: boolean, thunkAPI) => {
    const followingTeamsAndTags = (thunkAPI.getState() as AppState).followingTeamsAndTags;
    if (forceLoad || (followingTeamsAndTags.teams.length === 0 && followingTeamsAndTags.workGroups.length === 0)) {
      const followedGroups = await getFollowingTeams();

      return {
        teams: followedGroups.followedTeams,
        workGroups: followedGroups.followedWorkGroups,
      };
    } else {
      return {
        teams: followingTeamsAndTags.teams,
        workGroups: followingTeamsAndTags.workGroups,
      }
    }
  }
)




async function updateFollowingTeamsFunction(teamsToFollow: TeamEntity[], currentFollowingTeams: TeamEntity[], dispatch: any) {
  for (const followedTeam of currentFollowingTeams) {
    if (teamsToFollow.filter(ft => ft.id === followedTeam.id).length === 0) {
      await unfollowTeam(followedTeam.id);
    }
  }

  if (currentFollowingTeams.length >= 3) {
    dispatch(warningNotification('Cannot follow more than 3 teams'));
    return;
  }

  for (const team of teamsToFollow) {
    if (currentFollowingTeams.filter(ft => ft.id === team.id).length === 0) {
      await followTeam(team.id);
    }
  }
}

async function updateFollowingWorkgroupsFunction(workgroupsToFollow: Tag[], currentFollowingWorkgroups: Tag[], dispatch: any) {
  for (const followedWorkgroup of currentFollowingWorkgroups) {
    if (workgroupsToFollow.filter(wg => wg.id === followedWorkgroup.id).length === 0) {
      await unfollowWorkGroup(followedWorkgroup.id);
    }
  }

  if (currentFollowingWorkgroups.length >= 3) {
    dispatch(warningNotification('Cannot follow more than 3 workgroups'));
    return;
  }

  for (const workgroup of workgroupsToFollow) {
    if (currentFollowingWorkgroups.filter(fwg => fwg.id === workgroup.id).length === 0) {
      await followWorkGroup(workgroup.id);
    }
  }
}

export const updateFollowingTeamsAndWorkgroups: any = createAsyncThunk(
  'followingTeamsAndTags/updateFollowingTeamsAndWorkgroups',
  async (params: {teams: TeamEntity[], workGroups: Tag[], reloadTeamWhereabouts?: boolean}, thunkAPI) => {
    const followingTeamsAndTags = (thunkAPI.getState() as AppState).followingTeamsAndTags;
    const followedWorkGroups = followingTeamsAndTags.workGroups;
    const followedTeams = followingTeamsAndTags.teams;

    await updateFollowingTeamsFunction(params.teams, followedTeams, thunkAPI.dispatch);
    await updateFollowingWorkgroupsFunction(params.workGroups, followedWorkGroups, thunkAPI.dispatch);

    if (params.reloadTeamWhereabouts) {
      thunkAPI.dispatch(loadWhereabouts());
    }

    thunkAPI.dispatch(loadFollowingGroups(true))
  }
)

export const updateFollowingTeams: any = createAsyncThunk(
  'followingTeamsAndTags/updateFollowingTeams',
  async (teams: TeamEntity[], thunkAPI) => {
    const followingTeamsAndTags = (thunkAPI.getState() as AppState).followingTeamsAndTags;
    const followedTeams = followingTeamsAndTags.teams;

    await updateFollowingTeamsFunction(teams, followedTeams, thunkAPI.dispatch);
  }
)

export const updateFollowingWorkGroups: any = createAsyncThunk(
  'followingTeamsAndTags/updateFollowingWorkGroups',
  async (workGroups: Tag[], thunkAPI) => {
    const followingTeamsAndTags = (thunkAPI.getState() as AppState).followingTeamsAndTags;
    const followedWorkGroups = followingTeamsAndTags.workGroups;

    await updateFollowingWorkgroupsFunction(workGroups, followedWorkGroups, thunkAPI.dispatch);
  }
)

const followingTeamsAndTagsSlice = createSlice({
  name: 'followingTeamsAndTags',
  initialState: initialFollowingTeamsAndTagsState,
  reducers: {
    setFollowingTeams: (state, action) => ({
      ...state,
      teams: action.payload,
    }),
    setFollowingWorkGroups: (state, action) => ({
      ...state,
      workGroups: action.payload,
    }),
  },
  extraReducers: {
    [loadFollowingGroups.pending]: (state) => ({...state, isLoading: true}),
    [loadFollowingGroups.rejected]: (state) => ({...state, isLoading: false}),
    [loadFollowingGroups.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      teams: action.payload.teams,
      workGroups: action.payload.workGroups,
    }),

    [updateFollowingTeams.pending]: (state) => ({...state, isLoading: true}),
    [updateFollowingTeams.rejected]: (state) => ({...state, isLoading: false}),
    [updateFollowingTeams.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      // teams: action.payload.teams,
    }),

    [updateFollowingWorkGroups.pending]: (state) => ({...state, isLoading: true}),
    [updateFollowingWorkGroups.rejected]: (state) => ({...state, isLoading: false}),
    [updateFollowingWorkGroups.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      // workGroups: action.payload.workGroups,
    }),

    [updateFollowingTeamsAndWorkgroups.pending]: (state) => ({...state, isLoading: true}),
    [updateFollowingTeamsAndWorkgroups.rejected]: (state) => ({...state, isLoading: false}),
    [updateFollowingTeamsAndWorkgroups.fulfilled]: (state) => ({...state, isLoading: false}),
  }
})

export default followingTeamsAndTagsSlice.reducer;
export const {
  setFollowingTeams
} = followingTeamsAndTagsSlice.actions;

// Selectors
export const selectFollowingTeams = (state: AppState) => state.followingTeamsAndTags.teams;
export const selectFollowingWorkGroups = (state: AppState) => state.followingTeamsAndTags.workGroups;
export const selectIsLoading = (state: AppState) => state.followingTeamsAndTags.isLoading;
