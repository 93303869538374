import { agent } from './agent';
import { ApprovalState, ApprovalType, GroupedApprovalRequest } from "../models/approval-requests.models";
import { Moment } from "moment";
import { DATE_FORMAT } from "../utils/DateUtils";

export async function requestApprovals(pending: boolean,
                                       approved: boolean,
                                       rejected: boolean,
                                       from: Moment,
                                       to: Moment,
                                       groupId: string): Promise<GroupedApprovalRequest[]> {
    let params = '?'
    params += `pending=${pending}&`
    params += `approved=${approved}&`
    params += `rejected=${rejected}&`
    params += `from=${from.format(DATE_FORMAT)}&`
    params += `to=${to.format(DATE_FORMAT)}&`
    params += `groupId=${groupId}`
    return await agent.requests.get(`/api/approvals${params}`);
}

export async function sendApproveRequest(approval: GroupedApprovalRequest) {
    return await agent.requests.post(`/api/approvals/approve`, approval)
}

export async function sendDeclineRequest(approval: GroupedApprovalRequest) {
    return await agent.requests.post(`/api/approvals/decline`, approval)
}

export async function requestNumberOfLineReportsPendingApproval(approvalType?: ApprovalType): Promise<number> {
    const param = !!approvalType ? `?approvalType=${approvalType}` : '';
    return await agent.requests.get(`/api/approvals/pending/count${param}`);
}

export async function submitMultipleApprovalsByDateAndType(pendingApprovalDates: string[], userId: string, approvalAction: ApprovalState, approvalType: ApprovalType, message?: string): Promise<void> {
    return await agent.requests.post('/api/approvals/date', {
        pendingApprovalDates: pendingApprovalDates,
        userId: userId,
        approvalAction: approvalAction,
        approvalType: approvalType,
        message: message ?? '',
    }, true);
}
