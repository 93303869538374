import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMovement } from '../../../../../store/ducks/editMovements.duck';
import { BodyRegular } from "../../../atoms/fonts/Body";
import { Column, SpaceBetweenRow } from "../../../atoms/StructuralLayout";
import { Moment } from "moment";
import { EditHotDeskingButton } from "./EditHotDeskingButton";
import { MovementImage } from "../../../atoms/MovementImage";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { FreeBusyStatus, OFFICE_OPTION } from "../../../../../services/WhereaboutOptions";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";

interface Props {
  activeDate: Moment;
  offices: OfficeEntity[];
}

export default function ActivitySimpleOffice(props: Props) {
  const dispatch = useDispatch();
  const iconPack = useSelector(selectIconPack)

  const updateSelectedDay = (e: Event) => {
    e.stopPropagation();
    dispatch(updateMovement({selectedOption: OFFICE_OPTION, locationId: 0}));
  }

  return <div className="activity" onClick={(e: any) => updateSelectedDay(e)} data-test="activity-movement-icon-office">
    <MovementImage src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="At the office"/>
    <SpaceBetweenRow style={{padding: 0, alignItems: 'center'}}>
      <Column>
        <BodyRegular weight={600}>In the office</BodyRegular>
      </Column>
      {props.offices.length > 0 && <EditHotDeskingButton/>}
    </SpaceBetweenRow>
  </div>
}
