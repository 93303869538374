import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUsages, selectAllOfficeUsages } from "../store/ducks/officeUsage.duck";

export function WithOfficeUsagesLoader(): any {
  const timerId = useRef(0);
  const dispatch = useDispatch();
  const allOfficeUsages = useSelector(selectAllOfficeUsages);

  useEffect(() => {
    if (timerId.current > 0) {
      clearTimeout(timerId.current);
    }

    if (allOfficeUsages.filter(ou => !ou.hasLoaded && !ou.loading).length > 0) {
      timerId.current = setTimeout(() => dispatch(loadUsages()), 500);
    }
  }, [allOfficeUsages, dispatch]);

  useEffect(() => {
    return () => {
      if (timerId.current > 0) {
        clearTimeout(timerId.current);
      }
    }
  }, [])
}
