import { naturalComparator } from "../../../../utils/ArrayUtils";


export interface Department {
  id: number;
  name: string;
  count: number;
  restricted?: boolean;
}


export function sortDepartments(a: Department, b: Department) {
  return naturalComparator(a, b, 'name');
}

export function findDepartmentInDepartments(departmentId: number, list: Department[]) {
  return list.find((d: Department) => departmentId === d.id);
}
