import React, { useEffect, useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectConfig, selectIsConfigLoading, updateConfig } from "../../../../store/ducks/config.duck";
import TextField from "../../atoms/TextField";
import { Column, Row } from "../../atoms/StructuralLayout";
import Colours from "../../atoms/Colours";

export function EditingFutureWhereaboutsSettings() {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig)
  const isLoading = useSelector(selectIsConfigLoading)
  const [numberOfWeeks, setNumberOfWeeks] = useState(config?.repeatScheduleWeeksLimit ?? 1);
  const {t} = useTranslation();

  useEffect(() => {
    setNumberOfWeeks(config?.repeatScheduleWeeksLimit ?? 1);
  }, [config])

  const onChange = (enabled: boolean, weeks: number, restrictBookingWindowForTeamAdmins: boolean) => {
    if (!config) return;
    dispatch(updateConfig({
      ...config,
      repeatWeeklySchedule: enabled,
      repeatScheduleWeeksLimit: weeks,
      restrictBookingWindowForTeamAdmins: restrictBookingWindowForTeamAdmins
    }));
  }

  return (
    <ConfigurationSection style={{marginTop: 32, marginBottom: 48}}>
      <SpaceBetweenRow style={{marginBottom: 2}}>
        <Column>
          <BodyLarge colour={Colours.blue}>{t('settings.editing-future-whereabouts.repeat-weekly-schedule')}</BodyLarge>

        </Column>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!config.repeatWeeklySchedule} label=""
                                                           disabled={isLoading}
                                                           onChange={(val: boolean) => onChange(val, numberOfWeeks, !!config.restrictBookingWindowForTeamAdmins)}/>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.editing-future-whereabouts.repeat-weekly-schedule-description')}</BodyRegular>

      <Row>
        <BodyRegular weight={600} style={{marginTop: 6, marginRight: 16}}>{t('settings.editing-future-whereabouts.number-of-weeks')}</BodyRegular>
        <TextField onChange={(val: string) => setNumberOfWeeks(parseInt(val))}
                   onBlur={() => onChange(!!config?.repeatWeeklySchedule, numberOfWeeks, !!config.restrictBookingWindowForTeamAdmins)}
                   type={'number'}
                   disabled={isLoading || !config}
                   value={numberOfWeeks.toString()}
                   style={{width:60, marginBottom: 0}} />
      </Row>
    </ConfigurationSection>
  )
}
