import React from 'react';
import { SubHeaderContainer, SubHeaderContent, SubHeaderMenuItem } from "./ViewComponents";
import { BodyRegular } from "../../../../atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { redirectTo } from "../../../../../../store/ducks/auth.duck";
import { selectAllDepartments } from "../../../../../pages/departments/data/departments.duck";

export function CompanyViewSubHeader(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const departments = useSelector(selectAllDepartments);

  return (
    <SubHeaderContainer fullScreenView={props.fullScreenView}>
      <SubHeaderContent>
        <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/company/all')}
                           onClick={() => dispatch(redirectTo('/whereabouts/company/all'))}>
          <BodyRegular weight={600}>All teams</BodyRegular>
        </SubHeaderMenuItem>
        <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/company/workgroups')}
                           onClick={() => dispatch(redirectTo('/whereabouts/company/workgroups'))}>
          <BodyRegular weight={600}>Workgroups</BodyRegular>
        </SubHeaderMenuItem>
        {departments.length > 0 && <SubHeaderMenuItem selected={location.pathname.includes('/whereabouts/company/departments')}
                                                      onClick={() => dispatch(redirectTo('/whereabouts/company/departments'))}>
          <BodyRegular weight={600}>Departments</BodyRegular>
        </SubHeaderMenuItem>}
      </SubHeaderContent>
    </SubHeaderContainer>
  )
}

interface Props {
  fullScreenView?: boolean;
}
