import React from 'react';
import { HeadlineMedium } from "../UI/atoms/fonts/Headline";
import Icon, { IconTypes } from "../UI/atoms/icon/Icon";
import { Column, Row } from '../UI/atoms/StructuralLayout';
import { useTranslation } from "react-i18next";

export default function DialogHeader(props: { image?: string, icon?: IconTypes, title: string }) {
  const {image, icon, title} = props;
  const {t} = useTranslation();

  return (
    <Column>
      <Row className={!!icon ? 'dialog__imageAndIconHeader' : ''} style={{alignItems: 'flex-end'}}>
        {!!icon ? null : <HeadlineMedium className="dialog__title">{t(title)}</HeadlineMedium>}
        {!!icon ? <Icon color="blue" icon={icon} size="large" circle={true} /> : null}
        {!!image ? <img aria-hidden="true" alt="" src={image}/> : null}
      </Row>
      {!!icon ? <HeadlineMedium className="dialog__title">{t(title)}</HeadlineMedium> : null}
    </Column>
  )
}
