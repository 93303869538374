import React from 'react';
import './empty-team-view.scss';
import { BodyLarge, BodyRegular } from "../../../../atoms/fonts/Body";
import { AddTeamMemberButton } from "../../../../atoms/buttons/AddTeamMemberButton";
import { TeamEntity } from "../../../../../../models/team.models";
import { Column, Row } from '../../../../atoms/StructuralLayout';
import Colours from "../../../../atoms/Colours";
import { useTranslation } from "react-i18next";

export default function EmptyTeamView(props: {team?: TeamEntity}) {
  const {t} = useTranslation();
  return (
    <Row className="emptyTeamView">
      <Column className="emptyTeamView__message">
        <BodyLarge className="emptyTeamView__title" colour={Colours.blue}>{t('team.empty-team-title')}</BodyLarge>
        <BodyRegular style={{marginBottom: 32}}>{t('team.empty-team-description')}</BodyRegular>
        <AddTeamMemberButton team={props?.team} />
      </Column>
      <Column className="emptyTeamView__image">
        <img src="/assets/images/man-fishing.svg" alt="Man fishing" />
      </Column>
    </Row>
  )
}
