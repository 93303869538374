import React from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge } from "../../atoms/fonts/Body";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectConfig, selectIsConfigLoading, updateConfig } from "../../../../store/ducks/config.duck";
import { Column } from "../../atoms/StructuralLayout";
import Colours from "../../atoms/Colours";
import { IsSuperAdmin } from "../../../../hooks/IsSuperAdmin";

export function VisitorBookingSettings() {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const isLoading = useSelector(selectIsConfigLoading);
  const {t} = useTranslation();

  const onChange = (enabled: boolean) => {
    if (!config) return;
    dispatch(updateConfig({
      ...config,
      visitorBookingEnabled: enabled,
    }));
  }

  if (!IsSuperAdmin()) {
    return null;
  }

  return (
    <ConfigurationSection style={{marginTop: 32, marginBottom: 48}}>
      <SpaceBetweenRow style={{marginBottom: 2}}>
        <Column>
          <BodyLarge colour={Colours.blue}>{t('settings.visitor-booking.title')}</BodyLarge>

        </Column>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!config.visitorBookingEnabled} label=""
                                                           disabled={isLoading}
                                                           onChange={(val: boolean) => onChange(val)}/>}
      </SpaceBetweenRow>
    </ConfigurationSection>
  )
}
