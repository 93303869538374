import React, { useEffect } from 'react';
import { clearLastPath, getHashParams, getParameterByName } from '../../../utils/UrlUtils';
import AuthWrapper from '../auth/components/AuthWrapper';
import { microsoftClientId } from "../../../services/EnvironmentVariables";
import { app, authentication } from "@microsoft/teams-js";
import { v4 as uuidv4 } from 'uuid';

enum LoginStates {
  loggingIn = 'loggingIn',
  logInSuccessful = 'logInSuccessful',
}

export function isOnTeamsTabAuthPage(): boolean {
  return window.location.pathname === "/login/tab-auth";
}

export default function LoginWithAzureInTeamsTab() {
  useEffect(() => {
    document.body.style.backgroundColor = '#FAFAFA';
    clearLastPath();
  }, []);

  useEffect(() => {
    console.log('LoginWithAzureInTeamsTab useEffect')
    if (!authentication) {
      console.log('is not in teams on teams tab page')
      window.close();
      return;
    }

    const processAuth = async () => {

      await app.initialize();
      console.log('app.initialized')

      const hashParams: any = getHashParams();
      const error = hashParams.error;
      const accessToken = hashParams['access_token'];
      const idToken = hashParams['id_token'];
      const tokenType = hashParams['token_type'];
      const expiresIn = hashParams['expires_in'];
      const state = localStorage.getItem('simple.state');
      if (accessToken) {
        console.log('accessToken', accessToken)
        let key = "simple.result";
        const tokens = { idToken: idToken, accessToken: accessToken, tokenType: tokenType, expiresIn: expiresIn, }
        localStorage.setItem(key, JSON.stringify(tokens));
        authentication.notifySuccess(JSON.stringify(tokens));
        localStorage.setItem('simple.state', LoginStates.logInSuccessful)
        return;
      } else if (error) {
        console.log('error', error)
        localStorage.setItem('simple.error', error);
        localStorage.removeItem('simple.state');
        authentication.notifyFailure("Failed with error " + error);
        return;
      } else if (!state) {
        localStorage.setItem('simple.state', LoginStates.loggingIn)
        localStorage.removeItem('simple.error')
        const scopes = getParameterByName('scopes', window.location.href);

        console.log('!state')

        const ctx = await app.getContext();
        let queryParams = [];
        queryParams.push(`client_id=${microsoftClientId}`);
        queryParams.push(`response_type=id_token token`);
        queryParams.push(`response_mode=fragment`);
        queryParams.push(`nonce=${uuidv4()}`);
        queryParams.push(`scope=${scopes}`);
        queryParams.push(`state=${LoginStates.loggingIn}`);
        queryParams.push(`redirect_uri=${window.location.origin}/login/tab-auth`);
        if (ctx.user?.loginHint) queryParams.push(`login_hint=${ctx.user?.loginHint}`);

        const url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' + queryParams.join('&')
        localStorage.setItem("simple.url", url);
        window.location.assign(url)

      } else {
        console.log('unknown state', error, state, accessToken)
        localStorage.setItem("simple.error", JSON.stringify(hashParams));
        authentication.notifyFailure("UnexpectedFailure" + error + ' ' + accessToken + ' ' + state);
      }
    }

    processAuth();

  }, [])

  return (
    <AuthWrapper dataTest="login-invite" title="" spinner={true} hideLoginLink={true} hideHeader={true}>
    </AuthWrapper>
  )
}

