import styled, { css } from "styled-components/macro";
import { tablet } from "../MediaQueries";
import Colours from "../Colours";

interface BodyProps {
  colour?: string;
  weight?: number;
  soleil?: boolean;
  size?: number;
}

const BodyBase = styled.p<BodyProps>`
  font-style: normal;
  font-weight: normal;
  ${props => css`color: ${props.colour ? props.colour : Colours.black};`};
  ${props => css`font-weight: ${props.weight ? props.weight : 400};`};
  ${props => css`font-family: ${props.soleil ? '"soleil", sans-serif' : 'proxima-nova'};`};
`

const BodyXSmall = styled<any>(BodyBase)<BodyProps>`
  font-size: 16px;
  line-height: 20px;
  @media (${tablet}) {
    font-size: 16px;
    line-height: 18px;
  }
`

const BodyXXSmall = styled<any>(BodyBase)<BodyProps>`
  font-size: 14px;
  line-height: 18px;
`

const BodyXXLSmall = styled<any>(BodyBase)<BodyProps>`
  font-size: 13px;
  line-height: 18px;
`

const BodyVerySmall = styled<any>(BodyBase)<BodyProps>`
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
`

const BodySmall = styled<any>(BodyBase)<BodyProps>`
  font-size: 16px;
  line-height: 24px;
`

const BodyRegular = styled<any>(BodyBase)<BodyProps>`
  font-size: 18px;
  line-height: 28px;
  @media (${tablet}) {
    font-size: 18px;
    line-height: 28px;
  }
`

const BodyLarge = styled<any>(BodyBase)<BodyProps>`
  font-size: 22px;
  line-height: 34px;
`

const BodyError = styled<any>(BodyRegular)<BodyProps>`
  ${props => css`font-weight: ${props.weight ? props.weight : 600};`};
  ${props => css`font-size: ${props.size ? `${props.size}px` : '18px'};`};
  color: ${Colours.red};
`

export {
  BodyXSmall,
  BodyXXSmall,
  BodyXXLSmall,
  BodyVerySmall,
  BodySmall,
  BodyRegular,
  BodyLarge,
  BodyError,
}


