import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import DialogHeader from "../../../../../dialogs/DialogHeader";
import Dialog from "../../../../../UI/molecules/Dialog";
import { closeDialog } from "../../../../../../store/ducks/dialog.duck";
import TeamMultiSelect from "../../../../../UI/organisms/team-drop-down-list/TeamMultiSelect";
import { selectAllTags } from "../../../../../../store/ducks/userTags.duck";
import { TeamEntity } from "../../../../../../models/team.models";
import { Tag } from "../../../../../../models/tags.models";
import { selectAllCheckedUsers } from "../ducks/manage-users.duck";
import { UserAvatarAndName } from "../../../../../UI/molecules/UserAvatarAndName";
import { UserInfoV2 } from "../ducks/ManageUsers.model";
import { addMultipleUsersToWorkGroup } from "../../../../../../services/Tags";
import { Center } from "../../../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../../../UI/atoms/LoadingSpinner";
import { failureNotification, successNotification } from "../../../../../../store/ducks/notification.duck";
import { loadConfig } from "../../../../../../store/ducks/config.duck";

export default function AssignToWorkGroupDialog(props: Props) {
  const dispatch = useDispatch();
  const workgroups = useSelector(selectAllTags);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState<Tag | undefined>();
  const selectedUsers = useSelector(selectAllCheckedUsers);

  const close = () => {
    dispatch(closeDialog());
  }

  const onTeamSelect = (team: TeamEntity[], workGroup: Tag[]) => {
    if (workGroup.length > 0) {
      setSelection(workGroup[0]);
    }
  }

  const done = async () => {
    if (selection && selectedUsers.length > 0) {
      setLoading(true)
      try {
        await addMultipleUsersToWorkGroup(selectedUsers.map(u => u.id), selection.id);
        dispatch(loadConfig());
        dispatch(successNotification('Users added to workgroup'));
        close();
      } catch (e) {
        dispatch(failureNotification('Failed to add users to workgroup'));
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-users-page.assign-to-workgroup" />
      <TeamMultiSelect onSelect={onTeamSelect}
                       selectedWorkGroups={selection ? [selection] : []}
                       singleSelect={true}
                       workGroups={workgroups} />
      <UserList>
        {loading ? <Center><LoadingSpinner hideBorder={true} hideText={true}/></Center> : <>
          {selectedUsers.map((user: UserInfoV2, key: number) => <UserAvatarAndName key={key}
                                                                                   firstName={user.firstName}
                                                                                   lastName={user.lastName} />)}
        </>}
      </UserList>
      <PrimaryButton fullWidth={true} style={{marginBottom: 16, marginTop: 'auto'}} className={'saveChangesButton'} click={done} text="button.save-changes" />
    </DialogWrapper>
  )
}

interface Props {
  payload: any;
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 400px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 300px;
    min-height: 410px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
  .saveChangesButton {
    margin-top: auto;
  }
`

const UserList = styled.div`
  height: 300px;
  margin: 16px 0;
  overflow-y: auto;
  .userAvatarAndName {
    margin-bottom: 8px;
  }
`
