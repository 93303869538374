import { emptyWhereaboutsDay, Period, UsersCarParkBooking, UsersWhereaboutsDay } from "../models/movements.models";
import moment, { Moment } from "moment";
import { IconPack } from "../models/company.models";
import { FreeBusyStatus } from "../services/WhereaboutOptions";
import { DATE_FORMAT } from "./DateUtils";

export function updateWhereaboutsWithParking(whereabouts: UsersWhereaboutsDay[], carParking: UsersCarParkBooking[] | undefined) {
  const updatedWhereabouts = whereabouts.map(whereaboutsDay => {
    if (!carParking) {
      return whereaboutsDay;
    }
    const carParkingOnDate = carParking.find(cp => cp.date === whereaboutsDay.date);
    if (carParkingOnDate) {
      return {
        ...whereaboutsDay,
        amGeneralParking: carParkingOnDate.am,
        pmGeneralParking: carParkingOnDate.pm,
      }
    } else {
      return whereaboutsDay;
    }
  })
  return updatedWhereabouts;
}

export function isCarParkingRequiredCheckedForWhereaboutsDay(activePeriod: Period,
                                                             activeDay: moment.Moment,
                                                             defaultParkingState: undefined | boolean,
                                                             amStatus: string,
                                                             pmStatus: string,
                                                             amRequiresParking: boolean | undefined,
                                                             pmRequiresParking: boolean | undefined) {
  if (activePeriod === Period.AllDay || activePeriod === Period.AM) {
    if (amStatus === 'OFFICE') return amRequiresParking;
  } else if (activePeriod === Period.PM) {
    if (pmStatus === 'OFFICE') return pmRequiresParking;
  }
  return defaultParkingState;
}


export function iconPackPath(iconPack: IconPack, imageName: string, freeBusyStatus: FreeBusyStatus): string {
  if (iconPack === IconPack.STANDARD) {
    return `/assets/icons/${iconPackToPath(iconPack)}/${imageName}`;
  }

  const prefixedImageName = imageName.replace('.svg', `${freeBusyStatusToPrefix(freeBusyStatus)}.svg`)
  return `/assets/icons/${iconPackToPath(iconPack)}/${prefixedImageName}`;
}

function iconPackToPath(iconPack: IconPack) {
  switch (iconPack) {
    case IconPack.STANDARD: return 'standard'
    case IconPack.BASIC: return 'basic';
    default: return 'standard';
  }
}

function freeBusyStatusToPrefix(freeBusyStatus: FreeBusyStatus) {
  switch (freeBusyStatus) {
    case FreeBusyStatus.FREE: return '-Available';
    case FreeBusyStatus.WORKING_ELSEWHERE: return '-OutOffice';
    case FreeBusyStatus.OOF: return '-Busy';
    case FreeBusyStatus.BUSY: return '-OutOffice';
    case FreeBusyStatus.TENTATIVE: return '-Busy';
    default: return '-Available';
  }
}

export function getWhereaboutsDayWithFallback(date: Moment, userId: string, whereaboutsDays: UsersWhereaboutsDay[]): UsersWhereaboutsDay {
  const searchDate = date.format(DATE_FORMAT);
  const day = whereaboutsDays.find(wd => wd.date === searchDate);
  if (day) {
    return day;
  } else {
    return emptyWhereaboutsDay(searchDate, userId)
  }
}
