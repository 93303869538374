import styled from "styled-components/macro";
import { css } from "styled-components";


interface Props {
  color: string;
  width: number;
}

export const Divider = styled.div<Props>`
  height: 1px;
  ${props => css`background-color: ${props.color}`};
  ${props => css`width: ${props.width}px`};
`;
