import React, { useState } from "react";
import { ConfigurationSection } from "../../StyleComponents";
import { SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import Colours from "../../../../UI/atoms/Colours";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { Switch } from "../../../../UI/atoms/Switch";
import { Accordian } from "../../../../../animations/AnimationVariants";
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";
import { useDispatch, useSelector } from "react-redux";
import { updateCompany } from "../../../../../services/CompanyService";
import { selectCurrentUser, updateCurrentUser } from "../../../../../store/ducks/auth.duck";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  disabled: boolean;
}

export default function HolidayApprovalsSetting(props: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectCurrentUser);
  const companyEntity = user?.companyEntity;
  const {t} = useTranslation();

  const onChange = async (enabled: boolean) => {
    if (companyEntity) {
      setLoading(true);
      try {
        await updateCompany({
          ...companyEntity,
          holidayApprovalEnabled: enabled,
          lineManagementEnabled: enabled,
          disableEditingPreviousWhereabouts: (enabled ? true : companyEntity.disableEditingPreviousWhereabouts)
        });
        await dispatch(updateCurrentUser(true));
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <ConfigurationSection style={{borderBottom: 'none'}}>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.approvals.title')}</BodyLarge>
        {!props.disabled && <>
          {loading ? <SimpleSpinner size={16} /> : <Switch disabled={props.disabled}
                                                           value={!!companyEntity?.holidayApprovalEnabled}
                                                           label="" switchClass="" onChange={val => onChange(val)}/>}
        </>}
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 24}}>{t('settings.approvals.do-holidays-require-approval')}</BodyRegular>
      {!props.disabled && <Accordian isOpen={!!companyEntity?.holidayApprovalEnabled} className="">
        <FlatContentCard>
          {companyEntity?.lineManagementEnabled ? <>
            <BodyLarge colour={Colours.blue}>{t('settings.approvals.how-it-works')}</BodyLarge>
            <BodyRegular>{t('settings.approvals.create-line-managers-message-1')}</BodyRegular>
            <BodyRegular>{t('settings.approvals.create-line-managers-message-2')}</BodyRegular>
            <BodyRegular>{t('settings.approvals.create-line-managers-message-3')}</BodyRegular>
          </> : <>
            <BodyLarge colour={Colours.blue}>{t('settings.approvals.please-note')}</BodyLarge>
            <BodyRegular style={{marginBottom: 16}}><Trans i18nKey={'settings.approvals.please-note-message-1'} components={[<strong>{t('settings.approvals.company-admins')}</strong>]} /></BodyRegular>
            <BodyRegular>{t('settings.approvals.please-note-message-2')}</BodyRegular>
          </>}
        </FlatContentCard>
      </Accordian>}
    </ConfigurationSection>
  )
}
