import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../state/app.state';
import { Tag, TagWithCount } from "../../models/tags.models";
import { createTagRequest, deleteTagRequest, getAllTags, updateTagRequest } from "../../services/Tags";
import { failureNotification, warningNotification } from "./notification.duck";
import { loadConfig } from "./config.duck";

export interface UserTags {
  loading: boolean;
  tags: TagWithCount[];
  activeTags: Tag[];
}

export const initialUserTags: UserTags = {
  loading: false,
  tags: [],
  activeTags: []
};

export const fetchTags: any = createAsyncThunk(
  'userTags/fetchTags',
  async (skip: void, thunkAPI) => {
    try {
      const allTags = await getAllTags();

      try {
        const cachedTags = (localStorage.getItem('cachedActiveTags') ?? '').split(',').map(id => parseInt(id));
        const tags = allTags.filter(at => cachedTags.includes(at.id));
        thunkAPI.dispatch(setActiveTags(tags));
      } catch (e: any) {
        localStorage.removeItem('cachedActiveTags');
      }

      return allTags
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to load tags'));
    }
  }
);

export const updateTag: any = createAsyncThunk(
  'userTags/updateTag',
  async (tag: Tag, thunkAPI) => {
    try {
      await updateTagRequest(tag);
      await thunkAPI.dispatch(loadConfig());
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to update tag'));
    }
  }
);

export const createTag: any = createAsyncThunk(
  'userTags/createTag',
  async (label: string, thunkAPI) => {
    try {
      await createTagRequest(label);
      await thunkAPI.dispatch(loadConfig());
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to create tag'));
    }
  }
);

export const deleteTag: any = createAsyncThunk(
  'userTags/deleteTag',
  async (tagId: number, thunkAPI) => {
    try {
      await deleteTagRequest(tagId);
      await thunkAPI.dispatch(loadConfig());
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification(e?.message ?? 'Failed to delete tag'));
    }
  }
);

export const toggleActiveTag: any = createAsyncThunk(
  'userTags/toggleActiveTag',
  async (tag: Tag, thunkAPI) => {
    const activeTags = (thunkAPI.getState() as AppState).userTags.activeTags;
    function refineActiveTags() {
      if (activeTags.includes(tag)) {
        return activeTags.filter((at: Tag) => at !== tag);
      } else {
        if (activeTags.length >= 5) {
          thunkAPI.dispatch(warningNotification('You cannot select more than 5 tags'));
          return activeTags;
        } else {
          return [...activeTags, tag];
        }
      }
    }

    const result = refineActiveTags();
    localStorage.setItem('cachedActiveTags', result.map(t => t.id).join(','));
    return result;
  }
);

const userTagsSlice = createSlice({
  name: 'userTags',
  initialState: initialUserTags,
  reducers: {
    resetTags: (state) => (initialUserTags),
    clearActiveTags: (state) => ({...state, activeTags: []}),
    setActiveTags: (state, action) => ({...state, activeTags: action.payload}),
    setTags: (state, action) => ({...state, tags: action.payload}),
  },
  extraReducers: {
    [createTag.pending]: (state) => ({...state, loading: true}),
    [createTag.failed]: (state) => ({...state, loading: false}),
    [createTag.fulfilled]: (state) => ({...state, loading: false}),

    [fetchTags.pending]: (state) => ({...state, loading: true}),
    [fetchTags.failed]: (state) => ({...state, loading: false}),
    [fetchTags.fulfilled]: (state, action) => ({...state, tags: action.payload, loading: false}),

    [toggleActiveTag.fulfilled]: (state, action) => ({...state, activeTags: action.payload}),

    [updateTag.pending]: (state) => ({...state, loading: true}),
    [updateTag.failed]: (state) => ({...state, loading: false}),
    [updateTag.fulfilled]: (state, action) => ({...state, loading: false}),

    [deleteTag.pending]: (state) => ({...state, loading: true}),
    [deleteTag.failed]: (state) => ({...state, loading: false}),
    [deleteTag.fulfilled]: (state, action) => ({...state, loading: false}),
  },
});



export default userTagsSlice.reducer;
export const {
  resetTags,
  clearActiveTags,
  setActiveTags,
  setTags,
} = userTagsSlice.actions;


// Selectors
export const selectTagsLoading = (state: AppState) => state.userTags.loading;
export const selectAllTags = (state: AppState) => state.userTags.tags;
export const selectActiveTags = (state: AppState) => state.userTags.activeTags;
