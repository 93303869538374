import moment, { Moment } from "moment";
import { HolidayBookingEntity } from '../services/HolidayService';
import { getListOfDaysBetween, workdayCount } from '../utils/DateUtils';
import { SimpleUser } from "./user.models";

export enum HolidayRecurringDay {
  firstMonday=0,
  firstDayOfMonth=1,
}

export enum HolidayBookingProcessingState {
  none, pending, done, error
}

export class Holiday {
  constructor(public id: number | null,
              public name: string,
              public from: string,
              public to: string,
              public emailCaledarEvent: boolean) {
  }

  public fromDate(): Moment {
    return moment(this.from);
  }

  public toDate(): Moment {
    return moment(this.to);
  }

}

export class HolidayBooking {
  public bookedDays: Moment[];
  constructor(private bookingEntity: HolidayBookingEntity) {
    this.bookedDays = getListOfDaysBetween(moment(bookingEntity.fromDate), moment(bookingEntity.toDate));
  }

  public getId(): number {
    return this.bookingEntity.id;
  }

  public getName(): string {
    return this.bookingEntity.name;
  }

  public getEmailNotification(): boolean {
    return this.bookingEntity.emailNotification;
  }

  public getLength(): number {
    return workdayCount(this.getFromDate(), this.getToDate());
  }

  public getFromDate(): Moment {
    return moment(this.bookingEntity.fromDate);
  }

  public getToDate(): Moment {
    return moment(this.bookingEntity.toDate);
  }
}


export interface SimpleUserWithHolidayUsage extends SimpleUser {
  daysUsed: number;
  totalAllowance: number;
}

export interface CompanyHolidayUsageSummary {
  holidayUsages: SimpleUserWithHolidayUsage[];
  fromDate: string;
  toDate: string;
}
