import { useCallback, useEffect, useState } from 'react';
import { getWeeklyTeamNoteForUser, WeekNote } from "../../../../services/WeekNotesService";
import { Moment } from "moment";

export function WithSingleUserWeekNotes(userId: string, date: Moment) {
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(true);
  const [notes, setNotes] = useState<WeekNote | undefined>()

  const makeRequest = useCallback(async () => {
    setRequested(true);
    setLoading(true);
    try {
      const resp = await getWeeklyTeamNoteForUser(userId, date)
      setNotes(resp)
    } finally {
      setLoading(false);
    }
  }, [date, userId])

  useEffect(() => {
    if (!userId || !date || loading || requested) return;
    setRequested(true);
    setLoading(true);
    makeRequest();
  }, [date, loading, makeRequest, requested, userId])

  useEffect(() => {
    if (userId && date) {
      setRequested(false);
    }
  }, [userId, date]);

  return {
    loading: loading,
    hasRequested: requested,
    notes: notes,
    load: makeRequest,
  }
}

