import { FixedWhereabouts, UNKNOWN_OPTION, WhereaboutsOption } from "../services/WhereaboutOptions";
import Colours from "../components/UI/atoms/Colours";

const colorList = [
  '#5653D2',
  '#88A7F5',
  '#4471E5',
  '#0ADCFE',
  '#C0D1FA',
  '#947AC9',
  '#7C43A9',
  '#1C2684',
]

export function whereaboutsToColour(list: WhereaboutsOption[], key: string): string {
  const idx = list.findIndex(wo => wo.key === key);

  switch (key) {
    case UNKNOWN_OPTION.key: return '#BCC6C7';
    case FixedWhereabouts.Office: return '#3541AE';
    case FixedWhereabouts.HolidayPending:
    case FixedWhereabouts.HolidayApproved:
    case FixedWhereabouts.Holiday: return '#FFB84C';
    case FixedWhereabouts.Home: return '#3E80FF';
    case FixedWhereabouts.HomeApproved: return '#3E80FF';
    case FixedWhereabouts.HomePending: return '#3E80FF';
  }

  if (colorList.length > idx || idx < 0) {
    return colorList[idx] || Colours.black;
  } else {
    return Colours.black;
  }
}

export function hexToRGBA(hex: string, alpha: number): string {
  const h = "0123456789ABCDEF";
  const r = h.indexOf(hex[1])*16+h.indexOf(hex[2]);
  const g = h.indexOf(hex[3])*16+h.indexOf(hex[4]);
  const b = h.indexOf(hex[5])*16+h.indexOf(hex[6]);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
