import React from 'react';
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../../../../UI/atoms/fonts/Body";
import { LinkButton } from "../../../../../UI/atoms/buttons/LinkButton";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { tablet } from "../../../../../UI/atoms/MediaQueries";
import { useTranslation } from "react-i18next";

export function ReportingContentCardFull(props: Props) {
  const {imageUrl, title, description, link, isVisible} = props;
  const {t} = useTranslation();
  if (!isVisible) {
    return null;
  }
  return (
    <Wrapper>
      <Image src={imageUrl} alt={""} />
      <div style={{'alignSelf': 'center'}}>
        <HeadlineSmall style={{marginBottom: 8}}>{t(title)}</HeadlineSmall>
        <BodyLarge style={{marginBottom: 24}}>{t(description)}</BodyLarge>
        <LinkButton text={"button.view-report"} link={link} type={"outline"} />
      </div>
    </Wrapper>
  )
}

interface Props {
  isVisible: boolean;
  imageUrl: string;
  title: string;
  description: string;
  link: string;
}

const Image = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 12px;
  @media (${tablet}) {
    width: 200px;
    height: 200px;
    margin-right: 90px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colours.white};
  margin-bottom: 32px;
  width: 100%;
  padding: 32px;
  border-radius: 16px;

  @media (${tablet}) {
    flex-direction: row;
    padding: 52px 72px;
  }
`
