import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectedRootOffice } from "../../../../../store/ducks/officeView.duck";
import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { selectOfficesByParentId } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { AppState } from "../../../../../store/state/app.state";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { getOfficeWithCapacity, OfficeWithCapacity } from "../../../../../services/OfficeCapacityService";
import { FloorUserListView } from "./FloorUserListView";
import { selectActiveDay } from "../../../../../store/ducks/editMovements.duck";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { useTranslation } from "react-i18next";

export function AreaAndDeskUsageView() {
  const rootOffice = useSelector(selectedRootOffice);
  const activeDate = useSelector(selectActiveDay);
  const officesOfRoot = useSelector((state: AppState) => selectOfficesByParentId(state, rootOffice?.id));
  const [emptyOffices, setEmptyOffices] = useState<OfficeWithCapacity[]>([]);
  const [usedOffices, setUsedOffices] = useState<OfficeWithCapacity[]>([]);
  const [loaded, setLoaded] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (officesOfRoot && activeDate && !loaded) {
      setLoaded(true)
      Promise.all(officesOfRoot.map((oor: OfficeEntity) => getOfficeWithCapacity(oor, activeDate)))
        .then((officeWithCapacity: any[]) => {
          setEmptyOffices(officeWithCapacity.filter((owc: OfficeWithCapacity) => owc.capacity.amUsed + owc.capacity.pmUsed === 0));
          setUsedOffices(officeWithCapacity.filter((owc: OfficeWithCapacity) => owc.capacity.amUsed + owc.capacity.pmUsed > 0));
        })
    }
  }, [loaded, activeDate, officesOfRoot]);

  return (
    <>
      <HeadlineSmall style={{marginBottom: 16}}>{t('office-space-bookings')}</HeadlineSmall>
      {usedOffices.map((owc: OfficeWithCapacity, key: number) => (
        <FloorContainer key={key}>
          <FloorUserListView rootOffice={rootOffice} floor={owc.office} capacity={owc.capacity} loadUsers={true} />
        </FloorContainer>
      ))}
      <HeadlineSmall style={{marginBottom: 16, marginTop: 48}}>{t('office-space-no-bookings')}</HeadlineSmall>
      {emptyOffices.map((owc: OfficeWithCapacity, key: number) => (
        <FloorContainer key={key}>
          <FloorUserListView rootOffice={rootOffice} floor={owc.office} capacity={owc.capacity} />
        </FloorContainer>
      ))}
    </>
  )
}

export const FloorContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0 20px 26px 2px rgba(186, 188, 191, 0.15);
  border-radius: 24px;
  width: 100%;
  margin-bottom: 16px;
  padding: 24px;
  @media (${tablet}) {
    padding: 32px 48px;
  }
`
