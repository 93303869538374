import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import {
  confirmDeskForReservation,
  goBackToPreviousState,
  selectOfficeForReservation,
  selectSelectedArea,
  selectSelectedDesk,
  selectSelectedFloor,
  selectSelectionOffice
} from "../../../../store/ducks/deskReservation.duck";
import { MobileOnly } from "../../../UI/atoms/DeviceSpecific";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import DialogHeader from "../../DialogHeader";
import { BodyLarge, BodyRegular, BodySmall } from "../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import { Column, Row } from "../../../UI/atoms/StructuralLayout";
import Colours from "../../../UI/atoms/Colours";
import { FavouriteImage } from "../../../UI/atoms/FavouriteImage";
import {
  selectFavouriteDesks,
  selectFavouriteDesksLoading,
  toggleDefaultOffice,
  toggleFavoriteDesk
} from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { WithFavouriteOffices } from "../../../../hooks/AdvanceHotDeskingHooks";
import { OfficeEntityWithFavourite } from "../../../../services/AdvanceHotDeskingService";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { DeskPickerContentCard } from "./DeskPickerContentCard";
import { SelectedParkingContentCard } from "./SelectedParkingContentCard";
import { selectActiveDay, selectUserRequiredParking } from "../../../../store/ducks/editMovements.duck";

export function LocationSelectionDone() {
  const dispatch = useDispatch();
  const close = () => dispatch(closeDialog());
  const {t} = useTranslation();

  const isParkingSelected = useSelector(selectUserRequiredParking);
  const selectedOffice = useSelector(selectSelectionOffice);
  const selectedFloor = useSelector(selectSelectedFloor);
  const selectedArea = useSelector(selectSelectedArea);
  const allocatedDesk = useSelector(selectSelectedDesk)

  const favoritedOffices = WithFavouriteOffices();
  const favoritedDesks = useSelector(selectFavouriteDesks);
  const favoritingDesksLoading = useSelector(selectFavouriteDesksLoading);
  const date = useSelector(selectActiveDay);

  const isFavorited = useMemo(() => {
    if (allocatedDesk) {
      return !!favoritedDesks.find(fd => fd.deskId === allocatedDesk.id);
    } else {
      const matchingArray = [selectedOffice?.id, selectedFloor?.id, selectedArea?.id].filter(a => !!a);
      return favoritedOffices.filter((fo: OfficeEntityWithFavourite) => matchingArray.includes(fo.id)).length > 0
    }
  }, [favoritedOffices, selectedOffice, selectedFloor, selectedArea, allocatedDesk, favoritedDesks]);

  const makeFavourite = (e: Event) => {
    e.stopPropagation();
    if (allocatedDesk) {
      dispatch(toggleFavoriteDesk(allocatedDesk?.id))
    } else if (selectedArea) {
      dispatch(toggleDefaultOffice(selectedArea?.id))
    } else if (selectedFloor) {
      dispatch(toggleDefaultOffice(selectedFloor?.id))
    } else {
      dispatch(toggleDefaultOffice(selectedOffice?.id))
    }
  };

  const save = () => {
    if (allocatedDesk) {
      dispatch(confirmDeskForReservation(allocatedDesk));
    } else if (selectedArea) {
      dispatch(selectOfficeForReservation(selectedArea));
    } else if (selectedFloor) {
      dispatch(selectOfficeForReservation(selectedFloor));
    } else if (selectedOffice) {
      dispatch(selectOfficeForReservation(selectedOffice));
    }
  }

  const goBackToPreviousView = () => {
    dispatch(goBackToPreviousState());
  }

  return (
    <>
      <MobileOnly>
        <GoBackButton onClick={close} text="back-to-whereabouts"/>
      </MobileOnly>
      <DialogHeader title="desk-booking.confirm-booking"/>
      {date && <BodyLarge weight={600} style={{marginBottom: 16}}>{date.format('dddd DD MMMM')}</BodyLarge>}

      <DeskPickerContentCard style={{marginBottom: 8}}>
        <Row style={{marginBottom: 12}}>
          <Column style={{width: '100%'}}>
            <BodyRegular weight={600}>{selectedOffice?.label}</BodyRegular>
            <BodySmall>{selectedFloor?.label}{!!selectedArea ? `: ${selectedArea.label}` : ''}</BodySmall>
            {allocatedDesk && <BodySmall>{allocatedDesk?.label}</BodySmall>}
          </Column>
        </Row>
        <Row style={{alignSelf: 'center', cursor: 'pointer'}}
             onClick={(e: any) => makeFavourite(e)}
             data-test={'add-to-favourites'}>

          <IconWrapper>
            {favoritingDesksLoading ? <SimpleSpinner size={24} /> : <FavouriteImage src={isFavorited ? '/assets/icons/StarActive.svg' : '/assets/icons/Star.svg'}
                                                                                    alt="Favourite"
                                                                                    style={{marginRight: 12}}/>}
          </IconWrapper>

          <BodyRegular colour={Colours.blue}
                       weight={600}>{t(isFavorited ? 'added-to-favourites' : 'add-to-your-favourites')}</BodyRegular>
        </Row>
      </DeskPickerContentCard>

      {isParkingSelected && <SelectedParkingContentCard />}

      <PrimaryButton click={save}
                     text="button.confirm"
                     size="large"
                     style={{marginBottom: 16, marginTop: 48}}
                     fullWidth={true}
                     dataTest={'confirm-desk-reservation-button'}/>

      <OutlineButton click={goBackToPreviousView}
                     text="button.back-and-change"
                     size="large"
                     fullWidth={true} />
    </>
  )
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
`
