import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../UI/organisms/layout/Layout';
import { OnboardingScreen, selectActiveScreen } from '../../../store/ducks/onboarding.duck';
import OnboardTeamMember from './components/OnboardTeamMember';
import OnboardingComplete from './components/OnboardingComplete';
import OnboardingCreateTeam from './components/OnboardingCreateTeam';
import './onboarding.scss';
import { FadeAndPop } from "../../../animations/AnimationVariants";
import { setUser } from "../../../store/ducks/auth.duck";
import OnboardingCreateOffice from './components/OnboardingCreateOffice';
import SetupHolidayRules from './components/SetupHolidayRules';
import SetupHolidayAllowance from './components/SetupHolidayAllowance';
import styled from "styled-components/macro";
import { Auth } from "../../../services/Auth";
import { tablet } from "../../UI/atoms/MediaQueries";
import OnboardingOfficeCreated from "./components/OnboardingOfficeCreated";
import OnboardingWelcome from "./components/OnboardingWelcome";
import OnboardingFeatures from "./components/OnboardingFeatures";
import OnboardingCustomWhereabouts from "./components/OnboardingCustomWhereabouts";
import OnboardingIcons from "./components/OnboardingIcons";

export default function OnboardingPage() {
  return (
    <Layout style={{paddingTop: 48}}>
      <OnboardingContent/>
    </Layout>
  );
}

function OnboardingContent() {
  const activeScreen = useSelector(selectActiveScreen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeScreen === OnboardingScreen.Complete) {
      Auth.current(false).then((user) => dispatch(setUser(user)))
    }
  }, [dispatch, activeScreen]);

  console.log('active screen', activeScreen)

  return (
    <OnboardingWrapper data-test="onboarding">

      <FadeAndPop hidden={activeScreen !== OnboardingScreen.CreateTeam}>
        <OnboardingCreateTeam/>
      </FadeAndPop>

      <FadeAndPop hidden={activeScreen !== OnboardingScreen.AddMember}>
        <OnboardTeamMember/>
      </FadeAndPop>

      <FadeAndPop hidden={activeScreen !== OnboardingScreen.Complete}>
        <OnboardingComplete/>
      </FadeAndPop>

      <FadeAndPop hidden={activeScreen !== OnboardingScreen.SetupOffice}>
        <OnboardingCreateOffice/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.OfficeAdded}>
        <OnboardingOfficeCreated/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.SetupHolidayAllowance}>
        <SetupHolidayAllowance/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.SetupHolidayRules}>
        <SetupHolidayRules/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.Welcome}>
        <OnboardingWelcome/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.Features}>
        <OnboardingFeatures/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.CustomWhereabouts}>
        <OnboardingCustomWhereabouts/>
      </FadeAndPop>
      <FadeAndPop hidden={activeScreen !== OnboardingScreen.Icons}>
        <OnboardingIcons/>
      </FadeAndPop>
    </OnboardingWrapper>
  )
}

const OnboardingWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  @media (${tablet}) {
    max-width: 480px;
  }
`;

