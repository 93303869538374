import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { Auth } from '../../../services/Auth';
import { redirectTo, setUser } from '../../../store/ducks/auth.duck';
import { AppState } from '../../../store/state/app.state';
import { enrichError, Error, ErrorCode } from '../../../utils/error-helper';
import { AuthType } from "../../../utils/AuthUtils";

export interface LoginPageState {
  smartError?: Error;
  processing: boolean;
  email: string;
}

export const initialLoginPageState: LoginPageState = {
  processing: false,
  smartError: undefined,
  email: '',
}

export const loginWithInvite: any = createAsyncThunk(
  'loginPage/loginWithInvite',
  async (creds: any, thunkAPI) => {
    await thunkAPI.dispatch(loginActions.setProcessing(true));

    try {
      const user = await Auth.loginWithInvitation({
        email: creds.email.trim(),
        password: creds.password,
        inviteId: creds.inviteId,
        allowMarketingEmails: creds.allowMarketingEmails,
        authType: AuthType.Standard,
      });
      await thunkAPI.dispatch(setUser(user));
    } catch (err: any) {
      handleError(err, thunkAPI.dispatch);
    }
  }
)

export const login: any = createAsyncThunk(
  'loginPage/login',
  async (creds: any, thunkAPI) => {
    await thunkAPI.dispatch(loginActions.setProcessing(true));
    await thunkAPI.dispatch(loginActions.setEmail(creds.email));

    try {
      const user = await Auth.login({ email: creds.email, password: creds.password });
      await thunkAPI.dispatch(setUser(user));
    } catch (err: any) {
      console.log(err)
      handleError(JSON.parse(err?.message), thunkAPI.dispatch);
    }
  }
)

const loginPageSlice = createSlice({
  name: 'loginPage',
  initialState: initialLoginPageState,
  reducers: {
    setSmartError: (state, action) => ({ ...state, smartError: action.payload }),
    clearSmartError: (state) => ({ ...state, smartError: undefined }),
    setProcessing: (state, action) => ({ ...state, processing: action.payload}),
    setEmail: (state, action) => ({ ...state, email: action.payload}),
  },
  extraReducers: {
    [login.fulfilled]: (state) => ({ ...state, processing: false, }),
    [login.loginWithInvite]: (state) => ({ ...state, processing: false, }),
  }
})

export default loginPageSlice.reducer
export const loginActions = loginPageSlice.actions;

// Selectors
export const selectLoginErrorMessage = (state: AppState) => state.loginPage.smartError;
export const selectIsLoading = (state: AppState) => state.loginPage.processing;
export const selectEmail = (state: AppState) => state.loginPage.email;


function handleError(error: any, dispatch: any) {
  const enrichedError = enrichError(error.message);
  if (enrichedError?.id === ErrorCode.VerificationRequired) {
    dispatch(redirectTo('/verification-required'))
  } else {
    dispatch(loginActions.setSmartError(enrichedError));
    throw error;
  }
}
