import { Permission } from "../../../../../../models/user.models";

export interface SortOption {
  value: SortOptions;
  label: string;
}

export enum SortOptions {
  usernameAz = 'nameAz',
  usernameZa = 'nameZa',
  loggedIn = 'loggedIn',
  teamAz = 'teamAz',
  teamZa = 'teamZa',
  officeAz = 'officeAz',
  officeZa = 'officeZa',
}

export const MANAGE_USERS_SORT_OPTIONS = [
  {value: SortOptions.usernameAz, label: 'Sort: Name (A - Z)'},
  {value: SortOptions.usernameZa, label: 'Sort: Name (Z - A)'},
  {value: SortOptions.loggedIn, label: 'Sort: Has logged in'},
  {value: SortOptions.teamAz, label: 'Sort: Team (A - Z)'},
  {value: SortOptions.teamZa, label: 'Sort: Team (Z - A)'},
  {value: SortOptions.officeAz, label: 'Sort: Office (A - Z)'},
  {value: SortOptions.officeZa, label: 'Sort: Office (Z - A)'},
]

export const MANAGE_USERS_SORT_OPTIONS_FOR_OFFICES = [
  {value: SortOptions.usernameAz, label: 'Sort: Name (A - Z)'},
  {value: SortOptions.usernameZa, label: 'Sort: Name (Z - A)'},
]

export const MANAGE_USERS_SORT_OPTIONS_FOR_MISC = [
  {value: SortOptions.usernameAz, label: 'Sort: Name (A - Z)'},
  {value: SortOptions.usernameZa, label: 'Sort: Name (Z - A)'},
]

export interface UserInfoV2 {
  id: string;

  firstName: string;
  lastName: string;
  name: string;
  email: string;
  role: Permission;
  teamName: string;

  officeId: number;
  defaultOfficeName: string;

  firstAider: boolean;
  fireWarden: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;

  hasLoggedIn: boolean;
}

export interface UserInfoDetails {
  totalUsers: number;
  spacesFree: number;
}
