import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { Moment } from "moment";
import { SimpleUserWithLocation } from "../../../../models/movements.models";
import { requestUsersWithResponsibilitiesInOfficeOn } from "../../../../services/ResponsibilitiesService";
import { DailyFireWardensInOffice } from "./DailyFireWardensInOffice";
import { DailyFirstAidersInOffice } from "./DailyFirstAidersInOffice";

export function DailyResponsibilityContentCards(props: Props) {
  const {office, date} = props;
  const [loading, setLoading] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);
  const [usersInOffice, setUsersInOffice] = useState<SimpleUserWithLocation[]>([]);
  const timerRef = useRef(0);

  const firstAiders = useMemo(() => usersInOffice.filter(u => u.firstAider), [usersInOffice]);
  const fireWardens = useMemo(() => usersInOffice.filter(u => u.fireWarden), [usersInOffice]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, [])

  useEffect(() => {
    if (office && date) {
      setLoading(false);
      setUsersInOffice([]);
      setHasRequested(false);
      timerRef.current = setTimeout(() =>
        requestUsersWithResponsibilitiesInOfficeOn(office, date)
          .then(resp => setUsersInOffice(resp))
          .finally(() => {
            setLoading(false);
            setHasRequested(true);
          }), 1500)
    }
  }, [office, date]);

  return (
    <>
      <DailyFireWardensInOffice usersInOffice={fireWardens} hasRequested={hasRequested} loading={loading} />

      <DailyFirstAidersInOffice usersInOffice={firstAiders} hasRequested={hasRequested} loading={loading} />
    </>
  )
}

interface Props {
  office?: OfficeEntity;
  date?: Moment;
}
