import React from 'react';
import { HeadlineLarge } from "../../../UI/atoms/fonts/Headline";
import { BodyLarge, BodyRegular } from "../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";

export function CreateFloorPlanInstructions() {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <HeadlineLarge style={{marginBottom: 12}}>{t('settings.create-floor-plan')}</HeadlineLarge>
      <BodyLarge style={{marginBottom: 24}}>{t('settings.create-floor-plan-description')}</BodyLarge>
      <img style={{marginBottom: 42}} src="/assets/images/floor-plan-image.svg" aria-hidden={true} alt="Floor plan image" />
      <BodyRegular style={{marginBottom: 32}}>
        <Trans i18nKey={'settings.create-floor-plan-instructions'} components={[<a href={'mailto:hello@team-today.com'}>hello@team-today.com</a>]} />
      </BodyRegular>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 520px;
  padding: 16px 16px 0 16px;
  img {
    max-width: 100%;
  }
`;
