import React from 'react';
import { Column } from "../../../UI/atoms/StructuralLayout";
import { useTranslation } from "react-i18next";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { Checkbox } from "../../../UI/atoms/Checkbox";
import { MeetRoomCapacityBands } from "../models/MeetingRooms";
import { useDispatch, useSelector } from "react-redux";
import { selectedMeetingRoomCapacityBand, setMeetingRoomBand } from "../models/room-finder.duck";

export function NumberOfPeopleOption(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const capacityBand = useSelector(selectedMeetingRoomCapacityBand);

  const onChange = (val: boolean, capacityBands: MeetRoomCapacityBands) => {
    dispatch(setMeetingRoomBand(capacityBands));
  }

  return (
    <Column>
      <BodyRegular weight={600} style={{marginBottom: 16}}>{t('room-finder.number-of-people.title')}</BodyRegular>

      <Checkbox value={capacityBand === MeetRoomCapacityBands.ANY}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetRoomCapacityBands.ANY)}
                text={'room-finder.number-of-people.any'} />

      <Checkbox value={capacityBand === MeetRoomCapacityBands.OneToTwo}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetRoomCapacityBands.OneToTwo)}
                text={'room-finder.number-of-people.1to2'} />

      <Checkbox value={capacityBand === MeetRoomCapacityBands.ThreeToFive}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetRoomCapacityBands.ThreeToFive)}
                text={'room-finder.number-of-people.3to5'} />

      <Checkbox value={capacityBand === MeetRoomCapacityBands.SixToEight}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetRoomCapacityBands.SixToEight)}
                text={'room-finder.number-of-people.6to8'} />

      <Checkbox value={capacityBand === MeetRoomCapacityBands.MoreThanEight}
                style={{marginBottom: 12}}
                onChange={(val: boolean) => onChange(val, MeetRoomCapacityBands.MoreThanEight)}
                text={'room-finder.number-of-people.8orMore'} />

    </Column>
  )
}

interface Props {
}
