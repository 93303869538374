import React, { useEffect, useState } from 'react';
import { HeadlineLarge } from '../../../UI/atoms/fonts/Headline';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadOrphanedUsers,
  selectOrphanedUsers,
  updateUserTeam,
} from '../../../../store/ducks/management.duck';
import { UserAvatarAndName } from '../../../UI/molecules/UserAvatarAndName';
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { LinkButton } from "../../../UI/atoms/buttons/LinkButton";
import TeamDropDownList from '../../../UI/organisms/team-drop-down-list/TeamDropDownList';
import { BasicUserInfo } from '../../../../models/user.models';
import { Row } from '../../../UI/atoms/StructuralLayout';
import { useTranslation } from "react-i18next";
import { selectAllTeams } from "../../../../store/ducks/config.duck";

export default function OrphanedUsersPage() {
  const orphanedUsers = useSelector(selectOrphanedUsers);
  const [requestedOrphanedUsers, setRequestedOrphanedUsers] = useState(false);
  const teams = useSelector(selectAllTeams);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (!orphanedUsers.length && !requestedOrphanedUsers) {
      dispatch(loadOrphanedUsers());
      setRequestedOrphanedUsers(true);
    }
  }, [dispatch, orphanedUsers, requestedOrphanedUsers]);

  const setTeam = (user: BasicUserInfo, value: string) => dispatch(updateUserTeam({user: user, teamId: value}));

  if (orphanedUsers && requestedOrphanedUsers) {
    return (
      <div className="management__complete">
        <Row>
          <HeadlineLarge className="management__title">{t('settings.all-users-have-team')}</HeadlineLarge>
          <img src="/assets/images/woman-on-mobile.svg" alt="Everyone has been assigned"/>
        </Row>
        <LinkButton text="button.done" link="/manage/teams-and-users" size="large" type="outline"/>
      </div>
    )
  }

  return (
    <Wrapper>
      <div className="management__list">
        {orphanedUsers.map((user, key) => (
          <Row key={key} className="management__row management__teamOption">
            <UserAvatarAndName firstName={user.firstName} lastName={user.lastName}/>
            <TeamDropDownList teams={teams} onSelect={(teamId: string) => setTeam(user, teamId)} selectedTeamId={user.teamId} />
          </Row>
        ))}
      </div>
    </Wrapper>
  );
}

function Wrapper(props: { children: any }) {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <HeadlineLarge className="management__title">{t('settings.assign-users')}</HeadlineLarge>
      <BodyRegular>{t('settings.users-not-in-team')}</BodyRegular>
      {props.children}
    </React.Fragment>
  )
}
