import React from 'react';
import { Center } from "../../../UI/atoms/StructuralLayout";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";

export function NoMeetingRoomsInOfficeMessage(props: Props) {
  const {t} = useTranslation();

  return (
    <Center>
      <HeadlineSmall style={{marginTop: '10vh'}}>
        {t('room-finder.no-meeting-rooms-in-office')}
      </HeadlineSmall>
    </Center>
  )
}

interface Props {
}
