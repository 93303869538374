import React, { useMemo } from 'react';
import { CapacityResponse } from "../../../../../services/OfficeCapacityService";
import styled from "styled-components/macro";
import { BodyVerySmall } from "../../../atoms/fonts/Body";
import { Row } from "../../../atoms/StructuralLayout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectActivePeriod, selectUserRequiredParking } from "../../../../../store/ducks/editMovements.duck";
import { Period } from '../../../../../models/movements.models';
import { SimpleSpinner } from "../../../atoms/SimpleSpinner";

export function CarParkingCapacityLabel(props: Props) {
  const {capacity, isCarParkingEnabledForCompany} = props;
  const period = useSelector(selectActivePeriod);
  const userRequiresParkingSpace = useSelector(selectUserRequiredParking)
  const {t} = useTranslation();

  const parkingEnabled = useMemo(() => {
    return isCarParkingEnabledForCompany && userRequiresParkingSpace;
  }, [isCarParkingEnabledForCompany, userRequiresParkingSpace]);

  const spaceRemaining = useMemo(() => {
    switch (period) {
      case Period.AM: return (capacity?.carParkingCapacity ?? 0) - (capacity?.carParkingUsedAm ?? 0);
      case Period.PM: return (capacity?.carParkingCapacity ?? 0) - (capacity?.carParkingUsedPm ?? 0);
      case Period.AllDay: return (capacity?.carParkingCapacity ?? 0) - Math.max(capacity?.carParkingUsedAm ?? 0, capacity?.carParkingUsedPm ?? 0);
    }
  }, [capacity, period]);

  if (!parkingEnabled) {
    return null;
  }

  return (
    <Row style={{alignItems: 'center', minHeight: 24}}>
      {!!capacity ? <CarParkingAvailabilityIcon src={spaceRemaining > 0 ? '/assets/icons/CircleTick.svg' : '/assets/icons/Info.svg'} alt={''} /> : <SimpleSpinner size={20} />}
      <BodyVerySmall style={{marginLeft: 4}}>{t(spaceRemaining > 0 ? 'whereabouts.parking-available' : 'whereabouts.no-available')}</BodyVerySmall>
    </Row>
  )
}

interface Props {
  capacity?: CapacityResponse;
  isCarParkingEnabledForCompany: boolean;
}

const CarParkingAvailabilityIcon = styled.img`
`
