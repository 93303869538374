import { agent } from './agent';

export async function loadTeams() {
    return await agent.requests.get('/api/team/list');
};

export async function getTeamMembers(teamId) {
    return agent.requests.get(`/api/team/${teamId}/members`, true);
};

export async function sendInvite(email, firstname, lastname, teamId) {
    return agent.requests.post('/api/invite/team', {
        firstname: firstname.trim(),
        lastname: lastname.trim(),
        email: email.trim(),
        teamId: teamId.trim(),
    }, true);
}

export async function createTeamRequest(teamName, addUserToTeam) {
    return await agent.requests.post('/api/team', {
        teamName: teamName,
        addRequestingUserToTeam: addUserToTeam,
    }, true);
}

export async function deleteTeamRequest(teamId) {
    await agent.requests.del(`/api/team/${teamId}`, true);
}

export async function updateTeamRequest(teamId, teamName) {
    await agent.requests.put('/api/team', {
        id: teamId.trim(),
        teamName: teamName.trim(),
    });
}

export async function updateTeamAssignedOfficesRequest(teamId, officeIds) {
    await agent.requests.put('/api/team/offices', {
        id: teamId.trim(),
        officeIds: officeIds,
    });
}

export async function updateTeamAssignedDepartmentsRequest(teamId, departmentIds) {
    await agent.requests.put('/api/team/departments', {
        id: teamId.trim(),
        departmentIds: departmentIds,
    });
}

export async function removeAdminRights(userId, teamId) {
    return await agent.requests.post(`/api/team/admin/remove`, {userId, teamId}, true);
}

export async function addAdminRights(userId, teamId) {
    return await agent.requests.post(`/api/team/admin/add`, {userId, teamId}, true);
}

export async function putUserInTeam(userId, teamId) {
    return await agent.requests.put(`/api/team/${teamId}/user/${userId}`, null, true);
}
