import { BasicUserInfo } from "./user.models";
import { MovementDay } from "./movements.models";

export enum ApprovalType {
  workFromHome = 'WORK_FROM_HOME',
  holiday = 'HOLIDAY',
}

export enum ApprovalState {
  approved = 'APPROVED',
  cancelled = 'CANCELLED',
  pending = 'PENDING',
  declined = 'DECLINED',
  unknown = 'unknown'
}

export enum ApprovalsView {
  pending, approved, declined
}

export interface PendingApproval {
  id: number;
  userInfo: BasicUserInfo;
  date: string;
  approvalType: ApprovalType;
  movementDay?: MovementDay;
  message?: string;
  createdAt: number;
}

export interface GroupedApprovalRequest {
  approvalIds: number[];
  firstName: string;
  lastName: string;
  userId: string;
  approvalState: ApprovalState;
  fromDate: string;
  toDate: string;
  duration: number;
}

export function getPendingApprovalMessageAction(approval: PendingApproval, approved: boolean) {
  if (approval.approvalType === ApprovalType.workFromHome) {
    return `Work from home request ${approved ? 'approved' : 'rejected'}.`;
  } else {
    return 'Success';
  }
}
