import React from 'react';
import { HeadlineLarge } from "../../atoms/fonts/Headline";
import { BodyRegular } from "../../atoms/fonts/Body";
import { LinkButton } from "../../atoms/buttons/LinkButton";
import styled from "styled-components";
import { PrimaryButton } from "../../atoms/buttons/PrimaryButton";
import { Trans, useTranslation } from "react-i18next";

export enum MsalErrorCodes {
  accessDenied = 'access_denied',
  consentRequired = 'consent_required'
}

export function AzureErrorHandlingMessages(props: Props) {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <HeadlineLarge style={{marginBottom: 16}}>{t(props.title)}</HeadlineLarge>
      {props.errorCode === MsalErrorCodes.consentRequired && <>
        <BodyRegular><Trans i18nKey="errors.azure.consent-message.message-1" /></BodyRegular>
        <BodyRegular style={{marginBottom: 16}}><Trans i18nKey="errors.azure.consent-message.message-2" /></BodyRegular>
      </>}
      {props.errorCode === MsalErrorCodes.accessDenied && <>
        <BodyRegular><Trans i18nKey="errors.azure.access-denied.message-1" /></BodyRegular>
        <BodyRegular style={{marginBottom: 16}}><Trans i18nKey="errors.azure.access-denied.message-1" /></BodyRegular>
      </>}
      {props.errorCode !== MsalErrorCodes.accessDenied && props.errorCode !== MsalErrorCodes.consentRequired && <>
        <BodyRegular><Trans i18nKey="errors.azure.not-access-den-and-not-consent.message-1" /></BodyRegular>
        <BodyRegular style={{marginBottom: 16}}><Trans i18nKey="errors.azure.not-access-den-and-not-consent.message-1" /></BodyRegular>
      </>}
      <PrimaryButton click={props.onRetryClicked} text="button.retry" fullWidth={true} size="large" style={{marginBottom: 24}} />
      {props.goBackLink && <LinkButton type="outline" text="button.go-back" link={props.goBackLink} fullWidth={true} size="large" />}
    </Wrapper>
  )
}

interface Props {
  errorCode: any;
  title: string;
  onRetryClicked: any;
  goBackLink?: string;
}

const Wrapper = styled.div`
`
