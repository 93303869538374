import React from 'react';
import styled from "styled-components/macro";

export function MentalHealthAiderBadge(props: Props) {
  return (
    <MentalHealthAiderWrapper data-test="mental-health-aider-badge" style={props.style ?? {}} title="This person is a designated mental health first aider">
      <img src="/assets/icons/mental-health-first-aider.svg" alt="Mental health first aider" />
    </MentalHealthAiderWrapper>
  )
}

interface Props {
  style?: any;
}

const MentalHealthAiderWrapper = styled.div`
`
