import { useEffect, useState } from "react";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { Moment } from "moment";
import { DeskReservations, getDeskReservationsInOffice } from "../services/DeskBookingService";

export function DeskReservationsInOffice(office: OfficeEntity, activeDate?: Moment) {
  const [deskBookings, setDeskBookings] = useState<DeskReservations[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!office || !activeDate || isLoading || !office.deskBookingEnabled || hasLoaded) {
      return;
    }

    setIsLoading(true);
    getDeskReservationsInOffice(office.id, activeDate)
      .then((resp) => setDeskBookings(resp))
      .finally(() => {
        setIsLoading(false);
        setHasLoaded(true);
      });
  }, [office, activeDate, isLoading, hasLoaded]);


  return {
    loading: isLoading,
    deskBookings: deskBookings,
  };
}
