import React from 'react';
import PrivateRoute from '../../routing/PrivateRoute';
import { Switch } from 'react-router';
import './user-management.scss';
import { WorkPlannerPage } from "../wall-planner/WorkPlannerPage";
import { WallPlannerNewEventPage } from "../wall-planner/WallPlannerNewEventPage";

export default function WallPlannerRouting() {
  return (
    <Switch>
      <PrivateRoute exact path="/work-planner" component={WorkPlannerPage}/>
      <PrivateRoute exact path="/work-planner/event" component={WallPlannerNewEventPage}/>
      <PrivateRoute exact path="/work-planner/event/:eventId" component={WallPlannerNewEventPage}/>
    </Switch>
  )
}
