import React from 'react';
import { HolidayAllowanceUserRow } from "./HolidayAllowanceUserRow";
import { UsersHolidayAllowances } from "../../models/holidayAllowances.model";

export function HolidayAllowanceUserList(props: Props) {
  return (
    <>
      {props.allowances.map((item: UsersHolidayAllowances, key: number) => (
        <HolidayAllowanceUserRow item={item} key={key} />
      ))}
    </>
  )
}

interface Props {
  allowances: UsersHolidayAllowances[]
}
