import { FixedWhereabouts, FreeBusyStatus, WhereaboutsOption } from "../services/WhereaboutOptions";

export function companyMovementsForConjoiningTypes(optionA?: WhereaboutsOption, optionB?: WhereaboutsOption) {
  return optionA?.key === optionB?.key;
}

export function isBusy(location?: WhereaboutsOption) {
  return !!location && (location.freeBusyStatus === FreeBusyStatus.BUSY || location.freeBusyStatus === FreeBusyStatus.OOF);
}

export function isWorking(location?: WhereaboutsOption) {
  return !!location &&
    (location.freeBusyStatus === FreeBusyStatus.FREE);
}

export function isBusyInWork(location?: WhereaboutsOption) {
  return !!location &&
    (location.freeBusyStatus === FreeBusyStatus.TENTATIVE || location.freeBusyStatus === FreeBusyStatus.WORKING_ELSEWHERE);
}

export function isWorkingFromHome(location?: WhereaboutsOption) {
  return !!location &&
    (location.key === FixedWhereabouts.Home || location.key === FixedWhereabouts.HomeApproved);
}

export function isWorkingFromOffice(location?: WhereaboutsOption) {
  return !!location &&
    (location.key === FixedWhereabouts.Office);
}
