import React from 'react';
import { useDispatch } from 'react-redux';
import { next } from '../../../../store/ducks/onboarding.duck';
import { Column } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { BodyLarge } from "../../../UI/atoms/fonts/Body";
import { Button } from "../../../UI/atoms/buttons/Button";
import { useTranslation } from "react-i18next";

export default function OnboardingCustomWhereabouts() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <img style={{width: 330, marginBottom: 40}}
             className="onboarding__image"
             src="/assets/images/onboarding-custom-whereabouts.svg" alt="" aria-hidden={true} />
        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.custom-whereabouts-title')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 48}}>{t('onboarding.custom-whereabouts-description')}</BodyLarge>

        <Button dataTest="onboarding-custom-whereabouts-next"
                size="large"
                text="button.got-it"
                type="primary"
                onClick={() => dispatch(next())}
                fullWidth={true}/>
      </Column>
    </div>
  )
}
