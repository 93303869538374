import React, { useEffect } from 'react';
import './movements-wall-chart.scss';
import { useDispatch, useSelector } from "react-redux";
import { MediumLoadingSpinner } from "../../atoms/LoadingSpinner";
import { Center } from "../../atoms/StructuralLayout";
import MovementsList from "./components/team-movements-list/MovementsList";
import { BodyRegular } from "../../atoms/fonts/Body";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { HeadlineSmall } from "../../atoms/fonts/Headline";
import styled from "styled-components/macro";
import {
  loadLineReportsWhereabouts, selectIsLineReportOptionSelected,
  selectIsLoading,
  selectLineReportsWhereabouts
} from "../../../../store/ducks/LineReportsWhereabouts.duck";

interface TeamWallChartProps {
  enabledSetMovements: boolean;
}

export function LineReportsWallChart(props: TeamWallChartProps) {
  const {enabledSetMovements} = props;
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const dateString = calendarDate?.format(DATE_FORMAT);
  const whereabouts = useSelector(selectLineReportsWhereabouts);
  const isVisible = useSelector(selectIsLineReportOptionSelected);
  const loading = useSelector(selectIsLoading);

  useEffect(() => {
    if (isVisible) {
      dispatch(loadLineReportsWhereabouts({date: dateString}));
    }
  }, [dateString, isVisible, dispatch]);

  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      {loading ?
        <>
          <Center style={{marginTop: 16}}>
            <MediumLoadingSpinner hideBorder={true} />
          </Center>
        </>
        :
        <>
          <HeadlineSmall>Your line reports</HeadlineSmall>
          {whereabouts?.length > 0 ?
            <MovementsList movements={whereabouts} enableSetMovements={enabledSetMovements} /> :
            <BodyRegular>You have no line reports</BodyRegular>
          }
        </>
      }
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 24px;
`
