import React, { useMemo } from "react";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { ConfigurationSection } from "../../../StyleComponents";
import styled from "styled-components/macro";
import { fromMobile } from "../../../../../UI/atoms/MediaQueries";
import { setupCompanyActions } from "../../../../../../store/ducks/setupCompany.duck";
import { useDispatch, useSelector } from "react-redux";
import { isCompanyAdminUser } from "../../../../../../utils/AccessControl";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { useTranslation } from "react-i18next";

export default function LookingAfterMultipleTeams() {
  const current = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const isAdmin = useMemo(() => isCompanyAdminUser(current), [current]);
  const {t} = useTranslation();
  return (
    <MultipleTeamsSection>
      <MultipleTeamsInfo>
        <BodyRegular weight={600}>{t('settings.looking-after-multiple-teams')}</BodyRegular>
        <BodyRegular style={{marginBottom: 16}}>{t('settings.create-a-company')}</BodyRegular>
        <PrimaryButton dataTest="setupCompanyButton"
                       disabled={!isAdmin}
                       text="button.set-up-company-and-add-teams"
                       click={() => dispatch(setupCompanyActions.setIsOpen(true))} />
      </MultipleTeamsInfo>
      <img src="/assets/images/company-use-cases.svg" alt="Multiple use cases"/>
    </MultipleTeamsSection>
  )
}

const MultipleTeamsSection = styled<any>(ConfigurationSection)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  img {
    height: 210px;
    margin-top: 24px;
    align-self: center;
  }
  @media (${fromMobile}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    img {
      margin-top: 0;
    }
  }
`

const MultipleTeamsInfo = styled.div`
  display: flex;
  flex-direction: column;
  button {
    height: 50px;
  }
  @media (${fromMobile}) {
    max-width: 300px;
  }
`
