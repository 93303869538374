import React, { useMemo } from 'react';
import { MovementDay, Period } from "../../../../models/movements.models";
import { Row } from "../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import { WhereaboutsIconOutline, WhereaboutsIconOutlineUnknown } from "../../../UI/atoms/WhereaboutsIconOutline";
import { useSelector } from "react-redux";
import { selectWhereaboutsOptionByKey } from "../../../../store/ducks/whereaboutsOptions.duck";

export function UsersWhereaboutsIconsRow(props: Props) {
  return (
    <Row>
      {props.whereabouts.map((day, key) => (
        <WhereaboutsIconOutlineWrapper day={day} key={key} />
      ))}
    </Row>
  )
}

function WhereaboutsIconOutlineWrapper(props: {day: MovementDay}) {
  const {day} = props;
  const optionAm = useSelector(state => selectWhereaboutsOptionByKey(state, day.amStatus))
  const optionPm = useSelector(state => selectWhereaboutsOptionByKey(state, day.pmStatus))
  const activity = useMemo(() => {
    return {
      amOption: optionAm,
      pmOption: optionPm
    }
  }, [optionAm, optionPm]);

  if (!activity) {
    return null;
  } else if (!optionAm || !optionPm) {
    return <WhereaboutsIconOutlineUnknown />
  } else if (optionAm.status === optionPm.status) {
    return <WhereaboutsIconOutline sideOfDay={Period.AM} activity={activity} />
  } else {
    return <HalfDayRow>
      <WhereaboutsIconOutline sideOfDay={Period.AM} activity={activity} />
      <WhereaboutsIconOutline sideOfDay={Period.PM} activity={activity} />
    </HalfDayRow>
  }
}

interface Props {
  whereabouts: MovementDay[]
}

export const HalfDayRow = styled.div`
  display: flex;
  margin-right: 16px;
  img {
    margin-right: 2px !important;
  }
`
