import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from "styled-components/macro";
import { getOfficeLayout } from "../../../../services/DeskBooking";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { OfficeDeskSpotOverlay } from "./floor-map-view/OfficeDeskSpotOverlay";
import Colours from "../../../UI/atoms/Colours";
import { ToggleableOutlineButton } from "../../../UI/atoms/buttons/ToggleableOutlineButton";
import { BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedOffice,
  selectIsOfficeListLoading,
  selectOfficeFloorPlan,
  selectSelectedArea, setOfficeFloorPlan
} from "../../../../store/ducks/officeView.duck";
import { MapButtonRow, MapControlButton, MapControlsContainer, SelectionMessage } from "./ViewComponents";
import {
  clearDesks,
  fetchDeskReservations,
  fetchDesks,
  selectAllOfficeDeskReservations,
  selectAllOfficeDesks,
  selectIsDeskBookingLoading
} from "../../../../store/ducks/deskBooking.duck";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { OfficeAreaSpotOverlay } from "./floor-map-view/OfficeAreaSpotOverlay";
import { selectIsOfficeUsageLoading } from "../../../../store/ducks/officeUsage.duck";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { Period } from "../../../../models/movements.models";
import { Desk, DeskReservations } from "../../../../services/DeskBookingService";
import { loadFavouriteOffices, selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { SingleDeskSpotOverlay } from "./floor-map-view/SingleDeskSpotOverlay";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import { getParameterByName } from "../../../../utils/UrlUtils";
import { getInt } from "../../../../utils/MathUtils";
import { MeetingRoomSpotOverlay } from "./floor-map-view/MeetingRoomSpotOverlay";
import {
  addSelectedDeskId,
  clearSelectedDeskIds,
  removeSelectedDeskId,
  selectSelectedDeskIds,
  selectSidebarMode,
  setSidebarMode
} from "../ducks/manageDesks.duck";
import { SidebarMode } from "../data/ManageDesks.model";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { Permission } from "../../../../models/user.models";
import { selectMeetingRoomsInOffice } from "../../settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { AppState } from "../../../../store/state/app.state";
import { useTranslation } from "react-i18next";

interface Props {
  onDeskSelect?: (desk: any) => any;
  onOfficeSelect?: (office: any) => any;
  showAdminButtons: boolean;
}

export function getFloorPlanUrl(floorPlanImage: string) {
  if (floorPlanImage.startsWith('s3/')) {
    return `https://team-today-floorplans.s3.eu-west-2.amazonaws.com/${floorPlanImage}`;
  } else {
    return floorPlanImage;
  }
}

export function OfficeFloorMap(props: Props) {
  const {showAdminButtons} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [activeSpot, setActiveDeskSpot] = useState<any>(undefined);
  const [activePeriod, setActivePeriod] = useState(Period.AllDay);
  const myRef = useRef(null);

  const officeFloorPlan = useSelector(selectOfficeFloorPlan);
  const currentOffice = useSelector(selectedOffice);
  const selectedDate = useSelector(selectActiveDay);
  const currentUser = useSelector(selectCurrentUser);
  const allOffices = useSelector(selectAllOfficeEntities);
  const desks = useSelector(selectAllOfficeDesks);
  const reservations = useSelector(selectAllOfficeDeskReservations);
  const isDeskBookingLoading = useSelector(selectIsDeskBookingLoading);
  const isOfficeUsageLoading = useSelector(selectIsOfficeUsageLoading);
  const isOfficeViewLoading = useSelector(selectIsOfficeListLoading);
  const selectedArea = useSelector(selectSelectedArea);
  const selectedSidebarMode = useSelector(selectSidebarMode);
  const selectedDesksForManageDesks = useSelector(selectSelectedDeskIds);
  const meetingRooms = useSelector((state: AppState) => selectMeetingRoomsInOffice(state, currentOffice?.id ?? 0));

  const isManageDesksActive = selectedSidebarMode === SidebarMode.ManageDesks;

  const editFloorPlanButtonEnabled = useMemo(() => {
    if (!showAdminButtons) return false;
    return ['robin@team-today.com', 'isherwood88@gmail.com', 'andrew@team-tracker.com', 'lucy@team-today.com'].includes((currentUser?.email ?? '' as string));
  }, [currentUser, showAdminButtons]);

  const manageDesksButtonEnabled = useMemo(() => {
    if (!showAdminButtons) return false;
    if (currentUser?.role === Permission.CompanyAdmin || currentUser?.role === Permission.OfficeAdmin)  {
      return true;
    }
    return false;
  }, [currentUser, showAdminButtons]);

  const isLoadingRef = useRef(false);
  const isLoading = useMemo(() => {
    return isLoadingRef.current = isDeskBookingLoading || isOfficeUsageLoading || isOfficeViewLoading
    }, [isDeskBookingLoading, isOfficeUsageLoading, isOfficeViewLoading]);

  useEffect(() => {
    dispatch(loadFavouriteOffices());
  }, [dispatch])

  useEffect(() => {
    if (officeFloorPlan) {
      const deskQueryParam = getInt(getParameterByName('desk', window.location.href) || '');
      if (selectedArea) {
        setTimeout(() => {
          // @ts-ignore
          myRef?.current?.zoomToElement(`area-${selectedArea.id}`, 1);
        }, 250);
      } else if (deskQueryParam) {
        setTimeout(() => {
          // @ts-ignore
          myRef?.current?.zoomToElement(`desk-${deskQueryParam}`, 1);
        }, 1000);
      }
    }
  }, [officeFloorPlan, selectedArea]);

  const officeDesks = useMemo(() => {
    const desksOnPlan = (officeFloorPlan?.desks ?? []);
    return desks.map((desk: Desk) => {
      const deskFromPlan = (desksOnPlan.find((p: any) => p.deskId === desk.id) ?? {});
      return {
        ...deskFromPlan,
        ...desk,
        deskId: desk.id,
        isAmBooked: !!(reservations.find((r: any) => r.amDeskId === desk.id)),
        isPmBooked: !!(reservations.find((r: any) => r.pmDeskId === desk.id)),
        x: desk.x || deskFromPlan.x,
        y: desk.y || deskFromPlan.y,
        width: desk.width || deskFromPlan.width,
        height: desk.height || deskFromPlan.height,
        rotation: desk.rotation || deskFromPlan.rotate || 0,
        amReservation: (reservations.find((r: DeskReservations) => r.amDeskId === desk.id) ?? {}),
        pmReservation: (reservations.find((r: DeskReservations) => r.pmDeskId === desk.id) ?? {}),
      }
    });
  }, [officeFloorPlan, desks, reservations]);

  const officeAreas = useMemo(() => officeFloorPlan?.areas ?? [], [officeFloorPlan]);

  useEffect(() => {
    if (isLoadingRef.current) {
      return;
    }

    if (!selectedDate) {
      console.log('please select a date')
      return;
    }

    if (!currentOffice) {
      console.log('please select an office')
      return;
    }

    if (!currentOffice.officePlan) {
      console.error('No office plan for', currentOffice);
      return;
    }

    if (currentOffice?.floorPlanEnabled) {
      getOfficeLayout(currentOffice.officePlan).then(plan => dispatch(setOfficeFloorPlan(plan)));
    }

    if (currentOffice?.deskBookingEnabled) {
      dispatch(fetchDesks(currentOffice?.id));
      dispatch(fetchDeskReservations({officeId: currentOffice?.id, date: selectedDate}));
    } else {
      dispatch(clearDesks());
    }
  }, [dispatch, currentOffice, selectedDate]);

  const onDeskSpotSelected = (deskSpot: any) => {
    if (isManageDesksActive) {
      if (selectedDesksForManageDesks.find(id => id === deskSpot.id)) {
        dispatch(removeSelectedDeskId(deskSpot.id));
      } else {
        dispatch(addSelectedDeskId(deskSpot.id));
      }
    } else if (deskSpot?.active === false) {
      dispatch(warningNotification('That desk is not active'));
    } else {
      if (deskSpot?.canUserBook === undefined || deskSpot?.canUserBook) {
        if (props.onDeskSelect || props.onOfficeSelect) {
          if (deskSpot.id && props.onDeskSelect) {
            props.onDeskSelect(deskSpot);
          } else if (deskSpot.officeId && props.onOfficeSelect) {
            props.onOfficeSelect(deskSpot)
          }
        } else {
          setActiveDeskSpot(deskSpot);
        }
      } else {
        dispatch(warningNotification('You cannot book that desk'));
      }
    }
  }

  const clearSelection = () => {
    setActiveDeskSpot(undefined);
  }

  const editFloorPlan = () => {
    if (editFloorPlanButtonEnabled && currentOffice) {
      window.location.href = `/edit-floor-plan?officeId=${currentOffice.id}`;
    }
  }

  const toggleManageDesks = () => {
    if (selectedSidebarMode === SidebarMode.ManageDesks) {
      dispatch(setSidebarMode(SidebarMode.ChooseOffice));
    } else {
      dispatch(setSidebarMode(SidebarMode.ManageDesks));
    }
  }

  const onBackgroundClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (isManageDesksActive) {
      dispatch(clearSelectedDeskIds());
    }
  }

  if (!officeFloorPlan || officeFloorPlan.length === 0) {
    return null;
  }

  return (
    <>
      <Container onClick={(e: any) => onBackgroundClick(e)}>
        {isLoading && <LoadingSpinner fullScreen={true} />}
        {!!currentOffice ?
        <>
          <OfficeFloorMapWrapper>
            <TransformWrapper centerOnInit={officeFloorPlan?.centerOnInit ?? true} initialScale={officeFloorPlan?.initialScale ?? 0.5}
                              maxScale={officeFloorPlan?.maxScale ?? 2} minScale={officeFloorPlan?.minScale ?? 0.5} ref={myRef}>
              {(params: any) => (
                <div style={{position: 'relative', height: '100%', width: '100%'}} onClick={clearSelection}>
                  <MapButtonRow>
                    <ToggleableOutlineButton click={() => setActivePeriod(Period.AllDay)} text="button.all-day" isActive={activePeriod === Period.AllDay}/>
                    <ToggleableOutlineButton click={() => setActivePeriod(Period.AM)} text="button.am-only" isActive={activePeriod === Period.AM}/>
                    <ToggleableOutlineButton click={() => setActivePeriod(Period.PM)} text="button.pm-only" isActive={activePeriod === Period.PM}/>
                    <MapControlsContainer className="tools">
                      {editFloorPlanButtonEnabled && <MapControlButton colour={Colours.red} onClick={() => editFloorPlan()}><Icon color={'red'} icon={IconTypes.Chair} /></MapControlButton>}
                      {manageDesksButtonEnabled && <MapControlButton onClick={() => toggleManageDesks()}><Icon icon={IconTypes.Edit} /></MapControlButton>}
                      <MapControlButton onClick={() => params.zoomIn()}>+</MapControlButton>
                      <MapControlButton onClick={() => params.zoomOut()}>-</MapControlButton>
                      <MapControlButton onClick={() => params.resetTransform()}><BodyVerySmall style={{fontSize: 8}} weight={600}>100%</BodyVerySmall></MapControlButton>
                    </MapControlsContainer>
                  </MapButtonRow>

                  <TransformComponent wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}>
                    <FloorPlan src={getFloorPlanUrl(officeFloorPlan.floorPlan)}/>
                    {selectedDate && officeAreas && officeFloorPlan?.areas?.length > 0 && officeAreas.map((areaSpot: any, key: number) =>(
                        <OfficeAreaSpotOverlay key={key} onSelect={onDeskSpotSelected}
                                               currentDate={selectedDate}
                                               currentUser={currentUser}
                                               activeSpot={activeSpot}
                                               activePeriod={activePeriod}
                                               allOffices={allOffices}
                                               areaSpot={areaSpot} />
                    ))}
                    {officeDesks && selectedDate && officeFloorPlan && officeDesks.map((deskSpot: any, key: number) => (
                      <OfficeDeskSpotOverlay key={key} currentDate={selectedDate}
                                             deskSpot={deskSpot}
                                             activeDeskSpot={activeSpot}
                                             currentUser={currentUser}
                                             activePeriod={activePeriod}
                                             selectedDesksForManageDesks={selectedDesksForManageDesks}
                                             isManageDesksEnabled={isManageDesksActive}
                                             onSelect={onDeskSpotSelected} />
                    ))}

                    {selectedDate && meetingRooms.map((meetingRoom: any, key: number) => <MeetingRoomSpotOverlay key={key}
                                                                                                                 currentDate={selectedDate}
                                                                                                                 currentUser={currentUser}
                                                                                                                 meetingRoom={meetingRoom} />)}

                    {selectedDate && activeSpot && activeSpot?.id && <SingleDeskSpotOverlay currentDate={selectedDate}
                                                                                            activeDeskSpot={activeSpot}
                                                                                            currentUser={currentUser}
                                                                                            activePeriod={activePeriod}
                                                                                            office={currentOffice}
                                                                                            onSelect={onDeskSpotSelected} />}
                  </TransformComponent>
                </div>
              )}
            </TransformWrapper>
          </OfficeFloorMapWrapper>
        </>
        :
        <>
          <SelectionMessage>{t('office-map.select-office')}</SelectionMessage>
        </>}
      </Container>
    </>
  )
}

const OfficeFloorMapWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${Colours.veryLightGrey};
  max-height: 100%;
`

const FloorPlan = styled.img`
`

const Container = styled.div`
  display: flex;
  max-height: 100%;
`
