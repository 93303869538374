import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setCalendarSyncActive } from "../store/ducks/auth.duck";
import { AuthType, GetCurrentAuthType, stepUpAzureCalendarPermissions, teamsLogin } from "../utils/AuthUtils";
import { useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";
import { DialogIdentifiers, openDialog } from "../store/ducks/dialog.duck";
import { failureNotification, warningNotification } from "../store/ducks/notification.duck";
import { isInTeams } from "../utils/TeamsUtils";
import { microsoftRequest } from "../services/EnvironmentVariables";


const CALENDAR_ACCESS = 'CALENDARS.READWRITE';
const MAILBOX_SETTINGS_ACCESS = 'MAILBOXSETTINGS.READ';

export enum SyncStatus {
  Active, Failed, Pending, Disabled, NA
}

export function WithMicrosoftCalendarSyncActiveChecker() {
  const authType = GetCurrentAuthType();
  const user = useSelector(selectCurrentUser);
  const {instance, accounts} = useMsal();
  const [status, setStatus] = useState(SyncStatus.Pending);
  const dispatch = useDispatch();

  const checkStatus = useCallback((user, authType) => {
    if (authType !== AuthType.Microsoft) {
      dispatch(setCalendarSyncActive(false));
      return SyncStatus.NA;
    }
    if (!user?.enableOutlookSync) {
      dispatch(setCalendarSyncActive(false));
      return SyncStatus.Disabled;
    }
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const parsedToken = jwt_decode(accessToken) as any;
      const scopes = (parsedToken?.scp ?? '').toUpperCase();
      if (scopes.includes(CALENDAR_ACCESS) || scopes.includes(MAILBOX_SETTINGS_ACCESS)) {
        dispatch(setCalendarSyncActive(true));
        return SyncStatus.Active;
      }
    }
    dispatch(setCalendarSyncActive(false));
    return SyncStatus.Failed;
  }, [dispatch]);

  const manuallyCheckStatus = async () => {
    if (status === SyncStatus.Pending) {
      checkStatus(user, authType);
    }
    if (status === SyncStatus.Active || status === SyncStatus.NA) {
      return
    }
    if (status === SyncStatus.Disabled) {
      dispatch(openDialog(DialogIdentifiers.AzureCalendarSyncDisabledDialog));
      return;
    }
    let account;
    if (instance.getActiveAccount()) {
      account = instance.getActiveAccount();
    }
    if (!account && accounts.length > 0) {
      account = accounts[0];
    }
    if (accounts.length > 1 && account) {
      const message = `Multiple Microsoft accounts found, using ${account.username}`;
      console.log(message);
      dispatch(warningNotification(message));
    }

    if (isInTeams()) {
      setStatus(SyncStatus.Pending);
      await teamsLogin(instance, microsoftRequest.calendarScopes, dispatch, true, true);
    } else {
      if (account) {
        setStatus(SyncStatus.Pending);
        stepUpAzureCalendarPermissions(instance, account, dispatch);
      } else {
        dispatch(failureNotification('Failed to find Microsoft account, try to logout and login.'));
      }
    }
  }


  useEffect(() => {
    setTimeout(() => {
      setStatus(checkStatus(user, authType));
    }, 1000);
  }, [authType, checkStatus, user])

  return {
    calendarSyncStatus: status,
    manuallyCheckStatus,
  }
}
