import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectCalendarDate, setCalendarDate } from '../../../../store/ducks/dashboard.duck';
import Icon, { IconTypes } from '../../../UI/atoms/icon/Icon';
import FullScreenCalendar from '../../../UI/molecules/calendar/FullScreenCalendar';
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { Row } from '../../../UI/atoms/StructuralLayout';
import i18next from "i18next";

export default function WeekFlicker({className = '', disabled = false, showDate = false, onWeekChange = () => {}}: Props) {
  const date = useSelector(selectCalendarDate);
  const [fullScreenCalendarOpen, setFullScreenCalendarOpen] = useState(false);
  const today = moment();
  const dispatch = useDispatch();

  const setWeek = (moment: Moment) => {
    dispatch(setCalendarDate(moment));
  }

  const changeWeek = (number: number) => {
    if (disabled) return;
    if (isThisWeek() && number < 0) return;
    setWeek(date.clone().add(number, 'week'));
    onWeekChange();
  }

  const isThisWeek = () => {
    return today.startOf('isoWeek').diff(date.clone().startOf('isoWeek'), 'day') === 0;
  }

  const getText = () => {
    if (showDate || !isThisWeek()) {
      return date.clone().startOf('isoWeek').format('Do MMMM');
    } else {
      return i18next.t('dates.this-week');
    }
  }

  return (
    <div className={`weekFlicker ${className}`.trim()}>
      <div className="weekFlicker__control">

        <Icon circle={true} color="black" className={`weekFlicker__control--mobile ${isThisWeek() || disabled ? 'circle--disabled' : ''}`.trim()}
              icon={IconTypes.Previous}
              onClick={() => changeWeek(-1)}/>

        <BodyRegular weight={600} className="weekFlicker__dateLabel weekFlicker__dateLabel--desktop">
          {getText()}
        </BodyRegular>

        <BodyRegular weight={600} className="weekFlicker__dateLabel weekFlicker__dateLabel--mobile" onClick={() => setFullScreenCalendarOpen(!fullScreenCalendarOpen)}>
          {getText()}
          <Icon className="weekFlicker__calendar"
                icon={IconTypes.Calendar} color="black" />
        </BodyRegular>

        <Row>
          <Icon circle={true} color="black" className={`weekFlicker__control--tablet ${isThisWeek() || disabled ? 'circle--disabled' : ''}`}
                icon={IconTypes.Previous}
                onClick={() => changeWeek(-1)} dataTest="week-flicker-previous-desktop"/>
          <Icon circle={true} className={disabled ? 'circle--disabled' : ''}
                icon={IconTypes.Next}
                onClick={() => changeWeek(1)}
                dataTest="week-flicker-next-desktop"/>
        </Row>
      </div>
      <FullScreenCalendar onClose={() => setFullScreenCalendarOpen(false)} open={fullScreenCalendarOpen}/>
    </div>
  )
}

interface Props {
  className?: string;
  disabled?: boolean;
  showDate?: boolean;
  onWeekChange?: () => any;
}
