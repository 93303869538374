import React from 'react';
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";

export function CustomWhereaboutsPromo(props: Props) {
  const {t} = useTranslation();
  return (
    <Row>
      <OfficeIcon src={'/assets/images/custom-whereabouts-icon.svg'} alt={''} />
      <Column>
        <BodyLarge colour={Colours.blue}>{t('custom-whereabouts-page.promo-title')}</BodyLarge>
        <BodyRegular style={{marginBottom: 24}}>{t('custom-whereabouts-page.promo-message')}</BodyRegular>
      </Column>
    </Row>
  )
}

interface Props {
}

const OfficeIcon = styled.img`
  margin-right: 36px;
  width: 52px;
  height: 52px;
`
