import styled from "styled-components/macro";
import { smallTablet, tablet } from "./MediaQueries";

export const MobileOnly = styled.div`
  display: block;
  @media (${smallTablet}) {
    display: none !important;
  }
`

export const TabletOnly = styled.div`
  display: block;
  @media (${tablet}) {
    display: none !important;
  }
`

export const BiggerThanTabletOnly = styled.div`
  display: none;
  @media (${tablet}) {
    display: block;
  }
`
