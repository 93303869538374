import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { createCheckoutSession } from "./stripeService";
import { BodyError, BodyLarge } from "../../atoms/fonts/Body";
import { SpaceBetweenRow } from "../../atoms/StructuralLayout";
import { SwitchCounter } from "../../molecules/SwitchCounter";
import { HeadlineMedium } from "../../atoms/fonts/Headline";
import { LoadingSpinner } from '../../atoms/LoadingSpinner';
import {
  enableStripe,
  stripeMinUserLimit,
  stripePackSize,
  stripeProductMonthlyPriceId_AUD,
  stripeProductMonthlyPriceId_EUR,
  stripeProductMonthlyPriceId_GBP,
  stripeProductMonthlyPriceId_USD,
  stripeProductYearlyPriceId_AUD,
  stripeProductYearlyPriceId_EUR,
  stripeProductYearlyPriceId_GBP,
  stripeProductYearlyPriceId_USD,
  stripePublicKey,
  stripeUserLimit
} from "../../../../services/EnvironmentVariables";
import styled from "styled-components/macro";
import Colours from "../../atoms/Colours";
import { PrimaryButton } from "../../atoms/buttons/PrimaryButton";
import { ToggleableOutlineButton } from "../../atoms/buttons/ToggleableOutlineButton";
import { useDispatch } from "react-redux";
import { closeNewPaymentDialog } from "../../../../store/ducks/payments.duck";
import { successNotification } from "../../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";

const PACK_SIZE = stripePackSize;
const USER_LIMIT = stripeUserLimit;
const MIN_USER_LIMIT = stripeMinUserLimit;
const BASE_USERS = 10;

function getPriceId(currency: 'USD' | 'GBP' | 'AUD' | 'EUR', monthly: boolean) {
  switch (currency) {
    case "AUD": return monthly ? stripeProductMonthlyPriceId_AUD : stripeProductYearlyPriceId_AUD;
    case "USD": return monthly ? stripeProductMonthlyPriceId_USD : stripeProductYearlyPriceId_USD;
    case "EUR": return monthly ? stripeProductMonthlyPriceId_EUR : stripeProductYearlyPriceId_EUR;
    default: return monthly ? stripeProductMonthlyPriceId_GBP : stripeProductYearlyPriceId_GBP;
  }
}

function getPrice(currency: 'USD' | 'GBP' | 'AUD' | 'EUR', monthly: boolean): number {
  switch (currency) {
    case "AUD": return monthly ? 18.5 : 185;
    case "USD": return monthly ? 13.3 : 133;
    case "EUR": return monthly ? 11.9 : 119;
    default: return monthly ? 10 : 100;
  }
}

function getCurrencySymbol(currency: 'USD' | 'GBP' | 'AUD' | 'EUR'): string {
  switch (currency) {
    case "AUD": return '$';
    case "USD": return '$';
    case "EUR": return '€';
    default: return '£';
  }
}

export default function SetupPaymentFlow() {
  const dispatch = useDispatch();
  const [userCount, setUserCount] = useState(PACK_SIZE);
  const [loading, setLoading] = useState(false);
  const [monthly, setMonthly] = useState(true);
  const [currency, setCurrency] = useState<'USD' | 'GBP' | 'AUD' | 'EUR'>("USD");
  const [error, setError] = useState('');
  const {t} = useTranslation();

  const stripeCheckout = async () => {
    if (enableStripe) {
      setLoading(true);
      try {
        const priceId = getPriceId(currency, monthly);
        const data = await createCheckoutSession(priceId, userCount - BASE_USERS);
        const stripe = await loadStripe(stripePublicKey);
        if (!stripe) return;
        await stripe.redirectToCheckout({
          sessionId: data.sessionId
        });
        dispatch(closeNewPaymentDialog());
        dispatch(successNotification('Success'));
      } catch (err: any) {
        console.error(err)
        setError('There has been an error setting up your subscription, please contact hello@team-today.com');
      } finally {
        setLoading(false);
      }
    } else {
      console.warn('Stripe disabled')
    }
  }

  const QuantitySelector = () => (
    <>
      {error && <BodyError style={{marginBottom: 16}}>{error}</BodyError>}
      <HeadlineMedium style={{marginBottom: 48}}>{t('payments.how-many-users-you-need')}</HeadlineMedium>
      <SwitchCounter onChange={(val: number) => setUserCount(val)}
                     value={userCount}
                     limit={USER_LIMIT}
                     minValue={MIN_USER_LIMIT}
                     increment={PACK_SIZE}/>

      <BodyLarge weight={600}>{t('payments.how-often-you-pay')}</BodyLarge>
      <PaymentFrequencyRow style={{marginBottom: 32}}>
        <ToggleableOutlineButton isActive={monthly}
                                 click={() => setMonthly(true)}
                                 text="button.monthly" />
        <ToggleableOutlineButton isActive={!monthly}
                                 click={() => setMonthly(false)}
                                 text="button.yearly" />
      </PaymentFrequencyRow>

      <BodyLarge weight={600}>{t('payments.currency')}</BodyLarge>
      <CurrencyRow style={{marginBottom: 32}}>
        <ToggleableOutlineButton isActive={currency === 'USD'} click={() => setCurrency('USD')} text="USD" />
        <ToggleableOutlineButton isActive={currency === 'GBP'} click={() => setCurrency('GBP')} text="GBP" />
        <ToggleableOutlineButton isActive={currency === 'EUR'} click={() => setCurrency('EUR')} text="EUR" />
        <ToggleableOutlineButton isActive={currency === 'AUD'} click={() => setCurrency('AUD')} text="AUD" />
      </CurrencyRow>

      <InfoRow>
        <BodyLarge>{t('payments.first-10-users')}</BodyLarge>
        <BodyLarge weight={600}>{t('payments.FREE')}</BodyLarge>
      </InfoRow>
      <InfoRow>
        <BodyLarge>{t('payments.total-paid-users')}</BodyLarge>
        <BodyLarge weight={600}>{userCount - BASE_USERS}</BodyLarge>
      </InfoRow>

      <InfoRow style={{marginBottom: 16}}>
      {monthly ?
        <>
          <BodyLarge weight={600}>{t('payments.monthly-amount')}</BodyLarge>
          <BodyLarge>{getCurrencySymbol(currency)}{(Math.ceil((userCount - BASE_USERS) / PACK_SIZE) * getPrice(currency, monthly)).toFixed(2)}</BodyLarge>
        </> :
        <>
          <BodyLarge weight={600}>{t('payments.year-amount')}</BodyLarge>
          <BodyLarge>{getCurrencySymbol(currency)}{(Math.ceil((userCount - BASE_USERS) / PACK_SIZE) * getPrice(currency, monthly)).toFixed(2)}</BodyLarge>
        </>
      }
      </InfoRow>

      <PrimaryButton text="button.checkout" style={{marginBottom: 32}} size="large"
                     click={stripeCheckout} fullWidth={true} disabled={userCount <= 10}/>
      <StripeRow>
        <img src="/assets/icons/powered-by-stripe-purple.svg" alt="Powered by Stripe" />
      </StripeRow>
    </>
  )

  return (
    <>
      {loading ? <LoadingSpinner fullScreen={true}/> : <QuantitySelector/>}
    </>
  )
}

const InfoRow = styled<any>(SpaceBetweenRow)`
  border-bottom: 1px solid ${Colours.mildGrey};
  padding: 12px 0;
`


const StripeRow = styled.div`
  display: flex;
  justify-content: center;
`

const PaymentFrequencyRow = styled.div`
  display: flex;
  justify-content: center;
  & > * {
    flex: 1;
  }
`

const CurrencyRow = styled.div`
  display: flex;
  justify-content: center;
  * {
    min-width: unset !important;
  }
  & > * {
    flex: 1;
    min-width: unset;
  }
`

