import React, { useEffect, useState } from 'react';
import './row.scss';
import { HeadlineSmall } from '../../../../atoms/fonts/Headline';
import Icon, { IconTypes } from '../../../../atoms/icon/Icon';
import { TeamEntity } from '../../../../../../models/team.models';
import { MovementsHeaderRow } from "../StructuralComponents";

export default function HeaderRow(props: Props) {
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() => setIsOpen(props.defaultIsOpen), [props.defaultIsOpen]);

  const toggle = () => {
    props.onOpen(!isOpen);
    setIsOpen(!isOpen);
  }

  return (
    <MovementsHeaderRow dataTest={`team-${props.team?.id ?? 'no-team'}`} onClick={() => toggle()}>
      {props.team && <HeadlineSmall>{props.team.name}</HeadlineSmall>}
      {!props.disableDropdown && <Icon icon={IconTypes.Previous} className={`wallchartRow__nameIcon ${isOpen ? 'wallchartRow__nameIcon--open' : ''}`.trim()} />}
    </MovementsHeaderRow>
  )
}

interface Props {
  defaultIsOpen: boolean;
  disableDropdown: boolean;
  team?: TeamEntity;
  onOpen: (val: boolean) => void;
}
