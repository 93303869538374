import React, { useEffect, useMemo } from 'react';
import { MeetingRoomScheduleList } from "./MeetingRoomScheduleList";
import { NoMeetingRoomSelection } from "./NoMeetingRoomSelection";
import styled from "styled-components/macro";
import { isInTeams } from "../../../../utils/TeamsUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllMeetingRoomsForOffice,
  loadMeetingRoomBookings,
  selectedMeetingRoomCapacityBand,
  selectedMeetingRoomFacilities,
  selectedOffice,
  selectFilteredMeetingRooms
} from "../models/room-finder.duck";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { MeetingRoomFinderPageHeader } from "./MeetingRoomFinderPageHeader";
import {
  SyncStatus,
  WithMicrosoftCalendarSyncActiveChecker
} from "../../../../hooks/WithMicrosoftCalendarSyncActiveChecker";

export function RoomFinderView(props: Props) {
  const dispatch = useDispatch();
  const office = useSelector(selectedOffice);
  const capacityBands = useSelector(selectedMeetingRoomCapacityBand);
  const facilities = useSelector(selectedMeetingRoomFacilities);
  const filteredMeetingRooms = useSelector(selectFilteredMeetingRooms);
  const {calendarSyncStatus} = WithMicrosoftCalendarSyncActiveChecker();

  const containsOutlookMeetingRoom = useMemo(() => {
    return filteredMeetingRooms.filter(room => !!room.microsoftRoomId).length > 0;
  }, [filteredMeetingRooms])

  useEffect(() => {
    if (office) {
      dispatch(loadAllMeetingRoomsForOffice({office: office, capacityBands: capacityBands, facilities: facilities}));
    }
  }, [office, dispatch, capacityBands, facilities]);

  useEffect(() => {
    if (office && filteredMeetingRooms.length > 0) {
      if (!containsOutlookMeetingRoom || calendarSyncStatus === SyncStatus.Active) {
        dispatch(loadMeetingRoomBookings());
      }
    }
  }, [office, filteredMeetingRooms, dispatch, containsOutlookMeetingRoom, calendarSyncStatus]);

  return (
    <>
      <Container isInTeams={isInTeams()}>
        <MeetingRoomContent>

          <MeetingRoomContentContainer>
            {!isInTeams() && <HeaderRowWrapper>
              <MeetingRoomFinderPageHeader />
            </HeaderRowWrapper>}

            {filteredMeetingRooms.length > 0 ? <MeetingRoomScheduleList calendarSyncStatus={calendarSyncStatus} /> : <>
              <NoMeetingRoomSelection />
            </>}

          </MeetingRoomContentContainer>

        </MeetingRoomContent>
      </Container>
    </>
  )
}

interface Props {
}


const Container = styled.div<any>`
  display: flex;
  max-height: ${props => props.isInTeams ? 'calc(100vh - 60px)' : 'calc(100vh - 244px)'};
  @media (${tablet}) {
    display: flex;
    max-height: ${props => props.isInTeams ? 'calc(100vh - 60px)' : 'calc(100vh - 186px)'};
  }
`

const HeaderRowWrapper = styled.div`
  max-height: 128px;
  height: 128px;
  @media (${tablet}) {
    max-height: 72px;
    height: 72px;
  }
`

const MeetingRoomContent = styled.div`
  width: 100%;
  max-height: inherit;
  height: inherit;
  display: flex;
`

const MeetingRoomContentContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: inherit;
  height: inherit;
`
