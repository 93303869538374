import React, { useMemo } from 'react';
import styled from "styled-components";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import Colours from "../../../../UI/atoms/Colours";
import { useSelector } from "react-redux";
import { selectHolidayUserInfo, selectUsersHolidayGroupings } from "../../../../../store/ducks/holidays-v2.duck";
import { UsersHolidayBookingItem } from "./UsersHolidayBookingItem";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CheckLineReport } from "../../../../../hooks/management-structure/CheckLineReport";

export function HolidaysBookingsList(props: Props) {
  const userInfo = useSelector(selectHolidayUserInfo);
  const holidayGroupings = useSelector(selectUsersHolidayGroupings);
  const sortedBookedHolidays = useMemo(() => [...holidayGroupings].sort((a, b) => moment(a.fromDate) < moment(b.fromDate) ? -1 : 1), [holidayGroupings]);
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const isLineReportOfCurrentUser = CheckLineReport(currentUser, userInfo?.userId);

  return (
    <Container data-test={"your-bookings-container"}>
      <HeadlineSmall style={{marginBottom: 16}}>{(!userInfo.userId || userInfo.userId === currentUser?.id) ? t('holiday.your-bookings') : t('holiday.users-bookings', {firstName: userInfo.firstName})}</HeadlineSmall>

      {sortedBookedHolidays.map((holiday, key) => (
        <UsersHolidayBookingItemWrapper key={key} className={"usersHolidays__item"}>
          <UsersHolidayBookingItem holiday={holiday} currentUser={currentUser} isLineReportOfCurrentUser={isLineReportOfCurrentUser} />
        </UsersHolidayBookingItemWrapper>
      ))}
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  width: 100%;
  border: 1px solid ${Colours.mildGrey};
  border-radius: 24px;
  padding: 24px;
`

const UsersHolidayBookingItemWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 16px;
  padding-bottom: 16px;
  &:last-child {
    border: none;
    margin: 0;
    padding: 0;
  }
`
