import React from 'react';
import { BodyLarge, BodyRegular, BodySmall } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import TextField from "../../../UI/atoms/TextField";
import { CompanyEntity } from "../../../../models/company.models";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../store/ducks/config.duck";
import { UserInfo } from "../../../../models/user.models";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { canChangeChangeUsersHolidays, isCompanyAdminUser } from "../../../../utils/AccessControl";
import { useTranslation } from "react-i18next";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";

export function UserHolidayAllowanceSection(props: Props) {
  const config = useSelector(selectConfig);
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const canEditHolidayAllowances = isCompanyAdminUser(currentUser);

  return (
    <Wrapper className="" data-test="location-section">
      <BodyLarge colour={Colours.blue} style={{marginBottom: 6}}>{t('settings-page.edit-user.holiday-allowance')}</BodyLarge>
      <BodyRegular style={{marginBottom: 6}}>{t('settings-page.edit-user.holiday-allowance-message')}</BodyRegular>
      <TextField onChange={(value: string) => props.onHolidayAllowanceChange(parseFloat(value))}
                 label="settings-page.edit-user.users-allowance"
                 rightText="settings-page.edit-user.for-year"
                 value={(props?.holidayAllowance ?? 0).toString()}
                 disabled={!canChangeChangeUsersHolidays(currentUser)}
                 type="number" />

      {currentUser?.companyEntity.enableHolidayBooking && canEditHolidayAllowances && <OutlineButton link={'/holidays/settings/allowance'}
                                                                                                     style={{'margin': '0 0 24px 0', width: '280px', maxWidth: '300px'}}
                                                                                                     text={'settings.holiday-allowance.edit-individual-allowances'} />}

      {props.user.holidayAllowance ?
        <BodySmall>{t('settings-page.edit-user.holiday-allowance-taken-from-users-setting')}</BodySmall> :
          <>{config.companyHolidayAllowance ? <BodySmall>{t('settings-page.edit-user.holiday-allowance-taken-from-company-setting')}</BodySmall> :
            <BodySmall>{t('settings-page.edit-user.holiday-allowance-not-yet-set')}</BodySmall>
          }</>
        }

    </Wrapper>
  )
}

interface Props {
  onHolidayAllowanceChange: (x: number) => void;
  user: UserInfo;
  company?: CompanyEntity;
  holidayAllowance: number;
}


const Wrapper = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 24px;
  padding-bottom: 24px;
  .textField__input {
    width: 80px;
  }
`
