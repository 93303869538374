import styled from "styled-components/macro";
import { smallTablet, tablet } from "../../../UI/atoms/MediaQueries";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import Colours from "../../../UI/atoms/Colours";

export const ContentPage = styled.div`
  display: flex;
  flex-direction: column;
  @media (${tablet}) {
    flex-direction: row;
  }
`
export const MainContent = styled.div`
  max-width: 1080px;
  width: 100%;
  padding-top: 32px;
  @media (${tablet}) {
    width: 75%;
  }
`;

export const Sidebar = styled.div`
  min-width: 220px;
  width: 100%;
  padding-top: 32px;
  @media (${tablet}) {
    max-width: 380px;
    width: 25%;
    padding: 32px 0 0 16px;
  }
`;

export const SelectionMessage = styled<any>(HeadlineSmall)<any>`
  align-self: center;
  justify-self: center;
  text-align: center;
  width: 100%;
`

export const FullScreenContent = styled.div`
  max-height: inherit;
  position: relative;
  overflow: hidden;
  height: 100%;
  .officeFloorMap__sidebar--open {
    display: block !important;
  }
  .officeFloorMap__sidebar--desktopOnly {
    display: none !important;
    @media (${tablet}) {
      .officeFloorMap__sidebar--open {
        display: block !important;
      }
    }
  }
`

export const FloatingSidebar = styled.div`
  padding: 32px;
  height: 100%;
  min-width: 400px;
  max-width: 400px;
  min-height: 600px;
  background: #FFFFFF;
  box-shadow: 0 9px 22px rgba(46, 74, 148, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: none;
  z-index: 99990;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  .officeFloorMap__sidebar--closeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    @media (${tablet}) {
      display: none;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    position: fixed;
    z-index: 99999999;
    display: none;
  }
  @media (${tablet}) {
    min-width: 300px;
  }
`

export const TeamContentCardList = styled.div`
  width: 100%;
  & * {
    text-decoration: none;
  }
  & > * {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (${smallTablet}) {
    display: flex;
    flex-wrap: wrap;
    & > * {
      width: 30%;
      max-width: 310px;
    }
  }
`


export const MapButtonRow = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  padding: 8px 4% 8px 16px;
  width: 100%;
`

export const MapControlsContainer = styled.div`
  display: flex;
  margin-left: auto;
`


export const MapControlButton = styled.div<any>`
  border-radius: 50%;
  height: 48px;
  width: 48px;
  background-color: white;
  border: solid 1px ${props => props.colour ?? Colours.mildGrey};
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  span {
    color: ${props => props?.colour ?? Colours.mildGrey};
  }
`
