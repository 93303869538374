import React from 'react';
import styled from "styled-components/macro";
import { HeadlineMedium } from "../../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../../store/ducks/dialog.duck";
import { useDispatch, useSelector } from "react-redux";
import { selectCalendarDate } from "../../../../../../store/ducks/dashboard.duck";
import { selectSelectedOffice } from "../../../../../../store/ducks/officeView.duck";

export function NoVisitorBookings(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const selectedOffice = useSelector(selectSelectedOffice);
  const {t} = useTranslation();
  return (
    <Container>
      <img src={"/assets/images/shapes.svg"} alt={""} />
      <HeadlineMedium>{t('visitor-booking.no-visitors-today')}</HeadlineMedium>
      <PrimaryButton fullWidth={true} click={() => dispatch(openDialogWithPayload({
        payload: { date: calendarDate, office: selectedOffice },
        activeDialog: DialogIdentifiers.AddVisitorDialog
      }))} text={'visitor-booking.add-visitor'} />
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 80px;
  }
  h3 {
    margin-bottom: 32px;
  }
`
