import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { FlatContentCard } from "../../UI/atoms/FlatContentCard";
import TextField from "../../UI/atoms/TextField";
import { Center } from "../../UI/atoms/StructuralLayout";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { BorderlessButton } from "../../UI/atoms/buttons/BorderlessButton";
import Dialog from "../../UI/molecules/Dialog";
import { failureNotification, successNotification, warningNotification } from "../../../store/ducks/notification.duck";
import { selectRepeatScheduleWeeksLimit } from "../../../store/ducks/config.duck";
import { BodyLarge } from "../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { DayHeaders } from "./DayHeaders";
import { UsersWhereaboutsDay } from "../../../models/movements.models";
import MovementsWeek from "../../UI/organisms/movements-wallchart/components/team-member-row/MovementsWeek";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { selectActiveDay } from "../../../store/ducks/editMovements.duck";
import { DATE_FORMAT } from "../../../utils/DateUtils";
import { makeWhereaboutsScheduleRequest } from "../../../services/Movements";
import moment from "moment";

export function RepeatScheduleDialog(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const weekLimit = useSelector(selectRepeatScheduleWeeksLimit);
  const activeDay = useSelector(selectActiveDay);
  const includeWeekends = currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled;
  const [loading, setLoading] = useState(false);
  const [numberOfWeeks, setNumberOfWeeks] = useState('1');
  const {t} = useTranslation();

  const dateFrom = activeDay?.clone().startOf('isoWeek');
  const dateTo = dateFrom?.clone().add(includeWeekends ? 7 : 5, 'days');

  const onNumberOfWeeksChange = (val: string) => {
    const inputredValue = parseInt(val);
    setNumberOfWeeks(inputredValue.toString());
  }

  const close = () => {
    dispatch(closeDialog());
  }

  const repeatSchedule = () => {
    setLoading(true);
    const numberOfWeeksNumber = parseInt(numberOfWeeks)

    if (numberOfWeeksNumber < 1 || numberOfWeeksNumber > (weekLimit ?? 0)) {
      dispatch(failureNotification(`Weeks to change must be between 1 and ${(weekLimit ?? 0)}`));
      return;
    }

    makeWhereaboutsScheduleRequest(props.payload.whereabouts, (dateFrom ?? moment()).format(DATE_FORMAT), numberOfWeeksNumber, props.payload.userId)
      .then((result: any) => {
        if (result.errors.length > 0) {
          dispatch(warningNotification('Some weeks have NOT been updated'))
        } else {
          dispatch(successNotification('All weeks updated'))
        }
      })
      .then(() => close())
      .finally(() => {
        setLoading(false);
      })
  }

  if (!weekLimit || weekLimit === 0) {
    return (<DialogWrapper>
      <Dialog isOpen={true} onClose={close} showLogo={true}>
        <HeadlineMedium style={{marginBottom: 12}}>{t('whereabouts.repeat-schedule.title')}</HeadlineMedium>
        <Center><BodyLarge>{t('whereabouts.repeat-schedule.cannot-be-done-setting-not-set')}</BodyLarge></Center>
        <ButtonContainer>
          <OutlineButton click={close} text={'whereabouts.repeat-schedule.close'} size={'large'} />
        </ButtonContainer>
      </Dialog>
    </DialogWrapper>)
  } else if (loading) {
    return (<DialogWrapper>
        <Dialog isOpen={true} onClose={close} showLogo={true}>
          <HeadlineMedium style={{marginBottom: 12}}>{t('whereabouts.repeat-schedule.title')}</HeadlineMedium>
        </Dialog>
      </DialogWrapper>
    )
  } else {
    return (
      <DialogWrapper>
        <Dialog isOpen={true} onClose={close} showLogo={true}>
          <HeadlineMedium style={{marginBottom: 40, alignSelf: 'center'}}>{t('whereabouts.repeat-schedule.title')}</HeadlineMedium>
          <DayHeaders />
          <MovementsWeek dateFrom={dateFrom?.format(DATE_FORMAT) ?? ''}
                         dateTo={dateTo?.format(DATE_FORMAT) ?? ''}
                         movements={props.payload.whereabouts}
                         enableSetMovements={false}
                         areUsersMovements={false}
                         isEditing={false}
                         sevenDayWeekEnabled={currentUser?.companyEntity?.sevenDayWhereaboutsWeekEnabled}
                         style={{marginBottom: 32, marginTop: 8, width: '100%'}}
                         userId={''}
                         carParking={[]} />
          <FlatContentCard style={{paddingTop: 16, paddingBottom: 16}}>
            <TextField onChange={onNumberOfWeeksChange}
                       value={numberOfWeeks}
                       leftText={'whereabouts.repeat-schedule.repeat-schedule-for'}
                       rightText={'whereabouts.repeat-schedule.weeks'} />
          </FlatContentCard>
          <ButtonContainer>
            <PrimaryButton style={{marginBottom: 16}} click={repeatSchedule} text={'whereabouts.repeat-schedule.confirm-schedule'} size={'large'} />
            <BorderlessButton click={close} text={'whereabouts.repeat-schedule.cancel'} size={'large'} />
          </ButtonContainer>
        </Dialog>
      </DialogWrapper>
    )
  }
}

interface Props {
  payload: {
    whereabouts: UsersWhereaboutsDay[];
    userId: string;
  };
}

const DialogWrapper = styled.div`
  .textField__input {
    max-width: 48px;
    text-align: center;
  }
  .dialog__content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
  }
  .dialog {
    width: 890px !important;
    max-width: 100%;
  }
  .textField {
    margin: 0;
  }
  .textField__leftText, .textField__rightText {
    font-weight: 500;
  }
`

const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
