import styled from "styled-components/macro";
import Colours from "./Colours";
import { tablet } from "./MediaQueries";

export const VerticalSpacer = styled.div`
  align-self: stretch;
  width: 1px;
  background-color: ${Colours.mildGrey};
  content: '';
  display: none;
  @media (${tablet}) {
    display: block;
  }
`

export const HorizontalSpacer = styled.div<any>`
  align-self: stretch;
  width: 100%;
  background-color: ${props => props.colour ?? Colours.mildGrey};
  height: 1px;
  margin: 16px 0;
  content: '';
`

export const Spacer = styled.div`
  margin-bottom: 0;
  @media (${tablet}) {
    margin-bottom: 36px;
  }
`
