import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';

export interface GeneralState {
  menuOpen: boolean;
}

export const initialGeneralState: GeneralState = {
  menuOpen: false,
}

export const setMenuOpen: any = createAsyncThunk(
  'general/setMenuOpen',
  async (payload: boolean, thunkAPI) => {
    if (payload) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
    return payload;
  }
);

const generalSlice = createSlice({
  name: 'general',
  initialState: initialGeneralState,
  reducers: {},
  extraReducers: {
    [setMenuOpen.fulfilled]: (state, action) => ({...state, menuOpen: action.payload})
  }
})

export default generalSlice.reducer

// Selectors
export const selectMenuOpen = (state: AppState) => state.general.menuOpen;
