import React from 'react';
import Layout from "../../../UI/organisms/layout/Layout";
import { MeetingRoomBookings } from "../components/MeetingRoomBookings";

export function RoomBookings(props: Props) {
  return (
    <Layout>
      <MeetingRoomBookings />
    </Layout>
  )
}

interface Props {

}
