import React, { useState } from 'react';
import { ConfigurationSection, SpaceBetweenRow } from "../../../pages/settings/StyleComponents";
import { BodyLarge, BodyRegular } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import { SimpleSpinner } from "../../atoms/SimpleSpinner";
import { Switch } from "../../atoms/Switch";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { updateWeeklyNotes } from "../../../../store/ducks/onboarding.duck";
import { useTranslation } from "react-i18next";

export function WeeklyNotesSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();

  const onChange = (val: boolean) => {
    setIsLoading(true);
    try {
      dispatch(updateWeeklyNotes(val));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ConfigurationSection style={{marginTop: 32, marginBottom: 32}}>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('settings.weekly-notes.title')}</BodyLarge>
        {isLoading ? <SimpleSpinner size={32} /> : <Switch value={!!user?.companyEntity.weeklyNotesEnabled} label="" onChange={onChange}/>}
      </SpaceBetweenRow>
      {user?.companyEntity.lineManagementEnabled ? <>
        <BodyRegular style={{marginBottom: 8}}>{t('settings.weekly-notes.enabled-message')}</BodyRegular>
      </> : <>
        <BodyRegular style={{marginBottom: 8}}>{t('settings.weekly-notes.disabled-message')}</BodyRegular>
      </>}
    </ConfigurationSection>
  )
}
