
export function dedup(array: any, matcher: any) {
  return array.filter((thingA: any, index: number) => {
    return index === array.findIndex((thingB: any) => matcher(thingA, thingB));
  });
}

export function groupBy(xs: any[], key: string) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function naturalSort(arr: any[], key: string): any[] {
  return arr.sort(function(as, bs) {
    return naturalComparator(as, bs, key);
  });
}

export function naturalComparator(as: any, bs: any, key: string) {
  let a, b, a1, b1, rx=/(\d+)|(\D+)/g, rd=/\d+/;
  a = String(as[key]).toLowerCase().match(rx) || [];
  b = String(bs[key]).toLowerCase().match(rx) || [];

  // @ts-ignore
  while(a.length && b.length){
    // @ts-ignore
    a1 = a.shift();
    // @ts-ignore
    b1 = b.shift();
    // @ts-ignore
    if(rd.test(a1) || rd.test(b1)){
      // @ts-ignore
      if(!rd.test(a1)) return 1;
      // @ts-ignore
      if(!rd.test(b1)) return -1;
      // @ts-ignore
      if(a1 !== b1) return a1 - b1;
    }
    // @ts-ignore
    else if(a1 !== b1) return a1> b1? 1: -1;
  }
  // @ts-ignore
  return a.length - b.length;
}
