import React from "react";
import { BodyLarge, BodySmall } from "../../../../../UI/atoms/fonts/Body";
import { ConfigurationList, SpaceBetweenRow } from "../../../StyleComponents";
import Colours from "../../../../../UI/atoms/Colours";
import Icon, { IconTypes } from "../../../../../UI/atoms/icon/Icon";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { desktopSmall } from "../../../../../UI/atoms/MediaQueries";

interface Props {
  icon: IconTypes;
  bullets: string[];
  linkTo: string;
  title: string;
  dataTest?: string;
  style?: any;
}

export default function SettingsSectionContentCard(props: Props) {
  const {t} = useTranslation();
  return (
    <SettingsSectionLink to={props.linkTo} style={{textDecoration: 'none'}} data-test={props.dataTest}>
      <SettingsSectionContentCardWrapper style={props.style ?? {}}>
        <SettingsSectionIconColumn>
          <Icon icon={props.icon} size="mediumlarge"/>
        </SettingsSectionIconColumn>

        <SettingsContentColumn>
          <SettingsHeaderRow singleRow={props.bullets.length === 0}>
            <BodyLarge style={{marginRight: 16}} colour={Colours.blue}>{t(props.title)}</BodyLarge>
            <Icon color="blueIcon" icon={IconTypes.Next} size="mediumlarge"/>
          </SettingsHeaderRow>
          {props.bullets.length > 0 && <ConfigurationList>
            {props.bullets.map((bullet: string, key: number) => (
              <li key={key} style={{color: Colours.black}}>
                <BodySmall style={{display: 'inline'}}>{t(bullet)}</BodySmall>
              </li>
            ))}
          </ConfigurationList>}
        </SettingsContentColumn>

      </SettingsSectionContentCardWrapper>

    </SettingsSectionLink>
  )
}

export const SettingsHeaderRow = styled<any>(SpaceBetweenRow)<any>`
  ${props => css`border-bottom: ${!props.singleRow ? `1px solid ${Colours.iconGrey}` : 'none'}`};
  ${props => css`padding-bottom: ${!props.singleRow ? `8px` : '0px'}`};
  ${props => css`margin-bottom: ${!props.singleRow ? `12px` : '0px'}`};
`

export const SettingsContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SettingsSectionIconColumn = styled.div`
  display: flex;
  flex-direction: column;
`


export const SettingsSectionContentCardWrapper = styled.div`
  width: 100%;
  box-shadow: 0 0 17px 2px rgba(0, 87, 255, 0.15);
  display: flex;
  padding: 16px 24px;
  border: 1px solid #BCC6C7;
  border-radius: 8px;
  margin-bottom: 16px;
  @media (${desktopSmall}) {
    min-height: 190px;
  }
`

export const SettingsSectionLink = styled<any>(Link)<any>`
  width: 100%;
  @media (${desktopSmall}) {
    width: 46%;
    margin: 0 2% 0 2%;
  }
`
