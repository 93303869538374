import React, { useEffect, useMemo, useState } from 'react';
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { Desk, getAllAvailableDesksInOffice } from "../../../services/DeskBookingService";
import styled from "styled-components";
import { BodyRegular } from "../atoms/fonts/Body";
import { SimpleSpinner } from "../atoms/SimpleSpinner";
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { tablet } from "../atoms/MediaQueries";
import { Moment } from "moment";
import { Period } from "../../../models/movements.models";

export function DesksAvailableLabel(props: Props) {
  const {office, date, period} = props;
  const [desks, setDesks] = useState<Desk[]>([]);
  const containsDesks = useMemo(() => office.deskBookingEnabled, [office]);

  useEffect(() => {
    if (containsDesks) {
      getAllAvailableDesksInOffice(office.id, date, period).then(setDesks);
    }
  }, [office, containsDesks, date, period])

  if (!containsDesks) {
    return null;
  }

  return (
    <OfficeCapacityLabelContainer>
      {containsDesks && desks.length >= 0 ?
        <AvailableMessage>{desks.length} Desks available</AvailableMessage> :
        <>
          <SimpleSpinner size={16} />
          <AvailableMessage>Desks available</AvailableMessage>
        </>
      }


      <Icon size="medium" icon={IconTypes.Chair} style={{marginRight: 16}} />
    </OfficeCapacityLabelContainer>
  )
}

interface Props {
  office: OfficeEntity;
  date: Moment;
  period: Period;
}

const OfficeCapacityLabelContainer = styled.div`
  display: flex;
  align-items: center;
  .simple-spinner {
    margin-right: 4px;
  }
  .icon {
    margin-left: 16px;
  }
`

const AvailableMessage = styled<any>(BodyRegular)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  @media (${tablet}) {
    max-width: unset;
  }
`
