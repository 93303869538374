import React from 'react';
import Layout from "../../../../UI/organisms/layout/Layout";
import { FloatingSidebar, FullScreenContent } from "../../../company-movements/components/ViewComponents";
import styled from "styled-components/macro";
import { desktop, largeMobile, tablet } from "../../../../UI/atoms/MediaQueries";
import { useSelector } from "react-redux";
import { selectAddTeamMemberView } from "./ducks/add-users.duck";
import { AddTeamMemberView } from "./ducks/AddUsers.model";
import { AddUser } from "./components/AddUser";
import { AddUserSuccess } from "./components/AddUserSuccess";
import { UserLimitReached } from "./components/UserLimitReached";
import { selectIsConfigLoading } from "../../../../../store/ducks/config.duck";
import { UserMovedSuccessfully } from "./components/UserMovedSuccessfully";
import { AddUserSidebar } from "./components/AddUserSidebar";
import { isInTeams } from "../../../../../utils/TeamsUtils";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import Colours from "../../../../UI/atoms/Colours";

export function AddUsersPage(props: Props) {
  document.body.style.backgroundColor = Colours.veryLightGrey;
  const view = useSelector(selectAddTeamMemberView);
  const loading = useSelector(selectIsConfigLoading);
  return (
    <Layout style={{marginTop: isInTeams() ? 0 : 60}} hideFooter={true} fullScreenView={true}>
      <FullScreenContent>
        <Sidebar>
          <AddUserSidebar />
        </Sidebar>
        {loading ? <></> : <ContentWrapper>
          <Content>
            <GoBackButton />
            {view === AddTeamMemberView.AddUser && <AddUser />}
            {view === AddTeamMemberView.UserAddedSuccessful && <AddUserSuccess />}
            {view === AddTeamMemberView.UserMovedSuccessful && <UserMovedSuccessfully />}
            {view === AddTeamMemberView.AtUserLimit && <UserLimitReached />}
          </Content>
        </ContentWrapper>}
      </FullScreenContent>
    </Layout>
  )
}

interface Props {
}

const Sidebar = styled<any>(FloatingSidebar)<any>`
  display: none;
  @media (${tablet}) {
    display: block;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 16px;
  @media (${largeMobile}) {
    padding: 0;
  }
`

const Content = styled.div`
  max-width: 440px;
  padding-bottom: 80px;
  margin: 32px auto 0 auto;
  @media (${tablet}) {
    margin-left: 5%;
  }
  @media (${'min-width: 1000px'}) {
    margin-left: 15%;
  }
  @media (${desktop}) {
    margin: 32px auto 0 auto;
    margin-left: auto;
  }
`
