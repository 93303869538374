import React, { useMemo } from 'react';
import { Link } from "react-router-dom";
import { isInTeams } from "../../../../../utils/TeamsUtils";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";

export function BrandingLogo() {
  const currentUser = useSelector(selectCurrentUser);
  const logoUrl = useMemo(() => currentUser?.companyEntity.logoUrl || 'team-today-white.svg', [currentUser]);
  const isAuthenticated = !!currentUser;

  return (
    <div className="branding row">
      {isAuthenticated ?
        <Link to="/whereabouts/team" className="brand">
          <img data-test="brand-name" className="brand-name" src={`/assets/images/logos/${logoUrl}`} alt="Team Today logo"/>
        </Link> :
        <a href={(isInTeams() ? '/login' : 'https://team-today.com')} className="brand">
          <img data-test="brand-name" className="brand-name" src={`/assets/images/logos/${logoUrl}`} alt="Team Today logo"/>
        </a>
      }
    </div>
  )
}
