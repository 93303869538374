import React, { useMemo } from 'react';
import { FloatingUserCell } from "./FloatingUserCell";
import {
  BorderDayCell,
  PROJECT_ROW_HEIGHT,
  WallPlannerRow,
  WEEK_WIDTH,
  WeekCell
} from '../WallPlannerStructuralComponents';
import styled, { css } from "styled-components/macro";
import { BodyRegular, BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { useSelector } from "react-redux";
import { selectTeamProjects } from "../wallPlanner.duck";
import { daysBetween } from "../../../../utils/DateUtils";
import { TeamEvent } from "../../../../models/wall-planner.models";
import { smallTablet } from "../../../UI/atoms/MediaQueries";
import { WithWeeksInCurrentCalendarMonth } from "../../../../hooks/WithWeekInCurrentCalendarMonth";
import { useTranslation } from "react-i18next";

interface Props {
  team: any
}

export function WallPlannerProjectRow(props: Props) {
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();
  const allProjects = useSelector(selectTeamProjects);
  const {t} = useTranslation();
  const teamProjects = useMemo(() => allProjects.filter((teamEvent: TeamEvent) => teamEvent.teamId === props.team.id) ?? [], [props.team.id, allProjects]);
  if (!weeksInMonth || weeksInMonth.length === 0) {
    return null;
  }
  const firstDay = weeksInMonth[0];

  const onProjectEventClicked = (projectEvent: TeamEvent) => {
    window.location.href = `/work-planner/event/${projectEvent.id}`;
  }

  return (
    <WallPlannerProjectRowWrapper weekCount={weeksInMonth.length} projectCount={teamProjects.length}>
      <FloatingUserCell>
        <BodyRegular weight={400} style={{alignSelf: 'flex-start'}}>{t('work-planner.events-and-projects')}</BodyRegular>
      </FloatingUserCell>
      {weeksInMonth.map((timeFrame, k) => (
        <WeekCell key={k}>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell weekend={true}/>
          <BorderDayCell weekend={true}/>
        </WeekCell>
      ))}
      <ProjectActivitiesContainer projectCount={teamProjects.length}>
        <ProjectContainer style={{height: teamProjects.length * 28}}>
          {teamProjects.map((project: TeamEvent, key: number) => (
            <ProjectFill key={key} heightOffset={key}
                         start={daysBetween(project.from, firstDay)}
                         days={daysBetween(project.to, project.from) + 1}
                         title={project.eventName}
                         onClick={() => onProjectEventClicked(project)}>
              <BodyVerySmall weight={600}>{project.eventName}</BodyVerySmall>
            </ProjectFill>
          ))}
        </ProjectContainer>
      </ProjectActivitiesContainer>
    </WallPlannerProjectRowWrapper>
  )
}

export const WallPlannerProjectRowWrapper = styled<any>(WallPlannerRow)<any>`
  height: ${props => (props.projectCount ?? 1) * PROJECT_ROW_HEIGHT + 50}px;
  width: 100%;
  .floatingUser__userColumn {
    min-height: 40px;
  }
  @media (${smallTablet}) {
    height: ${props => Math.max((props.projectCount ?? 1), 1) * PROJECT_ROW_HEIGHT}px;
  }
`

export const ProjectContainer = styled.div`
`


export const ProjectActivitiesContainer = styled.div<any>`
  position: absolute;
  left: -${WEEK_WIDTH}px;
  top: 40px;
  @media (${smallTablet}) {
    left: 0;
    top: 0;
  }
`

export const ProjectFill = styled.div<any>`
  position: absolute;
  height: 24px;
  align-self: center;
  background: #E7FCFF;
  border: 1px solid #1EE1FF;
  border-radius: 4px;
  transition: background .25s;
  display: flex;
  align-items: center;
  user-select: none;
  top: 4px;
  padding: 2px 8px;
  cursor: pointer;
  ${props => css`left: ${props.start ? (props.start * (WEEK_WIDTH / 7) + 2) + WEEK_WIDTH + 'px' : (WEEK_WIDTH + 2) + 'px'};`};
  ${props => css`top: ${props.heightOffset * 30}px`};
  ${props => css`width: ${props.days ? (props.days * (WEEK_WIDTH / 7) - 6) + 'px' : 0};`};
  p {
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
    text-overflow: ellipsis;
  }
`
