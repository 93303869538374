import React, { useMemo } from 'react';
import { SpaceBetweenRow } from "../../../StyleComponents";
import { ReactSelectDropDownList } from "../../../../../UI/organisms/drop-down-list/ReactSelectDropDownList";
import TeamMultiSelect from "../../../../../UI/organisms/team-drop-down-list/TeamMultiSelect";
import { TeamEntity } from "../../../../../../models/team.models";
import {
  MANAGE_USERS_SORT_OPTIONS,
  MANAGE_USERS_SORT_OPTIONS_FOR_MISC,
  MANAGE_USERS_SORT_OPTIONS_FOR_OFFICES
} from "../ducks/ManageUsers.model";
import { WithTeams } from "../hooks/WithTeams";
import styled from "styled-components/macro";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUsers,
  selectIsManageUsersLoading,
  selectNumberOfUsersSkipped,
  selectSearchQuery,
  selectSelectedMisc,
  selectSelectedOffice,
  selectSelectedSortOption,
  selectSelectedTeam,
  selectTotalUsersInQuery,
  selectTotalUsersReturned,
  setSelectedMisc,
  setSelectedOffice,
  setSelectedTeam,
  setSortOption
} from "../ducks/manage-users.duck";
import { tablet } from "../../../../../UI/atoms/MediaQueries";
import { selectOffices } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { Tag } from "../../../../../../models/tags.models";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";


export function UserListViewControl(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const teams = WithTeams();
  const selectedTeam = useSelector(selectSelectedTeam);
  const selectedOffice = useSelector(selectSelectedOffice);
  const selectedMiscItem = useSelector(selectSelectedMisc);
  const selectedSortOption = useSelector(selectSelectedSortOption);
  const isManageUsersLoading = useSelector(selectIsManageUsersLoading);
  const totalUsersInQuery = useSelector(selectTotalUsersInQuery);
  const totalUsersReturned = useSelector(selectTotalUsersReturned);
  const skipped = useSelector(selectNumberOfUsersSkipped);
  const searchTerm = useSelector(selectSearchQuery);
  const offices = useSelector(selectOffices);

  const sortOptions = useMemo(() => {
    if (selectedOffice) return MANAGE_USERS_SORT_OPTIONS_FOR_OFFICES;
    else if (selectedMiscItem) return MANAGE_USERS_SORT_OPTIONS_FOR_MISC;
    else return MANAGE_USERS_SORT_OPTIONS;
  }, [selectedMiscItem, selectedOffice])

  const selectedSortItem = useMemo(() => {
    return MANAGE_USERS_SORT_OPTIONS.find(so => so.value === selectedSortOption);
  }, [selectedSortOption])

  const onSortOptionSelect = (id: any) => {
    dispatch(setSortOption(id.value));
    dispatch(loadUsers({orderBy: id.value, team: selectedTeam, misc: setSelectedMisc, office: selectedOffice, skip: skipped, searchTerm: searchTerm}));
  }

  const onTeamSelect = (teams: TeamEntity[], workGroups: Tag[], offices: OfficeEntity[], showLineReports?: boolean, misc?: string[]) => {
    if (offices.length > 0) {
      dispatch(setSelectedOffice(offices[0]));
    } else if (misc && misc.length > 0) {
      console.log(misc[0])
      dispatch(setSelectedMisc(misc[0]));
    } else if (teams.length > 0) {
      dispatch(setSelectedTeam(teams[0]));
    }

    dispatch(loadUsers({orderBy: selectedSortOption, team: teams[0], office: offices[0], misc: (misc ? misc[0] : undefined), searchTerm: searchTerm}));
  }

  return (
    <SpaceBetweenRow>
      <TeamSelectWrapper>
        <TeamsDropDownWrapper>
          <TeamMultiSelect teams={teams}
                           offices={offices}
                           selectedTeams={teams.length > 0 && !!selectedTeam ? [selectedTeam] : undefined}
                           selectedOffices={offices.length > 0 && !!selectedOffice ? [selectedOffice] : undefined}
                           selectedMisc={!!selectedMiscItem ? [selectedMiscItem] : undefined}
                           disabled={isManageUsersLoading}
                           singleSelect={true}
                           showInactiveUserOption={true}
                           onSelect={onTeamSelect} />
        </TeamsDropDownWrapper>
        <BodyRegular className={'userList__userCounts'} weight={600}>{t('manage-users-page.showing-users', {skip: skipped ,count: totalUsersReturned + skipped, total: totalUsersInQuery})}</BodyRegular>
      </TeamSelectWrapper>
      <SortDropDownWrapper>
        <ReactSelectDropDownList items={sortOptions}
                                 disabled={isManageUsersLoading}
                                 selectedItem={selectedSortItem}
                                 onItemSelected={onSortOptionSelect} />
      </SortDropDownWrapper>
    </SpaceBetweenRow>
  )
}

interface Props {
}

const TeamsDropDownWrapper = styled.div`
  width: 100%;
  @media (${tablet}) {
    margin-right: 32px;
    width: 300px;
  }
`

const SortDropDownWrapper = styled.div`
  width: 300px;
  display: none;

  @media (${tablet}) {
    display: block;
  }
`

const TeamSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (${tablet}) {
    flex-direction: row;
  }
  
  .userList__userCounts {
    display: none;
    @media (${tablet}) {
      display: block;
    }
  }
`
