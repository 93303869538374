import React from 'react';
import { BodyRegular, BodySmall } from "../../atoms/fonts/Body";
import Colours from "../../atoms/Colours";
import styled from "styled-components/macro";
import { SimpleUserWithLocation } from "../../../../models/movements.models";
import { Center, Column, SpaceBetweenRow } from "../../atoms/StructuralLayout";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices } from "../../../../utils/OfficeHelper";
import { HorizontalSpacer } from "../../atoms/VerticalSpacer";
import { useTranslation } from "react-i18next";
import { Tick } from "../../atoms/Tick";

export function DailyFirstAidersInOffice(props: Props) {
  const {usersInOffice, loading, hasRequested} = props;
  const allOffices = useSelector(selectAllOfficeEntities);
  const {t} = useTranslation();

  if (allOffices.length === 0) {
    return null;
  }

  return (
    <Container>
      <SpaceBetweenRow style={{padding: 0, alignItems: 'center'}}>
        <BodyRegular weight={600} style={{maxWidth: '80%'}}>{t('reports-page.first-booked-in')}</BodyRegular>
        <img src={"/assets/icons/firstAider-logo.svg"} alt={""} />
      </SpaceBetweenRow>
      <HorizontalSpacer colour={Colours.lightGrey} style={{marginTop: 8, marginBottom: 8}} />
      {loading ? <></> : <>
        <div>
          {hasRequested && usersInOffice.length === 0 && <>
            <Center>
              <BodyRegular>{t('reports-page.no-first-booked-in')}</BodyRegular>
            </Center>
          </>}
          {hasRequested && usersInOffice.length > 0 && <>
            <Column>
              {usersInOffice.map((user, key) => <UserInOfficeRow key={key}>
                <Tick style={{marginRight: 12}} />
                <Column>
                  <BodySmall weight={600}>{user.firstName} {user.lastName}</BodySmall>
                  <BodySmall style={{marginTop: -4}} colour={Colours.darkGrey}>{findOfficeInOffices(allOffices, user.officeId)?.label ?? ''}</BodySmall>
                </Column>
              </UserInOfficeRow>)}
            </Column>
          </>}
        </div>
      </>}
    </Container>
  )
}

interface Props {
  usersInOffice: SimpleUserWithLocation[];
  loading: boolean;
  hasRequested: boolean;
}

export const UserInOfficeRow = styled.div`
  display: flex;
  margin-bottom: 4px;
`

const Container = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 24px;
  background-color: ${Colours.veryLightGrey};
  border-radius: 24px;
  margin-bottom: 16px;
`
