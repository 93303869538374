export function roundUpNearest10(num: number) {
  return Math.ceil(num / 10) * 10;
}

export function getInt(value?: string) {
  if (!value) return 0;
  try {
    return parseInt(value);
  } catch (e) {
    return 0;
  }
}

export function safeParseInt(value?: string) {
  if (!value) throw new Error();
  const int = parseInt(value);
  if (int || int === 0) {
    return int;
  }
  throw new Error();
}
