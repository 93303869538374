import React, { useEffect, useMemo, useState } from 'react';
import styled from "styled-components/macro";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import moment, { Moment } from "moment";
import { getUnknownWhereaboutsReport } from "../../../services/ReportingService";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Center } from "../../UI/atoms/StructuralLayout";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { BasicUserInfo, UnknownWhereaboutsUserInfo } from "../../../models/user.models";
import { UserAvatarAndName } from "../../UI/molecules/UserAvatarAndName";
import { TableContents } from "../../UI/atoms/TableStructure";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { canUserViewTeamReports } from "../../../utils/AccessControl";
import AccessRequired from "../settings/components/AccessRequired";
import { BodyLarge, BodyRegular } from "../../UI/atoms/fonts/Body";
import Colours from "../../UI/atoms/Colours";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { FlatContentCard } from '../../UI/atoms/FlatContentCard';
import { sendWhereaboutsReminder } from "../../../services/Movements";
import { SimpleSpinner } from "../../UI/atoms/SimpleSpinner";
import { useTranslation } from "react-i18next";


export function UnknownWhereaboutsReportingPage() {
  const [date, setDate] = useState<Moment>(moment());
  const [reportData, setReportData] = useState<UnknownWhereaboutsUserInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => canUserViewTeamReports(currentUser),[currentUser]);
  const {t} = useTranslation();

  const onDateChange = (date: Moment) => {
    setDate(date);
  }

  useEffect(() => {
    if (date) {
      setReportData([]);
      setLoading(true);
      getUnknownWhereaboutsReport(date)
        .then(setReportData)
        .finally(() => setLoading(false));
    }
  }, [date]);

  return (
    <ReportingContainer>
      <GoBackButton url={"/manage/reporting"} />
      <HeadlineLarge>{t('reports-page.unknown-whereabouts.title')}</HeadlineLarge>
      <AccessRequired visible={!hasAccess} />
      {hasAccess &&
        <>
          {loading ? <>
            <Center>
              <LoadingSpinner hideBorder={true} />
            </Center>
          </> : <>
            <ReportContainer className="tableWrapper">
              <SpaceBetweenRow>
                <DateFlicker dateScale={DateScale.Week}
                             updateGlobalDate={false}
                             date={date}
                             onChange={onDateChange}
                             disabled={loading} />
                <div>
                </div>
              </SpaceBetweenRow>
              {reportData.length === 0 ? <>
                <FlatContentCard>
                  <BodyLarge weight={600}>{t('reports-page.unknown-whereabouts.note')}</BodyLarge>
                  <BodyRegular>{t('reports-page.unknown-whereabouts.all-users-have-whereabouts-set')}</BodyRegular>
                </FlatContentCard>
              </> : <>
                <table>
                  <tbody>
                  {reportData.map((user: UnknownWhereaboutsUserInfo, key: number) => (
                    <tr key={key}>
                      <UnknownWhereaboutsRow user={user} date={date} />
                    </tr>
                  ))}
                  </tbody>
                </table>
              </>}
            </ReportContainer>
          </>}
        </>
      }
    </ReportingContainer>
  );
}

interface UnknownWhereaboutsRowProps {
  user: UnknownWhereaboutsUserInfo;
  date: Moment;
}

function UnknownWhereaboutsRow(props: UnknownWhereaboutsRowProps) {
  const {user, date} = props;
  const [lastNotifiedDate, setLastNotifiedDate] = useState<Moment | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    setLastNotifiedDate(!!user?.lastNotifiedDate ? moment(user.lastNotifiedDate) : undefined);
  }, [user]);

  const sendReminder = async (user: BasicUserInfo) => {
    setLoading(true);
    try {
      await sendWhereaboutsReminder(user.userId, date);
      setLastNotifiedDate(moment())
    } catch (e: any) {
      console.error(e)
      dispatch(failureNotification('Failed to send reminder'));
    } finally {
      setLoading(false);
    }
  }

  return <>
    <th className="table__userCell">
      <UserAvatarAndName colour={user.emailVerified ? "blue" : "grey"}
                         firstName={user.firstName}
                         lastName={user.lastName}
                         showEditLink={true}
                         userId={user.userId} />
    </th>
    <td className="table__userCell" style={{marginLeft: 'auto'}}>
      <TableContents colour={Colours.darkGrey}>
        {!user.emailVerified && t('User has not verified email')}
      </TableContents>
    </td>
    <td className="table__userCell">
      <TableContents colour={Colours.darkGrey}>
        {lastNotifiedDate && user.emailVerified && t('reports-page.unknown-whereabouts.reminder-last-sent', {days: moment().diff(moment(lastNotifiedDate), 'days')})}
      </TableContents>
    </td>
    <td className="table__userCell">
      <TableContents>
        {loading ?
          <SimpleSpinner size={16} /> :
          <OutlineButton size={"medium"}
                         text={"button.send-reminder"}
                         disabled={loading || !user.emailVerified || (lastNotifiedDate ? moment().diff(moment(lastNotifiedDate), 'days') < 2 : false)}
                         click={() => sendReminder(user)} />
        }
      </TableContents>
    </td>
  </>
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
`

const ReportContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  tr {
    justify-content: space-between;
    display: flex;
  }
`
