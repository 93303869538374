import React from 'react';
import { useDispatch } from 'react-redux';
import { Column, Row } from '../../../UI/atoms/StructuralLayout';
import { HeadlineMedium } from '../../../UI/atoms/fonts/Headline';
import { BodyLarge } from '../../../UI/atoms/fonts/Body';
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { next } from "../../../../store/ducks/onboarding.duck";
import { useTranslation } from "react-i18next";

export default function OnboardingWelcome() {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  return (
    <div className="onboarding">
      <Column className="onboarding__content">
        <img style={{width: 320, marginBottom: 32}}
             className="onboarding__image"
             src="/assets/images/onboarding-home-image.png"
             alt="" aria-hidden={true} />
        <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.welcome')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 16}}>{t('onboarding.welcome-message')}</BodyLarge>

        <Row style={{marginBottom: 24, marginTop: 32}}>
          <PrimaryButton dataTest="onboarding-welcome-next"
                  size="large"
                  text="button.lets-go"
                  click={() => dispatch(next())}
                  fullWidth={true}/>
        </Row>
      </Column>
    </div>
  )
}
