import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import Dialog from "../../../UI/molecules/Dialog";
import DialogHeader from "../../../dialogs/DialogHeader";
import { LoadingOverlay } from "../../../UI/molecules/LoadingOverlay";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import { selectAllTeams } from "../../../../store/ducks/config.duck";
import TeamMultiSelect from "../../../UI/organisms/team-drop-down-list/TeamMultiSelect";
import { TeamEntity } from "../../../../models/team.models";
import styled from "styled-components/macro";
import { clearSelectedDeskIds, selectSelectedDeskIds } from "../ducks/manageDesks.duck";
import { updateDeskOwnershipsForTeams } from "../../../../services/DeskOwnershipService";
import { successNotification } from "../../../../store/ducks/notification.duck";
import { fetchDesks } from "../../../../store/ducks/deskBooking.duck";
import { selectSelectedOffice } from "../../../../store/ducks/officeView.duck";

export function AssignDesksToTeamDialog() {
  const dispatch = useDispatch();
  const allTeams = useSelector(selectAllTeams);
  const [teams, setTeams] = useState<TeamEntity[]>([]);
  const [loading, setLoading] = useState(false);
  const selectedDeskIds = useSelector(selectSelectedDeskIds);
  const selectedOffice = useSelector(selectSelectedOffice);

  const close = () => {
    dispatch(closeDialog())
  };

  const updateTagClick = async () => {
    setLoading(true);
    try {
      await updateDeskOwnershipsForTeams(selectedDeskIds, teams);
      dispatch(clearSelectedDeskIds());
      if (selectedOffice) {
        dispatch(fetchDesks(selectedOffice.id));
      }
      dispatch(successNotification('Desks assigned to team'))
      close();
    } finally {
      setLoading(false);
    }
  };

  const selectTeam = (teams: TeamEntity[]) => {
    setTeams(teams)
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-desks-sidebar.assign-desks-to-team" />

      {loading && <LoadingOverlay />}

      <TeamMultiSelect onSelect={selectTeam}
                       teams={allTeams}
                       selectedTeams={teams}
                       disabled={loading} />

      <PrimaryButton disabled={loading}
                     fullWidth={true}
                     click={updateTagClick}
                     style={{marginTop: 'auto'}}
                     text="button.save-changes" />
    </DialogWrapper>
  )
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 410px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 500px;
    min-height: 500px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
`
