import React, { useState } from 'react';
import { Row } from "../../../../../UI/atoms/StructuralLayout";
import TextField from "../../../../../UI/atoms/TextField";
import { updateFilteredUsers } from "../../../../../../store/ducks/userManagement.duck";
import { useDispatch } from "react-redux";
import { IconTypes } from "../../../../../UI/atoms/icon/Icon";

export function UserManagementSearchFilter(props: Props) {
  const [searchParam, setSearchParam] = useState('');
  const dispatch = useDispatch();

  const onSearchChange = (val: string) => {
    setSearchParam(val);
    dispatch(updateFilteredUsers(val));
  }
  return (
    <Row style={{marginBottom: 24}}>
      <TextField onChange={onSearchChange} value={searchParam} label="textinput.search-existing-user" icon={IconTypes.Search} />
    </Row>
  )
}

interface Props {
}
