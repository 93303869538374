import React from 'react';
import './empty-team-view.scss';
import { BodyLarge, BodyRegular } from "../../../../atoms/fonts/Body";
import { Column, Row } from '../../../../atoms/StructuralLayout';
import Colours from "../../../../atoms/Colours";
import { useTranslation } from "react-i18next";

interface Props {
}

export default function RestrictedTagView(props: Props) {
  const {t} = useTranslation()
  return (
    <Row className="emptyTeamView">
      <Column className="emptyTeamView__message">
        <BodyLarge className="emptyTeamView__title" colour={Colours.blue}>{t('team.restricted-team-title')}</BodyLarge>
        <BodyRegular style={{marginBottom: 32}}>{t('team.restricted-team-description')}</BodyRegular>
      </Column>
      <Column className="emptyTeamView__image">
        <img src="/assets/images/man-fishing.svg" alt="Man fishing" />
      </Column>
    </Row>
  )
}
