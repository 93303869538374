import React, { useEffect, useMemo, useState } from 'react';
import { FloatingSidebar, FullScreenContent } from './ViewComponents';
import { useDispatch, useSelector } from "react-redux";
import {
  loadUsersInOffice,
  MapView,
  selectedOffice,
  selectIsUsersInOfficeLoading,
  selectSelectedMapView,
  selectSelectedOffice, setSelectedCarParkSpace
} from "../../../../store/ducks/officeView.duck";
import { CreateFloorPlanInstructions } from "./CreateFloorPlanInstructions";
import { ChoseOfficeSidebar } from "./ChoseOfficeSidebar";
import {
  selectActiveOffices,
  selectIsAdvanceHotDeskingLoading
} from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { SelectOfficeFloor } from "./SelectOfficeFloor";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { Center } from '../../../UI/atoms/StructuralLayout';
import { WithOfficeChildren } from "../../../../hooks/WithOfficeChildren";
import styled from "styled-components/macro";
import { FloorMapHeader } from "./floor-map-view/FloorMapHeader";
import IconButton from "../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import Layout from "../../../UI/organisms/layout/Layout";
import { OfficeFloorMap } from "./OfficeFloorMap";
import { loadAllMeetingRoomsForOffice } from "../../meeting-rooms/models/room-finder.duck";
import { MeetRoomCapacityBands } from "../../meeting-rooms/models/MeetingRooms";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { resetManageDesks, selectSidebarMode } from "../ducks/manageDesks.duck";
import { SidebarMode } from "../data/ManageDesks.model";
import { ManageDesksSidebar } from "./ManageDesksSidebar";
import { WithDefaultOfficeSelection } from "../../../../hooks/WithDefaultOfficeSelection";
import { CarParkingMapView } from "./car-parking-map-view/CarParkingMapView";
import { CarParkSpace } from "../../../../services/CarParkingPlanService";

export function OfficeFloorMapView() {
  const dispatch = useDispatch();
  const isHotDeskingLoading = useSelector(selectIsAdvanceHotDeskingLoading);
  const isUsersInOfficeLoading = useSelector(selectIsUsersInOfficeLoading);
  const selectedOffice = useSelector(selectSelectedOffice);
  const selectedMapView = useSelector(selectSelectedMapView);
  const selectedDate = useSelector(selectActiveDay);
  const sidebarMode = useSelector(selectSidebarMode);
  const [sideBarOpen, setSideBarOpen] = useState(false);


  WithDefaultOfficeSelection(true);

  const isLoading = useMemo(() => isHotDeskingLoading || isUsersInOfficeLoading, [isHotDeskingLoading, isUsersInOfficeLoading]);

  useEffect(() => {
    if (selectedOffice && selectedDate) {
      dispatch(loadUsersInOffice({date: selectedDate, office: selectedOffice}));
    }
  }, [dispatch, selectedDate, selectedOffice]);

  useEffect(() => {
    if (selectedOffice) {
      dispatch(loadAllMeetingRoomsForOffice({office: selectedOffice, capacityBands: MeetRoomCapacityBands.ANY, facilities: []}));
    }
  }, [dispatch, selectedOffice]);

  useEffect(() => {
    return () => {
      dispatch(resetManageDesks());
    }
  }, [dispatch]);

  const onParkingSpaceSelected = (parkingSpace: CarParkSpace) => {
    dispatch(setSelectedCarParkSpace(parkingSpace));
  }

  return (
    <Layout hideFooter={true} fullScreenView={true} style={{maxWidth: '100%'}}>
      <FullScreenContent>
        <FloatingSidebar className={sideBarOpen ? 'officeFloorMap__sidebar--open' : ''}>
          <IconButton icon={IconTypes.Close} onClick={() => setSideBarOpen(false)} className={'officeFloorMap__sidebar--closeIcon'} />

          {sidebarMode === SidebarMode.ChooseOffice && <ChoseOfficeSidebar />}
          {sidebarMode === SidebarMode.ManageDesks && <ManageDesksSidebar />}
        </FloatingSidebar>
        <FloorMapHeader onSideBarOpen={(val: boolean) => setSideBarOpen(val)} />
        <MapContent>
          {isLoading && <LoadingSpinner fullScreen={true} />}
          <MapContainer>
            {selectedMapView === MapView.CarPark ? <CarParkingMapView onCarParkingSpaceSelected={onParkingSpaceSelected} /> : <FloorMapContent />}
          </MapContainer>
        </MapContent>
      </FullScreenContent>
    </Layout>
  )
}

function FloorMapContent() {
  const currentOffice = useSelector(selectedOffice);
  const offices = useSelector(selectActiveOffices);
  const isLoading = useSelector(selectIsAdvanceHotDeskingLoading);
  const officeChildren = WithOfficeChildren(currentOffice?.id);

  if (offices.length > 0 && currentOffice?.floorPlanEnabled) {
    if (officeChildren.length > 0 && !currentOffice?.hasAreas) {
      return <SelectOfficeFloor />
    } else {
      return <OfficeFloorMap showAdminButtons={true} />
    }
  } else if (isLoading) {
    return <Center><LoadingSpinner hideBorder={true} hideText={true} /></Center>
  } else if (!isLoading && !!currentOffice && !currentOffice.floorPlanEnabled) {
    return <CreateFloorPlanInstructions/>
  } else {
    return null;
  }
}

const MapContent = styled.div`
  display: flex;
  max-height: calc(100vh - 120px - 70px);
  height: 100%;
  max-width: 100%;
  @media (${tablet}) {
    margin-right: 300px;
    margin-left: auto;
    max-width: calc(1400px - ((2200px - (100vw - 1400px)) - 1400px) / 2);
  }
  @media (min-width: 1100px) {
    margin-right: 330px;
  }
  @media (min-width: 2000px) {
    max-width: 1300px;
  }
`

const MapContainer = styled.div`
  flex: 1;
  min-width: 400px;
  overflow: auto;
  max-height: 100%;
`
