import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import { selectAccountInfo, selectNumberOfRemainingUsers } from "../../../../../../store/ducks/payments.duck";
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { OutlineButton } from "../../../../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialog } from "../../../../../../store/ducks/dialog.duck";

export function AddUserSidebar(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useSelector(selectCurrentUser);
  const remainingUsers = useSelector(selectNumberOfRemainingUsers);
  const accountInfo = useSelector(selectAccountInfo);

  const title = useMemo(() => {
    if (user?.companyEntity.userLimit && user.companyEntity.userLimit === 10) {
      return 'manage.add-new-user.free'
    } else if (user?.companyEntity.trial) {
      return 'manage.add-new-user.trial'
    } else if (user?.companyEntity.userLimit && user.companyEntity.userLimit > 150) {
      return 'manage.add-new-user.enterprise'
    } else if (user?.companyEntity.userLimit && user.companyEntity.userLimit > 10) {
      return 'manage.add-new-user.premium'
    } else {
      return 'manage.add-new-user.account'
    }
  }, [user]);

  return (
    <>
      <HeadlineSmall style={{marinBottom: 8}}>{t(title)}</HeadlineSmall>
      <Section>
        <BodyRegular weight={400}>{t('manage.add-new-user.total')}</BodyRegular>
        <BodyRegular weight={600}>{user?.companyEntity.userLimit ?? 0}</BodyRegular>
      </Section>
      <Section>
        <BodyRegular weight={400}>{t('manage.add-new-user.current')}</BodyRegular>
        <BodyRegular weight={600}>{accountInfo?.totalUsers}</BodyRegular>
      </Section>
      <Section style={{marginBottom: 48}}>
        <BodyRegular weight={400}>{t('manage.add-new-user.remaining')}</BodyRegular>
        <BodyRegular weight={600}>{remainingUsers}</BodyRegular>
      </Section>
      <OutlineButton style={{marginBottom: 64}}
                     text={'manage-users-page.add-multiple-users'}
                     click={() => dispatch(openDialog(DialogIdentifiers.AddMultipleUsersInfo))} />
    </>
  )
}

interface Props {
}

const Section = styled.div`
  border-bottom: 1px solid ${Colours.lightGrey};
  padding-bottom: 8px;
  margin-bottom: 8px;
`
