import styled from "styled-components/macro";
import { smallTablet } from "./MediaQueries";

export const ContentCard = styled.div`
  width: 100%;
  box-shadow: 0 12px 32px 2px rgba(0, 87, 255, 0.15);
  background-color: white;
  padding: 16px 24px;
  border-radius: 20px;
  @media (${smallTablet}) {
    padding: 48px 56px;
  }
`

export const BlueContentCard = styled.div`
  display: flex;
  padding: 24px 32px;
  align-items: center;
  gap: 48px;
  width: 100%;
  border-radius: 12px;
  background: var(--Planner-Light-Blue, #E6FCFF);
`
