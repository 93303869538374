import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../UI/molecules/Dialog';
import { BodyRegular } from '../../UI/atoms/fonts/Body';
import './delete-company-dialog.scss';
import {
  selectIsDeleteCompanyDialogOpen,
  selectTeamToDeleteTeam,
  setIsDeleteCompanyDialogOpen
} from "./deleteCompanyDialog.duck";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";

export default function DeleteCompanyDialog() {
  const isOpen = useSelector(selectIsDeleteCompanyDialogOpen);
  const selectedTeam = useSelector(selectTeamToDeleteTeam);
  const dispatch = useDispatch();

  if (!isOpen) {
    return null
  }

  const close = () => {
    dispatch(setIsDeleteCompanyDialogOpen({isOpen: false, company: undefined}));
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => close()} className="deleteCompanyDialog">
      <HeadlineMedium className="deleteCompanyDialog__title">Delete team</HeadlineMedium>
      <div className="deleteCompanyDialog__content">
        <BodyRegular className="deleteCompanyDialog__info">Please email us at <a
          href="mailto:hello@team-today.com">hello@team-today.com</a> where we will confirm your details and process
          your deletion request for <strong>{selectedTeam?.name ?? ''}</strong>.</BodyRegular>

        <OutlineButton className="deleteCompanyDialog__button deleteCompanyDialog__cancelButton"
                       text="No don't remove team"
                       size="large"
                       fullWidth={true}
                       click={() => close()}/>
      </div>
    </Dialog>
  )
}
