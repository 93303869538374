import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { Holiday, HolidayBookingProcessingState } from "../../models/Holiday.model";
import { AppState } from "../state/app.state";
import {
  getHolidayBooking,
  postNewHoliday,
  deleteHolidayBookingRequest,
  updateNewHoliday
} from "../../services/HolidayService";
import { redirectTo } from './auth.duck';
import { failureNotification, successNotification } from './notification.duck';

export interface HolidayBookingState {
  bookingProcessingState: HolidayBookingProcessingState;
  editingHoliday?: any;
  bookedHoliday?: any;
}

export const initialHolidayBooking: HolidayBookingState = {
  bookingProcessingState: HolidayBookingProcessingState.none
}

export const saveHoliday: any = createAsyncThunk(
  'holidays/saveHoliday',
  async (holidayBooking: Holiday, thunkAPI) => {
    try {
      if (holidayBooking.id) {
        return await updateNewHoliday(holidayBooking.id, holidayBooking.name, holidayBooking.emailCaledarEvent, holidayBooking.fromDate(), holidayBooking.toDate());
      } else {
        return await postNewHoliday(holidayBooking.name, holidayBooking.emailCaledarEvent, holidayBooking.fromDate(), holidayBooking.toDate());
      }
    } catch (err: any) {
      thunkAPI.dispatch(failureNotification(err.message));
      throw err;
    }
  }
);

export const loadEditingHoliday: any = createAsyncThunk(
  'holidays/loadEditingHoliday',
  async (holidayBooking: number, thunkAPI) => {
    await new Promise((resolve: any) => setTimeout(() => resolve(), 1500));
    return await getHolidayBooking(holidayBooking);
  }
);

export const deleteHolidayBooking: any = createAsyncThunk(
  'holidays/deleteBooking',
  async (force: any, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    if (appState.holidayBooking.editingHoliday) {
      await deleteHolidayBookingRequest(appState.holidayBooking.editingHoliday.bookingEntity.id);
      await thunkAPI.dispatch(successNotification('Holiday booking deleted'));
      thunkAPI.dispatch(redirectTo('/holidays'));
    }
  }
);

const holidayBookingSlice = createSlice({
  name: 'holidayBooking',
  initialState: initialHolidayBooking,
  reducers: {
    reset: () => initialHolidayBooking,
    clearHolidayBooking: () => initialHolidayBooking
  },
  extraReducers: {

    [deleteHolidayBooking.pending]: (state) => ({...state, bookingProcessingState: HolidayBookingProcessingState.pending, }),
    [deleteHolidayBooking.rejected]: (state) => ({...state, bookingProcessingState: HolidayBookingProcessingState.none, }),
    [deleteHolidayBooking.fulfilled]: (state, action) => ({
      ...state,
      bookingProcessingState: HolidayBookingProcessingState.done,
      editingHoliday: null,
      bookedHoliday: null,
    }),

    [saveHoliday.pending]: (state) => ({...state, bookingProcessingState: HolidayBookingProcessingState.pending, }),
    [saveHoliday.rejected]: (state) => ({...state, bookingProcessingState: HolidayBookingProcessingState.none, }),
    [saveHoliday.fulfilled]: (state, action) => {
      return {
        ...state,
        bookingProcessingState: HolidayBookingProcessingState.done,
        bookedHoliday: action.payload
      }
    },

    [loadEditingHoliday.pending]: (state) => ({...state, bookingProcessingState: HolidayBookingProcessingState.pending, }),
    [loadEditingHoliday.reject]: (state) => ({...state, bookingProcessingState: HolidayBookingProcessingState.none, }),
    [loadEditingHoliday.fulfilled]: (state, action) => ({
      ...state,
      bookingProcessingState: HolidayBookingProcessingState.none,
      editingHoliday: action.payload
    }),
  }
});

export const {
  reset,
  clearHolidayBooking,
} = holidayBookingSlice.actions;
export default holidayBookingSlice.reducer;

// Selectors
export const selectBookingState = (state: AppState) => state.holidayBooking.bookingProcessingState;
export const selectEditingHoliday = (state: AppState) => state.holidayBooking.editingHoliday;
export const selectBookedHoliday = (state: AppState) => state.holidayBooking.bookedHoliday;
