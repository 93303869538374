import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BodyError, BodyRegular, BodySmall, BodyVerySmall } from '../../UI/atoms/fonts/Body';
import TextField from '../../UI/atoms/TextField';
import { requestInviteDetails } from '../../../services/RegisterService';
import { loginWithInvite } from './login.duck';
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Checkbox } from "../../UI/atoms/Checkbox";
import Colours from "../../UI/atoms/Colours";
import { Accordian } from "../../../animations/AnimationVariants";
import styled from "styled-components/macro";
import { Divider } from "../../UI/atoms/Divider";
import InviteErrorMessage from "./InviteErrorMessage";
import { AuthType } from "../../../utils/AuthUtils";
import { Auth } from "../../../services/Auth";
import { googleClientId, microsoftRequest } from "../../../services/EnvironmentVariables";
import GoogleLogin from "react-google-login";
import AuthWrapper from "../auth/components/AuthWrapper";
import { useMsal } from "@azure/msal-react";
import { AzureErrorHandlingMessages } from "../../UI/organisms/auth/AzureErrorHandlingMessages";
import { getParameterByName } from "../../../utils/UrlUtils";
import { TrackJS } from "trackjs";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { Trans, useTranslation } from "react-i18next";

export default function InviteLogin(props: Props) {
  document.body.style.backgroundColor = '#FAFAFA'

  const {t} = useTranslation();
  const {instance} = useMsal();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('rememberMeEmail') || '');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [informMe, setInformMe] = useState(false);
  const [agreeToTnC, setAgreeToTnC] = useState(false);
  const [inviteRequestFailed, setInviteRequestFailed] = useState(false);
  const [showAcceptTncError, setShowAcceptTncError] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [registerType, setRegisterType] = useState('');
  const invitationId = useMemo(() => getParameterByName('id', window.location.href), []);


  useEffect(() => {
    if (!!invitationId) {
      setIsLoading(true);
      requestInviteDetails(invitationId)
        .then((resp: any) => setEmail(resp.email))
        .catch((err: any) => {
          setErrorMessage(err?.message ?? 'error');
          setInviteRequestFailed(true);
        })
        .finally(() => setIsLoading(false))
    }
  }, [invitationId])

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!agreeToTnC) {
      setShowAcceptTncError(true);
    }

    setRegisterType(AuthType.Standard);

    if (password === repeatPassword) {
      setIsLoading(true);
      try {
        dispatch(loginWithInvite({email, password, inviteId: invitationId, allowMarketingEmails: informMe}));
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('invite.passwords-mismatch');
    }
  }

  const loginWithMicrosoft = async () => {
    if (inviteRequestFailed) {
      return;
    }
    if (agreeToTnC) {
      setShowAcceptTncError(false);
      setIsLoading(true);
      setRegisterType(AuthType.Microsoft);
      try {
        const response = await instance.acquireTokenPopup({
          ...microsoftRequest.login,
          redirectUri: `${window.location.origin}/blank.html`,
          prompt: 'select_account',
        });

        const emailLowerCase = email.toLowerCase();
        const accountUsername = (response.account?.username ?? '').toLowerCase()
        const emailFromTokenClaim = ((response.idTokenClaims as any)?.email ?? '').toLowerCase()
        const accountUsernameWithoutOm = accountUsername.replaceAll('onmicrosoft.', '')
        const emailFromTokenClaimWithoutOm = emailFromTokenClaim.replaceAll('onmicrosoft.', '')
        if (emailLowerCase === accountUsername ||
          emailLowerCase === emailFromTokenClaim ||
          emailLowerCase === accountUsernameWithoutOm ||
          emailLowerCase === emailFromTokenClaimWithoutOm ||
          emailLowerCase === response.uniqueId.toLowerCase()) {
          // ok
          localStorage.setItem('type', AuthType.Microsoft);
          localStorage.setItem('accessToken', response.accessToken);
          localStorage.setItem('idToken', response.idToken);
          localStorage.setItem('tokenExpires', (response.expiresOn ?? new Date()).toUTCString());

          await new Promise((resolve: any) => setTimeout(() => resolve(), 250));
          await Auth.azureAcceptInvite();
          await new Promise((resolve: any) => setTimeout(() => resolve(), 250));
          window.location.href = '/whereabouts/team';

        } else {
          // trying to use an account with the wrong email address
          alert(`That invite is for "${email}", you are signing in with "${(response.account?.username ?? 'n/a')}", "${(response.idTokenClaims as any)?.email}" "${response.uniqueId}". Request an invite be sent to "${response.uniqueId}". If you think there has been a mistake please contact support@team-today.com.`);
        }

      } catch (err: any) {
        setErrorMessage(err.errorCode)
        TrackJS.track(err)
      } finally {
        setIsLoading(false);
      }
    } else {
      setShowAcceptTncError(true);
    }
  }

  const retryMicrosoftLogin = async () => {
    setErrorMessage('');
    loginWithMicrosoft();
  }

  const loginSuccessfulWithGoogle = (response: any) => {
    if (inviteRequestFailed) {
      return;
    }
    localStorage.setItem('type', AuthType.Google)
    localStorage.setItem('idToken', response.tokenId)
    localStorage.setItem('accessToken', response.accessToken)
    setIsLoading(true);
    setRegisterType(AuthType.Google);
    Auth.googleAcceptInvite()
      .then(() => new Promise((resolve: any) => setTimeout(() => resolve(), 350)))
      .then(() => Auth.current(true))
      .then(() => new Promise((resolve: any) => setTimeout(() => resolve(), 150)))
      .then(() => window.location.href = '/whereabouts/team')
      .catch((err) => {
        console.error(err);
        setErrorMessage(err?.message ?? 'invite.failed')
      })
      .finally(() => setIsLoading(false));
  }

  const loginFailedWithGoogle = (error: any) => {
    console.error(error)
  }

  const onLoginWithGoogleClick = (onGoogleClick: any) => {
    if (inviteRequestFailed) {
      return;
    }
    if (agreeToTnC) {
      setShowAcceptTncError(false);
      onGoogleClick();
    } else {
      setShowAcceptTncError(true);
    }
  }

  if (errorMessage && registerType === AuthType.Microsoft) {
    return (<AuthWrapper dataTest="login-invite" title="">
      <AzureErrorHandlingMessages errorCode={errorMessage}
                                  goBackLink={`/invite?id=${invitationId}`}
                                  title={"invite.register-failed"}
                                  onRetryClicked={retryMicrosoftLogin} />
    </AuthWrapper>)
  }

  return (
    <AuthWrapper dataTest="login-invite" title="">
      <React.Fragment>
        <h1 className="headline__large auth__title">{t('invite.title')}</h1>
        <Accordian isOpen={showAcceptTncError}>
          {showAcceptTncError && <BodyError>{t('invite.tandc')}</BodyError>}
        </Accordian>
        <Accordian isOpen={!!errorMessage}>
          {!!errorMessage && <InviteErrorMessage errorMessage={errorMessage} />}
        </Accordian>
        {isLoading && <LoadingSpinner fullScreen={true} message="" />}

        {!inviteRequestFailed && <>
          <BodyRegular className="login__message"><Trans i18nKey={'register.best-experience-signup'} /></BodyRegular>

          <ThirdyPartyLoginButtons>
            <OutlineButton data-test="loginWithMicrosoftBtn"
                           click={loginWithMicrosoft}
                           text="login.with-office365"
                           borderColour={Colours.black}
                           fullWidth={true}
                           size="large"
                           imageUrl="/assets/icons/microsoft-office-logo.svg" />


            <GoogleLogin
              clientId={googleClientId}
              render={(renderProps) => (
                <OutlineButton dataTest="googleLogin"
                               className="login__button"
                               text="login.with-google"
                               borderColour={Colours.black}
                               click={() => onLoginWithGoogleClick(renderProps.onClick)}
                               fullWidth={true} size="large"
                               imageUrl="/assets/icons/google-logo.svg"/>)}
              buttonText="login.login"
              onFailure={loginFailedWithGoogle}
              onSuccess={loginSuccessfulWithGoogle}
              scope="https://www.googleapis.com/auth/calendar.events"
              cookiePolicy={'single_host_origin'}
              disabled={true}
              // isSignedIn={true}
            />
          </ThirdyPartyLoginButtons>

          <Accordian isOpen={showPasswordField}>
            <DividingLineContainer>
              <Divider color={Colours.mildGrey} width={250}/>
            </DividingLineContainer>
            <BodyRegular>{t('login.create-account-with-own-password')}</BodyRegular>
            <form className="auth__form" onSubmit={(e) => e.preventDefault()} style={{marginBottom: 32}}>
              <div className="inviteLogin__fields">
                <TextField dataTest="passwordInput" label="login.password" onChange={(v: string) => setPassword(v)} value={password}
                           type="password"/>
                <TextField dataTest="repeatPasswordInput" label="login.repeat-password"
                           onChange={(v: string) => setRepeatPassword(v)} value={repeatPassword} type="password"/>
              </div>
              <PrimaryButton dataTest="submit"
                             className="login__button"
                             click={handleSubmit}
                             buttonType="submit"
                             text="login.login"
                             size="large"
                             fullWidth={true} />
            </form>
          </Accordian>

          <CheckboxContainer>
            <Checkbox dataTest="termsAndConditionsCheckbox" value={agreeToTnC} onChange={(e: boolean) => setAgreeToTnC(e)} color={showAcceptTncError ? Colours.red : Colours.darkGrey}>
              <BodySmall className="login__checkbox">{t('login.terms-link.1')} <a
                href="https://team-today.com/legal/terms-and-conditions" rel="noreferrer" target={"_blank"}>{t('login.terms-link.2')}</a> {t('login.terms-link.3')} <a
                href="https://team-today.com/legal/privacy-policy" rel="noreferrer" target={"_blank"}>{t('login.terms-link.4')}</a>*</BodySmall>
            </Checkbox>
            <Checkbox value={informMe} onChange={(e: boolean) => setInformMe(e)}
                      text="login.marketing-emails"/>
          </CheckboxContainer>


          <CreateAccountWithPasswordButton>
            <BodyVerySmall style={{textAlign: 'center'}} onClick={() => setShowPasswordField(true)}>
              <Trans i18nKey={'invite.create-account-with-password'}
                     components={[<span>content</span>]}/>
            </BodyVerySmall>
          </CreateAccountWithPasswordButton>
        </>}
      </React.Fragment>
    </AuthWrapper>
  )
}

interface Props {
  invitationId: string;
}

const DividingLineContainer = styled.div`
  margin: 0 0 32px 0;
  display: flex;
  justify-content: center;
`

const ThirdyPartyLoginButtons = styled.div`
  margin: 0 0 32px 0;
  & > button {
    margin-bottom: 16px;
  }
`

const CheckboxContainer = styled.div<any>`
  margin: 0 0 48px 0;
`

const CreateAccountWithPasswordButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 -40px 0;
  cursor: pointer;
  span {
    font-weight: 600;
    color: ${Colours.blue};
  }
`
