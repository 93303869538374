import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { useDispatch, useSelector } from "react-redux";
import { CarParkPlanBooking, CarParkSpace } from "../../../../../services/CarParkingPlanService";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { Period } from "../../../../../models/movements.models";
import { Row } from "../../../../UI/atoms/StructuralLayout";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { isCompanyAdminUser, isOfficeAdminUser } from "../../../../../utils/AccessControl";
import { bookCarParkSpace, cancelCarParkBooking, selectIsLoading } from "../../ducks/carParkingMapView.duck";
import { selectActiveDay } from "../../../../../store/ducks/editMovements.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { IsSuperAdmin } from "../../../../../hooks/IsSuperAdmin";
import { setSelectedCarParkSpace } from "../../../../../store/ducks/officeView.duck";


interface Props {
  carParkingSpace?: CarParkSpace;
  carParkBookings: CarParkPlanBooking[];
}

export function SelectedCarParkingSpaceOverlay(props: Props) {
  const { carParkingSpace, carParkBookings } = props;
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser);
  const activeDate = useSelector(selectActiveDay);
  const isLoading = useSelector(selectIsLoading);
  const isSuperAdmin = IsSuperAdmin();
  const dispatch = useDispatch();

  const booking = useMemo(() => {
    return carParkBookings.find(b => b.spaceId === carParkingSpace?.id);
  }, [carParkBookings, carParkingSpace?.id])
  const canControlBooking = !!booking && !!currentUser && (isAdmin || booking.userId === currentUser?.id);
  const isBooked = !!booking;

  const close = () => {
    dispatch(setSelectedCarParkSpace(undefined));
  }

  const onEditClick = () => {
    if (carParkingSpace) {
      dispatch(openDialogWithPayload({
        payload: { parkingSpace: carParkingSpace },
        activeDialog: DialogIdentifiers.EditParkingSpaceDialog,
      }));
      close();
    }
  }

  const onCopySpaceClicked = () => {
    dispatch(openDialogWithPayload({
      payload: { parkingSpace: {...carParkingSpace, id: 0, label: 'New space'} },
      activeDialog: DialogIdentifiers.EditParkingSpaceDialog,
    }));
    close();
  }

  const onBookSpace = (period: Period) => {
    if (!currentUser || !activeDate || !carParkingSpace) {
      return;
    }
    const params = {
      period: period,
      userId: currentUser.id,
      dateString: activeDate.format(DATE_FORMAT),
      carParkPlanId: carParkingSpace.carParkPlanId,
      carParkingSpaceId: carParkingSpace.id,
    }
    dispatch(bookCarParkSpace(params));
  }

  const onCancelBookingClicked = () => {
    if (!currentUser || !activeDate || !carParkingSpace || !booking) {
      return;
    }
    const params = {
      userId: booking.userId,
      dateString: activeDate.format(DATE_FORMAT),
      carParkPlanId: carParkingSpace.carParkPlanId,
      carParkingSpaceId: carParkingSpace.id,
    }
    dispatch(cancelCarParkBooking(params));
  }

  if (!carParkingSpace) {
    return null;
  }

  return (
    <Popup style={{ left: carParkingSpace.x + 50, top: carParkingSpace.y - 0 }} onClick={(e: any) => e.stopPropagation()}>

      <ControlHeader>
        {isSuperAdmin && <IconButton icon={IconTypes.Copy} onClick={onCopySpaceClicked} />}
        {isAdmin && <IconButton icon={IconTypes.Edit} onClick={onEditClick} />}
      </ControlHeader>
      <PopupContent>

        {isBooked && booking && <BodyRegular weight={600}>{`${booking.firstName} ${booking.lastName}`}</BodyRegular>}

        <BodyRegular weight={600}>{carParkingSpace.label}</BodyRegular>

        {isBooked && canControlBooking && <OutlineButton click={onCancelBookingClicked}
                                                         text={'car-parking.cancel-booking'}
                                                         disabled={isLoading}
                                                         style={{marginTop: 16, marginBottom: 0}}
                                                         fullWidth={true} />}

        <PrimaryButton click={(e: any) => onBookSpace(Period.AllDay)}
                       disabled={!!isBooked || isLoading}
                       size={'medium'}
                       style={{marginTop: 16, marginBottom: 16}}
                       fullWidth={true}
                       text="button.book-all-day" />

        <Row style={{marginBottom: 16}}>
          <OutlineButton disabled={!!isBooked || isLoading}
                         text="button.book-am"
                         style={{marginRight: 8}}
                         size={'small'}
                         click={(e: Event) => onBookSpace(Period.AM)}  />
          <OutlineButton disabled={!!isBooked || isLoading}
                         text="button.book-pm"
                         style={{marginLeft: 8}}
                         size={'small'}
                         click={(e: Event) => onBookSpace(Period.PM)} />
        </Row>
      </PopupContent>
    </Popup>
  )
}

const ControlHeader = styled.div<any>`
  background-color: ${Colours.veryLightGrey};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 8px 24px;
`

const Popup = styled.div<any>`
  width: 260px;
  min-height: 120px;
  background-color: white;
  border: 1px solid ${Colours.veryLightGrey};
  box-shadow: 0 22px 24px 2px rgba(0, 87, 255, 0.15);
  border-radius: 16px;
  position: absolute;
  z-index: 150;
  overflow: hidden;
`

const PopupContent = styled.div<any>`
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
