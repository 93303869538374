import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { createDesk, deleteDeskRequest, Desk, getDesksInOffice, updateDesk } from "../../services/DeskBookingService";
import { failureNotification, successNotification } from "./notification.duck";
import { closeDialog } from "./dialog.duck";

export interface EditableDesk extends Desk {
  dirty?: boolean;
}

export interface EditDeskState {
  desks: EditableDesk[];
  isLoading: boolean;
}

export const initialEditDesk: EditDeskState = {
  desks: [],
  isLoading: false,
}

export const fetchDesks: any = createAsyncThunk(
  'editDesk/fetchDesks',
  async (officeId: number, thunkAPI) => {
    return await getDesksInOffice(officeId);
  }
)

export const editDesk: any = createAsyncThunk(
  'editDesk/editDesk',
  async (params: {original: EditableDesk, updated: EditableDesk}, thunkAPI) => {
    const desks = (thunkAPI.getState() as AppState).editDesk.desks;
    const {original, updated} = params;

    const idx = desks.indexOf(original);
    if (idx > -1) {
      const updatedDesks = [...desks];
      updatedDesks.splice(idx, 1, {...updated, dirty: true});
      return updatedDesks;
    }
    return desks
  }
)

export const addDesk: any = createAsyncThunk(
  'editDesk/addDesk',
  async (desk: EditableDesk, thunkAPI) => {
    const desks = (thunkAPI.getState() as AppState).editDesk.desks;
    return [...desks, desk];
  }
)

export const deleteDesk: any = createAsyncThunk(
  'editDesk/deleteDesk',
  async (deskId: number, thunkAPI) => {

    try {
      await deleteDeskRequest(deskId);
      thunkAPI.dispatch(closeDialog());
      thunkAPI.dispatch(successNotification('Deletion request submitted'));
      return (thunkAPI.getState() as AppState).editDesk.desks.filter(d => d.id !== deskId)
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to delete'));
    }
  }
)

export const saveEditDeskChanges: any = createAsyncThunk(
  'editDesk/saveChanges',
  async (officeId: number, thunkAPI) => {
    const desks = (thunkAPI.getState() as AppState).editDesk.desks;
    try {
      for (const desk of desks) {
        if (desk.dirty) {
          if (desk.id === 0) await createDesk(desk);
          else await updateDesk(desk);
        }
      }
      thunkAPI.dispatch(successNotification('Successfully updated all areas'));
      thunkAPI.dispatch(fetchDesks(officeId));
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Failed to save one or more desks.'));
      throw e;
    }
  }
)

const editDeskSlice = createSlice({
  name: 'editDesk',
  initialState: initialEditDesk,
  reducers: {
    resetEditDesk: () => ({...initialEditDesk}),
  },
  extraReducers: {
    [fetchDesks.pending]: (state) => ({...state, desks: [], isLoading: true}),
    [fetchDesks.rejected]: (state) => ({...state, isLoading: false}),
    [fetchDesks.fulfilled]: (state, action) => ({
      ...state,
      desks: action.payload,
      isLoading: false,
    }),

    [saveEditDeskChanges.pending]: (state) => ({...state, isLoading: true}),
    [saveEditDeskChanges.rejected]: (state) => ({...state, isLoading: false}),
    [saveEditDeskChanges.fulfilled]: (state) => ({ ...state, isLoading: false }),

    [deleteDesk.pending]: (state) => ({...state, isLoading: true}),
    [deleteDesk.rejected]: (state) => ({...state, isLoading: false}),
    [deleteDesk.fulfilled]: (state, action) => ({ ...state, isLoading: false, desks: action.payload }),

    [editDesk.fulfilled]: (state, action) => ({
      ...state,
      desks: action.payload,
    }),

    [addDesk.fulfilled]: (state, action) => ({
      ...state,
      desks: action.payload,
    }),
  }
})

export default editDeskSlice.reducer;
export const {
  resetEditDesk,
} = editDeskSlice.actions;

// Selectors
export const selectEditingDesks = (state: AppState) => state.editDesk.desks;
export const selectIsEditDesksDirty = (state: AppState) => state.editDesk.desks.filter(d => d.dirty).length > 0;
export const selectIsEditDesksLoading = (state: AppState) => state.editDesk.isLoading;
