import React from 'react';
import styled from "styled-components/macro";

export function FirstAiderBadge(props: Props) {
  return (
    <FirstAiderWrapper data-test="first-aider-badge" style={props.style ?? {}} title="This person is a designated first aider">
      <img src="/assets/icons/first-aider.svg" alt="Fire warden" />
    </FirstAiderWrapper>
  )
}

interface Props {
  style?: any;
}

const FirstAiderWrapper = styled.div`
`
