import React, { useMemo } from 'react';
import { Column } from "../../../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveOffices, selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { selectActiveDay } from "../../../../../store/ducks/editMovements.duck";
import { GenericDropDownList } from "../../../../UI/molecules/GenericDropDownList";
import { clearSelectedArea, selectedRootOffice, selectOffice } from "../../../../../store/ducks/officeView.duck";
import { findOfficeInOffices, getOfficeChildren, } from "../../../../../utils/OfficeHelper";
import isMobile from "is-mobile";
import { WeeklyOfficeUsageChart } from "../../../../UI/organisms/reports/WeeklyOfficeUsageChart";
import { useTranslation } from "react-i18next";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import { FreeBusyStatus } from "../../../../../services/WhereaboutOptions";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";

interface Props {
  hideUsageChart?: boolean;
}

export function OfficeListSidebar(props: Props) {
  const dispatch = useDispatch();
  const offices = useSelector(selectActiveOffices);
  const activeDay = useSelector(selectActiveDay);
  const activeOffice = useSelector(selectedRootOffice);
  const allOffices = useSelector(selectAllOfficeEntities);
  const iconPack = useSelector(selectIconPack);
  const {t} = useTranslation();

  const officesForDropdown = useMemo(() => offices.map((office: OfficeEntity) => ({...office, name: office.label})), [offices]);

  const onSelect = (officeId: any) => {
    const office = findOfficeInOffices(allOffices, parseInt(officeId));
    const officeChildren = getOfficeChildren(parseInt(officeId), allOffices);

    if (officeChildren.length > 0) {
      dispatch(selectOffice(officeChildren[0]));
      dispatch(clearSelectedArea());
    } else if (office) {
      dispatch(selectOffice(office));
      dispatch(clearSelectedArea());
    } else {
      console.warn('No office found for', officeId);
    }
  }

  if (offices.length > 0) {
    return (
      <Wrapper data-test="office-sidebar">
        <BodyRegular weight={600} style={{marginBottom: 8}}>{t('choose-office-to-view')}</BodyRegular>
        <OfficeSelectDropDown selectedId={activeOffice?.id}
                              onSelect={(office: any) => onSelect(office)}
                              items={officesForDropdown}
                              placeholder="textinput.choose-an-office" />
        {(!isMobile() && !props.hideUsageChart) && <>
          <WeeklyOfficeUsageChart office={activeOffice} style={{marginTop: 16}} date={activeDay} />
        </>}
      </Wrapper>
    )
  } else {
    return (
      <Wrapper data-test="office-sidebar">
        <Column>
          <OfficeImage style={{marginBottom: 24}} src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="Ofifce" aria-hidden={false} />
          <HeadlineSmall style={{marginBottom: 8}}>{t('create-an-office')}</HeadlineSmall>
          <BodyRegular style={{marginBottom: 32}}>{t('create-an-office-description')}</BodyRegular>
          <OutlineButton size="large"
                         fullWidth={true}
                         click={() => window.location.href = '/manage/hot-desking'}
                         text="button.create-office" />
        </Column>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-top: 24px;
  button {
    max-width: 100%;
  }
  @media (${tablet}) {
    margin-top: 0;
    max-width: 295px;
  }
`

const OfficeImage = styled.img`
  align-self: center;
  width: 96px;
`

const OfficeSelectDropDown = styled<any>(GenericDropDownList)<any>`
  width: 100%;
  background: white;
`
