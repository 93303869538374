import React, { useEffect } from 'react';
import AuthWrapper from '../auth/components/AuthWrapper';
import { clearLastPath, getParameterByName } from "../../../utils/UrlUtils";
import { AuthType } from "../../../utils/AuthUtils";
import { Auth } from "../../../services/Auth";

export default function LoginWithGoogle() {
  const idToken = getParameterByName('id_token', window.location.href);

  useEffect(() => {
    if (idToken) {
      localStorage.setItem('type', AuthType.Google)
      localStorage.setItem('idToken', idToken)
      Auth.current(true).then(console.log)
      clearLastPath();
    }
  }, [idToken])

  return (
    <AuthWrapper dataTest="login-invite" title="" spinner={false}>
    </AuthWrapper>
  )
}
