import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { selectApprovals, selectNameFilter } from "../../../../../store/ducks/approvalRequests.duck";
import { GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { ApprovalLine } from "./ApprovalLine";
import styled from "styled-components/macro";
import { tablet } from "../../../../UI/atoms/MediaQueries";

export function ApprovalsList(props: Props) {
  const groupedApprovals = useSelector(selectApprovals);
  const nameFilter = useSelector(selectNameFilter);
  const filteredApprovals = useMemo(() => {
    if (!nameFilter || nameFilter.length < 2) return groupedApprovals;
    const upperCaseNameFilter = nameFilter.toUpperCase();
    return groupedApprovals.filter(ga => (ga.firstName + ' ' + ga.lastName).toUpperCase().includes(upperCaseNameFilter));
  }, [groupedApprovals, nameFilter]);

  return (
    <ApprovalsListWrapper>
      {filteredApprovals.map((ga: GroupedApprovalRequest, key: number) => <ApprovalLine groupedApproval={ga}
                                                                                       key={key} />)}
    </ApprovalsListWrapper>
  )
}

interface Props {
}

const ApprovalsListWrapper = styled.div`
  margin: 16px 0 0 0;
  @media (${tablet}) {
    margin: 16px 32px 0 0;
  }
`
