import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { BodyVerySmall } from "../atoms/fonts/Body";
import { tablet } from "../atoms/MediaQueries";
import { StyledLink } from "../atoms/StyledLink";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  content?: string;
  icon?: string;
  style?: any;
  link?: string;
  linkMessage?: string;
  children?: any;
}

export function SuggestionMessage(props: Props) {
  const linkMessage = props.linkMessage ?? 'Show me how';
  const {t} = useTranslation();
  return (
    <Container style={props.style ?? {}}>
      {props.icon && <SuggestionIconImage src={`/assets/icons/font/${props.icon}.svg`} alt={props.icon}/>}
      <BodyVerySmall style={{marginBottom: 2}} weight={600}>{t(props.title)}</BodyVerySmall>
      {props.content && <BodyVerySmall>{t(props.content)}</BodyVerySmall>}
      {props.link &&
        <div style={{marginTop: 16}}>
          <StyledLink link={props.link ?? '#'}><BodyVerySmall weight={600} colour={Colours.blue}>{t(linkMessage)}</BodyVerySmall></StyledLink>
        </div>}
      {props.children && props.children}
    </Container>
  )
}

export const Container = styled.div`
  background-color: ${Colours.veryLightGrey};
  border-radius: 12px;
  padding: 16px;
`

export const SuggestionIconImage = styled.img`
  display: none;
  @media (${tablet}) {
    display: flex;
    margin-bottom: 12px;
  }
`
