import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { findOfficeInOffices, getFullOfficeHierarchy, getOfficeChildren } from "../utils/OfficeHelper";

export interface OfficeInformation {
  office?: OfficeEntity;
  parentOffice?: OfficeEntity;
  childOffices?: OfficeEntity[];
  officeNamePath?: string[];
}

export function WithOfficeInformation(officeId?: number): OfficeInformation {
  const offices = useSelector(selectAllOfficeEntities);
  return useMemo(() => {
    if (officeId) {
      const office = findOfficeInOffices(offices, officeId);
      if (office) {
        return {
          office: office,
          parentOffice: findOfficeInOffices(offices, office.parentId),
          childOffices: getOfficeChildren(officeId, offices),
          officeNamePath: getFullOfficeHierarchy(offices, [], office).map(o => o.label),
        }
      }
    }
    return {};
  }, [officeId, offices]);
}
