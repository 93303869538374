import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import DialogHeader from "../../../../../dialogs/DialogHeader";
import Dialog from "../../../../../UI/molecules/Dialog";
import { closeDialog } from "../../../../../../store/ducks/dialog.duck";
import TeamMultiSelect from "../../../../../UI/organisms/team-drop-down-list/TeamMultiSelect";
import { TeamEntity } from "../../../../../../models/team.models";
import { selectAllTeams } from "../../../../../../store/ducks/config.duck";
import { assignUsersToTeam, selectAllCheckedUsers, selectIsManageUsersLoading } from "../ducks/manage-users.duck";

export default function AssignToTeamDialog(props: Props) {
  const dispatch = useDispatch();
  const teams = useSelector(selectAllTeams);
  const [selection, setSelection] = useState<TeamEntity | undefined>();
  const selectedUsers = useSelector(selectAllCheckedUsers);
  const isLoading = useSelector(selectIsManageUsersLoading);

  const close = () => {
    dispatch(closeDialog());
  }

  const done = () => {
    if (selection && selectedUsers.length > 0) {
      dispatch(assignUsersToTeam(selection.id));
      close();
    }
  }

  const onTeamSelect = (team: TeamEntity[]) => {
    if (team.length > 0) {
      setSelection(team[0]);
    }
  }

  return (
    <DialogWrapper isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="manage-users-page.assign-to-team" />
      <TeamMultiSelect disabled={isLoading}
                       onSelect={onTeamSelect}
                       selectedTeams={selection ? [selection] : []}
                       singleSelect={true}
                       teams={teams} />
      <PrimaryButton disabled={isLoading}
                     fullWidth={true}
                     style={{marginBottom: 16, marginTop: 'auto'}}
                     className={'saveChangesButton'}
                     click={done}
                     text="button.save-changes" />
    </DialogWrapper>
  )
}

interface Props {
  payload: any;
}

export const DialogWrapper = styled<any>(Dialog)<any>`
  &.dialog {
    max-width: 100%;
    min-height: 400px;
  }
  .dialog__contentContainer, .dialog__content {
    width: 300px;
    min-height: 410px;
  }
  .dialog__content {
    display: flex;
    flex-direction: column;
  }
  .saveChangesButton {
    margin-top: auto;
  }
`

