import React from 'react';
import { BodyRegular } from "../../../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import Colours from "../../../../../UI/atoms/Colours";
import { useDispatch, useSelector } from "react-redux";
import { addFloorToOffice, selectFloors } from "../../../../../../store/ducks/editOfficeLayout.duck";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import OfficeFloorControl from "./OfficeFloorControl";
import { FlatContentCard } from "../../../../../UI/atoms/FlatContentCard";
import { useTranslation } from "react-i18next";

interface Props {
  isEditable: boolean;
  canCreateOffices: boolean;
  office: OfficeEntity;
}

export default function OfficeFloorsListControl(props: Props) {
  const {isEditable, office, canCreateOffices} = props;
  const dispatch = useDispatch();
  const floors = useSelector(selectFloors);
  const {t} = useTranslation();

  const onAddNewFloorClicked = () => {
    if (!isEditable) return;
    dispatch(addFloorToOffice(office.id));
  }

  return <OfficeFloorControlContainer>
    {(floors ?? []).length > 0 ?
      <>
        {floors.map((floor: OfficeEntity, key: any) => <OfficeFloorControl key={key} isEditable={isEditable} floor={floor} />)}
      </>
      :
      <>
      {canCreateOffices && <>
        <BodyRegular style={{marginBottom: 8, textAlign: 'center'}} weight={600}>{t('settings.manage-office.title')}</BodyRegular>
        <BodyRegular style={{marginBottom: 12, textAlign: 'center'}}>{t('settings.manage-office.message')}</BodyRegular>
      </>}
      </>
    }

    {canCreateOffices ? <NewFloorButton onClick={onAddNewFloorClicked} data-test="add-floor-button">
      <img src="/assets/icons/add-button.svg" alt="Add floor" aria-hidden={true}/>
      <BodyRegular weight={600} colour={Colours.blue}>{t('settings.manage-office.another-floor')}</BodyRegular>
    </NewFloorButton> : <>
      <FlatContentCard>
        <BodyRegular weight={600}>{t('settings.manage-office.additional-access-required')}</BodyRegular>
        <BodyRegular>{t('settings.manage-office.company-admin-only')}</BodyRegular>
      </FlatContentCard>
    </>}
  </OfficeFloorControlContainer>;
}

export const OfficeFloorControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colours.mildGrey};
  border-bottom: 1px solid ${Colours.mildGrey};
  padding: 16px 8px;
  .floor__name__textBox {
    flex: 1;
  }
  .floor__delete__icon i {
    margin: 10px 16px 0 16px;
    width: 22px;
    height: 22px;
  }
  .floor__capacity__textBox {
    text-align: left;
    input {
      width: 100px;
    }
  }
`;

export const FloorContainer = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  margin-bottom: 16px;
  &:last-child {
    border-bottom: none;
  }
`

export const NewFloorButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 16px;
    height: 32px;
    width: 32px;
  }
`
