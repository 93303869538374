import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { GoBackButton } from "../../../../../UI/atoms/buttons/GoBackButton";
import { HeadlineSmall } from "../../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { getFullOfficeTitle } from "../../../../../../utils/OfficeHelper";

export function VisitorBookingHeader(props: Props) {
  const {t} = useTranslation();
  const {office} = props;
  const allOffices = useSelector(selectAllOfficeEntities);

  const officeLabel = useMemo(() => {
    return getFullOfficeTitle(office, allOffices, ', ');
  }, [allOffices, office]);

  return (
    <Container>
      <GoBackButton />
      <HeadlineSmall>{t('visitor-booking.visitor-office-title', {officeName: officeLabel})}</HeadlineSmall>
    </Container>
  )
}

interface Props {
  office: OfficeEntity;
}

const Container = styled.div`
  display: flex;
  padding: 16px 0;
  & > h3 {
    margin: 0 auto;
  }
`
