import React from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { WhereaboutsBookingErrorType } from "../../../services/DeskBookingService";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import Dialog from "../../UI/molecules/Dialog";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { BodyLarge } from "../../UI/atoms/fonts/Body";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import styled from "styled-components/macro";

export function OutOfBookingRangeError(props: Props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const error = props.errors.find((e: any) => e.type === WhereaboutsBookingErrorType.OUT_OF_BOOKING_RANGE);

  const close = () => {
    dispatch(closeDialog());
  };

  // const weekEnding = useMemo(() => {
  //   if (!error) return moment();
  //   return moment(error.endOfCurrentBookingWindow)
  //     .add(4)
  //     .format('Do MMMM yyyy');
  // }, [error]);

  if (!error) {
    return null;
  }

  return (
    <WeeklyNotesDialogWrapper>
      <Dialog isOpen={true} onClose={close}>
        <ErrorImage src={"/assets/images/error-booking-desk.svg"} alt={""} />
        <HeadlineMedium style={{marginBottom: 16, marginTop: 16}}>{t('dialog.saved-whereabouts-errors-title')}</HeadlineMedium>
        <BodyLarge style={{marginBottom: 48}}>{error?.error ?? t('saved-whereabouts-errors-content-2-fallback')}</BodyLarge>
        <PrimaryButton click={close} fullWidth={true} size={'large'} text={"button.done"} />
      </Dialog>
    </WeeklyNotesDialogWrapper>
  )
}

interface Props {
  errors: any;
}

const ErrorImage = styled.img`
  margin: 0 auto;
  width: 96px;
`

const WeeklyNotesDialogWrapper = styled.div`
  .dialog {
  }
  .dialog__content {
    width: 100%;
    margin: unset;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`
