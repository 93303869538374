import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canUserCreateOffices, canUserEditOfficesInManagement } from "../../../../../utils/AccessControl";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { BodyError, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import DialogHeader from "../../../../dialogs/DialogHeader";
import AccessRequired from "../../components/AccessRequired";
import TextField from "../../../../UI/atoms/TextField";
import OfficeFloorsListControl from "./components/OfficeFloorsListControl";
import {
  createOrUpdateOfficeLayout,
  resetEditOfficeLayout,
  selectEditingOffice,
  selectEditOfficeError,
  selectFloors,
  selectIsLoading,
  setEditingOffice
} from "../../../../../store/ducks/editOfficeLayout.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { useParams } from "react-router";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { selectAllOfficeEntities, selectOffices } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import styled from "styled-components/macro";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { GeneralErrorMessage } from "../../../../UI/molecules/GeneralErrorMessage";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { Switch } from "../../../../UI/atoms/Switch";
import { Accordian } from "../../../../../animations/AnimationVariants";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";
import { Trans, useTranslation } from "react-i18next";
import Colours from "../../../../UI/atoms/Colours";
import { OfficeCarParkingSetting } from "./components/OfficeCarParkingSetting";
import { HorizontalSpacer } from "../../../../UI/atoms/VerticalSpacer";
import { getOfficeChildren } from "../../../../../utils/OfficeHelper";
import { DeskBookingWindowSetting } from "./DeskBookingWindowSetting";

export default function ManageOfficesPage() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const editingOffice = useSelector(selectEditingOffice);
  const currentUser = useSelector(selectCurrentUser);
  const loading = useSelector(selectIsLoading);
  const floors = useSelector(selectFloors);
  const offices = useSelector(selectOffices);
  const allOfficeEntities = useSelector(selectAllOfficeEntities);
  const editOfficeError = useSelector(selectEditOfficeError);
  const {t} = useTranslation();

  const [error, setError] = useState('');
  const [officeEntityId, setOfficeEntityId] = useState(0);
  const [officeName, setOfficeName] = useState('');
  const [officePostcode, setOfficePostcode] = useState('');
  const [capacity, setCapacity] = useState<string | null>(null);
  const [capacityEnabled, setCapacityEnabled] = useState(false);
  const [carParkingCapacity, setCarParkingCapacity] = useState(0);
  const [officeBookingWindow, setOfficeBookingWindow] = useState(0);
  const [restrictBookingWindowForTeamAdmins, setRestrictBookingWindowForTeamAdmins] = useState(false);

  const showOfficeCapacity = useMemo(() => floors && floors.length < 1, [floors]);
  const isEditable = useMemo(() => !!officeEntityId ? canUserEditOfficesInManagement(currentUser) : canUserCreateOffices(currentUser), [currentUser, officeEntityId]);

  useEffect(() => {
    if (params.officeId && !editingOffice) {
      const officeId = parseInt(params.officeId)
      const office = offices.find((office: OfficeEntity) => office.id === officeId);
      if (office) {
        const officeChildren = getOfficeChildren(office.id, allOfficeEntities);
        dispatch(setEditingOffice({office: office, children: officeChildren}))
      }
    }
  }, [dispatch, editingOffice, params, offices, allOfficeEntities]);

  useEffect(() => {
    return () => {
      dispatch(resetEditOfficeLayout());
    }
  }, [dispatch]);

  const submit = async () => {
    if (!isEditable) {
      return;
    }
    if (showOfficeCapacity && capacityEnabled) {
      if (!capacity || parseInt(capacity) < 1) {
        setError('Capacity must be greater than 1.');
      }
      if (!capacity || parseInt(capacity) > 1000) {
        setError('Capacity must be less than 1000.');
      }
    }

    try {
      dispatch(createOrUpdateOfficeLayout({
        ...(editingOffice || {}),
        id: officeEntityId,
        label: officeName,
        postCode: officePostcode,
        capacity: !!capacity ? parseInt(capacity) : null,
        capacityEnabled: capacityEnabled,
        carParkCapacity: carParkingCapacity,
        officeBookingWindowWeeks: officeBookingWindow,
        restrictBookingWindowForTeamAdmins: restrictBookingWindowForTeamAdmins,
      }))
    } catch (err: any) {
      setError(err.message);
    }
  }

  const clear = () => {
    setOfficeEntityId(0);
    setOfficeName('');
    setOfficePostcode('');
    setCapacity('0');
    setCapacityEnabled(false);
    setCarParkingCapacity(0);
    setOfficeBookingWindow(0);
    setRestrictBookingWindowForTeamAdmins(false);
  }

  const onDeleteOfficeClick = () => {
    dispatch(openDialogWithPayload({
      activeDialog: DialogIdentifiers.DeleteOfficeEntityDialog,
      payload: { officeId: officeEntityId },
    }));
  }

  useEffect(() => {
    clear();
  }, []);

  useEffect(() => {
    if (editingOffice) {
      setOfficeEntityId(editingOffice.id);
      setOfficeName(editingOffice.label);
      setOfficePostcode(editingOffice.postCode);
      setCapacity((editingOffice?.capacity ?? 0).toString());
      setCapacityEnabled(!!editingOffice?.capacityEnabled);
      setCarParkingCapacity(editingOffice?.carParkCapacity ?? 0);
      setOfficeBookingWindow(editingOffice?.officeBookingWindowWeeks ?? 0);
      setRestrictBookingWindowForTeamAdmins(!!editingOffice.restrictBookingWindowForTeamAdmins);
    } else {
      clear();
    }
  }, [editingOffice]);

  return (
    <>
      {loading && <LoadingSpinner fullScreen={true} />}
      <GoBackButton url="/manage/hot-desking" />
      <DialogHeader title={officeEntityId ? 'settings.edit-office' : 'settings.create-new-office'} />

      <GeneralErrorMessage style={{paddingBottom: 24}} error={editOfficeError} />

      {error && <BodyError style={{marginBottom: 24}}>{error}</BodyError>}

      <AccessRequired visible={!isEditable} companyAdminsRequired={true} teamAdminsRequired={false} />

      <InputForm onSubmit={(e) => e.preventDefault()}>
        <TextField disabled={!isEditable}
                   onChange={setOfficeName}
                   label="textinput.office-name"
                   value={officeName}
                   dataTest="office-name-input"
                   fullWidth={true}
        />

        <TextField disabled={!isEditable}
                   onChange={setOfficePostcode}
                   label="textinput.office-postcode"
                   value={officePostcode}
                   dataTest="office-postcode-input"
                   fullWidth={true}
        />

        <OfficeCarParkingSetting carParkingCapacity={carParkingCapacity}
                                 isEditable={isEditable}
                                 onCarParkCapacityChange={setCarParkingCapacity} />

        <HorizontalSpacer />

        {editingOffice && <>
          <DeskBookingWindowSetting office={editingOffice} disabled={!isEditable}
                                    onOfficeBookingWindowChanged={setOfficeBookingWindow}
                                    onRestrictBookingWindowForTeamAdminsChange={setRestrictBookingWindowForTeamAdmins}/>
        </>}

        <Switch value={capacityEnabled}
                weight={600}
                label={"textinput.enable-desk-booking"}
                style={{marginBottom: 12}}
                onChange={(val) => setCapacityEnabled(val)} />

        <Accordian isOpen={capacityEnabled}>
          {showOfficeCapacity && <TextField disabled={!isEditable}
                                            onChange={setCapacity}
                                            label="textinput.desks-available"
                                            type="number"
                                            dataTest="office-capacity-input"
                                            value={capacity || "0"}
                                            fullWidth={true}
          />}



          {editingOffice && <OfficeFloorsListControl office={editingOffice}
                                                     canCreateOffices={canUserCreateOffices(currentUser)}
                                                     isEditable={canUserEditOfficesInManagement(currentUser)} />}
        </Accordian>

        <Accordian isOpen={!capacityEnabled}>
          <FlatContentCard>
            <BodyRegular weight={400} colour={Colours.blue}>{t('settings-page.how-desk-capacities-work-title')}</BodyRegular>
            <BodyRegular><Trans i18nKey={'settings-page.how-desk-capacities-work'} components={[<a href={'mailto:hello@team-today.com'}>hello@team-today.com</a>]} /></BodyRegular>
          </FlatContentCard>
        </Accordian>

        <PrimaryButton disabled={!isEditable}
                       buttonType="submit"
                       style={{marginTop: 24}}
                       click={submit}
                       fullWidth={true} size="large"
                       dataTest="create-office-button"
                       text={!!officeEntityId ? 'button.save-changes' : 'button.create-office'} />
      </InputForm>

      {!!officeEntityId && canUserCreateOffices(currentUser) && <OutlineButton text="button.delete-office"
                                                                               fullWidth={true}
                                                                               click={onDeleteOfficeClick} />}

    </>
  )
}


const InputForm = styled.form`
  margin-bottom: 24px;
`

