import React from 'react';
import styled from "styled-components";
import { VerticallyCenteredRow } from "./StructuralLayout";
import Colours from "./Colours";
import { BodyRegular } from './fonts/Body';
import Icon, { IconTypes } from "./icon/Icon";
import { useTranslation } from "react-i18next";

export function AddToTeamsButton(props: Props) {
  const {t} = useTranslation();
  return (
    <a className="noDecoration" href="https://teams.microsoft.com/l/app/d1d8d8c3-5199-45c2-afcc-aaab4dd8da5d" target="_blank" rel="noopener noreferrer">
      <AddContainer style={props.style ?? {}}>
        <VerticallyCenteredRow>
          <TeamsImage src="/assets/images/ms-teams-logo.png" alt="" />
          <BodyRegular colour={Colours.blue}>{t('onboarding.add-to-ms-teams')}</BodyRegular>
          <Icon style={{marginLeft: 'auto'}} icon={IconTypes.Next} size="medium" color="blueOutline" />
        </VerticallyCenteredRow>
      </AddContainer>
    </a>
  )
}

interface Props {
  style?: any;
}

const AddContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 17px 2px rgba(0, 87, 255, 0.15);
  border-radius: 8px;
  border: 1px solid ${Colours.blue};
  padding: 20px 16px;
`

const TeamsImage = styled.img`
  width: 32px;
  margin-right: 24px;
`
