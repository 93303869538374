import React, { useEffect, useMemo, useState } from 'react';
import { WallPlannerTeamRow } from "./WallPlannerTeamRow";
import { WallPlannerUserRow } from "./WallPlannerUserRow";
import styled from "styled-components/macro";
import { Moment } from "moment";
import { Department } from "../../departments/data/departments.models";
import {
  DepartmentsWhereabouts,
  getDepartmentsWhereabouts,
  parseUsersGroupedWhereabouts,
  UsersGroupedWhereaboutsAsWorkBlocks
} from "../../departments/data/departmentsService";
import { useSelector } from "react-redux";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { WallPlannerUserRowV2 } from "./WallPlannerUserRowV2";
import { selectAllWhereaboutsOptions } from "../../../../store/ducks/whereaboutsOptions.duck";

interface Props {
  department: Department;
  isFirst: boolean;
  weeksInMonth: Moment[];
}

export function WallPlannerDepartmentPlan(props: Props) {
  const {weeksInMonth, isFirst, department} = props;
  const [departmentsWhereabouts, setDepartmentsWhereabouts] = useState<DepartmentsWhereabouts>();
  const calendarDate = useSelector(selectCalendarDate);
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const dateString = calendarDate?.format(DATE_FORMAT);

  useEffect(() => {
    getDepartmentsWhereabouts(department.id, dateString).then(setDepartmentsWhereabouts);
  }, [department, dateString]);

  const groupedWhereaboutsWithOptions: UsersGroupedWhereaboutsAsWorkBlocks[] = useMemo(() => {
    if (whereaboutsOptions.length > 0 && departmentsWhereabouts && departmentsWhereabouts?.usersWhereabouts.length > 0) {
      return departmentsWhereabouts.usersWhereabouts.map((groupedWhereabouts => {
        const enrichedWhereabouts = parseUsersGroupedWhereabouts(whereaboutsOptions, groupedWhereabouts.whereabouts);
        return {
          ...groupedWhereabouts,
          whereabouts: enrichedWhereabouts,
        }
      }))
    }
    return [];
  }, [whereaboutsOptions, departmentsWhereabouts]);

  if (!groupedWhereaboutsWithOptions) {
    return null;
  }

  return (
    <TeamContainer columns={weeksInMonth.length + (window.innerWidth < 768 ? 0 : 1)}>
      <WallPlannerTeamRow isFirst={isFirst} teamName={department.name}/>
      {groupedWhereaboutsWithOptions.map((groupedWhereabouts: UsersGroupedWhereaboutsAsWorkBlocks, k: any) => (
        <WallPlannerUserRowV2 key={k} user={groupedWhereabouts.userInfo} whereaboutsDays={groupedWhereabouts.whereabouts} />
      ))}
      <WallPlannerUserRow />
    </TeamContainer>
  )
}

export const TeamContainer = styled.div<any>`
  width: 100%;
  height: 100%;
  &:last-child {
    margin-bottom: 55px;
  }
  .userColumn__wrapper {
    z-index: 999;
  }
`
