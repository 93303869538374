import React, { useState } from 'react';
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { downloadDetailedHolidayReport, HolidayReportLine, toCsvDownload } from "../../../../services/ReportingService";
import { Moment } from "moment";

export function DownloadDetailedHolidayReportButton(props: Props) {
  const {selectedDate, disabled} = props;
  const [loading, setLoading] = useState(false);

  const downloadDetailedReport = async () => {
    try {
      setLoading(true);
      const reportData: HolidayReportLine[] = await downloadDetailedHolidayReport(selectedDate.year());
      toCsvDownload(reportData, `Detailed holiday report`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <OutlineButton size={"medium"}
                   disabled={disabled || loading}
                   text="reports-page.holidays-report.detailed-report"
                   click={downloadDetailedReport} />
  )
}

interface Props {
  selectedDate: Moment;
  disabled: boolean;
}
