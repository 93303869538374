import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { BasicUserInfo, UserInfo } from '../../models/user.models';
import { getUserInfo } from '../../services/ManagementService';
import { TeamInfo } from '../../models/team.models';
import { loadTeams } from '../../services/TeamService';
import { addLineReportRequest, deleteLineReportRequest, requestUsersHierarchy } from '../../services/CompanyHierarchyService';
import { updateUserRequest } from "../../services/UserService";
import { failureNotification } from "./notification.duck";

export interface EditUserState {
  isLoading: boolean;
  user?: UserInfo;
  lineReports: BasicUserInfo[];
  reportingTo: BasicUserInfo[];
  teams: TeamInfo[];
  errored: boolean;
}

export const initialEditUserState: EditUserState = {
  isLoading: false,
  lineReports: [],
  reportingTo: [],
  teams: [],
  errored: true,
}

export const fetchEditUser: any = createAsyncThunk(
  'editUser/setUser',
  async (userId: string, thunkAPI) => {
    const lineManagementEnabled = (thunkAPI.getState() as AppState).auth.currentUser?.companyEntity.lineManagementEnabled ?? false;
    const userInfo = await getUserInfo(userId);
    const teams = await loadTeams();
    const reportingStructure = lineManagementEnabled ? await requestUsersHierarchy(userId) : undefined;
    return {
      userInfo,
      teams,
      lineReports: reportingStructure?.lineReports ?? [],
      reportingManagers: reportingStructure?.reportingManager ?? []
    };
  }
)

export const addLineReport: any = createAsyncThunk(
  'editUser/addLineReport',
  async (params: {subordinateUserId: string, reportingToUserId: string}, thunkAPI) => {
    try {
      const reportingStructure = await addLineReportRequest(params.subordinateUserId, params.reportingToUserId);
      return {
        lineReports: reportingStructure.lineReports,
        reportingManagers: reportingStructure.reportingManager
      };
    } catch (e: any) {
      if (e.message) {
        thunkAPI.dispatch(failureNotification(e.message));
      }
      throw e;
    }
  }
)

export const updateUser: any = createAsyncThunk(
  'editUser/updateUser',
  async (user: UserInfo, thunkAPI) => {
    try {
      const response = await updateUserRequest(user.userId,
        user.firstName,
        user.lastName,
        user.teamId,
        user.fireWarden,
        user.firstAider,
        user.mentalHealthAider,
        user.keyHolder,
        user.holidayAllowance,
        user.enableOutlookSync);
      return response;
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification('Error. User NOT updated.'))
      throw e;
    }
  }
)

export const removeLineReport: any = createAsyncThunk(
  'editUser/removeLineReport',
  async (params: {subordinateUserId: string, reportingToUserId: string}, thunkAPI) => {
    const reportingStructure = await deleteLineReportRequest(params.subordinateUserId, params.reportingToUserId);
    return {
      lineReports: reportingStructure.lineReports,
      reportingManagers: reportingStructure.reportingManager
    };
  }
)

const EditUserSlice = createSlice({
  name: 'editUser',
  initialState: initialEditUserState,
  reducers: {
    resetEditUser: () => ({...initialEditUserState}),
    setEditingUser: (state, action) => ({...state, user: action.payload}),
  },
  extraReducers: {
    [fetchEditUser.pending]: (state) => ({...state, user: undefined, teams: [], errored: false, isLoading: true}),
    [fetchEditUser.rejected]: (state) => ({...state, user: undefined, teams: [], errored: true, isLoading: false}),
    [fetchEditUser.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload.userInfo,
      isLoading: false,
      errored: false,
      teams: action.payload.teams,
      lineReports: action.payload.lineReports,
      reportingManagers: action.payload.reportingManagers,
    }),

    [addLineReport.pending]: (state) => ({...state, isLoading: true}),
    [addLineReport.rejected]: (state) => ({...state, isLoading: false}),
    [addLineReport.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      lineReports: action.payload.lineReports,
      reportingManagers: action.payload.reportingManagers,
    }),

    [removeLineReport.pending]: (state) => ({...state, isLoading: true}),
    [removeLineReport.rejected]: (state) => ({...state, isLoading: false}),
    [removeLineReport.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      lineReports: action.payload.lineReports,
      reportingManagers: action.payload.reportingManagers,
    }),

    [updateUser.pending]: (state) => ({...state, isLoading: true}),
    [updateUser.rejected]: (state) => ({...state, isLoading: false}),
    [updateUser.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      user: action.payload
    }),
  }
});

export default EditUserSlice.reducer;
export const {
  resetEditUser,
  setEditingUser,
} = EditUserSlice.actions;

// Selectors
export const selectEdittingUser = (state: AppState) => state.editUser.user;
export const selectEdittingTeams = (state: AppState) => state.editUser.teams;
export const selectIsEditUserLoading = (state: AppState) => state.editUser.isLoading;
export const selectLineReports = (state: AppState) => state.editUser.lineReports;
export const selectHasEditUserErrored = (state: AppState) => state.editUser.errored;
