import React, { useState } from 'react';
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { CompanyHolidayUsageSummary } from "../../../../models/Holiday.model";
import { fetchAllUsersHolidaysInCompany } from "../../../../services/HolidayService";
import { toCsvDownload } from "../../../../services/ReportingService";
import { Moment } from "moment/moment";

export function DownloadHighLevelHolidayReportButton(props: Props) {
  const {selectedDate, disabled} = props;
  const [loading, setLoading] = useState(false);

  const downloadSummaryReport = async () => {
    if (disabled) return;
    try {
      setLoading(true);
      const summary: CompanyHolidayUsageSummary = await fetchAllUsersHolidaysInCompany(selectedDate.year());
      const data = summary.holidayUsages.map(u => ({
        'FirstName': u.firstName,
        'LastName': u.lastName,
        'Email': u.email,
        'TeamName': u.teamName,
        'DaysUsed': u.daysUsed,
        'TotalAllowance': u.totalAllowance,
      }))
      toCsvDownload(data, `Team Today holiday summary for ${summary.fromDate} to ${summary.toDate}`);
    } finally {
      setLoading(false);
    }
  }

  return (<OutlineButton size={"medium"}
                         disabled={disabled || loading}
                         text="reports-page.holidays-report.high-level-report"
                         click={downloadSummaryReport} />)
}

interface Props {
  selectedDate: Moment;
  disabled: boolean
}
