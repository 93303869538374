import React from 'react';
import styled from "styled-components/macro";
import { BodyRegular } from "../atoms/fonts/Body";
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { useSortBy, useTable } from "react-table";
import { useTranslation } from "react-i18next";

export function SortableTable(props: Props) {
  const {columns, data, initialState} = props;
  const {t} = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: columns,
      data: data,
      // @ts-ignore
      initialState: initialState,
    },
    useSortBy,
  )

  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <HeaderCell>
                  <BodyRegular weight={600}>
                    {t(column.render('Header'))}
                  </BodyRegular>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <Icon icon={IconTypes.Down} />
                      : <Icon icon={IconTypes.Up} />
                    : <Icon icon={IconTypes.Previous} />}
                </HeaderCell>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}><BodyRegular>{cell.render('Cell')}</BodyRegular></td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </Styles>
  )
}

const Styles = styled.div`
`

const HeaderCell = styled.div`
  display: flex;
  i {
    margin-left: 8px;
  }
`

interface Props {
  data: any;
  columns: any;
  initialState?: any;
}
