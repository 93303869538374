import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from "moment";
import { Column, SpaceBetweenRow } from "../../../UI/atoms/StructuralLayout";
import { BodyRegular } from '../../../UI/atoms/fonts/Body';
import Colours from "../../../UI/atoms/Colours";
import { Period } from '../../../../models/movements.models';
import { workdayCount } from "../../../../utils/DateUtils";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../store/ducks/config.duck";
import { fetchUsersHolidayInfoV2 } from "../../../../services/HolidayService";
import { TrackJS } from "trackjs";
import { UserHolidayInfoResponse } from "../models/Holidays.model";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";

export function HolidayBreakdownSummary(props: Props) {
  const {fromDate, userId} = props;
  const calendarDate = useSelector(selectCalendarDate);
  const config = useSelector(selectConfig);
  const [usersHolidayInfo, setUsersHolidayInfo] = useState<UserHolidayInfoResponse | undefined>(undefined);

  const year = useMemo(() => {
    if (fromDate) return config.getHolidayYearStartDate(fromDate).year()
    else if (calendarDate) return config.getHolidayYearStartDate(calendarDate).year();
    else config.getHolidayYearStartDate(moment()).year();
  }, [calendarDate, config, fromDate]);

  useEffect(() => {
    if (year && userId) {
      fetchUsersHolidayInfoV2(year, userId)
        .then(setUsersHolidayInfo)
        .catch((err) => {
          setUsersHolidayInfo(undefined);
          TrackJS?.track(err);
        })
    }
  }, [year, userId]);

  const dayCount = useMemo(() => {
    let days = workdayCount(props.fromDate ?? null, props.toDate ?? null);
    if (days === 1) {
      if (props.startDatePeriod !== Period.AllDay || props.endDatePeriod !== Period.AllDay) {
        days = 0.5;
      }
    } else {
      if (props.startDatePeriod !== Period.AllDay) {
        days = days - 0.5;
      }

      if (props.endDatePeriod !== Period.AllDay) {
        days = days - 0.5;
      }
    }

    return days;
  }, [props]);

  const daysLeft = useMemo(() => {
    if (!usersHolidayInfo) return '??';
    return usersHolidayInfo.holidayAllowance - usersHolidayInfo.holidaysTaken - dayCount;
  }, [usersHolidayInfo, dayCount]);

  return (
    <Column style={props.style ?? {}}>
      <SpaceBetweenRow style={{borderBottom: `1px solid ${Colours.mildGrey}`}}>
        <BodyRegular weight={400} colour={Colours.black}>Total work days booked</BodyRegular>
        <BodyRegular weight={600} colour={Colours.black}>{dayCount} days</BodyRegular>
      </SpaceBetweenRow>

      {config.holidayAllowance && <SpaceBetweenRow>
        <BodyRegular weight={400} colour={Colours.black}>Total holidays left for {year}</BodyRegular>
        <BodyRegular weight={600} colour={Colours.black}>{daysLeft} days</BodyRegular>
      </SpaceBetweenRow>}
    </Column>
  )
}

interface Props {
  fromDate?: Moment
  toDate?: Moment;
  style?: any;
  userId?: string;
  startDatePeriod: Period;
  endDatePeriod: Period;
}
