import React, { useMemo } from 'react';
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from '../../../../UI/atoms/fonts/Body';
import Colours from "../../../../UI/atoms/Colours";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props extends TooltipProps<ValueType, NameType> {
  title?: string;
}

export function ToolTip(props: Props) {
  const {active, payload, title} = props;
  const {t} = useTranslation();

  const date = useMemo(() => {
    if (payload && payload.length > 0 && payload[0].payload.date) {
      return moment(payload[0].payload.date).format('Do MMM YYYY');
    }
    return title ?? '';
  }, [payload, title]);

  const officeCapacity = useMemo(() => {
    if (payload && payload.length > 0) {
      return payload[0].payload.officeCapacity;
    }
    return 0;
  }, [payload]);

  const peopleInOffice = useMemo(() => {
    if (payload && payload.length > 0) {
      return payload[0].payload.officeAttendance;
    }
    return 0;
  }, [payload]);

  const keyRoleInOffice = useMemo(() => {
    if (payload && payload.length > 0) {
      return payload[0].payload.count;
    }
    return 0;
  }, [payload]);


  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <Title>
          <BodyLarge weight={600}>{date}</BodyLarge>
        </Title>
        <BodyRegular>{t('reports-page.key-responsib.office-capacity', {officeCapacity})}</BodyRegular>
        <BodyRegular>{t('reports-page.key-responsib.people-in-office', {peopleInOffice})}</BodyRegular>
        <BodyRegular>{t('reports-page.key-responsib.key-role-in-office', {keyRoleInOffice})}</BodyRegular>
      </ToolTipContainer>
    )
  }
  return null;
}

const ToolTipContainer = styled.div`
  background-color: white;
  padding: 24px 32px;
  box-shadow: 0 2px 8px 2px rgba(76, 109, 172, 0.15);
  border-radius: 24px;
  min-width: 200px;
`

const Title = styled.div`
  border-bottom: 1px solid ${Colours.mildGrey};
  padding-bottom: 8px;
  margin-bottom: 16px;
`
