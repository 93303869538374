import React, { useEffect, useMemo, useRef } from 'react';
import { DailyMeetingRoomSchedule } from "./DailyMeetingRoomSchedule";
import { TimeSwimlane } from "./TimeSwimlane";
import { HALF_HOUR_TIME_SLOT_HEIGHT, MEETING_ROOM_HEADER_HEIGHT } from "./StructuralComponents";
import { MeetingRoom } from "../models/MeetingRooms";
import { useSelector } from "react-redux";
import { isLoading, selectFilteredMeetingRooms } from "../models/room-finder.duck";
import { NoMeetingRoomsInOfficeMessage } from "./NoMeetingRoomsInOfficeMessage";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { Grid, GridContainer } from "./meeting-room-grid/StructuralComponents";
import styled from "styled-components/macro";
import moment from "moment";
import { SyncStatus } from "../../../../hooks/WithMicrosoftCalendarSyncActiveChecker";

export function MeetingRoomScheduleList(props: Props) {
  const {calendarSyncStatus} = props;
  const myRef = useRef(null);
  const meetingRooms = useSelector(selectFilteredMeetingRooms);
  const meetingRoomsLoading = useSelector(isLoading);

  const percentageThroughDay = useMemo(() => {
    const mmt = moment();
    return (mmt.hour() * 60 + mmt.minute()) / 1440;
  }, []);

  useEffect(() => {
    if (meetingRooms.length === 0) return;

    try {
      if (myRef) {
        // @ts-ignore
        myRef.current.scrollTop = (HALF_HOUR_TIME_SLOT_HEIGHT * 16) - 10;
      }
    } catch (e) {
    }
  }, [meetingRooms]);

  return (
    <>
      {meetingRoomsLoading && <LoadingSpinner fullScreen={true} hideText={true} />}
      {meetingRooms.length === 0 ? <>
        <NoMeetingRoomsInOfficeMessage />
      </> : <>
        <>
          <GridContainer ref={myRef} style={{height: '100%'}}>
            <Grid>
                <TimeSwimlane />
                {meetingRooms.map((meetingRoom: MeetingRoom, key: number) => (
                  <DailyMeetingRoomSchedule key={key} className={"grid-col"} meetingRoom={meetingRoom} calendarSyncStatus={calendarSyncStatus} />
                ))}
              <CurrentTimeLine percentageThroughDay={percentageThroughDay} />
            </Grid>
          </GridContainer>
        </>
      </>}
    </>
  )
}

interface Props {
  calendarSyncStatus: SyncStatus;
}

const CurrentTimeLine = styled.div<any>`
  position: absolute;
  top: ${props => (HALF_HOUR_TIME_SLOT_HEIGHT * 48) * props.percentageThroughDay + MEETING_ROOM_HEADER_HEIGHT}px;
  width: 100%;
  background: red;
  height: 1px;
`
