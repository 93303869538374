import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveDay,
  selectEditingWhereabouts, selectFocusedUserId,
  setActiveDay,
} from "../../../../../store/ducks/editMovements.duck";
import { emptyWhereaboutsDay, Period, UsersWhereaboutsDay } from "../../../../../models/movements.models";
import { HeadlineSmall } from "../../../atoms/fonts/Headline";
import { MovementIcon } from "../../../molecules/MovementIcons";
import moment, { Moment } from "moment";
import { Column } from '../../../atoms/StructuralLayout';
import PeriodList from "../../period-list/PeriodList";
import ActivityList from "./ActivityList";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import {
  selectAllOfficeEntities,
  selectNumberOfFavouriteOfficesAndDesks
} from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import {
  selectAllSelectableWhereaboutsOptions,
  selectAllWhereaboutsOptions
} from "../../../../../store/ducks/whereaboutsOptions.duck";
import isMobile from "is-mobile";
import { selectCurrentUser, selectIconPack } from "../../../../../store/ducks/auth.duck";
import { DATE_FORMAT, getListOfDaysBetweenInclusiveToExclusive } from "../../../../../utils/DateUtils";

export default function MobileEditMovementsContent() {
  const whereaboutsDays: UsersWhereaboutsDay[] = useSelector(selectEditingWhereabouts);
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const activeDate = useSelector(selectActiveDay);
  const currentUser = useSelector(selectCurrentUser);
  const focusedUserId = useSelector(selectFocusedUserId);

  const getOptionFor = (id: number) => {
    return whereaboutsOptions.find((whereaboutsOption) => whereaboutsOption.id === id);
  }

  const datesToRender = useMemo(() => {
    if (activeDate) {
      const weekStart = activeDate.clone().startOf('isoWeek');
      const days = currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled ? 7 : 5;
      return getListOfDaysBetweenInclusiveToExclusive(weekStart, weekStart.clone().add(days, 'days'))
    } else {
      return [];
    }
  }, [activeDate, currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled]);

  const whereaboutsToRender = useMemo(() => {
    if (focusedUserId) {
      return datesToRender.map((date: Moment) => {
        const day = whereaboutsDays.find(wd => wd.date === date.format(DATE_FORMAT));
        if (day) {
          return day;
        } else {
          return emptyWhereaboutsDay(date.format(DATE_FORMAT), focusedUserId);
        }
      })
    } else {
      return [];
    }
  }, [focusedUserId, datesToRender, whereaboutsDays]);

  if (!whereaboutsDays) {
    return null;
  }

  return (
    <>
      {whereaboutsToRender.map((whereaboutsDay: UsersWhereaboutsDay, key: number) => <>
        <MobileMovementDayRow key={key} day={moment(whereaboutsDay.date)}
                              amStatus={getOptionFor(whereaboutsDay.amStatusId)}
                              pmStatus={getOptionFor(whereaboutsDay.pmStatusId)}
                              officeLocationId={whereaboutsDay.amOfficeId}
                              officePmLocationId={whereaboutsDay.pmOfficeId}
                              showLoading={!!whereaboutsDay.isWhereaboutsSaving} />
      </>)}
    </>
  )
}

function MobileMovementDayRow(props: { showLoading: boolean, day: Moment, amStatus?: WhereaboutsOption, pmStatus?: WhereaboutsOption, officeLocationId: number, officePmLocationId: number }) {
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);
  const user = useSelector(selectCurrentUser);
  const isDisabled = useMemo(() => moment().diff(props.day, 'days') > 0, [props.day]);
  const isActive = useMemo(() => !!activeDay && moment(activeDay).diff(props.day, 'days') === 0, [activeDay, props.day]);
  const onDayClick = (day: Moment) => dispatch(setActiveDay(day));
  const activities = useSelector(selectAllSelectableWhereaboutsOptions);
  const numberOfFavouriteOfficesAndDesks = useSelector(selectNumberOfFavouriteOfficesAndDesks);
  const iconPack = useSelector(selectIconPack);

  const heightOfActivitiesList = useMemo(() => {
    const numberOfActivitiesInList = 1 + numberOfFavouriteOfficesAndDesks + activities.length + 1 + ((user?.defaultLocationId ?? 0) > 0 ? 1 : 0);
    return isActive ? 100 * (numberOfActivitiesInList) : 0;
  }, [isActive, numberOfFavouriteOfficesAndDesks, activities, user]);

  const offices = useSelector(selectAllOfficeEntities);
  const officeLocationId = props.officeLocationId ?? null;
  const officePmLocationId = props.officePmLocationId ?? null;

  const officeAm = useMemo(() => {
    const attendingOffice = offices.find((o: OfficeEntity) => o.id === officeLocationId) ?? null
    if (attendingOffice && attendingOffice.parentId) {
      return offices.find((o: OfficeEntity) => o.id === attendingOffice.parentId)
    }
    return attendingOffice ?? null;
  }, [offices, officeLocationId]);

  const officePm = useMemo(() => {
    if (!officePmLocationId || officeLocationId === officePmLocationId) return;

    const attendingOffice = offices.find((o: OfficeEntity) => o.id === officePmLocationId) ?? null
    if (attendingOffice && attendingOffice.parentId) {
      return offices.find((o: OfficeEntity) => o.id === attendingOffice.parentId)
    }
    return attendingOffice ?? null;
  }, [offices, officeLocationId, officePmLocationId]);

  if (isMobile()) {
    return (
      <div onClick={() => onDayClick(props.day)}
           className={`mobileEditMovements__row ${isActive ? 'mobileEditMovements__row--active' : ''}`}>
        <div className="mobileEditMovements__dayHeader">
          <Title day={props.day.format('dddd')} activePeriod={Period.AllDay} isActive={true}/>
          <MovementIcon className="mobileEditMovements__movementIcon"
                        disabled={isDisabled}
                        iconPack={iconPack}
                        amStatus={props.amStatus} pmStatus={props.pmStatus}
                        amOfficeLocationId={officeAm?.id} pmOfficeLocationId={officePm?.id}
                        officeAmLabel={officeAm?.label}  officePmLabel={officePm?.label} showLoading={props.showLoading}/>
        </div>
        <Column className="mobileEditMovements__content"
                onClick={(e: any) => e.stopPropagation()}
                style={{maxHeight: heightOfActivitiesList}}>
          {isActive && <>
            <PeriodList/>
            <ActivityList activeDate={activeDay}/>
          </>}
        </Column>
      </div>
    )
  } else {
    return null;
  }

}

function Title(props: { day: String, activePeriod: Period, isActive: boolean }) {
  const start = props.day;
  const middle = props.isActive && props.activePeriod !== Period.AllDay ?
    <span className="mobileEditMovements--highlighted"> {props.activePeriod}</span> : '';
  const end = props.isActive ? ' I\'ll be' : '';

  return (
    <HeadlineSmall>
      {start}
      <span>{middle}</span>
      {end}
    </HeadlineSmall>
  )
}
