import React, { useState } from 'react';
import Dialog from "../../UI/molecules/Dialog";
import DialogHeader from "../DialogHeader";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../store/ducks/dialog.duck";
import { CarParkSpace, requestUpdateCarParkingSpace } from "../../../services/CarParkingPlanService";
import TextField from "../../UI/atoms/TextField";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import {
  selectSelectedCarParkingPlan,
  setSelectedCarPark,
  setSelectedCarParkSpace
} from "../../../store/ducks/officeView.duck";
import { safeParseInt } from "../../../utils/MathUtils";
import { Switch } from "../../UI/atoms/Switch";
import { IsSuperAdmin } from "../../../hooks/IsSuperAdmin";

export function EditCarParkingSpaceDialog(props: Props) {
  const {parkingSpace} = props.payload;
  const dispatch = useDispatch();
  const selectedCarParkPlanOnOfficeView = useSelector(selectSelectedCarParkingPlan);
  const isSuperAdmin = IsSuperAdmin();

  const [label, setLabel] = useState(props?.payload?.parkingSpace?.label ?? '');
  const [x, setX] = useState(props?.payload?.parkingSpace?.x ?? 0);
  const [y, setY] = useState(props?.payload?.parkingSpace?.y ?? 0);
  const [width, setWidth] = useState(props?.payload?.parkingSpace?.width ?? 0);
  const [height, setHeight] = useState(props?.payload?.parkingSpace?.height ?? 0);
  const [rotation, setRotation] = useState(props?.payload?.parkingSpace?.rotation ?? 0);
  const [active, setActive] = useState(props?.payload?.parkingSpace?.active ?? false);
  const [loading, setLoading] = useState(false);

  const close = () => {
    dispatch(closeDialog());
  }

  const updateX = (val: string) => {
    try {
      const newX = safeParseInt(val);
      setX(newX);
      updateSpace(label, newX, y, width, height, rotation, active);
    } catch (e) {}
  }

  const updateY = (val: string) => {
    try {
      const newY = safeParseInt(val);
      setY(newY);
      updateSpace(label, x, newY, width, height, rotation, active);
    } catch (e) {}
  }

  const updateWidth = (val: string) => {
    try {
      const newWidth = safeParseInt(val);
      setWidth(newWidth);
      updateSpace(label, x, y, newWidth, height, rotation, active);
    } catch (e) {}
  }

  const updateHeight = (val: string) => {
    try {
      const newHeight = safeParseInt(val);
      setHeight(newHeight);
      updateSpace(label, x, y, width, newHeight, rotation, active);
    } catch (e) {}
  }

  const updateRotation = (val: string) => {
    try {
      const newRotation = safeParseInt(val);
      setRotation(newRotation);
      updateSpace(label, x, y, width, height, newRotation, active);
    } catch (e) {}
  }

  const updateActive = (val: boolean) => {
    try {
      setActive(val);
      updateSpace(label, x, y, width, height, rotation, val);
    } catch (e) {}
  }

  const updateSpace = (label: string, x: number, y: number, width: number, height: number, rotation: number, active: boolean) => {
    if (selectedCarParkPlanOnOfficeView) {
      const updatedCarParkSpace = {
        ...parkingSpace,
        label: label,
        width: width,
        height: height,
        x: x,
        y: y,
        rotation: rotation,
        active: active,
      }

      const updatedParkingSpaces = [
        ...selectedCarParkPlanOnOfficeView.parkingSpaces.filter(ps => ps.id !== parkingSpace.id),
        updatedCarParkSpace
      ];

      const updatedCarPark = {
        ...selectedCarParkPlanOnOfficeView,
        parkingSpaces: updatedParkingSpaces
      };

      if (selectedCarParkPlanOnOfficeView) {
        dispatch(setSelectedCarPark(updatedCarPark));
      }
    }
  }

  const save = async () => {
    setLoading(true);
    try {
      const updatedSpace = {
        ...props.payload.parkingSpace,
        x: x, y: y, width: width, height: height, rotation: rotation, label: label, active: active
      }
      const updatedCarParkSpace = await requestUpdateCarParkingSpace(updatedSpace);

      if (selectedCarParkPlanOnOfficeView) {
        const updatedParkingSpaces = [
          ...selectedCarParkPlanOnOfficeView.parkingSpaces.filter(ps => ps.id !== updatedCarParkSpace.id).filter(ps => !!ps.id),
          updatedCarParkSpace
        ];
        const updatedCarPark = {
          ...selectedCarParkPlanOnOfficeView,
          parkingSpaces: updatedParkingSpaces
        };

        if (selectedCarParkPlanOnOfficeView) {
          dispatch(setSelectedCarPark(updatedCarPark));
        }
      }
      dispatch(closeDialog());
      dispatch(setSelectedCarParkSpace(undefined));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog isOpen={true} onClose={() => close()} style={{marginLeft: '50%'}}>
      <DialogHeader title={'car-parking.edit-space-title'} />
      <TextField type={'text'} onChange={setLabel} value={label} label={"Label"} disabled={loading} />
      {isSuperAdmin && <>
        <TextField type={'number'} onChange={updateX} value={x.toString()} label={"car-parking.xaxis"} disabled={loading} />
        <TextField type={'number'} onChange={updateY} value={y.toString()} label={"car-parking.yaxis"} disabled={loading} />
        <TextField type={'number'} onChange={updateWidth} value={width.toString()} label={"car-parking.width"} disabled={loading} />
        <TextField type={'number'} onChange={updateHeight} value={height.toString()} label={"car-parking.height"} disabled={loading} />
        <TextField type={'number'} onChange={updateRotation} value={rotation.toString()} label={"car-parking.rotation"} disabled={loading} />
      </>}
      <Switch style={{marginBottom: 32}} value={active} label={'Active'} onChange={(val: boolean) => updateActive(val)} />
      <PrimaryButton text={"button.save"} fullWidth={true} click={save} disabled={loading} />
    </Dialog>
  )
}

interface Props {
  payload: {
    parkingSpace: CarParkSpace;
  };
}
