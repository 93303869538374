import React, { useMemo } from 'react';
import { UserAvatarAndName } from '../../../../molecules/UserAvatarAndName';
import { useSelector } from 'react-redux';
import { MemberMovements } from '../../../../../../models/movements.models';
import EditMovementsPanel from '../edit-movements-panel/EditMovementsPanel';
import { selectEditingWhereabouts, selectFocusedUserId } from '../../../../../../store/ducks/editMovements.duck';
import './user-row.scss';
import { MovementsName, UsersMovements, UsersMovementsRow } from "../StructuralComponents";
import { Column } from '../../../../atoms/StructuralLayout';
import MovementsWeek from "../team-member-row/MovementsWeek";
import { UserResponsibilityBadges } from "../../../../molecules/UserResponsibilityBadges";
import { canEditAnyUser, canUserEditUser } from "../../../../../../utils/AccessControl";
import { SetWhereaboutsButton } from "./SetWhereaboutsButton";
import moment from "moment";
import { ResyncCalendarButton } from "../../../../molecules/ResyncCalendarButton";
import { User } from "../../../../../../models/user.models";

interface Props {
  member: MemberMovements;
  enableSetMovements: boolean;
  currentUser?: User
}

export default function WhereaboutsRow(props: Props) {
  const {member, currentUser} = props;
  const focusedUserId = useSelector(selectFocusedUserId);
  const editingWhereabouts = useSelector(selectEditingWhereabouts);

  const isEditing = useMemo(() => !!focusedUserId && focusedUserId === member.id, [focusedUserId, member]);
  const canEditUsersMovements = useMemo(() => canUserEditUser(currentUser, member), [currentUser, member]);
  const isCurrentUser = useMemo(() => currentUser?.id === member.id, [currentUser, member]);
  const usesCalendarSync = useMemo(() => currentUser?.enableOutlookSync, [currentUser]);
  const enableSetMovements = useMemo(() => canEditUsersMovements || isCurrentUser, [canEditUsersMovements, isCurrentUser]);
  const hasBeenEdited = useMemo(() => isCurrentUser && currentUser?.whereaboutsEdited, [isCurrentUser, currentUser]);
  const carParking = useMemo(() => member.carParking ?? [], [member]);

  const dataTestValue =  `avatar-${member.firstName}-${member.lastName}`.replace('/ /g', '-');

  return (
    <UsersMovementsRow className="wallchart__row" isEditting={isEditing} data-test={dataTestValue}>
      <Column>
        <UsersMovements>
          <MovementsName>
            <UserAvatarAndName colour={isCurrentUser ? 'blue' : 'darkBlue'}
                               firstName={member.firstName} lastName={member.lastName}
                               userId={member.id} showEditLink={canEditAnyUser(currentUser)}/>
            <UserResponsibilityBadges user={member} />
          </MovementsName>

          <MovementsWeek movements={isEditing ? editingWhereabouts : member.whereabouts}
                         userId={member.id}
                         isEditing={isEditing}
                         areUsersMovements={isCurrentUser}
                         enableSetMovements={enableSetMovements}
                         carParking={carParking}
                         dateFrom={member.dateFrom}
                         dateTo={member.dateTo}
                         sevenDayWeekEnabled={currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled} />

          {/* Mobile set movements button */}
          {enableSetMovements && isCurrentUser && !hasBeenEdited &&
              <SetWhereaboutsButton userId={props.member.id} isCurrentUser={isCurrentUser}
                                                   isEditing={isEditing}
                                                   className="wallchartRow__editButton--mobile"/>}
        </UsersMovements>

        {enableSetMovements && isCurrentUser && <>
          {hasBeenEdited && usesCalendarSync ?
            <ResyncCalendarButton date={moment(member.dateFrom)} /> :
            <SetWhereaboutsButton userId={props.member.id} isCurrentUser={isCurrentUser}
                                  isEditing={isEditing} />}
        </>}

      </Column>

      {enableSetMovements && isEditing && <EditMovementsPanel isEditing={isEditing} userId={props.member.id}/>}
    </UsersMovementsRow>
  )
}
