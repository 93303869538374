import { useEffect, useState } from "react";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { CapacityResponse } from "../services/OfficeCapacityService";
import { Moment } from "moment";
import { getUsersInOfficeOn } from "../services/Movements";
import { SimpleUserWithLocation } from "../models/movements.models";

export function UsersInOffice(office: OfficeEntity, activeDate?: Moment, capacity?: CapacityResponse) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [usersInOffice, setUsersInOffice] = useState<SimpleUserWithLocation[]>([]);

  useEffect(() => {
    // setUsersInOffice([]);
    // setHasLoaded(false);
  }, [activeDate]);

  useEffect(() => {
    if (isLoading || hasLoaded || !activeDate || !capacity || ((capacity.amUsed + capacity.pmUsed) === 0)) {
      return;
    }

    setIsLoading(true);
    getUsersInOfficeOn(activeDate, office.id)
      .then((resp: SimpleUserWithLocation[]) => setUsersInOffice(resp))
      .finally(() => {
        setIsLoading(false);
        setHasLoaded(true);
      });
  }, [capacity, isLoading, activeDate, office, hasLoaded]);

  return {
    loading: isLoading,
    users: usersInOffice,
  };
}
