import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";
import { smallTablet } from "../../../UI/atoms/MediaQueries";
import { WEEK_WIDTH } from "../WallPlannerStructuralComponents";

export function FloatingUserCell(props: Props) {
  return (
    <UserColumnWrapper hideOnMobile={!!props.hideOnMobile} className="userColumn__wrapper">
      <UserColumn className="floatingUser__userColumn" style={props.style ?? {}}>
        {props.children ?? null}
      </UserColumn>
    </UserColumnWrapper>
  )
}

interface Props {
  children?: any;
  style?: any
  hideOnMobile?: boolean
}


export const UserColumn = styled.div`
  //position: absolute;
  z-index: 99;
  background-color: transparent;
  width: ${WEEK_WIDTH}px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  @media (${smallTablet}) {
    z-index: unset;
    align-items: center;
    background-color: white;
    border-right: 1px ${Colours.mildGrey} solid;
  }
`

export const UserColumnWrapper = styled.div<any>`
  display: ${props => props.hideOnMobile ? 'none' : 'block'};
  width: 0;
  min-width: 0;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  position: sticky;
  z-index: 99;
  @media (${smallTablet}) {
    position: relative;
    z-index: unset;
    min-width: ${WEEK_WIDTH}px;
    width: ${WEEK_WIDTH}px;
    border-right: 1px ${Colours.mildGrey} solid;
    display: block;
    padding-left: 0;
  }
`
