import { useSelector } from "react-redux";
import { selectCurrentUsersLineReport } from "../../store/ducks/companyStructure.duck";
import { useMemo } from "react";

export function HasCurrentUserGotLineReports() {
  const lineReports = useSelector(selectCurrentUsersLineReport);

  return useMemo(() => {
    return !!lineReports && lineReports.length > 0;
  }, [lineReports]);
}
