import React, { useMemo } from 'react';
import WhereaboutsRow from "../user-row/WhereaboutsRow";
import { MemberMovements } from "../../../../../../models/movements.models";
import { Column } from '../../../../atoms/StructuralLayout';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../../store/ducks/auth.duck";
import { useLocation } from "react-router-dom";
import { naturalSort } from "../../../../../../utils/ArrayUtils";

export default function MovementsList(props: { movements: MemberMovements[], enableSetMovements: boolean }) {
  const {movements, enableSetMovements} = props;
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);
  const isTeamView = useMemo(() => location.pathname.includes('whereabouts/team'), [location])

  const filteredWhereaboutsList = useMemo(() => {
    const whereaboutsList = isTeamView ? movements.filter(m => m.id !== currentUser?.id) : movements;
    return naturalSort(whereaboutsList, 'firstName');
  }, [currentUser, movements, isTeamView])

  return (
    <Column>
      {filteredWhereaboutsList.map((whereabouts: any, k: any) => (
        <WhereaboutsRow key={k} member={whereabouts} enableSetMovements={enableSetMovements} currentUser={currentUser}/>
      ))}
    </Column>
  )
}
