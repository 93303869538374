import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getVisitorBookings, VisitorBooking } from "./visitor-booking-service";
import { AppState } from "../../../../../../store/state/app.state";
import { DATE_FORMAT } from "../../../../../../utils/DateUtils";


export interface VisitorBookingState {
  bookings: VisitorBooking[];
  loading: boolean;
}

export const initialVisitorBookingState: VisitorBookingState = {
  bookings: [],
  loading: false,
};

export const loadVisitorBookings: any = createAsyncThunk(
  'visitorBooking/loadVisitorBookings',
  async (params: {date?: string, officeId?: number}, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const date = params?.date ?? appState.dashboard.calendarDate.format(DATE_FORMAT);
    const officeId = params?.officeId ?? (appState.officeView.selectedOffice?.id ?? 0);
    const bookings = await getVisitorBookings(officeId, date);
    return bookings;
  }
);

export const updateVisitorBookingItem: any = createAsyncThunk(
  'visitorBooking/updateVisitorBookingItem',
  async (params: {visitorBooking: VisitorBooking}, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const bookings = appState.visitorBooking.bookings;

    return bookings.map(b => b.id === params.visitorBooking.id ? params.visitorBooking : b);
  }
);

const visitorBookingSlice = createSlice({
  name: 'visitorBooking',
  initialState: initialVisitorBookingState,
  reducers: {
    resetVisitorBooking: (state) => (initialVisitorBookingState),
  },
  extraReducers: {
    [loadVisitorBookings.pending]: (state) => ({...state, bookings: [], loading: true}),
    [loadVisitorBookings.failed]: (state) => ({...state, bookings: [], loading: false}),
    [loadVisitorBookings.fulfilled]: (state, action) => ({...state, bookings: action.payload, loading: false}),

    [updateVisitorBookingItem.fulfilled]: (state, action) => ({...state, bookings: action.payload}),
  },
});

export default visitorBookingSlice.reducer;
export const {
  resetVisitorBooking,
} = visitorBookingSlice.actions;


// Selectors
export const selectVisitorBookings = (state: AppState) => state.visitorBooking.bookings;
export const selectVisitorBookingsLoading = (state: AppState) => state.visitorBooking.loading;
