import { useMemo } from "react";
import { useSelector } from "react-redux";
import { OfficeEntity, OfficeEntityWithFavourite } from "../services/AdvanceHotDeskingService";
import { selectAllOfficeEntities, selectFavouriteOffices } from "../store/ducks/advanceHotDeskingSetup.duck";
import { hasOfficeChildren } from "./WithOfficeChildren";

export function WithFavouriteOffices() {
  const allOffice = useSelector(selectAllOfficeEntities);
  const favourites = useSelector(selectFavouriteOffices);

  return useMemo(() => {
    return allOffice
      .map((office: OfficeEntity) => ({ ...office, isFavourite: !!favourites.find(favOffice => favOffice.officeId === office.id)}))
      .filter((office: OfficeEntityWithFavourite) => office.isFavourite)
      .filter((office: OfficeEntityWithFavourite) => !hasOfficeChildren(office.id, allOffice));
  }, [allOffice, favourites]);
}
