import React from 'react';
import styled from "styled-components/macro";

export function MovementImage(props: Props) {
  return (<ImageWrapper>
    <Image src={props.src} alt={props.alt}/>
  </ImageWrapper>)
}

const ImageWrapper = styled.div`
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Image = styled.img`
  max-height: 43px;
  max-width: 43px;
`;

interface Props {
  src: string;
  alt: string;
}
