import React, { useEffect } from 'react';
import { OfficeFloorMap } from "../../../pages/company-movements/components/OfficeFloorMap";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { selectOffice } from "../../../../store/ducks/officeView.duck";
import {
  selectAreaForReservation,
  selectDeskForReservation,
  selectFloorForReservation,
  selectOfficeForReservation,
  selectSelectedFloor,
  setShowFloorMap
} from "../../../../store/ducks/deskReservation.duck";
import { selectAllOfficeDesks } from "../../../../store/ducks/deskBooking.duck";
import { findDeskInDesks, findOfficeInOffices } from "../../../../utils/OfficeHelper";
import { warningNotification } from "../../../../store/ducks/notification.duck";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { Desk } from "../../../../services/DeskBookingService";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";

export function OfficeFloorMapWithDataProviders(props: Props) {
  const dispatch = useDispatch();
  const selectedFloorForFloorPlan = useSelector(selectSelectedFloor);
  const desks = useSelector(selectAllOfficeDesks);
  const offices = useSelector(selectAllOfficeEntities);

  useEffect(() => {
    dispatch(selectOffice(selectedFloorForFloorPlan));
  }, [dispatch, selectedFloorForFloorPlan]);

  const onDeskSpotSelect = (desk: any) => {
    const deskEntity = findDeskInDesks(desks, desk.id);
    if (!deskEntity) {
      dispatch(warningNotification('Invalid desk'));
    } else if (!deskEntity.active) {
      dispatch(warningNotification('Desk not valid'));
    } else {
      if (desk.isAmBooked && desk.isPmBooked) {
        dispatch(warningNotification('Desk is already booked by someone'));
      } else {
        if (props.onDeskSelect) {
          props.onDeskSelect(deskEntity);
        } else {
          dispatch(selectDeskForReservation(desk));
          dispatch(setShowFloorMap(false));
        }
      }
    }
  }

  const onOfficeSpotSelect = (office: any) => {
    const officeEntity = findOfficeInOffices(offices, office.officeId);
    if (!officeEntity) {
      dispatch(warningNotification('Invalid office'));
    } else {
      if (props.onOfficeSelect) {
        props.onOfficeSelect(officeEntity);
      } else {
        if (officeEntity.area) {
          dispatch(selectAreaForReservation(officeEntity));
        } else if (officeEntity.floor) {
          dispatch(selectFloorForReservation(officeEntity));
        } else if (officeEntity.office) {
          dispatch(selectOfficeForReservation(officeEntity));
        }
        dispatch(setShowFloorMap(false));
      }
    }
  }

  const close = (e?: InputEvent) => {
    if (e) {
      e.stopPropagation();
    }
    dispatch(setShowFloorMap(false));
    if (props.close) {
      props.close();
    }
  }

  return (
    <FullPageWrapper onClick={(e: any) => close(e)}>
      <Container>
        <OfficeFloorMap showAdminButtons={false} onDeskSelect={onDeskSpotSelect} onOfficeSelect={onOfficeSpotSelect} />
        <OutlineButton text={"button.close"} className={"floorMap__close"} click={close} />
      </Container>
    </FullPageWrapper>
  )
}

interface Props {
  onDeskSelect?: (desk: Desk) => any;
  onOfficeSelect?: (office: OfficeEntity) => any;
  close?: () => any;
}

const FullPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  z-index: 99999;
`

const Container = styled.div`
  position: relative;
  background-color: white;
  border-radius: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;
  .floorMap__close {
    position: absolute;
    bottom: 20px;
    right: 24px;
    width: 100%;
  }
  @media (${tablet}) {
    border-radius: 12px;
    width: 80vw;
    height: 80vh;
    margin: 10vh 10vw;
  }
`
