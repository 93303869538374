
export function isNumber(n: any) {
  if (!n) return false;
  return new RegExp('^[0-9]+$').test(n);
}

export function asNumber(n?: string | null): number {
  if (!n) return 0;
  try {
    return parseInt(n);
  } catch (ex: any) {
    return 0;
  }
}
