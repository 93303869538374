import React, { useEffect, useMemo, useState } from 'react';
import { HeadlineLarge, HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import TextField from "../../../../UI/atoms/TextField";
import { Checkbox } from "../../../../UI/atoms/Checkbox";
import styled from "styled-components/macro";
import { isNumber } from "../../../../../utils/TypeUtils";
import {
  failureNotification,
  successNotification,
  warningNotification
} from "../../../../../store/ducks/notification.duck";
import { getMeetingRoomById, updateMeetingRoom } from "../../../../../services/MeetingRoomsService";
import { MeetingRoom, MeetingRoomFacilities } from "../../../meeting-rooms/models/MeetingRooms";
import { OfficeDropDownList } from "../../../../UI/organisms/OfficeDropDownList";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { useParams } from "react-router";
import { findOfficeInOffices } from "../../../../../utils/OfficeHelper";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { selectAllOfficesForMeetingRooms, selectOffices } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { setMeetingRoomFacilities } from "../../../meeting-rooms/models/room-finder.duck";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { OutlookMeetingRoomLookup } from "./OutlookMeetingRoomLookup";

export function EditMeetingRoomPage(props: Props) {
  const dispatch = useDispatch();
  const params: any = useParams();
  const [meetingRoom, setMeetingRoom] = useState<MeetingRoom | undefined>(undefined);
  const [name, setName] = useState('');
  const [capacity, setCapacity] = useState('');
  const [tv, setTv] = useState(false);
  const [confCall, setConfCall] = useState(false);
  const [whiteboard, setWhiteboard] = useState(false);
  const [chargePoints, setChargePoints] = useState(false);
  const [microsoftRoomId, setMicrosoftRoomId] = useState('');
  const [office, setOffice] = useState<OfficeEntity | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const offices = useSelector(selectOffices);
  const [activeRequest, setActiveRequest] = useState(false);
  const allOfficesForMeetingRooms = useSelector(selectAllOfficesForMeetingRooms);

  const meetingRoomId = useMemo(() => params.meetingRoomId, [params]);

  useEffect(() => {
    if (meetingRoom || loading) return;

    if (meetingRoomId && allOfficesForMeetingRooms.length > 0 && !activeRequest) {
      setLoading(true);
      setActiveRequest(activeRequest);
      getMeetingRoomById(meetingRoomId).then((resp: MeetingRoom) => {
        setMeetingRoom(resp);
        setName(resp.name);
        setCapacity(resp.capacity.toString());
        setMicrosoftRoomId(resp.microsoftRoomId || '');
        setMeetingRoomFacilities(resp.facilities)

        setTv(resp.facilities.includes(MeetingRoomFacilities.TV));
        setConfCall(resp.facilities.includes(MeetingRoomFacilities.ConferenceCall));
        setWhiteboard(resp.facilities.includes(MeetingRoomFacilities.WhiteBoard));
        setChargePoints(resp.facilities.includes(MeetingRoomFacilities.ChargePoints));

        const office = findOfficeInOffices(allOfficesForMeetingRooms, resp.officeId);
        if (office) {
          setOffice(office);
        } else {
          dispatch(failureNotification('Cannot load meeting room, failed to find office'));
          throw new Error('Cannot load meeting room, failed to find office');
        }
      })
        .finally(() => setLoading(false));
    }
  }, [activeRequest, dispatch, offices, meetingRoomId, meetingRoom, loading, allOfficesForMeetingRooms]);


  const setCapacityWithCheck = (val: string) => {
    if (isNumber(val)) {
      setCapacity(val);
    }
  }

  const save = async () => {
    if (!capacity) {
      dispatch(failureNotification('Capacity needs to be set'));
      return;
    }

    if (!name) {
      dispatch(failureNotification('Name needs to be set'));
      return;
    }

    if (!office) {
      dispatch(failureNotification('Select an office'));
      return;
    }

    if (!meetingRoom) {
      dispatch(failureNotification('No meeting room to update'));
      return;
    }

    try {
      setLoading(true);
      let facilities: MeetingRoomFacilities[] = [];

      if (confCall) facilities.push(MeetingRoomFacilities.ConferenceCall);
      if (tv) facilities.push(MeetingRoomFacilities.TV);
      if (chargePoints) facilities.push(MeetingRoomFacilities.ChargePoints);
      if (whiteboard) facilities.push(MeetingRoomFacilities.WhiteBoard);

      meetingRoom.name = name;
      meetingRoom.capacity = parseInt(capacity);
      meetingRoom.officeId = office.id;
      meetingRoom.facilities = facilities;
      meetingRoom.microsoftRoomId = microsoftRoomId;

      // @ts-ignore
      await updateMeetingRoom(meetingRoom);
      dispatch(successNotification("Meeting room updated"));

    } finally {
      setLoading(false);
    }
  }

  const onDeleteMeetingRoomClick = () => {
    if (!meetingRoom) {
      dispatch(warningNotification('No meeting room to delete'));
      return;
    }
    dispatch(openDialogWithPayload({
      payload: { meetingRoom: meetingRoom },
      activeDialog: DialogIdentifiers.DeleteMeetingRoomConfirm
    }));
  }

  return (
    <>
      <GoBackButton url={'/manage/meeting-rooms'} />
      <HeadlineLarge style={{marginBottom: 16}}>{t('settings.meeting-rooms.edit')}</HeadlineLarge>
      <TextField disabled={loading}
                 value={name}
                 onChange={setName}
                 fullWidth={true}
                 label={"settings.meeting-rooms.name"} />
      <TextField disabled={loading}
                 value={capacity}
                 onChange={setCapacityWithCheck}
                 fullWidth={true}
                 label={"settings.meeting-rooms.capacity"} />

      <OutlookMeetingRoomLookup loading={loading}
                                microsoftRoomId={microsoftRoomId}
                                onChange={roomId => setMicrosoftRoomId(roomId)} />

      <BodyRegular weight={600} colour={Colours.darkGrey}>{t('settings.meeting-rooms.office')}</BodyRegular>
      <OfficeDropDownList onSelect={setOffice} disabled={loading} preSelectedOffice={office?.id ?? meetingRoom?.officeId} offices={allOfficesForMeetingRooms} />


      <HeadlineSmall style={{marginTop: 24}}>{t('room-finder.facilities.title')}</HeadlineSmall>
      <FacilitiesList>
        <Checkbox disabled={loading} value={tv} onChange={setTv} text={'room-finder.facilities.tv'} />
        <Checkbox disabled={loading} value={confCall} onChange={setConfCall} text={'room-finder.facilities.conference-call'} />
        <Checkbox disabled={loading} value={whiteboard} onChange={setWhiteboard} text={'room-finder.facilities.whiteboard'} />
        <Checkbox disabled={loading} value={chargePoints} onChange={setChargePoints} text={'room-finder.facilities.charge-points'} />
      </FacilitiesList>
      <PrimaryButton disabled={loading} fullWidth={true} size={'large'} style={{marginTop: 24, marginBottom: 16}} click={save}
                     text={'button.update'} />

      <OutlineButton disabled={loading} fullWidth={true} size={'large'} click={onDeleteMeetingRoomClick} text={'delete-meeting-room-dialog.delete-meeting-room'} />
    </>
  )
}

interface Props {
}

const FacilitiesList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 12px;
  }
`
