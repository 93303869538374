import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../atoms/Colours";
import { BodyLarge } from "../../atoms/fonts/Body";

export function AccountSuspendedBanner(props: Props) {
  return (
    <>
      <Wrapper>
        <Container className={'content'}>
          <BodyLarge weight={600} colour={Colours.white}>This account has been suspended.</BodyLarge>
          <BodyLarge weight={600} colour={Colours.white}>Contact <a href="mailto:support@team-today.com">support@team-today.com</a> to resume.</BodyLarge>
        </Container>
      </Wrapper>
      <PagePadding />
    </>
  )
}

interface Props {
}

const Container = styled.div`
  text-align: center;
  padding: 16px 0;
`

const Wrapper = styled.div`
  width: 100%;
  background-color: ${Colours.red};
  min-height: 40px;
  position: fixed;
  z-index: 999999;
  * {
    color: white;
  }
`

const PagePadding = styled.div`
  min-height: 104px;
`
