import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Row } from '../../../../UI/atoms/StructuralLayout';
import { FlatContentCard } from "../../../../UI/atoms/FlatContentCard";
import Colours from "../../../../UI/atoms/Colours";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import TextField from "../../../../UI/atoms/TextField";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyResponsibilitiesConfigKey,
  selectFireWardensConfig,
  selectFirstAidersConfig, selectIsLoading,
  selectSelectedOffice,
  updateConfig
} from "../key-responsibilities.duck";

export function KeyResponsibilitiesConfig(props: Props) {
  const [fireWardenValue, setFireWardenValue] = useState(0);
  const [firstAiderValue, setFirstAiderValue] = useState(0);
  const dispatch = useDispatch();
  const selectedOffice = useSelector(selectSelectedOffice);
  const isLoading = useSelector(selectIsLoading);
  const firstAiderConfig = useSelector(selectFirstAidersConfig);
  const fireWardenConfig = useSelector(selectFireWardensConfig);
  const {t} = useTranslation();

  useEffect(() => {
    setFireWardenValue(fireWardenConfig.perUsers as number);
  }, [fireWardenConfig]);

  useEffect(() => {
    setFirstAiderValue(firstAiderConfig.perUsers as number);
  }, [firstAiderConfig]);

  const isDisabled = useMemo(() => isLoading || !selectedOffice, [isLoading, selectedOffice]);

  const onSaveFireWarden = () => {
    dispatch(updateConfig({
      officeId: selectedOffice?.id,
      configId: KeyResponsibilitiesConfigKey.FIRE_WARDEN_PER_STAFF,
      value: `${fireWardenValue}`,
    }));
  }

  const onFireWardenChange = (val: string) => {
    try {
      setFireWardenValue(parseInt(val));
    } catch (e) {
      console.log(e)
      setFireWardenValue(fireWardenValue);
    }
  }

  const onSaveFirstAider = () => {
    dispatch(updateConfig({
      officeId: selectedOffice?.id,
      configId: KeyResponsibilitiesConfigKey.FIRST_AIDER_PER_STAFF,
      value: `${firstAiderValue}`,
    }))
  }

  const onFirstAiderChange = (val: string) => {
    try {
      setFirstAiderValue(parseInt(val));
    } catch (e) {
      console.log(e)
      setFirstAiderValue(firstAiderValue);
    }
  }

  return (
    <Row style={{marginTop: 32}}>
      <ContentCard>
        <HeadlineSmall color={Colours.darkGrey}>{t('reports-page.key-responsib.fire-warden')}</HeadlineSmall>
        <TextField onChange={onFireWardenChange}
                   value={`${fireWardenValue}`}
                   disabled={isDisabled}
                   leftText={'reports-page.key-responsib.one-fire-warden'}
                   onBlur={onSaveFireWarden}
                   rightText={'reports-page.key-responsib.staff'} />
      </ContentCard>

      <ContentCard>
        <HeadlineSmall color={Colours.darkGrey}>{t('reports-page.key-responsib.first-aider')}</HeadlineSmall>
        <TextField onChange={onFirstAiderChange}
                   value={`${firstAiderValue}`}
                   disabled={isDisabled}
                   leftText={'reports-page.key-responsib.one-first-aider'}
                   onBlur={onSaveFirstAider}
                   rightText={'reports-page.key-responsib.staff'} />
      </ContentCard>
    </Row>
  )
}

interface Props {
}

const ContentCard = styled<any>(FlatContentCard)<any>`
  width: 450px;
  max-width: 100%;
  margin-right: 24px;
  border: 1px solid ${Colours.lightGrey};
  .textField__input {
    width: 50px;
  }
`

