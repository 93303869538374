import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { selectAllOfficeEntities, selectOffices } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import Select from "react-select";
import Colours from "../atoms/Colours";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import makeAnimated from "react-select/animated";
import { BodySmall } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { findOfficeInOffices, getFullOfficeTitle, sortOffice } from "../../../utils/OfficeHelper";

export const REACT_SELECT_DROPDOWN_STYLES = {
  option: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    backgroundColor: 'white',
    color: Colours.black,
    cursor: 'pointer',
    '&:hover': {
      color: Colours.black,
      backgroundColor: Colours.blue10,
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
    fontSize: '18px',
  }),
  menu: (css: any) => ({
    ...css,
    width: "max-content",
    minWidth: "250px",
    zIndex: 100,
  }),
  placeholder: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: 'hsl(0, 0%, 80%)',
  }),
  noOptionsMessage: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  input: (css: any) => ({
    ...css,
    fontFamily: '"soleil", sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontFamily: '"soleil", sans-serif',
    color: Colours.black,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: Colours.black,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Colours.darkGrey,
      color: 'white',
    },
  }),
};

interface OfficeDropDownItem extends OfficeEntity {
  value: number;
  label: string;
}

const animatedComponents = makeAnimated();

export function OfficeDropDownList(props: Props) {
  const {preSelectedOffice, selectDefaultOffice, onSelect} = props;
  const allOffices = useSelector(selectAllOfficeEntities);
  const rootOffices = useSelector(selectOffices);
  const officesToDisplay = props?.offices ?? rootOffices;
  const [selectedOffice, setSelectedOffice] = useState<OfficeEntity | undefined>(undefined);
  const {t} = useTranslation();

  const dropDownOffice: OfficeDropDownItem[] = useMemo(() => {
    return officesToDisplay.map((o: OfficeEntity) => {
      return {...o, label: getFullOfficeTitle(o, allOffices), value: o.id}
    }).sort(sortOffice);
  }, [officesToDisplay, allOffices]);

  useEffect(() => {
    if (preSelectedOffice && !selectedOffice) {
      const defaultOffice = findOfficeInOffices(officesToDisplay, preSelectedOffice);
      setSelectedOffice(defaultOffice);
      if (defaultOffice) {
        onSelect(defaultOffice);
      }
    }
  }, [officesToDisplay, preSelectedOffice, selectedOffice, onSelect]);

  useEffect(() => {
    if (!selectDefaultOffice || preSelectedOffice) return;
    if (selectedOffice) return;
    const defaultOffice = dropDownOffice.length > 0 ? dropDownOffice[0] : undefined;
    if (defaultOffice) {
      setSelectedOffice(defaultOffice);
      onSelect(defaultOffice);
    }
  }, [dropDownOffice, preSelectedOffice, selectDefaultOffice, onSelect, selectedOffice]);

  const onItemSelected = (item: any) => {
    setSelectedOffice(item);
    props.onSelect(item);
  }

  return (
    <>
      {props.label && <BodySmall className="textField__label" weight={600}>{t(props.label)}</BodySmall>}
      <Select
        options={dropDownOffice}
        onChange={onItemSelected}
        value={selectedOffice}
        styles={REACT_SELECT_DROPDOWN_STYLES}
        closeMenuOnSelect={true}
        components={{ ...animatedComponents, ClearIndicator:() => null, IndicatorSeparator:() => null }}
        isMulti={false}
        isDisabled={props.disabled}
      />
    </>
  )
}

interface Props {
  onSelect: (office: OfficeEntity) => any;
  disabled: boolean;
  label?: string;
  selectDefaultOffice?: boolean;
  preSelectedOffice?: number;
  offices?: OfficeEntity[];
}

