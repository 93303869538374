import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { updateOutlookSyncState } from "../../services/UserService";
import { updateCurrentUser } from "./auth.duck";

export interface OutlookSync {
  loading: boolean;
  success: boolean;
}

export const initialOutlookSync: OutlookSync = {
  loading: false,
  success: false,
}

export const setSync: any = createAsyncThunk(
  'outlookSync/setSync',
  async (state: boolean, thunkAPI) => {
    try {
      await updateOutlookSyncState(state)
      await thunkAPI.dispatch(updateCurrentUser(true));
      return state;
    } catch (err: any) {
      throw err;
    }
  }
);

const outlookSyncSlice = createSlice({
  name: 'outlookSync',
  initialState: initialOutlookSync,
  reducers: {
    reset: () => ({...initialOutlookSync}),
    closeOutlookSyncDialog: (state) => ({
      ...initialOutlookSync
    })
  },
  extraReducers: {
    [setSync.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        success: true,
      }
    },
    [setSync.rejected]: (state) => {
      return {
        ...state,
        loading: false,
        success: false,
      }
    },
    [setSync.pending]: (state) => {
      return {
        ...state,
        loading: true,
        success: false,
      }
    },
  }
});

export default outlookSyncSlice.reducer
export const {
  reset,
  closeOutlookSyncDialog,
} = outlookSyncSlice.actions;

// Selectors
export const selectIsLoading = (state: AppState) => state.outlookSync.loading;
export const selectIsSuccessful = (state: AppState) => state.outlookSync.success;
