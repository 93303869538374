import React from 'react';
import { BodyError } from "../../atoms/fonts/Body";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from "react-i18next";


export function RegisterErrorMessage(props: Props) {
  const {t} = useTranslation();

  if (!props.errorMessage) return null;

  if (props.errorMessage === 'Email already exists') {
    return (
      <Wrapper>
        <BodyError>{t('errors.email-in-use.title')}</BodyError>
        <BodyError><Trans i18nKey={'errors.email-in-use.message'} components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} /></BodyError>
      </Wrapper>
    )
  }

  if (props.errorMessage.includes('The email ') && props.errorMessage.includes(' already exists')) {
    return (
      <Wrapper>
        <BodyError>{props.errorMessage}</BodyError>
        <BodyError>
          <Trans i18nKey={'errors.email-exists.message'}
                 components={[<Link to="/login">{t('errors.email-exists.logging-in')}</Link>, <a href="mailto:hello@team-today.com">hello@team-today.com</a>]} />
        </BodyError>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <BodyError>{t('errors.general.title')}</BodyError>
      <Trans i18nKey={'errors.general.message'}
             components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} />
    </Wrapper>
  )
}

interface Props {
  errorMessage?: string;
}

const Wrapper = styled.div`
`
