import { agent } from './agent';
import { Moment } from 'moment';
import {
  MemberMovements,
  SimpleUserWithLocation,
  UsersWhereaboutsDay, WhereaboutsDayV2
} from '../models/movements.models';
import {
  CompanyMovementResponse,
  TeamMovementResponse,
  UpdateWhereaboutsResponse,
  WhereaboutsTeamViewResponse
} from '../models/api.models';
import { Tag } from "../models/tags.models";
import { DATE_FORMAT } from "../utils/DateUtils";

export const makeWhereaboutsScheduleRequest = async (usersWhereabouts: UsersWhereaboutsDay[], startDate: String, numberOfWeeks: number, userId: string) => {
  return agent.requests.post('/api/whereabouts/schedule', {
    whereabouts: usersWhereabouts, startDate, numberOfWeeks, userId
  });
}

const getCompanyWeeklyMovements = async (companyId: string | null, calendarDate: Moment): Promise<CompanyMovementResponse> => {
  if (companyId === null) {
    throw new Error();
  }
  const response: CompanyMovementResponse = (await agent.requests.get(`/api/whereabouts/company?cid=${companyId}&week=${calendarDate.format(DATE_FORMAT)}`));
  return {
    ...response,
  }
}

export async function requestWhereaboutsV2(calendarDate: Moment): Promise<WhereaboutsTeamViewResponse> {
  let qs = `?week=${calendarDate.format(DATE_FORMAT)}`;
  return await (agent.requests.get(`/api/v3/whereabouts` + qs));
}

export async function requestLineReportsWhereabouts(calendarDateString: string): Promise<MemberMovements[]> {
  let qs = `?week=${calendarDateString}`;
  return await (agent.requests.get(`/api/v2/whereabouts/line-reports` + qs));
}

export const requestTeamWeeklyMovements = async (teamId: string | null, calendarDate: Moment): Promise<TeamMovementResponse> => {
  if (teamId === null) {
    throw new Error();
  }
  const response: TeamMovementResponse = (await agent.requests.get(`/api/whereabouts/team?teamId=${teamId}&week=${calendarDate.format(DATE_FORMAT)}`, true));
  return {
    ...response,
  }
}

export const requestUsersWeeklyMovements = async (calendarDate: Moment, userId: string): Promise<MemberMovements> => {
  const weekCommencing = calendarDate.clone().isoWeekday('monday');
  return (await agent.requests.get(`/api/whereabouts/user?week=${weekCommencing.format(DATE_FORMAT)}&userId=${userId}`));
}

export const getUsersInOfficeOn = async (calendarDate: Moment, officeId: number): Promise<SimpleUserWithLocation[]> => {
  return (await agent.requests.get(`/api/whereabouts/office?date=${calendarDate.format(DATE_FORMAT)}&office=${officeId}`));
}

export function getMovementsByTags(calendarDate: Moment, tags: Tag[]): Promise<MemberMovements[]> {
  return agent.requests.get(`/api/whereabouts/tag?week=${calendarDate.format(DATE_FORMAT)}&ids=${tags.map(at => at.id).join(',')}`);
}

export function acceptAndSyncMovements(date: Moment): Promise<void> {
  return agent.requests.post(`/api/whereabouts/resync?date=${date.format(DATE_FORMAT)}`);
}

export function acceptAndSyncAllMovements(): Promise<void> {
  return agent.requests.post(`/api/whereabouts/resync/all`);
}

export function sendWhereaboutsReminder(userId: string, date: Moment): Promise<void> {
  return agent.requests.post('/api/whereabouts/reminder', {userIds: [userId], weekCommencingDate: date.format(DATE_FORMAT)})
}

export function updateWhereaboutsDay(whereaboutsDay: WhereaboutsDayV2): Promise<UpdateWhereaboutsResponse> {
  return agent.requests.put('/api/v3/whereabouts', whereaboutsDay)
}

export const Movements = {
  getCompanyWeeklyMovements,
}
