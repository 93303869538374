import { naturalComparator } from "../utils/ArrayUtils";

export interface TeamEntity {
  value: TeamEntity;
  companyId: string;
  id: string;
  name: string;
  officeIds?: number[];
}

export interface TeamWithCount {
  id: string;
  name: string;
  memberCount: number;
  restricted?: boolean;
  officeIds?: number[];
  departmentIds?: number[];
}

export interface TeamWithCountAndRestricted extends TeamWithCount {
  restricted?: boolean;
}

export interface TeamInfo {
  team: TeamEntity;
  members: number;
}

export enum GroupType {
  Team, WorkGroup
}

export function sortTeam(a: TeamWithCountAndRestricted, b: TeamWithCountAndRestricted) {
  if (a.restricted === b.restricted) {
    return naturalComparator(a, b, 'name');
  }
  return !a.restricted ? -1 : 1;
}
