import { createSlice } from '@reduxjs/toolkit';
import { AppState } from "../../../../store/state/app.state";
import { Department } from "./departments.models";


export interface DepartmentsState {
  departments: Department[]
  loading: boolean;
}

export const initialDepartmentsState: DepartmentsState = {
  departments: [],
  loading: false,
};

// export const cancelCarParkBooking: any = createAsyncThunk(
//   'departments/cancelCarParkBooking',
//   async (params: {userId: string, dateString: string, carParkingSpaceId: number, carParkPlanId: number}, thunkAPI) => {
//     await requestCancelCarParkBooking(params.userId, params.dateString, params.carParkingSpaceId);
//     thunkAPI.dispatch(getCarParkBookings({carParkPlanId: params.carParkPlanId, dateString: params.dateString}));
//   }
// );


const departmentsSlice = createSlice({
  name: 'departments',
  initialState: initialDepartmentsState,
  reducers: {
    resetDepartments: (state) => (initialDepartmentsState),
    setDepartments: (state, action) => ({...state, departments: action.payload}),
  },
  extraReducers: {
    // [getCarParkDetails.pending]: (state) => ({...state, carParkDetails: [], loading: true}),
    // [getCarParkDetails.failed]: (state) => ({...state, carParkDetails: [], loading: false}),
    // [getCarParkDetails.fulfilled]: (state, action) => ({...state, carParkDetails: action.payload.carParkDetails, loading: false}),
  },
});



export default departmentsSlice.reducer;
export const {
  resetDepartments, setDepartments,
} = departmentsSlice.actions;


// Selectors
export const selectAllDepartments = (state: AppState) => state.departments.departments;
