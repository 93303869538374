import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeadlineLarge } from '../../../../UI/atoms/fonts/Headline';
import IconButton from '../../../../UI/molecules/icon-button/IconButton';
import { Permission } from '../../../../../models/user.models';
import AccessRequired from '../../components/AccessRequired';
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { setIsDeleteCompanyDialogOpen } from '../../../../dialogs/delete-company-dialog/deleteCompanyDialog.duck';
import { canUserUpdateCompanySettings } from "../../../../../utils/AccessControl";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { reset, selectEditCompanyState } from "./editCompany.duck";
import { ConfigurationSection } from "../../StyleComponents";
import { DomainBasedSignUpSetting } from "../../../../UI/organisms/settings/DomainBasedSignUpSetting";
import { LineManagementSettings } from "../../../../UI/organisms/settings/LineManagementSettings";
import { SevenDayWorkingWeekSettings } from "../../../../UI/organisms/settings/SevenDayWorkingWeekSettings";
import { TenantBasedSignUpSetting } from "../../../../UI/organisms/settings/TenantBasedSignUpSetting";
import { WeeklyNotesSetting } from "../../../../UI/organisms/settings/WeeklyNotesSetting";
import { useTranslation } from "react-i18next";
import {
  DisableUpdatingPreviousWhereaboutsSetting
} from "../../../../UI/organisms/settings/DisableUpdatingPreviousWhereaboutsSetting";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { EditingFutureWhereaboutsSettings } from "../../../../UI/organisms/settings/EditingFutureWhereaboutsSettings";
import { CompanyNameSetting } from "../../../../UI/organisms/settings/CompanyNameSetting";
import { WhereaboutsIconPackSetting } from "../../../../UI/organisms/settings/WhereaboutsIconPackSetting";
import { AzureScimIntegrationSettingMessage } from "../../../../UI/organisms/settings/AzureScimIntegrationMessage";
import Colours from "../../../../UI/atoms/Colours";
import { BuildVersion } from "../../../../UI/organisms/settings/BuildVersion";
import { VisitorBookingSettings } from "../../../../UI/organisms/settings/VisitorBookingSettings";
import { PrivateGroupsSetting } from "../../../../UI/organisms/settings/PrivateGroupsSetting";
import { RestrictTeamManagement } from "../../../../UI/organisms/settings/RestrictTeamManagement";

export default function CompanySettingsPage() {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const state = useSelector(selectEditCompanyState);
  const editable = user?.role === Permission.CompanyAdmin;
  const title = state.companyEnabled ? 'company-settings-page.edit-company' : 'company-settings-page.edit-global';
  const isEditable = useMemo(() => canUserUpdateCompanySettings(user), [user]);
  const {t} = useTranslation();

  const company = user?.companyEntity;

  const openDeleteDialog = () => dispatch(setIsDeleteCompanyDialogOpen({isOpen: true, company: state.companyName}));

  useEffect(() => {
    dispatch(reset())
    return () => dispatch(reset());
  }, [dispatch]);

  if (!company) {
    return null // loading
  }

  return (
    <React.Fragment>
      <GoBackButton/>
      <HeadlineLarge className="management__title">{t(title)}</HeadlineLarge>

      <AccessRequired visible={!editable} companyAdminsRequired={true}/>

      {isEditable && <>
        <ConfigurationSection>
          <CompanyNameSetting editable={editable} />
        </ConfigurationSection>

        <TenantBasedSignUpSetting />

        <AzureScimIntegrationSettingMessage style={{marginTop: 32, marginBottom: 32, paddingBottom: 16, borderBottom: '1px solid ' + Colours.lightGrey}} />

        <DomainBasedSignUpSetting settingsView={true} />

        <SevenDayWorkingWeekSettings />

        <LineManagementSettings />

        <WeeklyNotesSetting />

        <DisableUpdatingPreviousWhereaboutsSetting />

        <EditingFutureWhereaboutsSettings />

        <VisitorBookingSettings />

        <WhereaboutsIconPackSetting />

        <PrivateGroupsSetting />

        <RestrictTeamManagement />

        <BuildVersion />

        <div className="management__row management__loneControl" style={{marginBottom: 32}}>
          <IconButton className="editCompanyPage__deleteCompanyBtn"
                      disabled={!editable}
                      icon={IconTypes.Bin}
                      text="button.delete-account"
                      color="darkGrey"
                      onClick={() => openDeleteDialog()}/>
        </div>
      </>}

    </React.Fragment>
  );
}
