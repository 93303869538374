import React, { useEffect, useState } from 'react';
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { SpaceBetweenRow } from "../../StyleComponents";
import { Switch } from "../../../../UI/atoms/Switch";
import Colours from "../../../../UI/atoms/Colours";
import styled from "styled-components/macro";
import { Accordian } from "../../../../../animations/AnimationVariants";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../../../../store/ducks/auth.duck";
import TextField from "../../../../UI/atoms/TextField";
import { UpdateUserField, updateUserField } from "../../../../../services/UserService";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import Icon, { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { getCar } from "../../../../../services/CarParkService";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { Center } from '../../../../UI/atoms/StructuralLayout';

interface FieldSaving {
  value: string;
  loading: boolean;
  saved: boolean;
}

export function UsersCarParkingDetails(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [carParkingEnabled, setCarParkingEnabled] = useState(false);
  const [carMake, setCarMake] = useState<FieldSaving>({value: '', loading: false, saved: false});
  const [carRegistration, setCarRegistration] = useState<FieldSaving>({value: '', loading: false, saved: false});
  const [carColour, setCarColour] = useState<FieldSaving>({value: '', loading: false, saved: false});

  useEffect(() => {
    setCarParkingEnabled(!!current?.carParkingEnabled);
  }, [current]);

  useEffect(() => {
    const loadCar = async () => {
      try {
        if (current && !carMake.value && !carColour.value && !carRegistration.value) {
          setLoading(true);
          const car = await getCar(current.id);
          setCarColour({...carColour, value: car.colour});
          setCarRegistration({...carRegistration, value: car.registration});
          setCarMake({...carMake, value: car.make});
        }
      } finally {
        setLoading(false);
      }
    }
    if (!isRequested && carParkingEnabled) {
      setIsRequested(true);
      loadCar();
    }
  }, [carColour, carMake, carParkingEnabled, carRegistration, current, isRequested])

  if (!current) return null;

  const saveRegistration = async () => {
    if (!carRegistration.value || carRegistration.value.length > 15) return;
    try {
      setCarRegistration({...carRegistration, loading: true, saved: false});
      await updateUserField(current.id, UpdateUserField.carRegistration, carRegistration.value);

    } finally {
      setCarRegistration({...carRegistration, loading: false, saved: true});
    }
  }

  const saveMake = async () => {
    if (!carMake.value || carMake.value.length > 30) return;
    try {
      setCarMake({...carMake, loading: true, saved: false});
      await updateUserField(current.id, UpdateUserField.carMake, carMake.value);

    } finally {
      setCarMake({...carMake, loading: false, saved: true});
    }
  }

  const saveColour = async () => {
    if (!carColour.value || carColour.value.length > 30) return;
    try {
      setCarColour({...carColour, loading: true, saved: false});
      await updateUserField(current.id, UpdateUserField.carColour, carColour.value);

    } finally {
      setCarColour({...carColour, loading: false, saved: true});
    }
  }

  const onCarColourChange = (val: string) => {
    setCarColour({...carColour, value: val, saved: false})
  }
  const onCarRegistrationChange = (val: string) => {
    setCarRegistration({...carRegistration, value: val, saved: false})
  }
  const onCarMakeChange = (val: string) => {
    setCarMake({...carMake, value: val, saved: false})
  }
  const onCarParkingEnabledChange = async (val: boolean) => {
    await updateUserField(current.id, UpdateUserField.parkingEnabled, val);
    dispatch(setUser({
      ...current,
      carParkingEnabled: val,
    }));
    setCarParkingEnabled(val);
  }

  return (
    <Container>
      <SpaceBetweenRow>
        <BodyLarge colour={Colours.blue}>{t('personal-perf.require-space-title')}</BodyLarge>
        <Switch value={carParkingEnabled} onChange={onCarParkingEnabledChange} />
      </SpaceBetweenRow>
      <BodyRegular style={{marginBottom: 16}}>{t('personal-perf.require-space-description')}</BodyRegular>

      <Accordian isOpen={carParkingEnabled}>

        {loading ? <Center><LoadingSpinner hideBorder={true} hideText={true} /></Center> : <>
          <FieldRow>
            <FieldContainer>
              <TextField onChange={onCarRegistrationChange}
                         onBlur={saveRegistration}
                         disabled={carRegistration.loading}
                         fullWidth={true}
                         value={carRegistration.value}
                         label={'personal-perf.veh-registration'} />
            </FieldContainer>
            {carRegistration.loading && <SimpleSpinner size={24} />}
            {!carRegistration.loading && carRegistration.saved && <Icon icon={IconTypes.Tick}
                                                                        color={'greenFill'}
                                                                        circle={true} />}
          </FieldRow>
          <FieldRow>
            <FieldContainer>
              <TextField onChange={onCarColourChange}
                         onBlur={saveColour}
                         fullWidth={true}
                         disabled={carColour.loading}
                         value={carColour.value}
                         label={'personal-perf.veh-colour'} />
            </FieldContainer>
            {carColour.loading && <SimpleSpinner size={24} />}
            {!carColour.loading && carColour.saved && <Icon icon={IconTypes.Tick}
                                                            color={'greenFill'}
                                                            circle={true} />}
          </FieldRow>
          <FieldRow>
            <FieldContainer>
              <TextField onChange={onCarMakeChange}
                         onBlur={saveMake}
                         disabled={carMake.loading}
                         fullWidth={true}
                         value={carMake.value}
                         label={'personal-perf.veh-make'} />
            </FieldContainer>
            {carMake.loading && <SimpleSpinner size={24} />}
            {!carMake.loading && carMake.saved && <Icon icon={IconTypes.Tick}
                                                        color={'greenFill'}
                                                        circle={true} />}
          </FieldRow>
        </>}

      </Accordian>
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  margin-bottom: 48px;
  padding-bottom: 48px;
  border-bottom: 1px solid ${Colours.lightGrey};
`

const FieldContainer = styled.div`
  flex: 1;
`

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  .simple-spinner, .icon {
    align-self: flex-end !important;
    margin-bottom: 30px;
    margin-left: 8px;
  }
`
