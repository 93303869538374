import { useSelector } from "react-redux";
import { useMemo } from "react";
import { User } from "../../models/user.models";
import { selectCurrentUsersLineReport } from "../../store/ducks/companyStructure.duck";

export function CheckLineReport(currentUser?: User, userIdToCheck?: string) {
  const lineReports = useSelector(selectCurrentUsersLineReport);

  return useMemo(() => {
    if (currentUser && userIdToCheck && currentUser.companyEntity.lineManagementEnabled && lineReports.length > 0) {
      return !!lineReports.find(lr => lr.userId === userIdToCheck);
    }
    return false;
  }, [currentUser, lineReports, userIdToCheck])
}
