import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import {HttpError} from '../models/error.models';
import {apiUrl} from "./EnvironmentVariables";
import {AuthType, resetAllLoginStorages} from "../utils/AuthUtils";
import {TrackJS} from 'trackjs';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = apiUrl;


const responseBody = res => {
    if (!!res.headers.accessToken && localStorage.getItem('accessToken') !== res.headers.accessToken) {
        localStorage.setItem('accessToken', res.headers.accessToken)
    }
    if (!!res.headers.idToken && localStorage.getItem('idToken') !== res.headers.idToken) {
        localStorage.setItem('idToken', res.headers.idToken)
    }
    return res.body;
};

const tokenPlugin = req => {
    const authType = localStorage.getItem('type');
    if (authType === AuthType.Microsoft) {
        const accessToken = localStorage.getItem('accessToken');
        const idToken = localStorage.getItem('authToken') || localStorage.getItem('idToken');

        if (accessToken) {
            req.set('Authorization', 'Bearer ' + idToken);
            req.set('AccessToken', accessToken);
            req.set('x-auth-type', AuthType.Microsoft);
        }
    } else if (authType === AuthType.Google) {
        const idToken = localStorage.getItem('idToken');
        const accessToken = localStorage.getItem('accessToken');

        if (idToken) {
            req.set('idToken', idToken);
            req.set('AccessToken', accessToken);
            req.set('Authorization', 'JWT ' + accessToken);
            req.set('x-auth-type', AuthType.Google);
        }
    } else {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            req.set('AccessToken', accessToken);
        }

        const idToken = localStorage.getItem('idToken');
        if (idToken) {
            req.set('idToken', idToken);
            req.set('Authorization', 'JWT ' + idToken);
            req.set('x-auth-type', AuthType.Standard);
        }
    }
}

function processError(err, passive) {
    if (err && err.response) {
        if (err.response.statusCode === 401 && !passive) {
            // localStorage.removeItem('type');
            localStorage.removeItem('accessToken')
            localStorage.removeItem('idToken');
            localStorage.removeItem('tokenExpires');
            // localStorage.removeItem('redirect');
            // TODO Check for token expired error
            window.location.href = `/login`;
            return;
        }
        if (err.response.statusCode === 404 && !passive) {
            window.location.href = '/404';
        }
    }
    if (err && err.message) {
        if (err.message.includes('User not registered')) {
            resetAllLoginStorages();
            if (!passive) {
                window.location.href = `/login?not=reg&t=${Date.now()}`;
            }
        }
        const parsedError = JSON.parse(JSON.stringify(err));
        if (parsedError && parsedError.response) {
            try {
                throw new HttpError(JSON.parse(parsedError.response.text).message, parsedError.response.statusCode);
            } catch (ex) {
                if (parsedError.response.message) {
                    throw new Error(parsedError.response.message)
                } else if (parsedError.response.text) {
                    throw new Error(parsedError.response.text)
                } else if (parsedError.message) {
                    throw new Error(parsedError.message)
                } else if (parsedError.text) {
                    throw new Error(parsedError.text)
                } else {
                    TrackJS.track(err);
                    throw new Error('Unable to get error from response');
                }
            }
        } else {
            TrackJS.track(err);
            throw err;
        }
    } else {
        TrackJS.track(err);
        throw err;
    }
}

const requests = {
    del: (url, passive = false) =>
        superagent.del(`${API_ROOT}${url}`, passive).use(tokenPlugin)
            .then(responseBody)
            .catch(err => processError(err)),
    get: (url, passive = false, skipErrorProcess = false) =>
        superagent.get(`${API_ROOT}${url}`).use(tokenPlugin)
            .then(responseBody)
            .catch(err => {
                if (skipErrorProcess) {
                    throw err;
                } else {
                    processError(err, passive);
                }
            }),
    put: (url, body, passive = false) =>
        superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin)
            .then(responseBody)
            .catch(err => processError(err, passive)),
    post: (url, body, passive = false) => superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin)
            .then(responseBody)
            .catch(err => processError(err, passive))
};

export function getErrorMessage(err, fallback) {
    try {
        return JSON.parse(err.message).message;
    } catch (e) {
        return fallback;
    }
}

export const agent = {
    requests,
};
