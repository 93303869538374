import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BodyLarge } from '../../../UI/atoms/fonts/Body';
import { next, selectOnboardedTeam } from '../../../../store/ducks/onboarding.duck';
import { OnboardingContainer } from '../../../UI/molecules/OnboardingContainer';
import DoThisLaterButtonRow from '../../../UI/molecules/DoThisLaterButtonRow';
import { AddTeamMember } from "../../../UI/organisms/add-team-member/AddTeamMember";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import { HeadlineMedium } from "../../../UI/atoms/fonts/Headline";

export default function OnboardTeamMember() {
  const dispatch = useDispatch();
  const onboardedTeam = useSelector(selectOnboardedTeam);
  const currentUser = useSelector(selectCurrentUser);
  const teamToAddTo = useMemo(() => onboardedTeam ?? currentUser?.teamEntity, [onboardedTeam, currentUser]);
  const done = () => dispatch(next());
  const {t} = useTranslation();

  return (
    <OnboardingContainer dataTest="onbarding-teamMember">
      <img className={'onboardingTeamMember__image'} src={'/assets/images/onboarding/shapes-spread.svg'} alt={""} />
      <HeadlineMedium style={{marginBottom: 12}}>{t('onboarding.add-user.title')}</HeadlineMedium>
      <BodyLarge style={{marginBottom: 24}}>{t('onboarding.add-user.message')}</BodyLarge>
      <AddTeamMember onDone={done} hideImages={true} team={teamToAddTo} hideSuccessMessage={true} />
      <div className="onboarding__teamMemberControls">
        <DoThisLaterButtonRow dataTest="onboardTeamMemberSkip" onClick={done}/>
      </div>
    </OnboardingContainer>
  )
}

