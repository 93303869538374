import { useEffect } from 'react';
import { getParameterByName } from "../utils/UrlUtils";
import { selectActiveDay, setActiveDay } from "../store/ducks/editMovements.duck";
import moment from "moment";
import { getLinkIdFromSubEntityId, isInTeams } from "../utils/TeamsUtils";
import { app } from "@microsoft/teams-js";
import { ActiveCompanyView, setActiveCompanyView } from "../store/ducks/companyMovements.duck";
import { useDispatch, useSelector } from "react-redux";
import { selectCalendarDate } from "../store/ducks/dashboard.duck";

export function WithCompanyViewSelector() {
  const dispatch = useDispatch();
  const currentDate = useSelector(selectCalendarDate);
  const activeDay = useSelector(selectActiveDay);

  useEffect(() => {
    const dateParam = getParameterByName('date', window.location.href);
    if (dateParam) {
      dispatch(setActiveDay(moment(dateParam)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isInTeams()) {
      app.getContext().then((ctx: app.Context) => {
        const linkId = getLinkIdFromSubEntityId(ctx.page.subPageId);
        const storedLink = localStorage.getItem('deepLinkId');

        if (!!storedLink && !!linkId && storedLink === linkId) {
          return;
        } else if (linkId) {
          localStorage.setItem('deepLinkId', linkId);
        }

        if ((ctx.page.subPageId || '').includes('floor')) {
          dispatch(setActiveCompanyView(ActiveCompanyView.FloorPlan));
        }
      });
    } else {
      const view = getParameterByName('view', window.location.href) as ActiveCompanyView;
      if (view === ActiveCompanyView.Whereabouts || view === ActiveCompanyView.FloorPlan || view === ActiveCompanyView.OfficeView || view === ActiveCompanyView.Tags) {
        dispatch(setActiveCompanyView(view));
      }
    }
  }, [dispatch, currentDate, activeDay]);
}
