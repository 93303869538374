import { createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../store/state/app.state";
import { CompanyEntity } from "../../../models/company.models";

export interface DeleteCompanyDialogState {
  isOpen: boolean;
  company?: CompanyEntity;
}

export const initialDeleteCompanyDialogState: DeleteCompanyDialogState = {
  isOpen: false,
  company: undefined,
}

const slice = createSlice({
  name: 'deleteCompanyDialog',
  initialState: initialDeleteCompanyDialogState,
  reducers: {
    setIsDeleteCompanyDialogOpen: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
  },
  extraReducers: {}
})

export default slice.reducer;
export const {
  setIsDeleteCompanyDialogOpen,
} = slice.actions;

// Selectors
export const selectIsDeleteCompanyDialogOpen = (state: AppState) => state.deleteCompanyDialog.isOpen;
export const selectTeamToDeleteTeam = (state: AppState) => state.deleteCompanyDialog.company;
