import React, { useMemo } from 'react';
import { BodyError, BodyRegular } from '../../UI/atoms/fonts/Body';
import { enrichError } from "../../../utils/error-helper";
import { LinkButton } from "../../UI/atoms/buttons/LinkButton";
import { HorizontalSpacer } from "../../UI/atoms/VerticalSpacer";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  loginMethod: string
  errorCode: string;
}

export default function LoginErrorMessage(props: Props) {
  const errorMessage = useMemo(() => enrichError(props.errorCode), [props.errorCode]);
  const {t} = useTranslation();

  if (props.errorCode === 'reg') {
    return (<>
      <BodyError>{t('errors.no-account-registered.title')}</BodyError>
      <BodyRegular weight={400}>
        <Trans i18nKey={'errors.no-account-registered.message'}
               components={[<a href="mailto:hello@team-today.com">hello@team-today.com</a>]} />
      </BodyRegular>
      <LinkButton text={"errors.no-account-registered.button"} link={"/register"} fullWidth={true} size={"large"} type={"primary"} style={{marginBottom: 24}} />
      <HorizontalSpacer style={{marginBottom: 24}} />
    </>)
  } else {
    return (
      <>
        <BodyError>{errorMessage.title}</BodyError>
        <BodyError weight={400}>{errorMessage.description}</BodyError>
      </>
    )
  }
}
