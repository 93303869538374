import { Moment } from "moment";
import { agent } from "./agent";
import { OfficeEntity } from "./AdvanceHotDeskingService";
import { Period } from "../models/movements.models";


export async function getOfficeAreaCapacity(areaId: number, dateString: Moment): Promise<CapacityResponse> {
  return await agent.requests.get(`/api/office/area/${areaId}/capacity/date/${dateString.format('YYYY-MM-DD')}`);
}

export async function getOfficeWithCapacity(office: OfficeEntity, date: Moment): Promise<OfficeWithCapacity> {
  const capacity = await agent.requests.get(`/api/office/capacity/${office.id}/date/${date.format('YYYY-MM-DD')}`);
  return {
    office: office,
    capacity: capacity,
  }
}

export async function getOfficeCapacity(officeId: number, date: Moment): Promise<CapacityResponse> {
  return await agent.requests.get(`/api/office/capacity/${officeId}/date/${date.format('YYYY-MM-DD')}`);
}

export async function getAllOfficeCapacity(officeId: number, date: Moment): Promise<CapacityResponse[]> {
  return await agent.requests.get(`/api/office/capacity/all/${officeId}/date/${date.format('YYYY-MM-DD')}`);
}

export async function getAllOfficeCapacities(date: Moment): Promise<CapacityResponse[]> {
  return await agent.requests.get(`/api/office/capacity/all/date/${date.format('YYYY-MM-DD')}`);
}

export interface CarParkBooking {
  firstName: string;
  lastName: string;
  registration: string;
  period: Period;
}

export async function getCarParkBookings(officeId: number, dateString: string): Promise<CarParkBooking[]> {
  return await agent.requests.get(`/api/car-park/bookings?officeId=${officeId}&date=${dateString}`);
}

export interface CapacityResponse {
  capacity: number;
  amUsed: number;
  pmUsed: number;
  officeId: number;
  date: string;
  carParkingUsedAm?: number;
  carParkingUsedPm?: number;
  carParkingCapacity?: number;
}

export interface OfficeUsage extends CapacityResponse {
  loading: boolean;
  hasLoaded: boolean;
  failed?: boolean;
}

export function emptyOfficeUsage(officeId: number, date: string): OfficeUsage {
  return {
    officeId: officeId,
    date: date,
    capacity: 0,
    amUsed: 0,
    pmUsed: 0,
    loading: false,
    hasLoaded: false,
  }
}

export interface OfficeWithCapacity {
  office: OfficeEntity;
  capacity: CapacityResponse;
}
