import React from 'react';
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import styled from "styled-components/macro";
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import Colours from "../../../../UI/atoms/Colours";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../../services/WhereaboutOptions";

export function ManageOfficeLocationsOption(props: Props) {
  const {t} = useTranslation();
  const iconPack = useSelector(selectIconPack)
  return (
    <Row>
      <OfficeIcon src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt={''} />
      <Column>
        <BodyLarge colour={Colours.blue}>{t('settings.manage-office-locations-title')}</BodyLarge>
        <BodyRegular style={{marginBottom: 24}}>{t('settings.manage-office-locations-description')}</BodyRegular>
        <OutlineButton link={'/manage/hot-desking'} click={() => {}} text={'button.setup-multiple-offices'} />
      </Column>
    </Row>
  )
}

interface Props {
}

const OfficeIcon = styled.img`
  margin-right: 36px;
  width: 52px;
  height: 52px;
`
