import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { UserAvatarAndName } from "./UserAvatarAndName";
import { BodyRegular, BodySmall } from "../atoms/fonts/Body";

export function UserInfoBox(props: Props) {
  const {firstName, lastName, userId, teamName} = props;

  if (!firstName || !lastName || !userId) {
    return null;
  }

  return (
    <Wrapper style={props?.style ?? {}}>
      <UserAvatarAndName hideName={true} firstName={firstName} lastName={lastName} colour={'blue'} />
      <DetailsList>
        <BodyRegular>{`${firstName} ${lastName}`}</BodyRegular>
        {teamName && <BodySmall colour={Colours.darkGrey}>{teamName}</BodySmall>}
        {!!props.children && props.children}
      </DetailsList>
    </Wrapper>
  )
}

interface Props {
  firstName?: string;
  lastName?: string;
  teamName?: string;
  userId?: string;
  children?: any;
  style?: any;
}

const Wrapper = styled.div`
  position: relative;
  justify-self: center;
  align-self: center;
  border: solid 1px ${Colours.blue};
  display: flex;
  padding: 24px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 400px;
  .userAvatarAndName {
    align-items: unset;
  }
`

const DetailsList = styled.div`
  padding-left: 12px;
  flex: 1;
`
