import React from "react";
import { Link } from "react-router-dom";

interface Props {
  link: string;
  children: any;
  style?: any;
}

export const StyledLink = function (props: Props) {
  return <Link style={{textDecoration: 'none', ...(props.style ?? {})}} to={props.link}>{props.children}</Link>
}
