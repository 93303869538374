import React, { useEffect, useMemo, useState } from 'react';
import { Column } from "../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import moment, { Moment } from "moment";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { MountedContentCard } from "../../UI/atoms/MountedContentCard";
import { Button, ButtonProps } from "../../UI/atoms/buttons/Button";
import IconButton, { IconButtonProps } from "../../UI/molecules/icon-button/IconButton";
import { tablet } from "../../UI/atoms/MediaQueries";
import { useParams } from "react-router";
import {
  bookHoliday,
  deleteHolidayBooking,
  loadUsersHolidays,
  selectHolidayUserInfo,
  selectIsLoading,
  selectUsersHolidayGroupings
} from "../../../store/ducks/holidays-v2.duck";
import Layout from '../../UI/organisms/layout/Layout';
import { isDateInRange, PRETTY_SHORT_DATE_FORMAT } from "../../../utils/DateUtils";
import { HolidayGrouping } from "./models/Holidays.model";
import { HolidayDateRangePicker } from "./components/HolidayDateRangePicker";
import { HolidayBreakdownSummary } from "./components/HolidayBreakdownSummary";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Period } from "../../../models/movements.models";
import { useTranslation } from "react-i18next";
import { BodyRegular } from "../../UI/atoms/fonts/Body";
import { UserInfoBox } from "../../UI/molecules/UserInfoBox";
import { selectConfig } from "../../../store/ducks/config.duck";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { IconTypes } from "../../UI/atoms/icon/Icon";

export function HolidayCreateEditPage() {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const [fromOriginalDate, setFromOriginalDate] = useState<Moment | undefined>(undefined);
  const [toOriginalDate, setToOriginalDate] = useState<Moment | undefined>(undefined);
  const [fromDate, setFromDate] = useState<Moment | undefined>(undefined);
  const [toDate, setToDate] = useState<Moment | undefined>(undefined);
  const [startDatePeriod, setStartDatePeriod] = useState(Period.AllDay);
  const [endDatePeriod, setEndDatePeriod] = useState(Period.AllDay);
  const {t} = useTranslation();
  const bookedHolidays = useSelector(selectUsersHolidayGroupings);
  const sortedBookedHolidays = useMemo(() => [...bookedHolidays].sort((a, b) => moment(a.fromDate) < moment(b.fromDate) ? -1 : 1), [bookedHolidays]);
  const isLoading = useSelector(selectIsLoading);
  const holidayUserInfo = useSelector(selectHolidayUserInfo);
  const config = useSelector(selectConfig);
  const currentUser = useSelector(selectCurrentUser);
  const currentUserId = currentUser?.id;
  const userIdFromUrl = params?.user ?? currentUserId;

  const dateParam: Moment | undefined = useMemo(() => params.date ? moment(params.date) : undefined, [params])

  useEffect(() => {
    if (userIdFromUrl) {
      dispatch(loadUsersHolidays({holidayYear: dateParam?.clone(), userId: userIdFromUrl}));
    }
  }, [userIdFromUrl, dateParam, dispatch]);

  const editingHolidayGroup: HolidayGrouping | undefined = useMemo(() => {
    if (dateParam) {
      const holidayToEdit = sortedBookedHolidays.filter(bh => isDateInRange(dateParam, moment(bh.fromDate), moment(bh.toDate)));
      if (holidayToEdit.length > 1) {
        console.error('Found more than one holiday within date range', holidayToEdit);
      }
      if (holidayToEdit.length > 0) {
        const selectedHoliday = holidayToEdit[0];
        const fromDate = moment(selectedHoliday.fromDate);
        const toDate = moment(selectedHoliday.toDate)
        setFromDate(fromDate);
        setFromOriginalDate(fromDate);
        setToDate(toDate);
        setToOriginalDate(toDate);

        if (selectedHoliday.holidayBookingList.length > 0) {
          const first = selectedHoliday.holidayBookingList[0];
          const last = selectedHoliday.holidayBookingList[selectedHoliday.holidayBookingList.length - 1];
          setStartDatePeriod(first.am && first.pm ? Period.AllDay : (first.am ? Period.AM : Period.PM));
          setEndDatePeriod(last.am && last.pm ? Period.AllDay : (last.am ? Period.AM : Period.PM));
        }
        return selectedHoliday;
      }
    }
    return undefined;
  }, [dateParam, sortedBookedHolidays]);

  const isEditing = useMemo(() => !!editingHolidayGroup, [editingHolidayGroup]);

  const onDatesSelected = (startDatePeriod: Period, endDatePeriod: Period, startDate?: Moment, endDate?: Moment) => {
    setStartDatePeriod(startDatePeriod);
    setEndDatePeriod(endDatePeriod);

    if (startDate) setFromDate(moment(startDate));
    else setFromDate(undefined);

    if (endDate) setToDate(moment(endDate));
    else setToDate(undefined);
  }

  const onBookHolidayClick = () => {
    if (!holidayUserInfo.userId) {
      dispatch(failureNotification('No user found to book holiday for.'));
      return;
    }
    dispatch(bookHoliday({
      fromDate: fromDate,
      fromDatePeriod: startDatePeriod,
      toDate: toDate,
      toDatePeriod: endDatePeriod,
      userId: holidayUserInfo.userId,
      originalFromDate: fromOriginalDate,
      originalToDate: toOriginalDate,
    }));
  }

  const deleteHolidayClicked = () => {
    if (!holidayUserInfo.userId) {
      dispatch(failureNotification('No user found to book holiday for.'));
      return;
    }
    dispatch(deleteHolidayBooking({
      fromDate: fromDate,
      fromDatePeriod: startDatePeriod,
      toDate: toDate,
      toDatePeriod: endDatePeriod,
      userId: holidayUserInfo.userId
    }));
  }

  return (
    <Layout>
      {isLoading ? <LoadingSpinner fullScreen={true} /> :
        <MountedContentCardWrapper centre={true}>
          <GoBackButton/>
          <Column style={{position: 'relative', marginBottom: 8}}>
            <HeadlineLarge style={{marginBottom: 12, marginLeft: 'auto', marginRight: 'auto'}}>{t(isEditing ? 'holidays.edit-holiday-title' : 'holidays.add-holiday-title')}</HeadlineLarge>
            <BodyRegular style={{marginBottom: 24, marginLeft: 'auto', marginRight: 'auto'}}>{t('holidays.holiday-year-span', {from: config.getHolidayYearStartDate(dateParam?.clone())?.format(PRETTY_SHORT_DATE_FORMAT), to: config.getHolidayYearEndDate(dateParam?.clone())?.format(PRETTY_SHORT_DATE_FORMAT)})}</BodyRegular>

            <UserInfoBox firstName={holidayUserInfo.firstName}
                         lastName={holidayUserInfo.lastName}
                         teamName={holidayUserInfo.teamName}
                         userId={holidayUserInfo.userId} />

            <HolidayDateRangePicker fromDate={fromDate}
                                    toDate={toDate}
                                    disabled={isEditing}
                                    fromDatePeriod={startDatePeriod}
                                    toDatePeriod={endDatePeriod}
                                    onDatesSelected={onDatesSelected} />
          </Column>

          <HolidayBreakdownSummary fromDate={fromDate} toDate={toDate} userId={params.user}
                                   startDatePeriod={startDatePeriod} endDatePeriod={endDatePeriod}
                                   style={{marginBottom: 16}} />

          <ButtonWithMargin type="primary"
                            text={isEditing ? 'button.save-changes' : 'button.book-holiday'}
                            fullWidth={true}
                            disabled={isEditing}
                            dataTest={"book-holiday-button"}
                            size="large"
                            onClick={() => onBookHolidayClick()}/>

          {isEditing && <IconButtonWithMargin icon={IconTypes.Bin} type="outline-borderless"
                                              text="button.delete-holiday-request" fullWidth={true}
                                              size="mediumlarge" onClick={deleteHolidayClicked}/>}

        </MountedContentCardWrapper>
      }
    </Layout>
  )
}

const IconButtonWithMargin = styled<any>(IconButton)<IconButtonProps>`
  margin-bottom: 16px;
`

const ButtonWithMargin = styled<any>(Button)<ButtonProps>`
  margin: 0 auto 24px auto;
  @media (${tablet}) {
    width: 343px;
  }
`

const MountedContentCardWrapper = styled<any>(MountedContentCard)`
  margin-top: 40px;
  max-width: 680px;
`

