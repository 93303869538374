import React from 'react';
import styled from "styled-components/macro";
import { smallTablet } from "../../atoms/MediaQueries";
import Header from "../../organisms/header/Header";
import Footer from "../../organisms/footer/Footers";
import { WEEK_WIDTH } from "../../../pages/wall-planner/WallPlannerStructuralComponents";
import { AccountSuspendedBanner } from "../../organisms/stripe/AccountSuspendedBanner";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";

export function WorkPlannerLayout(props: Props) {
  const user = useSelector(selectCurrentUser);
  return (
    <WorkPlannerLayoutWrapper>
      {user && user.companyEntity && user.companyEntity.overduePayment && <AccountSuspendedBanner /> }
      <Header />
      <WorkPlannerPageWrapper style={props.pageContentStyles ?? {}} numberOfWeeksInMonth={props.numberOfWeeksInMonth}>
        {props.children}
      </WorkPlannerPageWrapper>
      <Footer />
    </WorkPlannerLayoutWrapper>
  )
}

interface Props {
  children: any;
  numberOfWeeksInMonth: number;
  pageContentStyles?: any;
}

const WorkPlannerLayoutWrapper = styled.div`
  padding: 0 0 16px 0;

  .wallPlanner__desktopControls {
    display: none;
  }

  @media (${smallTablet}) {
    padding: 0;
    .wallPlanner__desktopControls {
      display: inline-block;
    }
  }
`

const WorkPlannerPageWrapper = styled.div<any>`
  margin: 0 auto 0 auto;
  width: 100%;
  max-width: ${props => (props.numberOfWeeksInMonth ?? 4) * WEEK_WIDTH + 50}px;
  padding: 0 16px;
`
