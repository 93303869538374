import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../UI/molecules/Dialog';
import { BodyLarge, BodyRegular, BodySmall } from '../../UI/atoms/fonts/Body';
import './delete-team-dialog.scss';
import {
  selectIsDeleteTeamDialogOpen,
  selectIsDeleteTeamDialogProcessing,
  selectTeamToDeleteTeam,
  setIsDeleteTeamDialogOpen,
  setIsDeleteTeamDialogProcessing
} from "./deleteTeamDialog.duck";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { deleteTeamRequest } from "../../../services/TeamService";
import { NotificationMessages, redirectWithNotification } from "../../../models/notification.models";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { PrimaryButton } from "../../UI/atoms/buttons/PrimaryButton";
import { OutlineButton } from "../../UI/atoms/buttons/OutlineButton";
import { useTranslation } from "react-i18next";
import { Center } from "../../UI/atoms/StructuralLayout";

export default function DeleteTeamDialog() {
  const isOpen = useSelector(selectIsDeleteTeamDialogOpen);
  const selectedTeam = useSelector(selectTeamToDeleteTeam);
  const isLoading = useSelector(selectIsDeleteTeamDialogProcessing);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  if (!isOpen) {
    return null
  }

  const close = () => {
    dispatch(setIsDeleteTeamDialogOpen({isOpen: false, team: undefined}));
  }
  const deleteTeam = () => {
    dispatch(setIsDeleteTeamDialogProcessing(true))
    deleteTeamRequest(selectedTeam?.id)
      .then(() => redirectWithNotification(NotificationMessages.teamDeleted, '/manage/teams-and-users'))
      .catch(() => failureNotification('Team failed to delete'))
      .finally(() => setIsDeleteTeamDialogProcessing(false));
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => close()} className="deleteTeamDialog">
      <HeadlineMedium className="deleteTeamDialog__title">{t('delete-team-dialog.title')}</HeadlineMedium>
      {isLoading ?
        <LoadingSpinner hideBorder={true}/> :
        <>
          <BodyRegular className="deleteTeamDialog__message">{t('delete-team-dialog.description')}</BodyRegular>

          <div className="deleteTeamDialog__content">
            <Center>
              <BodyLarge weight={600} className="deleteTeamDialog__message">{selectedTeam?.name ?? ''}</BodyLarge>
            </Center>
          </div>

          <BodySmall className="deleteTeamDialog__info">{t('delete-team-dialog.warning-message')}</BodySmall>

          <PrimaryButton className="deleteTeamDialog__button"
                         fullWidth={true}
                         text="button.confirm-delete-team"
                         size="large"
                         click={() => deleteTeam()}/>
          <OutlineButton className="deleteTeamDialog__button deleteTeamDialog__cancelButton"
                         text="button.reject-delete-team"
                         size="large"
                         fullWidth={true}
                         click={() => close()}/>
        </>
      }
    </Dialog>
  )
}
