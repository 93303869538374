import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { Moment } from "moment";
import { BodyRegular } from "../atoms/fonts/Body";
import { PRETTY_DATE_FORMAT } from "../../../utils/DateUtils";
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { Desk } from "../../../services/DeskBookingService";
import { CarParkSpace } from "../../../services/CarParkingPlanService";

export function OfficeAndDateContentCard(props: Props) {
  return (
    <Container style={props?.style ?? {}}>
      <Left>
        <Icon icon={IconTypes.Office} size={'medium'} />
      </Left>
      <Right>
        <BodyRegular weight={600}>{props.office?.label ?? '???'}</BodyRegular>
        {props.desk && <BodyRegular>{props.desk.label}</BodyRegular>}
        {props.parkingSpace && <BodyRegular>{props.parkingSpace.label}</BodyRegular>}
        <BodyRegular>{props.date.format(PRETTY_DATE_FORMAT)}</BodyRegular>
      </Right>
    </Container>
  )
}

interface Props {
  style?: any;
  office?: OfficeEntity;
  date: Moment;
  desk?: Desk;
  parkingSpace?: CarParkSpace
}

const Container = styled.div`
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  background-color: ${Colours.veryLightGrey};
  display: flex;
  i {
    height: 26px;
  }
`


const Left = styled.div`
`


const Right = styled.div`
  
`
