import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../state/app.state';
import { getAllUsersRequest } from "../../services/UserService";
import { getAllUserTags } from "../../services/Tags";
import { SimpleUser } from "../../models/user.models";
import { UserTag } from "../../models/tags.models";
import { addOrReplaceQueryParam } from "../../utils/UrlUtils";

export enum UserManagementView {
  TagManager = 'tag', UserManager = 'user',
}

export interface UserManagementState {
  loading: boolean;
  activeView: UserManagementView;
  allUsers: SimpleUser[];
  filteredUsers: SimpleUser[];
  allUserTags: UserTag[];
}

export const initialUserManagementState: UserManagementState = {
  loading: false,
  activeView: UserManagementView.UserManager,
  allUsers: [],
  filteredUsers: [],
  allUserTags: [],
};

export const setActiveView: any = createAsyncThunk(
  'userManagement/setActiveView',
  async (view: UserManagementView, thunkAPI) => {
    addOrReplaceQueryParam('view', view);
    return view;
  }
);

export const loadAllUsers: any = createAsyncThunk(
  'userManagement/loadAllUsers',
  async (skip: number = 0, thunkAPI) => {
    const allUsers = await getAllUsersRequest();
    return allUsers;
  }
);

export const loadAllUserTags: any = createAsyncThunk(
  'userManagement/loadAllUserTags',
  async (skip: number = 0, thunkAPI) => {
    const allUserTags = await getAllUserTags();
    return allUserTags;
  }
);

const UserManagementSlice = createSlice({
  name: 'UserManagement',
  initialState: initialUserManagementState,
  reducers: {
    resetUserManagement: (state) => (initialUserManagementState),
    updateFilteredUsers: (state, action) => {
      const filteredUsers = action.payload.length > 2 ? state.filteredUsers.filter(u => `${u.firstName} ${u.lastName} ${u.email} ${u.teamName}`.toLowerCase().includes(action.payload.toLowerCase())) : state.allUsers;
      return {
        ...state, filteredUsers: filteredUsers,
      };
    },
  },
  extraReducers: {
    [loadAllUsers.pending]: (state) => ({...state, loading: true}),
    [loadAllUsers.failed]: (state) => ({...state, loading: false}),
    [loadAllUsers.fulfilled]: (state, action) => ({...state, allUsers: action.payload, filteredUsers: action.payload, loading: false}),

    [setActiveView.fulfilled]: (state, action) => ({...state, activeView: action.payload}),

    [loadAllUserTags.fulfilled]: (state, action) => ({...state, allUserTags: action.payload}),
  },
});



export default UserManagementSlice.reducer;
export const {
  resetUserManagement,
  updateFilteredUsers,
} = UserManagementSlice.actions;


// Selectors
export const selectFilteredUsers = (state: AppState) => state.userManagement.filteredUsers;
export const selectAllUserTags = (state: AppState) => state.userManagement.allUserTags;
