import React from 'react';
import { BodyLarge, BodyRegular } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { Trans, useTranslation } from "react-i18next";

export default function CancelSection() {
  const {t} = useTranslation();

  return (
    <div>
      <BodyLarge weight={600} style={{marginBottom: 16}}>{t('payments.looking-to-cancel')}</BodyLarge>
      <BodyRegular weight={400}><Trans i18nKey={'payments.looking-to-cancel-message'}
                                       components={[<strong><a style={{color: Colours.blue}} href="mailto:hello@team-today.com">hello@team-today.com</a></strong>]} />
      </BodyRegular>
    </div>
  );
}
